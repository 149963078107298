<template>
    <div class="my_health_wrap my_result_detail">
          <div class="contents">
            <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
              <span class="bullet01"></span>
            </div>
            <div class="depth__text">
              <h4 class="depth01__title title">검사정보</h4>
            </div>
          </div>
          <div class="table-cont">
            <ul class="table-list type02">
              <li class="table-item">
                <div class="item tit">검사일자</div>
                <div class="item">2024.05.17</div>
              </li>
              <li class="table-item">
                <div class="item tit">검사명</div>
                <div class="item">CRP (Quant)</div>
              </li>
              <li class="table-item">
                <div class="item tit">결과</div>
                <div class="item">1.12 (mg/L)</div>
              </li>
              <li class="table-item">
                <div class="item tit">참고치</div>
                <div class="item">0.0~5.0</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="contents">
            <div class="chart_wrap">
              <div class="chart_label">
                <p>28.40 (mg/L)</p>
              </div>
               <HealthChart />
            </div>
        </div>
        <div class="contents pt60">
          <div class="table-wrap table-type1">
            <div>
              <table>
                <caption class="blind">표</caption>
                <colgroup>
                  <col style="width:50%;">
                  <col style="width:50%;">
                </colgroup>
                <thead>
                    <tr>
                      <th>날짜</th>
                      <th>수치</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2024.07.29</td>
                      <td>1.12 (mg/L)</td>
                    </tr>
                    <tr>
                      <td>2024.06.18</td>
                      <td>1.72 (mg/L)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        <div class="contents">
          <div class="btn-wrap center">
            <a href="javascript:;" class="btn gray">목록</a>
          </div>
        </div>
    </div>
</template>

<script>
import HealthChart from '@/components/common/HealthChart.vue'; 

export default {
    components: {
        HealthChart 
  },
  setup() {
    return {
   
    };
  }
};

</script>
