<template>
    <div>
    <p class="contents sub-text">회원가입 시 등록하신 이메일 주소로 아이디를 전송하였습니다.</p>
    <p class="sub-text dp2">회원가입 시 등록하신 이메일 주소로 아이디를 전송하였습니다.</p>
    <section class="box" style="margin-top: 60px;">
        <div class="tab-menu type01">
            <ul class="tab-menu__list">
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn">전체</button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn">노인</button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn">남성</button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn">여성</button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn">소아</button>
                </li>
            </ul>
        </div>
        <div class="board__search">
            <div class="board__select">
                <select name="" id="Sel_Inst" class="select_department">
                    <option value="">전체</option>
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" id="Search_doc" placeholder="검색어를 입력하세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>
        <div class="initial-search">
            <h3>초성검색</h3>
            <ul class="initial-search__list">
                <li><a href="javascript:;" class="all active">ALL</a></li>
                <li><a href="javascript:;">ㄱ</a></li>
                <li><a href="javascript:;">ㄴ</a></li>
                <li><a href="javascript:;">ㄷ</a></li>
                <li><a href="javascript:;">ㄹ</a></li>
                <li><a href="javascript:;">ㅁ</a></li>
                <li><a href="javascript:;">ㅂ</a></li>
                <li><a href="javascript:;">ㅅ</a></li>
                <li><a href="javascript:;">ㅇ</a></li>
                <li><a href="javascript:;">ㅈ</a></li>
                <li><a href="javascript:;">ㅊ</a></li>
                <li><a href="javascript:;">ㅋ</a></li>
                <li><a href="javascript:;">ㅌ</a></li>
                <li><a href="javascript:;">ㅍ</a></li>
                <li><a href="javascript:;">ㅎ</a></li>
            </ul>
        </div>
    </section>

    <div class="box" style="margin-top: 60px;">
        <div class="text-box type1 info-text">
            <h3 class="depth02__title title">제목</h3>
            <ul class="text-box__list">
                <li class="text-box__item">컨텐츠 간격 및 margin-top: 60px; 인 애들은 "contents" 클래스를 넣어주세요</li>
                <li class="text-box__item">line-height는 모두 통일 했습니다. 넣지 마세요 (06.03)</li>
            </ul>
            <div class="depth__wrap info-reference">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title">의료법 시행규칙에 지정된 별지 서식이 아닌 동의서, 위임장은 법적으로 효력이 없습니다.</h4>
                </div>
            </div>
            <div class="btn-wrap right">
                <a href="javascript:;" class="btn blue">버튼</a>
                <a href="javascript:;" class="btn mint">버튼</a>
            </div>
        </div>

        <div class="contents text-btn">
            <div class="text">
                <p><b>법정대리인(보호자)</b>가 병원 홈페이지의 <b>회원 아이디/패스워드</b>를 알고 계셔야 합니다.</p>
            </div>
            <div class="btn">
                <div class="btn-wrap"><a href="javascript:;" class="btn">아이디 찾기</a><a href="javascript:;" class="btn">비밀번호 찾기</a></div>
            </div>
        </div>

        <div class="contents">
                <div class="text-box type4">
            <div class="text-box__wrap">
                <div class="info-text">
                    <h3 class="depth03__title title">진료정보</h3>
                    <div class="depth__wrap depth03">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p>필수항목 : 성명, 주민등록번호, 주소, 자택 전화번호, 휴대폰 전화번호</p>
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <p class="">※</p>
                                </div>
                                <div class="depth__text">
                                    <p>의료법에 의해 고유식별정보 및 진료정보를 의무적으로 보유하여야 합니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-text">
                    <h3 class="depth03__title title">홈페이지 - 진료 및 종합건강검진 예약</h3>
                    <div class="depth__wrap depth03">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p>필수항목 : 이름, 생년월일, 연락처</p>
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <p class="">※</p>
                                </div>
                                <div class="depth__text">
                                    <p>서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다. : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-text">
                    <h3 class="depth03__title title">개인정보 수집방법</h3>
                    <div class="depth__wrap depth03">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p>서면양식, 홈페이지, 팩스, 전화</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>

        <section class="box" style="margin-top: 60px;">
            <h2 style="color: rgb(92, 92, 227);">텍스트</h2>
            <div class="info-text">
                <h3 class="depth01__title title">대 타이틀에 대한 텍스트 가이드</h3>
                <h3 class="depth02__title title">중 타이틀에 대한 텍스트 가이드</h3>
                <h3 class="depth02__title title blue__title">중 타이틀에 대한 텍스트 가이드</h3>
                <h3 class="depth03__title title">소 타이틀에 대한 텍스트 가이드</h3>
                <p>본문 내용에 대한 텍스트 가이드</p>
                <p class="small-text">본문 하위 내용에 대한 텍스트 가이드</p>
            </div>
        </section>
        <div class="box" style="margin-top: 60px;">
            <h2 style="color: rgb(92, 92, 227);">블릿</h2>


            <!-- 블릿형 -->
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">리스트 대 타이틀 내용</h4>
                    <!-- depth02 -->
                    <div class="depth__wrap depth02">
                        <div class="depth__bullet">
                            <span class="bullet02"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth02__title title">중타이틀 리스트 내용에 대한 가이드</h4>
                            <!-- depth03 -->
                            <div class="depth__wrap depth03">
                                <div class="depth__bullet">
                                    <span class="bullet03"></span>
                                </div>
                                <div class="depth__text">
                                    <h4 class="depth03__title title">본문 리스트 형식 텍스트는 현재 최대 3depth까지의 가이드가 있습니다.</h4>
                                    <p>텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 </p>
                                    <div class="depth__wrap">
                                        <div class="depth__bullet">
                                            <span class="bullet04"></span>
                                        </div>
                                        <div class="depth__text">
                                            <p>블릿있는 텍스트 입니다. 블릿있는 텍스트 입니다. 블릿있는 텍스트 입니다. 블릿있는 텍스트 입니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- 순서블릿형 -->
            <div class="info-text depth__wrap depth01">

                <div class="depth__text">
                    <h4 class="depth01__title title">리스트 대 타이틀 내용</h4>
                    <!-- depth02 -->
                    <div class="depth__wrap depth02">
                        <div class="depth__bullet">
                            <h4 class="depth02__title">1.</h4>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth02__title title">중타이틀 리스트 내용에 대한 가이드</h4>
                            <!-- depth03 -->
                            <div class="depth__wrap depth03">
                                <div class="depth__bullet">
                                    <h4 class="depth03__title">가.</h4>
                                </div>
                                <div class="depth__text">
                                    <h4 class="depth03__title title">본문 리스트 형식 텍스트는 현재 최대 3depth까지의 가이드가 있습니다.</h4>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>①</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 텍스트 예제 </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contents text-box type2">
            <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의 <span class="c-red">(필수)</span></h3>
            <div class="text-box__wrap">
                <p>
                    자료 수급예정
                </p>
            </div>
        </div>
        <div class="agree-input">
            <label for="agree-y" class="agree-input__item">
                <input type="radio" id="agree-y" class="agree-input__radio" name="agree">
                <span class="icon"></span>
                동의합니다
            </label>
            <label for="agree-n" class="agree-input__item">
                <input type="radio" id="agree-n" class="agree-input__radio" name="agree" checked>
                <span class="icon"></span>
                동의하지 않습니다
            </label>
        </div>


    </div>
    <section class="box" style="margin-top: 60px;">
        <h2 style="color: rgb(92, 92, 227);">버튼 모음</h2>
        <div class="btn-wrap center">
            <a href="javascript:;" class="btn gray">목록</a>
            <a href="javascript:;" class="btn dark-gray">피카츄</a>
            <a href="javascript:;" class="btn red">삭제</a>
            <a href="javascript:;" class="btn">취소</a>
            <a href="javascript:;" class="btn blue">작성</a>
        </div>
    </section>

    <section class="box" style="margin-top: 60px;">
        <h2 style="color: rgb(92, 92, 227);">테이블 모음</h2>
        <div class="table-wrap table-type1">
            <h3 class="deps-tit">테이블타입 1</h3>
            <div class="scroll">
                <table>
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: 25%;">
                        <col style="width: 25%;">
                        <col style="width: 25%;">
                        <col style="width: 25%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>부서</th>
                            <th>전화번호</th>
                            <th>부서</th>
                            <th>전화번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>소화기내과</td>
                            <td>1282, 1288</td>
                            <td>호흡기내과</td>
                            <td>1287</td>
                        </tr>
                        <tr>
                            <td>심장내과</td>
                            <td>1280, 1283</td>
                            <td>내분비대사내과</td>
                            <td>1285, 1286</td>
                        </tr>

                        <tr>
                            <td>정신건강의학과</td>
                            <td style="border-right: 1px solid #d9d9d9;">1580</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="table-cont">
            <ul class="table-list type02">
                <li class="table-item">
                    <div class="item tit">이름</div>
                    <div class="item">이준용</div>
                </li>
                <li class="table-item">
                    <div class="item tit">환자번호</div>
                    <div class="item">1234567</div>
                </li>
                <li class="table-item">
                    <div class="item tit">휴대전화</div>
                    <div class="item">010-1234-5678</div>
                </li>
                <li class="table-item">
                    <div class="item tit">이메일</div>
                    <div class="item">abcde1234@naver.com</div>
                </li>
            </ul>
        </div>
        <div class="depth__wrap info-reference">
            <div class="depth__bullet">※</div>
            <div class="depth__text">
                <h4 class="depth03__title"> 예약일시를 꼭 확인 후 내원해 주시기 바랍니다.</h4>
            </div>
        </div>
        <div class="depth__wrap">
            <div class="depth__bullet">※</div>
            <div class="depth__text">
                <h4 class="depth03__title">예약 진료과로 바로 가셔서 성함을 말씀하시고 직원의 안내를 빋으십시오.</h4>
            </div>
        </div>
    </section>

    <section class="box" style="margin-top: 60px;">
        <h2 style="color: rgb(92, 92, 227);">input 모음</h2>

        <div class="input-form type1">
            <div class="input-form__top-wrap">
                <p class="input-form__top-text type2">&#183; 부정확한 휴대전화, 이메일 주소 입력 시 다른 사람에게 나의 정보가 전송될 수도 있습니다.</p>
                <p class="input-form__top-text type2">&#183; 정확하게 입력하여 주시기 바랍니다.</p>
            </div>
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>아이디</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" placeholder="  영문,숫자조합 3~12자리, 첫 자는 반드시 영문 소문자 사용">
                                <button class="btn">중복확인</button>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <input type="text" class="cont__text-input" placeholder=" 영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지">
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <!-- 조건 미성립시 class='b-red' 추가-->
                            <input type="text" class="cont__text-input b-red" placeholder=" 영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지">
                            <p class="desc c-red">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>이름</div>
                        <div class="cont">이준용</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>휴대전화</div>
                        <div class="cont">010-1234-5678</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box email">
                        <div class="tit"><span class="c-red">* </span>이메일</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input"> <span class="line">@</span>
                            <input type="text" class="cont__text-input">
                            <select class="cont__select">
                                <option value="직접입력">직접입력</option>
                                <option value="naver.com">naver.com</option>
                                <option value="gmail.com">daum.net</option>
                                <option value="gmail.com">gmail.com</option>
                                <option value="yahoo.com">yahoo.com</option>
                                <option value="nate.com">nate.com</option>
                                <option value="korea.com">korea.com</option>
                                <option value="chol.com">chol.com</option>
                            </select>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit">유선 전화번호</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input"> <span class="line">-</span>
                            <input type="text" class="cont__text-input"> <span class="line">-</span>
                            <input type="text" class="cont__text-input">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>환자와의 관계</div>
                        <div class="cont radio">
                            <div class="input-form__radio">
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" v-model="item1" value="본인" checked>
                                    <span class="icon"></span>
                                    본인
                                </label>
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" v-model="item1" value="보호자">
                                    <span class="icon"></span>
                                    보호자
                                </label>
                            </div>
                            <label class="cont__text">
                                <input type="text" class="cont__text-input" :disabled="item1 !== '보호자'" v-model="item2" ref="item2">
                                <p class="present">보호자 선택 시 환자명을 입력해 주세요.</p>
                            </label>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box address">
                        <div class="tit">주소</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                                <button class="btn">우편번호 검색</button>
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">휴대전화</div>
                        <div class="cont">010-1234-5678</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">이메일</div>
                        <div class="cont">hahaha0800</div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>칭찬 직원(의료진)</div>
                        <div class="cont"><input type="text" class="cont__text-input"></div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">부서/병동</div>
                        <div class="cont"><input type="text" class="cont__text-input"></div>
                    </div>
                </li>
                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>칭찬 직원(의료진)</div>
                        <div class="cont"><textarea name="" id=""></textarea></div>
                    </div>
                </li>
                  <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>사유</div>
                        <div class="cont radio type2">
                            <div class="input-form__radio">
                                <div class="input-form__radio-inner">
                                    <div class="radio-list">
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="no-info" v-model="radioChecked"><span class="icon"></span> 원하는 정보가 없어서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="low-usage" v-model="radioChecked"><span class="icon"></span> 사용빈도가 낮아서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="privacy-concern" v-model="radioChecked"><span class="icon"></span> 개인정보 유출이 우려되서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="no-email" v-model="radioChecked"><span class="icon"></span> 메일 수신을 원하지 않아서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="moving" v-model="radioChecked"><span class="icon"></span> 거주지 이전
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="inconvenient" v-model="radioChecked"><span class="icon"></span> 서비스 이용이 불편해서
                                        </label>
                                        <label class="input-item radio-input">
                                            <input type="radio" class="radio" name="radio1" v-model="radioChecked" value="other">
                                            <span class="icon"></span> 기타
                                            <input type="text" class="cont__text-input ml10" placeholder="의견을 남겨주세요." :disabled="radioChecked !== 'other'" v-model="otherReason">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </li>
            </ul>
        </div>
    </section>

    <section class="box" style="margin-top: 60px;">
        <h2 style="color: rgb(92, 92, 227);">아이콘 모음</h2>

        <div class="arrow-wrap">
            <div class="arrow-btn"></div>
        </div>
    </section>
    <section class="box" style="margin-top: 60px;">
        <h2 style="color: rgb(92, 92, 227);">달력</h2>
        <div class="calendar">
            <div class="calendar__header">
                <button class="calendar__header--button prev"></button>
                <h4 class="calendar__header--title">2022.08</h4>
                <button class="calendar__header--button next"></button>
            </div>
            <div class="calendar__hcon">
                <table>
                    <caption>
                        예약일 선택 테이블
                    </caption>
                    <colgroup>
                        <col width="14.29%">
                        <col width="14.29%">
                        <col width="14.29%">
                        <col width="14.29%">
                        <col width="14.29%">
                        <col width="14.29%">
                        <col width="14.29%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>일</th>
                            <th>월</th>
                            <th>화</th>
                            <th>수</th>
                            <th>목</th>
                            <th>금</th>
                            <th>토</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="today"><a href="javascript:;">1</a></td>
                            <td><a href="javascript:;">2</a></td>
                            <td class="pos active"><a href="javascript:;">3</a></td>
                            <td><a href="javascript:;">4</a></td>
                            <td class="pos"><a href="javascript:;">5</a></td>
                            <td><a href="javascript:;">6</a></td>
                        </tr>
                        <tr>
                            <td><a href="javascript:;">7</a></td>
                            <td><a href="javascript:;">8</a></td>
                            <td class="pos"><a href="javascript:;">9</a></td>
                            <td><a href="javascript:;">10</a></td>
                            <td><a href="javascript:;">11</a></td>
                            <td class="pos"><a href="javascript:;">12</a></td>
                            <td><a href="javascript:;">13</a></td>
                        </tr>
                        <tr>
                            <td><a href="javascript:;">14</a></td>
                            <td><a href="javascript:;">15</a></td>
                            <td class="pos"><a href="javascript:;">16</a></td>
                            <td><a href="javascript:;">17</a></td>
                            <td><a href="javascript:;">18</a></td>
                            <td class="pos"><a href="javascript:;">19</a></td>
                            <td><a href="javascript:;">20</a></td>
                        </tr>
                        <tr>
                            <td><a href="javascript:;">21</a></td>
                            <td><a href="javascript:;">22</a></td>
                            <td class="pos"><a href="javascript:;">23</a></td>
                            <td><a href="javascript:;">24</a></td>
                            <td><a href="javascript:;">25</a></td>
                            <td class="pos"><a href="javascript:;">26</a></td>
                            <td><a href="javascript:;">27</a></td>
                        </tr>
                        <tr>
                            <td><a href="javascript:;">28</a></td>
                            <td><a href="javascript:;">29</a></td>
                            <td class="pos"><a href="javascript:;">30</a></td>
                            <td><a href="javascript:;">31</a></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="calendar__bottom">
                <p>
                    <span class="today"></span>
                    오늘
                </p>
                <p>
                    <span class="pos"></span>
                    예약가능
                </p>
                <p>
                    <span class="active"></span>
                    선택
                </p>
            </div>
        </div>

        <div class="contents box">
            <div class="profile__tit">주간 일정</div>
            <div class="calendar__header"><button class="calendar__header--button prev"></button>
                <h4 class="calendar__header--title">2022.08</h4><button class="calendar__header--button next"></button>
            </div>
            <div class="weekly__calendar">
                <table>
                    <colgroup>
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                        <col style="width: calc(100% / 7);">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>진료시간</th>
                            <th>월 (6/20)</th>
                            <th>화 (5.21)</th>
                            <th>수 (5.22)</th>
                            <th>목 (5.23)</th>
                            <th>금 (5.24)</th>
                            <th>토 (5.25)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>오전</td>
                            <td><span class="check"></span></td>
                            <td></td>
                            <td></td>
                            <td><span class="check"></span></td>
                            <td><span class="check"></span></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>오전</td>
                            <td></td>
                            <td><span class="check"></span></td>
                            <td></td>
                            <td><span class="check"></span></td>
                            <td></td>
                            <td><span class="check"></span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="weekly__calendar-info">
                    <div class="item">
                        <span class="check"></span>
                        <span>외래진료</span>
                    </div>
                </div>
                <a href="javascript:;" class="weekly__calendar-btn">진료예약</a>
            </div>
        </div>
    </section>
    <!-- 데이터피커 -->
     <section class="box" style="margin-top: 60px;">
        <div class="profile__tit">데이터피커 단일</div>
            <div class="date_wrap">
                <div class="date-input">
                <VueDatePicker v-model="date" locale="ko"  :enable-time-picker="false" :format="customFormat" :week-start="0" :day-class="getDayClass" :select-text="'선택'" :cancel-text="'취소'" />
                <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
            </div>
         </div>

        <!-- 단일 버전 메소드는 마이페이지->나의 건강관리 ->혈압_결과_데이트피커기능.vue -->

        <div class="profile__tit contents">데이터피커 두개</div>
        <div class="date_wrap">
             <div class="date-input">
                <VueDatePicker v-model="selectedDateRange" locale="ko"
                :enable-time-picker="false" :format="customFormat"
                :week-start="0" :day-class="getDayClass"
                :select-text="'선택'" :cancel-text="'취소'"
                :range="true" :multi-calendars="true"/>
            <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
        </div>
        </div>
       <!-- 두개 버전은 마이페이지 ->나의건강관리->나의건강관리_메인.vue에 있음 -->
     </section>

</div>
</template>


<script>
import { ref, onMounted } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
components: {
    VueDatePicker,
},
data() {
    return {
       
    };
},
setup() {
    const customFormat = 'yyyy.MM.dd'; 
    const selectedDateRange = ref([new Date(), new Date()]);
    const selectedPeriod = ref(3);

    const setSelectedPeriod = (months) => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - months);
        selectedDateRange.value = [startDate, endDate]; 
        selectedPeriod.value = months; 
    };

    const getDayClass = (date) => {
        const day = date.getDay();
        if (day === 0) {
        return 'sunday-class';
        } else if (day === 6) {
        return 'saturday-class';
        }
        return '';
    };
        
    const addClass = (event) => {
        event.currentTarget.classList.add('on');
    };

    onMounted(() => {
        setSelectedPeriod(3);
    });

    return {
        customFormat,
        selectedDateRange,
        selectedPeriod,
        setSelectedPeriod,
        getDayClass,
        addClass
    };
}
};
</script>