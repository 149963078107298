<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="my_health_wrap my_result_wrap contents">
        <div class="contents health_info_wrap">
            <div class="tab-menu__wrap">
                <div class="tab-menu type02">
                    <ul class="tab-menu__list center">
                        <template v-for="(menu, idx) in menuList" :key="idx">
                            <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '580'">
                                <a href="javascript:;" v-if="menu.menuNo == '618'" @click="goRes(menu)">
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </a>
                                <a :href="menu.menuUrl" :target="menu.linkMethod" v-else-if="menu.menuUrl.indexOf('http') > -1">
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </a>
                                <router-link :to="menu.menuUrl" v-else>
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="contents result_box01">
                <div class="text-box type1 info-text">
                    <ul class="text-box__list">
                        <li class="text-box__item">오늘날짜로부터 2년 이내의 내역만 보여지며, 아래 조건검색(1년 단위) 로 검색을 통해 조회하실 수 있습니다.</li>
                        <li class="text-box__item">예약은 되었으나 실제 진료받지 못한 경우, 해당 내역은 조회되지 않습니다.</li>
                    </ul>
                </div>
                <div class="date_wrap contents">
                    <div class="board__select">
                        <select name="" id="Sel_Inst" class="select_department" @change="changeDept" ref="dept">
                            <option value="">전체</option>
                            <option :value="i" v-for="i in deptList" :key="i">{{i}}</option>
                        </select>
                    </div>
                    <div class="date-input">
                        <VueDatePicker v-model="selectedDateRange" locale="ko"
                                       :enable-time-picker="false" :format="customFormat"
                                       :week-start="0" :day-class="getDayClass"
                                       :select-text="'선택'" :cancel-text="'취소'"
                                       :range="true" :multi-calendars="true"/>
                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘"/>
                    </div>

                    <div class="tab-menu type_date">
                        <ul class="tab-menu__list">
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                            </li>
                        </ul>
                    </div>

                    <div class="health_info_search">
                        <button @click="getOutList">검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
                    </div>
                </div>

                <div class="contents">
                    <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                        <div class="scroll">
                            <table>
                                <caption class="blind">결과조회-외래진료 폼</caption>
                                <colgroup>
                                    <col style="width:17%;">
                                    <col style="width:34%;">
                                    <col style="width:34%;">
                                    <col style="width:15%;">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>진료일자</th>
                                    <th>진료과</th>
                                    <th>진료의</th>
                                    <th>다시예약</th>
                                </tr>
                                </thead>
                                <tbody class="no-data-table" v-if="$commonUtils.isEmpty(outList)">
                                    <tr>
                                        <td colspan="4">검색된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(out, idx) in outList" :key="idx">
                                        <td>{{ $commonUtils.makeDateFormat(out.orddd,'.') }}</td>
                                        <td>{{ out.orddeptnm }}</td>
                                        <td>{{ out.hptlJobTitle ? out.hptlJobTitle : out.orddrnm }}</td>
                                        <td class="result_btn">
                                            <div class="btn-wrap center" v-if="out.drRsvOpenYn == 'Y' && out.rsvOpenYn == 'Y'">
                                                <a href="javascript:;" class="btn" @click="goReservation(out)">다시예약</a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css'
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    data() {
        return {
            searchParam: {},
            selectedDateRange: [new Date(), new Date()],
            selectedPeriod: 3,
            customFormat: 'yyyy.MM.dd',
            outList: [],
            popupVisible: false,

            orgList: [],
            deptList: []
        }
    },
    components: {
        VueDatePicker,
        NameCertifyPopUp
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.setSelectedPeriod(3)
        this.getOutList()
    },
    methods: {
        goRes(item){
            alert('국가건강검진 결과조회를 위해 국민건강보험공단 사이트로 이동합니다.');
            window.open(item.menuUrl)
        },
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        setSelectedPeriod(months) {
            const startDate = new Date()
            const endDate = new Date()
            startDate.setMonth(endDate.getMonth() - months)
            this.selectedDateRange = [startDate, endDate]
            this.selectedPeriod = months
        },
        getDayClass(date) {
            const day = date.getDay()
            if (day === 0) {
                return 'sunday-class'
            } else if (day === 6) {
                return 'saturday-class'
            }
            return ''
        },
        addClass(event) {
            event.currentTarget.classList.add('on')
        },
        getOutList() {
            this.searchParam.fromdd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[0], 'yyyyMMdd', '')
            this.searchParam.todd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[1], 'yyyyMMdd', '')

            this.axios.get(this.api.mypage.outList, {
                params: this.searchParam
            }).then(res => {
                const outList = res.data.list.patinfolist
                const drList = res.data.docList

                if(Array.isArray(outList)){
                    this.outList = outList
                } else {
                    this.outList = [outList]
                }

                if(this.outList[0] && drList.length > 0){
                    for(let i of this.outList){
                        for(let j of drList){
                            if(i.orddeptcd == j.doctorDept.deptCd && i.orddrnm == j.drName){
                                i.rsvOpenYn = j.doctorDept.rsvOpenYn
                                i.drRsvOpenYn = j.doctorDept.drRsvOpenYn
                                i.hptlJobTitle = j.hptlJobTitle
                            }
                        }
                    }
                }

                this.orgList = this.outList

                this.deptList = []
                this.$refs.dept.value = ''
                for(let i of this.outList){
                    this.deptList.push(i.orddeptnm)
                }
                this.deptList = this.$commonUtils.dupArr(this.deptList)
            })
        },
        changeDept(){
            const dept = this.$refs.dept.value
            this.outList = this.orgList.filter(item => {
                let matchDept = true;

                if (dept) {
                    matchDept = item.orddeptnm === dept;
                }

                return matchDept;
            });
        },
        goReservation(out){
            const searchParam = {
                startIndex: 1,
                pageRow: 10,
                drName: out.orddrnm,
                langType: 'kr',
                instNo: 1,
                deptClsf: 'A',
                deptCd: out.orddeptcd,
            }

            this.axios
                .get(this.api.doctor.doctorList,
                    {
                        params: searchParam
                    })
                .then(res => {
                    const totCnt = res.data.doctorTotCnt
                    if(totCnt == 0){
                        alert('해당하는 과 혹은 의료진이 없습니다.\n관리자에게 문의하세요.')
                        return
                    }
                    if(totCnt > 0){
                        const doctorInfo = res.data.doctorList[0]
                        console.log(doctorInfo)
                        this.axios.get(this.api.doctor.absence, {
                            params: {
                                drNo: doctorInfo.drNo
                            }
                        }).then(res => {
                            const reason = res.data.reason
                            if(reason){
                                const startDt = new Date(res.data.staDt)
                                const endDt = new Date(res.data.endDt)
                                const today = new Date()
                                if(today >= startDt && today <= endDt){
                                    alert(reason)
                                    return
                                }
                            }
                            this.$store.commit('setReserveInfo', doctorInfo)
                            this.$router.push('/reserve/reservation.do')
                        }).catch(err => {
                            alert('오류가 발생했습니다.')
                        })
                    }
                })
        },
    }
}

</script>
