<template>
    <Header></Header>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home"><a href="" class="Location__item--btn"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQ4ja3TP0sDQRAF8J+HiGAKLQVFsbIQ4p/SRrDKB0hhZyXYiFjnQ9jZCJYigo2CRUDBNkr8ArGytRAUooVaZALHceeZ4IOFtzPz3s4uOyP1el0JFnAafAtPvxUnJWZ1tPEYqx2xQowWxMdxGB3t4CziNzjGJvbR/UuHi2hhDaspM8FXI9eK2l8Nt3GPJtbRyTmwE7lm1G6nk/0rV3CEmt41L3OM0vjEAW5xovcEu3hLsIwHzAcvM0vjMjTz4bGcROvn2MDzAGZ9PIf2HM0EM2jgAtUhDKuhbWB2FB+RmMNUjmAEK8Hb+M7kp0IL3aJ/2McErjOGNbwXCcomZVfvB0zHqkSsEGUdLuEKb7G/ilghyjqErwI+tOFA+HfD7BtWMJnaj+VoxjI1lSLDV/ljt5fiL3qzvpWpueuTH1quOlOE3j3aAAAAAElFTkSuQmCC"
                            alt="이미지"></a></li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과/의료진</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/department-doctor/department.do" class="">진료과/의료진</a></li>
                            <li><a href="/reserve/appointment.do" class="">예약/발급</a></li>
                            <li><a href="/hospital/information/outpatient.do" class="">이용안내</a></li>
                            <li><a href="/hospital/healthInfo/consulting.do" class="">건강정보</a></li>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">병원소개</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">창원파티마병원</a></li>
                            <li><a href="/hospital/hospitalIntro/story/newsBoard.do" class="">파티마이야기</a></li>
                            <li><a href="/hospital/hospitalIntro/active/prayBoard.do" class="">병원활동</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item">
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a aria-current="page" href="/kr/news/list.do"
                                    class="router-link-active router-link-exact-active">화이팅</a></li>
                            <li><a href="/kr/press/list.do" class="">언론보도</a></li>
                            <li><a href="/kr/event/list.do" class="">행사안내</a></li>
                            <li><a href="/kr/job/list.do" class="">퍼블팀</a></li>
                            <li><a href="https://fliphtml5.com/bookcase/nrkwy/"
                                    to="https://fliphtml5.com/bookcase/nrkwy/"></a></li>
                            <li><a href="/kr/gallery/list.do" class=""></a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sub-tit">
            <h2>진료과</h2>
        </div>
        <div class="inner">
            <div class="profile">
        <div class="profile__img">
            <div class="profile__bg"></div>

            <img src="@/assets/resource/img/profile-sample3-1.png" alt="이미지">
            <div class="profile__img-tit">
                <div class="profile__inner">
                    <span class="department-name">소아청소년과</span>
                    <span class="name">마상혁</span>
                    <button type="button" class="btn active">
                        <span class="icon"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="profile__info">
            <div class="profile__inner">
                <div class="profile__info-tit">
                    <span class="department-name">소아청소년과</span>
                    <span class="name">마상혁</span>
                </div>

                <!-- active 추가 시 하트 변화-->
                <button type="button" class="btn active">
                    <span class="icon"></span>
                    <span>관심의료원</span>
                </button>
            </div>
            <div class="contents profile__cont">
                <div class="box">
                    <div class="profile__specialty">
                        <div class="text">
                            <div class="tit">주진료분야</div>
                            <div class="desc">호홉기감염(오래가는기침, 부비동염), 소아감염병, 성장 클리닉 (저신장, 성조숙증)</div>
                        </div>
                        <div class="text">
                            <div class="tit">센터</div>
                            <div class="desc">
                                <a href="javascript:;">재활치료센터</a>
                            </div>
                        </div>
                        <div class="text">
                            <div class="tit">주진료분야</div>
                            <div class="desc">
                                <a href="javascript:;">성장클리닉</a>
                            </div>
                        </div>
                        <div class="text">
                            <div class="tit">안내사항</div>
                            <div class="desc">
                                ※ 성장클리닉은 월,수,목만 진료하며 전화예약(270-1000)
                                시에만 진료가능합니다.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contents box" id="diagnosis">
                    <div class="profile__tit">진료 일정</div>
                    <div class="calendar__header"><button class="calendar__header--button prev"></button>
                        <h4 class="calendar__header--title">2022.08</h4><button class="calendar__header--button next"></button>
                    </div>
                    <div class="weekly__calendar">
                        <table>
                            <colgroup>
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                                <col style="width: calc(100% / 7);">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>진료시간</th>
                                    <th>월 (6/20)</th>
                                    <th>화 ((5.21)</th>
                                    <th>수 (5.22)</th>
                                    <th>목 (5.23)</th>
                                    <th>금 (5.24)</th>
                                    <th>토 (5.25)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>오전</td>
                                    <td><span class="check"></span></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="check"></span></td>
                                    <td><span class="check"></span></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>오전</td>
                                    <td></td>
                                    <td><span class="check"></span></td>
                                    <td></td>
                                    <td><span class="check"></span></td>
                                    <td></td>
                                    <td><span class="check"></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="weekly__calendar-info">
                            <div class="item">
                                <span class="check"></span>
                                <span>외래진료</span>
                            </div>
                        </div>
                        <a href="javascript:;" class="weekly__calendar-btn">진료예약</a>
                    </div>
                </div>
                <div class="box bt" id="education">
                    <div class="profile__tit">학력</div>
                    <ul class="profile__list">
                        <li class="profile__item">경북대학교의과대학 졸업 (1990년)</li>
                        <li class="profile__item">고신대학교 대학원 의학과 소아과학 전공 박사(2004년)</li>
                    </ul>
                </div>
                <div class="box">
                    <div class="profile__tit">경력</div>
                    <ul class="profile__list">
                        <li class="profile__item">현) 창원파티마병원 소아청소년과 주임과장</li>
                        <li class="profile__item">현) 가톨릭대학교 의과대학 외래교수</li>
                        <li class="profile__item">2007년 소아감염 세부전문의 취득</li>
                        <li class="profile__item">대한간호사협회 건강한 모유수유아 선발대회 경남 대회 심사위원</li>
                        <li class="profile__item">예방접종심의위원회 폴리오 분과위원회 위원</li>
                    </ul>
                    <button href="javascript:;" class="weekly__calendar-btn type2">더보기 +</button>
                </div>
                <div class="box">
                    <div class="profile__tit">학회</div>
                    <ul class="profile__list">
                        <li class="profile__item">대한 소아감염병학회 정회원</li>
                        <li class="profile__item">대한감염학회 정회원</li>
                        <li class="profile__item">대한소아알레르기 호홉기학회 정회원</li>
                        <li class="profile__item">대한신생아학회 정회원</li>
                    </ul>
                </div>
                <div class="box bt" id="book">
                    <div class="profile__tit">주요논문</div>
                    <p class="desc">
                        Immunogenicity and safety of a combined DTPa-IPV/Hib vaccine administered as
                        a three-dose primary vaccination course in healthy Korean infants: phase III,
                        randomized study. Hum Vaccin Immunother. 2019;15(2):317-326.
                    </p>
                    <p class="desc">
                        Active-controlled phase III study of an egg-cultivated quadrivalent inactivated
                        split-virion influenza vaccine (GC3110A) in healthy Korean children aged
                        6-35 months. Vaccine. 2021 Apr 8;39(15):2103-2109 Diagnostic Impact of
                        Clinical Manifestations of Group A Streptococcal Pharyngitis. Infect Chemother.
                        2021 Sep;53(3):553-556
                    </p>
                    <button href="javascript:;" class="weekly__calendar-btn type2">더보기 +</button>
                </div>
                <div class="box bt" id="news">
                    <div class="profile__tit">언론보도</div>
                    <ul class="profile__list">
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                        <li class="profile__item">
                            <div class="tit">2023.12.18</div>
                            <div class="desc">[경남신문] 100일간 '콜록 콜록' 백일해···백신만이 살길이다</div>
                        </li>
                    </ul>
                    <button href="javascript:;" class="weekly__calendar-btn type2">더보기 +</button>
                </div>
            </div>
        </div>
        <div class="profile__rnb">
            <ul class="profile__rnb-list">
                <li class="profile__rnb-item diagnosis" :class="{ on: activeSection === 'diagnosis' }">
                    <a href=" javascript:;" @click.prevent="scrollTo('diagnosis')">진료일정</a>
                </li>
                <li class="profile__rnb-item education" :class="{ on: activeSection === 'education' }">
                    <a href="javascript:;" @click.prevent="scrollTo('education')">학력&#183;경력</a>
                </li>
                <li class="profile__rnb-item book" :class="{ on: activeSection === 'book' }">
                    <a href=" javascript:;" @click.prevent="scrollTo('book')">주요논문</a>
                </li>
                <li class="profile__rnb-item news" :class="{ on: activeSection === 'news' }">
                    <a href=" javascript:;" @click.prevent="scrollTo('news')">언론보도</a>
                </li>
            </ul>
        </div>
    </div>
        </div>
    </div>
    <Footer></Footer>
</template>



<script>
 import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Footer from '@/components/common/Footer.vue';
    // import { gsap, ScrollTrigger } from "gsap/all";
    export default {
        components: {
            Header,
            LocationBar,
            Footer
        },
        data() {
            return {
                activeSection: '',
            };
        },
        methods: {
            scrollTo(id) {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            },
            handleIntersection(entries) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.activeSection = entry.target.id;
                    }
                });
            },
        },
        mounted() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5, // 섹션의 절반 이상이 보일 때 active 설정
            };

            const observer = new IntersectionObserver(this.handleIntersection, options);

            const sections = document.querySelectorAll('#diagnosis, #education, #book, #news');
            sections.forEach(section => {
                observer.observe(section);
            });
        },

    };
</script>