<template>
    <div class="login">
        <div class="sub-text dp2">홈페이지의 다양한 정보와 맞춤 서비스를 이용하시려면 로그인이 필요합니다.</div>
        <div class="login__inner">
            <div class="contents login__input">
                <label class="login__id">
                    <input type="text" placeholder="아이디를 입력해 주세요." maxlength="12" ref="id" @keyup.enter="loginSubmit" v-model="memId">
                </label>
                <label class="login__password">
                    <input type="password" placeholder="비밀번호를 입력해 주세요." maxlength="12" ref="pw" @keyup.enter="loginSubmit" v-model="memPwd">
                    <div class="icon" @click="pwLook" ref="eye" title="비밀번호 보기"></div>
                </label>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue" @click="loginSubmit">로그인</a></div>
                <ul class="login__list">
                    <li class="login__item">
                        <router-link :to="`/member/idInquiry.do`">아이디 찾기</router-link>
                    </li>
                    <li class="login__item">
                        <router-link :to="`/member/pwInquiry.do`">비밀번호 찾기</router-link>
                    </li>
                    <li class="login__item">
                        <router-link :to="`/member/join.do`">회원가입</router-link>
                    </li>
                </ul>
            </div>
            <div class="contents login__sns">
                <p>SNS 계정으로 로그인하기</p>
                <div class="btn-wrap">
                    <a href="javascript:;" class="btn naver"><span id="naver_id_login"></span> 네이버 로그인</a>
                    <!-- <a href="javascript:;" class="btn kakao" @click="kakaoLogin">카카오 로그인</a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // redirectUrl: this.$store.state.redirectUrlStore.redirectUrl,
            memId: '',
            memPwd: '',
        }
    },
    mounted() {
        // this.$store.state.redirectUrlStore.redirectUrl = null;

        // 네아로
        var naver_id_login = new window.naver_id_login(this.naverId, location.origin + "/member/login.do");
        var state = naver_id_login.getUniqState();
        naver_id_login.setButton("green", 3, 48);
        naver_id_login.setDomain(location.origin);
        naver_id_login.setState(state);
        naver_id_login.init_naver_id_login();
        // 네아로 접근시
        if (this.$route.hash != '') {
            this.naverApiLogin();
            this.$router.replace('/member/login.do')
        }
    },
    methods: {
        pwLook(){
            const pw = this.$refs.pw;
            const eye = this.$refs.eye;
            pw.classList.toggle('on');
            if(pw.classList.contains('on')){
                pw.setAttribute('type', 'text');
                eye.setAttribute('title', '비밀번호 숨김');
            }else{
                pw.setAttribute('type', 'password');
                eye.setAttribute('title', '비밀번호 보기');
            }
        },
        loginSubmit() {
            this.memId = this.memId.trim()

            const reg = /^(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_-])(?=.*[0-9]).{8,12}$/; // 영문, 숫자, 특수문자 인식

            if (this.memId == '') {
                alert('아이디를 입력해주세요.')
                return
            }
            if (this.memPwd == '') {
                alert('비밀번호를 입력해주세요.')
                return
            }
            if(!reg.test(this.memPwd)){
                alert('영문,숫자,특수문자 조합 8~12자리 사용 가능합니다.');
                return
            }

            const params = new URLSearchParams();
            params.append('memId', this.memId);
            params.append('memPwd', this.memPwd);
            params.append('memPwEnc', btoa(this.memPwd));
            this.axios
                .post(this.api.member.login, params)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.result.status === true) {
                            this.setMyInfoStore();
                        } else {
                            alert(res.data.result.message);
                            // 휴면 계정
                            // if(res.data.result.code == 'E_WRONG_Z'){
                            //     // this.$store.state.memberStore.memId = this.memId;
                            //     const find = {
                            //         id: this.memId
                            //     }
                            //     this.$store.commit('setFind', find);
                            //     this.$router.replace('/member/sleep.do');
                            // 임시비밀번호
                            if(res.data.result.code == 'E_WRONG_PW_TEMP'){
                                const find = {
                                    id: this.memId,
                                    pw: this.memPwd
                                }
                                this.$store.commit('setFind', find);
                                this.$router.push('/member/pwChange.do');
                            // 3개월 비밀번호 미변경
                            }else if(res.data.result.code == 'E_WRONG_PW_DATE'){
                                const find = {
                                    id: this.memId,
                                    pw: this.memPwd
                                }
                                this.$store.commit('setFind', find);
                                this.$router.push('/member/lastPwChange.do');
                            }
                        }
                    } else {
                        alert('다시 시도해주시길 바랍니다.')
                        location.reload();
                    }
                })
                .catch((err) => console.log(err));
        },
        // hash의 accessToken 추출
        accessToken() {
            const hash = this.$route.hash;
            const result = hash.substring(hash.indexOf("access_token") + 13, hash.indexOf("&state"));
            return result
        },
        // accessToken으로 naverId로 로그인
        naverApiLogin() {
            this.axios.get('/api/naverInfo.do', {
                params: {
                    access_token: this.accessToken()
                }
            })
                .then(res => {
                    const data = res.data.response;
                    let naverId = data.id;
                    const params = new URLSearchParams();
                    params.append('memId', naverId);
                    params.append('memPwd', naverId);
                    params.append('memPwEnc', btoa(naverId));
                    params.append('memType', 'B');
                    this.axios
                        .post(this.api.member.login, params)
                        .then((res) => {
                            if (res.status === 200) {
                                if (res.data.result.status === true) {
                                    this.setMyInfoStore();
                                } else {
                                    alert(res.data.result.message);
                                    // if(res.data.result.code == 'E_WRONG_Z'){
                                    //     // this.$store.state.memberStore.memId = naverId;
                                    //     this.$router.replace('/member/sleep.do');
                                    // }
                                }
                            } else {
                                alert('다시 시도해주시길 바랍니다.')
                                location.reload();
                            }
                        })
                        .catch((err) => console.log(err));
                })
        },

        // 카카오 login
        kakaoLogin() {
            window.Kakao.Auth.login({
                scope: 'account_email, gender, birthday, name, birthyear, phone_number',
                success: this.getIdLogin,
            });
        },
        // 카카오 ID 획득 후 로그인
        getIdLogin(authObj) {
            //console.log(authObj); // token 정보
            window.Kakao.API.request({
                url: '/v2/user/me',
                success: res => {
                    console.log(res)
                    let kakaoId = res.id;
                    const params = new URLSearchParams();
                    params.append('memId', kakaoId);
                    params.append('memPwd', kakaoId);
                    params.append('memPwEnc', btoa(kakaoId));
                    params.append('memType', 'B');
                    this.axios
                        .post(this.api.member.login, params)
                        .then((res) => {
                            if (res.status === 200) {
                                if (res.data.result.status === true) {
                                    this.setMyInfoStore();
                                } else {
                                    alert(res.data.result.message);
                                    // if(res.data.result.code == 'E_WRONG_Z'){
                                    //     // this.$store.state.memberStore.memId = kakaoId;
                                    //     this.$router.replace('/member/sleep.do');
                                    // }
                                }
                            } else {
                                alert('다시 시도해주시길 바랍니다.')
                                location.reload();
                            }
                        })
                        .catch((err) => console.log(err));
                },
                fail: error => {
                    console.log(error);
                }
            })
        },
        setMyInfoStore() {
            this.axios.get(this.api.member.info)
                .then(res => {
                    this.$store.state.myInfoStore = res.data;
                    this.$store.commit('setStatus', true);
                    if(this.$store.getters.getPrevUrl){
                        location.href = this.$store.getters.getPrevUrl;
                    }else{
                        this.$router.replace('/');
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    },
}
</script>

<style scoped>
.leftmenu {
    position: relative;
}
.leftmenu i{
    position: absolute;
    cursor: pointer;
}
</style>