<template>
  <!-- 호스피스완화센터 -->
  <div class="tab-menu__wrap">
    <div class="tab-menu type02">
      <ul class="tab-menu__list">
        <li
          class="tab-menu__item"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ on: selectedTab === index }"
          @click="selectTab(index)"
        >
          <button type="button" class="tab-menu__btn">{{ tab }}</button>
        </li>
      </ul>
    </div>
  </div>
  <div class="btn-wrap contents right clinic">
    <router-link
      to="/hospital/department-doctor/centerClinic.do"
      class="btn mint"
    >
      <img src="/resource/icon/depart_potal_icon.png" alt="이미지" />전체
      센터&클리닉</router-link
    >
  </div>
  <div class="info-text contents" v-if="selectedTab === 0">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">호스피스 완화의료의 정의</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <div class="depth__wrap">
              <div class="depth__text">
                <p>
                  호스피스․완화의료는 치료에 반응하지 않는 환자와 그 가족들이
                  남은 생애동안 질적인 삶을 유지 할 수 있도록 신체적, 사회적
                  영적인 문제를 해결 또는 상담하고, 사별간호를 포함한 아름다운
                  마무리를 돕고자 각 분야의 전문가와 자원봉사자가 함께하는
                  전인적인 돌봄을 말합니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="depth__wrap depth02 mt20">
          <div class="depth__text">
            <div class="text-box type5">
              <ul class="text-box__list">
                <li class="text-box__item">
                  <h3 class="tit">운영철학</h3>
                  <div class="desc">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            모든 인간을 존엄한 존재로서 존중하며 대상자의 생의
                            마지막 과정에서 경험하게 되는 신체적, 정신적, 영적,
                            사회적 및 경제적 고통을 함께 나눌 수 있도록 돕는다.
                          </p>
                        </div>
                      </div>
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            죽음을 삶의 한 과정으로 받아들이고 삶을 연장시키거나
                            단축시키지 않으며 마지막 순간까지 가족처럼 섬기고
                            돌본다.
                          </p>
                        </div>
                      </div>
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            환자 개인의 종교를 존중하며, 영원한 삶을 평안하게
                            준비하도록 돕는다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="text-box__item">
                  <h3 class="tit">운영원칙</h3>
                  <div class="desc">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            전문 의료인들의 선도적인 연구와 협력을 통해 의료의
                            질을 유지하며, 새로운 의학분야의 발전을 도모하는
                            대표적인 의료기관이 된다.
                          </p>
                        </div>
                      </div>
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            가톨릭교회의 정신을 본받아 인간생명의 존엄성이
                            진료활동 전반에 걸쳐 존중되도록 하고, 특히 가난하고
                            소외된 이들에게 각별한 관심을 가지고 돌보며, 병을
                            치유하시는 분이 하느님이심을 알게 한다.
                          </p>
                        </div>
                      </div>
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            이를 위해 우리는 각자 맡은 분야에서 전문성을 기르고
                            존중하며, 다학제간 협력정신을 바탕으로 최상의
                            의료서비스를 제공한다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info-text contents" v-if="selectedTab === 1">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">호스피스 완화의료팀</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text hospis_img_wrap contents">
            <img
              src="/resource/img/hospis_team_pc-1.png"
              alt="호스피스완화의료팀"
              class="hosipis_team_pc"
            />
            <img
              src="/resource/img/hospis_team_mo-1.png"
              alt="호스피스완화의료팀"
              class="hosipis_team_mo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contents" v-if="selectedTab === 2">
    <div class="info-text">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">상담 및 입원 안내</h4>
        </div>
      </div>
    </div>
    <div class="depth__wrap depth02 mt20">
      <div class="depth__text">
        <div class="text-box type5">
          <ul class="text-box__list">
            <li class="text-box__item">
              <h3 class="tit">입원대상</h3>
              <div class="desc">
                <div class="depth__text">
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>말기암환자</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">입원절차</h3>
              <div class="desc">
                <ul class="step-textbox type02 step_hospis">
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">주치의<br>호스피스 의뢰</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step01.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">호스피스<br>의사동의</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step02.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">호스피스 병동<br>면담 및 안내</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step03.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">입원</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step04.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">타기관 입원절차</h3>
              <div class="desc">
                <ul class="step-textbox step_hospis">
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">타기관 주치의 소견서</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step05.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">본원 호스피스<br>외래방문(혈액종양내과)</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step06.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">호스피스 의사동의</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step02.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">호스피스 병동<br class="mo"> 면담 및 안내</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step03.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                   <li class="step-textbox__item">
                    <div class="step-textbox__item-wrap">
                      <div class="step-textbox__title">
                        <h3 class="depth02__title">입원</h3>
                        <img
                          src="/resource/icon/hospis_clinic-step04.png"
                          class="step-textbox__title--icon"
                          alt="이미지"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="depth__wrap info-reference step_bottom">
                  <div class="depth__bullet">※</div>
                  <div class="depth__text">
                    <h4 class="depth03__title">TEL. 270-1650(문의사항에 대해 24시간 안내해 드립니다.)</h4>
                  </div>
                  </div>
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">상담 및 입원서류 준비</h3>
              <div class="desc">
                <div class="depth__text">
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>말기환자임을 나타내는 의사소견서(진료의뢰서)</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>
                        의무기록사본 : 최근 검사결과(혈액검사, MRI 판독 등),
                        최근 약 처방 내역
                      </p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>최근 촬영한 MRI 등 영상검사 CD</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">호스피스 완화의료 서비스 안내</h3>
              <div class="desc">
                <div class="depth__text">
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>포괄적인 초기평가 및 돌봄계획수립과 상담</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>통증 및 신체증상 완화</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>심리, 사회, 영적지지 및 상담</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>음악, 미술요법 외 프로그램</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>임종 준비 및 돌봄 지원</p>
                    </div>
                  </div>
                  <div class="depth__wrap">
                    <div class="depth__bullet">
                      <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                      <p>사별가족 돌봄 서비스</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="contents" v-if="selectedTab === 3">
    <div class="info-text">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">프로그램</h4>
        </div>
      </div>
    </div>
    <div class="depth__wrap depth01 mt20">
      <div class="depth__text">
        <div class="text-box type5">
          <ul class="text-box__list">
            <li class="text-box__item">
              <h3 class="tit">경락요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram01.png" class="l-img" style="float: left;" alt="이미지">
                손이나 인체 각 부위를 사용하여 쓰다듬고 주무르며 마사지를 통해
                피로 및 혈액순환 개선으로 부종이 완화되며, 스트레스 감소되어
                심신의 안녕감 증대에 도움을 줍니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">음악요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram02.jpg" class="l-img" style="float: left;" alt="이미지">
                음악을 듣고 신체와 정신을 회복, 유지하며, 기운을 향상시키는
                방법으로 마음의 안도감과 감정적 스트레스를 완화시키는
                활동입니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">미술요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram03.JPG" class="l-img" style="float: left;" alt="이미지">
                오랜 투병으로 지친 환자에게 자신의 마음을 표현할 수 있도록 하고,
                힘든 느낌, 생각들을 미술활동을 통해 표출함으로써 마음의 안정에
                도움을 줍니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">독서요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram04.jpg" class="l-img" style="float: left;" alt="이미지">
                책을 통해 정신적 건강을 증진시키는 치료방법으로 환자 스스로
                자신의 문제를 파악하고 해결하는데 도움이 되고, 유익한 도서를
                매개체로 내재된 생각이나 심리적 질병 치료에 도움을 줍니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">아로마요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram05.JPG" class="l-img" style="float: left;" alt="이미지">
                식물의 향과 약효를 이용해서 몸과 마음의 균형을 회복시켜 면역력을
                개선시키고, 환자의 불안, 우울감, 수면증진, 생리적 스트레스 반응
                완화에 도움을 줍니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">원예요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram06.jpg" class="l-img" style="float: left;" alt="이미지">
                꽃을 피우고 식물을 키우는 활동을 통해 몸과 마음의 병을 치유하고
                식물을 보고 좋은 향기를 맡으며 정서적 안정을 줍니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">다도요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram07.jpg" class="l-img" style="float: left;" alt="이미지">
                다양한 종류의 차를 마시면서 자유로운 주제로 이야기를 나누고
                공감을 통해 마음을 치유 받을 수 있습니다.
              </div>
            </li>
            <li class="text-box__item">
              <h3 class="tit">전환요법</h3>
              <div class="desc">
                <img src="/resource/img/hospiprogram08.JPG" class="l-img" style="float: left;" alt="이미지">
                그림과 단어가 표시되어 있는 감정카드를 통해 자신의 감정을
                지각하고, 내재된 마음 표현을 통한 상담기법을 접목할 수 있습니다.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="contents" v-if="selectedTab === 4">
    <div class="info-text">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">주요시설</h4>
        </div>
      </div>
    </div>

    <div class="funeral-swiper__wrap hospisCenter_swiper">
      <a href="javascript:;" title="팝업닫기" class="funeral-swiper__close"></a>
      <div class="funeral-swiper swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="/resource/img/hospice01.jpg" alt="가족실" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice02.jpg" alt="기도실" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice03.JPG" alt="목욕실" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice04.JPG" alt="병실" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice05.jpg" alt="병실전경" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice06.JPG" alt="사별가족모임" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice07.jpg" alt="상담실" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice08.jpg" alt="옥외정원" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice09.jpg" alt="옥외정원2" />
          </div>
          <div class="swiper-slide">
            <img src="/resource/img/hospice010.jpg" alt="임종실" />
          </div>
        </div>
        <div class="pagination_wrap">
          <p id="image-alt-text"></p>
          <div class="swiper-pagination_hospis_page"></div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div thumbsslider="" class="funeral-sub-swiper swiper hospisCenter_swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="/resource/img/hospice01.jpg" alt="가족실" />
          <p class="thum_title">가족실</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice02.jpg" alt="기도실" />
          <p class="thum_title">기도실</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice03.JPG" alt="목욕실" />
          <p class="thum_title">목욕실</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice04.JPG" alt="병실" />
          <p class="thum_title">병실</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice05.jpg" alt="병실전경" />
          <p class="thum_title">병실전경</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice06.JPG" alt="사별가족모임" />
          <p class="thum_title">사별가족모임</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice07.jpg" alt="상담실" />
          <p class="thum_title">상담실</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice08.jpg" alt="옥외정원" />
          <p class="thum_title">옥외정원</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice09.jpg" alt="옥원정원2" />
          <p class="thum_title">옥외정원2</p>
        </div>
        <div class="swiper-slide">
          <img src="/resource/img/hospice010.jpg" alt="임종실" />
          <p class="thum_title">임종실</p>
        </div>
      </div>
      <!-- <a href="javascript:;" class="funeral-sub-swiper__more"><p>이미지 더보기</p></a> -->
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      tabs: [
        "호스피스 완화의료의 정의",
        "호스피스완화의료팀",
        "상담 및 입원안내",
        "프로그램",
        "주요시설",
      ],
      selectedTab: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
    updateAltText() {
      const activeSlide = document.querySelector(
        ".funeral-swiper .swiper-slide-active img"
      );
      const altText = activeSlide ? activeSlide.alt : "";
      document.getElementById("image-alt-text").textContent = altText;
    },
    initializeSwiper() {
      this.$nextTick(() => {
        var swiper2 = new Swiper(".funeral-sub-swiper", {
          slidesPerView: 4,
          loop: true,
          freeMode: true,
          loopedSlides: 5,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          allowTouchMove: false,
        });

        var swiper = new Swiper(".funeral-swiper", {
          spaceBetween: 10,
          loop: true,
          loopedSlides: 5,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination_hospis_page",
            type: "fraction",
          },
          thumbs: {
            swiper: swiper2,
          },
          on: {
            init: () => {
              this.updateAltText();
            },
            slideChangeTransitionEnd: () => {
              this.updateAltText();
            },
          },
        });

        // jQuery 관련 코드
        $(function () {
          $(".funeral-sub-swiper .swiper-slide").click(function () {
            $(".funeral-swiper__wrap").addClass("on");
          });
          $(".funeral-swiper__close").click(function () {
            $(".funeral-swiper__wrap").removeClass("on");
          });

          $(".funeral-sub-swiper__more").click(function () {
            if ($(".funeral-sub-swiper").hasClass("on") == true) {
              $(".funeral-sub-swiper").removeClass("on");
            } else {
              $(".funeral-sub-swiper").addClass("on");
            }
          });
        });
      });
    },
  },
  watch: {
    selectedTab(newVal) {
      if (newVal === 4) {
        this.initializeSwiper();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.selectedTab === 4) {
        this.initializeSwiper();
      }
    });
  },
};
</script>
