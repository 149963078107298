<template>
    <div class="translate_con">
        <div class="contents">
            <div class="translate_top">
                <div class="text-box type1 top-title">
                    <img src="/resource/icon/trans_icon.png" alt="이미지">
                    <h3 class="depth02__title">외국인 환자의 원할한 진료 서비스 제공을 위해 통역서비스를 제공하고 있습니다.<br>통역이 필요한 경우 중앙안내로 신청하면 통역봉사자와 연결이 가능합니다.</h3>
                </div>
            </div>
        </div>
        <div class="contents">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">대상</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet">
                                            <span class="bullet03"></span>
                                        </div>
                                        <div class="depth__text">
                                            <p>영어, 중국어, 베트남어, 필리핀어, 인도네시아어</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <div class="contents volunteerActive">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">신청</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet">
                                            <span class="bullet03"></span>
                                        </div>
                                        <div class="depth__text">
                                            <p>중앙안내 270-1080</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="depth__wrap info-reference">
                        <div class="depth__bullet">※</div>
                        <div class="depth__text">
                            <h4 class="depth03__title">원할한 통역봉사자 연결을 위해 예약환자는 진료일 1일전, 당일 접수환자는 진료 3시간 전에 신청해 주시기 바랍니다.</h4>
                        </div>
                    </div>

                    <div class="depth__wrap depth03 depth03_type02">
                        <div class="depth__text">
                            <p>For non-Korean speaking patients, we offer translation services.</p>
                            <p>If you need an interpreter, please contact the Central Information Desk in the first floor lobby.</p>
                            <p>Tel. 270-1080</p>
                        </div>
                    </div>

                    <div class="depth__wrap depth03 depth03_type02">
                        <div class="depth__text">
                            <p>为了便于外国患者医诊,本院免费提供翻译服务。</p>
                            <p>需要翻译者请到中央咨询处。</p>
                            <p>Tel. 270-1080</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    export default {
       
    }
</script>