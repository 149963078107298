<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">외래 예약</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">종합검진예약</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">일반검진예약</button></li>
        </ul>
    </div>
  
    <div class="contents">
      <div class="booking-check-wrap">
        <div class="booking-check">
          <div class="booking-check__line-wrap">
            <span class="booking-check__line"></span>
          </div>
          <div>
            <ul>
              <li v-for="(reservation, index) in reservations" :key="index">
                예약{{ reservation }}
              </li>
            </ul>
          </div>
          <!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
          <ul class="booking-check__list">
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
              <li class="booking-check__item">
                <div class="booking-check__item--con">
                  <div class="doctor-list__item">
                      <div class="doctor-list--img">
                          <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                      </div>
                      <div class="doctor-list--con">
                          <div class="doctor-list--text">
                              <h3>마상혁<sub>소아청소년과</sub></h3>
                              <h4>주진료분야</h4>
                              <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                          </div>
                      </div>
                  </div>
                </div> 
                <span class="booking-check__item--mark"></span>
                <div class="booking-check__item--day">
                  <h4 class="booking-check__item--day-text">2024.05.07 (목) 14:20</h4>
                  <div class="btn-wrap"><a href="javascript:;" class="btn">예약변경</a><a href="javascript:;" class="btn">예약취소</a></div>
                </div>
              </li>
          </ul>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
    // import gsap from 'gsap';
    export default {
        data() {
            return {
              reservations: [],
              apiEndpoint: 'https://api.example.com/mypage/select',
              fromdd: '', // 시작일
              todd: '' // 종료일
            };
        },
        methods: {
          fetchReservations() {
              const fromdd = this.$store.state.reserveStore.reserve.otpt_orddd;
              const todd = this.$store.state.reserveStore.reserve.otpt_orddd;
  
              // API 호출
              this.axios.get(this.api.mypage.select, {
                  params: {
                      fromdd: fromdd,
                      todd: todd
                  }
              })
              .then(res  => {
                  console.log(res.data); // API 응답 데이터 처리
                  this.reservations = res.data;
              })
              .catch(err => {
                console.error('예약 조회 오류:', error);
                alert('예약 조회 중 오류가 발생했습니다.');
              });
          },
         
        },
        mounted() {
          this.fetchReservations();
  
          window.addEventListener('scroll', function() {
              var items = document.querySelectorAll('.booking-check__item');
        
              items.forEach(function(item) {
                  var rect = item.getBoundingClientRect();
                  var screenCenter = window.innerHeight / 1.3;
                  var itemCenter = rect.top + rect.height / 1.3;
                  var viewportBottom = window.scrollY + window.innerHeight;
                  var isBelowViewport = rect.bottom > viewportBottom;
                  if (Math.abs(itemCenter - screenCenter) < rect.height) {
                      item.classList.add('on');
                  }else {
                    if (isBelowViewport) {
                      item.classList.remove('on');
                    }
                  }
              });
          });
  
          let visualArea = gsap.timeline({
              scrollTrigger: {
                  trigger: '.booking-check-wrap',
                  pin: '.booking-check-wrap',
                  start: 'top top',
                  end: '+=3000',
                  scrub: 1
              }
          });
  
          visualArea
          .addLabel('start')
              .to('.booking-check__line',{height: '100%',},'start')
              .to('.booking-check',{transform: 'translateY(calc(-100% + 100dvh))',},'start')
        }
    };
  </script>
  
  <style>
    @import 'aos/dist/aos.css';
    /* 스타일링을 원하는 경우 여기에 작성 */
  </style>