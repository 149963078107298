<template>
  <div class="myHealthCheckup_reservation myHealthCheckup_reservation_detail contents">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet">
        <span class="bullet01"></span>
      </div>
      <div class="depth__text">
        <h4 class="depth01__title title">검진정보</h4>
      </div>
    </div>

    <div class="table-cont mt0">
      <ul class="table-list type02">
        <li class="table-item">
          <div class="item tit">검진일자</div>
          <div class="item">2024.06.30(수)</div>
        </li>
        <li class="table-item">
          <div class="item tit">검진구분</div>
          <div class="item">건강증진센터</div>
        </li>
        <li class="table-item">
          <div class="item tit">검진명</div>
          <div class="item">여성정밀코스</div>
        </li>
        <li class="table-item">
          <div class="item tit">추가선택검사</div>
          <div class="item">스트레스 검사, PET-CT (상반신)</div>
        </li>
      </ul>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet">
          <span class="bullet01"></span>
        </div>
        <div class="depth__text">
          <h4 class="depth01__title title">결과조회</h4>
        </div>
      </div>
      <div class="checkup_detail_btn_wrap">
        <button class="checkup_detail_btn_mo" :class="{ active: isActiveMo }" @click="toggleDetails">종합판정 및 소견</button>
        <ul class="checkup_detail_btn_list" :class="{ active: isActiveMo }">
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 0 }"
            @click="setActive(0)">
            <a href="javascript:;">종합판정 및 소견</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 1 }"
            @click="setActive(1)">
            <a href="javascript:;">신체계측</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 2 }"
            @click="setActive(2)">
            <a href="javascript:;">혈압&심전도</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 3 }"
            @click="setActive(3)">
            <a href="javascript:;">안과</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 4 }"
            @click="setActive(4)">
            <a href="javascript:;">청력</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 5 }"
            @click="setActive(5)">
            <a href="javascript:;">X-선</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 6 }"
            @click="setActive(6)">
            <a href="javascript:;">구강</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 7 }"
            @click="setActive(7)">
            <a href="javascript:;">폐기능/객담</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 8 }"
            @click="setActive(8)">
            <a href="javascript:;">소변/분변</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 9 }"
            @click="setActive(9)">
            <a href="javascript:;">혈액형</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 10 }"
            @click="setActive(10)">
            <a href="javascript:;">골다공증</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 11 }"
            @click="setActive(11)">
            <a href="javascript:;">일반혈액</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 12 }"
            @click="setActive(12)">
            <a href="javascript:;">당뇨</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 13 }"
            @click="setActive(13)">
            <a href="javascript:;">이상지질</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 14 }"
            @click="setActive(14)">
            <a href="javascript:;">간기능</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 15 }"
            @click="setActive(15)">
            <a href="javascript:;">췌장기능</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 16 }"
            @click="setActive(16)">
            <a href="javascript:;">전해질</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 17 }"
            @click="setActive(17)">
            <a href="javascript:;">신장기능</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 18 }"
            @click="setActive(18)">
            <a href="javascript:;">통풍</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 19 }"
            @click="setActive(19)">
            <a href="javascript:;">갑상선기능</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 20 }"
            @click="setActive(20)">
            <a href="javascript:;">간염</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 21 }"
            @click="setActive(21)">
            <a href="javascript:;">풍진</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 22 }"
            @click="setActive(22)">
            <a href="javascript:;">류마티스인자</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 23 }"
            @click="setActive(23)">
            <a href="javascript:;">성적전파질환</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 24 }"
            @click="setActive(24)">
            <a href="javascript:;">종양표지자</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 25 }"
            @click="setActive(25)">
            <a href="javascript:;">비타민</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 26 }"
            @click="setActive(26)">
            <a href="javascript:;">호르몬</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 27 }"
            @click="setActive(27)">
            <a href="javascript:;">위</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 28 }"
            @click="setActive(28)">
            <a href="javascript:;">대장</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 29 }"
            @click="setActive(29)">
            <a href="javascript:;">상복부초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 30 }"
            @click="setActive(30)">
            <a href="javascript:;">갑상선초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 31 }"
            @click="setActive(31)">
            <a href="javascript:;">경동맥초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 32 }"
            @click="setActive(32)">
            <a href="javascript:;">전립선초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 33 }"
            @click="setActive(33)">
            <a href="javascript:;">경두개<br /> 초음파 혈류</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 34 }"
            @click="setActive(34)">
            <a href="javascript:;">심장초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 35 }"
            @click="setActive(35)">
            <a href="javascript:;">운동부하<br /> 심전도</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 36 }"
            @click="setActive(36)">
            <a href="javascript:;">활동중 심전도</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 37 }"
            @click="setActive(37)">
            <a href="javascript:;">유방단순촬영<br /> 유방초음파</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 38 }"
            @click="setActive(38)">
            <a href="javascript:;">부인과</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 39 }"
            @click="setActive(39)">
            <a href="javascript:;">CT</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 40 }"
            @click="setActive(40)">
            <a href="javascript:;">MRI,A</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 41 }"
            @click="setActive(41)">
            <a href="javascript:;">양전자 방출<br /> 단층촬영(PET-CT)</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 42 }"
            @click="setActive(42)">
            <a href="javascript:;">관상동맥심장질환<br /> 발생 위험도</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 43 }"
            @click="setActive(43)">
            <a href="javascript:;">대사증후군</a>
          </li>
          <li 
            class="checkup_detail_btn_item" 
            :class="{ active: activeIndex === 44 }"
            @click="setActive(44)">
            <a href="javascript:;">추가선택</a>
          </li>
        </ul>
      </div>

      <div class="btn-wrap center contents">
        <a href="javascript:;" class="btn gray">목록</a>
      </div>
    </div>

    <!-- 버튼에 따라 추가되는 아이템들 -->
    <!-- 건강검진종합평가서 -->
    <div class="contents checkup_detail checkup_detail01">
      <h3 class="checkup_detail01_tit">건강진단결과종합평가서</h3>
      <div class="checkup_detail01_con">
        <ul class="checkup_member_info_list">
          <li class="checkup_member_info_item">
            <p>검진일시&nbsp;:&nbsp;</p>
            <h5>2024.06.30.</h5>
          </li>
          <li class="checkup_member_info_item">
            <h5>남 26세</h5>
          </li>
          <li class="checkup_member_info_item">
            <p>No&nbsp;:&nbsp;</p>
            <h5>001571264</h5>
          </li>
          <li class="checkup_member_info_item">
            <p>이름&nbsp;:&nbsp;</p>
            <h5>홍길동</h5>
          </li>
        </ul>
        <div class="contents mt">
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
              <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            정확한 진단 또는 질병의 치료를 위해 빠른 시일 내에
                            반드시 진료 또는 정밀검사가 필요한 소견입니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrap table-type1 mt20 scroll" :class="{ 'on': isActive }" @click="addClass">
            <div class="scroll">
              <table>
                <caption class="blind">
                  건강진단결과종합평가서 
                </caption>
                <colgroup>
                  <col style="width: 80%" />
                  <col style="width: 20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>소견 및 권고사항</th>
                    <th>진료과</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: left">1. 유방 종괴 : 좌측 유방 2.7cm 크기의 종괴가 관찰됩니다.</td>
                    <td>외과</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">2. 백혈구 이상증가</td>
                    <td>
                      혈액종양내과
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="contents mt">
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
              <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            정확한 진단 또는 질병의 치료를 위해 가까운 기간 (1개월)내에 진료 또는 추적검사가 필요한 소견입니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrap table-type1 mt20 scroll" :class="{ 'on': isActive2 }" @click="addClass2">
            <div class="scroll">
              <table>
                <caption class="blind">
                  건강진단결과종합평가서 
                </caption>
                <colgroup>
                  <col style="width: 80%" />
                  <col style="width: 20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>소견 및 권고사항</th>
                    <th>진료과</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: left">1. 유방 종괴 : 좌측 유방 2.7cm 크기의 종괴가 관찰됩니다.</td>
                    <td>외과</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">2. 백혈구 이상증가</td>
                    <td>
                      혈액종양내과
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="contents mt">
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
              <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            이상소견 또는 정상범위를 벗어난 소견입니다. 즉각적인 진료는 필요하지 않으나 주의를 기울여야 합니다. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrap table-type1 mt20 scroll" :class="{ 'on': isActive3 }" @click="addClass3">
            <div class="scroll">
              <table>
                <caption class="blind">
                  건강진단결과종합평가서 
                </caption>
                <colgroup>
                  <col style="width: 70%" />
                  <col style="width: 20%" />
                  <col style="width: 10%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>소견 및 권고사항</th>
                    <th>진료과</th>
                    <th>기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: left">1. 유방 종괴 : 좌측 유방 2.7cm 크기의 종괴가 관찰됩니다.</td>
                    <td>외과</td>
                    <td>6개월내</td>
                  </tr>
                  <tr>
                    <td style="text-align: left">2. 백혈구 이상증가</td>
                    <td>
                      혈액종양내과
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="contents">
          <h4 class="checkup_detail_tit_sub">건강검진 종합판정 1</h4>
          <div class="checkup_detail01_info_box">
            <p>홍길동님께서 실시하신 <b>[ 두산중공업 여성정밀 ]</b> 과 <b>[ 추가검사 ]</b> 대한 종합 소견은 다음과 같습니다.</p>
          </div>
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">01.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            1단계 비만 현재 비만증으로 꾸준한 식이요법, 운동요법을 통해 체중 조절하시기 바랍니다.
                        </p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">02.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            경증 간기능 이상 : 간염효소치 약간 증가. 약 한달간 금주 후 간기능 검사 경과 관찰 필요합니다.
                        </p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">03.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            고지혈 (매우높음) : 동맥경화증의 위험인자인 저밀도지단백 콜레스테롤이 매우 높습니다. 내분비 내과 진료 필요합니다.
                        </p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">04.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            1도 방실차단 : 매년 정기적 심전도 경과관찰 하시면 되겠습니다. 순환기계 증상(호흡곤란, 흉통, 심계항진, 가슴뛰는 증상, 현기증등)이 있는 경우 심장내과 상담 바랍니다.
                        </p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">05.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                           고주파 난청주의,양측 : 소음등을 피하시고 소음환경에서는 보호구 착용 철저히 바랍니다.
                        </p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">06.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            갑상선초음파 검사 상 양측결절(우:0.3cm,좌:1.25cm) : 6 개월 후 내분비내과 갑상선초음파 경과관찰 필요합니다.
                        </p>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="contents">
          <h4 class="checkup_detail_tit_sub">건강검진 종합판정 2</h4>
          <div class="checkup_detail01_info_box">
            <p>홍길동님께서 실시하신 <b>[ 두산중공업 여성정밀 ]</b> 과 <b>[ 추가검사 ]</b> 대한 종합 소견은 다음과 같습니다.</p>
          </div>
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">01.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                            대장내시경 검사 상
                        </p>
                        <p>상행결장 측방발육형종양(0.8cm) 조직검사 결과 : 관상선종(남아있음)</p>
                        <p>상행결장 측방발육형종양(4.0cm) 조직검사 결과 : 관상선종 및 고등급이형성(남아있음)</p>
                        <p>다발성 대장용종 : 상행결장(2개), 간만곡부위(2개), 횡행결장(4개), 하행결장(4개), S상결장(4개), 직장부위(5개) -> 용종 남아있음</p>
                    </div>
                </div>
                <div class="depth__wrap info-reference mt0">
                    <div class="depth__bullet">
                        <p class="small-text">02.</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">
                           위내시경 상
                        </p>
                        <p>※ 많이 아픔</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 건강검진종합평가서 -->

    <!-- 신체계측 -->
    <div class="contents checkup_detail checkup_detail02">
        <h4 class="checkup_detail_tit_sub">신체계측</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                신체계측 
              </caption>
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 19%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>신장</td>
                  <td style="text-align:left;">cm</td>
                  <td class="present_line">170</td>
                  <td>196</td>
                </tr>
                <tr>
                  <td>체중</td>
                  <td style="text-align:left;">kg</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>비만도</td>
                  <td style="text-align:left;">18.5~24.9 kg/m²</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>적정체중</td>
                  <td style="text-align:left;">kg</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>체지방률</td>
                  <td style="text-align:left;">10 ~ 20 %</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>허리둘레</td>
                  <td style="text-align:left;">cm</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <!-- <tr>
                  <td></td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              신체 변화, 체질량 지수를 계산하여 성인병의 원인이 되는 비만 여부를 알 수 있으며, 종합적인 판정에 도움을 주는 자료로 제공하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              비만은 고혈압, 당뇨병, 지방간, 고지혈증, 심장병, 뇌혈관질환, 퇴행성 관절염 등 다양한 만성 질환들을 유발시킬 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              BMI 기준 25~29.9 : 1단계 비만 / 30이상 : 2단계 비만 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 신체계측 -->

    <!-- 혈압&심전도 -->
    <div class="contents checkup_detail checkup_detail03">
        <h4 class="checkup_detail_tit_sub">혈압&심전도</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                혈압&심전도 
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 20%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>수축기 혈압</td>
                  <td>120 이하 mmHg</td>
                  <td class="present_line" style="text-align:right;">111<span class="ml10">mmHg</span></td>
                  <td style="text-align:right;">119<span class="ml10">mmHg</span></td>
                </tr>
                <tr>
                  <td>이완기 혈압</td>
                  <td>80 이하 mmHg</td>
                  <td class="present_line" style="text-align:right;">70<span class="ml10">mmHg</span></td>
                  <td style="text-align:right;">60<span class="ml10">mmHg</span></td>
                </tr>
                <tr>
                  <td>심전도</td>
                  <td>-</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              정상 혈압 : 110/70 미만 / 높은 정상혈압 : 120~139/80~89 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              경증 고혈압 : 140~159 / 중등증 고혈압 : 160~179 / 심한 고혈압 : 180이상 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              심전도는 심장에 전기적인 에너지를 체표면의 전극을 통해 기록하는 검사로 심비대, 부정맥, 심근경색 등 진단에 도움을 주며 이상이 있을 경우 추가적인 검사가 필요로 합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 혈압&심전도 -->

    <!-- 안과 -->
    <div class="contents checkup_detail checkup_detail04">
        <h4 class="checkup_detail_tit_sub">안과</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               안과 
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 20%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>시력(좌안)</td>
                  <td></td>
                  <td class="present_line">0.7</td>
                  <td>0.6</td>
                </tr>
                <tr>
                  <td>시력(우안)</td>
                  <td></td>
                  <td class="present_line">1.0</td>
                  <td>0.01</td>
                </tr>
                <tr>
                  <td>안압(좌안)</td>
                  <td>10-20 mmHg</td>
                  <td class="present_line">11</td>
                  <td>19</td>
                </tr>
                 <tr>
                  <td>안압(우안)</td>
                  <td>10-20 mmHg</td>
                  <td class="present_line">15</td>
                  <td>14</td>
                </tr>
                 <tr>
                  <td>안저</td>
                  <td>-</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              안압은 각막 안쪽의 액체압력을 측정하여 녹내장(안압상승)을 살펴보는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              안저촬영은 혈관이상, 삼출물 및 퇴행성 변화, 만성 성인병(고혈압, 당뇨)의 망막합병증 유무, 녹내장 등의 망막질환을 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 안과 -->

    <!-- 청력 -->
    <div class="contents checkup_detail checkup_detail05">
        <h4 class="checkup_detail_tit_sub">청력</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               청력 
              </caption>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 15%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>주파수 500Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                  <td>
                    <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>주파수 1000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                    <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                    </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>주파수 2000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                    </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>주파수 3000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                    </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                </tr>
                 <tr>
                  <td>주파수 4000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>주파수 6000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                      </div>
                  </td>
                </tr>
                 <tr>
                  <td>주파수 8000Hz(<span class="red__title bold">좌</span> / <span class="blue__title bold">우</span>)</td>
                  <td>0~30 dB</td>
                  <td class="present_line">
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                     </div>
                  </td>
                  <td>
                      <div class="hearing_ability_box">
                        <p class="red__title">20<span class="ml10">dB</span></p>
                        <p class="blue__title">20<span class="ml10">dB</span></p>
                     </div>
                  </td>
                </tr>
                 <tr>
                  <td>청력판정</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             소리를 듣는 능력을 평가하는 방법으로 청력장애 정도를 진단하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 청력 -->

    <!-- X-선 -->
    <div class="contents checkup_detail checkup_detail06">
        <h4 class="checkup_detail_tit_sub">X-선</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                X-선 
              </caption>
              <colgroup>
                <col style="width: 24%" />
                <col style="width: 38%" />
                <col style="width: 38%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>흉부 X-선</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type">흉부CT실시</div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type">흉부CT실시</div>
                      </div>
                  </td>
                </tr>
                 <tr>
                  <td>복부 X-선</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>퇴행성 척추증 의심</p>
                          <div class="x_line_type">복부CT실시</div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>아픔</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              흉부촬영은 폐결핵, 폐렴 등의 감염성 폐질환, 폐암 또는 폐전이 등의 종양성 폐질환을 진단하고 추적하는데 유용한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              폐암 진단, 병변의 발견 및 특성은 흉부 촬영만으로 진단이 어렵습니다. 흉부CT가 병행되어야 진단에 도움이 됩니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- X-선 -->

    <!-- 구강 -->
    <div class="contents checkup_detail checkup_detail07">
        <h4 class="checkup_detail_tit_sub">구강</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                구강
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>구강검사</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              치아우식증(충치), 치석, 치주질환 등 확인하고 최소 1~2년에 한번은 구강검진을 받는 것을 권장 드립니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 구강 -->

    <!-- 폐기능/객담 -->
    <div class="contents checkup_detail checkup_detail08">
      <h4 class="checkup_detail_tit_sub">폐기능/객담</h4>
      <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
        <div class="scroll">
          <table>
            <caption class="blind">
              폐기능/객담
            </caption>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 15%" />
              <col style="width: 30%" />
              <col style="width: 30%" />
            </colgroup>
            <thead>
              <tr>
                <th>검사항목</th>
                <th>참고치 / 단위</th>
                <th class="present_line">이번 검사결과</th>
                <th>이전 검사결과(2024 년)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: left;">노력성 폐활량 (FVC)</td>
                <td></td>
                <td class="present_line"></td>
                <td></td>
              </tr>
              <tr>
                <td style="text-align: left;">1초간 노력성 호기량(FEV1)</td>
                <td></td>
                <td class="present_line"></td>
                <td></td>
              </tr>
              <tr>
                <td style="text-align: left;">노력성 폐활량 % (FVC%)</td>
                <td>80% 이상%</td>
                <td class="present_line"></td>
                <td></td>
              </tr>
              <tr>
                <td style="text-align: left;">1초간 노력성 호기량비(FEV1%)</td>
                <td>80% 이상%</td>
                <td class="present_line"></td>
                <td></td>
              </tr>
              <tr>
                <td style="text-align: left;">FEV1/FEV %</td>
                <td>70% 이상%</td>
                <td class="present_line"></td>
                <td></td>
              </tr>
              <tr>
                <td style="text-align: left;">폐기능 판정</td>
                <td></td>
                <td class="present_line"></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
              <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            폐기능검사는 산소를 들이마시고 이산화탄소를 배출하는 호흡 과정의 기능을 검사하는 방법으로, 폐에서 가스교환과정이 잘 이루어지는지 평가하는 검사입니다. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            간질성 폐질환, 폐기종, 천식, 만성 폐쇄성 폐질환 등 폐 질환의 여부나 경증을 판단할 수 있습니다. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="table-wrap table-type1 table-typeCenter contents mt scroll" :class="{ 'on': isActive }" @click="addClass">
        <div class="scroll">
          <table>
            <caption class="blind">
              폐기능/객담
            </caption>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 15%" />
              <col style="width: 30%" />
              <col style="width: 30%" />
            </colgroup>
            <thead>
              <tr>
                <th>검사항목</th>
                <th>참고치 / 단위</th>
                <th class="present_line">이번 검사결과</th>
                <th>이전 검사결과(2024 년)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: left;">객담</td>
                <td>음성</td>
                <td class="present_line"></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
            <div class="depth__text">
              <div class="depth__wrap depth02">
                <div class="depth__text">
                  <div class="depth__wrap depth03">
                    <div class="depth__text">
                      <div class="depth__wrap">
                        <div class="depth__bullet">
                          <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                          <p>
                            객담의 비정상세포가 있는지 확인하는 검사로 기도, 기관지, 폐에서 떨어져 나온 세포와 세균, 바이러스, 이물질 등을 확인하여 비정상 세포가 확인되면 폐암을 조기발견 할 수 있습니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- 폐기능/객담 -->

    <!-- 소변/분변 -->
    <div class="contents checkup_detail checkup_detail09">
        <h4 class="checkup_detail_tit_sub">소변/분변</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                소변/분변
              </caption>
              <colgroup>
                <col style="width: 10%" />
                <col style="width: 20%" />
                <col style="width: 16%" />
                <col style="width: 27%" />
                <col style="width: 27%" />
              </colgroup>
              <thead>
                <tr>
                  <th colspan="2">검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="13">소변</td>
                  <td>요비중</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요산도</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요단백</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요당</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요케톤</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요빌리루빈</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요나이트라이트</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요잠혈</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요유로빌리노겐</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">적혈구(현미경)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">백혈구(현미경)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">상피세포(현미경)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">요검사 판정</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td rowspan="4">분변</td>
                  <td>기생충</td>
                  <td>음성</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9">원충</td>
                  <td>음성</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9">대변잠혈</td>
                  <td>음성</td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9">대변검사 판정</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              소변검사는 소변에서 배출되는 노폐물을 검출하는 검사로 비뇨기계 감염의 감별진단, 신장기능 장애, 담관질환 등의 진단에 도움이 되는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              소변에서 적혈구가 발견되는 것은 현미경적 혈뇨 소견으로 그 자체가 질병은 아니지만 원인을 찾기 위한 검사가 필요할 수도 있으며 신장질환, 요로계질환(신종양, 요결석, 사구체신염, 당뇨병, 통풍성 질환, 황달 등)시에 배설될 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              백혈구가 발견되는 경우 요로염증을 의심하는 소견으로 증상(빈뇨, 배뇨통증)이 있을 시 치료가 필요합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              요당은 당뇨병이나 신장기능 이상 시 배설될 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              단백질은 급,만성 신장염 등에서 나올수 있으나 정상인도 심한 운동이나 열성질환 등에서 일시적으로 나올 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              분변검사는 소화기관이나 소화기관에 연결되는 장기에 기생하는 기생충, 원충 감염 여부를 검사하고 치질로 인한 출혈 또는 대장이나 직장, 항문 등에서의 출혈 여부 등을 검사합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              치아우식증(충치), 치석, 치주질환 등 확인하고 최소 1~2년에 한번은 구강검진을 받는 것을 권장 드립니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              잠혈반응이 양성인 경우는 위장관 출혈이나 직장, 항문 출혈 등이 의심되므로 반드시 정밀검사를 받아야 합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              기생충, 충란, 원충 감염 시 필요에 따라 약물치료를 받으시면 됩니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 소변/분변 -->

    <!-- 혈액형검사 -->
    <div class="contents checkup_detail checkup_detail010">
        <h4 class="checkup_detail_tit_sub">혈액형검사</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                혈액형검사 
              </caption>
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 19%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ABO형</td>
                  <td>(A / B / AB / O형)</td>
                  <td class="present_line">A형</td>
                  <td>A형</td>
                </tr>
                <tr>
                  <td>Rho(D)</td>
                  <td>(Rh+형 / Rh-형)</td>
                  <td class="present_line">Rh-형</td>
                  <td>Rh-형</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              혈액형은 수혈 시 수혈자의 혈액형에 적합하지 않은 혈액을 공급 받을 시 면역반응으로 부작용 및 치명적인 사고를 초래할 수 있습니다. 수혈로 인한 사고를 피하기 위해 반드시 혈액형 검사는 정확히 하기 위한 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              ABO 혈액형에 대한 표현형이 특이한 경우 분자진단검사를 통해 ABO 혈액형 유전자를 시행해야하는 경우도 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 혈액형검사 -->

    <!-- 골다공증검사 -->
    <div class="contents checkup_detail checkup_detail011">
        <h4 class="checkup_detail_tit_sub">골다공증검사</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                골다공증검사 
              </caption>
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 19%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              골다공증, 골연화증과 같은 대부분의 대사성 골질환 환자 등의 질환 관련 검사로 골밀도의 지표를 측정하고 이를 정상인의 골밀도와 비교하여 뼈의 양이 감소되었는지를 평가하는 목적입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              폐경기 여성의 경우 에스트로겐의 감소로 골밀도가 낮아질 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              직계가족이 골다공증이 있는 경우 발생 위험이 높습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 골다공증검사 -->

    <!-- 일반혈액 -->
    <div class="contents checkup_detail checkup_detail012">
        <h4 class="checkup_detail_tit_sub">일반혈액</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                일반혈액
              </caption>
              <colgroup>
                <col style="width: 10%" />
                <col style="width: 18%" />
                <col style="width: 16%" />
                <col style="width: 28%" />
                <col style="width: 28%" />
              </colgroup>
              <thead>
                <tr>
                  <th colspan="2">검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="23">일반<br>혈액<br>검사</td>
                  <td style="text-align:left;">백혈구(WBC)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">적혈구(RBC)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">헤모글로빈(Hb)</td>
                  <td style=""></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">혈구용적치(Hct)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">혈소판(platelet) </td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">적혈구침강속도(ESR)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">MPV(평균혈소판용적)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">MCV(평균혈구용적)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td rowspan="6">백혈구<br>백분율</td>
                  <td style="text-align:left;">호중구(Neutrophil)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">임파구(Lymphocyte)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">단핵구(Monocyte)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">호산구(Eosinophil)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">호염구(Basophil)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">간상구(Band)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                  <tr>
                  <td rowspan="5">철분<br>대사<br>검사</td>
                  <td style="text-align:left;">철(Fe)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">불포화 철결합능(UIBC)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">총 철결합능(TIBC)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">저창철(Ferritin)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-left:1px solid #d9d9d9; text-align:left;">트렌스테린 포화도<br>(Transferrin saturation)</td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              일반혈액검사는 혈액 내 적혈구, 백혈구, 혈소판 등의 양 및 특성을 평가하는 검사로 전반적인 건강상태를 나타냅니다. 다양한 질환의 선별검사와 추적관찰에 이용되는 검사이며 빈혈, 세균감염, 면역기능 저하, 혈액응고 이상, 백혈병 등의 질환관련 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              철분 검사를 통해 빈혈의 원인을 알 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              혈액내 철은 골수로 이동해 헤모글로빈을 구성하며 적혈구가 생성됩니다. 철결핍성 빈혈, 출혈성 빈혈, 악성종양, 암, 자궁근종, 위궤양 등에 감소되며, 용혈성 빈혈, 재생불량성 빈혈, 백혈병, 간경변 등에 증가 될 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              Ferritin은 세포 내 철을 저장하는 단백질로 철분 고갈시 감소 됩니다. 빈혈은 아니고 낮은 Ferritin은 갑상선 기능 항진증, 비타민C 결핍 등에 나타날 수 있으며 높을 때는 철분이 지나치게 많아진 것이거나 몸의 염증, 스트레스, 감염, 악성종양 등의 경우입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 일반혈액 -->
    
    <!-- 당뇨 -->
    <div class="contents checkup_detail checkup_detail013">
        <h4 class="checkup_detail_tit_sub">당뇨</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                당뇨 
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">혈 당(Glucose)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">당화혈색소(HbA1C)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">당화혈색소(HbA1C-IFCC)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">평균혈당(Average Glucose)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              당뇨 질환의 관련 진단 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              공복혈당이 101~126미만은 공복혈당장애로 당뇨병 및 심혈관질환의 위험도가 증가할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              복혈당이 126 이상, 당화혈색소가 6.0% 이상인 경우 내분비내과에서 정밀 진단하시는게 좋습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 당뇨 -->

    <!-- 이상지질 -->
    <div class="contents checkup_detail checkup_detail014">
        <h4 class="checkup_detail_tit_sub">이상지질</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               이상지질 
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">총 콜레스테롤<br>(T. cholesterol)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">고밀도 콜레스테롤<br>(HDL-cholesterol)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">저밀도 콜레스테롤<br>(LDL-cholesterol)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">중성지방(Triglyceride)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              고지혈증, 동맥경화 관련인자, 심혈관 관련 진단검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              콜레스테롤과 중성지방은 식생활과 관계가 깊으며 특히 혈관벽이 좁아지거나 막히는 동맥경화증을 초래하며 고혈압, 신장질환, 뇌경색 등을 유발하기도 합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                               HDL 콜레스테롤은 동맥경화 예방효과가 있으며 기준치보다 높아야 하며, LDL 콜레스테롤, 총콜레스테롤, 중성지방은 동맥경화와 연관성이 깊으며 기준치 이하로 유지되도록 해야 합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                               HS-CRP, CRP는 급성기 반응물질로서 체내 염증이나 급성질환 시 증가하며 염증성 질환, 조직파괴, 괴사, 감염증에 증가 됩니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 이상지질 -->

    <!-- 간기능 -->
    <div class="contents checkup_detail checkup_detail015">
        <h4 class="checkup_detail_tit_sub">간기능</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               간기능 
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">총단백(Total Protein)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">알부민(Albumin)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">글로불린(Globulin)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">혈청지오티(SGOT)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">혈청지피티(SGPT)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">감마지티피(γ-GTP)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">알카리포스파타제(ALP)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">유산탈수효소(LDH)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">총빌리루빈(Total Bilirubin)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              간질환(지방간, 간경화, 급만성간염, 담낭염) 관련 진단하는 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              GOT, GPT는 현재 간기능 상태를 반영하는 대표적인 지표로 급성간염 시 증가합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                                r-GPT는 알콜성 간염 시 증가 합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                               총빌리루빈은 폐쇄성 황달시 증가하며, 공복시에도 단순 증가할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                               간기능의 이상은 질병의 종류나 정도에 따라 다양한 결과를 보이며 한가지 항목으로 질병을 진단하는 것이 아니라 다른 검사항목과 참조하여 종합적인 결과를 내립니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 간기능 -->

    <!-- 췌장기능 -->
    <div class="contents checkup_detail checkup_detail016">
        <h4 class="checkup_detail_tit_sub">췌장기능</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               췌장기능
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">리파제(Lipase)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">아밀라아제(Amylase)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              췌장질환의 진단이나 경과 관찰에 유용한 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              증가된 Amylase 수치는 항상 췌장의 문제를 진단하는 것은 아닙니다. 담낭 손상 등 다양한 상황에서 증가할 수 있으며 여러 검사들과 함께 진단해야 합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                                급성 췌장염에서 Amylase와 Lipase가 같이 증가하나 Lipase는 Amylase 보다 1~2주 더 시간이 걸려 회복합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 췌장기능 -->

    <!-- 전해질 -->
    <div class="contents checkup_detail checkup_detail017">
        <h4 class="checkup_detail_tit_sub">전해질</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               전해질
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 20%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">무기인(P)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">칼슘(Ca)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">나트륨(Na)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">칼륨(K)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">염소(Cl)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">마그네슘(Mg)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              인체의 대사과정을 조절하는 전해질의 평형 관련 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              신장 질환, 부갑상선 질환 등 전해질 불균형으로 발생하는 각종 질병을 진단할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 전해질 -->

    <!-- 신장기능 -->
    <div class="contents checkup_detail checkup_detail018">
        <h4 class="checkup_detail_tit_sub">신장기능</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               신장기능
              </caption>
              <colgroup>
                <col style="width: 22%" />
                <col style="width: 18%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">요소질소(BUN)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">크레아티닌(Creatinine)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">신사구체여과율(GRF)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              신장기능의 손상정도를 파악하고, 탈수, 신부전증, 신장염 등 신장질환 관련 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              BUN이 약간 증가된 경우는 탈수에 의한 경우가 많으며, Creatinine이 증가한 경우에는 신장기능이 감소한 상태일 수 있으니 진료가 필요합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 신장기능 -->

    <!-- 통풍 -->
    <div class="contents checkup_detail checkup_detail019">
        <h4 class="checkup_detail_tit_sub">통풍</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               통풍
              </caption>
              <colgroup>
                <col style="width: 22%" />
                <col style="width: 18%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">요산(Uric Acid)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              <span class="red__title">요산</span>은 혈액 내 농도가 높아지면 요산염 결정이 관절의 연골, 힘줄, 주위조직에 침착되어 관절의 염증과 통증을 유발하는 통풍성 관절염을 진단하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              증상이 동반되지 않을 시 경과관찰이 필요하지만 요산 수치 9.0mg/dl이면 신결석 위험이 높아져 적절한 치료가 필요합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 통풍 -->

    <!-- 갑상선기능 -->
    <div class="contents checkup_detail checkup_detail020">
        <h4 class="checkup_detail_tit_sub">갑상선기능</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               갑상선기능
              </caption>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 15%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">갑상선자극호르몬(TSH)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">티록신(T3)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">유리티록신(Free T4)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              갑상선과 관련된 호르몬 갑상선자극호르몬(TSH), 티록신(T3), 유리티록신(Free T4)의 혈중농도를 측정하여 갑상선의 기능을 평가하고 이상 유무를 파악하는 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              갑상선기능저하증일 경우 피로, 체중증가, 푸석푸석한 머리카락, 생리주기 불균형, 심박동수 감소, 땀이 잘 나지 않고 추위를 많이 타는 증상이 나타날 수 있습니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              갑상선기능항증진증의 경우 불안, 체중감소, 손떨림, 불면증, 심박동수 증가, 안구가 돌출되는 증상이 나타날 수 있습니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 갑상선기능 -->

    <!-- 간염-->
    <div class="contents checkup_detail checkup_detail021">
        <h4 class="checkup_detail_tit_sub">간염</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               간염
              </caption>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 15%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">B형간염 S항원(HBs Ag)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">B형간염 S항체(HBs Ab)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">B형간염 E항원(HBe Ag)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">B형간염 E항원(HBe Ab)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">C형간염 항체 (HCV Ab)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              각종 간염의 감염 여부, 항체 생성 여부를 확인하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              HAV Ab 양성 : 면역생성상태 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              HBsAg  양성 : B형간염보균자 또는 급,만성간염상태 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              HBsAb  양성 : 면역생성상태(과거감염) 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                               HCV Ab 양성 : 과거감염 또는 현재 C형간염보균상태 확인 필요
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 간염 -->

    <!-- 풍진-->
    <div class="contents checkup_detail checkup_detail022">
        <h4 class="checkup_detail_tit_sub">풍진</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               풍진
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 20%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              풍진의 감염여부를 진단이 목적이며, 가임기 여성들의 산전 검사로 유용한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              임산부 감염 시 태아가 태반을 통해 감염되며 선천성 풍진 증후군을 진단할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 풍진 -->

    <!-- 류마티스인자-->
    <div class="contents checkup_detail checkup_detail023">
        <h4 class="checkup_detail_tit_sub">류마티스인자</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll">
          <div class="scroll">
            <table>
              <caption class="blind">
               류마티스인자
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">류마티스인자(RA factor)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">C반응성 단백(CRP)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              자가면역질환인 류마티스성 관절염의 진단하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              류마티스인자(RF) 결과 만으로 류마티스관절염을 진단하지 않습니다. 기타 자가면역질환, 정상인에게도양성으로 나오는 경우가 있으며 임상적 증상과 영상검사를 종합해 최종 확인이 필요합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 류마티스인자 -->

    <!-- 성적전파질환-->
    <div class="contents checkup_detail checkup_detail024">
        <h4 class="checkup_detail_tit_sub">성적전파질환</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               성적전파질환
              </caption>
              <colgroup>
                <col style="width: 26%" />
                <col style="width: 14%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">매독반응검사(VDRL-정성)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">HIV 항체(HIV Ag/Ab Combo)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              성 매개 질환을 진단하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              매독은 음성이 정상이며 감염 시 정밀검사가 필요하며 두통, 고열, 인후통, 임파선의 종창 등의 증상이 보이고 피부나 점막에 발진이 나타날 수 있으니 진료를 요합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              에이즈(HIV)에 감염되면 우리 몸의 면역세포가 파괴되어 각종 감염에 취약해지게 되며 예방이 가장 중요한 질병입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 성적전파질환 -->

    <!-- 종양표지자-->
    <div class="contents checkup_detail checkup_detail025">
        <h4 class="checkup_detail_tit_sub">종양표지자</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               종양표지자
              </caption>
              <colgroup>
                <col style="width: 22%" />
                <col style="width: 18%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">AFP<br>(관련사항-간암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">CEA<br>(관련사항–대장암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">PSA<br>(관련사항-전립선암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">CA125<br>(관련사항-난소암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">CA19-9<br>(관련사항–췌장,담도)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">NSE<br>(관련사항-폐암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">Cyfra21-1<br>(관련사항-폐암)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              종양표지자 검사는 암 발생 시 비정상적으로 증가할 수 있는 특수한 표지자들로 암을 발견하는데 중요한 지표로 사용되나 암이 아닌 질환에서 증가할 수 있고 경미한 단순 증가도 있을 수 있습니다. 암의 진단에 보조적인 역할을 합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              AFP : 간세포암, 간경변, 바이러스성 간염, 임신후반   
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              CEA : 췌장암, 대장암, 담도암, 폐암, 위암, 유방암, 전이성 간암 등 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              PSA : 전립선암, 전립선비대증, 전립선염
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              CA125 : 난소암, 자궁내막암, 자궁내막증 등 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              CA19-9 : 소화기암(대장암, 췌장암, 폐암, 담관암, 위암 등)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 종양표지자 -->

    <!-- 비타민-->
    <div class="contents checkup_detail checkup_detail026">
        <h4 class="checkup_detail_tit_sub">비타민</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               비타민
              </caption>
              <colgroup>
                <col style="width: 23%" />
                <col style="width: 17%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">비타민 D검사(Vit D)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              비타민D는 골다공증, 당뇨 등 수많은 질환과 밀접한 관련이 있으며, 칼슘과 인이 흡수되고 뼈를 형성하는데 도움을 주며 인슐린 작용을 도우며 부족시 골다공증 등 다양한 질병 발생 위험을 높이게 됩니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 비타민 -->

    <!-- 호르몬-->
    <div class="contents checkup_detail checkup_detail027">
        <h4 class="checkup_detail_tit_sub">호르몬</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               호르몬
              </caption>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 15%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">융모 성선자극 호르몬(HCG)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">에스테라디올(estradiol, E2)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">난포자극호르몬(FSH)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">황체형성 호르몬(LH)</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
          <div class="mint_tip">Tip</div>
          <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              혈액 속의 난포자극호르몬, 황체형성 호르몬, 에스트로겐의 여성호르몬 수치 및 테스토스테론의 남성호르몬 수치를 확인하여 다양한 갱년기 장애를 진단하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              남성의 갱년기 증상은 피로, 무기력, 활력 저하, 불안, 초조, 우울 등 신체적 변화로 체모와 근육량 감소, 복부지방 증가, 발기부전, 여성형 유방, 안면홍조 등이 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              여성의 갱년기 증상은 심한 기분 변화, 우울증, 기억력 장애 등 신체적 변화로 얼굴/목 부위가 붉어지고 열감, 수면 중 땀흘림, 수면장애, 불규칙한 자궁출혈, 질건조 등이 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 호르몬 -->

    <!-- 위내시경 또는 위장조영검사 -->
    <div class="contents checkup_detail checkup_detail028">
        <h4 class="checkup_detail_tit_sub">위내시경 또는 위장조영검사</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               위내시경 또는 위장조영검사
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align:left;">(위내시경)식도</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>역류성 식도염</p>
                          <div class="x_line_type">위내시경</div>
                      </div>
                      <div class="x_line_reserve">
                          <p>위축성 위염 및 다발성 위궤양(BX)</p>
                          <div class="x_line_type">체중부,체상부</div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type">위장조영검사</div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align:left;">(위내시경)위</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align:left;">(위내시경)십이지장</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align:left;">위조직검사</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>만성 위염(BX)</p>
                          <div class="x_line_type"></div>
                      </div>
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      <!-- 헬리코박터검사 -->
        <h4 class="checkup_detail_tit_sub contents">헬리코박터검사</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive2 }" @click="addClass2">
          <div class="scroll">
            <table>
              <caption class="blind">
               헬리코박터검사
              </caption>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 15%" />
                <col style="width: 30%" />
                <col style="width: 30%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;"> Helicobacter Ig G</td>
                  <td>음성</td>
                  <td class="present_line">음성</td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">Helicobacter Ig M</td>
                  <td>음성</td>
                  <td class="present_line">음성</td>
                  <td></td>
                </tr>
                <tr>
                  <td style="text-align: left;">헬리코박터균 검사(H-P test)</td>
                  <td>음성</td>
                  <td class="present_line">헬리코박터 양성</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              위내시경은 내시경을 통해 식도, 위, 십이지장 내부를 관찰하는 검사로 질환의 유무 및 상태 등을 진단하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              위-식도 역류 질환, 식도정맥류, 염증, 궤양, 종양을 확진하며 필요시 조직검사를 시행합니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              헬리코박터 검사는 내시경 시 위궤양, 십이지장 궤양의 흔적을 발견하면 헬리코박터균 감염을 의심해서 위 점막을 채취, 균을 배양해 헬리코박터균의 존재를 확인하는 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 위내시경 또는 위장조영검사 -->

    <!-- 대장내시경 또는 대장조영검사  -->
    <div class="contents checkup_detail checkup_detail029">
        <h4 class="checkup_detail_tit_sub">대장내시경 또는 대장조영검사 </h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
               대장내시경 또는 대장조영검사 
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>대장내시경</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>대장종용(BX) 및 내치질</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type">위장조영검사</div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>대장조직검사</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>종합판정표 참고</p>
                          <div class="x_line_type"></div>
                      </div>
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              내시경을 통해 대장 점막을 관찰하여 이상 병변 제거 및 장 질환을 진단하기 위한 검사입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              대장암의 95% 이상이 용종이 자라면서 발병하기 때문에 용종을 제거해 대장암을 예방할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 대장내시경 또는 대장조영검사  -->

    <!-- 상복부초음파  -->
    <div class="contents checkup_detail checkup_detail030">
        <h4 class="checkup_detail_tit_sub">상복부초음파</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              상복부초음파 
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>간</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>좌엽 간낭종</p>
                          <div class="x_line_type">0.6cm</div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>담낭, 담도</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                 <tr>
                  <td>비장</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                 <tr>
                  <td>췌장</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>잘보이지 않음=>복부/골반CT실시</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                      <div class="x_line_reserve">
                        <p>-</p>
                        <div class="x_line_type"></div>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>신장</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>양측 신낭종</p>
                          <div class="x_line_type">소수,0.6cm</div>
                      </div>
                  </td>
                  <td>
                      <div class="x_line_reserve">
                        <p>-</p>
                        <div class="x_line_type"></div>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>기타</td>
                  <td class="present_line">
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                      <div class="x_line_reserve">
                        <p>-</p>
                        <div class="x_line_type"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              간, 담낭, 췌장, 비장, 신장의 모양과 병변을 확인할 수 있는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              지방간, 만성간염, 간경변증, 혈관종, 낭종, 담관확장, 담낭결석, 담도암, 췌장염, 췌장암 등의 진단이 가능하며 CT나 MRI 등의 추가검사가 필요할 수 있습니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 상복부초음파  -->

    <!-- 갑상선초음파  -->
    <div class="contents checkup_detail checkup_detail031">
        <h4 class="checkup_detail_tit_sub">갑상선초음파</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              갑상선초음파
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              갑상선의 모양과 형태 및 물혹, 결절을 확인할 수 있는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 갑상선초음파 -->

    <!-- 경동맥초음파  -->
    <div class="contents checkup_detail checkup_detail032">
        <h4 class="checkup_detail_tit_sub">경동맥초음파</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              경동맥초음파
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             경동맥내 혈액의 흐름과 혈액 상태를 확인할 수 있으며, 동맥경화를 예측할 수 있어 부분적 동맥 폐색 및 협착을 확인할 수 있는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 경동맥초음파 -->

    <!-- 전립선초음파  -->
    <div class="contents checkup_detail checkup_detail033">
        <h4 class="checkup_detail_tit_sub">전립선초음파</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              전립선초음파
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             전립선의 형태 및 염증 질환, 크기, 석회화, 결절 등을 확인할 수 있는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 전립선초음파 -->

    <!-- 경두개 초음파 혈류  -->
    <div class="contents checkup_detail checkup_detail034">
        <h4 class="checkup_detail_tit_sub">경두개 초음파 혈류</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
             경두개 초음파 혈류
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             뇌혈관 협착이나 폐색, 재관류 등 뇌 질환을 확인할 수 있는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 경두개 초음파 혈류 -->

    <!-- 심장초음파   -->
    <div class="contents checkup_detail checkup_detail035">
        <h4 class="checkup_detail_tit_sub">심장초음파 </h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
            심장초음파 
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             심장내부의 심방과 심실의 크기와 모양, 판막의 움직임, 대동맥의 크기 등 심장의 구조를 확인하여 각종 심장 질환을 진단하고 기능을 평가하기 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             판막 질환, 허혈성 심장질환, 선천성 심장질환, 심근병증, 심장막 질환, 감염성 심내막염, 폐공맥색전, 대동맥박리 등의 심혈관질환의 진단과 치료 경과의 관찰에 용이 합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 심장초음파  -->

    <!-- 운동부하 심전도   -->
    <div class="contents checkup_detail checkup_detail036">
        <h4 class="checkup_detail_tit_sub">운동부하 심전도 </h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
            운동부하 심전도
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             안정상태에서 증상이 나타나지 않아 진단이 어려운 협심증 등의 진단을 위해 심장에 부하를 주어 증상의 발현 여부 및 심장의 변화를 측정하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             심근의 허혈, 부정맥, 고혈압 등의 심혈관질환의 유무나 심장과 폐의 지구력을 측정할 수 있습니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 운동부하 심전도  -->

    <!-- 활동중 심전도   -->
    <div class="contents checkup_detail checkup_detail037">
        <h4 class="checkup_detail_tit_sub">활동중 심전도</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
            활동중 심전도
              </caption>
              <colgroup>
                <col style="width: 50%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             심장 박동의 이상상태를 측정하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             부정맥은 계속 지속될 수도 있지만 잠깐 생겼다 없어지는 경우가 많아 일상생활 하면서 환자의 심전도를 기록합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 활동중 심전도  -->

    <!-- 유방단순촬영/유방초음파  -->
    <div class="contents checkup_detail checkup_detail038">
        <h4 class="checkup_detail_tit_sub">유방단순촬영/유방초음파</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              유방단순촬영/유방초음파
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>유방단순촬영</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>유방초음파</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>정상</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             유방단순촬영은 유방암이 혹으로 만져지기 전 즉, 아주 초기에 유방암을 진단할 수 있는 장점이 있어 중요한 검사입니다. 유방 단순 촬영술은 유방 초음파 검사에서 보이지 않는 유방암으로 인한 석회화의 발견에 특히 예민한 방법으로 유방 초음파 검사는 선별검사로 유방단순촬영술을 대체할 수 없습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             유방초음파는 방사선 노출이 없으며, 유방의 양성혹, 악성혹, 염증성 병변, 물혹 등을 영상소견으로 구분하는데에 그 정확도가 매우 높습니다. 단, 유방초음파 검사는 유방에 지방 조직이 많을수록 작은 고형 종괴를 찾는데 민감도가 떨어지고, 미세 석회화를 발견하고 특성을 파악하는 데 제한이 있기 때문에 유방 촬영술 없이 단독으로 사용하는 것은 권장되지 않습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             BI-RADS category
                            </p>
                            <p>C0 (category0) : 양성/악성인지 잘 모르는 경우 또는 불완전해서 추가적인 추적검사 필요한 경우</p>
                            <p>C1 (category1) : 정상 (병변이 없음)</p>
                            <p>C2 (category2) : 병은 있으나 정상적인 양성 병변 (특별한 처치, 수술 필요 없음)</p>
                            <p>C3 (category3) : 혹이 양성에 가까우며, 악성일 경우가 2%도 안되는 경우 </p>
                            <p>C4 (category4) : 추가적인 조직검사 필요한 경우 ( 악성 병변 가능성 있음 )</p>
                            <p>C5 (category5) : 악성의 가능성이 매우 높은 병소</p>
                            <p>C6 (category6) : 유방암으로 진단 받은 경우</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 유방단순촬영/유방초음파  -->

    <!-- 부인과  -->
    <div class="contents checkup_detail checkup_detail039">
        <h4 class="checkup_detail_tit_sub">부인과</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              부인과
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>부인과초음파</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>자궁경부<br>세포진검사</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>인유두종<br>바이러스 검사</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             부인과초음파는 여성 생식기(자궁, 난소 및 질)의 질환의 발견 및 진단을 위한 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             자궁의 근종, 난소의 물혹, 종양 등 종괴성 질환의 발견, 골반강 내 염증, 난소 염전등 종괴성 질환이나 비종괴성 질환의 진단에 유용합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             자궁경부세포진검사는 자궁경부나 질에서 세포 샘플을 채취하여 비정상 세포를 식별하고 자궁경부암의 조기진단을 돕는 검사입니다.
                             </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                            인유두종 바이러스는 자궁경부암을 비롯하여 몇 종류의 암의 원인으로 밝혀진 바이러스로 자궁경부 세포검사와 같은 방법으로 검체를 채취한 후 HPV 바이러스의 유전자 검사를 통해 어떤 형의 바이러스에 감염되었는지를 진단하는 검사입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                            16형과 18형의 인유두종 바이러스가 자궁경부암의 주요 원인인 발암성으로 분류되며 자궁경부암의 70%가 이 두 유형에 의해 발생합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                            자궁경부 이형성증은 인유두종 바이러스에 의해 세포와 조직이 비정상적으로 변형된 상태로 자궁경부암 전단계이며 자궁경부이형성증 → 상피내암 → 자궁경부암의 순서로 진행됩니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- 부인과  -->

    <!-- CT -->
    <div class="contents checkup_detail checkup_detail040">
        <h4 class="checkup_detail_tit_sub">CT</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
              CT
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>뇌</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>흉부</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>-</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             CT스캐너를 이용한 컴퓨터 단층 촬영법입니다.
                            </p>
                            <p>엑스선이나 초음파를 여러 각도에서 인체에 투영하고 이를 컴퓨터로 재구성하여 인체 내부 단면의 모습을 화상으로 처리. 종양 등의 진단법으로 널리 이용되고 있습니다.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- CT  -->

    <!-- MRI,A -->
    <div class="contents checkup_detail checkup_detail041">
        <h4 class="checkup_detail_tit_sub">MRI,A</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
             MRI,A
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 40%" />
                <col style="width: 40%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>뇌<br>MRI&MRA</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>뇌 MRI</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>뇌 MRA</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td>-</td>
                  <td class="present_line">
                      <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                  <td>
                       <div class="x_line_reserve">
                          <p>-</p>
                          <div class="x_line_type"></div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             자기 공명 영상 촬영 MRI : magnetic resonance imaging
                            </p>
                            <p>전자장내에서의 에너지 방출신호를 이용하여 컴퓨터로 단층상을 구성하는 방법입니다. 병소의 진전범위나 정상구조물과의 해부학적 관계를 알기 쉽습니다.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             자기 공명 혈관 조영술 MRA :  magnetic resonance Angiography
                            </p>
                            <p>MRI 장비를 이용하여 혈관이 잘 보이는 영상을 얻는 한가지 방법이며, MRI로 혈관을 보기 위해 특별히 하는 검사입니다.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!-- MRI,A  -->

    <!-- 양전자 방출 단층촬영 PET-CT -->
    <div class="contents checkup_detail checkup_detail042">
        <h4 class="checkup_detail_tit_sub">양전자 방출 단층촬영 PET-CT</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
             양전자 방출 단층촬영 PET-CT
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>검사결과</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PET-CT</td>
                  <td style="text-align:left;">
                      Conclusion
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="tip_box">
            <div class="mint_tip">Tip</div>
            <div class="info-text depth__wrap depth01">
              <div class="depth__text">
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                             PET – CT는 암의 영상진단 방법 중에 가장 초기에, 가장 정확하게 암을 찾아내는 최첨단 검사방법입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              CT / MRI / 초음파 촬영 등은 우리 몸의 해부학적 이상을 찾아 질환의 진단에 이용하지만 PET은 우리 몸의 신진대사의 이상을 찾아내어 진단하는 차이점이 있습니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              PET이란 우리 몸의 신진대사에 이용되는 포도당과 유사한 물질( 양전자를 방출하는 방사성 의약품 )을 주사해 전신 대사상태의 미세한 변화를 영상으로 촬영합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              PET – CT는 암의 조기진단뿐만 아니라, 동시에 발생한 다른 암의 우연한 발견, 악성 암과 양성 암의 감별, 암의 병기 결정, 재발 암의 발견, 암 치료경과 관찰과 예후를 예측하는데 이용될 수 있습니다.  
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__text">
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                        <div class="depth__wrap">
                          <div class="depth__bullet">
                            <span class="bullet03"></span>
                          </div>
                          <div class="depth__text">
                            <p>
                              방사선 의약품 F-18 FDG를 정맥주사한 후 PET영상을 얻고 동일한 부위에 관하여 CT를 시행하여 각기 분석하고, PET와 CT를 융합한 영상을 바탕으로 종합 분석한 결과입니다. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <!--양전자 방출 단층촬영 PET-CT  -->

    <!-- 관상동맥심장질환 발생 위험도 평가 -->
    <div class="contents checkup_detail checkup_detail043">
        <h4 class="checkup_detail_tit_sub">관상동맥심장질환 발생 위험도 평가</h4>
        <h5 class="checkup_detail_tit_sub_small mt20">종합조사표</h5>
        <div class="table-cont">
          <ul class="table-list type02">
            <li class="table-item">
              <div class="item tit">사업장명</div>
              <div class="item">창원파티마</div>
            </li>
            <li class="table-item">
              <div class="item tit">성명</div>
              <div class="item">홍길동</div>
            </li>
            <li class="table-item">
              <div class="item tit">성 / 연령</div>
              <div class="item">
                <span>남</span>
                <span class="ml10">50세</span>
              </div>
            </li>
            <li class="table-item">
              <div class="item tit">수축기 혈압</div>
              <div class="item">
                <span>104</span>
                <span class="ml10">mmHg</span>
              </div>
            </li>
            <li class="table-item">
              <div class="item tit">LDL 콜레스테롤</div>
              <div class="item">
                <span>154</span>
                <span class="ml10">mg/dL</span>
              </div>
            </li>
            <li class="table-item">
              <div class="item tit">HDL 콜레스테롤</div>
              <div class="item">
                <span>54</span>
                <span class="ml10">mg/dL</span>
              </div>
            </li>
            <li class="table-item">
              <div class="item tit">흡연유무</div>
              <div class="item">유</div>
            </li>
            <li class="table-item">
              <div class="item tit">당뇨병유무</div>
              <div class="item">무</div>
            </li>
          </ul>
        </div>
        <h5 class="checkup_detail_tit_sub_small contents">관상동맥심장질환 (Coronary heart disease) 위험도</h5>
        <div class="depth__wrap mt20">
          <div class="depth__text">
            <div class="depth__wrap ">
              <div class="depth__bullet">
                <span class="bullet03"></span>
              </div>
              <div class="depth__text">
                <p>
                  귀하의 향후 10년 이내 <b class="red__title">관상동맥질환</b> 발병 위험도는 <b class="red__title">70%</b> 입니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="contents bar_charts_wrap">
            <HealthChartBar/>
            <div class="depth__wrap info-reference" style="margin-top:0;">
              <div class="depth__bullet">
                  <p class="small-text">**</p>
              </div>
              <div class="depth__text">
                  <p class="small-text">저위험군 : 위험인자가 거의 없음을 의미</p>
              </div>
            </div>

            <div class="depth__wrap desc_border">
              <div class="depth__text">
                <div class="depth__wrap ">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      관상동맥심장질환 발생위험도에 관한 평가는 귀하의 건강검진 결과를 바탕으로 실시되었습니다.
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div class="depth__wrap">
              <div class="depth__text">
                <div class="depth__wrap ">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      미국의 Framingham Risk Score에 따른 이 평가는 성인의 성별, 연령, 저밀도지단백 콜레스테롤 (LDL), 고밀도 지단백 콜레스테롤 (HDL), 수축기 혈압, 흡연, 당뇨 등의 위험인자로 향후 10년간 <b>홍길동</b>님에게서 관상동맥심장질환이 발병할 확률을 평가한 결과입니다. 
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div class="depth__wrap info-reference">
              <div class="depth__bullet">
                  <p class="small-text">**</p>
              </div>
              <div class="depth__text">
                  <p class="small-text">이미 뇌/심혈관 질환을 가지고 계신분은 위의 위험도 평가가 의미 없음을 알려드립니다.</p>
              </div>
            </div>
        </div>
    </div>
    <!--관상동맥심장질환 발생 위험도 평가  -->

    <!-- 대사증후군 평가 -->
    <div class="contents checkup_detail checkup_detail044">
        <h4 class="checkup_detail_tit_sub">대사증후군 평가 </h4>
         <div class="depth__wrap mt20">
            <div class="depth__text">
              <div class="depth__wrap ">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    대사증후군이란, 뇌심혈관질환 및 당뇨병의 위험을 높이는, 체지방 증가, 혈압 상승, 혈당 상승, 혈중 지질 이상 등의이상 상태들의 집합을 말합니다. 대사증후군이 있는 경우에는 심혈관질환의 위험을 두 배 이상 높이며, 당뇨병의 발병을 10배 이상 증가시킵니다. (국가건강정보포털 의학정보, 국가건강정보포털)
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div class="contents mt">
            <div class="depth__wrap">
              <div class="depth__text">
                <div class="depth__wrap ">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                     대사증후군은  아래의 다섯가지 구성요소 중 3가지 이상이 해당 될 경우로 정의 할 수 있습니다.
                     </p>
                  </div>
                </div>
              </div>
          </div>

          <div class="table-wrap table-type1 evaluation_table scroll" :class="{ 'on': isActive }" @click="addClass">
            <div class="scroll">
              <table>
                <caption class="blind">표</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:25%;">
                  <col style="width:55%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">복부비만<span></span></div>
                    </th>
                    <td>허리둘레</td>
                    <td>남성 : 90Cm, 여성 : 85 Cm 이상</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">고중성지방혈증<span></span></div>
                    </th>
                    <td>중성지방(Triglyceride)</td>
                    <td>150 mg/dl 이상 또는 이상지질혈증 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">낮은 HDL 콜레스테롤 혈증<span></span></div>
                    </th>
                    <td>고밀도콜레스테롤(HDL-cholesterol)</td>
                    <td>남성 : 40 mg/dl 미만, 여성 : 50 mg/dl 미만 또는 이상지질혈증 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">높은 혈압<span></span></div>
                    </th>
                    <td>수축기 / 이완기혈압</td>
                    <td>130 / 85 mmHg 이상 또는 고혈압 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">혈당 장애<span></span></div>
                    </th>
                    <td>공복혈당</td>
                    <td>100 mg/dl 이상 또는 당뇨병 치료약제 복용 중</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="contents">
          <div class="depth__wrap">
            <div class="depth__text">
              <div class="depth__wrap ">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    <b>홍길동</b>님의 대사증후군 평가 관련 결과는 아래와 같습니다. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__text">
              <div class="depth__wrap ">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    대사증후군 위험요인 중 3가지를 보유하고 계십니다. <b>홍길동</b>님은 대사증후군입니다. 
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrap table-type1 evaluation_table scroll" :class="{ 'on': isActive2 }" @click="addClass2">
            <div class="scroll">
              <table>
                <caption class="blind">표</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:25%;">
                  <col style="width:55%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">복부비만<span></span></div>
                    </th>
                    <td>허리둘레</td>
                    <td>남성 : 90Cm, 여성 : 85 Cm 이상</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">고중성지방혈증<span class="danger_label"></span></div>
                    </th>
                    <td>중성지방(Triglyceride)</td>
                    <td>150 mg/dl 이상 또는 이상지질혈증 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">낮은 HDL 콜레스테롤 혈증<span class="danger_label"></span></div>
                    </th>
                    <td>고밀도콜레스테롤(HDL-cholesterol)</td>
                    <td>남성 : 40 mg/dl 미만, 여성 : 50 mg/dl 미만 또는 이상지질혈증 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">높은 혈압<span class="danger_label"></span></div>
                    </th>
                    <td>수축기 / 이완기혈압</td>
                    <td>130 / 85 mmHg 이상 또는 고혈압 치료약제 복용 중</td>
                  </tr>
                  <tr>
                    <th>
                      <div class="table_flex_wrap">혈당 장애<span></span></div>
                    </th>
                    <td>공복혈당</td>
                    <td>100 mg/dl 이상 또는 당뇨병 치료약제 복용 중</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="depth__wrap info-reference">
            <div class="depth__bullet">
              <p class="small-text">※</p>
            </div>
            <div class="depth__text">
              <p class="small-text">대사증후군 위험요인 1~2개를 보유한 사람은 대사증후군 주의군, 위험요인 3개 이상을 보유한 사람은 대사증후군으로 진단됩니다. 위의 사항에 해당되시는 경우 적절한 건강관리 권고 드립니다.</p>
            </div>
          </div>
          <div class="depth__wrap info-reference">
            <div class="depth__bullet">
              <p class="small-text">※</p>
            </div>
            <div class="depth__text">
              <p class="small-text">대사증후군에 해당되시는 경우, 표준체중 및 허리둘레의 유지, 금연 및 절주, 규칙적인 운동, 건강한 식생활 등에 유의하시기 바랍니다.</p>
            </div>
          </div>
        </div>
    </div>
    <!--대사증후군 평가  -->

    <!-- 선택추가 -->
    <div class="contents checkup_detail checkup_detail045">
        <h4 class="checkup_detail_tit_sub">추가선택</h4>
        <div class="table-wrap table-type1 mt20 table-typeCenter scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">
                선택추가
              </caption>
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 19%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
              </colgroup>
              <thead>
                <tr>
                  <th>검사항목</th>
                  <th>참고치 / 단위</th>
                  <th class="present_line">이번 검사결과</th>
                  <th>이전 검사결과(2024 년)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr>
                <!-- <tr>
                  <td></td>
                  <td style="text-align:left;"></td>
                  <td class="present_line"></td>
                  <td></td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <!-- 선택추가 -->

  </div>
</template>

<script>
import HealthChartBar from '@/components/common/HealthChartBar.vue';

export default {
  components: {
    HealthChartBar
  },
  data(){
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isActiveMo: false,
      activeIndex: 0
    }
  },
  methods: {
    addClass() {
      if (!this.isActive) {
        this.isActive = true;
      }
    },
    addClass2() {
      if (!this.isActive2) {
        this.isActive2 = true;
      }
    },
    addClass3() {
      if (!this.isActive3) {
        this.isActive3 = true;
      }
    },
    toggleDetails() {
      this.isActiveMo = !this.isActiveMo;
    },
    setActive(index) {
      this.activeIndex = index;
    }
  }

}
</script>