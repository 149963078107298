<template>
    <div class="board__search">
        <div class="board__select" :class="{ 'active': selectOn }" v-if="searchArr != null" @click="selectFn">
            <select name="" id="Sel_Inst" class="select_department" ref="selVal" v-model="searchType">
                <option :value="arr.value" v-for="arr in searchArr" :key="arr.value">{{arr.title}}</option>
            </select>
        </div>

        <label class="board__input">
            <input type="text" id="Search_doc" ref="searchInput" placeholder="검색어를 입력하세요." v-model="searchKeyword" @keyup.enter="search()" >
            <button type="button" class="board__input-btn" @click="search()"></button>
        </label>

        <!-- 통합검색에서만 -->
        <div class="re-search__btn" v-if="this.$route.name == 'search'">
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="checkbox" class="agree-input__radio" v-model="inSearch" >
                    <span class="icon"></span> 결과 내 재 검색
                </label>
            </div>
            <!-- ** 결과내재검색 후 버튼 노출 ** -->
            <button type="button" class="btn">재 검색 취소</button>
        </div>
    </div>


</template>

<script>
    export default {
        props: {
            searchArr: Array,
            keyword: String
        },
        data() {
            return {
                searchKeyword: this.keyword,
                inSearchKeyword: '',
                searchType: this.searchArr ? this.searchArr[0].value : '',
                prevData: '',
                selectOn: false,
                inSearch: false
            }
        },
        methods: {
            search() {
                const s = this.$refs.selVal ? this.$refs.selVal.value : ''
                this.$emit("setState", s, this.searchKeyword, this.prevData);
                this.prevData = s
            },
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        },
        computed: {

        }
    }
</script>