<template>
    <!-- 작업환경의학센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="department_organ_pic">
                            <img src="/resource/img/department_organ25.png" alt="이미지">
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>직업환경의학과는 근로자가 일을 하는 과정에서 노출되는 유해물질, 근골격계 부담작업, 업무 스트레스, 감정노동 등의 업무적 요인과 흡연, 과도한 음주, 운동 부족 등 나쁜 생활습관에 의해 초래될 수 있는 건강이상을 정기적인 건강진단을 통해 조기에 발견하고, 신속한 의학적 치료 및 관리를 취하는 것을 목적으로 하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>특수건강진단, 배치전 건강진단, 일반건강진단, 채용건강진단, 업무관련성 및 적합성평가, 금연치료, 환경성질환</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 직업환경의학과는 각종 건강진단을 통하여 건강이상을 조기에 발견하고, 신속한 의학적 치료 및 관리를 제공하고 있습니다. 또한, 취업, 진학, 유학, 국내외 거주 등의 목적으로 건강진단을 하는 경우 해당 목적에 알맞은 항목들로 구성된 건강진단 결과를 최대한 빠르게 처리하고 있으며, 소방관 및 경찰관 검진, 사업장이나 수검자 개인이 요구하는 항목들로 구성된 특화된 건강진단 업무도 협의하에 수행하고 있습니다.</p>
                                <br>
                                <p>창원파티마병원 직업환경의학과는 질병을 가진 환자의 의학적 관리에만 머물지 않고, 근로자의 질병이 업무수행을 하면서 노출된 물리적 요인, 화학적 요인, 인간공학적 요인, 업무스트레스 요인, 감정노동 등이 발생 및 악화의 주요한 원인인지를 밝히는 업무관련성 평가를 제공하고 있습니다.</p>
                                <p>또한, 업무 수행 전 근로자가 가진 질병이 향후 업무를 수행하는데 문제가 없는지, 그리고 건강상태에 따라 적정한 업무 및 개선안을 권고하는 업무적합성 평가도 제공합니다.</p>
                                <p>그 외 근로자 뇌·심혈관계 질환, 니코틴 중독(금연약물치료), 환경성 질환 등에 관한 상담, 진단, 치료, 관리 등의 업무를 수행합니다.</p>
                                <br>
                                <p>건강을 위해 금연, 절주, 규칙적 운동, 적정 체중 관리 등 좋은 습관을 가지는 것은 매우 중요합니다. 또한 직업환경적인 요인을 관리하는 것도 역시 매우 중요합니다. 평소 유해물질에 노출되는 일이나 활동을 하는 경우 위험성을 인지하고 규정된 방법과 정량을 지켜 사용해야 하며, 적절한 보호구를 착용해야 합니다. 또한, 근골격계 부담작업을 장기간 반복적으로 하는 경우 근골격계 질환, 과도한 업무 스트레스를 받는 경우 뇌심혈관 질환이 발생 및 악화될 수 있으므로 관련 업무를 수행하면서 건강에 이상이 있는 경우 직업환경적인 요인도 고려해서 관리해야 합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">금연클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>