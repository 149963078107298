<template>
  <div class="jobHealthCenter contents nationCancer_checkup">
    <div class="info-text depth__wrap contents">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              아래 항목을 작성해 주시면 원활한 국가암검진 예약을 위해 전문
              상담원이 확인 후 연락하여 검진 예약을 도와 드리고 있습니다.
            </p>
            <p>상담시간 평일 9:00~17:00</p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>
              예약 가능 검사 : 위암(위내시경, 위장조영술 중 택1), 폐암(폐CT),
              간암(간초음파)
            </p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>
              당일 가능 검사 : 유방암, 자궁경부암, 대장암(분변잠혈검사) 검사
            </p>
            <div class="depth__wrap info-reference">
              <div class="depth__bullet">
                <p class="small-text">※</p>
              </div>
              <div class="depth__text">
                <p class="small-text">
                  대장암검사는 대변검사(분변잠혈검사)로 진행하며,
                  <b class="red__title">대장내시경 추가 검사</b>를 원할 경우
                  소화기내과 진료 후 신청가능 합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap contents">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">간편예약 신청</h4>
      </div>
    </div>
    <div class="input-form type1">
      <ul class="input-form__list">
        <li class="input-form__item item2">
          <div class="input-form__box">
            <div class="tit">이름</div>
            <div class="cont">이준용</div>
          </div>
          <div class="input-form__box">
            <div class="tit">생년월일</div>
            <div class="cont">1999.01.01</div>
          </div>
        </li>
        <li class="input-form__item item2">
          <div class="input-form__box">
            <div class="tit">전화번호</div>
            <div class="cont">010-1234-5678</div>
          </div>
          <div class="input-form__box">
            <div class="tit">희망검진일</div>
            <div class="cont date_wrap">
              <div class="date-input">
                <VueDatePicker
                  v-model="date"
                  locale="ko"
                  :enable-time-picker="false"
                  :format="customFormat"
                  :week-start="0"
                  :day-class="getDayClass"
                  :select-text="'선택'"
                  :cancel-text="'취소'"/><img src="@/assets/resource/icon/date_icon.png" alt="아이콘"/>
              </div>
            </div>
          </div>
        </li>
        <li class="input-form__item">
            <div class="input-form__box">
                <div class="tit">예약항목</div>
                <div class="cont radio">
                    <div class="input-form__radio">
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio1" value="폐CT">
                            <span class="icon"></span> 폐CT
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio1" value="간초음파">
                            <span class="icon"></span> 간초음파
                        </label>
                    </div>
                    <div class="input-form__radio">
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio1" value="위내시경" @change="toggleEndoscopy">
                            <span class="icon"></span> 위내시경
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio1" value="위장조영술" @change="toggleEndoscopy">
                            <span class="icon"></span> 위장조영술
                        </label>
                        <p class="red__title"><span>◀</span>위암(검사 2종 중 택1)</p>
                    </div>
                </div>
            </div>
        </li>
        <li class="input-form__item">
            <div class="input-form__box">
                <div class="tit">위내시경 수면 유무</div>
                <div class="cont radio">
                    <div class="input-form__radio">
                        <label class="input-item" style="color:#ccc;">
                            <input type="radio" class="radio" name="radio2" value="수면" disabled>
                            <span class="icon"></span> 수면
                        </label>
                        <label class="input-item" style="color:#ccc;">
                            <input type="radio" class="radio" name="radio2" value="비수면" disabled>
                            <span class="icon"></span> 비수면
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li class="input-form__item">
            <div class="input-form__box">
                <div class="tit">관련질환유무</div>
                <div class="cont radio">
                    <div class="input-form__radio gap30">
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 고혈압
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 당뇨
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 뇌질환
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 심장질환
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 혈소판감소증
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 모름
                        </label>
                        <label class="input-item">
                            <input type="radio" class="radio" name="radio3" value="">
                            <span class="icon"></span> 기타
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li class="input-form__item">
            <div class="input-form__box address">
                <div class="tit">복용약</div>
                <div class="cont">
                    <div class="cont-item">
                        <input type="text" class="cont__text-input">
                    </div>
                </div>
            </div>
        </li>
      </ul>
    </div>

    <div class="depth__wrap info-reference">
        <div class="depth__bullet">
            <p class="small-text red__title">*</p>
            </div>
            <div class="depth__text">
            <p class="small-text red__title">
                간편 예약을 신청하시면 전문 상담원이 통화 후 검진 항목과 희망일을 확정하여 안내 하고 있습니다.
            </p>
        </div>
    </div>

    <div class="btn-wrap center contents">
      <a href="javascript:;" class="btn" @click="submitForm">국가암검진 간편예약신청</a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    VueDatePicker,
  },
  data() {
    return {
      customFormat: "yyyy.MM.dd",
      endoscopyDisabled: true,
    };
  },
  setup() {
    const now = new Date();
    const date = ref(now);

    return { date };
  },
  methods: {
    submitForm() {
      alert("국가암검진 간편예약신청이 완료되었습니다. 담당자가 확인 후 곧 연락드리겠습니다.");
    },
    toggleEndoscopy() {
        this.endoscopyDisabled = false;
        const sleepLabels = document.querySelectorAll('.input-form__radio label');
        sleepLabels.forEach(label => {
            if (label.querySelector('input[name="radio2"]')) {
                label.querySelector('input[name="radio2"]').disabled = false;
                label.style.color = "#555";
            }
        });
    }
  },
  getDayClass(date) {
    const day = date.getDay();
    if (day === 0) {
      return "sunday-class";
    } else if (day === 6) {
      return "saturday-class";
    }
    return "";
  },
};
</script>
