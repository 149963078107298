<template>
    <!-- 갑상선클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 내분비대사내과에서는 갑상선 기능 저하 및 항진증, 갑상선 결절과 갑상선암 등을 진단 및 치료하고 있으며, 보다 신속 정확한 진단을 위해 혈액검사, 갑상선 초음파, 갑상선 스캔 검사 등을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text heart_appointment center_box">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">연속혈당측정기의 특·장점</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>갑상선암 증상은 대개 통증이 없는 목의 종괴가 만져지는 경우가 많습니다. 그러나 보통은 증상이 없어 건강검진 시 시행한 초음파를 통해 우연히 발견하는 경우가 훨씬 많으며, 정기적인 검사를 통해 조기에 암을 발견하는 것이 무엇보다 중요합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑상선 질환 가족력이 있는 경우</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑상선 기능 항진·저하증 환자</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑상선염 의심 환자</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">신속 정확한 갑상선암 진단·치료 받도록 연계진료</h4>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 갑상선클리닉은 갑상선 초음파, 혈액검사를 비롯해 결절의 악성 및 양성 여부를 확인하는 미세침흡인세포검사 등을 시행하고 있으며, 갑상선암 진단 시 보다 신속한 수술적치료를 위해 본원 외과로 연계진료가 가능합니다.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <img src="/resource/img/center04.png" alt="갑상선" style="width:100%; max-width:500px;">
                            </div>
                        </div>

            </div>
        </div>
    </div>
     <div class="btn-wrap center contents">
        <a href="javascript:;" class="btn blue">센터/클리닉 전체</a>
    </div>
</template>


<script>
export default {
   
};
</script>