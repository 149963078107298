<template>
  <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>

  <div class="myHealthCheckup_reservation contents">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet">
        <span class="bullet01"></span>
      </div>
      <div class="depth__text">
        <h4 class="depth01__title title">결과조회</h4>
      </div>
    </div>
    <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
      <div class="scroll">
        <table class="myHealthcheckup_table">
          <caption class="blind">
            결과조회 폼
          </caption>
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 20%" />
            <col style="width: 55%" />
            <col style="width: 15%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>검진일자</th>
              <!-- <th>검진구분</th> -->
              <th>검진명</th>
              <!-- <th>추가선택검사</th> -->
              <th>결과조회</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="list.length == 0">
                <td colspan="100%">내용이 없습니다.</td>
            </tr>
            <tr v-for="(i, idx) in list" :key="i">
              <td>{{ idx + 1 }}</td>
              <td>{{$commonUtils.makeDateFormat(i.orddd, '.')}}</td>
              <!-- <td>{{i.healexamflag == 'C' ? '건강증진센터' : '직업환경의학센터'}}</td> -->
              <!-- <td>건강증진센터</td> -->
              <td>{{i.pkgnm}}</td>
              <!-- <td>
                <template v-if="i.optionList.length == 0">-</template>
                <template v-for="(option, optionIdx) in i.optionList" :key="optionIdx">
                    {{ optionIdx == 0 ? option : ', ' + option }}
                </template>
              </td> -->
              <td>
                <div class="btn-wrap center">
                  <a href="javascript:;" class="btn hover_btn" @click="goDetail(i)">상세보기</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="info-text depth__wrap depth01">
      <div class="depth__text">
        <div class="depth__wrap info-reference">
          <div class="depth__bullet">
            <p class="small-text">※</p>
          </div>
          <div class="depth__text">
            <p class="small-text">
              건강검진 결과조회는 결과 확인만을 위한 용도로 제증명 서류가 필요한
              경우 해당 부서로 문의해 주시기 바랍니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'
import {course} from './course_option'
export default {
    components: {
        NameCertifyPopUp
    },
    data() {
        return {
            list: [],
            deptCd: this.$route.params.deptCd,
            hpUrl: this.$route.params.hpUrl,
            course: course,
            isActive: false,
            popupVisible: false,
        }
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        getList(){
            // healexamflag - C: 종합검진(건강검진), G: 일반검진(직업환경)
            let arr = []
            this.axios.get(this.api.appointment.selectParticipant, {
                params: {
                    healexamflag: 'C'
                }
            }).then(res => {
                if(res.data.length > 0 && res.data[0].errortype == 'currentMember_null') {
                    alert('로그인을 해주시길 바랍니다.')
                    const path = this.$route.path
                    window.location.href = path.replace('checkUpResult', 'main')
                }
                arr = res.data
                // 직업환경 추가 시 삭제
                if(arr.length > 0){
                  const map = new Map();

                  arr.forEach(item => {
                    if (!map.has(item.rgstno)) {
                        map.set(item.rgstno, {
                            ...item,
                            optionList: []
                        });
                    }
                    
                    // if (item.gb === "추가" && map.get(item.rgstno).pkgnm != item.pkgnm) {
                    //     map.get(item.rgstno).optionList.push({
                    //         ...item
                    //     });
                    // }
                  });

                  this.list = Array.from(map.values());
                  this.list = this.list[0].errormsg ? [] : this.list
                }else{
                  this.list = []
                }

                for(let i of this.list){
                  // const pkgnm = i.pkgnm.replace(/\(.*?\)/g, '').trim()
                  // const courseName = this.course[pkgnm] ? this.course[pkgnm][this.$store.state.myInfoStore.memGender]['optionList'] : []
                  // i.optionList = courseName ? courseName : []
                  i.optionList = []
                }
                this.list.sort((a, b) => {
                  return b.orddd - a.orddd
                })
                // END: 직업환경 추가 시 삭제
                // this.axios.get(this.api.appointment.selectParticipant, {
                //     params: {
                //         healexamflag: 'G'
                //     }
                // }).then(res => {
                //     arr = arr.concat(res.data)

                //     if(arr.length > 0){
                //       const map = new Map();

                //       arr.forEach(item => {
                //         if (!map.has(item.rgstno)) {
                //             map.set(item.rgstno, {
                //                 ...item,
                //                 optionList: []
                //             });
                //         }
                        
                //         // if (item.gb === "추가" && map.get(item.rgstno).pkgnm != item.pkgnm) {
                //         //     map.get(item.rgstno).optionList.push({
                //         //         ...item
                //         //     });
                //         // }
                //       });

                //       this.list = Array.from(map.values());
                //       this.list = this.list[0].errormsg ? [] : this.list
                //     }else{
                //       this.list = []
                //     }

                //     for(let i of this.list){
                //       // const pkgnm = i.pkgnm.replace(/\(.*?\)/g, '').trim()
                //       // const courseName = this.course[pkgnm] ? this.course[pkgnm][this.$store.state.myInfoStore.memGender]['optionList'] : []
                //       // i.optionList = courseName ? courseName : []
                //       i.optionList = []
                //     }
                //     this.list.sort((a, b) => {
                //       return b.orddd - a.orddd
                //     })
                // })
            })
        },
        goDetail(item){
            this.$router.push(`/deptHp/${this.deptCd}/${this.hpUrl}/checkUpResultDetail.do?rgstno=${item.rgstno}&healexamflag=${item.healexamflag}`)
      },
        addClass() {
            if (!this.isActive) {
                this.isActive = true;
            }
        },
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.getList()
    },
};
</script>