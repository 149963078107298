<template>
    <div class="board-list">
        <div class="praise__btn-wrap">
            <button type="button" class="praise__btn blue">건강상담 글쓰기</button>
            <button type="button" class="praise__btn">작성한 글로 바로가기</button>
        </div>
        <div class="board__search">
            <div class="board__select" :class="{ 'active': selectOn }" @click="selectFn">
                <select name="" id="">
                    <option value="전체">전체</option>
                    <option value="제목">제목</option>
                    <option value="글">글</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" placeholder="검색어를 입력해주세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>

        <div class="board-list__table contents">
            <table>
                <colgroup>
                    <col style="width: 100px;">
                    <col style="width: auto">
                    <col style="width: 100px;">
                    <col style="width: 150px;">
                </colgroup>
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th>제목</th>
                        <th>게시자</th>
                        <th>등록일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="number"><span class="num">1</span>
                            <span class="new"></span>
                        </td>
                        <td class="left tit">
                            <a href="javascript:;" class="board-list__link">
                                <span class="new"></span>
                                <p>[5월 진료안내] 근로자의날·어린이날대체휴일·부처님오신날 - 휴진</p>
                            </a>
                        </td>
                        <td class="date">2024.04.20</td>
                        <td class="view">222</td>
                    </tr>

                    <tr>
                        <td class="number"><span class="num">1</span>
                        </td>
                        <td class="left tit">
                            <a href="javascript:;" class="board-list__link">
                                <p>주님 부활 대축일 기념 작은음악회 개최, 아름다운 음악으로 환자들과 함께 부활의 기쁨 나눠 천주교 마산 주님 부활 대축일 기념 작은음악회 개최, 아름다운 음악으로 환자들과 함께 부활의 기쁨 나눠 천주교 마산</p>
                            </a>
                        </td>
                        <td class="date">2024.04.20</td>
                        <td class="view">222</td>
                    </tr>
                </tbody>
            </table>

            <!-- 우리금융저축은행과 같은 페이징가져왔습니다. -->
            <div class="contents">
                <div class="paging ">
                    <a href="javascript:;" class="board__pagination-btn first">처음</a>
                    <a href="javascript:;" class="board__pagination-btn prev">이전</a>
                    <a href="javascript:;" class="board__pagination-btn num active"><span>1</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>2</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>3</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>4</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>5</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>6</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>7</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>8</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>9</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>10</span></a>
                    <a href="javascript:;" class="board__pagination-btn next">다음</a>
                    <a href="javascript:;" class="board__pagination-btn last">마지막</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>