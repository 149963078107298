<template>
  <div class="healthNotice healthCheckupInfo">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">기본사항</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                검사전날 저녁식사는 8시 이전에 드시고, 술, 육류, 기름진 음식은
                금합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                저녁식사 후 밤 9시부터 금식이 필요합니다.(껌, 물, 약, 음료수,
                담배 등도 일체 금하셔야 합니다.)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>귀금속(반지, 목걸이 등) 및 귀중품은 지참을 삼가주십시오.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                원활한 건강검진을 위해 당일 진한화장, 액세서리, 매니큐어 등을
                자제하여 주십시오.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검사 당일 어린아이의 동반은 삼가 주십시오.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>위, 대장내시경 검사 시 조직검사 또는 용종 절제술을 하는 경우 14일 이내에는 비행기 탑승이 불가할 수 있습니다.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">대변채취</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                채변은 검진 하루 전 또는 검진 당일에 채취합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>채변방법은 대변용기(흰 용기, 파란뚜껑)에 성함을 확인(또는 기록)하신 후 파란뚜껑에 달려있는 긴 스틱을 이용해 3곳 정도를 찔러 채취하면 됩니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                대장내시경 예약고객은 장정결제 복용 전 채취하기 바랍니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">복용약물 안내</h4>
          <div class="depth__wrap">
            <div class="depth__text">
              <p>
                뇌졸중, 심장질환, 폐질환 등의 질환이 있거나, 수술 및 입원치료를 받으신 분은 복용하시는 약물에 대하여 주치의에게 확인 한 후 검진 여부를 결정하셔야 합니다.
              </p>
            </div>
          </div>
          <br />
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                혈압약, 심장약, 항경련제를 복용하시는 분은 검진 당일 오전
                6시경에 소량의 물로 복용하시면 됩니다.<br>(단, 항응고제는 복용이
                불가합니다.)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                검진 전날 저녁 또는 당일 아침 인슐린 주사나 당뇨약 복용은
                불가합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                아스피린 등 혈전용해제 성분의 약을 복용하시는 분은 조직검사 시
                출혈의 위험이 있으므로 주치의 상담 후 검사 1주일 전부터 복용을
                중단하셔야 합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                복용하시는 약에 대한 조정이 안 되신 분은 조직검사를 실시하지
                못할 수도 있습니다.<br>(복용중단이 어려운 분은 사전에 검진센터로
                연락 바랍니다.)
              </p>
            </div>
          </div>
        </div>
      </div>

    <div class="btn-wrap center add_drug_btn contents">
      <a href="javascript:;" class="btn dark-gray">상세 약물 보기<span>▾</span></a>
    </div>
    <div class="add_drug_box">
      <ul class="add_drug_list mt0">
        <li class="add_drug_item">
          <img src="/resource/img/drug01.gif" alt="아스피린 프로텍트정(100mg)">
          <p>아스피린 프로텍트정(100mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug03.gif" alt="플라빅스정(75mg)">
          <p>플라빅스정(75mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug02.gif" alt="보령아시트릭스캡슐(100mg)">
          <p>보령아시트릭스캡슐(100mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug05.gif" alt="프레탈정(50mg)">
          <p>프레탈정(50mg)</p>
        </li>
        <!-- <li class="add_drug_item">
          <img src="/resource/img/drug02_01.gif" alt="바이엘아스피린(100mg)">
          <p>바이엘아스피린(100mg)</p>
        </li> -->
        <li class="add_drug_item">
          <img src="/resource/img/drug06.gif" alt="프레탈정(100mg)">
          <p>프레탈정(100mg)</p>
        </li>
         <!-- <li class="add_drug_item">
          <img src="/resource/img/drug02_02.gif" alt="클로그렐정(75mg)">
          <p>클로그렐정(75mg)</p>
        </li> -->
         <!-- <li class="add_drug_item">
          <img src="/resource/img/drug02_03.gif" alt="제일와파린정(쿠마딘정)">
          <p>제일와파린정(쿠마딘정)</p>
        </li> -->
        <li class="add_drug_item">
          <img src="/resource/img/drug04.gif" alt="디스그렌">
          <p>디스그렌</p>
        </li>
      </ul>
      <button class="accordion_btn_wrap">
        <img src="/resource/icon/accordion_button-active.png" alt="이미지">
      </button>
    </div>

      <div class="info-text depth__wrap depth01 contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">수면내시경(의식 하 진정내시경)</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                수면내시경 검사를 받으신 고객은 검사 후 회복실에서 휴식이 필요합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                의식이 회복되는 시간은 개인차가 있으며, 반드시 보호자의 동반이 필요합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검사 당일은 자가운전 및 중요한 약속, 무리한 운동, 기계조작을 삼가 해 주시기 바랍니다.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">여성 수검자</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                생리 중일 경우 일부검사에 제한이 있을 수 있습니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                생리로 인해 시행하지 못하는 검사는 생리 종료 5일 후부터 별도로 검사하실 수 있습니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>가임기 여성고객은 반드시 임신 여부를 확인이 필요합니다.<br>(임신 중 방사선촬영 불가)</p>
            </div>
          </div>
           <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>수유 중일 경우 유방촬영 검사는 불가하며, 수면내시경 검사 시 24시간 경과 후 수유를 권합니다.</p>
            </div>
          </div>
           <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>자궁 절제술을 받은 분들도 난소 등 골반 내 장기의 이상여부를 위해 부인과검진이 필요합니다.</p>
            </div>
          </div>
           <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검진 전 24시간 이내 부부관계, 질정 크림 사용은 불가합니다.</p>
            </div>
          </div>
           <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검진당일 샤워나 목욕은 삼가해 주십시오.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">객담채취(종합정밀 Ⅱ(숙박), 호흡기정밀건강검진)</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                객담검사는 폐암관련검사와 폐결핵관련 검사 2가지를 실시합니다.<br>(수령하신 객담통 2개 모두 채취해 주시기 바랍니다.)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                객담은 건강검진 당일아침 일어나자마자 바로 입을 헹군 후 깊은 기침으로 채취하셔야 합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>채취하신 객담은 냉장보관 하였다가 검진 당일 제출해 주시면 됩니다.</p>
            </div>
          </div>
        </div>
      </div>
     
    
    </div>
  </div>
</template>
<script>
export default {
    mounted() {
        const button = document.querySelector('.btn.dark-gray');
        const drugBox = document.querySelector('.add_drug_box');
        const accordionButton = document.querySelector('.accordion_btn_wrap');

        button.addEventListener('click', function() {
          if (drugBox.style.display === 'none' || drugBox.style.display === '') {
            // 슬라이드 다운
            drugBox.style.display = 'block';
            const height = drugBox.scrollHeight + 'px';
            drugBox.style.height = height;
            button.classList.add('active');
          } else {
            // 슬라이드 업
            drugBox.style.height = '0';
            button.classList.remove('active');
            setTimeout(() => {
              drugBox.style.display = 'none';
            }, 100); // transition 시간과 동일하게 설정
          }
        });

        accordionButton.addEventListener('click', function() {
          // 버튼에서 active 클래스 제거
          button.classList.remove('active');
          
          // drugBox를 슬라이드 업하여 높이 0으로 설정하고 display를 none으로 변경
          drugBox.style.height = drugBox.scrollHeight + 'px';
          setTimeout(() => {
            drugBox.style.height = '0';
          }, 10); // 약간의 지연을 주어 높이가 설정되도록 함
          setTimeout(() => {
            drugBox.style.display = 'none';
          }, 100); // transition 시간과 동일하게 설정
        });
      }
};
</script>