<template>
    <!-- 감염내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>

                 <div class="depth__wrap depth02">
                    <div class="depth__text">

                        <div class="department_organ_pic">
                            <img src="/resource/img/department_organ01.png" alt="이미지">
                        </div>

                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>감염내과는 여러 감염질환의 진단 및 치료를 담당하는 진료과입니다. 세균, 바이러스, 진균, 결핵 등 각종 미생물에 의한 감염병과 원인을 모르는 발열 질환 및 임파선염, 대상포진 등을 진료하고 있으며, 개인의 건강 뿐만 아니라 나아가 지역사회 공중 보건을 위해 인플루엔자, 폐렴, 대상포진 등 성인 예방접종에 대한 진료를 하고 있으며, 해외여행 전 각종 질환의 예방 및 해외여행 후 발생 가능한 감염 질환에 대한 여행의학도 함께 담당하고 있습니다. 또한 의료관련감염 예방을 위해 안전하고 깨끗한 의료 환경을 유지 및 개선하는 감염관리 업무도 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>발열, 불명열, 여행의학, 성인예방접종, 임파선염, 감염질환, 대상포진</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">진료대상</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>발열, 불명열</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>패혈증</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>임파선염</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>대상포진</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>폐외결핵</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심내막염, 요로감염, 피부 연조직 감염</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>기타 성매개 질환, 에이즈</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>해외 여행 후 발열, 감염질환</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>성인 표준 예방접종, 해외여행 전후 예방접종 및 상담</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>