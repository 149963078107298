<template>
    <div class="contents healthInfoDet">
        <div class="board-view__top">
            <ul class="board-view__share-list" :class="{active : this.shareList}">
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.clip"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('band')"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('twitter')"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('facebook')"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
            </ul>

            <button type="button" class="board-view__btn share" @click="shareFn()"></button>
            <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
            <button type="button" class="board-view__btn print" @click="print()"></button>

        </div>
        <div class="board-view__wrap info-wrap">
            <div class="info__tit">
                <h3>{{ title }}</h3>
                <!-- <ul class="board-view__share-list" :class="{active : this.shareList}">
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
                </ul>

                <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
                <button type="button" class="board-view__btn print" @click="print()"></button> -->
            </div>
            <div class="info__con">
                <div class="info__left" :class="{ 'active': isBbOnTop }">
                    <ul>
                        <template v-for="(item, index) in lnbItems" :key="index">
                            <li :class="{ 'active': accordionIndex === index }">
                                <a href="javascript:;" @click="activateAccordion(index)">{{ item }}</a>
                                <span></span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="info__right" ref="con" @scroll="handleScroll">
                    <div class="info__item" v-for="(item, index) in accordionItems" :key="index">
                        <div class="info-text">
                            <h3 class="depth01__title title">{{ item.contentit }}</h3>
                            <h3 class="depth02__title title" v-if="item.title != 'Intro'">{{ item.title }}</h3>
                            <p v-html="item.content"></p>
                        </div>
                    </div>
                    <div class="nuri_type04 contents">
                        <img src="/resource/img/img_opentype04.png" alt="공공누리 4유형">
                        <p class="nuri_notice">
                            본 공공저작물은 공공누리 <span class="blue__title"><b>“출처표시+상업적이용금지+변경금지”</b></span> 조건에 따라 이용할 수 있습니다.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FavoriteHeartVue from '../common/FavoriteHeart.vue';
import mental from '@/assets/resource/json/mental.json'

    const _ = require('lodash');
    export default {
        components: {
            FavoriteHeartVue
        },
        data() {
            return {
                shareList: false,
                isBbOnTop: false,
                title: '',
                lnbItems: [],
                accordionItems: [],
                accordionIndex: 0,
                info: null
            };
            
        },
        methods: {
            shareFn() {
                this.shareList = !this.shareList
            },
            print(){
                window.print()
            },
            checkBbPosition() {
                const bbElement = document.querySelector('.info__con');
                if (bbElement) {
                    const bbPosition = bbElement.getBoundingClientRect().top;
                    this.isBbOnTop = bbPosition <= 0;
                }
            },
            handleScroll() {
                const infoItems = document.querySelectorAll('.info__item');
                const infoLeftItems = document.querySelectorAll('.info__left li');

                infoItems.forEach((item, index) => {
                    const rect = item.getBoundingClientRect();
                    const threshold = 2;

                    if (rect.top < threshold && rect.bottom > 0) { // 변경된 부분: 요소의 top이 0보다 작고 bottom이 0보다 큰 경우를 화면 상단에 위치한 것으로 간주합니다.
                        infoLeftItems.forEach((leftItem, leftIndex) => {
                            if (leftIndex === index) {
                                leftItem.classList.add('active');
                            } else {
                                leftItem.classList.remove('active');
                            }
                        });
                    }
                });
            },
            activateAccordion(index) {
                // 엑티브 되는 박스의 index
                const accordionItem = this.$el.querySelectorAll('.info__item')[index];
   
                accordionItem.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
            },
            getInfo(){
                const key = this.$route.query.key
                let data  = {}
                for(let i of mental){
                    if(i.DISS_SJ == key){
                        data = i
                    }
                }

                this.accordionItems = []

                this.title = data.DISS_SJ
                for(let i = 10; i <= 60; i++){
                    const title = 'CATEGORY_NM' + i
                    const content = 'DISS_CN' + i
                    let contentit = this.lnbItems[String(i).slice(0,1) - 1]
                    this.lnbItems.push(data[title])
                    const obj = {}
                    if(data[title] && data[content]){
                        this.accordionItems.push({ 'contentit':  contentit, 'title': data[title], 'content': _.unescape(data[content].replace('http://localhost:8081', 'https://www.mentalhealth.go.kr')) });
                    }
                }
                this.lnbItems = this.$commonUtils.dupArr(this.lnbItems).filter(i => i)
                this.accordionItems = this.accordionItems.reduce((acc, curr) => {
                    const existing = acc.find(item => item.contentit === curr.contentit);
                    if (existing) {
                        acc.push({ contentit: '', title: curr.title, content: curr.content });
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
            },
            favoInfo(){
                const query = this.$route.query
                this.axios.get(this.api.article.view + query.boardNo + '/' + query.articleNo, {
                    params: {
                        instNo: 1,
                        articleNo: query.articleNo,
                        boardNo: query.boardNo
                    }
                }).then(res => {
                    this.info = {}
                    this.info.favoYn = res.data.favoInfoYn == 'Y' ? 'Y' : 'N'
                    this.info.no = query.articleNo
                    this.info.type = 'BBS'
                    this.info.mypageYn = 'N'
                })
            }
        },
        created() {
            this.favoInfo()
        },
        mounted() {
            this.getInfo()
            window.addEventListener('scroll', this.checkBbPosition);
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.checkBbPosition);
            window.removeEventListener('scroll', this.handleScroll);
        }
    };
</script>