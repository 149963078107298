<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">2020년~현재</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">2010년~2019년</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">1990년~1999년</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">1980년~1989년</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">1969년~1979년</button></li>
        </ul>
    </div>
    <div class="contents history">
        <div class="history__bg">Fatima History</div>
        <div class="history__top">
            <h2 class="tit">2020년 ~ 현재 </h2>
            <span class="line"></span>
            <span class="text">History</span>
        </div>

        <div class="history__cont">
            <div class="year__box">
                <ul class="year__list">
                    <li class="year__item" :class="{ 'active': index === historyIndex, 'prev': index === historyIndex - 1 }" v-for="(year, index) in historyData">
                        <a href="javascript:;">{{ year.year }}</a>
                    </li>
                </ul>
            </div>

            <div class="history__wrap">
                <div class="history__line">
                    <div class="bar"></div>
                </div>
                <ul class="history__list">
                    <li class="history__item" v-for="(item, index) in historyData">
                        <div class="img" v-if="item.img"><img :src="require(`@/assets/resource/img/${item.img}`)" class="history__img" alt="이미지"></div>
                        <div class="text">
                            <div class="date">{{item.year +'.'+ item.month +'.'+ item.day}}</div>
                            <div class="tit">{{item.tit}}</div>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    // import { gsap, ScrollTrigger } from "gsap/all";

    export default {
        data() {
            return {
                historyIndex: 0,
                historyData: [
                    { tit: '영양과 조리장 리모델링1', year: '2021', month: '02', day: '18', img: 'history-img01.jpg' },
                    { tit: '영양과 조리장 리모델링2', year: '2022', month: '02', day: '18', img: 'history-img02.jpg' },
                    { tit: '영양과 조리장 리모델링3', year: '2023', month: '02', day: '18', img: 'history-img03.jpg' },
                    { tit: '영양과 조리장 리모델링1', year: '2024', month: '02', day: '18', img: 'history-img01.jpg' },
                    { tit: '영양과 조리장 리모델링2', year: '2025', month: '02', day: '18', img: 'history-img02.jpg' },
                    { tit: '영양과 조리장 리모델링3', year: '2026', month: '02', day: '18', img: 'history-img03.jpg' },
                    { tit: '영양과 조리장 리모델링1', year: '2027', month: '02', day: '18', img: 'history-img01.jpg' },
                    { tit: '영양과 조리장 리모델링2', year: '2028', month: '02', day: '18', img: 'history-img02.jpg' },
                    { tit: '영양과 조리장 리모델링3', year: '2029', month: '02', day: '18', img: 'history-img03.jpg' },
                ]
            };

        },
        methods: {
            listGsap() {
                let boxItems = document.querySelectorAll(".history__item");
                let heightBox = Array.from(boxItems).reduce((totalHeight, el) => totalHeight + el.offsetHeight, 0);
                const bar = document.querySelector('.history__line .bar');
                const historyHeight = document.querySelector('.history').offsetHeight;

                console.log(heightBox)
                gsap.to('.history', {
                    scrollTrigger: {
                        trigger: '.history',
                        start: "top top",
                        scrub: 3,
                        toggleClass: { targets: '.history', className: 'active' }
                    }
                })

                gsap.to(bar, {
                    height: '100%',
                    scrollTrigger: {
                        trigger: '.history',
                        start: 'top top',
                        end: `+=${historyHeight}`,
                        scrub: 1,
                    }
                });


                boxItems.forEach((item, index) => {
                    gsap.from(item, {
                        scrollTrigger: {
                            trigger: item,
                            start: "-300px center",
                            end: '-200%',
                            scrub: 3,
                            onEnter: () => {
                                this.historyIndex = index;
                                item.classList.add("active")

                            },
                            onEnterBack: () => {
                                this.historyIndex = index;
                                item.classList.remove("active")
                            },
                        },
                        y: 200,
                        opacity: 0
                    });
                });
            }
        },
        mounted() {
            setTimeout(() => {
                this.listGsap();

       
            }, 100);
        }

    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>