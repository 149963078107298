<template>
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">절차</h4><!-- depth02 -->
                <div class="depth__wrap depth02">
                    <ul class="step-box">
                        <li class="step-box__item">
                            <div class="step-box__img">
                                <img src="/displayEditorFile.do?filePath=/1/f3f063d9-0141-41b2-af08-c8c5a03569fe" alt="이미지">
                            </div>
                            <div class="step-box__text">
                                <h4 class="depth02__title">신청</h4>
                                <p>Step01</p>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__img">
                                <img src="/displayEditorFile.do?filePath=/1/f4a9700d-ac8a-4f63-accc-aa7380d6e403" alt="이미지">
                            </div>
                            <div class="step-box__text">
                                <h4 class="depth02__title">제증명 발급</h4>
                                <p>Step02</p>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__img">
                                <img src="/displayEditorFile.do?filePath=/1/6e574d60-4101-492b-9bfc-f52adc175736" alt="이미지">
                            </div>
                            <div class="step-box__text">
                                <h4 class="depth02__title">수납 및 수령</h4>
                                <p>Step03</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet02"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">신청</h4>
                        <div class="depth__wrap ">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>1층 원무과 제증명 창구 (입원환자 : 병동 간호사실)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet02"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">제증명 발급</h4>
                        <div class="depth__wrap depth02">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>담당의사 면담 후 발급</p>
                            </div>
                        </div>
                        <div class="depth__wrap info-reference">
                            <div class="depth__bullet">
                                <h4 class="depth03__title">※</h4>
                            </div>
                            <div class="depth__text">
                                <h4 class="depth03__title">일부서류(진료확인서, 입원확인서)는 진료과 접수(예약) / 방문 없이 서류발급 창구에서 발급이 가능합니다.
                                </h4>
                            </div>
                        </div>
                        <div class="depth__wrap info-reference">
                            <div class="depth__bullet">
                                <h4 class="depth03__title">※</h4>
                            </div>
                            <div class="depth__text">
                                <h4 class="depth03__title">진단서, 소견서는 의료법 제17조에 의거 본인 외 발급이 불가합니다. (단, 사망진단서는 배우자와 직계존비속에
                                    한해 발급이 가능합니다.)</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet02"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">수납 및 수령</h4>
                        <div class="depth__wrap ">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>1층 원무과 제증명 창구(입원환자 : 무인수납기 또는 제증명 창구)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">증명서 사본발급 구비서류</h4><!-- depth02 -->
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet03"></span></div>
                    <div class="depth__text">
                        <p class="">
                            의무기록 열람이나 사본발급은 의료법 제21조(기록 열람 등)에 의거 본인에 한해 허용되는 것이 원칙입니다. <br>
                            단, 친족이나 대리인의 경우 아래의 구비서류 지참하시면 발급 가능합니다.
                        </p><!-- depth03 -->
                    </div>
                </div>
            </div>
        </div>
        <div class="info-text">
            <h3 class="depth02__title title blue__title">환자가 동의한 경우</h3>
            <div class="table-wrap table-type1" ref="tableWrap" @click="addOnClickClass">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width:25%;">
                        <col style="width:25%;">
                        <col style="width:50%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th colspan="2"></th>
                            <th>구비서류</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b>환자</b></td>
                            <td><b>본인</b></td>
                            <td><b>본인신분증</b></td>
                        </tr>
                        <tr>
                            <td>친족</td>
                            <td>배우자, 직계존속, <br>
                                직계비속, 배우자의 직계존속
                            </td>
                            <td>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>수령인의 신분증사본</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>환자의 자필동의서 (14세 미만 제외)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>대리인</td>
                            <td>
                                친족이외의 지정대리인 <br>
                                (형제, 자매, 자부, 사위 보험회사 등)
                            </td>
                            <td>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>수령인의 신분증사본</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>환자의 신분증사본 (17세 미만 제외)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>환자의 자필동의서 (만 14세 미만은 법정대리인이 작성)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>환자의 자필위임장 (만 14세 미만은 법정대리인이 작성)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap info-reference">
                                    <div class="depth__bullet">
                                        <h4 class="depth03__title">※</h4>
                                    </div>
                                    <div class="depth__text">
                                        <h4 class="depth03__title">만 14세미만 환자와 법정대리인과의 관계 증명</h4>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="info-text">
            <h3 class="depth02__title title blue__title">환자 동의가 불가능한 경우</h3>
            <div class="table-wrap table-type1" ref="tableWrap" @click="addOnClickClass">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width:33.33%;">
                        <col style="width:33.33%;">
                        <col style="width:33.33%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>수령자</th>
                            <th>구비서류</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1. 환자사망 <br>
                                2. 의식불명 <br>
                                3. 행방불명 <br>
                                4. 의사무능력자
                            </td>
                            <td>
                                환자친족만 가능 <br>
                                (배우자, 직계존속, 직계비속, 배우자의 직계존속)
                            </td>
                            <td>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet">
                                        <p>1.</p>
                                    </div>
                                    <div class="depth__text">
                                        <p>신청자의 신분증사본</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet">
                                        <p>2.</p>
                                    </div>
                                    <div class="depth__text">
                                        <p>친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet">
                                        <p>3.</p>
                                    </div>
                                    <div class="depth__text">
                                        <p>환자의 동의가 불가함을 확인가능한 서류</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02 info-reference">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>사망사실확인 서류(가족관계증명서 등)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>의식불명 확인 진단서</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>행방불명확인 서류(주민등록등본, 법원 실종선고 결정문 등)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap depth02">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>의사무능력자 증명서류(법원선고결정문, 정신과 전문의 진단서)</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">양식다운로드</h4>
                </div>
            </div>
            <div class="text-box type1">
                <h3 class="depth02__title title">구비서류 준비 시 유의사항</h3>
                <ul class="text-box__list">
                    <li class="text-box__item">신분증 또는 신분증사본은 주민등록번호 및 사진이 있어야 합니다.(주민등록증/여권/운전면허증 그 밖에 공공기관에서 발급한 신분증)
                    </li>
                    <li class="text-box__item">친족관계확인서류는 환자-신청자 간의 관계가 반드시 명시되어야 합니다.(건강보험증은 친족관계가 입증되지 않으므로 인정되지 않습니다.)
                    </li>
                    <li class="text-box__item">환자와 신청자간의 관계가 변동 될 수 있으므로 의무기록 사본발급일 기준으로 3개월 이내에 발급받은 것만 인정됩니다.</li>
                    <li class="text-box__item">동의서 및 위임장의 자필서명은 도장 및 지장은 인정되지 않습니다.</li>
                </ul>
                <div class="depth__wrap info-reference">
                    <div class="depth__bullet">※</div>
                    <div class="depth__text">
                        <h4 class="depth03__title">의료법 시행규칙에 지정된 별지 서식이 아닌 동의서, 위임장은 법적으로 효력이 없습니다.</h4>
                    </div>
                </div>
                <div class="btn-wrap right">
                    <a href="javascript:;" class="btn blue">진료기록 열람 및 사본발급 동의서</a>
                    <a href="javascript:;" class="btn mint">진료기록 열람 및 사본발급 위임장</a>
                </div>
            </div>
        </div>
    </div>
    <img src="/src/assets/resource/img/" alt="이미지">
</template>


<script>
  export default {

}
</script>