<template>
    <div class="my_health_wrap my_result_wrap contents">
        <div class="contents health_info_wrap">
          <div class="tab-menu type02">
            <ul class="tab-menu__list center">
              <li class="tab-menu__item" :class="{ active: activeTab === 0 }">
                <button type="button" class="tab-menu__btn" @click="setActiveTab(0)">외래진료 내역</button>
              </li>
              <li class="tab-menu__item" :class="{ active: activeTab === 1 }">
                <button type="button" class="tab-menu__btn" @click="setActiveTab(1)">약 처방 내역</button>
              </li>
              <li class="tab-menu__item" :class="{ active: activeTab === 2 }">
                <button type="button" class="tab-menu__btn" @click="setActiveTab(2)">입퇴원 내역</button>
              </li>
              <li class="tab-menu__item" :class="{ active: activeTab === 3 }">
                <button type="button" class="tab-menu__btn" @click="setActiveTab(3)">검사결과</button>
              </li>
              <li class="tab-menu__item">
                <a href="javascript:;" type="button" class="tab-menu__btn">종합건강검진결과</a>
              </li>
              <li class="tab-menu__item">
                <a href="javascript:;" type="button" class="tab-menu__btn">국가건강검진결과</a>
              </li>
            </ul>
          </div>

          <div class="contents result_box01" v-show="activeTab === 0">
            <div class="text-box type1 info-text">
              <ul class="text-box__list">
                <li class="text-box__item">오늘날짜로부터 2년 이내의 내역만 보여지며, 아래 조건검색(1년 단위) 로 검색을 통해 조회하실 수 있습니다.</li>
                <li class="text-box__item">예약은 되었으나 실제 진료받지 못한 경우, 해당 내역은 조회되지 않습니다. </li>
              </ul>
            </div>
            <div class="date_wrap contents">
              <div class="board__select">
                <select name="" id="Sel_Inst" class="select_department">
                  <option value="">진료과 전체</option>
                  <option value="">소화기내과</option>
                  <option value="">성형외과</option>
                </select>
              </div>
              <div class="date-input">
                  <VueDatePicker v-model="selectedDateRange" locale="ko"
                      :enable-time-picker="false" :format="customFormat"
                      :week-start="0" :day-class="getDayClass"
                      :select-text="'선택'" :cancel-text="'취소'"
                      :range="true" :multi-calendars="true"/>
                <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
              </div>

              <div class="tab-menu type_date">
                <ul class="tab-menu__list">
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                  </li>
                </ul>
              </div>
            
              <div class="health_info_search">
                <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
              </div>
            </div>

            <div class="contents"> 
              <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                <div class="scroll">
                  <table>
                    <caption class="blind">결과조회-외래진료 폼</caption>
                    <colgroup>
                      <col style="width:17%;">
                      <col style="width:34%;">
                      <col style="width:34%;">
                      <col style="width:15%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>진료일자</th>
                        <th>진료과</th>
                        <th>진료의</th>
                        <th>다시예약</th>
                      </tr>
                    </thead>
                    <!-- <tbody class="no-data-table">
                      <tr>
                        <td colspan="4">검색된 내용이 없습니다.</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr>
                        <td>2024.05.04</td>
                        <td>소화기내과</td>
                        <td>박종호 과장</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">다시예약</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2024.05.01</td>
                        <td>소화청소년과</td>
                        <td>마상혁 과장</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">다시예약</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2024.04.07</td>
                        <td>호흡기내과</td>
                        <td>이일수 과장</td>
                        <td class="result_btn"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="contents result_box02" v-show="activeTab === 1">
            <div class="text-box type1 info-text">
              <ul class="text-box__list">
                <li class="text-box__item">입원환자는 퇴원 시 처방된 약만 조회됩니다.</li>
              </ul>
            </div>
            <div class="date_wrap contents">
              <div class="select_wrap_box">
                <div class="board__select">
                  <select name="" id="Sel_Inst" class="select_department">
                    <option value="">진료과 전체</option>
                    <option value="">소화기내과</option>
                    <option value="">성형외과</option>
                  </select>
                </div>
                <div class="board__select">
                  <select name="" id="Sel_Inst02" class="select_department">
                    <option value="">구분 전체</option>
                    <option value="">외래</option>
                    <option value="">입원</option>
                  </select>
                </div>
              </div>
             
              <div class="date-input">
                  <VueDatePicker v-model="selectedDateRange" locale="ko"
                      :enable-time-picker="false" :format="customFormat"
                      :week-start="0" :day-class="getDayClass"
                      :select-text="'선택'" :cancel-text="'취소'"
                      :range="true" :multi-calendars="true"/>
                <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
              </div>

              <div class="tab-menu type_date">
                <ul class="tab-menu__list">
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                  </li>
                </ul>
              </div>
            
              <div class="health_info_search">
                <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
              </div>
            </div>

            <div class="contents"> 
              <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                <div class="scroll">
                  <table>
                    <caption class="blind">결과조회-외래진료 폼</caption>
                    <colgroup>
                      <col style="width:14%;">
                      <col style="width:8%;">
                      <col style="width:32%;">
                      <col style="width:32%;">
                      <col style="width:14%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>진료일자</th>
                        <th>구분</th>
                        <th>진료과</th>
                        <th>진료의</th>
                        <th>결과</th>
                      </tr>
                    </thead>
                    <!-- <tbody class="no-data-table">
                      <tr>
                        <td colspan="5">검색된 내용이 없습니다.</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr>
                        <td>2024.05.04</td>
                        <td>외래</td>
                        <td>소화기내과</td>
                        <td>박종호 과장</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2024.05.01</td>
                        <td>외래</td>
                        <td>소화청소년과</td>
                        <td>마상혁 과장</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2024.04.07</td>
                        <td>입원</td>
                        <td>호흡기내과</td>
                        <td>이일수 과장</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="contents result_box03" v-show="activeTab === 2">
            <div class="date_wrap contents">
              <div class="board__select">
                <select name="" id="Sel_Inst" class="select_department">
                  <option value="">진료과 전체</option>
                  <option value="">소화기내과</option>
                  <option value="">성형외과</option>
                </select>
              </div>
              <div class="date-input">
                  <VueDatePicker v-model="selectedDateRange" locale="ko"
                      :enable-time-picker="false" :format="customFormat"
                      :week-start="0" :day-class="getDayClass"
                      :select-text="'선택'" :cancel-text="'취소'"
                      :range="true" :multi-calendars="true"/>
                <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
              </div>

              <div class="tab-menu type_date">
                <ul class="tab-menu__list">
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                  </li>
                </ul>
              </div>
            
              <div class="health_info_search">
                <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
              </div>
            </div>

            <div class="contents"> 
              <div class="table-wrap table-type1 scroll" @click="addClass">
                <div class="scroll">
                  <table>
                    <caption class="blind">결과조회-외래진료 폼</caption>
                    <colgroup>
                      <col style="width:15%;">
                      <col style="width:15%;">
                      <col style="width:35%;">
                      <col style="width:35%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>진료일자</th>
                        <th>퇴원일자</th>
                        <th>진료과</th>
                        <th>주치의</th>
                      </tr>
                    </thead>
                    <!-- <tbody class="no-data-table">
                      <tr>
                        <td colspan="4">검색된 내용이 없습니다.</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr>
                        <td>2024.05.04</td>
                        <td>2024.05.08</td>
                        <td>소화기내과</td>
                        <td>박종호 과장</td>
                      </tr>
                      <tr>
                        <td>2024.05.01</td>
                        <td>2024.05.02</td>
                        <td>소화청소년과</td>
                        <td>마상혁 과장</td>
                      </tr>
                      <tr>
                        <td>2024.04.07</td>
                        <td>2024.04.18</td>
                        <td>호흡기내과</td>
                        <td>이일수 과장</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

          <div class="contents result_box04" v-show="activeTab === 3">
            <div class="text-box type1 info-text">
              <ul class="text-box__list">
                <li class="text-box__item">기본 진단검사에 한해서만 결과를 제공하며 검사 3일 후에 조회 됩니다..</li>
              </ul>
            </div>
            <div class="date_wrap contents">
              <div class="board__select">
                <select name="" id="Sel_Inst" class="select_department">
                  <option value="">진료과 전체</option>
                  <option value="">소화기내과</option>
                  <option value="">성형외과</option>
                </select>
              </div>
              <div class="date-input">
                  <VueDatePicker v-model="selectedDateRange" locale="ko"
                      :enable-time-picker="false" :format="customFormat"
                      :week-start="0" :day-class="getDayClass"
                      :select-text="'선택'" :cancel-text="'취소'"
                      :range="true" :multi-calendars="true"/>
                <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
              </div>

              <div class="tab-menu type_date">
                <ul class="tab-menu__list">
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                  </li>
                  <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                    <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                  </li>
                </ul>
              </div>
            
              <div class="health_info_search">
                <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
              </div>
            </div>

            <div class="contents">
              <h3 class="deps-tit">2024.07.29</h3>
              <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                <div class="scroll">
                  <table>
                    <caption class="blind">결과조회-외래진료 폼</caption>
                    <colgroup>
                      <col style="width:23%;">
                      <col style="width:23%;">
                      <col style="width:24%;">
                      <col style="width:14%;">
                      <col style="width:16%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>검사명1</th>
                        <th>검사명2</th>
                        <th>결과</th>
                        <th>참고치</th>
                        <th>상세보기</th>
                      </tr>
                    </thead>
                    <!-- <tbody class="no-data-table">
                      <tr>
                        <td colspan="5">검색된 내용이 없습니다.</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr>
                        <td>CRP (Quant)</td>
                        <td>CRP (Quant)</td>
                        <td>1.12 (mg/L)</td>
                        <td>0.0~5.0</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Total Protein (Serum)</td>
                        <td>Total Protein (Serum)</td>
                        <td>5.4 (g/dL)</td>
                        <td>6.6~8.3</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>ALT (SGPT)</td>
                        <td>ALT (SGPT)</td>
                        <td>16 (IU/L)</td>
                        <td>3~45</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="contents">
              <h3 class="deps-tit">2024.01.30</h3>
              <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                <div class="scroll">
                  <table>
                    <caption class="blind">결과조회-외래진료 폼</caption>
                    <colgroup>
                      <col style="width:23%;">
                      <col style="width:23%;">
                      <col style="width:24%;">
                      <col style="width:14%;">
                      <col style="width:16%;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>검사명1</th>
                        <th>검사명2</th>
                        <th>결과</th>
                        <th>참고치</th>
                        <th>상세보기</th>
                      </tr>
                    </thead>
                    <!-- <tbody class="no-data-table">
                      <tr>
                        <td colspan="5">검색된 내용이 없습니다.</td>
                      </tr>
                    </tbody> -->
                    <tbody>
                      <tr>
                        <td>CRP (Quant)</td>
                        <td>CRP (Quant)</td>
                        <td>1.12 (mg/L)</td>
                        <td>0.0~5.0</td>
                        <td class="result_btn">
                          <div class="btn-wrap center">
                            <a href="javascript:;" class="btn">상세보기</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {
    components: {
        VueDatePicker,
  },
  setup() {
    const activeTab = ref(0);
    const customFormat = 'yyyy.MM.dd'; 
    const selectedDateRange = ref([new Date(), new Date()]);
    const selectedPeriod = ref(3); 

   
    const setSelectedPeriod = (months) => {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setMonth(startDate.getMonth() + months);
      selectedDateRange.value = [startDate, endDate]; 
      selectedPeriod.value = months; 
    };

   
    const getDayClass = (date) => {
      const day = date.getDay();
      if (day === 0) {
        return 'sunday-class';
      } else if (day === 6) {
        return 'saturday-class';
      }
      return '';
    };
      
    const addClass = (event) => {
      event.currentTarget.classList.add('on');
    };

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    }; 

    onMounted(() => {
      setSelectedPeriod(3); 
    });

    return {
      activeTab,
      customFormat,
      selectedDateRange,
      selectedPeriod,
      setSelectedPeriod,
      getDayClass,
      addClass,
      setActiveTab
    };
  }
};

</script>
