<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="btn-wrap right"><a href="https://www.nhis.or.kr/nhis/healthin/retrieveWrittenStatementPerson.do" class="btn blue" title="새 창 이동" target="_blank">문진표 작성</a></div>
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>국민건강보험 가입자가 검진주기 대상년도에 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
    
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">검진주기</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">검진주기</caption>
                <colgroup>
                    <col style="width:15%;">
                    <col style="width:20%;">
                    <col style="width:40%;">
                    <col style="width:25%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검진종류</th>
                        <th>검진주기</th>
                        <th>대상자</th>
                        <th>검사방법</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>위암</td>
                        <td rowspan="3">2년</td>
                        <td>만 40세 이상 남·여</td>
                        <td>내시경 또는 위장조영술</td>
                    </tr>
                    <tr>
                        <td>유방암</td>
                        <td>만 40세 이상 여성</td>
                        <td>맘모그래피(X-ray)</td>
                    </tr>
                    <tr>
                        <td>자궁경부암</td>
                        <td>만 20세 이상 여성</td>
                        <td>세포병리검사</td>
                    </tr>
                    <tr>
                        <td>대장암</td>
                        <td>1년</td>
                        <td>만 50세 이상 남·여</td>
                        <td>분변잠혈검사</td>
                    </tr>
                    <tr>
                        <td>간암</td>
                        <td>6개월(1년 2회:상·하반기)</td>
                        <td>만 40세 이상의 남·여 중 B형,C형 간염 보균자 및 간경변 고위험군으로 2년이내 검진 및 진료기록이 있는 분</td>
                        <td>간초음파 및 혈액(혈청태아단백)</td>
                    </tr>
                     <tr>
                        <td>폐암</td>
                        <td>2년</td>
                        <td>만 54세 이상 74세 이하 남·여 중 폐암발생 고위험군</td>
                        <td>흉부 CT</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="info-text depth__wrap depth01 contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">신청방법</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">활동내용 폼</caption>
                <colgroup>
                    <col style="width:20%;">
                    <col style="width:30%;">
                    <col style="width:50%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검진종류</th>
                        <th>신청방법</th>
                        <th>유의사항</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>위 암</td>
                        <td>방문예약</td>
                        <td>예약시 별도안내</td>
                    </tr>
                    <tr>
                        <td>유방암</td>
                        <td>당일검사</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>자궁경부암</td>
                        <td>당일검사</td>
                        <td>생리 전,후 1일주일은 피해주십시오.</td>
                    </tr>
                    <tr>
                        <td>대장암</td>
                        <td>당일검사</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>간 암</td>
                        <td>방문예약</td>
                        <td>예약시 별도안내</td>
                    </tr>
                      <tr>
                        <td>폐 암</td>
                        <td>방문예약</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">전화예약</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>055)270-1800</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:00</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">결과통보</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 15일 이내.</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 모바일(카카오톡), 우편, 내원수령.</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>