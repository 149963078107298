<template>
    <div class="container">
        <div class="inner">
            <div class="profile">
                <div class="profile__img">
                    <div class="profile__bg"></div>

                    <img :src="`/displayFile.do?drNo=${info.drNo}`" alt="이미지">
                    <div class="profile__img-tit">
                        <div class="profile__inner">
                            <span class="department-name">{{info.deptNmA ? info.deptNmA : info.deptNm}}</span>
                            <span class="name">{{info.hptlJobTitle ? info.hptlJobTitle : info.drName}}</span>
                            <button type="button" class="btn" :class="favoYn" @click="favoAddDel">
                                <span class="icon"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="profile__info">
                    <div class="profile__inner">
                        <div class="profile__info-tit">
                            <span class="department-name">{{info.deptNmA ? info.deptNmA : info.deptNm}}</span>
                            <span class="name">{{info.hptlJobTitle ? info.hptlJobTitle : info.drName}}</span>
                        </div>

                        <!-- active 추가 시 하트 변화-->
                        <button type="button" class="btn" :class="favoYn" @click="favoAddDel">
                            <span class="icon"></span>
                            <span style="margin-left: 5px;"> 관심 의료진</span>
                        </button>
                    </div>
                    <div class="contents profile__cont">
                        <div class="box">
                            <div class="profile__specialty">
                                <div class="text" v-if="special">
                                    <div class="tit">주진료분야</div>
                                    <div class="desc">{{special}}</div>
                                </div>
                                <div class="text" v-if="center.length > 0">
                                    <div class="tit">센터</div>
                                    <div class="desc">
                                        <a :href="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${i.deptCd}`" v-for="i in center" :key="i">{{i.deptNm}}</a>
                                    </div>
                                </div>
                                <div class="text" v-if="clinic.length > 0">
                                    <div class="tit">클리닉</div>
                                    <div class="desc">
                                        <a :href="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${i.deptCd}`" v-for="i in clinic" :key="i">{{i.deptNm}}</a>
                                    </div>
                                </div>
                                <div class="text" v-if="absence">
                                    <div class="tit">안내사항</div>
                                    <div class="desc" v-html="absence"></div>
                                </div>
                            </div>
                        </div>
                        <div class="contents box" id="diagnosis">
                            <div class="profile__tit">진료 일정</div>
                            <div class="calendar__header">
                                <button class="calendar__header--button prev" @click="prevCalendar"></button>
                                <h4 class="calendar__header--title">{{$commonUtils.dateToStr(setMonday(nowDate), '.')}} <span>~</span> {{$commonUtils.dateToStr(setSunday(nowDate), '.')}}</h4>
                                <button class="calendar__header--button next" @click="nextCalendar"></button>
                            </div>
                            <div class="weekly__calendar">
                                <div class="weekly__wrap">
                                    <div class="weekly__none-inner" v-if="!info.scheduleStatus">
                                        <div class="weekly__none">
                                            <p>조회 가능한 진료 일정이 없습니다.</p>
                                            <img src="@/assets/resource/icon/search-img.png" alt="이미지">
                                        </div>
                                    </div>
                                    <table>
                                        <caption class="blind">의료진시간표</caption>
                                        <colgroup>
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                            <col style="width: calc(100% / 7);">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>진료<br class="mo-br">시간</th>
                                                <th v-html="getWeek()[0]"></th>
                                                <th v-html="getWeek()[1]"></th>
                                                <th v-html="getWeek()[2]"></th>
                                                <th v-html="getWeek()[3]"></th>
                                                <th v-html="getWeek()[4]"></th>
                                                <th v-html="getWeek()[5]"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>오전</td>
                                                <td v-for="am in ampm.am" :key="am"><span :class="am.value"></span></td>
                                            </tr>
                                            <tr>
                                                <td>오후</td>
                                                <td v-for="pm in ampm.pm" :key="pm"><span :class="pm.value"></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="weekly__calendar-info">
                                    <div class="item">
                                        <span class="check"></span>
                                        <span>외래진료</span>
                                    </div>
                                </div>
                                <a href="javascript:;" class="weekly__calendar-btn" @click="goReservation" v-if="rsvYn == 'Y'">진료예약</a>
                                <a href="javascript:;" class="weekly__calendar-btn" @click="showPopup" v-if="rsvYn == 'N'">첫방문 간편예약</a>
                            </div>
                        </div>

                        <!-- 학력 섹션 -->
                        <div class="box bt" id="education" v-if="eduList.length > 0">
                            <div class="profile__tit">학력</div>
                            <ul class="profile__list">
                                <template v-for="(i, idx) in eduList" :key="i">
                                    <li class="profile__item" v-if="idx < eduCount">{{i.recordContent}}</li>
                                </template>
                            </ul>
                            <button v-if="eduList.length > 5" href="javascript:;" class="weekly__calendar-btn type2" @click="toggleList('eduCount', 'eduExpanded')">
                                {{ eduExpanded ? '접기 -' : '더보기 +' }}
                            </button>
                        </div>

                        <!-- 경력 섹션 -->
                        <div class="box" v-if="careerList.length > 0">
                            <div class="profile__tit">경력</div>
                            <ul class="profile__list">
                                <template v-for="(i, idx) in careerList" :key="i">
                                    <li class="profile__item" v-if="idx < careerCount">{{i.recordContent}}</li>
                                </template>
                            </ul>
                            <button v-if="careerList.length > 5" href="javascript:;" class="weekly__calendar-btn type2" @click="toggleList('careerCount', 'careerExpanded')">
                                {{ careerExpanded ? '접기 -' : '더보기 +' }}
                            </button>
                        </div>

                        <!-- 학회 섹션 -->
                        <div class="box" v-if="academyList.length > 0">
                            <div class="profile__tit">학회</div>
                            <ul class="profile__list">
                                <template v-for="(i, idx) in academyList" :key="i">
                                    <li class="profile__item" v-if="idx < academyCount">{{i.recordContent}}</li>
                                </template>
                            </ul>
                            <button v-if="academyList.length > 5" href="javascript:;" class="weekly__calendar-btn type2" @click="toggleList('academyCount', 'academyExpanded')">
                                {{ academyExpanded ? '접기 -' : '더보기 +' }}
                            </button>
                        </div>

                        <!-- 논문 섹션 -->
                        <div class="box bt" id="book" v-if="thesisList.length > 0">
                            <div class="profile__tit">주요논문</div>
                            <template v-for="(i, idx) in thesisList" :key="i">
                                <p class="desc" v-if="idx < thesisCount">{{i.title}}</p>
                            </template>
                            <button v-if="thesisList.length > 5" href="javascript:;" class="weekly__calendar-btn type2" @click="toggleList('thesisCount', 'thesisExpanded')">
                                {{ thesisExpanded ? '접기 -' : '더보기 +' }}
                            </button>
                        </div>

                        <!-- 언론보도 섹션 -->
                        <div class="box bt" id="news" v-if="newsList">
                            <div class="profile__tit">언론보도</div>
                            <ul class="profile__list">
                                <template v-for="(i, idx) in newsList.list" :key="i">
                                    <li class="profile__item" v-if="idx < newsCount">
                                        <div class="tit">{{$commonUtils.dateToStr(new Date(i.createdDt), '.')}}</div>
                                        <div class="desc" style="cursor: pointer" @click="goNews(i)">{{i.title}}</div>
                                    </li>
                                </template>
                            </ul>
                            <button v-if="newsList.totCnt > newsCount" href="javascript:;" class="weekly__calendar-btn type2" @click="toggleList('newsCount', 'newsExpanded')">
                                {{ newsExpanded ? '접기 -' : '더보기 +' }}</button>
                        </div>
                    </div>
                </div>
                <div class="profile__rnb">
                    <ul class="profile__rnb-list">
                        <li class="profile__rnb-item diagnosis" :class="{ on: activeSection === 'diagnosis' }">
                            <a href=" javascript:;" @click.prevent="scrollTo('diagnosis')">진료일정</a>
                        </li>
                        <li class="profile__rnb-item education" :class="{ on: activeSection === 'education' }">
                            <a href="javascript:;" @click.prevent="scrollTo('education')">학력&#183;경력</a>
                        </li>
                        <li class="profile__rnb-item book" :class="{ on: activeSection === 'book' }">
                            <a href=" javascript:;" @click.prevent="scrollTo('book')">주요논문</a>
                        </li>
                        <li class="profile__rnb-item news" :class="{ on: activeSection === 'news' }">
                            <a href="javascript:;" @click.prevent="scrollTo('news')">언론보도</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <FirstReservePopUp :popupVisible="popupVisible" @hide-popup="hidePopup"/>
</template>

<script>
    import FavoriteHeartVue from '../common/FavoriteHeart.vue'
    import FirstReservePopUp from '@/components/reserve/FirstReservePopUp.vue'

    export default {
        components: {
            FavoriteHeartVue,
            FirstReservePopUp
        },
        data() {
            return {
                activeSection: '',

                drNo: this.$route.query.drNo,
                info: '',
                special: '',
                clinic: [],
                center: [],
                mainDept: '',
                absence: '',
                doctorDeptList: [],

                nowDate: new Date,
                startDate: '',
                endDate: '',
                schedule: [],
                ampm: {},

                eduList: [],
                eduCount: 5,
                eduExpanded: false,

                careerList: [],
                careerCount: 5,
                careerExpanded: false,

                academyList: [],
                academyCount: 5,
                academyExpanded: false,

                thesisList: [],
                thesisCount: 5,
                thesisExpanded: false,

                newsList: '',
                newsCount: 10,
                favoYn: '',
                popupVisible: false,
                rsvYn: 'N',
            }
        },
        created() {
            if (!this.drNo) {
                alert('잘못된 접근입니다.')
                self.close();
            }
        },
        methods: {
            showPopup() {
                this.popupVisible = true;
            },
            hidePopup() {
                this.popupVisible = false;
            },
            goNews(article) {
                if (!article.linkUrl) {
                    return
                }
                window.open(article.linkUrl)
            },
            scrollTo(id) {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            },
            handleIntersection(entries) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.activeSection = entry.target.id;
                    }
                });
            },
            // 의사 정보
            getDoctorInfo() {
                this.axios.get(`/api/doctorApi/${this.drNo}`)
                    .then(res => {
                        this.info = res.data
                        this.doctorDeptList = this.info.doctorDeptList
                        this.favoYn = this.info.favoYn == 'Y' ? 'active' : ''
                        this.special = res.data.doctorDept.special
                        let deptCdChk = false
                        for (let i of this.doctorDeptList) {
                            if (i.deptClsf == 'A') this.info.deptNmA = i.deptNm
                            if (i.deptClsf == 'B') this.clinic.push(i)
                            if (i.deptClsf == 'C') this.center.push(i)
                            if (i.mainDeptYn == 'Y') {
                                this.mainDept = i
                                this.rsvYn = i.drRsvOpenYn
                                this.getSchedule()
                            }
                            if(i.deptCd == 'HM' || i.deptCd == 'OM') {
                                deptCdChk = true
                            }
                        }
                        this.rsvYn = deptCdChk ? '' : 'Y'
                        this.getAbsence()
                    }).catch(err => {
                        console.error(err)
                    })
            },
            async getAbsence() {
                await this.axios.get(this.api.doctor.absence, {
                    params: {
                        drNo: this.drNo
                    }
                }).then(res => {
                    this.absence = ''
                    const startDt = new Date(res.data.staDt)
                    const endDt = new Date(res.data.endDt)
                    const today = new Date()
                    if(today >= startDt && today <= endDt){
                        this.absence = res.data.reason
                    }
                })
            },
            getSchedule() {
                this.axios.get(this.api.doctor.schedule, {
                    params: {
                        fromdd: this.$commonUtils.dateToStr(this.setMonday(this.nowDate), ''),
                        todd: this.$commonUtils.dateToStr(this.setSunday(this.nowDate), ''),
                        orddeptcd: this.mainDept.emrDeptCd ? this.mainDept.emrDeptCd : this.mainDept.deptCd,
                        orddrid: this.info.empId ? this.info.empId : this.drNo,
                        exposeYn: 'Y'
                    }
                })
                    .then(res => {
                        this.schedule = res.data[0].schedule
                        let count = 0
                        const amObj = {
                            "MON": "",
                            "TUE": "",
                            "WED": "",
                            "THU": "",
                            "FRI": "",
                            "SAT": ""
                        }
                        const pmObj = {
                            "MON": "",
                            "TUE": "",
                            "WED": "",
                            "THU": "",
                            "FRI": "",
                            "SAT": ""
                        }
                        for (let j of this.schedule) {
                            if (j.jinyn == 1 && j.day != "SUN") {
                                const obj = j.ampm == 'AM' ? amObj : pmObj
                                obj[j.day] = "check"
                                count++
                            }
                        }

                        this.ampm.am = this.transObj(amObj)
                        this.ampm.pm = this.transObj(pmObj)
                        this.info.scheduleStatus = count > 0 ?  true : false
                    })
                    .catch(err => {
                        console.error(err)
                    })
            },
            transObj(obj) {
                const keys = Object.keys(obj)
                const arr = keys.map(key => ({
                    key: key,
                    value: obj[key]
                }))
                return arr
            },
            setMonday(now) {
                now.setHours(0, 0, 0, 0)
                const day = now.getDay()
                const diff = now.getDate() - day + (day == 0 ? -6 : 1)
                const monday = new Date(now.setDate(diff))

                return monday
            },
            setSunday(now) {
                const day = now.getDay()
                const diff = now.getDate() - day + 7
                const sunday = new Date(now.setDate(diff))

                return sunday;
            },
            getWeek() {
                const monday = new Date(this.nowDate);
                const dayOfWeek = this.nowDate.getDay();
                monday.setDate(this.nowDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

                const saturday = new Date(monday);
                saturday.setDate(monday.getDate() + 5);

                const result = [];
                const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
                for (let day = new Date(monday); day <= saturday; day.setDate(day.getDate() + 1)) {
                    const dayOfWeek = weekdays[day.getDay()];
                    const date = `${day.getMonth() + 1}/${day.getDate()}`;
                    result.push(`${dayOfWeek}<br class="mo-br">(${date})`);
                }
                return result;
            },
            prevCalendar() {
                const prev = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() - 7)
                if (prev < new Date()) {
                    alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                    return
                }
                this.nowDate = prev
                this.getSchedule()
            },
            nextCalendar() {
                const next = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 7)
                const now = new Date()
                if (next > new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30)) {
                    alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                    return
                }
                this.nowDate = next
                this.getSchedule()
            },
            goReservation() {
                if (this.absence) {
                    alert(this.absence)
                }
                for (let i of this.info.doctorDeptList) {
                    if (i.deptNm == this.info.deptNm) {
                        this.info.doctorDept.rsvOpenYn = i.rsvOpenYn
                        if(i.rsvOpenYn == 'N'){
                            alert('온라인으로 예약할 수 없는 진료과입니다.\n270-1000 번으로 문의 부탁드립니다.')
                            return
                        }
                        break
                    }
                }
                this.$store.commit('setReserveInfo', this.info)
                this.$router.push('/reserve/reservation.do')
            },
            getDoctorRecord() {
                this.axios
                    .get(this.api.doctor.record, {
                        params: {
                            drNo: this.drNo,
                            langType: 'kr'
                        }
                    })
                    .then(res => {
                        for (var i = 0; i < res.data.length; i++) {
                            if (res.data[i].recordType == 'A') { // 학력
                                this.eduList.push(res.data[i]);
                            }
                            if (res.data[i].recordType == 'B') { // 경력
                                this.careerList.push(res.data[i]);
                            }
                            if (res.data[i].recordType == 'F') { // 학회
                                this.academyList.push(res.data[i]);
                            }
                        }
                    })
            },
            getDoctorThesis() {
                this.axios
                    .get(this.api.doctor.thesis, {
                        params: {
                            drNo: this.drNo,
                        }
                    })
                    .then(res => {
                        this.thesisList = res.data
                    })
            },
            // 언론보도
            getNewsList(pageRow) {
                this.axios.get(this.api.article.newsList, {
                    params: {
                        startIndex: 1,
                        pageRow: pageRow,
                        drNo: this.drNo,
                        instNo: 1
                    }
                })
                    .then(res => {
                        this.newsList = res.data.totCnt > 0 ? res.data : ''
                    })
            },
            more(count, len) {
                this[count] = len
            },
            toggleList(count, expanded) {
                if (this[expanded]) {
                    this[count] = 5;
                } else {
                    this[count] = this[count.replace('Count', 'List')].length;
                }
                this[expanded] = !this[expanded];
            },
            favoAddDel() {
                if (this.$store.getters.getStatus) {
                    const txt = '의료진'
                    if (this.info.favoYn == 'Y') {
                        if (confirm(`관심 ${txt}을 해제하시겠습니까?`)) {
                            this.axios
                                .delete(this.api.favorite.delete, {
                                    params: {
                                        favoNo: this.info.drNo,
                                        favoTypeCd: 'DOCTOR'
                                    }
                                })
                                .then(res => {
                                    if (res.status == 200) {
                                        this.info.favoYn = ''
                                        this.favoYn = ''

                                        alert(`해당 ${txt}이 즐겨찾기에서 해제되었습니다.`);
                                    } else {
                                        alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                                    }
                                })
                        }
                    } else {
                        this.axios.get(this.api.favorite.add, {
                            params: {
                                favoNo: this.info.drNo,
                                favoTypeCd: 'DOCTOR'
                            }
                        })
                            .then(res => {
                                if (res.status == 200) {
                                    this.info.favoYn = 'Y'
                                    this.favoYn = 'active'

                                    alert(`해당 ${txt}이 즐겨찾기에 등록되었습니다. 마이페이지 > 관심 정보에서 확인하실 수 있습니다.`);
                                } else {
                                    alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                                }
                            })
                    }
                } else {
                    alert('로그인 후 이용하실 수 있습니다.')
                }
            },
        },
        mounted() {
            this.getDoctorInfo()
            this.getDoctorRecord()
            this.getDoctorThesis()
            this.getNewsList(10);

            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5, // 섹션의 절반 이상이 보일 때 active 설정
            };


        },
        updated() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5, // 섹션의 절반 이상이 보일 때 active 설정
            };

            const observer = new IntersectionObserver(this.handleIntersection, options);

            const sections = document.querySelectorAll('#diagnosis, #education, #book, #news');
            sections.forEach(section => {
                observer.observe(section);
            });
        },
        computed(){
            this.doctorDeptList.filter(i => {
                return i.mainDeptYn == 'Y' && i.deptClsf == 'A'
            })
        }
    }
</script>
