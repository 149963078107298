<template>
    <div class="board__search">
        <div class="board__input">
            <input type="text" placeholder="검색어를 입력해주세요.">
            <button type="button" class="board__input-btn"></button>
        </div>
    </div>

    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">전체</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">구강</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">귀코목</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">내분비</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">뇌신경</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">눈</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">면역</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">비뇨기</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">뼈근육</button>
            </li>
        </ul>
        <ul class="tab-menu__list">
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">생식기</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">소화기</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">순환기</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">암</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">정신건강</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">피부</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">호흡기</button>
            </li>
            <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">기타</button>
            </li>
        </ul>
    </div>
    <ul class="contents masonry">
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__img">
                    <img src="@/assets/resource/img/kv_bg01.png" alt="이미지">
                </div>
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방</h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방</h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__img">
                    <img src="@/assets/resource/img/kv_bg01.png" alt="이미지">
                </div>
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방 </h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방 </h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방 상처관리와 흉터예방</h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__img">
                    <img src="@/assets/resource/img/kv_bg01.png" alt="이미지">
                </div>
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방 상처관리와 흉터예방 상처관리와 흉터예방 상처관리와 흉터예방</h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
        <li class="masonry__item">
            <a href="javascript:;" class="masonry__link">
                <div class="masonry__img">
                    <img src="@/assets/resource/img/kv_bg01.png" alt="이미지">
                </div>
                <div class="masonry__text">
                    <h4>상처관리와 흉터예방 상처관리와 흉터예방</h4>
                    <p class="desc">피부가 자외선을 받게 되면 비교적 짧은짧은짧은짧은</p>
                    <div class="arrow-wrap">
                        <span class="tag">#약물처치</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</template>


<script>
    export default {
        data() {
            return {
                itemLength: 0,
                itemWidth: 0,
                itemMargin: ''
            };
        },
        mounted() {
            this.updateMasonryLayout();
            window.addEventListener('resize', this.updateMasonryLayout);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateMasonryLayout);
        },
        methods: {
            updateMasonryLayout() {
                if (window.innerWidth >= 1440) {
                    // PC
                    this.itemLength = 4;
                    this.itemWidth = 25;
                    this.itemMargin = '20px';
                } else if (window.innerWidth >= 721) {
                    // 테블릿
                    this.itemLength = 2;
                    this.itemWidth = 49;
                    this.itemMargin = '10px';
                } else {
                    // 모바일
                    this.itemLength = 1;
                    this.itemWidth = 100;
                    this.itemMargin = '10px';
                }
                this.setupMasonryLayout();
            },
            setupMasonryLayout() {
                const masonryItems = document.querySelectorAll('.masonry__item');
                let columnHeights = Array.from({ length: this.itemLength }, () => 0);

                masonryItems.forEach(item => {
                    const minHeight = Math.min(...columnHeights);
                    const columnIndex = columnHeights.indexOf(minHeight);
                    item.style.marginBottom = this.itemMargin;
                    item.style.position = 'absolute';
                    item.style.left = `${columnIndex * this.itemWidth}%`;
                    item.style.top = `${columnHeights[columnIndex]}px`;
                    columnHeights[columnIndex] += item.offsetHeight + parseInt(this.itemMargin);
                });

                const maxHeight = Math.max(...columnHeights);
                document.querySelector('.masonry').style.height = `${maxHeight}px`;
            }
        }
    };
</script>