<template>
    <div class="reserv_intro">
        <div class="contents">
            <ul class="reserv_list">
                <li class="reserv_item first_reserve">
                    <div class="info-text">
                        <h3 class="depth01__title title">첫방문 간편예약</h3>
                        <p>처음오시는 고객의 편의를 위하여 전문 상담원이<br>
                            전화를 통해 진료예약을 도와 드리는 서비스입니다.</p>
                    </div>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn blue" @click="showPopup">간편예약 바로가기</a>
                    </div>
                    <p class="text__bold">상담시간 : 평일 8:30 ~ 16:30,<br>토/공휴일 제외</p>
                </li>

                <li class="reserv_item online_reserve">
                    <div class="info-text">
                        <h3 class="depth01__title title">온라인 간편예약</h3>
                        <p>창원파티마병원 홈페이지를 통해<br>
                            편리하게 진료 예약 서비스를 이용하세요.</p>
                    </div>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn mint">온라인예약 바로가기</a>
                    </div>
                </li>
            </ul>

        </div>

        <div class="contents">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">전화예약</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet"><span class="bullet04"></span></div>
                                        <div class="depth__text">
                                            <p>270-1000(컨택센터) 전화로 편리하게<br>예약하실 수 있습니다.</p>
                                        </div>
                                    </div>
                                    <p class="blue__title">상담시간 : 평일 7:30 ~ 17:30 /<br>토요일 7:30 ~ 12:30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">진료협력센터 초진예약</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet"><span class="bullet04"></span></div>
                                        <div class="depth__text">
                                            <p>1,2차 의료기관에서 진료의뢰서를 받으신 초진환자분은 진료협력센터에서 진료예약이 가능합니다.</p>
                                            <p>진료협력센터 : 270-1108</p>
                                        </div>
                                    </div>
                                    <p class="blue__title">상담시간 : 평일 8:30 ~ 17:30 /<br>토요일 8:30 ~ 12:30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="first_reserve_popup" v-show="popupVisible">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>첫방문 간편예약</h4>
                <a href="javascript:;" @click="hidePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="contents first_desc">
                <div class="text-box type1 info-text">
                    <p>간편예약은 처음오시는 고객의 편의를 위하여 전문 상담원이 전화를 통해 진료예약을<br>
                        도와 드리는 서비스입니다.</p>
                    <h3>상담시간</h3>
                    <p>평일 : 8:30 ~ 17:00, 토/공휴일 제외</p>
                </div>
            </div>

            <div class="contents text-box type2 first_info_box">
                <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의
                    <span class="c-red">(필수)</span>
                </h3>
                <div class="text-box__wrap">
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                </div>
            </div>
            <div class="agree-input">
                <label for="agree-y" class="agree-input__item">
                    <input type="radio" id="agree-y" class="agree-input__radio" name="agree">
                    <span class="icon"></span> 동의합니다 </label>
                <label for="agree-n" class="agree-input__item">
                    <input type="radio" id="agree-n" class="agree-input__radio" name="agree">
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>

            <div class="contents first_input_box">
                <div class="input-form type1">
                    <p class="input-form__top-text">
                        <span class="c-red">* </span> 필수 입력항목
                    </p>
                    <ul class="input-form__list">
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>이름
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" placeholder="홍길동">
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>휴대전화
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" placeholder="000-0000-0000">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="btn-wrap center first_btn">
                <a href="javascript:;" class="btn" @click="hidePopup">취소</a>
                <a href="javascript:;" class="btn blue">간편예약 신청</a>
            </div>




        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                popupVisible: false
            };
        },
        methods: {
            showPopup() {
                this.popupVisible = true;
            },
            hidePopup() {
                this.popupVisible = false;
            }
        }
    };
</script>