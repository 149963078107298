<template>
  <div class="greetings contents">
      <div class="greetings_tit">
          <p data-aos="fade-up" data-aos-duration="1000">홈페이지를 방문하신 여러분</p>
          <h4 class="red__title" data-aos="fade-up" data-aos-duration="1500">사랑으로 환영합니다</h4>
      </div>

      <div class="contents">
          <div class="greetings_con_wrap">
              <div class="greeting_img-wrap">
                  <div class="greeting_img">
                      <img src="/resource/img/greetings-canter.png" alt="병원장 박정순 수녀" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                  </div>
              </div>
              <div class="greeting_txt contents" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
          
                  <h5>안녕하십니까?<br>창원파티마병원 진료협력센터 센터장입니다.</h5>
                  <p class="mt40">
                    진료협력센터는 개인의원에서 전문화된 진료가 필요할 때 본원으로 신속한 외래 진료 예약을 제공합니다. <br>
                    또한, 3차 병원에서 본원으로 검사나 경과 관찰이 필요할 때, 빠른 진료 예약을 돕고 있습니다. 반대로, 본원에서 경과가 좋아진 <br>
                    환자를 개인의원으로 연계하고, 본원에서 3차 병원의 진료가 필요할 경우에도 빠른 예약을 지원합니다.
                  </p>
                  <p class="mt40">
                    입원 환자의 3차 병원이나 요양병원 전원 시에도 병원 탐색과 자료 정리를 통해 원활한 전원이 이루어질 수 있도록 최선을 다하고 있습니다.
                  </p>
                  <p class="mt40">
                    저희 창원파티마병원 진료협력센터는 환자 중심의 의료서비스를 제공하며, 지역 의료기관과의 협력을 통해 환자분들의 건강을 지키기 위해
                    노력하고 있습니다. 많은 관심과 성원을 부탁드리며, 언제든지 필요하신 사항이 있으시면 저희 센터를 찾아 주시기 바랍니다.
                  </p>
                
                  <p class="mt40">
                    감사합니다.
                  </p>
                  <div class="greeting_name" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
                      <p>진료협력센터장</p>
                      <h6>이상민 과장</h6>
                  </div>
              </div>

              <!-- <div class="greeting_name_wrap">
                  
              </div> -->
              <div class="greeting_back_wrap" data-aos="zoom-in">
                  <div class="greeting_back"></div>
              </div>
              <p class="greetings_back_tit" data-aos="fade-right" data-aos-duration="1500">GREETING</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {};
</script>