<template>
  <div class="myHealthCheckup_reservation contents">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet">
        <span class="bullet01"></span>
      </div>
      <div class="depth__text">
        <h4 class="depth01__title title">결과조회</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table class="myHealthcheckup_table">
          <caption class="blind">
            결과조회 폼
          </caption>
          <colgroup>
            <col style="width: 9%" />
            <col style="width: 17%" />
            <col style="width: 17%" />
            <col style="width: 17%" />
            <col style="width: 25%" />
            <col style="width: 15%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>검진일자</th>
              <th>검진구분</th>
              <th>검진명</th>
              <th>추가선택검사</th>
              <th>결과조회</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2024.6.30</td>
              <td>건강증진센터</td>
              <td>여성정밀코스</td>
              <td>스트레스 검사, PET-CT(상반신)</td>
              <td>
                <div class="btn-wrap center">
                  <a href="javascript:;" class="btn hover_btn">상세보기</a>
                </div>
              </td>
            </tr>
              <tr>
              <td>2</td>
              <td>2024.6.30</td>
              <td>직업환경의학센터</td>
              <td>일반검진</td>
              <td>-</td>
              <td>
                <div class="btn-wrap center">
                  <a href="javascript:;" class="btn hover_btn">상세보기</a>
                </div>
              </td>
            </tr>
              <tr>
              <td>3</td>
              <td>2024.6.30</td>
              <td>직업환경의학센터</td>
              <td>국가암검진</td>
              <td>-</td>
              <td>
                <div class="btn-wrap center">
                  <a href="javascript:;" class="btn hover_btn">상세보기</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="info-text depth__wrap depth01">
      <div class="depth__text">
          <div class="depth__wrap info-reference">
              <div class="depth__bullet">
                  <p class="small-text">※</p>
              </div>
              <div class="depth__text">
                  <p class="small-text">
                      건강검진 결과조회는 결과 확인만을 위한 용도로 제증명 서류가 필요한 경우 해당 부서로 문의해 주시기 바랍니다.
                  </p>
              </div>
          </div>
      </div>
    </div>

    
    <!-- <div class="contents detail_reserve02">
      <ul class="detail_reserve_info_list">
        <li class="detail_reserve_info_item">
          <p>검진일자 :</p>
          <h4>&nbsp;2024.6.30</h4>
        </li>
        <li class="detail_reserve_info_item">
          <p>검진구분 :</p>
          <h4>&nbsp;건강증진센터</h4>
        </li>
        <li class="detail_reserve_info_item">
          <p>검진명 :</p>
          <h4>&nbsp;여성정밀코스</h4>
        </li>
        <li class="detail_reserve_info_item">
          <p>선택추가검사 :</p>
          <h4>&nbsp;스트레스 검사, PET-CT(상반신)</h4>
        </li>
      </ul>

    </div> -->
  </div>
</template>

<script>
export default {};
</script>