<template>
    <ul class="initial-search__list">
        <li><a href="javascript:;" class="all" :class="consonant == '' ? 'active' : ''" @click="emitConsonant('')">ALL</a></li>
        <li v-for="(item, index) in list" :key="index">
            <a href="javascript:;" :class="`${consonant == item.cho ? 'active' : ''} ${item.disable}`" @click="emitConsonant(item.cho)">{{item.cho}}</a>
        </li>
    </ul>
</template>


<script>
export default {
    props: {
        list: []
    },
    data() {
        return {
            consonant: '',
        }
    },
    methods: {
        emitConsonant(consonant){
            this.consonant = consonant
            this.$emit('consonant', consonant)
        },
    }
}
</script>
