<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>식품 위생과 관련된 직종에 근무하기 위해 보건증 발급을 목적으로 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검사항목</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>대변배양검사</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>흉부방사선(X-Ray)</p>
                  </div>
              </div>
        </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:00</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">소요시간 : 30분</h4>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">당일 수검자 현황에 따라 시간이 달라질 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증</p>
                  </div>
              </div>
          </div>
    </div>
   
   
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">서류발급</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 검사 후 6일</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 우편, 내원수령</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>