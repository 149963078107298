<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item">
                    <router-link to="/mypage/board/thanks.do" v-slot="thanks">
                        <button type="button" class="tab-menu__btn" @click="thanks">칭찬합니다</button>
                    </router-link>
                </li>
                <li class="tab-menu__item">
                    <router-link to="/mypage/board/suggest.do" v-slot="suggest">
                        <button type="button" class="tab-menu__btn" @click="suggest">고객의 소리</button>
                    </router-link>
                </li>
                <li class="tab-menu__item active">
                    <router-link to="/mypage/board/counsel.do" v-slot="counsel">
                        <button type="button" class="tab-menu__btn" @click="counsel">건강상담</button>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

    <div class="contents">
        <ul>
            <li class="c-gray5">회원님께서 작성하신 글을 최신순으로 확인하실 수 있습니다.</li>
            <li class="c-gray5">글 수정은 접수 상태일 때 제목 클릭 후 수정 가능합니다.</li>
        </ul>
    </div>
    <div class="contents board-list">
        <div class=" board-list__table">
            <table>
                <colgroup>
                    <col style="width: 10%;">
                    <col style="width: 20%;">
                    <col style="width: auto">
                    <col style="width: 13%;">
                    <col style="width: 13%;">
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th>등록일자</th>
                        <th>제목</th>
                        <th>공개여부</th>
                        <th>진행상황</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td colspan="5" v-if="orgList.length == 0">내역이 없습니다.</td></tr>
                    <tr v-for="(article, idx) in list" :key="idx">
                        <td class="check">
                            <input type="checkbox" :id="`check${idx}`" :value="article.articleNo" v-model="article.isChecked">
                            <label :for="`check${idx}`"></label>
                        </td>
                        <td class="date">{{ $commonUtils.timestampToDate(article.createdDt) }}</td>
                        <td class="left tit">
                            <router-link :to="`/mypage/board/counselView.do?boardNo=6&articleNo=${article.articleNo}`" class="board-list__link">
                                <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                                <p>{{ article.title }}</p>
                            </router-link>
                            <div class="check">
                                <input type="checkbox" id="check3">
                                <label for="check3"></label>
                            </div>
                        </td>
                        <td class="okay">{{article.item3 == 'Y' ? '공개' : '비공개'}}</td>
                        <td class="okay">{{article.item18 == 'R' ? '접수' : article.item18 == 'O' ? '승인완료' : '답변완료'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="contents btn-wrap right"><a href="javascript:;" class="btn red" @click="deleteArticle" v-if="list.length > 0">삭제</a></div>

            <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            orgList: [],
            list: [],
            pageList: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 10,
        }
    },
    watch: {
        $route(to, from) {
            if(to.query.currentNum != from.query.currentNum){
                this.currentNum = to.query.currentNum
                this.sliceList()
            }
        }
    },
    methods: {
        getList(){
            this.list = []
            const boardNo = 6
            const params = {
                boardNo: boardNo,
            }
            this.axios.get(this.api.mypage.articleList, {
                params: params
            })
                .then(res => {
                    this.orgList = res.data.list
                    this.list = res.data.list
                    this.totCnt = res.data.list.length
                    this.sliceList()
                })
        },
        deleteArticle() {
            if (confirm('삭제하시겠습니까?')) {
                const boardNo = 6
                const articleNo = this.list.filter(article => article.isChecked).map(article => article.articleNo)
                
                this.axios.delete(`${this.api.article.view}${boardNo}`, {
                    params: {
                        boardNo: boardNo,
                        articleNos: articleNo.join(',')
                    }
                }).then(res => {
                    this.currentNum = 1
                    this.getList()
                })
            }
        },
        sliceList(){
            const start = (this.currentNum - 1) * this.pageRow
            const end = this.currentNum * this.pageRow
            this.list = this.orgList.slice(start, end)
        },

        setNum(n){
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = {currentNum: this.currentNum};
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj});
        },

    },
    mounted() {
        this.getList()
    }

}

</script>