<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>국민건강보험 가입자에게 제공되는 국가암 건강검진을 다양한 방법으로 쉽고 빠르게 예약할 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">예약이 필요한 암검진</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">검사항목</caption>
                <colgroup>
                    <col style="width:80%;">
                    <col style="width:20%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검진항목</th>
                        <th>신청방법</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>위암(위내시경, 위장조영술 중 택1), 폐암(폐CT), 간암(간초음파)</td>
                        <td>전화, 방문, 간편예약</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">예약이 필요 없는 암검진</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">검사항목</caption>
                <colgroup>
                    <col style="width:80%;">
                    <col style="width:20%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검진항목</th>
                        <th>신청방법</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>대장암(분변검사), 유방암, 자궁경부암</td>
                        <td>방문당일검진</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
     <div class="depth__wrap info-reference">
        <div class="depth__bullet">
            <p class="small-text">※</p>
        </div>
        <div class="depth__text">
            <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
        </div>
    </div>

     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">간편예약</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text kcc_btn_wrap">
                      <p>이름, 전화번호, 검진항목 등을 남기시면 전문 상담원이 연락하여 예약을 도와드립니다.</p>
                      <div class="btn-wrap right"><a href="/ReservationInquiry/CancerReserveIntro.do" class="btn blue">국가암검진 간편예약신청</a></div>
                  </div>
              </div>
        </div>
    </div>
    
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">전화예약</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>055) 270-1800 (상담시간 평일 8:30~16:40 / 토 8:30~12:00)</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">방문예약</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>지하1층 직업환경의학센터 방문</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>