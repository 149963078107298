<template>
    <!-- 심혈관센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심혈관센터는 심장초음파, 운동부하검사 및 심장혈관촬영 등 심혈관계에 대한 각종 검사와 심박동기 삽입, 관상동맥 풍선성형술 및 스텐드 삽입술 등의 중재적 시술을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">중재적 시술, 응급환자 더욱 신속하게</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 혈관조영실에서는 심장내과, 신경과, 신경외과, 영상의학과 전문의가 3대의 혈관조영장비를 이용해 심장, 두경부, 복부 및 말초혈관의 진단 및 색전술, 성형술, 확장술 등의 중재적 시술을 시행하고 있으며, 특히 응급환자의 진단 및 시술을 위해 24시간 운용하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">주요검사</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>혈관조영실</p>
                                <p>혈관조영기, 혈관 내 초음파, 심근 분획 혈류 예비력 검사, 동맥 경화반 절삭기, 대동맥 내 풍선 펌프, 경피적 관상동맥 스텐트 삽입술, 경피적 관상동맥 풍선 성형술, 혈관내 혈전 제거술, 인공심박동기, 이식형 제세동기, 심낭 천자, 초소형 무선 심박동기</p>
                            </div>
                        </div>
                        <br>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심전도실</p>
                                <p>심전도, 운동부하 검사, 홀터 검사 , 72시간 이상 패치형 심전도 모니터링, 삽입·비삽입형 사건 기록지, 활동 혈압계, 인공 심박동기 분석, 심장 재활</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>진찰실 내 자동 혈압 측정실</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심초음파실</p>
                                <p>흉부 심초음파, 경식도 심초음파, 도부타민 스트레스 심초음파, 기립경검사 </p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>핵의학</p>
                                <p>심근 스캔</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>CT실</p>
                                <p>관상동맥 조영 CT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="img-box-wrap">
    <img src="/resource/img/blood_vessel01.jpg" alt="이미지">
    <img src="/resource/img/blood_vessel02.jpg" alt="이미지">
    <img src="/resource/img/blood_vessel03.JPG" alt="이미지">
    <img src="/resource/img/blood_vessel04.jpg" alt="이미지">
</div>
</template>


<script>
export default {
   
};
</script>