<template>
    <div class="board-list" @click="resetActiveCont">
        <div class="board-gallery">
            <div class="contents board-gallery__list type2" @click.stop>
                <div class="item-wrap" v-for="(group, groupIndex) in galleryGroups" :key="groupIndex">
                    <div class="item-group">
                        <div class="board-gallery__item" v-for="(item, index) in group" :key="index" :class="{ show: activeCont === item.articleNo }" @click="showCont(item.articleNo, $event,item)">
                            <a href="javascript:;">
                                <img :src="`/displayFile.do?name=${item.attachBag.thumbs[0].savedName}`" alt="이미지">
                                <div class="board-gallery__text">
                                    <h3>{{ item.title }}</h3>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="board-gallery__cont">
                        <div class="board-gallery__cont-inner">
                            <div v-html="this.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Search from '../common/SearchBox.vue'
    import Pagination from '../common/Pagination.vue'

    export default {
        components: {
            Search,
            Pagination
        },
        props: {
            boardInfo: ''
        },
        data() {
            return {
                board: {},
                list: [],
                pageList: '',
                totalPage: '',
                pageCount: window.innerWidth > 720 ? 10 : 5,
                totCnt: 0,
                activeCont: null,

                // 게시글 parameter
                currentNum: this.$route.query.currentNum || 1,
                pageRow: 999,
                //검색 관련 parameter
                title: this.$route.query.title || '',
                content: this.$route.query.content || '',
                searchKeyword: this.$route.query.searchKeyword || '',

            }
        },
        watch: {
            $route(to, from) {
                // 동일 게시판 안에서 이동
                if (to.query != from.query && to.path == from.path) {
                    if (Object.keys(to.query).length === 0) this.init()
                    for (let i in this.$route.query) {
                        this[i] = this.$route.query[i]
                    }

                    this.getList()
                }
            },
            // 다른 게시판으로 이동
            boardInfo(oldVal, newVal) {
                this.init()
                this.getList()
            }
        },
        computed: {
            galleryGroups() {
                const groups = [];
                console.log(this.list)
                if (this.list !== []) {
                    const groupSize = window.innerWidth <= 720 ? 1 : 3;

                    for (let i = 0; i < this.list.length; i += groupSize) {
                        groups.push(this.list.slice(i, i + groupSize));
                    }
                }

                return groups;
            }
        },
        methods: {
            getList() {
                const boardNo = this.boardInfo.boardNo
                const params = {
                    instNo: 1,
                    boardNo: boardNo,
                    startIndex: this.currentNum,
                    pageRow: this.pageRow,
                    title: this.title,
                    content: this.content,
                    searchKeyword: this.searchKeyword,
                }
                this.axios.get(this.api.article.list + boardNo, {
                    params: params
                })
                    .then(res => {
                        this.list = res.data.list
                        this.board = res.data.board
                        this.totCnt = res.data.totCnt
                    })
            },
            // 검색 emit
            setState(s, v, p) {
                this[p] = ''
                this.currentNum = 1
                this[s] = v
                this.goPage()
            },
            // pagination emit
            setNum(n) {
                this.currentNum = n
                this.goPage()
            },
            goPage() {
                const obj = { currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword };
                for (const key in obj) {
                    if (obj[key] === null || obj[key] === '') {
                        delete obj[key];
                    }
                }

                this.$router.push({ path: this.$route.path, query: obj });
            },
            // 데이터 초기화
            init() {
                this.currentNum = 1
                this.title = ''
                this.content = ''
                this.searchKeyword = ''
            },
            showCont(articleNo, event, item) {
                event.stopPropagation(); // 부모 엘리먼트로의 클릭 이벤트 전파를 막기 위해 stopPropagation() 사용
                if (this.activeCont === articleNo) {
                    this.activeCont = null;
                } else {
                    this.activeCont = articleNo;
                }
                this.content = item.content;
            },
            resetActiveCont() {
                this.activeCont = null;
            }
        },
        mounted() {
            this.getList()
            document.addEventListener('click', this.resetActiveCont);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.resetActiveCont);
        }
    }
</script>