<template>
    <!-- 뇌혈관센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>뇌혈관센터는 뇌출혈, 뇌동맥류, 뇌경색, 뇌혈관기형을 비롯한 각종 뇌질환의 효과적인 치료를 위해 신경과, 신경외과, 재활의학과 의료진이 치료를 위해 연계하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">대한신경중재치료의학회 신경중재치료 인증병원</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원은 대한신경중재치료의학회(KSIN)가 지정한 신경중재치료 인증병원으로 뇌혈관질환의 진단 및 치료에 있어 수준높은 의료서비스를 제공하는 기관임을 인정받았습니다.</p>
                                <br>
                                <p>신경중재치료는 첨단영상의학기기를 이용해 미세도관을 뇌혈관 내로 삽입해 각종 뇌혈관과 신경계 질환을 진단·치료하는 분야로 대한신경중재치료의학회가 지정한 인증병원은 두경부 및 척추혈관 환자를 전문으로 치료하면서 학회 요건을 충족하고, 이들 환자에게 최적의 치료를 제공할 수 있음을 인정하는 기관을 의미합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">체계적인 뇌졸중 전문치료를 위한 뇌졸중집중치료실 운영</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>2010년 경남지역 최초로 개설된 창원파티마병원 뇌졸중집중치료실은 뇌졸중 환자의 전문적인 치료를 위해 신경과, 신경외과, 재활의학과 전문의, 전담간호사, 재활치료사가 체계적인 뇌졸중 전문치료를 신속 정확하게 제공하고 있습니다.</p>
                                <br>
                                <p>뇌졸중집중치료실은 뇌졸중 환자의 전문적인 치료를 위한 독립적인 공간으로, 전문 간호사가 24시간 상주하고 있어 환자의 혈압, 산소포화도, 심전도 등을 모니터링 하고 있습니다. 또한 흡인성 폐렴, 욕창, 요로감염, 낙상, 깊은 혈관 혈전증과 같이 급성기 뇌졸중 환자에게 흔한 합병증 예방을 위한 진료 시스템을 제공하고 있으며, 신속한 재활평가를 통해 조기 재활 치료가 진행 됩니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>

<!-- 0801 수정본 -->
<!-- <div class="text-box type5">
    <ul class="text-box__list">
        <li class="text-box__item">
            <h3 class="tit">대한신경중재치료의학회 신경중재치료 인증병원</h3>
            <div class="desc">
                <img src="/resource/img/brain01.jpg" class="l-img" style="float: left;" alt="이미지">
                창원파티마병원은 대한신경중재치료의학회(KSIN)가 지정한 신경중재치료 인증병원으로 뇌혈관질환의 진단 및 치료에 있어 수준높은 의료서비스를 제공하는 기관임을 인정받았습니다.
                <br><br>
                신경중재치료는 첨단영상의학기기를 이용해 미세도관을 뇌혈관 내로 삽입해 각종 뇌혈관과 신경계 질환을 진단·치료하는 분야로 대한신경중재치료의학회가 지정한 인증병원은 두경부 및 척추혈관 환자를 전문으로 치료하면서 학회 요건을 충족하고, 이들 환자에게 최적의 치료를 제공할 수 있음을 인정하는 기관을 의미합니다.
            </div>
        </li>
        <li class="text-box__item">
            <h3 class="tit">체계적인 뇌졸중 전문치료를 위한 뇌졸중집중치료실 운영</h3>
            <div class="desc">
                <img src="/resource/img/brain02.jpg" class="l-img" style="float: left;" alt="이미지">
                2010년 경남지역 최초로 개설된 창원파티마병원 뇌졸중집중치료실은 뇌졸중 환자의 전문적인 치료를 위해 신경과, 신경외과, 재활의학과 전문의, 전담간호사, 재활치료사가 체계적인 뇌졸중 전문치료를 신속 정확하게 제공하고 있습니다.
                <br><br>
                뇌졸중집중치료실은 뇌졸중 환자의 전문적인 치료를 위한 독립적인 공간으로, 전문 간호사가 24시간 상주하고 있어 환자의 혈압, 산소포화도, 심전도 등을 모니터링 하고 있습니다. 또한 흡인성 폐렴, 욕창, 요로감염, 낙상, 깊은 혈관 혈전증과 같이 급성기 뇌졸중 환자에게 흔한 합병증 예방을 위한 진료 시스템을 제공하고 있으며, 신속한 재활평가를 통해 조기 재활 치료가 진행 됩니다.
            </div>
        </li>
    </ul>
    <div class="img-box-wrap">
        <img src="/resource/img/brain03.jpg" alt="이미지">
        <img src="/resource/img/brain04.jpg" alt="이미지">
        <img src="/resource/img/brain05.jpg" alt="이미지">
    </div>
</div> -->
<!-- 0801 수정본 -->