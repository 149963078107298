<template>
    <Search @setState="search"></Search>

    <!-- <div class="initial-search">
        <h3>초성검색</h3>
        <consonant @consonant="searchConsonant"></consonant>
    </div> -->
    <!--  board-card component 호출 및 params 변수 전달  -->
    <board-card ref="boardCard" @articleView="articleView" @getContents="getContents"></board-card>
    <pagination :totCnt="totCnt" :pageRow="params.pageRow" :currentNum="params.startIndex" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
    <!-- <button @click="moreList">더보기</button> -->
</template>
<script>
import BoardCard from '@/components/boards/BoardCard.vue'
import Search from '@/components/common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'
// import Consonant from '@/components/common/Consonant.vue'
import mental from '@/assets/resource/json/mental.json'

export default {
    components: {
        // Consonant,
        Search,
        BoardCard,
        Pagination
    },
    data() {
        return {
            params: {
                instNo: 1,
                boardNo: 26,
                startIndex: 1,
                pageRow: 16,
                title: this.title,
                content: this.content,
                orderValue: 'TITLE',
                searchKeyword: this.searchKeyword,
            },
            searchParam: {},
            pagingFlag: false,
            
            totalPage: 0,
            pageCount: 5,
            totCnt: 0,
        }
    },
    methods: {
        setNum(page) {
            this.$refs.boardCard.printList = []
            this.getList(page)
        },
        search(searchType, searchKeyword) {
            this.params.title = searchKeyword

            this.$refs.boardCard.printList = []

            this.getList(1)
        },
        searchConsonant(consonant) {
            this.params.consonant = consonant

            this.$refs.boardCard.printList = []

            this.getList(1)
        },
        articleView(key, boardNo, articleNo) {
            this.$router.push({ path: '/healthinfo/mentalDetail.do', query: { key: key, boardNo: boardNo, articleNo: articleNo } });
        },
        async getContents(list, totCnt) {
            let keys = list.map(item => item.key)
            this.totCnt = totCnt
            this.totalPage = Math.ceil(this.totCnt / this.params.pageRow);

            for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < mental.length; j++) {
                    if (list[i].title == mental[j].DISS_SJ) {
                        list[i].content = mental[j].DISS_CN12
                    }
                }
            }

            this.$refs.boardCard.printList.push(...list)

            this.$nextTick(() => {
                this.$refs.boardCard.updateMasonryLayout()
            })
        },
        getList(page) {
            this.params.startIndex = page
            this.$refs.boardCard.getList(this.params)
        },
        moreList() {
            this.getList(this.params.startIndex + 1)
        }
    },
    mounted() {
        console.log(mental)
        this.getList(1)
    },


}
</script>