<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>특수건강진단대상업무에 종사하거나 종사할 예정에 있는 근로자의 건강관리 및 적합성평가를 위해 사업주가 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
    
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">검진주기</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">검진주기</caption>
                <colgroup>
                    <col style="width:5%;">
                    <col style="width:55%;">
                    <col style="width:25%;">
                    <col style="width:15%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>특수건강진단 대상 유해인자(시행규칙 별표23)</th>
                        <th>배치 후 첫번째 특수건강진단</th>
                        <th>기본주기</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="2">1</td>
                        <td>N,N-디메틸아세트아미드</td>
                        <td rowspan="2">1개월 이내</td>
                        <td rowspan="2">6개월</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">N,N-디메틸포름아미드</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>벤젠</td>
                        <td>2개월 이내</td>
                        <td>6개월</td>
                    </tr>
                    <tr>
                        <td rowspan="2">3</td>
                        <td>1, 1, 2, 2-테트라클로로에탄, 사염화탄소</td>
                        <td rowspan="2">3개월 이내</td>
                        <td rowspan="2">6개월</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">아크릴로니트릴, 염화비닐</td>
                    </tr>
                     <tr>
                        <td>4</td>
                        <td>석면, 면분진</td>
                        <td>12개월 이내</td>
                        <td>12개월</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>광물성분진, 목재분진, 소음 및 충격소음</td>
                        <td>12개월 이내</td>
                        <td>24개월</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>제1호부터 제5호까지의 규정의 대상 유해인자를 제외한 별표 22의 모든 대상 유해인자</td>
                        <td>6개월 이내</td>
                        <td>12개월</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검사종류</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>특수건강진단 : 특수건강진단대상업무에 종사하는 근로자의 건강관리를 위하여 사업주가 실시하는 건강진단.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>배치전건강진단 : 특수건강진단대상업무에 종사할 근로자의 배치 예정 업무에 대한 적합성 평가를 위하여 사업주가 실시하는 건강진단.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수시건강진단 : 급성으로 발병하거나, 정기적 건강진단으로는 발견하기 어려운 직업성 질환을 조기에 진단하기 위해 실시하는 건강진단.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>임시건강진단 : 특수건강진단 대상유해인자 또는 그 밖의 유해인자에 의한 중독 여부 질병에 걸렸는지 여부 또는 질병의 발생원인 등을 확인 하기위해 필요하다고 인정되는 경우 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>

     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:30</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검사비용</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>고시수가 적용.(문의 055-270-1501~3)</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>작업환경측정결과서</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>물질안전보건자료(MSDS)</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>건강디딤돌사업 선정통보서.(대상사업장에 한함)</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">유의사항</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>검진 전 8시간 이상 금식하시기 바랍니다. (커피, 우유 포함 일체 음식물)</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소변검사가 있으니 검사 전 되도록 소변을 참아 주시기 바랍니다.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>생물학적 노출지표 검사 시 소변 채취 시간에 따라 검사 값이 크게 차이가 날 수 있습니다.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>검사 전 1시간 이내에는 금연하시기 바랍니다.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>검사 전날에는 심한 운동, 과로, 음주, 과식을 삼가십시오.</p>
                  </div>
              </div>
          </div>
    </div>

    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">결과통보</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 검사 후 15일 이내.</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 모바일(카카오톡), 우편, 내원수령.</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>