<template>
  <div class="healthCheckupReserve healthCheckupReserve02">
    <div class="contents">
      <div class="healthCheckupReserve_top_box">
        <img src="/resource/icon/checkup_result_icon.png" alt="이미지" />
        <h3>건강검진 예약신청 접수가 완료되었습니다.<br>담당자 확인 후 곧 연락드리겠습니다.</h3>
        <p>문의사항 : 055-270-1200~1</p>
      </div>
    </div>
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">수검자정보</h4>
          <div class="depth__wrap depth02">
            <div class="depth__text">
              <div class="depth__wrap">
                <div class="depth__text">
                  <p>
                    수신자의 정보가 정확하지 않을 경우 정상적으로 예약이 되지
                    않을 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-form type1 mt40">
        <ul class="input-form__list">
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">이름</div>
              <div class="cont">
                <p>이준용</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">생년월일</div>
              <div class="cont">
                <p>1999.01.01</p>
              </div>
            </div>
          </li>
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">성별</div>
              <div class="cont">
                <p>남성</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">연락처</div>
              <div class="cont">
                <p>010-000-0000</p>
              </div>
            </div>
          </li>
        </ul>
        <p class="input_additional">
          ※ 개인정보수정은 병원 홈페이지에서 가능합니다.
        </p>
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">예약정보</h4>
        </div>
      </div>

      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 18%" />
              <col style="width: 18%" />
              <col style="width: 64%" />
            </colgroup>
            <tbody>
              <tr>
                <td style="background-color: #f3f3f3;" colspan="2">건강검진 종류</td>
                <td style="text-align:left">남성정밀검사</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3;" colspan="2">예약일</td>
                <td style="text-align:left">2024.06.03</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3;" colspan="2">추가검사 내용</td>
                <td style="text-align:left">PET-CT(상반신), 스트레스검사</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3;" rowspan="3">건강진단<br>비용</td>
                <td style="background-color: #f3f3f3;" >검진코스</td>
                <td style="text-align:left">750,000 원</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3; border-left:1px solid #d9d9d9">추가검사</td>
                <td style="text-align:left">780,000 원</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3; border-left:1px solid #d9d9d9">합계</td>
                <td style="text-align:left">1,560,000 원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p class="input_additional">※ 직원과 상담 후 일정이 변경될 수 있습니다.</p>
    </div>

    <div class="contents">
        <div class="btn-wrap center">
          <a href="javascript:;" class="btn blue-line">예약확인</a>
          <a href="javascript:;" class="btn" style="font-weight: 700">메인 페이지 이동</a>          
        </div>
    </div>

  </div>
</template>

<script>
export default {
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
    methods: {
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>

