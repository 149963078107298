<template>
    <p class="contents sub-text">회원님의 정보를 안전하게 보호하기 위해 <br>
        임시비밀번호를 실제 사용하시는 비밀번호로 변경해주시기 바랍니다.</p>
    <div class="join contents">
        <div class="input-form type1">
            <ul class="input-form__list">
                 <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호</div>
                        <div class="cont">
                            <div class="password" :class="memPwdValid == 'Y' ? 'active' : ''">
                                <input type="password" class="cont__text-input" maxlength="12" v-on:keyup="confirmPw" v-model="memPwd" ref="memPwd">
                            </div>
                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                            <p class="desc">동일문자 연속 4개 사용금지</p>
                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호 확인</div>
                        <div class="cont">
                             <div class="password" :class="memPwdValid == 'Y' && memPwd == memPwdChk ? 'active' : ''">
                                <input type="password" class="cont__text-input" :class="memPwd != '' && memPwdChk != '' && memPwd != memPwdChk ? 'b-red' : ''" maxlength="12"
                                title="비밀번호 확인" v-model="memPwdChk" ref="memPwdChk" @keyup.enter="changePassword">
                            </div>
                            <p class="desc c-red" v-show="memPwd != '' && memPwdChk != '' && memPwd != memPwdChk">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn blue" @click="changePassword">비밀번호 변경</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            memId: this.$store.state.memberStore.find.id,
            memTmpPwd: this.$store.state.memberStore.find.pw,
            memPwd: '',
            memPwdChk: '',
            memPwdValid: ''
        }
    },
    mounted() {
        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('init')
        // });
    },
    methods: {
        changePassword(){
            if (this.memPwd == '') {
                alert('신규 비밀번호를(을) 입력해주세요.');
                this.$refs.memPwd.focus();
                return
            }
            if (this.memPwdChk == '') {
                alert('신규 비밀번호 확인를(을) 입력해주세요.');
                this.$refs.memPwdChk.focus();
                return
            }
            if (this.memPwdValid != 'Y') {
                alert('비밀번호는(을) 영문, 숫자, 특수문자 조합 8~12자리 사용 가능하고 연속번호는 사용금지입니다.\n다시 입력해주시기 바랍니다.');
                this.memPwd = '';
                this.$refs.memPwd.focus();
                return
            }
            if (this.memPwd != this.memPwdChk) {
                alert('비밀번호와 비밀번호 확인이 일치하지않습니다.');
                this.$refs.memPwd.focus();
                return
            }

            this.axios({
                url: this.api.member.pwChange,
                method: 'post',
                params: {
                    memId: this.memId,
                    memOrgPwd: btoa(this.memTmpPwd),
                    memPwd: btoa(this.memPwd)
                }
            }).then(res => {
                if (res.data.result.code === 'OK') {
                    this.$store.commit('setStatus', false)
                    alert('비밀번호가 변경되었습니다.\n다시 로그인 해주세요.');
                    location.href = "/member/login.do"
                } else {
                    alert(res.data.result.message);
                }
            })
        },
        // pw 조건식 일치 여부
        confirmPw() {
            const reg = /^(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_-])(?=.*[0-9]).{8,12}$/; // 영문, 숫자, 특수문자 인식
            const regNum = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(987)|(876)|(765)|(654)|(543)|(432)|(321)/; // 연속 숫자
            const regAlp = /(abc)|(bcd)|(cde)|(def)|(efg)|(fgh)|(ghi)|(hij)|(ijk)|(jkl)|(klm)|(lmn)|(mno)|(nop)|(opq)|(pqr)|(qrs)|(rst)|(stu)|(tuv)|(uvw)|(vwx)|(wxy)|(xyz)|(zyx)|(yxw)|(xwv)|(wvu)|(vut)|(uts)|(tsr)|(srq)|(rqp)|(qpo)|(pon)|(onm)|(nml)|(mlk)|(lkj)|(kji)|(jih)|(ihg)|(hgf)|(gfe)|(fed)|(edc)|(dcb)|(cba)/; // 연속 문자
            const sameCheck = /([0-9a-zA-Z])\1{3,}/; // 동일문자 연속 4개 이상 확인

            if (this.memPwd == '') {
                this.memPwdValid = ''
            }else if (reg.test(this.memPwd) && !regNum.test(this.memPwd) && !regAlp.test(this.memPwd) && !sameCheck.test(this.memPwd) && this.memPwd.indexOf(this.memId) < 0) {
                this.memPwdValid = 'Y'
            } else {
                this.memPwdValid = 'N'
            }

            console.log(this.memPwdValid)
        },
    },
    beforeUnmount() {
        this.$store.commit('init')
    },
}
</script>