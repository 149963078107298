<template>
    <!-- 진단 및 검사 리스트 -->
    <Search @setState="search"></Search>
    <div class="initial-search">
        <h3>초성검색</h3>
        <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
    </div>
    <!--  board-card component 호출 및 params 변수 전달  -->
    <board-card ref="boardCard" @articleView="articleView" @getContents="getContents"></board-card>
    <!-- <pagination :totCnt="totCnt" :pageRow="params.pageRow" :currentNum="params.startIndex" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination> -->
    <!-- <button @click="moreList">더보기</button> -->
</template>

<script>
import BoardCard from '@/components/boards/BoardCard.vue'
import Search from '@/components/common/SearchBox.vue'
import Consonant from '@/components/common/Consonant.vue'
import Pagination from '../common/Pagination.vue'

export default {
    components: {
        Consonant,
        Search,
        BoardCard,
        Pagination
    },
    data() {
        return {
            list: '',
            params: {
                instNo: 1,
                boardNo: 28,
                startIndex: 1,
                pageRow: 10000,
                title: this.title,
                content: this.content,
                orderValue: 'TITLE',
                searchKeyword: this.searchKeyword,
            },
            searchParam: {},
            pagingFlag: false,
            consonantList: '',
            
            totalPage: 0,
            pageCount: 5,
            totCnt: 0,
        }
    },
    methods: {
        setNum(page) {
            this.$refs.boardCard.printList = []
            this.getList(page)
        },
        search(searchType, searchKeyword) {
            this.params.title = searchKeyword
            this.$refs.boardCard.printList = []
            this.getList(1)
        },
        searchConsonant(consonant) {
            this.params.consonant = consonant
            this.$refs.boardCard.printList = []
            this.getList(1)
        },
        articleView(key, boardNo, articleNo) {
            this.$router.push({ path: '/healthinfo/inspecDetail.do', query: { key: key, boardNo: boardNo, articleNo: articleNo } });
        },
        async getContents(list, totCnt) {
            let keys = list.map(item => item.key)
            this.totCnt = totCnt
            this.totalPage = Math.ceil(this.totCnt / this.params.pageRow);
            await this.axios.post(this.api.health.kdcaList, keys).then(res => {
                const resultList = res.data
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < resultList.length; j++) {
                        if (list[i].key == resultList[j].key) {
                            if(Array.isArray(resultList[j].XML.svc.cntntsClList.cntntsCl)){
                                list[i].content = resultList[j].XML.svc.cntntsClList.cntntsCl[0].CNTNTS_CL_CN
                            }else{
                                list[i].content = resultList[j].XML.svc.cntntsClList.cntntsCl.CNTNTS_CL_CN
                            }
                        }
                    }
                }
                this.$refs.boardCard.printList.push(...list)
                if(!this.consonantList){
                    this.consonantList = this.$commonUtils.setConsonantList(this.$refs.boardCard.printList, 'title')
                }
            })
            this.$refs.boardCard.updateMasonryLayout()
        },
        getList(page) {
            this.params.startIndex = page
            this.$refs.boardCard.getList(this.params)
        },
        moreList() {
            this.getList(this.params.startIndex + 1)
        }
    },
    mounted() {
        this.getList(1)
    }
}
</script>