<template>
    <div class="mascot contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">마스코트</h4>
            </div>
        </div>
       <ul class="mascot_desc_list">

            <li class="mascot_desc_item mascot_desc_item01">
                <div class="mascot_item_txtimg">
                    <img src="/resource/img/hello_fati_mati.png" alt="이미지">
                </div>
            </li>

            <li class="mascot_desc_item mascot_desc_item02">
                <ul class="mascot_list">
                    <li class="mascot_item mascot_img">
                        <img src="/resource/img/fati_main01.png" alt="이미지">
                    </li>
                    <li class="mascot_item mascot_txt">
                        <div class="mascot_txt_box">
                            <h3 class="mint__title">파티</h3>
                            <p class="mt20">천사의 날개를 모티브로 디자인된 파티는<br>하느님이 보내주신 수호천사입니다.</p>
                            <p class="mt40">쓰고 있는 안경은 하느님의 눈으로 진정성있게<br>세상을 바라보고자 하는 올곧은 시선을 의미합니다.</p>
                            <p class="mt40">파티마병원 공동체의 일원이 되어<br>사랑의 돌봄으로 생명 존중의 전인치유 활동을<br>함께 펼쳐 나갑니다.</p>
                        </div>
                    </li>
                </ul>
            </li>
            <li class="mascot_desc_item mascot_desc_item03">
                <ul class="mascot_list">
                    <li class="mascot_item mascot_img">
                        <img src="/resource/img/mati_main01.png" alt="이미지">
                    </li>
                    <li class="mascot_item mascot_txt">
                        <div class="mascot_txt_box">
                            <h3 class="blue__title">마티</h3>
                            <p class="mt20">치유자이신 하느님의 어린양을 모티브로 디자인된 마티는<br>수호천사 파티와 파티마 가족의 전인치유 활동에 함께합니다.</p>
                            <p class="mt40">환자들이 치유활동을 통해 어린이처럼 순수하고<br>생동감 있는 모습으로 회복되도록 기도하고 돕습니다</p>
                        </div>
                    </li>
                </ul>
            </li>

       </ul>

       <div class="contents">
            <div class="mascot_grid_wrap">
                <div class="mascot_grid_bg">
                    <img src="/resource/img/fatimati_slide.png" alt="이미지">
                </div>
            </div>
       </div>

       <div class="contents">
            <ul class="color_chip_list">
                <li class="color_chip_item">
                    <span class="mint_circle"></span>
                    <p><b>C :</b> 75&nbsp;&nbsp;<b>Y :</b> 40</p>
                </li>
                <li class="color_chip_item">
                    <span class="blue_circle"></span>
                    <p><b>C :</b> 90&nbsp;&nbsp;<b>M :</b> 60&nbsp;&nbsp;<b>Y :</b> 40</p>
                </li>
                <li class="color_chip_item">
                    <span class="red_circle"></span>
                    <p><b>M :</b> 60</p>
                </li>
                <li class="color_chip_item">
                    <span class="white_circle"></span>
                    <p><b>ALL : </b> 0</p>
                </li>
            </ul>
       </div>
       <div class="contents">
            <p class="mascot_mean">파랑과 청록을 주 컬러로 각컬러는 <br><span class="mint__title">생명,</span> <span class="blue__title">존경,</span> <span class="red__title">사랑,</span> <span style="color:#333;">순수</span>의 의미를 담고있습니다.</p>
       </div>

       <div class="contents">
            <div class="mascot_appli">
                <h4>응용디자인</h4>
                <div class="mascot_appli_wrap">
                    <ul class="mascot_appli_list appli_img_top">
                        <li class="appli_img_item">
                            <img src="/resource/img/fatimati01.png" alt="이미지">
                        </li>
                        <li class="appli_img_item">
                            <img src="/resource/img/fatimati03.png" alt="이미지">
                            
                        </li>
                    </ul>
                    <ul class="mascot_appli_list appli_img_bottom">
                        <li class="appli_img_item">
                            <img src="/resource/img/fatimati02.png" alt="이미지">
                        </li>
                        <li class="appli_img_item">
                            <img src="/resource/img/fatimati04.png" alt="이미지">
                        </li>
                    </ul>
                </div>
            </div>
       </div>
    </div>
</template>



<script>
    export default {

    }
</script>