<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">칭찬합니다</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">고객의소리</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">건강상담</button></li>
        </ul>
    </div>

    <div class="contents">
        <ul>
            <li class="c-gray5">회원님께서 작성하신 글을 최신순으로 확인하실 수 있습니다.</li>
            <li class="c-gray5">관리자가 승인한 글만 <b class="c-blue">이용안내 > 고객 서비스 > 칭찬합니다</b> 에서 확인 가능합니다.</li>
            <li class="c-gray5">글 수정은 접수 상태일 때 제목 클릭 후 수정 가능합니다.</li>
        </ul>
    </div>
    <div class="contents board-list">
        <div class=" board-list__table">
            <table>
                <colgroup>
                    <col style="width: 10%;">
                    <col style="width: 20%;">
                    <col style="width: auto">
                    <col style="width: 18%;">
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th>등록일자</th>
                        <th>제목</th>
                        <th>진행상황</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="check">
                            <input type="checkbox" id="check1">
                            <label for="check1"></label>
                        </td>
                        <td class="date">2024.05.08</td>
                        <td class="left tit">
                            <a href="javascript:;" class="board-list__link">
                                <span class="new"></span>
                                <p>전훈재 교수님, 고맙습니다.</p>
                            </a>
                            <div class="check">
                                <input type="checkbox" id="check3">
                                <label for="check3"></label>
                            </div>
                        </td>
                        <td class="okay">접수</td>
                    </tr>
                    <tr>
                        <td class="check">
                            <input type="checkbox" id="check2">
                            <label for="check2"></label>
                        </td>
                        <td class="date">2024.05.08</td>
                        <td class="left tit">
                            <a href="javascript:;" class="board-list__link">
                                <span class="new"></span>
                                <p>공감에 감사드립니다.</p>
                            </a>
                            <div class="check">
                                <input type="checkbox" id="check4">
                                <label for="check4"></label>
                            </div>
                        </td>
                        <td class="okay">승인완료</td>
                    </tr>
                </tbody>
            </table>
            <div class="contents btn-wrap right"><a href="javascript:;" class="btn red">삭제</a></div>

            <!-- 우리금융저축은행과 같은 페이징가져왔습니다. -->
            <div class="contents">
                <div class=" paging">
                    <a href="javascript:;" class="board__pagination-btn first">처음</a>
                    <a href="javascript:;" class="board__pagination-btn prev">이전</a>
                    <a href="javascript:;" class="board__pagination-btn num active"><span>90</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>91</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>93</span></a>
                    <a href="javascript:;" class="board__pagination-btn num"><span>910</span></a>
                    <a href="javascript:;" class="board__pagination-btn next">다음</a>
                    <a href="javascript:;" class="board__pagination-btn last">마지막</a>
                </div>
            </div>
        </div>
    </div>
</template>



<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>