<template lang="">
 <div class="login">
        <!-- 센터 로그인 추가 부분 -->
        <h3 class="center_loginTitle">창원파티마병원 통합 로그인 페이지 입니다.</h3>
        <div class="sub-text dp2">모든 패밀리 사이트(창원파티마병원, 건강증진센터, 직업환경의학센터, 진료협력센터)는 통합회원제로 운영되고 있습니다.<br>어느 패밀리 사이트에서든지 동일한 계정으로 사이트 이용이 가능합니다.</div>
         <!-- 센터 로그인 추가 부분 -->
        <div class="login__inner">
            <div class="contents login__input">
                <label class="login__id">
                    <input type="text" placeholder="아이디를 입력해 주세요.">
                </label>
                <label class="login__password">
                    <input type="text" placeholder="비밀번호를 입력해 주세요.">
                    <div class="icon"></div>
                </label>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
                <ul class="login__list">
                    <li class="login__item">
                        <a href="javascript:;">아이디 찾기</a>
                    </li>
                    <li class="login__item">
                        <a href="javascript:;">비밀번호 찾기</a>
                    </li>
                    <li class="login__item">
                        <a href="javascript:;">회원가입</a>
                    </li>
                </ul>
            </div>
            <div class="contents login__sns">
                <p>SNS 계정으로 로그인하기</p>
                <div class="btn-wrap">
                    <a href="javascript:;" class="btn naver">아이디 찾기</a>
                    <a href="javascript:;" class="btn kakao">아이디 찾기</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>