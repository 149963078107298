<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <template v-for="(menu, idx) in menuList" :key="idx">
                    <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '591'">
                        <router-link :to="menu.menuUrl" >
                            <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                        </router-link>
                    </li>
                </template>
            </ul>
        </div>
    </div>

    <template v-if="step === 1">
        <div class="contents info-text">
            <h3 class="depth01__title title center">개인정보 보호를 위해 <span class="c-blue">비밀번호를</span> <br>
                다시 한번 입력해주시기 바랍니다.
            </h3>
        </div>

        <div class="join contents">
            <div class="changing-information">
                <div class="input-form type1">
                    <ul class="input-form__list">
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">아이디</div>
                                <div class="cont">
                                    {{ myInfo.memId }}
                                </div>

                            </div>
                        </li>
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">비밀번호</div>
                                <div class="cont">
                                    <div class="cont-item">
                                        <input type="password" class="cont__text-input" v-model="myInfo.memPwEnc" maxlength="12">
                                        <a href="javascript:;" class="btn password-link" @click="logout">비밀번호찾기 ></a>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn blue" @click="passwordCheck">확인</a>
            </div>
        </div>
    </template>
    <template v-if="step === 2">
        <div class="contents input-form type1">
            <div class="input-form__top-wrap">
                <p class="input-form__top-text type2">&#183; 부정확한 휴대전화, 이메일 주소 입력 시 다른 사람에게 나의 정보가 전송될 수도 있습니다.</p>
                <p class="input-form__top-text type2">&#183; 정확하게 입력하여 주시기 바랍니다.</p>
            </div>

            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">아이디</div>
                        <div class="cont">
                            {{ this.$store.state.myInfoStore.memType == 'B' ? 'SNS 회원' : myInfo.memId }}
                        </div>
                    </div>
                </li>

                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">이름</div>
                        <div class="cont">{{ myInfo.memName }}</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit"><span class="c-red">* </span>휴대전화</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input" v-model="myInfo.memHpNo1" maxlength="3" @input="this.$commonUtils.numberOnly">
                            <span class="line">-</span>
                            <input type="text" class="cont__text-input" v-model="myInfo.memHpNo2" maxlength="4" @input="this.$commonUtils.numberOnly">
                            <span class="line">-</span>
                            <input type="text" class="cont__text-input" v-model="myInfo.memHpNo3" maxlength="4" @input="this.$commonUtils.numberOnly">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit">유선 전화번호</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input" v-model="myInfo.memTel1" maxlength="3" @input="this.$commonUtils.numberOnly">
                            <span class="line">-</span>
                            <input type="text" class="cont__text-input" v-model="myInfo.memTel2" maxlength="4" @input="this.$commonUtils.numberOnly">
                            <span class="line">-</span>
                            <input type="text" class="cont__text-input" v-model="myInfo.memTel3" maxlength="4" @input="this.$commonUtils.numberOnly">
                        </div>
                    </div>
                </li>

                <li class="input-form__item">
                    <div class="input-form__box email">
                        <div class="tit"><span class="c-red">* </span>이메일</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input" v-model="myInfo.memEmail1">
                            <span class="line">@</span>
                            <input type="text" class="cont__text-input" v-model="myInfo.memEmail2">
                            <select class="cont__select" v-model="myInfo.memEmail2">
                                <option value="">직접입력</option>
                                <option value="naver.com">naver.com</option>
                                <option value="daum.net">daum.net</option>
                                <option value="gmail.com">gmail.com</option>
                                <option value="yahoo.com">yahoo.com</option>
                                <option value="nate.com">nate.com</option>
                                <option value="korea.com">korea.com</option>
                                <option value="chol.com">chol.com</option>
                            </select>
                        </div>
                    </div>
                </li>

                <li class="input-form__item">
                    <div class="input-form__box address">
                        <div class="tit">주소</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input" readonly v-model="myInfo.memZipcode" @click="zipcodeApi">
                                <button class="btn" @click="zipcodeApi">우편번호 검색</button>
                            </div>
                            <div class="cont-item"><input type="text" class="cont__text-input" v-model="myInfo.memAddress1"></div>
                            <div class="cont-item"><input type="text" class="cont__text-input" v-model="myInfo.memAddress2"></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <router-link to="/mypage/mypage.do" class="btn">취소</router-link>
            <a href="javascript:;" class="btn blue" @click="updateMember">확인</a>
        </div>

        <div id="zipcodeWrap" style="display:none;">
            <div ref="embed" class="zipcodeWrap_style"
                 style="display: block; position: fixed; overflow: hidden; z-index: 1; border: 3px solid;">
                <div style="height:30px;background:white;">
                    <button id="btnCloseLayer" @click="closeDaumPostcode()"  style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1; height: 40px; width: 40px;padding: 0;border: none; font-size:24px;">
                        x
                    </button>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    data() {
        return {
            myInfo: this.$store.state.myInfoStore,
            step: this.$store.state.myInfoStore.memType == 'B' ? 2 : 1
        };
    },
    methods: {
        passwordCheck() {
            this.axios({
                url: this.api.mypage.passwordCheck,
                method: 'post',
                params: {memPwEnc: btoa(this.myInfo.memPwEnc)}
            })
                .then(res => {
                    if (res.data.result.code === 'OK') {
                        this.step = 2;
                        if(this.$commonUtils.isNotEmpty(this.myInfo.memHpNo)){
                            const memHpNo = this.myInfo.memHpNo.replaceAll('-', '')
                            this.myInfo.memHpNo1 = memHpNo.substring(0, 3);
                            this.myInfo.memHpNo2 = memHpNo.substring(3, 7);
                            this.myInfo.memHpNo3 = memHpNo.substring(7, 11);
                        }

                        if(this.$commonUtils.isNotEmpty(this.myInfo.memTel)){
                            const memTel = this.myInfo.memTel.replaceAll('-', '')
                            if(this.myInfo.memTel.length === 11){
                                this.myInfo.memTel1 = memTel.substring(0, 3);
                                this.myInfo.memTel2 = memTel.substring(3, 7);
                                this.myInfo.memTel3 = memTel.substring(7, 11);
                            } else {
                                if(this.myInfo.memTel.substring(0, 2) === '02'){
                                    this.myInfo.memTel1 = memTel.substring(0, 2);
                                    this.myInfo.memTel2 = memTel.substring(2, 6);
                                    this.myInfo.memTel3 = memTel.substring(6, 10);
                                } else {
                                    this.myInfo.memTel1 = memTel.substring(0, 3);
                                    this.myInfo.memTel2 = memTel.substring(3, 6);
                                    this.myInfo.memTel3 = memTel.substring(6, 10);
                                }
                            }
                        }

                        if(this.$commonUtils.isNotEmpty(this.myInfo.memEmail)){
                            this.myInfo.memEmail1 = this.myInfo.memEmail.split('@')[0];
                            this.myInfo.memEmail2 = this.myInfo.memEmail.split('@')[1];
                        }

                    } else {
                        alert(res.data.result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateMember() {
            this.myInfo.memHpNo = this.myInfo.memHpNo1 + '-' +  this.myInfo.memHpNo2 + '-' + this.myInfo.memHpNo3;
            this.myInfo.memTel = this.setTel();
            this.myInfo.memEmail = this.myInfo.memEmail1 + '@' + this.myInfo.memEmail2;
            this.myInfo.memEmail = this.myInfo.memEmail.replaceAll(" ", "");
            delete this.myInfo.failDt

            this.axios({
                url: this.api.mypage.myInfoUpdate,
                method: 'post',
                params: this.myInfo
            }).then((response) => {
                if (response.data.result.code === 'OK') {
                    alert('수정되었습니다.');
                    this.$router.push('/mypage/mypage.do');
                } else {
                    alert('수정에 실패하였습니다.');
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        setTel(){
            let tel = [this.myInfo.memTel1, this.myInfo.memTel2, this.myInfo.memTel3].filter(num => num != '');
            let setNumber = tel.join('-');
            return setNumber;
        },
        zipcodeApi() {
            const zipcodeWrap = document.getElementById('zipcodeWrap');
            zipcodeWrap.style.display = 'block';
            new window.daum.Postcode({
                oncomplete: (data) => {
                    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                    // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
                    // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                    let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                    let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraRoadAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if (extraRoadAddr !== '') {
                        extraRoadAddr = ' (' + extraRoadAddr + ')';
                    }
                    // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                    if (fullRoadAddr !== '') {
                        fullRoadAddr += extraRoadAddr;
                    }

                    // 우편번호와 주소 정보를 해당 필드에 넣는다.
                    this.myInfo.memZipcode = data.zonecode;
                    this.myInfo.memAddress1 = fullRoadAddr;
                    this.myInfo.memAddress2 = '';

                    // 주소 정보 클릭하면 팝업 close
                    this.closeDaumPostcode();
                }
            }).embed(this.$refs.embed);
            //.open(); 사용시 팝업창 생성
            const width = innerWidth
            if(width <= 720){
                const el = document.querySelector('[id^="__daum__layer_"]')
                if(el){
                    el.style.width = "100%";
                    el.style.maxWidth = "360px";
                    el.style.height = "500px";
                }
            }
        },
        closeDaumPostcode() {
            const zipcodeWrap = document.getElementById('zipcodeWrap');
            zipcodeWrap.style.display = 'none';
            $(".postal_button").attr("tabindex", "0").focus();
        },
        logout() {
            this.$store.commit('myInfoInit')
            this.axios
                .get(this.api.member.logout)
                .then((res) => {
                    // console.log(res)
                })
                .catch(err => {
                    console.log(err);
                });
            this.$store.commit("setStatus", false);
            this.$store.commit('getShowMypageNameCheckModal', true);
            location.href = '/member/pwInquiry.do'
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
.zipcodeWrap_style{width: 100% !important; max-width: 500px !important; height: 500px !important; top: 50% !important; left: 50% !important; transform: translate(-50%, -50%) !important;}

@media(max-width: 720px){
.zipcodeWrap_style{width: 100% !important; max-width: 360px !important; height: 500px !important;}
}
</style>
