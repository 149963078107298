const memberStore = {
    state: {
        // 진료예약 완료
        reserve: '',
        // 진료변경
        change: '',
        // 진료예약
        info: '',
        // 검진예약 완료
        cprsv: '',
        // 검진예약
        cprsvInfo: '',
        // 간편예약
        first: ''
    },
    mutations: {
        setReserve: function (state, reserve) {
            state.reserve = reserve;
        },
        setChange: function (state, change) {
            state.change = change;
        },
        setReserveInfo: function (state, info) {
            state.info = info;
        },
        setCprsv: function (state, cprsv) {
            state.cprsv = cprsv;
        },
        setCprsvInfo: function (state, cprsvInfo) {
            state.cprsvInfo = cprsvInfo;
        },
        setFirst: function (state, first) {
            state.first = first;
        },

        // 초기화
        reserveInit: function(state){
            for(let i in state) {
                state[i] = ''
            }
        }
    }
}

export default memberStore