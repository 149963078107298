<template>
    <div class="join-tab">
          <ul class="join-tab__list">
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item" v-if="$store.state.memberStore.memType != 'B'">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">{{ $store.state.memberStore.memType == 'D' ? '보호자 및 ' : '' }}본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '02' : '03' }}</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">{{ $store.state.memberStore.memType == 'B' ? '03' : '04' }}</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>
    <div class="find-info">
        <div class="contents find-info__box">
            <img src="@/assets/resource/icon/logo-b.png" class="logo" alt="이미지">
            <p class="find-info__text-type2">환영합니다 <span class="blue">{{this.$store.state.memberStore.finish.memName}}</span>님!</p>
            <p>창원파티마병원 홈페이지에 가입해 주셔서 감사합니다.</p>
            <ul class="contents find-info__list">
                <li class="find-info__item">
                    <div class="tit">아이디</div>
                    <div class="desc">{{resultId}}</div>
                </li>
                <li class="find-info__item">
                    <div class="tit">이메일</div>
                    <div class="desc">{{$commonUtils.maskingEmail(this.$store.state.memberStore.finish.memEmail)}}</div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <router-link to="/" class="btn">메인으로 이동</router-link>
            <router-link :to="`/member/login.do`" class="btn blue">로그인</router-link>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            resultId: this.$commonUtils.maskingId(this.$store.state.memberStore.finish.memId),
            memId: '',
            memEmail: '',
        }
    },
    created() {
        if(this.$store.state.memberStore.memDi == '' && this.$store.state.memberStore.memType != 'B'){
            alert('올바르지 않은 접근입니다.')
            this.$router.push('/member/join.do')
        }else{
            this.regInfo();
            this.$store.commit('setMemCi', '');
            this.$store.commit('setMemDi', '');
        }
        // const finishId = 'SNS-Naver 회원'
        // const mem = {
        //     memName: '한상우',
        //     memId: this.$store.state.memberStore.memType == 'B' ? 'qwewq' : 'regtest01',
        //     memEmail: 'beefcattle@naver.com'
        // }
        // this.$store.commit('setFinish', mem)
        // console.log(this.$store.state.memberStore.finish)
        // console.log(this.resultId)
    },
    methods: {
        // 아이디, 메일 문자 치환 함수
        regInfo() {
            String.prototype.replaceAt = function (index, replacement) {
                if (index >= this.length) {
                    return this.valueOf();
                }
                return this.substring(0, index) + replacement + this.substring(index + 1);
            }
            // 유저 이메일 치환

            let email = this.$store.state.memberStore.finish.memEmail;
            email = 'beefcattle@naver.com'
            const emailIdx = email.indexOf('@');
            for (let i = 3; i < emailIdx; i++) {
                email = email.replaceAt(i, '*');
            }
            this.memEmail = email;
            // 유저 아이디 치환
            if (this.$store.state.memberStore.memType == 'C') {
                this.memId = this.$store.state.memberStore.snsType;
            } else {
                let ID = this.$store.state.memberStore.finish.memId;
                const IDLen = ID.length;
                ID = ID.replaceAt(3, '*');
                for (let i = 3; i < IDLen; i++) {
                    ID = ID.replaceAt(i, '*');
                }
                this.memId = ID;
            }
        }
    },
    mounted() {
        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('init')
        // });
    },
    beforeUnmount() {
        this.$store.commit('init')
    },
}
</script>