<template>
    <!-- 혈액종양내과내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4> 
            </div>
        </div>

        <div class="depth__wrap depth02">
            <div class="depth__text">
                
                <div class="department_organ_pic">
                    <img src="/resource/img/department_organ29.png" alt="이미지">
                </div>

            
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__text">
                            <p>혈액종양내과는 혈액 질환의 진단과 치료 ,각종 암으로 인한 암성통증, 합병증의 치료와 항암치료 및 호스피스 완화의료를 시행하고 있습니다.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    </div>

    <!-- <div class="info-text info-text02 depth__wrap depth01">
        <div class="depth__text">
                <div class="department_organ_pic">
                <img src="/resource/img/department_organ29.png" alt="이미지">
            </div>

            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__text">
                            <p>혈액종양내과는 혈액 질환의 진단과 치료 ,각종 암으로 인한 암성통증, 합병증의 치료와 항암치료 및 호스피스 완화의료를 시행하고 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>빈혈, 혈소판 질환 등 혈액질환, 혈액암, 고형암, 항암치료 클리닉, 호스피스 완화의료, 다학제 통합진료</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">최적의 치료방향 제시하는 다학제 통합진료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>'암'이라는 상황을 맞닥뜨린다면 덜컥 겁이 나거나, 막막하거나, 어느 병원에서 어떤 치료를 어떻게 받아야할 지 걱정되는 것이 보통입니다. 치료를 시작하면서도 수많은 궁금증이 생기고, 식단을 비롯해 치료 시 주의사항 등에 대해서도 정확한 정보를 얻고 싶어 하는 것이 당연합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원에서는 환자를 중심으로 주치의와 각 과 전문의들이 한 데 모여 계획을 의논하고 최적의 치료 방법을 제시하는 다학제 통합진료를 시행하고 있습니다. 다학제 통합진료의 장점은 의료진들이 다양한 의견을 나누어 최적의 치료계획을 도출한다는 점입니다. 환자에게 병기에 맞는 치료를 제공하고, 부작용 및 합병증까지 고려한 다각적 접근으로 치료 성공률을 보다 높일 수 있습니다. </p>
                            </div>
                        </div>
                        <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p>또한 검사·수술 일정의 협의가 신속히 이루어져 환자의 시간, 비용, 체력, 심리적 부담감을 줄일 수 있으며, 무엇보다 환자중심의 맞춤형 진료를 제공하기 때문에 환자와 보호자의 만족도가 높습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">암 환자 및 보호자 교육·상담</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>통합진료 후에는 종양 전문 간호사가 환자에게 치료 진행에 대한 세부적인 교육을 실시하며, 치료를 받는 과정 중에도 궁금한 점이나 의논하고 싶은 사항을 각과 전문의와 간호사에게 상담할 수 있습니다. 또한 임상 영양사가 암 환자의 식이관리법에 대한 교육도 실시하게 됩니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">창원파티마병원 다학제 통합진료 절차 </h4>
                    </div>
                </div>
                 <ul class="step-box appointStep">
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">Step01</h4>
                                    <p class="">담당주치의가 다학제<br>통합진료가 필요하다고 판단</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">Step02</h4>
                                    <p>담당 코디네이터에게<br>의뢰</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">Step03</h4>
                                    <p>진료일정에 맞게 다학제<br>통합진료 진행</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">Step04</h4>
                                    <p>다학제 통합진료 결과에<br>따른 추적 관찰 혹은 수술이나<br>항암 및 방사선치료 등 진행</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">Step05</h4>
                                    <p>수술 후 주의사항, 식이<br>관리 등 종양 전문 간호사와<br>임상 영양사가 교육 실시</p>
                                </div>
                            </li>
                </ul>
                <div class="depth__wrap">
                    <div class="depth__text">
                        <p>※ 문의 : 270-1466 (다학제진료실)</p>
                    </div>
                </div> 

                
            </div>
        </div>
    </div>

     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">호스피스·완화의료센터,</a>
                                <a href="javascript:;">항암치료클리닉,</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {

    };
</script>