<template>
  <div class="jobHealthCenter contents">
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              뇌·심혈관계 질환은 근골격계 질환 다음으로 2번째로 많은
              직업병입니다. 근로복지공단에서 조사하여 돌발적 사건 또는 급격한
              업무환경 변화, 단기간 동안 업무상 부담, 만성적인 과중한 업무 등이
              확인되는 경우 직업병으로 인정하고 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              우리나라 사망 원인 중 중풍이나 뇌졸중으로 알려진 뇌혈관계질환과 협심증, 심근경색 등의 심혈관계질환을 합치면 암보다 가장 많은 비중을 차지하고 있습니다. 점점 고령화되어가는 사회구조를 생각하면 이러한 질환들은 앞으로 더욱 늘어날 것으로 예상합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              뇌혈관계질환과 심혈관계질환을 합쳐 뇌·심혈관계질환이라고 하는데, 주요한 원인 중에는 고혈압, 당뇨, 이상지질혈증, 흡연, 비만 등이 있습니다. 이러한 질환 및 나쁜 생활습관들은 건강검진을 통해서 알 수 있으나, 대부분의 근로자는 건강검진의 결과를 적절히 활용하지 못하는 경우가 많습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              저희 센터 내의 직업환경의학과 외래에서는 질환이나 나쁜 생활습관이 있는 근로자에게 알맞은 의학적 상담, 해당 진료과로 의뢰, 추적검사 또는 약물치료 등 적절한 의학적 관리를 제공하고 있으며, 본원과 보건대행사업장으로 계약이 되어있는 경우 사업장에서도 정기적인 추적검사와 상담으로 종합적이고 지속적인 관리를 하고 있습니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              뇌·심혈관계 질환은 근골격계 질환 다음으로 2번째로 많은
              직업병입니다. 근로복지공단에서 조사하여 돌발적 사건 또는 급격한
              업무환경 변화, 단기간 동안 업무상 부담, 만성적인 과중한 업무 등이
              확인되는 경우 직업병으로 인정하고 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h4 class="depth02__title title contents">진료예약 055)270-1800</h4>
  </div>
</template>
<script>
export default {};
</script>
