<template>
  <div class="tab-menu__wrap">
      <div class="tab-menu type02">
          <ul class="tab-menu__list">
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_reserve.do`" type="button" class="tab-menu__btn">예약</router-link>
              </li>
              <li class="tab-menu__item on">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_Check.do`" type="button" class="tab-menu__btn">검사</router-link>
              </li>
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_etc.do`" type="button" class="tab-menu__btn">기타</router-link>
              </li>
          </ul>
      </div>
  </div>
  <div class="board-list">
    <div class="contents faq">
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="faq-title" :class="{ 'active': activeIndex === index }" @click.stop="toggle(index)">
          <div class="faq-title__text">
            <span class="icon">Q </span>
            <em>{{ faq.question }}</em>
          </div>
          <div class="faq-title__btn"></div>
        </div>
        <slide-up-down :active="activeIndex === index">
          <div class="faq-content">
            <span>A</span>
            <p v-html="faq.answer"></p>
          </div>
        </slide-up-down>
        <div class="faq-title__btn--mo" :class="{ 'active': activeIndex === index }"  @click.stop="toggle(index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  components: {
    SlideUpDown,
  },
  data() {
    return {
      activeIndex: null,
      deptCd: this.$route.params.deptCd,
      hpUrl: this.$route.params.hpUrl,
      faqs: [
        {
          question: "혈액검사로 모든 암을 확인할 수 있나요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p>저희 건강증진센터에서는 혈액을 통하여 종양의 선별, 진단, 경과예측 및 감시하기 위한 '종양표지자’검사를 시행하고 있습니다.</p>
                          <p>종양표지자란, 암세포가 있는 것을 나타내어 주는 물질을 총칭하는 말로 암의 사전예방, 조기발견 치료중인 암의 예후를 관찰하기 위한 목적으로 활용됩니다. 그러나 많은 종류의 종양표지자는 암과는 상관없이 증가하는 등, 불확실한 성질을 지니고 있는 경우도 있기 때문에 이것만으로 암의 유무를 진단할 수 있는 것은 아닙니다.</p>
                      </div>
                    </div>
                    <br>
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                          <p><b>[관련질환]</b></p>
                           <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>AFP : 원발성 간암, 간염, 간경변, 고환종양</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>CEA : 대장암, 위암, 췌장암, 소화기계암, 폐암</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>CA19-9 : 췌장암, 담도암, 만성췌장염, 담석증 등</p>
                            </div>
                        </div>
                          <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>CA125 : 난소암, 자궁암, 자궁근종, 난소낭종 등</p>
                            </div>
                        </div>
                          <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>PSA : 전립선암, 전립선 비대증, 전립선염 등</p>
                            </div>
                        </div>
                      </div>
                    </div>`
        },
        {
          question: "위내시경과 위장조영촬영의 차이점은 뭔가요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>위장조영촬영 검사는 조영제인 바륨을 마신 후 식도와 위 점막을 코팅하면서 촬영을 하여 그 영상을 판독하는 간접적인 관찰방법입니다.</p>
                        <p>위내시경은 내시경 관을 인체 내로 삽입하여 실시간으로 직접 관찰하고 필요시 조직검사도 실시할 수 있는 장점이 있습니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "일반내시경과 수면내시경 검사는 어떤 차이가 있나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>일반내시경과 수면내시경은 모두 내시경 관을 입을 통해 삽입하여 식도, 위 등을 직접 관찰 합니다.</p>
                        <p>다만 일반내시경인 경우 의식이 있는 상태로 검사하기 때문에 검사하는 동안 고통을 느낄 수 있어 편안한 검사를 원할 경우 수면 위내시경 검사를 실시합니다.</p>
                        <p>수면 내시경은 의식하 진정내시경이라고도 하는데 검사 동안 가벼운 진정제를 사용하여 순간적으로 가수면 상태로 유도합니다. 마취제를 사용하는 것이 아니라 진정제를 정맥 주사하여 가볍게 잠드는 것이므로 사람에 따라 약간의 고통을 느낄 수도 있고, 수면 유도가 잘 되지 않을 경우도 있습니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "수면내시경 시 주의점은?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>수면 내시경 검사를 선택하는 것은 본인의 의지이지만, 아주 극히 드물게 수면 중 호흡곤란, 저산소증, 심장마비, 약제 과민 반응에 의한 응급조치가 필요한 경우도 있기 때문에 호흡기 질환으로 인한 폐기능 장애, 만성 폐질환자, 신장 혹은 심장질환(협심증 및 심근경색증), 간경화나 간 기능 이상을 동반한 만성질환, 그 외 기타 만성질환자들은 주의가 필요하며, 이러한 경우 수면내시경 전 담당 주치의와 상의가 필요합니다.</p>
                        <br>
                        <p>일반적인 경우 수면 내시경 검사가 끝나고 1시간 정도 지나면 식사를 할 수 있으며 검사 후 잠시 어지러운 증상이 나타날 수 있지만 30분 정도 지나면 곧 회복됩니다. 검사 당일에는 자가 운전이나 기계 조작 등 무리한 일은 피하는 것이 좋으며 반드시 보호자가 동반해야 합니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "대장내시경 검사 주기는 어떻게 되나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>대장내시경 검사 시행 후 결과가 정상이고 불편사항이나 가족력이 없다면 3~5년 주기로 검사하시면 됩니다. </p>
                        <p>불편증상, 과거력, 가족력 등이 있으면 2~3년 주기로 검진하실 것을 권유드립니다.</p>
                        <br>
                        <p>단, 용종제거를 하신 경우 1~2년 뒤 재검사가 필요하며 특히, 제거하신 용종이 선종이라면 다음 해에 재검사가 필요합니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "MRI와 MRA의 차이점은 무엇인가요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>MRI 검사는 자기장과 고주파의 상호작용을 이용하여 인체의 해부학적, 병리학적 정보를 얻을 수 있는 검사입니다.</p>
                        <p>뇌 MRI는 치매, 뇌종양 등 뇌의 구조적, 기능적 이상소견 여부를 확인하고자 하는 목적이 있으며 뇌 MRA는 동맥류, 혈관기형, 혈관형태 등 뇌 혈관 이상을 확인하기 위해 혈관만을 영상화하는 검사입니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "생리기간 중에도 건강진단이 가능한가요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>생리 중일 경우 소변 및 부인과 검사를 제외한 모든 검사가 가능합니다.</p>
                        <p>생리로 인해 시행하지 못한 검사는 생리 종료 5일 후부터 가능하므로, 차후 재방문 하셔서 검사를 받으시면 됩니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "미혼여성입니다. 자궁암 검사를 받을 수 있나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>성경험이 없는 미혼여성은 질경을 통한 자궁경부암 세포진 검사와 골반초음파 검사에 어려움이 있습니다.</p>
                        <p>자궁, 난소, 난관 등 장기를 직접 볼 수 있는 골반초음파 검사는 미혼인 경우에도 가능합니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "내시경 등 검사기구들은 위생적으로 관리하고 있나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>창원파티마병원 건강증진센터에서는 모든 검사 장비 및 내시경의 기본적인 세척은 물론이고 물리적, 화학적 방법을 통해 각각의 기구를 철저히 소독하고 있습니다. </p>
                        <p>또한 별도의 감독기구인 감염관리실에서 철저히 점검하고 있으므로 안심하시고 검사를 받으셔도 됩니다.</p>
                    </div>
                  </div>`
        }
      ]
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    handleClickOutside(event) {
      const isClickInside = this.$el.contains(event.target);
      if (!isClickInside) {
        this.activeIndex = null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
