<template>
    <div class="first_reserve_popup name_certify_popup">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>실명인증</h4>
                <a href="javascript:;">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="contents first_desc">
                <div class="text-box type1 info-text">
                    <p>창원파티마병원은 의료법 시행령 제42조 2, 국민건강보험법 제 12조에 의거하여 진료예약 환자 확인을
                        <br>위한 <b>고유식별번호</b>를 수집하고 있습니다. </p>

                </div>
            </div>

            <div class="contents text-box type2 first_info_box">
                <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의
                    <span class="c-red">(필수)</span>
                </h3>
                <div class="text-box__wrap">
                    <p>창원파티마병원이 본인확인과 관련하여 고객으로부터 수집한 개인정보를 이용하고자 할 때에는
                        <br>'개인정보보호법' 에 따라 고객의 동의를 얻어야 합니다. </p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                </div>
            </div>
            <div class="agree-input">
                <label for="agree-y" class="agree-input__item">
                    <input type="radio" id="agree-y" class="agree-input__radio" name="agree">
                    <span class="icon"></span> 동의합니다 </label>
                <label for="agree-n" class="agree-input__item">
                    <input type="radio" id="agree-n" class="agree-input__radio" name="agree">
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>

            <div class="contents first_input_box">
                <div class="input-form type1">
                    <p class="input-form__top-text">
                        <span class="c-red">* </span> 필수 입력항목
                    </p>
                    <ul class="input-form__list">
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>이름
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" placeholder="홍길동">
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>주민등록번호
                                </div>
                                <div class="cont row">
                                    <input type="text" class="cont__text-input" placeholder="000000">
                                    <span class="line">-</span>
                                    <input type="text" class="cont__text-input" placeholder="0000000">
                                </div>
                            </div> 
                        </li>
                    </ul>
                </div>
            </div>

            <div class="certify_content">
                <p>미성년자 실명인증이 안될 경우 </p>
                <p>아래의 사이트에서 실명 등록을 먼저 해주시기 바랍니다.</p>
            </div>
            <div class="certify_content">
                <p>개인 실명등록 홈페이지 접속<span>(www.siren24.com)</span>→ SIREN24 서비스 바로가기→ 실명등록 (실명등록 완료까지 약 24시간 소요) → 익일 창원파티마병원 홈페이지에서 실명인증 재시도</p>
            </div>


            <div class="btn-wrap center first_btn">
                <a href="javascript:;" class="btn" @click="hidePopup">취소</a>
                <a href="javascript:;" class="btn blue">간편예약 신청</a>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                
            };
        },
        methods: {
           
        }
    };
</script>