<template>
    <!-- 비뇨의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>비뇨의학과에서는 신장, 요관, 방광, 전립선, 남성의학, 요로결석 등의 진단과 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>배뇨장애, 전립선질환, 요로결석, 비뇨기계수술</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">전립선비대증</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">노화 현상이지만 젊은 연령도 관리 필요</p>
                                <p>전립선은 40대에 접어들면서 서서히 크기가 증가해 주로 50대부터 전립선비대증으로 진행되게 되며, 60~70대에는 당연히 유병률이 더욱 증가합니다. 전립선비대증 외에도 전립선에는 전립선염이 생길 수 있으며, 전립선비대증의 합병증으로도 생길 수 있지만 그와 상관없이 20~30대의 젊은 연령층에서도 발생할 수 있기 때문에 젊은 연령에서도 전립선에 대한 진찰과 검사가 필요합니다.</p>
                                <p>전립선비대증은 암으로 발전하지는 않지만 크기가 자란 전립선은 단순히 ‘양성’ 전립선비대증인 것 외에 전립선암이 숨어 있을 수 있어 이를 가려내기 위해 배뇨장애가 있는 남성 환자들은 기본적으로 직장 수지 검사, 전립선 초음파 검사, 혈액검사(PSA)를 시행해봐야 합니다. 혈액검사 시 전립선특이항원 수치가 계속 높은 경우 전립선암이 숨어 있을 수 있음을 의미하므로 암 여부를 확인하기 위한 전립선 조직검사를 시행하고 있습니다.</p>
                                <p>전립선비대증을 나이 탓으로만 여기거나 대수롭지 않게 넘겨 방치하는 경우가 많으나, 비대해진 전립선은 자연적으로 줄어들지 않기 때문에 되도록 조기에 발견하여 치료하는 것이 좋습니다. 가벼운 소변 불편감이라도 지속된다면 비뇨의학과 상담을 받아 보는 것이 좋으며, 특히 소변에 피가 나오는 경우, 소변을 여러 번 보는데도 잘 나오지 않고 하복부가 불편하거나 팽만한 경우 빨리 내원하여 비뇨의학과 전문의 진료 및 검사를 받아 봐야 합니다.</p>
                                <br>
                                <p style="font-weight:bold;">전립선비대증 내시경수술</p>
                                <p>전립선비대증은 대개 먹는 약으로 치료를 시작합니다. 약을 완전히 중단하기는 어려우며, 꾸준히 복용하는 것이 중요합니다. 약을 먹어도 효과가 없고, 소변이 막혀 나오지 않는 경우가 반복되거나, 염증, 방광결석 같은 합병증이 생긴 경우, 다른 건강 문제로 인해 약을 복용할 수 없는 경우에는 수술치료가 필요합니다. 창원파티마병원 비뇨의학과에서는 요도로 들어가는 내시경을 이용한 수술로 전기 기구로 전립선 조직을 깎아내거나, 홀뮴 레이저를 이용하여 전립선을 도려내는 수술이 가능하며, 환자 개인의 상태에 맞춰 수술 방법을 달리 합니다. 보통 수술 후 3~5일간 소변줄을 착용한 상태로 입원 치료 후 퇴원합니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">요로결석</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">빠른 진단 및 치료 필요한 응급 질환</p>
                                <p>요로에 생긴 결석이 소변이 통과하는 것을 방해해 여러 문제를 야기하는 질환으로, 가장 흔한 증상으로는 옆구리와 배의 갑작스런 통증과 함께 구역질이나, 구토, 혈뇨 등이 있습니다. 간혹 요로결석으로 인한 옆구리 통증을 자연 회복하는 단순한 복통으로 여겨 제때 병원을 찾지 않아 진단과 치료가 늦어지는 환자들이 있으며, 심한 요로 감염이나 패혈증, 콩팥 기능이 돌이킬 수 없을 정도로 쇠퇴하는 합병증을 동반할 수도 있기 때문에 빠른 진단과 치료가 필요한 응급 질환이라 할 수 있습니다. 따라서 의심 증상이 있다면 지체 없이 곧바로 병원을 찾는 것이 좋습니다.</p>
                                <p>수분을 제대로 섭취하지 않으면 소변량이 줄고, 돌이 될 만한 칼슘과 기타 다양한 성분이 소변에 농축되면서 요로결석이 잘 발생합니다. 하지만 요 농축 외에도 몸 속 소변이 지나가는 통로의 생김새가 비정상적인 경우나 전립선비대증, 신경인성 방광 같이 소변 배출에 지장을 주는 다른 비뇨기 질환에 의해서도 요로결석이 생기기도 합니다. 또한 영양 불균형이나 비만인 사람에서도 요로결석이 많이 발견됩니다.</p>
                                <br>
                                <p style="font-weight:bold;">체외충격파쇄석술·내시경 수술</p>
                                <p>크기가 작은 돌, 특히 4㎜ 이하의 돌은 상당수 자연 배출이 되지만 그러한 기대 요법 중에 극심한 통증이 따르거나, 크기가 큰 돌의 경우 좀 더 적극적인 치료로 체외충격파 쇄석술이나 내시경 수술을 선택해 치료할 수 있습니다. 체외충격파쇄석술은 특수한 침대에 누운 상태에서 옆구리에 충격파를 주어 돌을 깨는 치료 방법으로 외래 통원 치료가 가능한 장점은 있지만, 단단하거나 크기가 큰 돌의 경우 또는 불리한 위치의 돌은 여러 차례 시술이 필요한 단점도 있습니다.</p>
                                <p>요관 내시경을 이용한 결석 제거수술은 소변 통로를 그대로 따라 올라가는 최소 침습적인 방법으로, 보이는 상처 없이 치료가 끝나고, 2~3일 정도로 비교적 입원 기간이 짧으며, 크기가 크거나 단단한 돌도 한 번의 수술로 거의 100%에 가까운 완치율로 치료할 수 있어 각광 받고 있습니다. 무엇보다 결석에 의한 통증에서 바로 해방될 수 있어 직업상 통증이나 합병증으로 의한 응급상황에 대처하기 힘든 경우, 해외여행이나 다른 중요한 일을 앞두고 있는 환자들에게도 매우 유용한 치료법입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>