<template>
    <div class="board-list">
        <div class="praise__btn-wrap">
            <button type="button" class="praise__btn blue" @click="$router.push('/healthcare/counselRegister.do')">건강상담 글쓰기</button>
            <button type="button" class="praise__btn" @click="$router.push('/mypage/board/counsel.do')">작성한 글로 바로가기</button>
        </div>
        <Search :searchArr="searchArr" @setState="search"></Search>

        <div class="board-list__table contents">
            <table>
                <colgroup>
                    <col style="width: 100px;">
                    <col style="width: auto">
                    <col style="width: 100px;">
                    <col style="width: 150px;">
                </colgroup>
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th>제목</th>
                        <th>게시자</th>
                        <th>등록일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="list.length == 0"><td colspan="4">작성된 글이 없습니다.</td></tr>
                    <tr v-for="(article, idx) in list" :key="idx">
                        <td class="number"><span class="num">{{ article.rowNum }}</span>
                            <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                        </td>
                        <td class="left tit">
                            <router-link :to="`/${board.boardId}/view.do?boardNo=${board.boardNo}&articleNo=${article.articleNo}`" class="board-list__link">
                                <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                                <p>{{article.title}}</p>
                            </router-link>
                        </td>
                        <td class="date">{{article.writer}}</td>
                        <td class="view">{{$commonUtils.timestampToDate(article.createdDt)}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="contents">
                <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Search from '../common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'
import { data } from 'vue3-timepicker'

export default {
    components: {
        Search,
        Pagination
    },
    props: {
        boardInfo: ''
    },
    data() {
        return {
            board: {},
            list: [],
            pageList: '',
            totalPage: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 10,
            //검색 관련 parameter
            title: this.$route.query.title || '',
            content: this.$route.query.content || '',
            searchKeyword: this.$route.query.searchKeyword || '',

            // 검색박스 셀렉트 옵션 리스트
            searchArr: [
                {'title':'전체', 'value':'searchKeyword'}
                , {'title':'제목', 'value':'title'}
                , {'title':'글', 'value':'content'}
            ],
        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if (to.query != from.query && to.path == from.path) {
                if(Object.keys(to.query).length === 0) this.init()
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }

                this.getList()
            }
        },
        // 다른 게시판으로 이동
        boardInfo(oldVal, newVal) {
            this.init()
            this.getList()
        }
    },
    methods: {
        getList() {
            const boardNo = this.boardInfo.boardNo
            const params = {
                instNo: 1,
                boardNo: boardNo,
                startIndex: this.currentNum,
                pageRow: this.pageRow,
                title: this.title,
                content: this.content,
                searchKeyword: this.searchKeyword,
                item3: 'Y'
            }
            this.axios.get(this.api.article.list + boardNo, {
                params: params
            })
                .then(res => {
                    if(res.data.list) this.list = res.data.list
                    this.board = res.data.board
                    this.totCnt = res.data.totCnt
                })
        },
        // 검색 emit
        search(t, v) {
            this.init()
            if (t == '') this.searchKeyword = v
            this[t] = v
            this.goPage()
        },
        // pagination emit
        setNum(n) {
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = { currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword };
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj });
        },
        // 데이터 초기화
        init() {
            this.currentNum = 1
            this.title = ''
            this.content = ''
            this.searchKeyword = ''
        }
    },
    mounted() {
        this.getList()
    },
}
</script>