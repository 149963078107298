<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>

    <div class="certification">
        <ul class="contents login-box__list">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit">본인 인증</h3>
                <p class="desc">본인명의 휴대폰, 공동인증서</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">인증하기</a></div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit">아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">인증하기</a></div>
            </li>
        </ul>
    </div>

    <div class="contents info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">유의사항</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>어린이 회원가입은 본인인증(휴대폰 또는 아이핀)과 법적대리인(보호자)인증 후 가입이 가능합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>본인인증 방법 선택 후 팝업창이 나타나지 않으면 브라우저의 팝업차단을 해제해 주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>이이핀(I-PIN) 인증 또는 휴대폰 인증시 장애가 있으신 경우는 나이스평가정보 실명확인 서비스 기관에 문의하시기 바랍니다. <br>
                                        나이스평가정보 본인 인증 콜센터 : 1600-1522</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>