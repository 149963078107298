<template>
    <div class="pray_active full-width">
        <div class="pray_tit">
            <h3><b class="red__title">원목</b>은 하느님의 치유도구로 부름 받은 원목자들이 의료팀, 직원들과 함께 협력하여<br>환자의 신체적 고통을 덜어주고, 병실 방문과 상담을 통한 영적, 정서적 돌봄으로 하느님에<br>대한 사랑, 신뢰, 희망을 체험 할수 있도록 돕는 활동을 말합니다.</h3>

            <div class="pray_wings_wrap">
                <img src="@/assets/resource/icon/wings_left_icon.png" class="wings" alt="날게아이콘">
                <div class="pray_form_wrap">
                    <h4>
                        <img src="@/assets/resource/icon/wings_left_s_icon.png" alt="이미지" class="left_wings_small"> 마음택배
                        <img src="@/assets/resource/icon/wings_right_s_icon.png" alt="이미지" class="right_wings_small">
                    </h4>
                    <p>방문기도 신청하기를 통해 병실과 환자 이름을 기재해<br>주시면 병실을 방문하여 기도합니다.</p>
                    <div class="contents pray_flex_from">
                        <div class="pray_form mr15">
                            <p><b>병실 호수</b></p>
                            <input type="text" placeholder="ex) 123호" v-model="item1">
                        </div>
                        <div class="pray_form ml15">
                            <p><b>환자 이름</b></p>
                            <input type="text" placeholder="홍길동" v-model="name">
                        </div>
                    </div>
                    <p class="pray_desc">※ 환자 정보는 비공개로 접수되며 방문기도 신청 외 목적으로 사용되지않습니다.</p>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn mint" @click="insertData">방문기도 신청하기</a>
                    </div>
                </div>
                <img src="@/assets/resource/icon/wings_right_icon.png" class="wings" alt="날게아이콘">
            </div>
        </div>
        <div class="inner contents">
            <div class="info-text depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">활동안내</h4>
                </div>
            </div>
            <div class="pray__activity">
                <img src="@/assets/resource/img/activity-img1.png" alt="이미지">
            </div>
            <div class="table-wrap table-type1 scroll" @click="addClass">
                <div class="scroll">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                            <col style="width:17%;">
                            <col style="width:17%;">
                            <col style="width:66%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">구분</th>
                                <th>활동내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2">환우영적돌봄</td>
                                <td class="text_left">병실방문, 대화, 기도, 상담</td>
                            </tr>
                            <tr>
                                <td colspan="2">전례</td>
                                <td class="text_left">주일미사, 평일미사, 장례미사, 연도, 십자가의 길, 세계병자의 날 등</td>
                            </tr>
                            <tr>
                                <td colspan="2">성사생활</td>
                                <td class="text_left">세례성사, 고해성사, 병지성사, 봉성체, 대세</td>
                            </tr>
                            <tr>
                                <td colspan="2">직원사목</td>
                                <td class="text_left">개인상담. 성사생활, 신심단체, 세계 병자의 날, 부활·성탄행사, 파티마 성모의 밤, 성지순례 등</td>
                            </tr>
                            <tr>
                                <td rowspan="3">그 외 활동</td>
                                <td>예비자 교리</td>
                                <td class="text_left">직원들을 위한 예비자 교리반 개설</td>
                            </tr>
                            <tr>
                                <td style="border-left:1px solid #d9d9d9">피정</td>
                                <td class="text_left">신자들의 영적쇄신을 위한 피정</td>
                            </tr>
                            <tr>
                                <td style="border-left:1px solid #d9d9d9">270-1287</td>
                                <td class="text_left">레지오 마리애, 전례부</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="contents">
                <div class="info-text depth__wrap">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">전례안내</h4>
                    </div>
                </div>
                <ul class="pray__list" >
                    <li class="pray__item"><img src="@/assets/resource/img/activity-img2.png" alt="이미지"></li>
                    <li class="pray__item"><img src="@/assets/resource/img/activity-img3.png" alt="이미지"></li>
                    <li class="pray__item"><img src="@/assets/resource/img/activity-img4.png" alt="이미지"></li>
                </ul>
                <div class="table-wrap table-type1">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width:25%;">
                                <col style="width:43%;">
                                <col style="width:32%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>일시</th>
                                    <th>장소</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>주말미사</td>
                                    <td>일요일 09:30</td>
                                    <td rowspan="3">1층 성당</td>
                                </tr>
                                <tr>
                                    <td>평일미사</td>
                                    <td>수요일 16:00</td>
                                </tr>
                                <tr>
                                    <td>직원미사</td>
                                    <td>매월 둘째 주 18:00</td>
                                </tr>
                                <tr>
                                    <td>고해성사</td>
                                    <td rowspan="3">신청시</td>
                                    <td rowspan="3">원목실, 병실</td>
                                </tr>
                                <tr>
                                    <td>병자성사</td>
                                </tr>
                                <tr>
                                    <td>대세</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
            <div class="contents">
                <div class="info-text depth__wrap">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">성사안내</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 scroll" @click="addClass">
                    <div class="scroll">
                        <table>
                            <caption class="blind">성사안내</caption>
                            <thead>
                                <tr>
                                    <th scope="col">구분</th>
                                    <th scope="col">성사내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">고해성사</td>
                                    <td class="td_left">죄를 진실하게 뉘우치고 하느님께 용서를 청함으로써 하느님과 화해를 이루고 교회 공동체와 세상과 일치를 이루는 성사입니다. </td>
                                </tr>
                                <tr>
                                    <td scope="row">병자성사</td>
                                    <td class="td_left">아픈 사람을 위하여 하느님께 특별한 축복을 청하는 성사입니다.<br>
                                        임종이나 큰 수술을 앞두고 있을 때 언제든지 받을 수 있습니다. </td>
                                </tr>
                                <tr>
                                    <td scope="row">봉성체</td>
                                    <td class="td_left">거동이 불편한 환자 중 세례를 받은 신자를 대상으로 직접 병실을 방문하여 성체를 모실 수 있도록 도와 드립니다.</td>
                                </tr>
                                <tr>
                                    <td scope="row">대세</td>
                                    <td class="td_left">천주교 신자가 아닌 환자가 위독한 경우 세례를 받을 수 있도록 도와드립니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p class="color_777 small-text">※  문의 : 원목실 270-1004</p>

            </div>

        </div>
    </div>
</template>



<script>
import { ref } from 'vue'
import axios from 'axios'
import api from '@/api/api'

export default {
    setup() {
        const item1 = ref("")
        const name = ref("")
        const addClass = (event) => {
            event.currentTarget.classList.add('on');
        };
        const insertData = () => {
            if(!item1.value){
                alert("병실 호수를 입력해주세요.")
                return
            }
            if(!name.value){
                alert("환자 이름을 입력해주세요.")
                return
            }
            if(confirm("방문기도를 신청하시겠습니까?")){
                const params = {
                    'instNo': '1',
                    'applicantName': name.value,
                    'item1': item1.value,
                    'createdBy': name.value
                }
                axios.post(api.submission.insert, params)
                .then(res=>{
                    alert(res.data.msg)
                    name.value = ''
                    item1.value = ''
                })
            }
        }
        return {
            item1,
            name,
            addClass,
            insertData,
        };
    }
}
</script>
