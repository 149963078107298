<template>
    <div class="board-list">
        <Search :searchArr="searchArr" @setState="setState"></Search>
        <div class="contents board-list__table">
            <table>
                <colgroup>
                    <col style="width: 100px;">
                    <col style="width: 100px;">
                    <col style="width: auto">
                    <col style="width: 150px;">
                </colgroup>
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th>언론사</th>
                        <th>제목</th>
                        <th>보도일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(article, idx) in list" :key="idx">
                        <td class="number">
                            <span class="notice" v-if="article.noticeYn == 'Y'">공지</span>
                            <span class="num" v-else>
                                {{ article.articleNo }}
                            </span>
                            <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                        </td>
                        <td class="view">{{ article.item1 }}</td>
                        <td class="left tit">
                            <a :href="article.linkUrl" class="board-list__link" v-if="article.linkUrl" :target="article.linkMethod">
                                <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                                <p>{{ article.title }}</p>
                            </a>
                            <router-link :to="`/${board.boardId}/view.do?boardNo=${board.boardNo}&articleNo=${article.articleNo}`" class="board-list__link" v-else>
                                <p>{{ article.title }}</p>
                            </router-link>
                        </td>
                        <td class="view">{{ article.item2 }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- 안론보도 0713-->
            <!-- <table>
                <colgroup>
                    <col style="width: 100px;">
                    <col style="width: 100px;">
                    <col style="width: auto">
                    <col style="width: 150px;">
                </colgroup>
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th>언론사</th>
                        <th>제목</th>
                        <th>보도일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(article, idx) in list" :key="idx">
                        <td class="number">
                            <template v-if="article.noticeYn == 'Y'">
                                <span class="notice">공지</span>
                            </template>
                            <template v-else>
                                <span class="num">
                                    {{ article.articleNo }}
                                </span>
                            </template>
                        </td>
                        <td></td>
                        <td class="left tit">
                            <a :href="article.linkUrl" class="board-list__link" v-if="article.linkUrl" :target="article.linkMethod">
                                <p>{{ article.title }}</p>
                            </a>
                            <router-link :to="`/${board.boardId}/view.do?boardNo=${board.boardNo}&articleNo=${article.articleNo}`" class="board-list__link" v-else>
                                <p>{{ article.title }}</p>
                            </router-link>
                        </td>
                        <td class="date">{{ $commonUtils.timestampToDate(article.createdDt) }}</td>
                    </tr>
                </tbody>
            </table> -->
            <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
        </div>
    </div>
</template>

<script>
    import Search from '../common/SearchBox.vue'
    import Pagination from '../common/Pagination.vue'

    export default {
        components: {
            Search,
            Pagination
        },
        props: {
            boardInfo: ''
        },
        data() {
            return {
                board: {},
                list: '',
                pageList: '',
                pageCount: window.innerWidth > 720 ? 10 : 5,
                totCnt: 0,

                // 게시글 parameter
                currentNum: this.$route.query.currentNum || 1,
                pageRow: 10,
                //검색 관련 parameter
                title: this.$route.query.title || '',
                content: this.$route.query.content || '',
                searchKeyword: this.$route.query.searchKeyword || '',

                // 검색박스 셀렉트 옵션 리스트
                searchArr: [
                    { 'title': '전체', 'value': 'searchKeyword' }
                    , { 'title': '제목', 'value': 'title' }
                    , { 'title': '내용', 'value': 'content' }
                    , { 'title': '언론사', 'value': 'item1' }
                ],
            }
        },
        watch: {
            $route(to, from) {
                // 동일 게시판 안에서 이동
                if (to.query != from.query && to.path == from.path) {
                    if (Object.keys(to.query).length === 0) this.init()
                    for (let i in this.$route.query) {
                        this[i] = this.$route.query[i]
                    }

                    this.getList()
                }
            },
            // 다른 게시판으로 이동
            boardInfo(oldVal, newVal) {
                this.init()
                this.getList()
            }
        },
        methods: {
            getList() {
                const boardNo = this.boardInfo.boardNo
                const params = {
                    instNo: 1,
                    boardNo: boardNo,
                    startIndex: this.currentNum,
                    pageRow: this.pageRow,
                    title: this.title,
                    content: this.content,
                    searchKeyword: this.searchKeyword,
                }
                this.axios.get(this.api.article.list + boardNo, {
                    params: params
                })
                    .then(res => {
                        this.list = res.data.list
                        this.board = res.data.board
                        this.totCnt = res.data.totCnt

                    })
            },
            // 검색 emit
            setState(s, v, p) {
                this[p] = ''
                this.currentNum = 1
                this[s] = v
                this.goPage()
            },
            // pagination emit
            setNum(n) {
                this.currentNum = n
                this.goPage()
            },
            goPage() {
                const obj = { currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword };
                for (const key in obj) {
                    if (obj[key] === null || obj[key] === '') {
                        delete obj[key];
                    }
                }

                this.$router.push({ path: this.$route.path, query: obj });
            },
            // 데이터 초기화
            init() {
                this.currentNum = 1
                this.title = ''
                this.content = ''
                this.searchKeyword = ''
            }
        },
        mounted() {
            this.getList()
        },
    }
</script>