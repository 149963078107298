<template>
    <!-- 통증클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 마취통증의학과에서는 몸의 통증으로 인해 발생하는 생활의 불편함을 효과적으로 줄이기 위해 통증클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text heart_appointment center_box">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">통증을 관리해야 하는 이유</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>통증은 신체나 장기 또는 부속기관의 이상여부를 알려주는 여러 가지 신호 중 하나로 통증을 치료하지 않고 방치 할 경우 그 자체가 병이 되어 면역을 떨어드리고 혈액순환이 원활하지 않게 돼 또 다른 질환을 유발할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">통증클리닉이 필요한 경우</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>대상포진 및 대상포진 후 신경통</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>여러 원인의 요통- 추간판탈출증, 척추관협착증 등</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>척추 수술 후의 통증, 좌골신경통</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>두통</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>여러 가지 안면통</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>어깨, 목의 통증</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>암으로 인한 통증</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>여러 신경마비, 안면경련</p>
                            </div>
                        </div>
                          <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>근육통 – 격렬한 운동 후, 바르지 않은 자세 등에 의한 근막통증증후군</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="img-box-wrap">
    <img src="/resource/img/cerebrovascular01.jpg" alt="이미지">
    <img src="/resource/img/cerebrovascular02.jpg" alt="이미지">
    <img src="/resource/img/cerebrovascular03.jpg" alt="이미지">
</div>
    </div>
</template>


<script>
export default {
   
};
</script>