<template>
    <div class="my_health_wrap contents">
        <div class="day_result_box">
            <div class="day_result_item my_day">
                <div class="calendar">
                  <div class="btn-wrap">
                          <a href="javascript:;" class="btn">오늘</a>
                    </div>

                    <div class="calendar__header">
                        <button class="calendar__header--button prev"></button>
                        <h4 class="calendar__header--title">2022.08</h4>
                        <button class="calendar__header--button next"></button>
                    </div>
                    
                    <div class="calendar__hcon">
                        <table>
                            <caption> 예약일 선택 테이블 </caption>
                            <colgroup>
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>일</th>
                                    <th>월</th>
                                    <th>화</th>
                                    <th>수</th>
                                    <th>목</th>
                                    <th>금</th>
                                    <th>토</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td><a href="javascript:;">1</a></td>
                                    <td><a href="javascript:;">2</a></td>
                                    <td><a href="javascript:;">3</a></td>
                                    <td><a href="javascript:;">4</a></td>
                                    <td><a href="javascript:;">5</a></td>
                                    <td><a href="javascript:;">6</a></td>
                                </tr>
                                <tr>
                                    <td><a href="javascript:;">7</a></td>
                                    <td class="pos"><a href="javascript:;">8</a></td>
                                    <td><a href="javascript:;">9</a></td>
                                    <td><a href="javascript:;">10</a></td>
                                    <td class="pos"><a href="javascript:;">11</a></td>
                                    <td><a href="javascript:;">12</a></td>
                                    <td><a href="javascript:;">13</a></td>
                                </tr>
                                <tr>
                                    <td><a href="javascript:;">14</a></td>
                                    <td><a href="javascript:;">15</a></td>
                                    <td><a href="javascript:;">16</a></td>
                                    <td class="pos"><a href="javascript:;">17</a></td>
                                    <td><a href="javascript:;">18</a></td>
                                    <td><a href="javascript:;">19</a></td>
                                    <td><a href="javascript:;">20</a></td>
                                </tr>
                                <tr>
                                    <td><a href="javascript:;">21</a></td>
                                    <td><a href="javascript:;">22</a></td>
                                    <td><a href="javascript:;">23</a></td>
                                    <td class="pos"><a href="javascript:;">24</a></td>
                                    <td><a href="javascript:;">25</a></td>
                                    <td><a href="javascript:;">26</a></td>
                                    <td><a href="javascript:;">27</a></td>
                                </tr>
                                <tr>
                                    <td><a href="javascript:;">28</a></td>
                                    <td><a href="javascript:;">29</a></td>
                                    <td class="pos active"><a href="javascript:;">30</a></td>
                                    <td><a href="javascript:;">31</a></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="calendar__bottom">
                        <div class="agree-input">
                          <label for="all-health" class="agree-input__item">
                            <input type="radio" id="all-health" class="agree-input__radio" name="agree" checked>
                            <span class="icon"></span> 전체 
                          </label>
                          <label for="blood-power" class="agree-input__item">
                            <input type="radio" id="blood-power" class="agree-input__radio" name="agree">
                            <span class="icon"></span> 혈압 
                          </label>
                          <label for="blood-sugar" class="agree-input__item">
                            <input type="radio" id="blood-sugar" class="agree-input__radio" name="agree">
                            <span class="icon"></span> 혈당 
                          </label>
                          <label for="bmi-body" class="agree-input__item">
                            <input type="radio" id="bmi-body" class="agree-input__radio" name="agree">
                            <span class="icon"></span> 체질량 
                          </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="day_result_item my_health">
                <div class="health_register_wrap">
                  <p class="day_register"><b>2022.08.30</b>&nbsp;측정결과</p>

                  <div class="measure_resister">
                      <ul class="measure_resister_list">
                        <li class="measure_resister_item resister_item01">
                          <h3>혈압</h3>
                          <img src="@/assets/resource/icon/bloodpower_icon.png" alt="아이콘">
                          <div class="resister_item_txt">
                            <p>전고혈압</p>
                            <h4>80~120<span>&nbsp;mmHg</span></h4>
                          </div>
                          <button>+ &nbsp;혈압 등록</button>
                        </li>

                        <li class="measure_resister_item resister_item02">
                          <h3>혈당</h3>
                          <img src="@/assets/resource/icon/bloodsugar_icon.png" alt="아이콘">
                          <div class="resister_item_txt">
                            <p>저혈당</p>
                            <h4>50<span>&nbsp;mg/dl</span></h4>
                          </div>
                          <button>+ &nbsp;혈당 등록</button>
                        </li>

                        <li class="measure_resister_item resister_item03">
                          <h3>체질량</h3>
                          <img src="@/assets/resource/icon/bmibody_icon.png" alt="아이콘">
                          <div class="resister_item_txt resister_not_data">
                            <!-- <p>고도비만</p>
                            <h4>35<span>&nbsp;Kg/m²</span></h4> -->
                            <p class="not_data_txt">등록된 데이터가 없습니다.</p>
                          </div>
                          <button>+ &nbsp;체질량 등록</button>
                        </li>

                      </ul>
                  </div>

                </div>
            </div>
        </div>

        <div class="contents health_info_wrap">
          <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
              <span class="bullet01"></span>
            </div>
            <div class="depth__text">
              <h4 class="depth01__title title">검사정보</h4>
            </div>
          </div>

          <div class="tab-menu type02">
            <ul class="tab-menu__list">
              <li class="tab-menu__item active">
                <button type="button" class="tab-menu__btn">혈압</button>
              </li>
              <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">혈당</button>
              </li>
              <li class="tab-menu__item">
                <button type="button" class="tab-menu__btn">체질량</button>
              </li>
            </ul>
          </div>

          <div class="date_wrap contents">
            <div class="date-input">
                   <VueDatePicker v-model="selectedDateRange" locale="ko"
                      :enable-time-picker="false" :format="customFormat"
                      :week-start="0" :day-class="getDayClass"
                      :select-text="'선택'" :cancel-text="'취소'"
                      :range="true" :multi-calendars="true"/>
              <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
            </div>

            <div class="tab-menu type_date">
              <ul class="tab-menu__list">
                <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                  <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                  <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                  <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                  <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                </li>
              </ul>
            </div>
            
            <div class="health_info_search">
              <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
            </div>

          </div>
        </div>

        <div class="contents">
            <div class="chart_wrap">
               <HealthChart />
            </div>
        </div>

        <div class="contents pt60">
          <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
              <span class="bullet01"></span>
            </div>
            <div class="depth__text">
              <h4 class="depth01__title title">측정 상세정보</h4>
            </div>
          </div>

          <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
            <div class="scroll">
              <table class="scroll">
                <caption class="blind">측정 상세정보</caption>
                <colgroup>
                  <col style="width:auto;">
                  <col style="width:19.5%;">
                  <col style="width:19.5%;">
                  <col style="width:19.5%;">
                  <col style="width:19.5%;">
                  <col style="width:19.5%;">
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>측정일자</th>
                    <th>측정시간</th>
                    <th>수축기혈압</th>
                    <th>이완기혈압</th>
                    <th>혈압상세</th>
                  </tr>
                </thead>
                <tbody> 
                  <tr>
                      <td class="check">
                        <input type="checkbox" id="check1">
                        <label for="check1"></label>
                      </td>
                      <td>2024.06.22</td>
                      <td>10:31</td>
                      <td>100</td>
                      <td>80</td>
                      <td>전고혈압</td>
                    </tr>
                    <tr>
                      <td class="check">
                        <input type="checkbox" id="check2">
                        <label for="check2"></label>
                      </td>
                      <td>2024.06.20</td>
                      <td>19:59</td>
                      <td>80</td>
                      <td>60</td>
                      <td>저혈압</td>
                    </tr>
                    <tr>
                      <td class="check">
                        <input type="checkbox" id="check3">
                        <label for="check3"></label>
                      </td>
                      <td>2024.06.19</td>
                      <td>15:31</td>
                      <td>120</td>
                      <td>90</td>
                      <td>정상</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="contents btn-wrap right"><a href="javascript:;" class="btn remove_btn">삭제</a></div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import HealthChart from '@/components/common/HealthChart.vue'; 

export default {
    components: {
        VueDatePicker,
        HealthChart 
  },
  setup() {
    const customFormat = 'yyyy.MM.dd'; 
    const selectedDateRange = ref([new Date(), new Date()]);
    const selectedPeriod = ref(3); 

   
    const setSelectedPeriod = (months) => {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setMonth(startDate.getMonth() + months);
      selectedDateRange.value = [startDate, endDate]; 
      selectedPeriod.value = months; 
    };

   
    const getDayClass = (date) => {
      const day = date.getDay();
      if (day === 0) {
        return 'sunday-class';
      } else if (day === 6) {
        return 'saturday-class';
      }
      return '';
    };
      
    const addClass = (event) => {
      event.currentTarget.classList.add('on');
    };
     

    onMounted(() => {
      setSelectedPeriod(3); 
    });

    return {
      customFormat,
      selectedDateRange,
      selectedPeriod,
      setSelectedPeriod,
      getDayClass,
      addClass
    };
  }
};

</script>
