<template>
    <div class="my_result_wrap_view contents">
        <div class="info-text depth__wrap depth01">
          <div class="depth__bullet">
            <span class="bullet01"></span>
          </div>
          <div class="depth__text">
            <h4 class="depth01__title title">외래정보</h4>
          </div>
        </div>
        <div class="table-cont">
          <ul class="table-list type02">
            <li class="table-item">
              <div class="item tit">처방일자</div>
              <div class="item">2024.05.17</div>
            </li>
            <li class="table-item">
              <div class="item tit">구분</div>
              <div class="item">외래</div>
            </li>
            <li class="table-item">
              <div class="item tit">진료과</div>
              <div class="item">소화기내과</div>
            </li>
            <li class="table-item">
              <div class="item tit">진료의</div>
              <div class="item">박종호 과장</div>
            </li>
          </ul>
        </div>

        <div class="contents">
           <div class="info-text depth__wrap depth01">
              <div class="depth__bullet">
                <span class="bullet01"></span>
              </div>
              <div class="depth__text">
                <h4 class="depth01__title title">외래약 처방내역</h4>
              </div>`
          </div>
          <div class="table-wrap table-type1 scroll" @click="addClass">
            <div class="scroll">
              <table>
                <caption class="blind">표</caption>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:15%;">
                  <col style="width:15%;">
                  <col style="width:15%;">
                  <col style="width:15%;">
                </colgroup>
                <thead>
                  <tr>
                    <th>약품명</th>
                    <th>처방량</th>
                    <th>횟수</th>
                    <th>용법</th>
                    <th>일수</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>류코스팀 프리필드시린지 주 150MCG동아</td>
                      <td>1SRG</td>
                      <td>피하주사</td>
                      <td></td>
                      <td>1일</td>
                    </tr>
                     <tr>
                      <td>코데인 인산염 정20mg(구주)</td>
                      <td>1 TAB</td>
                      <td>식후 30분</td>
                      <td></td>
                      <td>7일</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        <div class="contents">
          <div class="btn-wrap center">
            <a href="javascript:;" class="btn gray">목록</a>
          </div>
        </div>
       
    </div>
</template>

<script>
export default {
 setup() {
    const addClass = (event) => {
      event.currentTarget.classList.add('on');
    };
    return {
      addClass,
    };
  }
};

</script>
