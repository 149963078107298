<template>
    <div>

        <div class="contents input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">제목</div>
                        <div class="cont">용종제거</div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">이름</div>
                        <div class="cont">이*용</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit">등록일자</div>
                        <div class="cont">
                            2024.05.07
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">내용</div>
                        <div class="cont">
                            어제 인접의원에서 건강검진 대장에 작은 용종 제거 큰 거 1개 상급병원가서 제거 하라네요
                            궁금한 건 또 대장 약물 복용해야 하는 지 진료의뢰서 씨디만 있으면 제거가가능한지요?
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">답변</div>
                        <div class="cont">

                            안녕하세요. 문의하신 “용종제거”는 바로 제거되지 않습니다. 접수 -> 진료-> 처방 -> 예약
                            ->검사를 거쳐므로 제거 시에는 금식과 함께 장 청소가 되어 있어야 합니다
                            ------- 중략 ----------
                            <div class="date">2024.05.08.</div>
                        </div>
                    </div>
                </li>

            </ul>
        </div>

        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn blue">목록</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>