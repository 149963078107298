<template>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home">
                    <a href="" class="Location__item--btn">
                        <img src="../../assets/resource/icon/Location_home.png" alt="이미지">
                    </a>
                </li>
                <li class="Location__item" v-for="(item, index) in menuItems" :key="index" :class="{ 'active': activeIndex === index }">
                    <a href="javascript:;" class="Location__item--btn" @click="toggleActive($event, index)">
                        <p>{{ item.label }}</p>
                        <span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
                                <component :is="subItem.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="subItem.menuUrl" :to="subItem.menuUrl">{{ subItem.menuName }}</component>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
  </div>
</template>


<script>
export default {
    data() {
        return {
            menuItems: [
                // { label: '1댑스', subItems: ['1댑스 서브', '1댑스 서브', '1댑스 서브', '1댑스 서브'] },
                // { label: '2댑스', subItems: ['2댑스 서브', '2댑스 서브', '2댑스 서브', '2댑스 서브', '2댑스 서브'] },
                // { label: '3댑스', subItems: ['3댑스 서브', '3댑스 서브', '3댑스 서브', '3댑스 서브', '3댑스 서브'] },
            ],
            activeIndex: -1,
            activeMenu: {}
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);

        let path = this.$commonUtils.getMenuPath()

        const list = this.menuList.sort((a, b) => {
            return b.depth - a.depth
        })

        // menuList의 url과 현재 url이 같으면 activeMenu에 저장, menuType이 B,C인 메뉴만 적용
        let menuNo = ''
        for(let i = 0; i < list.length; i++){
            if(list[i].menuUrl == path && list[i].useYn == 'Y'){
                this.activeMenu = list[i]
                if(this.activeMenu.depth == 4){
                    menuNo = this.activeMenu.parentMenuNo
                    this.activeMenu = {}
                }
                if(this.activeMenu.menuType){
                    break
                }
            }
            if(menuNo != '' && list[i].menuNo == menuNo) this.activeMenu = list[i]
        }

        // activeMenu의 depth를 구하고, 상위 메뉴들도 설정해준다.
        let depth = this.activeMenu.depth

        for(let i = depth - 1; i >= 0; i--){
            let menuItem = {subItems: []}

            menuItem.label = this.activeMenu.menuName

            for(let j = 0; j < this.menuList.length; j++){
                if(this.menuList[j].parentMenuNo == this.activeMenu.parentMenuNo && this.menuList[j].gnbYn == 'Y'){
                    menuItem.subItems.push(this.menuList[j])
                }
            }

            this.menuItems[i] = menuItem

            for(let j = 0; j < this.menuList.length; j++){
                if(this.menuList[j].menuNo == this.activeMenu.parentMenuNo){
                    this.activeMenu = this.menuList[j]
                    break
                }
            }

        }
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        // toggleActive(index) {
        //     if (this.activeIndex === index) {
        //         this.activeIndex = -1; // 클릭된 메뉴를 다시 누르면 엑티브 제거
        //     } else {
        //         this.activeIndex = index;
        //     }
        // },
        // handleClickOutside(event) {
        //     if (!this.$el.contains(event.target)) {
        //         this.activeIndex = -1; // 메뉴 외부를 클릭하면 엑티브 제거
        //     }
        // },
        toggleActive(event,index) {
            const target = event.currentTarget;
            const parentLayer = target.closest('.Location__item').querySelector('.Location__item--Layer');
            if (this.activeIndex === index) {
                // 두 번째 클릭 시 높이를 0으로 설정
                parentLayer.style.height = '0';
                this.activeIndex = -1; // 비활성화
            } else {
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
                const ulHeight = parentLayer.querySelector('ul').offsetHeight + 'px';
                parentLayer.style.height = ulHeight;
                this.activeIndex = index; // 활성화
            }
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                // 메뉴 외부를 클릭하면 엑티브 제거
                this.activeIndex = -1;

                // .Location__item--Layer의 높이를 0으로 설정
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
            }
        }

    }
}
</script>
