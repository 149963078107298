<template>
   <div class="director_history medicalOffice_wrap">
        <div class="contents">
            <div class="history_swiper_wrap full-width">
                <div class="medicalDirector"><img src="@/assets/resource/img/MedicalDirector.png" alt="MedicalDirector"></div>
                <div class="swiper mySwiper inner02">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="direcor_items">
                                <img src="@/assets/resource/img/medical_dr01.png" alt="의무원장 김병일">
                                <h3 class="blue__title">초대의무원장</h3>
                                <h4>김병일</h4>
                                <p>(1969.9.1 ~ 1976.3.14)</p>
                            </div>
                             <div class="direcor_items">
                                <img src="@/assets/resource/img/medical_dr02.png" alt="의무원장 김형규">
                                <h3 class="blue__title">제2대의무원장</h3>
                                <h4>김형규</h4>
                                <p>(1976.3.15 ~ 1980.7.30)</p>
                             </div>
                               <div class="direcor_items">
                                <img src="@/assets/resource/img/medical_dr03.png" alt="의무원장 이일웅">
                                <h3 class="blue__title">제3대의무원장</h3>
                                <h4>이일웅</h4>
                                <p>(1980.8.1 ~ 1998.3.4)</p>
                             </div>
                             <div class="direcor_items">
                                <img src="@/assets/resource/img/medical_dr04.png" alt="의무원장 구본천">
                                <h3 class="blue__title">제4대의무원장</h3>
                                <h4>구본천</h4>
                                <p>(1998.3.5 ~ 2004.2.29)</p>
                             </div>
                        </div>
                        <div class="swiper-slide">
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/medical_dr05.png" alt="의무원장 김정원">
                                <h3 class="blue__title">제5대의무원장</h3>
                                <h4>김정원</h4>
                                <p>(2004.3.1 ~ 2010.8.31)</p>
                             </div>
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/medical_dr06.png" alt="의무원장 김준호">
                                <h3 class="blue__title">제6대의무원장</h3>
                                <h4>김준호</h4>
                                <p>(2010.9.1 ~ 2014.8.31)</p>
                             </div>
                              <div class="direcor_items">
                                 <img src="@/assets/resource/img/medical_dr07.png" alt="의무원장 정원조">
                                <h3 class="blue__title">제7대의무원장</h3>
                                <h4>정원조</h4>
                                <p>(2014.9.1 ~ 2016.8.31)</p>
                             </div> 
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/medical_dr08.png" alt="의무원장 조성래">
                                <h3 class="blue__title">제8대의무원장</h3>
                                <h4>조성래</h4>
                                <p>(2016.9.1 ~ 2020.8.31)</p>
                             </div>
                        </div>
                        <div class="swiper-slide">
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/medical_dr09.png" alt="의무원장 구본원">
                                <h3 class="blue__title">제9대의무원장</h3>
                                <h4>구본원</h4>
                                <p>(2020.9.1 ~ 현재)</p>
                             </div>
                             
                        </div>
                    </div>
                    <div class="swiper-pagination contents"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
                
            </div>
        </div>
   </div>
</template>



<script>
export default {
    mounted() {
        let swiperMediOffi;

        function initializeSwiper() {
            if (window.innerWidth > 1024) {
                swiperMediOffi = new Swiper('.medicalOffice_wrap .mySwiper', {
                    speed: 400,
                    loop: true,
                    navigation: {
                        nextEl: '.medicalOffice_wrap .swiper-button-next',
                        prevEl: '.medicalOffice_wrap .swiper-button-prev'
                    },
                    pagination: {
                        el: ".medicalOffice_wrap .swiper-pagination",
                    }
                });
            } else if (swiperMediOffi) {
                swiperMediOffi.destroy(true, true);
                swiperMediOffi = null;
            }
        }

        // Initialize Swiper on page load
        initializeSwiper();

        // Re-initialize Swiper on window resize
        window.addEventListener('resize', initializeSwiper);
    },
}
</script>