<template>
    <!-- 인공신장센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>인공신장센터는 60병상 규모로 신장 대체 요법을 이용해 제 기능을 하지 못하는 신장의 기능을 회복하거나 유지할 수 있도록 돕고 있습니다. 특히 음압시설을 갖춘 격리실 2병상이 있어 공기매개주의, 비말주의. 접촉주의 등 감염성질환이 있는 투석 환자와 일반 환자를 구별하여 투석하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">전 병상 최신 혈액투석여과 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>인공신장센터는 전 병상이 일반적인 혈액투석이 아닌 온라인 혈액투석여과를 시행하고 있습니다. 혈액투석여과는 일반 투석에서 제거할 수 없는 분자량이 큰 요독물질을 제거할 수 있어 신부전 환자에서 나타나는 여러 요독 증상을 줄일 수 있으며, 빈혈교정, 심혈관질환의 위험을 줄이는데 효과적일뿐만 아니라 결과적으로 생존율 향상도 기대할 수 있습니다.</p>
                                <p>아직까지 우리나라 전체 투석환자 중 혈액투석여과 치료를 받는 환자는 20%가 안 되는 실정이나 현재 본원에서는 대부분의 환자들이 혈액투석여과 치료를 받고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">창원지역 최초 투석치료정보 관리시스템 도입</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>2014년 창원지역 최초로 투석중인 환자의 혈압, 맥박 등 기본적인 신체정보를 자동으로 알려주는 투석치료 정보관리시스템(TDMS, Therapy Data Management System)을 도입해 투석환자에게 자동화된 투석시스템과 맞춤 서비스를 제공하고 있습니다.</p>
                                <p>투석치료 정보관리시스템(TDMS)은 개인카드를 이용해 개인별 투석 데이터, 혈압, 체중, 휠체어 무게까지 안전하게 전산화 하여 관리하는 시스템으로 투석 데이터를 자동으로 수집해 실시간 모니터링이 가능하며, 수집된 데이터를 통해 효율적인 환자 관리와 치료과정에서 발생할 수 있는 오류를 미연에 방지할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">역삼투압 정수시스템 및 정수시설 살균시스템</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>온라인 혈액투석여과는 투석용수를 직접 혈액내로 주입하기 때문에 무엇보다 투석용수의 수질관리가 중요합니다. 본원 인공신장센터는 모든 혈액투석 환자가 혈액투석여과를 할 수 있을 정도의 대용량의 정수시설을 기본으로, 세균의 오염이나 바이오필름의 형성을 예방효과가 있는 스테인레스 배관과 고온가열소독 시스템을 설치하여 초여과수를 확보하고 있으며 이를 통해 투석환자에게 장기적인 안전한 치료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">투석환자를 위한 다양한 편의시설</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>전 침상 모두 전동침대를 비치하고 있으며, 투석 시 무료하지 않도록 침상별로 LCD 모니터가 설치되어 있습니다. 또한 전병상 투석기별 호출벨을 설치해 환자의 요청과 응급상황에 대비하고 있으며 별도의 휴게실과 탈의실을 갖추고 상담 공간을 마련해 식이요법, 투석방법, 합병증 안내, 약물요법 등의 정보를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">고객편의를 위한 야간 혈액투석 실시 </h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 인공신장센터는 주간에 투석치료를 받을 수 없는 분들의 편의를 위해 야간 혈액투석도 실시하고 있습니다. 야간혈액투석은 월,수,금 18:00~22:30 운영하고 있으며, 문의는 인공신장센터 270-1451로 하면 됩니다. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="img-box-wrap">
    <img src="/resource/img/kidneys01.jpg" alt="이미지">
    <img src="/resource/img/kidneys02.jpg" alt="이미지">
</div>
</template>


<script>
export default {
   
};
</script>