<template>
    <div class="board-view">
        <div class="board-view__top">
            <ul class="board-view__share-list" :class="{active : this.shareList}">
                <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
            </ul>
            <button type="button" class="board-view__btn share" @click="shareFn()"></button>
            <button type="button" class="board-view__btn print"></button>
        </div>
        <div class="board-view__wrap">
            <div class="board-view__tit">
                <h3>내원객을 위한 ‘작은 도서관’ 도서기증</h3>
                <div class="board-view__info">
                    <span class="date">2024.05.30</span>
                    <div class="view">
                        <span class="icon"></span>
                        1,000
                    </div>
                </div>
            </div>
            <div class="board-view__file">
                <div class="tit">첨부파일</div>
                <ul class="file-list">
                    <div class="file-item"><button type="button"><span class="icon"></span><em class="num"></em> 103038임상정보지.hwp(0 Bytes)</button></div>
                    <div class="file-item"><button type="button"><span class="icon"></span><em class="num"></em> 103038임상정보지.hwp(0 Bytes)</button></div>
                    <div class="file-item"><button type="button"><span class="icon"></span><em class="num"></em> 103038임상정보지.hwp(0 Bytes)</button></div>
                    <div class="file-item"><button type="button"><span class="icon"></span><em class="num"></em> 103038임상정보지.hwp(0 Bytes)</button></div>
                </ul>
            </div>
            <!-- 컨텐츠 내용 -->
            <div class="board-view__cont">게시물입니다.</div>

            <div class="board-view__page">
                <div class="board-view__move next">
                    <div class="tit">
                        <div class="arrow-btn"></div>
                        다음 글
                    </div>
                    <a href="javascript:;">다음 글이 없습니다.</a>
                </div>
                <!-- 이전글이 있을경우  on추가 (다음글도 마찬가지) -->
                <div class="board-view__move prev on">
                    <div class="tit">
                        <div class="arrow-btn"></div>
                        이전 글
                    </div>
                    <a href="javascript:;">진료안내] 병·의원 진료 전 본인확인 의무화 제도 *24. 5. 20부터 시행</a>
                </div>
            </div>

            <div class="btn-wrap center">
                <a href="javascript:;" class="btn gray">목록</a>
            </div>
        </div>

    </div>

</template>


<script>
    export default {
        data() {
            return {
                shareList: false
            };
        },
        methods: {
            shareFn() {
                this.shareList = !this.shareList
            },
            addIndexToFileItems() {
                const fileItems = document.querySelectorAll('.file-item');
                fileItems.forEach((item, index) => {
                    const numElement = item.querySelector('.num');
                    if (numElement) {
                        numElement.textContent = index + 1 + '.';
                    }
                });
            }
        },
        mounted() {
            this.addIndexToFileItems();
        },
    };
</script>