<template>
    <div class="praise full-width">
        <div class="praise__tit">
            <h3>
                창원파티마병원에서 만난 고마운 인연이 있으신가요? <br>
                따뜻한 사연과 마음을 전해주세요
            </h3>
            <div class="praise__btn-wrap">
                <button type="button" class="praise__btn">칭찬합니다 글쓰기</button>
                <button type="button" class="praise__btn">작성한 글로 바로가기</button>
            </div>
        </div>
        <div class="inner">
            <h4 class="praise__sub-tit">칭찬이야기</h4>
            <ul class="praise__list">
                <li class="praise__item">
                    <a href="javascript:;" class="praise__link">
                        <span class="year">2024</span>
                        <span class="date">05.09</span>
                        <span class="name">[칭찬 직원 : 전도환 과장]</span>
                        <span class="tit">정형외과 전도환 과장님께
                            감사드립니다.정형외과 전도환 과장님께
                            감사드립니다.정형외과 전도환 과장님께
                            감사드립니다.</span>
                        <span class="desc">4월28일 81 낮병동에서 당일수술을 받은 환자의
                            자녀입니다. 우선, 의사 선생님께서는 정말 친절 ...
                        </span>
                        <div class="arrow-wrap">
                            <div class="arrow-btn"></div>
                        </div>
                    </a>
                </li>
                <li class="praise__item">
                    <a href="javascript:;" class="praise__link">
                        <span class="year">2024</span>
                        <span class="date">05.09</span>
                        <span class="name">[칭찬 직원 : 전도환 과장]</span>
                        <span class="tit">정형외과 전도환 과장님께
                            감사드립니다.</span>
                        <span class="desc">4월28일 81 낮병동에서 당일수술을 받은 환자의
                            자녀입니다. 우선, 의사 선생님께서는 정말 친절 ...
                        </span>
                        <div class="arrow-wrap">
                            <div class="arrow-btn"></div>
                        </div>
                    </a>
                </li>
                <li class="praise__item">
                    <a href="javascript:;" class="praise__link">
                        <span class="year">2024</span>
                        <span class="date">05.09</span>
                        <span class="name">[칭찬 직원 : 전도환 과장]</span>
                        <span class="tit">정형외과 전도환 과장님께
                            감사드립니다.</span>
                        <span class="desc">4월28일 81 낮병동에서 당일수술을 받은 환자의
                            자녀입니다. 우선, 의사 선생님께서는 정말 친절 ...
                        </span>
                        <div class="arrow-wrap">
                            <div class="arrow-btn"></div>
                        </div>
                    </a>
                </li>
                <li class="praise__item">
                    <a href="javascript:;" class="praise__link">
                        <span class="year">2024</span>
                        <span class="date">05.09</span>
                        <span class="name">[칭찬 직원 : 전도환 과장]</span>
                        <span class="tit">정형외과 전도환 과장님께
                            감사드립니다.</span>
                        <span class="desc">4월28일 81 낮병동에서 당일수술을 받은 환자의
                            자녀입니다. 우선, 의사 선생님께서는 정말 친절 ...
                        </span>
                        <div class="arrow-wrap">
                            <div class="arrow-btn"></div>
                        </div>
                    </a>
                </li>
                <li class="praise__item">
                    <a href="javascript:;" class="praise__link">
                        <span class="year">2024</span>
                        <span class="date">05.09</span>
                        <span class="name">[칭찬 직원 : 전도환 과장]</span>
                        <span class="tit">정형외과 전도환 과장님께
                            감사드립니다.</span>
                        <span class="desc">4월28일 81 낮병동에서 당일수술을 받은 환자의
                            자녀입니다. 우선, 의사 선생님께서는 정말 친절 ...
                        </span>
                        <div class="arrow-wrap">
                            <div class="arrow-btn"></div>
                        </div>
                    </a>
                </li>
            </ul>

            <div class="paging">
                <a href="javascript:;" class="board__pagination-btn first">처음</a>
                <a href="javascript:;" class="board__pagination-btn prev">이전</a>
                <a href="javascript:;" class="board__pagination-btn num active"><span>1</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>2</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>3</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>4</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>5</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>6</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>7</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>8</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>9</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>10</span></a>
                <a href="javascript:;" class="board__pagination-btn next">다음</a>
                <a href="javascript:;" class="board__pagination-btn last">마지막</a>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
            };
        },
        methods: {

        },
        mounted() {
        },
    };
</script>