<template>
  <div class="contents healthCenter_intro cooperation_intro">
    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">진료절차</h4>
        <div class="depth__wrap depth02"></div>
      </div>
    </div>
    <ul class="step-textbox consignment-step type03">
      <li class="step-textbox__item">
        <div class="step-textbox__item-wrap">
          <div class="step-textbox__title">
            <h3 class="depth02__title">진료의뢰서 작성 진료예약</h3>
          </div>
          <div class="step-textbox__con">
            <div class="depth__wrap">
              <div class="depth__bullet">
                <span class="bullet03"></span>
              </div>
              <div class="depth__text">
                <p>인터넷 · 전화 · FAX</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="step-textbox__item">
        <div class="step-textbox__item-wrap">
          <div class="step-textbox__title">
            <h3 class="depth02__title">진료협력센터 방문</h3>
          </div>
        </div>
      </li>
      <li class="step-textbox__item">
        <div class="step-textbox__item-wrap">
          <div class="step-textbox__title">
            <h3 class="depth02__title">해당과 진료</h3>
          </div>
        </div>
      </li>
      <li class="step-textbox__item">
        <div class="step-textbox__item-wrap">
          <div class="step-textbox__title">
            <h3 class="depth02__title">결과 회신</h3>
          </div>
          <div class="step-textbox__con">
            <div class="depth__wrap">
              <div class="depth__bullet">
                <span class="bullet03"></span>
              </div>
              <div class="depth__text">
                <p>인터넷 · 전화 · FAX</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="step-textbox__item">
        <div class="step-textbox__item-wrap">
          <div class="step-textbox__title">
            <h3 class="depth02__title">전원,되의뢰</h3>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="contents">
    <a href="javascript:;" class="cooperation_intro__button">
      <span>진료의뢰신청서 양식 다운로드</span>
    </a>
  </div>
</template>

<script>
export default {};
</script>