<template>
    <div class="contents layout">
        <div class="layout__inner">
            <div class="layout__floor">
                <ul class="layout__floor-list">
                    <li class="layout__floor-item active"><button type="button">지하1층</button></li>
                    <li class="layout__floor-item"><button type="button">1층</button></li>
                    <li class="layout__floor-item"><button type="button">2층</button></li>
                    <li class="layout__floor-item"><button type="button">3층</button></li>
                    <li class="layout__floor-item"><button type="button">4층</button></li>
                    <li class="layout__floor-item"><button type="button">5층</button></li>
                    <li class="layout__floor-item"><button type="button">6층</button></li>
                    <li class="layout__floor-item"><button type="button">7층</button></li>
                    <li class="layout__floor-item"><button type="button">8층</button></li>
                    <li class="layout__floor-item"><button type="button">9층</button></li>
                </ul>
            </div>
            <div class="layout__store">
                <ul class="layout__store-list">
                    <li class="layout__store-item active"><button type="button"><span>1</span>직업환경의학센터</button></li>
                    <li class="layout__store-item"><button type="button"><span>2</span>직업환경의학센터2</button></li>
                    <li class="layout__store-item"><button type="button"><span>3</span>PET-CT 동위원소실</button></li>
                    <li class="layout__store-item"><button type="button"><span>4</span>베이커리</button></li>
                    <li class="layout__store-item"><button type="button"><span>5</span>주차장</button></li>
                    <li class="layout__store-item"><button type="button"><span>6</span>푸드코트</button></li>
                    <li class="layout__store-item"><button type="button"><span>7</span>편의점</button></li>
                    <li class="layout__store-item"><button type="button"><span>8</span>사회복지과</button></li>
                    <li class="layout__store-item"><button type="button"><span>9</span>영양과</button></li>
                    <li class="layout__store-item"><button type="button"><span>10</span>자체관리과</button></li>
                </ul>
            </div>
            <div class="layout__map">
                <h3 class="tit"><span class="num">4층</span><span class="circle"></span></h3>
                <div class="pin" style="top: 190px; left: 385px;"><img src="@/assets/resource/icon/pin.png" alt="이미지"></div>
                <img src="@/assets/resource/img/test-map.png" alt="이미지">
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>