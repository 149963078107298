<template>
    <div class="board-view">
        <div class="board-view__top">
            <ul class="board-view__share-list" :class="{active : this.shareList}">
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.clip"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('band')"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('twitter')"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('facebook')"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
            </ul>
            <button type="button" class="board-view__btn share" @click="shareFn()"></button>
            <button type="button" class="board-view__btn print" @click="print()"></button>
        </div>
        <div class="board-view__wrap">
            <div class="board-view__tit">
                <h3>{{ articleInfo.title }}</h3>
                <div class="board-view__info">
                    <span class="date">{{ $commonUtils.timestampToDate(articleInfo.createdDt) }}</span>
                    <div class="view">
                        <span class="icon"></span>
                        {{ articleInfo.readCnt }}
                    </div>
                </div>
            </div>
            <div class="board-view__file" v-if="JSON.stringify(articleInfo.attachBag) !== '{}'">
                <div class="tit">첨부파일</div>
                <ul class="file-list">
                    <li class="file-item" v-for="(i, idx) in attachList" :key="idx">
                        <button type="button" @click="this.$commonUtils.fileDownload(i.attachNo, i.displayName)">
                            <span class="icon"></span><em class="num">{{ idx + 1 }} .</em> {{ i.displayName }} 
                        </button>
                    </li>
                </ul>
            </div>
            <!-- 컨텐츠 내용 -->
            <div class="board-view__cont">
                <iframe style="width:100%; height:760px" :src="`https://www.youtube.com/embed/${videoId}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen v-if="boardId == 'healthvideo' && articleInfo.linkUrl != null"></iframe>

                <div v-html="articleInfo.content"></div>
            </div>

            <div class="board-view__page">
                <div :class="`board-view__move next ${articleInfo.nextArticle ? 'on' : ''}`">
                    <div class="tit">
                        <div class="arrow-btn"></div>
                        다음 글
                    </div>
                    <a href="javascript:;" @click="goArticle(articleInfo.nextArticle)" v-if="articleInfo.nextArticle">
                        {{ $commonUtils.getTitle(articleInfo.nextArticle) }}
                    </a>
                    <a href="javascript:;" v-else>다음 글이 없습니다.</a>
                </div>
                <!-- 이전글이 있을경우  on추가 (다음글도 마찬가지) -->
                <div :class="`board-view__move prev ${articleInfo.prevArticle ? 'on' : ''}`">
                    <div class="tit">
                        <div class="arrow-btn"></div>
                        이전 글
                    </div>
                    <a href="javascript:;" @click="goArticle(articleInfo.prevArticle)" v-if="articleInfo.prevArticle">
                        {{ $commonUtils.getTitle(articleInfo.prevArticle) }}
                    </a>
                    <a href="javascript:;" v-else>다음 글이 없습니다.</a>
                </div>
            </div>

            <div class="contents btn-wrap center">
                <router-link :to="$route.path.replace('/view.do', '/list.do')" class="btn gray">목록</router-link>
            </div>
        </div>

    </div>

</template>


<script>
export default {
    data() {
        return {
            shareList: false,
            boardNo: this.$route.query.boardNo | '',
            articleNo: this.$route.query.articleNo | '',
            articleInfo: '',
            attachList: '',
            boardId: this.$route.params.boardId,
            videoId: '',
        };
    },
    watch: {
        $route(to, from){
            for(let i in this.$route.query){
                this[i] = this.$route.query[i]
            }
            this.getView()
        }
    },
    methods: {
        shareFn() {
            this.shareList = !this.shareList
        },
        
        getView() {
            this.axios
                .get(this.api.article.view + this.boardNo + '/' + this.articleNo, {
                    params: {
                        instNo: 1,
                        articleNo: this.articleNo,
                        boardNo: this.boardNo
                    }
                })
                .then((res) => {
                    this.articleInfo = res.data
                    if(this.boardId == 'healthvideo'){
                        this.videoId = this.articleInfo.linkUrl.split('v=')[1]
                    }
                    this.attachList = res.data.attachBag.attach;
                });
        },
        print(){
            window.print()
        },
        goArticle(article){
            // this.$route.query.articleNo = article.articleNo
            // this.$router.push({path: this.$route.path, query: this.$route.query})
            this.$router.push({path: this.$route.path, query: {boardNo: this.boardNo, articleNo: article.articleNo}})
        }
    },
    mounted() {
        this.getView()
    },
};
</script>