<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item" v-for="(year, index) in historyData" :class="activeIndex === index ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="changeYear(index)">{{ index }}</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="contents history">
        <div class="history__bg">Fatima History</div>
        <div class="history__top">
            <h2 class="tit">{{this.activeIndex}}</h2>
            <span class="line"></span>
            <span class="text">History</span>
        </div>

        <div class="history__cont">
            <div class="year__box">
                <ul class="year__list">
                    <li class="year__item" :class="{ 'active': year === currentYear }" v-for="(year, index) in this.sortedPrintList(printList)" :data-year="year">
                        <a href="javascript:;">{{ year }} </a>
                    </li>
                </ul>
            </div>

            <div class="history__wrap">
                <div class="history__line">
                    <div class="bar"></div>
                </div>
                <ul class="history__list">
                    <template v-for="(item, index) in this.historyFn(this.activeIndex)">
                        <!-- {{itemList.tit}} -->
                        <!-- <li class="history__item" :data-value="item.year" data-aos="fade-up" data-aos-offset="400" data-aos-duration="1000">
                            <div class="img" v-if="item.img"><img :src="require(`@/assets/resource/img/${item.img}`)" class="history__img" alt="이미지"></div>
                            <div class="text">
                                <div class="date">{{item.year +'.'+ item.month +'.'+ item.day}}</div>
                                <div class="tit">
                                    <p>{{item.tit}}</p>
                                    <p>{{item.tit2}}</p>
                                    <p>{{item.tit3}}</p>
                                    <p>{{item.tit4}}</p>
                                    <p>{{item.tit5}}</p>
                                </div>
                                <input type="hidden" class="current_year" :value="item.year" />
                            </div>
                        </li> -->
                        <li class="history__item" :data-value="item.year" v-bind:data-aos="index !== 0 ? 'fade-up' : null" v-bind:data-aos-offset="index !== 0 ? '400' : null" v-bind:data-aos-duration="index !== 0 ? '1000' : null">
                            <div class="img" v-if="item.img"><img :src="require(`@/assets/resource/img/${item.img}`)" class="history__img" alt="이미지"></div>
                            <div class="text">
                                <div class="date">{{item.year +'.'+ item.month +'.'+ item.day}}</div>
                                <div class="tit">
                                    <p>{{item.tit}}</p>
                                    <p>{{item.tit2}}</p>
                                    <p>{{item.tit3}}</p>
                                    <p>{{item.tit4}}</p>
                                    <p>{{item.tit5}}</p>
                                </div>
                                <input type="hidden" class="current_year" :value="item.year" />
                            </div>
                        </li>
                    </template>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    // import { gsap, ScrollTrigger } from "gsap/all";

    export default {
        data() {
            return {
                historyIndex: 0,
                activeIndex: '2020년~현재',
                historyData:
                {
                    '2020년~현재': {

                        "2024": [
                            { tit: '처음 오신 분 전용 창구 개설', year: '2024', month: '03', day: '' },
                            { tit: '영양과 조리장 리모델링', year: '2024', month: '01', img: 'history_2024.01-1.jpg', day: '' },
                            { tit: '창원시 2023년도 사회공헌 이웃돕기 유공 단체 선정', year: '2024', month: '01', img: 'history_2024.01-2.jpg', day: '' },
                        ],
                        "2023": [
                            { tit: '전자의무기록 시스템 인증 획득', year: '2023', month: '12', day: '' },
                            { tit: '4C병동 신설', year: '2023', month: '11', img: 'history_2023_11_9.jpg', day: '' },
                            { tit: 'Fatima History Wall 제막식', year: '2023', month: '09', img: 'history_2023_09.jpg', day: '' },
                            { tit: '국가보훈부 2023 우수보훈위탁병원 선정', year: '2023', month: '09', day: '' },
                            { tit: '3.0T(테슬라)S MRI 도입', year: '2023', month: '08', img: 'history_2023_08.jpg', day: '' },
                            { tit: 'Daycare센터 확장', tit2: '심혈관 중재시술 인증기관 지정 (대한심혈관중재학회)', year: '2023', month: '07', day: '' },
                            { tit: '차세대 통합의료정보시스템 F-AEGIS 도입', tit2: '응급의료센터 확장 및 리모델링', tit3: '환자중심 의료문화 확산을 위한 환자경험리더 ‘심플러스’ 발대', year: '2023', month: '06', day: '' },
                            { tit: '창원지역최초 보건복지부 1주기 기관생명윤리위원회 평가 인증 획득', year: '2023', month: '04', day: '' },
                            { tit: '혈관조영실 확장, 혈관조영장비 도입', tit2: '3D 스펙트럴 CT 도입', year: '2023', month: '02', day: '' },
                            { tit: '대한신경중재치료의학회 ‘신경중재치료 인증병원’ 지정', year: '2023', month: '01', img: 'history_2023_01.jpg', day: '' },
                            { tit: '국토안전관리원 ‘지진안전 시설물’ 내진 특등급 인증', year: '2023', month: '01', day: '' },
                        ],
                        "2022": [
                            { tit: '응급의료기관평가 A등급', year: '2022', month: '12', day: '' },
                            { tit: '대한진단검사의학회, 진단검사의학재단 ‘우수검사실 신임 인증', tit2: '데이케어센터 개설 및 통증클리닉 확장 이전', year: '2022', month: '11', day: '' },
                            { tit: '병리과 이전', year: '2022', month: '10', day: '' },
                            { tit: '개원 53주년 기념식 및 인재상 선포식', tit2: '대한소화기내시경연구재단 ‘우수내시경실 인증’', year: '2022', month: '09', img: 'history_2022_09.jpg', day: '' },
                            { tit: 'COVID-19 호흡기환자 진료센터 운영', tit2: '중앙공급실 확장 이전', year: '2022', month: '08', day: '' },
                            { tit: '레지오팀 ‘우리 즐거움의 원천’ 쁘레시디움 2,000차(40년) 기념주회', tit2: '뇌졸중집중치료실 확장 및 1인실 증설', tit4: 'EMR 착수보고회', year: '2022', month: '06', day: '' },
                            { tit: '이념 wall 제막식', year: '2022', month: '06', img: 'history_2022_06.jpg', day: '' },
                            { tit: '당뇨·인공췌장클리닉 개설', year: '2022', month: '05', img: 'history_2022_05.jpg', day: '' },
                            { tit: '초음파센터 확장 이전', year: '2022', month: '04', img: 'history_2022_04.jpg', day: '' },
                            { tit2: 'COVID-19 확진자 외래단기진료센터 개설', tit3: '대한뇌졸중학회 ‘재관류치료 뇌졸중센터 인증’ 획득', tit4: 'COVID-19 후유증 클리닉 개설', tit5: '안전경영 선포식 및 EMR추진위원회 발대식', year: '2022', month: '04', day: '' },
                            { tit: 'AI바우처 지원사업 선정, 4가지 인공지능 솔루션 도입', year: '2022', month: '03', day: '' },
                            { tit: '안전보건팀 신설', tit2: '건강검진전용 CT도입', year: '2022', month: '01', day: '' },
                        ],
                        "2021": [
                            { tit: '비만대사통합진료 시행', year: '2021', month: '12', day: '' },
                            { tit: '핵심가치선포식, 마스코트 및 파티마송 발표', year: '2021', month: '11', img: 'history_2021_11.jpg', day: '' },
                            { tit: 'COVID-19 감염병관리기관 지정 및 중등증환자 전담치료병동 개설', year: '2021', month: '10', day: '' },
                            { tit: '결핵환자 재택의료 시범사업 참여', year: '2021', month: '09', day: '' },
                            { tit: '외래 증축 준공 축복식', year: '2021', month: '06', img: 'history_2021_06.jpg', day: '' },
                            { tit: '소방공무원 정신건강진료비 지원기관 지정', tit2: '창원시 아동학대 전담의료기관 지정', tit3: 'COVID-19 예방접종 위탁의료기관 지정', tit4: 'COVID-19 후유증 클리닉 개설', tit5: '안전경영 선포식 및 EMR추진위원회 발대식', year: '2021', month: '03', day: '' },
                            { tit: '3주기 의료기관인증 획득', tit2: '파티마 성모상 제막식 및 치유자 예수상 축복식', tit3: '창원교육지원청 ‘아이좋아 희망드림센터’ 의료기관 지정', tit4: 'COVID-19 후유증 클리닉 개설', tit5: '안전경영 선포식 및 EMR추진위원회 발대식', year: '2021', month: '03', day: '' },
                        ],
                        "2020": [
                            { tit: '경상남도 교육청 교원 치유지원 심리치료기관 지정', year: '2020', month: '11', img: 'history_2020_11.jpg', day: '' },
                            { tit: '제9대 의무원장 구본원 취임', year: '2020', month: '09', day: '' },
                            { tit: '카카오 알림톡 서비스 제공', year: '2020', month: '08', day: '' },
                            { tit: 'COVID-19 감염증 국민안심병원 운영', year: '2020', month: '03', day: '' },
                            { tit: '신포괄수가제 시범기관 지정', tit2: 'COVID-19 감염증 선별진료소 운영', year: '2020', month: '01', day: '' },
                        ],
                    },
                    '2010년 ~ 2019년': {
                        "2019": [
                            { tit: '비만대사수술 클리닉 개설', year: '2019', month: '10', img: 'history_2019_10.jpg', day: '' },
                            { tit: '개원 50주년 기념 및 비전선포식', year: '2019', month: '09', day: '' },
                            { tit: '최첨단 자동화 진단검사 시스템 구축', year: '2019', month: '06', img: 'history_2019_06.jpg', day: '' },
                            { tit: '남녀고용평등 우수기업 표창', year: '2019', month: '05', img: 'history_2019_05.jpg', day: '' },
                            { tit: '증축(외래)공사 착공식', year: '2019', month: '03', img: 'history_2019_03.jpg', day: '' },
                            { tit: '뇌졸중센터 인증', year: '2019', month: '01', day: '' },
                        ],
                        "2018": [
                            { tit: '제9대 병원장 박정순(도밍가)수녀 취임', year: '2018', month: '08', day: '' },
                            { tit: '심장재활치료실 open', year: '2018', month: '05', img: 'history_2018_05.jpg', day: '' },
                            { tit: '의료기관 개설허가 변경 (총 22개 진료과목, 입원실 132실, 442병상)', year: '2018', month: '04', day: '' },
                        ],
                        "2017": [
                            { tit: '창원파티마병원 공식 SNS(블로그, 페이스북) open', year: '2017', month: '09', day: '' },
                        ],
                        "2016": [
                            { tit: '간호간병통합서비스병동 open', year: '2016', month: '12', img: 'history_2016_12.jpg', day: '' },
                            { tit: '의료기관 개설허가 변경(총 22개 진료과목, 입원실 132실, 447병상)', year: '2016', month: '10', day: '' },
                            { tit: '제8대 의무원장 조성래 박사 취임', year: '2016', month: '09', day: '' },
                            { tit: '2주기 의료기관평가인증 획득 (보건복지부)', year: '2016', month: '06', day: '' },
                        ],
                        "2015": [
                            { tit: '조직기증자 등록기관 지정(보건복지부)', year: '2015', month: '04', day: '' },
                            { tit: '장기이식센터 신장이식 성공', year: '2015', month: '01', img: 'history_2015_01.jpg', day: '' },
                        ],
                        "2014": [
                            { tit: '제7대 의무원장 정원조 박사 취임', year: '2014', month: '09', day: '' },
                            { tit: '의료기관 개설허가 변경(총 22개 진료과목, 입원실 135실, 474병상)', year: '2014', month: '06', day: '' },
                            { tit: '장기이식센터 개설', year: '2014', month: '04', day: '' },
                        ],
                        "2013": [
                            { tit: '비산업 부분 온실가스감축 우수기관표창(환경부)', year: '2013', month: '11', day: '' },
                            { tit: '의료기관 개설허가 변경(총 22개 진료과목, 입원실 136실, 480병상)', year: '2013', month: '10', day: '' },
                            { tit: '의료기관 개설허가 변경(총 22개 진료과목, 입원실 136실, 479병상)', year: '2013', month: '09', day: '' },
                            { tit: '비전선포식(고객에게 가장 사랑받는 창원대표병원)', year: '2013', month: '09', img: 'history_2013_09.jpg', day: '' },
                            { tit: '통합컨택센터 Open', year: '2013', month: '03', day: '' },
                        ],
                        "2012": [
                            { tit: '비산업 부분 온실가스감축 우수기관표창(한국환경산업기술원)', year: '2012', month: '12', img: 'history_2012_12.png', day: '' },
                            { tit: '제8대 병원장 박정애(비안네)수녀 취임', year: '2012', month: '12', day: '' },
                            { tit: '뇌졸중 전문 치료실 인증', year: '2012', month: '09', day: '' },
                            { tit: '장례식장 서비스 KS인증 획득', year: '2012', month: '08', day: '' },
                            { tit: '심폐소생술 교육기관 인증(대한심폐소생협회)', year: '2012', month: '05', day: '' },
                            { tit: '파티마어린이집 및 기숙사 개원', year: '2012', month: '03', img: 'history_2012_03.jpg', day: '' },
                            { tit: '의료기관평가인증 획득(보건복지부)', year: '2012', month: '01', day: '' },
                        ],
                        "2011": [
                            { tit: '비산업 부분 온실가스 감축 우수기관 선정(환경부)', year: '2011', month: '11', day: '' },
                            { tit: '우수건진센터 인증(대한종합건강관리학회)', year: '2011', month: '07', day: '' },
                            { tit: '3층, 7층, 8층 증축', year: '2011', month: '05', day: '' },
                            { tit: '파티마갤러리 Open', year: '2011', month: '01', day: '' },
                        ],
                        "2010": [
                            { tit: '외국인근로자등 소외계층 의료서비스 지원 사업 시행의료기관 인증(경상남도)', year: '2010', month: '12', day: '' },
                            { tit: '창원파티마 증축 및 직장보육시설 착공공사 축복식', year: '2010', month: '10', img: 'history_2010_10.jpg', day: '' },
                            { tit: '제6대 의무원장 김준호 과장 취임', year: '2010', month: '09', day: '' },
                            { tit: '중증응급질환 특성화센터 지정', year: '2010', month: '04', day: '' },
                        ],
                    },
                    '2000년 ~ 2009년': {
                        "2009": [
                            { tit: '장기이식등록기관 지정(국립의료원)', tit2: '신종플루 거점병원 선정(질병관리본부)', tit3: '사회복지봉사활동 인증센터 지정(보건복지부, 한국사회복지협의회)', year: '2009', month: '09', day: '' },
                            { tit: '증축 (7층,8층,9층 병동 및 10층 의사연구실, 전공의 숙소)', year: '2009', month: '04', day: '' },
                            { tit: '호스피스병동 개설, 말기암환자 전문의료기관 지정(경상남도)', year: '2009', month: '02', img: 'history_2009_02.jpg', day: '' },
                            { tit2: 'PET-CT 도입 가동', tit3: '제7대 병원장 최수자(까리다드)수녀 취임', year: '2009', month: '02', day: '' },
                        ],
                        "2008": [
                            { tit: '영상EMR(Electronic Medical Record) 오픈', year: '2008', month: '06', day: '' },
                            { tit: '검사통합예약시스템 가동', year: '2008', month: '03', day: '' },
                            { tit: '창원파티마병원 증축공사 축복식', year: '2008', month: '02', img: 'history_2008_02.jpg', day: '' },
                        ],
                        "2006": [
                            { tit: '제6대 병원장 이인숙(마리루이즈)수녀 취임', year: '2006', month: '10', day: '' },
                        ],
                        "2005": [
                            { tit: '의약품 임상시험실시기관 지정(식품의약품안정청)', year: '2005', month: '05', day: '' },
                        ],
                        "2004": [
                            { tit: 'DUR(Drug Utilization Review) 도입', year: '2004', month: '06', day: '' },
                            { tit2: 'PACS(Picture Archiving & Communication System) 도입', year: '2004', month: '03', img: 'history_2004_03.jpg', day: '' },
                            { tit: '제5대 의무원장 김정원 박사 취임', year: '2004', month: '03', day: '' },
                        ],
                        "2003": [
                            { tit: '응급의학과 신설', year: '2003', month: '09', day: '' },
                            { tit: '피부과 신설', year: '2003', month: '04', day: '' },
                            { tit: 'OCS(Order Communication System) 도입', year: '2003', month: '03', day: '' },
                        ],
                        "2002": [
                            { tit: '산업의학과 신설', year: '2002', month: '03', day: '' },
                            { tit: '창원파티마병원 진료 시작', year: '2002', month: '01', day: '' },
                        ],
                        "2000": [
                            { tit: '창원파티마병원 2차 공사 착수 축복식', year: '2000', month: '08', img: 'history_2000_08.jpg', day: '' },
                            { tit: '제5대 병원장 장증태(쟌마리)수녀 취임', year: '2000', month: '02', day: '' },
                        ],
                    },
                    '1990년 ~ 1999년': {
                        "1999": [
                            { tit: '창원파티마병원 건축기금 후원회 발족', year: '1999', month: '03', img: 'history_1999_04.jpg', day: '' },
                        ],
                        "1998": [
                            { tit: '산업의학연구소 신설', year: '1998', month: '12', img: 'history_1998_12.jpg', day: '' },
                            { tit: '산업의학과 신설', year: '1998', month: '10', day: '' },
                            { tit: '제4대 의무원장 구본천 과장 취임', year: '1998', month: '03', day: '' },
                        ],
                        "1996": [
                            { tit: '창원파티마병원 기공 축복식', year: '1996', month: '02', img: 'history_1996_02.jpg', day: '' },
                        ],
                        "1994": [
                            { tit: '직원 연수원 신축(경남 창녕군 상길면)', year: '1994', month: '04', img: 'history_1994_04.jpg', day: '' },
                            { tit: '정신과, 통증치료실 신설', year: '1994', month: '03', day: '' },
                            { tit: '제4대 병원장 여귀남 벨라뎃다 수녀 취임', year: '1994', month: '02', day: '' },
                        ],
                        "1993": [
                            { tit: '창원파티마병원 신축부지 매입', year: '1993', month: '12', day: '' },
                            { tit: '인턴·레지던트 수련병원 인가', year: '1993', month: '11', day: '' },
                            { tit: '인공신장실 신설', year: '1993', month: '03', img: 'history_1993_02.jpg', day: '' },
                        ],
                        "1992": [
                            { tit: '6층 소아과병동 증축', year: '1992', month: '12', day: '' },
                        ],
                    },
                    '1980년 ~ 1989년': {
                        "1989": [
                            { tit: '직원 휴가의 집 신축 - 경남 창녕군 상길면', year: '1989', month: '02', day: '' },
                        ],
                        "1987": [
                            { tit: '물리치료실 신설, 신경외과 신설', year: '1987', month: '04', day: '' },
                        ],
                        "1986": [
                            { tit: '안과, 해부병리과 신설', year: '1986', month: '05', day: '' },
                        ],
                        "1985": [
                            { tit: '종합건강진단센터 신설', year: '1985', month: '05', img: 'history_1985_05.jpg', day: '' },
                        ],
                        "1984": [
                            { tit: '정형외과 신설', year: '1984', month: '04', day: '' },
                        ],
                        "1983": [
                            { tit: '치과 신설, 종합병원 인가', year: '1983', month: '02', img: 'history_1983_02.jpg', day: '' },
                        ],
                        "1982": [
                            { tit: '인턴 수련병원 인가', year: '1982', month: '09', img: 'history_1982_05.jpg', day: '' },
                        ],
                    },
                    '1969년 ~ 1979년': {
                        "1979": [
                            { tit: '외래 및 병실증축', year: '1979', month: '12', day: '' },
                        ],
                        "1977": [
                            { tit: '이비인후과 신설', year: '1977', month: '02', day: '' },
                        ],
                        "1976": [
                            { tit: '3층 병동 증축', year: '1976', month: '06', day: '' },
                            { tit: '제2대 의무원장 김형규 박사 취임', year: '1976', month: '03', day: '' },
                        ],
                        "1974": [
                            { tit: '제2대 병원장 여귀남(벨라뎃다)수녀 취임', year: '1974', month: '09', day: '' },
                        ],
                        "1971": [
                            { tit: '응급실, 외래 및 입원실 증축', year: '1971', month: '12', day: '' },
                        ],
                        "1970": [
                            { tit: '외과 신설', year: '1970', month: '06', day: '' },
                            { tit: '내과 신설', year: '1970', month: '03', day: '' },
                        ],
                        "1969": [
                            { tit: '마산 파티마병원 개원(총 10병상, 4개 진료과)', tit2: '초대 병원장 : 장초득(피아)수녀 취임', tit3: '초대 의무원장 : 김병일 박사 취임', year: '1969', month: '09', img: 'history_1969.jpg', day: '' },
                        ],
                    },
                },
                currentYear: '',
                sortedData: {},
                printList: [],
            };

        },
        methods: {
            setupObserver() {
                const elements = document.querySelectorAll('.history__item');

                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('active');
                        } else {
                            entry.target.classList.remove('active');
                        }
                    });
                }, {
                    threshold: 0.1 // 10%가 보일 때 클래스 추가
                });

                elements.forEach(element => {
                    observer.observe(element);
                });
            },
            resetGsap() {
                gsap.killTweensOf('.history__item'); // 모든 항목의 애니메이션을 제거합니다.
                ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // 모든 ScrollTrigger 인스턴스를 제거합니다.
                document.querySelectorAll('.history__item').forEach(item => item.classList.remove('active')); // 모든 항목에서 'active' 클래스를 제거합니다.
            },
            listGsap() {
                let boxItems = document.querySelectorAll(".history__item");
                let heightBox = Array.from(boxItems).reduce((totalHeight, el) => totalHeight + el.offsetHeight, 0);
                const bar = document.querySelector('.history__line .bar');
                const historyHeight = document.querySelector('.history').offsetHeight;
                gsap.to('.history', {
                    scrollTrigger: {
                        trigger: '.history',
                        start: "top top",
                        scrub: 3,
                        toggleClass: { targets: '.history', className: 'active' }
                    }
                });

                boxItems.forEach((item, index) => {
                    gsap.from(item, {
                        scrollTrigger: {
                            trigger: item,
                            start: "-300px center",
                            end: '-200%',
                            scrub: 3,
                            onEnter: () => {

                                this.historyIndex = index;
                                this.currentYear = item.getAttribute('data-value');
                                item.classList.add("active");
                            },
                            onEnterBack: () => {
                                this.currentYear = item.getAttribute('data-value');
                                this.historyIndex = index;
                                item.classList.remove("active");
                            },
                        },
                        // y: 200,
                        // opacity: 0p
                    });
                });
                this.initGoTop(); // 고탑 다시불러오기
            },
            // 고탑 다시불러오기
            initGoTop() {
                if (window.matchMedia("(max-width: 720px)").matches) {
                    gsap.to('.go-top', {
                        scrollTrigger: {
                            trigger: '.footer',
                            start: 'top bottom-=10px',
                            toggleClass: { targets: '.go-top', className: 'on' },
                            scrub: true
                        }
                    });
                } else {
                    gsap.to('.go-top', {
                        scrollTrigger: {
                            trigger: '.footer',
                            start: 'top bottom-=60px',
                            toggleClass: { targets: '.go-top', className: 'on' },
                            scrub: true
                        }
                    });
                }
                gsap.to('.go-top', {
                    scrollTrigger: {
                        trigger: '.container',
                        start: 'top top',
                        end: '+=100',
                        toggleClass: { targets: '.go-top', className: 'show' },
                        scrub: true
                    },
                    opacity: 1,
                    pointerEvents: 'all'
                });
            },

            // 고탑 다시불러오기


            changeYear(index) {
                this.resetGsap(); // 기존 gsap 설정 초기화
                this.printList = this.historyData[index];
                this.activeIndex = index;
                this.listGsap(); // 새로운 데이터에 대해 gsap 설정

            },
            historyFn(key) {
                const events = this.historyData[key];

                // 연도와 월 기준으로 정렬합니다.
                const sortedKeys = Object.keys(events).sort((a, b) => {
                    // 연도 비교
                    const yearComparison = b - a;
                    if (yearComparison !== 0) {
                        return yearComparison; // 연도 차이로 정렬
                    }

                    // 연도가 동일할 경우 월 비교
                    const aMonth = parseInt(events[a][0].month, 10);
                    const bMonth = parseInt(events[b][0].month, 10);

                    return bMonth - aMonth;  // 월 차이로 정렬
                });

                // 정렬된 연도와 월에 해당하는 배열을 생성합니다.
                const sortedEvents = sortedKeys.flatMap(key => events[key]);

                // 결과를 출력합니다.
                return sortedEvents;
            },
            sortedPrintList(list) {
                const sortedKeys = Object.keys(list).sort((a, b) => { return b - a });
                return sortedKeys;
            },
            scrollFunc() {
                let documentHeight = document.documentElement.scrollHeight;
                let scrollTop = window.scrollY || document.documentElement.scrollTop;
                let windowHeight = window.innerHeight;

                let scrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;

                document.querySelector('.history__line .bar').style.height = scrollPercentage + '%';
            }
        },
        mounted() {
            this.printList = this.historyData[this.activeIndex];
            this.setupObserver();
            setTimeout(() => {
                this.listGsap();
            }, 1100);
            window.addEventListener('scroll', this.scrollFunc);
        },
        watch: {
            currentYear(newValue, oldValue) {
                // console.log('newValue : ' + newValue)
                // console.log('oldValue : ' + oldValue)
                this.$nextTick(() => {
                    document.querySelectorAll(".year__item").forEach(item => item.classList.remove('prev'));

                    document.querySelectorAll(".year__item").forEach(item => {
                        // console.log(parseInt(item.getAttribute('data-year')), 'sdfs')
                        if (parseInt(item.getAttribute('data-year')) == parseInt(oldValue)) {
                            item.classList.add('prev');
                        }
                    });
                });
            }
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.scrollFunc);
        }
    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>