<template>
    <MypagePopupVue ref="MypagePopupVue"></MypagePopupVue>

    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item active">
                    <button type="button" class="tab-menu__btn">혈압</button>
                </li>
                <li class="tab-menu__item">
                    <button
                        type="button"
                        class="tab-menu__btn"
                        @click="this.$router.push('/mypage/health/bloodSugar.do')"
                    >
                        혈당
                    </button>
                </li>
                <li class="tab-menu__item">
                    <button
                        type="button"
                        class="tab-menu__btn"
                        @click="this.$router.push('/mypage/health/bodyMass.do')"
                    >
                        체질량
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="date_wrap contents">
        <div class="time-input">
            <TimePicker v-model="time" format="HH:mm" minute-step="1" is24/>
            <img src="@/assets/resource/icon/time_icon.png" alt="아이콘"/>
        </div>
        <div class="date-input">
            <VueDatePicker
                v-model="date"
                locale="ko"
                :enable-time-picker="false"
                :format="customFormat"
                :week-start="0"
                :day-class="getDayClass"
                :select-text="'선택'"
                :cancel-text="'취소'"
            />
            <img src="@/assets/resource/icon/date_icon.png" alt="아이콘"/>
        </div>
    </div>

    <div class="measure_wrap">
        <ul class="measure_input_list">
            <li class="measure_input_item measure_it01">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/measure_icon01.png" alt="이미지"/>
                        <h3>수축기혈압</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="systolic" class="cont__text-input"
                               @input="validateInput('systolic', $event)"/>
                        <span>mmHg</span>
                    </div>
                </div>
                <p class="measure_txt">이완기혈압보다 큰 숫자를 입력해주세요.</p>
            </li>
            <li class="measure_input_item measure_it02">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/measure_icon02.png" alt="이미지"/>
                        <h3>이완기혈압</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="diastolic" class="cont__text-input"
                               @input="validateInput('diastolic', $event)"/>
                        <span>mmHg</span>
                    </div>
                </div>
                <p class="measure_txt">수축기혈압보다 작은 숫자를 입력해주세요.</p>
            </li>
        </ul>

        <div class="btn-wrap center contents">
            <a href="javascript:;" class="btn blue" @click="calculateResult">측정하기</a>
        </div>
    </div>

    <div class="contents" v-if="!result">
        <div class="text-box type1 info-text">
            <h3 class="depth02__title title">혈압 측정 방법</h3>
            <ul class="text-box__list">
                <li class="text-box__item">
                    혈압 측정 30분 전에는 알코올, 커피(카페인) 섭취와 운동, 목욕을 피하고
                    금연을 합니다.
                </li>
                <li class="text-box__item">
                    5분 이상 앉은 자세로 심리적 안정을 취하도록 합니다.
                </li>
                <li class="text-box__item">
                    혈압을 잴 때, 팔의 위치는 심장의 위치와 비슷해야 합니다.
                </li>
                <li class="text-box__item">
                    혈압은 특별한 경우가 아니라면, 앉아서 팔에서 측정합니다.
                </li>
                <li class="text-box__item">측정 시 말을 하거나 움직이지 않습니다.</li>
                <li class="text-box__item">
                    혈압이 정상보다 높을 경우, 2번 이상 다시 측정합니다.
                </li>
            </ul>
        </div>
    </div>

    <div class="measure_result_wrap contents" v-if="result">
        <div class="measure_result_box">
            <div class="measure_result_con">
                <img src="@/assets/resource/icon/measure_icon03.png" alt="이미지"/>
                <p>
                    현재 혈압 상태는&nbsp;<span class="blue__title">'{{ result }}'</span
                >&nbsp;입니다.
                </p>
            </div>
        </div>
    </div>

    <div class="contents" v-if="result">
        <div class="table-wrap table-type1">
            <div class="scroll">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width: 33.333%"/>
                        <col style="width: 33.333%"/>
                        <col style="width: 33.333%"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>혈압분류</th>
                        <th>수축기혈압</th>
                        <th>이완기혈압</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>저혈압</td>
                        <td>90이하</td>
                        <td>60이하</td>
                    </tr>
                    <tr>
                        <td>정상</td>
                        <td>91 ~ 119</td>
                        <td>61 ~ 79</td>
                    </tr>
                    <tr>
                        <td>전고혈압</td>
                        <td>120 ~ 139</td>
                        <td>80 ~ 89</td>
                    </tr>
                    <tr>
                        <td>1기고혈압</td>
                        <td>140 ~ 159</td>
                        <td>90 ~ 99</td>
                    </tr>
                    <tr>
                        <td>2기고혈압</td>
                        <td>160 이상</td>
                        <td>100 이상</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="btn-wrap center contents" v-if="result">
        <a href="javascript:;" class="btn" @click="saveSubmit">결과저장</a>
    </div>
</template>

<script>
import {ref} from "vue"
import TimePicker from "vue3-timepicker"
import VueDatePicker from "@vuepic/vue-datepicker"
import MypagePopupVue from '../popup/MypagePopup.vue'
import "@vuepic/vue-datepicker/dist/main.css"
import "vue3-timepicker/dist/VueTimepicker.css"

export default {
    components: {
        TimePicker,
        VueDatePicker,
        MypagePopupVue
    },
    data() {
        return {
            systolic: "",
            diastolic: "",
            result: "",
            customFormat: "yyyy.MM.dd",
            time: {HH: "", mm: ""},
            date: new Date()
        }
    },
    setup() {
    },
    methods: {
        validateInput(type, event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3)
            this[type] = input
        },
        calculateResult() {
            const systolic = parseInt(this.systolic)
            const diastolic = parseInt(this.diastolic)

            if (!this.systolic || !this.diastolic) {
                alert("데이터를 입력해 주세요")
                return
            }

            if (systolic <= diastolic) {
                alert("수치를 다시 입력해주세요.")
                return
            }

            if (systolic <= 90 ) {
                this.result = "저혈압"
            } else if (
                systolic >= 91 &&
                systolic <= 119
            ) {
                this.result = "정상"
            } else if (
                systolic >= 120 &&
                systolic <= 139
            ) {
                this.result = "전고혈압"
            } else if (
                systolic >= 140 &&
                systolic <= 159
            ) {
                this.result = "1기고혈압"
            } else if (systolic >= 160 ) {
                this.result = "2기고혈압"
            } else {
                alert("혈압 범위를 벗어났습니다. 다시 입력해주시길 바랍니다.")
                return
            }
        },
        getDayClass(date) {
            const day = date.getDay()
            if (day === 0) {
                return "sunday-class"
            } else if (day === 6) {
                return "saturday-class"
            }
            return ""
        },
        saveSubmit() {
            this.$refs.MypagePopupVue.openPopup()
        },
        submit() {
            let reqData = {
                hmCd: 'BP'
            }

            var year = this.date.getFullYear()
            var month = ('0' + (this.date.getMonth() + 1)).slice(-2)
            var day = ('0' + this.date.getDate()).slice(-2)
            var hours = this.time.HH ? this.time.HH : ('0' + this.time.getHours()).slice(-2)
            var min = this.time.mm ? this.time.mm : ('0' + this.time.getMinutes()).slice(-2)

            reqData.logDt = year + '' + month + '' + day + '' + hours + '' + min + '00'
            reqData.record1 = this.systolic
            reqData.record2 = this.diastolic
            reqData.record3 = ''
            reqData.result = this.result
            this.axios({
                method: "post",
                url: this.api.mypage.healthReg,
                params: reqData
            }).then(response => {
                alert('저장되었습니다.')

                this.systolic = ''
                this.diastolic = ''
                this.result = ''

                this.$router.push('/mypage/health/healthCare.do')
            })
        },
    },
    mounted() {
        const now = new Date()

        this.time.HH = now.getHours().toString().padStart(2, '0');
        this.time.mm = now.getMinutes().toString().padStart(2, '0');

        this.date = now

    }
}
</script>
