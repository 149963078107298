<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">혈압</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">혈당</button></li>
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">체질량</button></li>
        </ul>
    </div>

    <div class="date_wrap contents">
        <div class="time-input">
            <TimePicker v-model="time" format="HH:mm" minute-step="1" is24/>
            <img src="@/assets/resource/icon/time_icon.png" alt="아이콘">
        </div>
        <div class="date-input">
            <VueDatePicker v-model="date" locale="ko" :enable-time-picker="false" :format="customFormat"/>
            <img src="@/assets/resource/icon/date_icon.png" alt="아이콘">
        </div>
    </div>

    <div class="measure_wrap body_mass">
        <ul class="measure_input_list">
            <li class="measure_input_item measure_it01">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/gender_icon.png" alt="아이콘">
                        <h3>성별</h3>
                    </div>
                    <div class="agree-input">
                        <label for="gender-man" class="agree-input__item">
                            <input type="radio" id="gender-man" class="agree-input__radio" v-model="gender" value="남자" checked>
                            <span class="icon"></span> 남자 
                        </label>
                        <label for="gender-woman" class="agree-input__item">
                            <input type="radio" id="gender-woman" class="agree-input__radio" v-model="gender" value="여자">
                            <span class="icon"></span> 여자 
                        </label>
                    </div>
                </div>
            </li>
            <li class="measure_input_item measure_it02">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/ruler_icon.png" alt="아이콘">
                        <h3>키</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="height" @input="validateHeight" class="cont__text-input">
                        <span>cm</span>
                    </div>
                </div>
            </li>
            <li class="measure_input_item measure_it03">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/scale_icon.png" alt="아이콘">
                        <h3>몸무게</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="weight" @input="validateWeight" class="cont__text-input">
                        <span>Kg</span>
                    </div>
                </div>
            </li>
        </ul>

        <div class="btn-wrap center contents">
            <a href="javascript:;" class="btn blue" @click="calculateBMI">측정하기</a>
        </div>
    </div>

    <div class="contents" v-if="!result">
        <div class="text-box type1 info-text">
            <h3 class="depth02__title title">체질량 측정 방법</h3>
            <ul class="text-box__list">
                <li class="text-box__item">신체질량지수(BMI) = 체중(kg) / [신장(m)]²</li>
                <li class="text-box__item">판정기준 - 저체중 : 18.5 미만, 정상 : 18.5 ~ 23, 과체중 : 23 ~ 25, 비만 : 25 ~ 30, 고도비만 : 30 이상</li>
            </ul>
        </div>
    </div>

    <div class="measure_result_wrap contents body_mass_result" v-if="result">
        <div class="measure_result_box">
            <div class="measure_result_con">
                <img src="@/assets/resource/icon/measure_icon03.png" alt="이미지">
                <p>현재 신체정보는&nbsp;<span class="blue__title">{{ height }}cm &nbsp;/&nbsp;{{ weight }}Kg</span>으로<br>BMI 지수는 {{ bmi.toFixed(2) }}Kg/m²&nbsp;<span class="blue__title">'{{ result }}'</span>&nbsp;입니다.</p>
            </div>
        </div>
    </div>

    <div class="contents" v-if="result">
        <div class="table-wrap table-type1">
            <div class="scroll">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width:50%;">
                        <col style="width:50%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>판정분류</th>
                            <th>BMI 지수</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>저체중</td>
                            <td>18.5 미만</td>
                        </tr>
                        <tr>
                            <td>정상</td>
                            <td>18.5~23</td>
                        </tr>
                        <tr>
                            <td>과체중</td>
                            <td>23~25</td>
                        </tr>
                        <tr>
                            <td>비만</td>
                            <td>25~30</td>
                        </tr>
                        <tr>
                            <td>고도비만</td>
                            <td>30이상</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="btn-wrap center contents" v-if="result">
        <a href="javascript:;" class="btn">결과저장</a>
    </div>
</template>

<script>
import { ref } from 'vue';
import TimePicker from 'vue3-timepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {
    components: {
        TimePicker,
        VueDatePicker
    },
    data() {
        return {
            gender: '',
            height: '',
            weight: '',
            result: '',
            bmi: 0,
            customFormat: 'yyyy.MM.dd'
        };
    },
    setup() {
        const time = ref(null);
        const date = ref(null);
        
        return { time, date };
    },
    methods: {
        validateHeight(event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3);
            this.height = input;
        },
        validateWeight(event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3);
            this.weight = input;
        },
        calculateBMI() {
            if (!this.height || !this.weight) {
                alert("수치를 입력해 주세요");
                return;
            }
            
            const heightInMeters = this.height / 100;
            const bmi = this.weight / (heightInMeters * heightInMeters);
            this.bmi = bmi;

            if (bmi < 18.5) {
                this.result = '저체중';
            } else if (bmi >= 18.5 && bmi < 23) {
                this.result = '정상';
            } else if (bmi >= 23 && bmi < 25) {
                this.result = '과체중';
            } else if (bmi >= 25 && bmi < 30) {
                this.result = '비만';
            } else if (bmi >= 30) {
                this.result = '고도비만';
            } else {
                this.result = '잘못된 값';
            }
        }
    }
};
</script>
