<template>
   <div class="director_history medicalDirector_wrap">
        <div class="contents">
            <div class="history_swiper_wrap full-width">
                <div class="medicalDirector"><img src="@/assets/resource/img/HospitalDirector.png" alt="HospitalDirector"></div>
                <div class="swiper mySwiper inner02">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="direcor_items">
                                <img src="@/assets/resource/img/master_1.jpg" alt="병원장 장초득 피아 수녀">
                                <h3 class="blue__title">초대병원장</h3>
                                <h4>장초득 피아 수녀</h4>
                                <p>(1969.9.1 ~ 1974.9)</p>
                            </div>
                             <div class="direcor_items">
                                <img src="@/assets/resource/img/master_2.jpg" alt="병원장 여귀남 벨라뎃다 수녀">
                                <h3 class="blue__title">제2대 병원장</h3>
                                <h4>여귀남 벨라뎃다 수녀</h4>
                                <p>(1974.9.6 ~ 1980.7)</p>
                             </div>
                               <div class="direcor_items">
                                <img src="@/assets/resource/img/master_3.jpg" alt="병원장 이정순 힐데 수녀">
                                <h3 class="blue__title">제3대 병원장</h3>
                                <h4>이정순 힐데 수녀</h4>
                                <p>(1980.8.1 ~ 1994.2)</p>
                             </div>
                             <div class="direcor_items">
                                <img src="@/assets/resource/img/master_4.jpg" alt="병원장 여귀남 벨라뎃다 수녀">
                                <h3 class="blue__title">제4대 병원장</h3>
                                <h4>여귀남 벨라뎃다 수녀</h4>
                                <p>(1994.2.23 ~ 2000.2)</p>
                             </div>
                        </div>
                        <div class="swiper-slide">
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/master_5.jpg" alt="병원장 장증태 쟌마리 수녀">
                                <h3 class="blue__title">제5대 병원장</h3>
                                <h4>장증태 쟌마리 수녀</h4>
                                <p>(2000.2.16 ~ 2006.2)</p>
                             </div>
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/master_6.jpg" alt="병원장 이인숙 마리루이즈 수녀">
                                <h3 class="blue__title">제6대 병원장</h3>
                                <h4>이인숙 마리루이즈 수녀</h4>
                                <p>(2006.2.17 ~ 2009.2)</p>
                             </div>
                              <div class="direcor_items">
                                 <img src="@/assets/resource/img/master_7.jpg" alt="병원장 최수자 까리다드 수녀">
                                <h3 class="blue__title">제7대 병원장</h3>
                                <h4>최수자 까리다드 수녀</h4>
                                <p>(2009.2.26 ~ 2012.12)</p>
                             </div> 
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/master_8.jpg" alt="병원장 박정애 비안네 수녀">
                                <h3 class="blue__title">제8대 병원장</h3>
                                <h4>박정애 비안네 수녀</h4>
                                <p>(2012.12.3 ~ 2018.8.28)</p>
                             </div>
                        </div>
                        <div class="swiper-slide">
                             <div class="direcor_items">
                                 <img src="@/assets/resource/img/master_9.jpg" alt="병원장 박정순 도밍가 수녀">
                                <h3 class="blue__title">제9대 병원장</h3>
                                <h4>박정순 도밍가 수녀</h4>
                                <p>(2018.8.29 ~ 현재)</p>
                             </div>
                             
                        </div>
                    </div>
                    <div class="swiper-pagination contents"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
                
            </div>
        </div>
   </div>
</template>



<script>
export default {
    mounted() {
        let swiperMediDir;

        function initializeSwiper() {
            if (window.innerWidth > 1024) {
                swiperMediDir = new Swiper('.medicalDirector_wrap .mySwiper', {
                    speed: 400,
                    loop: true,
                    navigation: {
                        nextEl: '.medicalDirector_wrap .swiper-button-next',
                        prevEl: '.medicalDirector_wrap .swiper-button-prev'
                    },
                    pagination: {
                        el: ".medicalDirector_wrap .swiper-pagination",
                    }
                });
            } else if (swiperMediDir) {
                swiperMediDir.destroy(true, true);
                swiperMediDir = null;
            }
        }

        // Initialize Swiper on page load
        initializeSwiper();

        // Re-initialize Swiper on window resize
        window.addEventListener('resize', initializeSwiper);
    },
}
</script>