<template>
    <header class="center-header" :class="[{ 'show': isMenuActive }, { 'health' : hpUrl == 'healthcenter'}, { 'enviro' : hpUrl == 'onecenter' }, { 'coop' : hpUrl == 'medicalcenter' }]" 
        @mouseenter="activateMenu" @mouseleave="resetHeaderActive">
        <div class="header__top">
            <div class="header__inner">
                <ul class="header__top--list">
                    <li>
                        <a href="javascript:;" @click="goHome"><img src="@/assets/resource/icon/canter_home-icon.png" alt="이미지"></a>
                    </li>
                    <li>
                        <a href="javascript:;" @click="goHome">창원파티마병원</a>
                    </li>
                    <li>
                        <div class="header__top--select-wrap" @mouseenter="activeSelect" @mouseleave="resetSelect">
                            <a href="javascript:;">센터바로가기</a>
                            <slide-up-down :active="isSelectActive" class="header__top--select">
                                <div class="header__top--option"><a href="/deptHp/3/medicalcenter/main.do" target="_blank">진료협력센터</a></div>
                                <div class="header__top--option"><a href="/deptHp/2/onecenter/main.do" target="_blank">직업환경의학센터</a></div>
                                <div class="header__top--option"><a href="/deptHp/1/healthcenter/main.do" target="_blank">건강증진센터</a></div>
                            </slide-up-down>
                        </div>
                    </li>
                </ul>
                <ul class="header__top--list" v-if="!$store.state.memberStore.status">
                    <li>
                        <a href="javascript:;" @click="[goPage('/member/deptLogin.do'), navRemove()]">로그인</a>
                    </li>
                    <li>
                        <a href="/member/join.do" @click="navRemove">회원가입</a>
                    </li>
                </ul>
                <ul class="header__top--list" v-else>
                    <li>
                        <a href="javascript:;" @click="logout">로그아웃</a>
                    </li>
                    <li>
                        <a href="/mypage/mypage.do" @click="navRemove">마이페이지</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header__bottom">
            <div class="header__inner">
                <div class="header__front">
                    <h1 class="header__tit">
                        <a href="javascript:;" @click="goPage('/main.do')">
                            <img src="@/assets/resource/icon/logo-b.png" alt="이미지">
                            <span>{{this.$parent.menuInfo.hpTitle}}</span>
                        </a>
                    </h1>
                    <div class="header__menu-wrap">
                        <div class="line"></div>
                        <ul class="header__list">
                            <li class="header__item" @mouseenter="menuLine(idx + 1)" v-for="(one, idx) in $parent.oneDepth" :key="one">
                                <component :is="one.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="one.menuUrl" :target="one.linkMethod"
                                    :to="one.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${one.menuUrl}?deptClsf=A&deptCd=${convertDeptCd[deptCd]}` : `/deptHp/${deptCd}/${hpUrl}${one.menuUrl}`"
                                    @click="resetHeaderActive" class="header__link">{{one.menuName}}</component>
                            </li>
                        </ul>
                    </div>
                    <div class="header__menu">
                        <a href="javascript:;" class="header__menu-link login" v-if="!$store.state.memberStore.status" @click="[goPage('/member/deptLogin.do'), navRemove()]">로그인</a>
                        <a href="/member/join.do" class="header__menu-link join" v-if="!$store.state.memberStore.status" @click="navRemove">회원가입</a>
                        <a href="javascript:;" class="header__menu-link join" v-if="$store.state.memberStore.status" @click="logout">로그아웃</a>
                        <a href="/mypage/mypage.do" class="header__menu-link join" v-if="$store.state.memberStore.status" @click="navRemove">마이페이지</a>
                        <a href="javascript:;" class="header__menu-link" title="전체메뉴 보기" @click="navClick()">
                            <span class="bar"></span>
                            <span class="bar"></span>
                            <span class="bar"></span>
                        </a>
                    </div>
                    <slide-up-down class="header__cont-box" :active="isMenuActive">
                        <div class="header__inner">
                            <div class="header__cont-left" v-if="hpUrl == 'onecenter'">
                                <h3 class="header__cont-left__title">전화예약안내</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        <img src="@/assets/resource/icon/header__cont-left__number01.png"  alt="이미지">
                                        <a href="tel:0552701051"> TEL : 055)270-1051~3</a> 
                                    </li>
                                </ul>
                                <P class="header__cont-left__number--sub-text">평일 09:00~17:00 <br> 토요일 09:00~12:00</P>
                                <div class="header__cont-left__bottom">
                                    <a href="/deptHp/2/onecenter/cancerReservation.do">국가암검진 예약</a>
                                    <a href="https://www.nhis.or.kr/nhis/etc/personalLoginPage.do" title="국민건강보험 페이지로 새 창 이동" target="_blank" class="nhis_alert" @click="goNhis()">건강검진 결과조회</a>
                                </div>
                                <br>
                                <br>
                                <span></span>
                            </div>
                            <div class="header__cont-left" v-if="hpUrl == 'healthcenter' ">
                                <h3 class="header__cont-left__title">전화예약안내</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        <img src="@/assets/resource/icon/header__cont-left__number01.png"  alt="이미지">
                                        <a href="tel:0552701200"> TEL : 055)270-1200~1</a> 
                                    </li>
                                </ul>
                                <P class="header__cont-left__number--sub-text">(평일 09:00~16:40/토요일 09:00~12:00)</P>
                                <div class="header__cont-left__bottom">
                                    <a href="/deptHp/1/healthcenter/PersonalReservationGuide.do" class="blue_box">검진예약</a>
                                    <a href="/deptHp/1/healthcenter/checkUpResult.do" class="mint_box">종합검진결과 조회</a>
                                </div>
                                <br>
                                <br>
                                <span></span>
                            </div>
                            <div class="header__cont-left"  v-if="hpUrl == 'medicalcenter' ">
                                <h3 class="header__cont-left__title">이용안내</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        <img src="@/assets/resource/icon/header__cont-left__number01.png"  alt="이미지">
                                        <a href="tel:0552701108"> TEL : 055)270-1108</a> 
                                    </li>
                                    <li>
                                        <img src="@/assets/resource/icon/header__cont-left__number02.png"  alt="이미지">
                                        FAX : 055)270-1029
                                    </li>
                                </ul>
                                <P class="header__cont-left__number--sub-text">(평일 09:00~17:00/토요일 09:00~12:00)</P>
                                <h3 class="header__cont-left__emphasis-title">야간 및 응급</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        055)270-1119,1120
                                    </li>
                                </ul>
                                <div class="header__cont-left__bottom">

                                </div>
                            </div>
                            <ul class="header__cont-list">
                                <li class="header__cont-item" @mouseenter="menuLine(idx + 1)" v-for="(one, idx) in $parent.oneDepth" :key="one">
                                    <template v-for="(two, index) in $parent.menuList" :key="two">
                                        <h2 class="header__cont-tit" :class="two.childCnt > 0 ? 'header__sub-list-wrap' : ''" v-if="two.gnbYn == 'Y' && two.depth == 2 && one.menuNo == two.parentMenuNo">
                                            <a href="javascript:;" class="header__deps1-link" @click="goRes(two)" v-if="two.menuNo == '58'">
                                                {{two.menuName}}<div v-if="two.linkMethod === '_blank'" class="newWidow"></div>
                                            </a>
                                            <a href="javascript:;" class="header__deps1-link" @click="waiting" v-else-if="two.menuNo == '13'">
                                                {{two.menuName}}<div v-if="two.linkMethod === '_blank'" class="newWidow"></div>
                                            </a>
                                            <component :is="two.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="two.menuUrl" :target="two.linkMethod"
                                                :to="two.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${two.menuUrl}?deptClsf=A&deptCd=${convertDeptCd[deptCd]}` : `/deptHp/${deptCd}/${hpUrl}${two.menuUrl}`"
                                                class="header__deps1-link" v-else>
                                                {{two.menuName}}<div v-if="two.linkMethod === '_blank'" class="newWidow"></div>
                                            </component>
                                            <ul class="header__sub-list" v-if="two.childCnt > 0">
                                                <template v-for="(three, index) in $parent.menuList" :key="three">
                                                    <li v-if="two.menuNo == three.parentMenuNo">
                                                        <component :is="three.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="three.menuUrl"  :target="three.linkMethod"
                                                            :to="three.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${three.menuUrl}?deptClsf=A&deptCd=${convertDeptCd[deptCd]}` : `/deptHp/${deptCd}/${hpUrl}${three.menuUrl}`"
                                                            class="header__sub-list--link">{{three.menuName}}<div v-if="three.linkMethod === '_blank'" class="newWidow"></div></component>
                                                    </li>
                                                </template>
                                            </ul>
                                        </h2>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </slide-up-down>

                </div>
            </div>
        </div>
        
        <div class="site-map">
            <ul class="site-map__list">
                <template v-for="(item, index) in $parent.oneDepth" :key="index">
                    <li class="site-map__item" v-if="item.depth == 1 && item.gnbYn == 'Y'">
                        <h2>{{item.menuName}}</h2>
                        <ul class="deps2__list">
                            <template v-for="(el, index) in $parent.menuList" :key="index">
                                <template v-if="el.depth == 2 && el.gnbYn=='Y' && el.parentMenuNo == item.menuNo">
                                    <li class="deps2__item">
                                        <!-- <component :is="el.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="el.menuUrl" class="deps2__link" :to="el.menuUrl" @click="resetHeaderActive">{{el.menuName}}</component> -->
                                        <component :is="el.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="el.menuUrl" :target="el.linkMethod"
                                            :to="el.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${el.menuUrl}?deptClsf=A&deptCd=${convertDeptCd[deptCd]}` : `/deptHp/${deptCd}/${hpUrl}${el.menuUrl}`"
                                            class="deps2__link" @click="[navRemove(), resetHeaderActive()]">{{el.menuName}}</component>
                                        <ul class="deps3__list">
                                            <template v-for="(data, index) in $parent.menuList" :key="index">
                                                <template v-if="data.depth == 3 && data.gnbYn=='Y' && el.menuNo == data.parentMenuNo">
                                                    <li class="deps3__item">
                                                        <!-- <component :is="data.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="data.menuUrl" :to="data.menuUrl" @click="resetHeaderActive">
                                                            <span class="name">{{data.menuName}}</span>
                                                        </component> -->
                                                        <component :is="data.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="data.menuUrl" :target="data.linkMethod"
                                                            :to="data.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${data.menuUrl}?deptClsf=A&deptCd=${convertDeptCd[deptCd]}` : `/deptHp/${deptCd}/${hpUrl}${data.menuUrl}`" @click="[navRemove(), resetHeaderActive()]">
                                                            <span class="name">{{data.menuName}}</span>
                                                        </component>
                                                    </li>
                                                </template>
                                            </template>
                                        </ul>
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </li>
                </template>
            </ul>
        </div>
    </header>
</template>

<script>
    import SlideUpDown from 'vue-slide-up-down';

    export default {
        components: {
            SlideUpDown,
        },
        data() {
            return {
                isMenuActive: false,
                isSelectActive: false,

                depthNum: '',
                activeMenu: {},
                deptCd: this.$route.params.deptCd,
                hpUrl: this.$route.params.hpUrl,

                convertDeptCd: {
                    '1' : 'HM',
                    '2' : 'OM'
                }
            };
        },
        methods: {
            waiting(){
                alert('준비 중 입니다.')
            },
            goRes(item){
                alert('국가건강검진 결과조회를 위해 국민건강보험공단 사이트로 이동합니다.');
                window.open(item.menuUrl)
            },
            goNhis(){
                alert('국가건강검진 결과조회를 위해 국민건강보험공단 사이트로 이동합니다.');
                window.location.href = 'https://www.nhis.or.kr/nhis/etc/personalLoginPage.do';
            },
            navClick() {
                document.querySelector('.site-map').classList.toggle('is-open');
                document.querySelector('body').classList.toggle('is-open');
            },
            navRemove() {
                document.querySelector('.site-map').classList.remove('is-open');
                document.querySelector('body').classList.remove('is-open');
            },
            activateMenu() {
                this.isMenuActive = true;
            },
            resetHeaderActive() {
                this.isMenuActive = false;
            },
            activeSelect() {
                this.isSelectActive = true;
            },
            resetSelect() {
                this.isSelectActive = false;
            },
            menuLine(item) {
                if(this.$parent.oneDepth){
                    const count = this.$parent.oneDepth.length

                    const style = (100 / count) * item

                    let headerLine = document.querySelector(".header__menu-wrap .line")
                    headerLine.style.left = style + '%'
                }
            },
            logout() {
                this.$store.commit('myInfoInit')
                this.axios
                    .get(this.api.member.logout)
                    .then((res) => {
                        // console.log(res)
                    })
                    .catch(err => {
                        console.log(err);
                    });
                this.$store.commit("setStatus", false);
                this.$store.commit('getShowMypageNameCheckModal', true);
                location.reload();
            },
            goPage(str){
                this.$router.push(`/deptHp/${this.deptCd}/${this.hpUrl}${str}`)
            },
            goHome(){
                this.$store.commit('setCprsvInfo', '')
                this.$store.commit('setCprsv', '')
                this.$store.commit('init')
                this.$store.commit('setReserveInfo', '')
                this.$store.commit('setReserve', '')
                location.href = '/'
            }
        },
        mounted() {
            // v-for="(el, index) in $parent.menuList" :key="index"
        },
        beforeUnmount() {
        }
    };
</script>