<template>
    <!-- 진단검사의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>진단검사의학과는 인체로부터 채취되는 혈액, 골수, 소변, 대변, 기타 체액 등 다양한 검체에 존재하는 물질들을 과학적 방법으로 측정, 분석, 판독하는 검사 행위를 수행함으로써 질병의 조기 발견, 진단, 경과 관찰, 치료 및 예후 판정에 기여하는 과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>진단혈액, 임상화학, 진단면역, 임상미생물, 수혈의학, 분자진단, 진단검사의학일반</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>