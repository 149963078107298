import store from "@/store/store";
import router from ".";
import axios from 'axios';
import api from "@/api/api";

// 로그인 사용자 밀어내기
function isLoginCheck(next, text) {
    axios.get(api.member.info).then(response => {
        if (response.data) {
            alert(`로그인이 되어있습니다.\n${text}을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
            router.push('/');
        } else {
            return next();
        }
    });
}

// 비로그인 사용자 밀어내기
function isNotLoginCheck(to, from, next) {
    axios.get(api.member.info).then(response => {
        if (response.data) {
            store.commit('setInfo', response.data)
            return next();
        } else {
            alert('로그인 후 이용이 가능합니다.');
            // store.commit("getRedirectUrl", to.href);
            store.commit("setStatus", false);
            router.push('/member/login.do')
        }
    });
}


export {isLoginCheck, isNotLoginCheck};