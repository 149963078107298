<template>
    <Header></Header>
    <LocationBar></LocationBar>
    <div class="container">
        <div class="inner">
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
    import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Footer from '@/components/common/Footer.vue';

    export default {
        components: {
            Header,
            LocationBar,
            Footer
        },
        mounted() {
            console.log(123)
        },
    }
</script>
<style>
    @import url('../../assets/resource/css/sub.css');
    /* @import url('../src/assets/resource/js/mainGsap.js'); */
</style>