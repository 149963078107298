<template>
    <Search @setState="search"></Search>
    <board-card ref="boardCard" @articleView="articleView" @getContents="getContents"></board-card>
    <pagination :totCnt="totCnt" :pageRow="params.pageRow" :currentNum="currentNum" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
</template>

<script>
import BoardCard from '@/components/boards/BoardCard.vue'
import Search from '@/components/common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'

export default {
    components: {
        Pagination,
        Search,
        BoardCard,
    },
    data() {
        return {
            params: {
                instNo: 1,
                boardNo: 27,
                startIndex: 1,
                pageRow: 12,
                title: this.title,
                content: this.content,
                orderValue: 'TITLE',
                searchKeyword: this.searchKeyword,
            },
            searchParam: {},
            pagingFlag: false,
            currentNum: 1,
            totalPage: 0,
            pageCount: 5,
            totCnt: 0,
        }
    },
    methods: {
        search(searchType, searchKeyword) {
            this.params.title = searchKeyword;
            this.$refs.boardCard.printList = [];
            this.getList(1);
        },
        articleView(key, boardNo, articleNo) {
            this.$router.push({ path: '/healthinfo/cancerDetail.do', query: { key: key, boardNo: this.params.boardNo, articleNo: articleNo } });
        },
        async getContents(list) {
            let keys = list.map(item => item.key);
            await this.axios.post(this.api.health.cancerList, keys).then(res => {
                const resultList = res.data;
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < resultList.length; j++) {
                        if (list[i].key == resultList[j].key) {
                            for (let k in resultList[j].result) {
                                if (resultList[j].result[k].contents != null && resultList[j].result[k].contents != '') {
                                    list[i].content = resultList[j].result[k].contents;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                }
                this.$refs.boardCard.printList.push(...list);
            });
            this.$refs.boardCard.updateMasonryLayout();
        },
        async getList(page) {
            this.params.startIndex = page;
            this.currentNum = page;
            this.$refs.boardCard.printList = []; // 페이지 이동 시 초기화
            await this.axios.get(this.api.article.list + this.params.boardNo, {
                params: this.params
            }).then(res => {
                const totalCnt = res.data.totCnt;
                this.totalPage = Math.ceil(totalCnt / this.params.pageRow);
                this.totCnt = totalCnt;

                const list = res.data.list.map(item => {
                    return { ...item, key: item.item1 };
                });
                this.getContents(list);
            });
        },
        setNum(page) {
            this.getList(page);
        },
        moreList() {
            this.getList(this.currentNum + 1);
        },
    },
    mounted() {
        this.getList(1);
    }
}
</script>
