<template>
    <Header></Header>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home"><a href="" class="Location__item--btn"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQ4ja3TP0sDQRAF8J+HiGAKLQVFsbIQ4p/SRrDKB0hhZyXYiFjnQ9jZCJYigo2CRUDBNkr8ArGytRAUooVaZALHceeZ4IOFtzPz3s4uOyP1el0JFnAafAtPvxUnJWZ1tPEYqx2xQowWxMdxGB3t4CziNzjGJvbR/UuHi2hhDaspM8FXI9eK2l8Nt3GPJtbRyTmwE7lm1G6nk/0rV3CEmt41L3OM0vjEAW5xovcEu3hLsIwHzAcvM0vjMjTz4bGcROvn2MDzAGZ9PIf2HM0EM2jgAtUhDKuhbWB2FB+RmMNUjmAEK8Hb+M7kp0IL3aJ/2McErjOGNbwXCcomZVfvB0zHqkSsEGUdLuEKb7G/ilghyjqErwI+tOFA+HfD7BtWMJnaj+VoxjI1lSLDV/ljt5fiL3qzvpWpueuTH1quOlOE3j3aAAAAAElFTkSuQmCC"
                            alt="이미지"></a></li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과/의료진</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/department-doctor/department.do" class="">진료과/의료진</a></li>
                            <li><a href="/reserve/appointment.do" class="">예약/발급</a></li>
                            <li><a href="/hospital/information/outpatient.do" class="">이용안내</a></li>
                            <li><a href="/hospital/healthInfo/consulting.do" class="">건강정보</a></li>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">병원소개</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">창원파티마병원</a></li>
                            <li><a href="/hospital/hospitalIntro/story/newsBoard.do" class="">파티마이야기</a></li>
                            <li><a href="/hospital/hospitalIntro/active/prayBoard.do" class="">병원활동</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item">
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a aria-current="page" href="/kr/news/list.do"
                                    class="router-link-active router-link-exact-active">화이팅</a></li>
                            <li><a href="/kr/press/list.do" class="">언론보도</a></li>
                            <li><a href="/kr/event/list.do" class="">행사안내</a></li>
                            <li><a href="/kr/job/list.do" class="">퍼블팀</a></li>
                            <li><a href="https://fliphtml5.com/bookcase/nrkwy/"
                                    to="https://fliphtml5.com/bookcase/nrkwy/"></a></li>
                            <li><a href="/kr/gallery/list.do" class=""></a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sub-tit">
            <h2>센터&클리닉</h2>
        </div>
        <div class="inner">
            <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">진료과 안내</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">의료진 검색</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn on">센터&클리닉</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">진료일정표</button></li>
        </ul>
    </div>
    <div class="initial-search">
        <h3>초성검색</h3>
        <ul class="initial-search__list">
            <li><a href="javascript:;" class="all active">ALL</a></li>
            <li><a href="javascript:;">ㄱ</a></li>
            <li><a href="javascript:;">ㄴ</a></li>
            <li><a href="javascript:;">ㄷ</a></li>
            <li><a href="javascript:;">ㄹ</a></li>
            <li><a href="javascript:;">ㅁ</a></li>
            <li><a href="javascript:;">ㅂ</a></li>
            <li><a href="javascript:;">ㅅ</a></li>
            <li><a href="javascript:;">ㅇ</a></li>
            <li><a href="javascript:;">ㅈ</a></li>
            <li><a href="javascript:;">ㅊ</a></li>
            <li><a href="javascript:;">ㅋ</a></li>
            <li><a href="javascript:;">ㅌ</a></li>
            <li><a href="javascript:;">ㅍ</a></li>
            <li><a href="javascript:;">ㅎ</a></li>
        </ul>
    </div>


    <div class="contents">
        <div class="info-text">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">특성화 센터</h4>
                </div>
            </div>
            <ul class="med-departments__list">
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
            </ul>
        </div>
        <div class="info-text">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">전문 클리닉</h4>
                </div>
            </div>
            <ul class="med-departments__list">
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
                <li class="med-departments__list--item">
                    <a href="javascript:;">
                        <div class="med-departments__list--img">
                            <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지">
                        </div>
                        <h4>진료과 제목</h4>
                    </a>
                </li>
            </ul>
        </div>
    </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
    import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Footer from '@/components/common/Footer.vue';

    export default {
        components: {
            Header,
            LocationBar,
            Footer
        },
        mounted() {
        },
    }
</script>
