<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>자격, 면허를 취득 및 보건의료 서비스에 종사하기 위해 『정신건강증진 및 정신질환자 복지서비스 지원에 관한 법률 제3조제1호에 따른 정신질환자, 마약, 대마, 향정신성의약품 중독자가 아님을 증명』하는 진단서가 필요한 경우 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검진대상</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>의사, 치과의사, 한의사, 간호사, 조산사, 약사, 한약사, 임상병리사, 방사선사, 물리치료사, 작업치료사, 치과기공사, 치과위생사, 의무기록사, 안경사, 위생사, 1·2급 응급구조사, 의지·보조기기사, 1·2급 언어재활사 등.</p>
                  </div>
              </div>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검사항목</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>간이정신검사, 마약검사(TBPE,대마)</p>
                  </div>
              </div>
        </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:00</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">소요시간 : 1시간 이내</h4>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">당일 수검자 현황에 따라 시간이 달라질 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">유의사항</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소변검사가 있으니 검사 전 되도록 소변을 참아 주시기 바랍니다.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>복용하는 약이 있는 경우 전화문의(055-270-1051~3) 후 내원하시기 바랍니다.</p>
                  </div>
              </div>
          </div>
    </div>
   
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">서류발급</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 검사 후 5일</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 우편, 내원수령</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>