<template>
    <!-- 소아청소년내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소아청소년과는 신생아부터 고등학생까지 진료하고 있으며, 다양한 질환의 정확한 진단과 치료, 특히 자녀들의 건강한 성장 발달을 확인 및 관리할 수 있는 진료과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>호흡기 감염 (감기, 중이염, 부비동염, 폐렴, 기관지염, 만성기침, 독감 등),  성장( 키 작은 아이, 이차성징이 빠른 아이), 소화기(구토, 설사, 자주 배가 아픈 아이들, 지방간 등), 신장(요로감염, 혈뇨, 단백뇨), 내분비(갑상선 질환, 비만, 고지혈증 등), 잘 먹지 않는 아이들, 감염병(열, 인후염 등), 신생아 관리</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">                       
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 소아청소년과는 아이들의 건강과 행복을 최우선으로 하는 전문 진료를 제공하고 있습니다. 저희 소아청소년과는 다양한 분야에서 탁월한 의료 서비스를 제공하여 아이들이 건강하게 성장할 수 있도록 최선을 다하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">호흡기 감염</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>저희 소아청소년과는 감기, 중이염, 부비동염, 폐렴, 기관지염, 만성기침 등 호흡기 감염 질환을 전문적으로 진료합니다. 항생제는 최소로, 정확한 진단 신속한 치료로 아이들이 빠르게 회복할 수 있도록 지원합니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">소화기 질환</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>구토, 설사, 자주 배가 아픈 아이들, 지방간 등 다양한 소화기 질환을 치료합니다. 소화기 문제로 고통받는 아이들이 건강을 되찾을 수 있도록 지속적인 관리와 치료를 합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">신장 질환</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>요로감염, 혈뇨, 단백뇨 등의 신장 질환을 전문적으로 진료합니다. 정확한 진단과 체계적인 치료로 아이들의 신장 건강을 지켜드립니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">내분비 질환</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>갑상선 질환, 비만, 고지혈증 등 내분비 질환을 앓고 있는 아이들을 위한 전문 진료를 제공합니다. 대사증후군으로 진행하고 있는 아이들을 진료하여 질병으로 이환되지 않도록 지켜드립니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">식욕 부진</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>잘 먹지 않는 아이들을 위한 영양 상담과 치료를 제공합니다. 아이들이 건강한 식습관을 가질 수 있도록 지속적인 관심과 지원을 아끼지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">감염병</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>열이 나는 아이들, 목이 부은 아이들, 인후염 등 다양한 감염병을 진료합니다. 감염병 예방과 관리를 통해 아이들의 건강을 지킵니다. 적절한 시기에 예방접종을 할 수 있도록 도와드립니다. 시기를 높친 접종도 제대로 챙겨드리겠습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">신생아 관리</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신생아들을 위한 특별한 관리와 진료를 제공합니다. 신생아의 건강한 성장을 위해 세심한 주의와 사랑으로 진료에 임합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">혈관종 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>아이의 몸에 혈관종이 있는 경우 수술을 하지 않고 치료할 수도 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">성장·장건강·대사증후군클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>