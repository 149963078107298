<template>
    <!-- 호흡기내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>호흡기내과는 호흡과 관련된 기관지와 폐·흉막의 질환을 진단 및 치료하는 진료과입니다. 기침, 객담, 호흡곤란, 객혈, 흉통 등의 증상과 급·만성기관지염, 알러지성 기도질환, 기관지 천식, 폐렴 및 결핵, 폐암 등의 호흡기 질환을 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>만성기침, 기관지 천식, 만성폐쇄성폐질환, 폐암, 폐렴 및 폐결핵</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">폐기능검사</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>호흡곤란의 원인을 파악하기 위한 첫 단계인 기본 검사로 만성폐쇄성폐질환이나 천식과 같은 질병을 진단할 수 있는 폐기능 검사 장비를 갖추고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">기관지 내시경실</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>시술 및 소독, 보관 공간을 완전히 분리해 독립적인 검사실을 갖추고 있습니다. 환자 상태에 따라 기관지 내시경 사전검사, 흉부 CT, 폐기능검사, 기관지 내시경 하 조직검사까지 가능하며, 결핵이나 폐암 등 중증질환의 진단이 보다 신속하게 이루어지고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">결핵관리상담실</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>활동성 폐결핵은 물론 비활동성 폐결핵, 잠복 결핵 등 다양한 형태의 결핵에 대한 치료는 물론 풍부한 임상 경험을 갖춘 전문의로 구성돼 있으며, 결핵성 질환에 대한 전문 상담 간호사가 상주하고 있어 약제 부작용을 비롯한 식이지도, 접촉자 검사 안내 등 맞춤형 상담 및 교육을 제공하고 있습니다</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">협진 시스템으로 환자 맞춤형 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 호흡기내과에서는 환자의 질환 및 증상에 맞는 최적의 치료를 위해 타 과와 유기적으로 협진하고 있습니다.</p>
                            </div>
                        </div>
                      
                    </div>
                </div>
                  <br>
                <div class="depth__wrap">
                    <div class="depth__text">
                        <div class="table-wrap table-type1">
                            <div class="scroll">
                                <table>
                                    <caption class="blind">협진 시스템으로 환자 맞춤형 치료</caption>
                                    <colgroup>
                                        <col style="width:33.333%;">
                                        <col style="width:33.333%;">
                                        <col style="width:33.333%;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>질환 및 증상</th>
                                            <th>진료과</th>
                                            <th>치료</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>흡연에 의한 폐질환</td>
                                            <td><a href="javascript:;" class="mint_link">직업환경의학과</a></td>
                                            <td>금연클리닉 통한 금연지도 및 관리</td>
                                        </tr>
                                        <tr>
                                            <td>원인 모를 신경성 흉부 증상</td>
                                            <td><a href="javascript:;">정신건강의학과</a></td>
                                            <td>신체화장애클리닉과 연계 치료</td>
                                        </tr>
                                        <tr>
                                            <td>만성호흡곤란 환자</td>
                                            <td><a href="javascript:;">재활의학과</a></td>
                                            <td>호흡재활치료 실시</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {

    };
</script>