<template>
    <!-- 정신건강의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>정신건강의학과는 기분, 생각, 행동, 수면 등의 이상에 대한 진단과 치료를 담당하는 과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>기분장애, 불안장애, 수면장애, 조현병</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">섬세한 전문 면담, 임상심리검사 통한 진단 및 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>기분장애, 불안장애, 강박장애, 수면장애, 조현병 등 각종 정신과적 질환들은 중추신경계의 생물학적 이상과 정신역동, 스트레스 등의 사회심리학적 개별성이 복합적으로 작용하는 뇌의 질환입니다. 전문적인 면담과 적절한 검사를 통해 정확히 진단하고, 약물치료와 면담을 병행하며 꾸준한 치료가 필요합니다.</p>
                                <p>창원파티마병원 정신건강의학과에서는 최소한의 약물 사용과 개별적인 상담을 통해 환자들이 기존의 사회활동과 일상생활로 복귀할 수 있도록 하며, 건강하게 유지할 수 있도록 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">수면장애 치료 </h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>불면증 극복을 위해서는 규칙적인 기상 시간을 지키고, 낮에 충분한 햇볕에 노출될 수 있도록 합니다. 규칙적인 운동도 도움이 되며, 술과 담배, 커피는 피하는 것이 좋습니다. 근육 이완, 명상, 요가 등 이완 요법을 시행하고, 잠에 들기 위해 너무 애쓰지 않고, 수면 위생을 잘 점검하는 것도 중요합니다. 그럼에도 호전이 없다면 내원하여 전문의에게 상담을 받아 보는 것이 좋습니다.</p>
                                <p>창원파티마병원 정신건강의학과에서는 면담을 통해 수면패턴과 불면의 원인을 점검하고, 치료가 필요한 경우 인지행동적 접근과 함께 증상에 따른 적절한 약물을 처방하고 있습니다.</p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>