<template>
    <div class="contents nopayment">
        <div class="tab-menu__wrap">
            <div class="tab-menu type02">
                <ul class="tab-menu__list">
                    <li class="tab-menu__item" v-for="(value, key) in itemData" :key="value" :class="{ 'on': menu === key }">
                        <button type="button" @click="tabMenu(key)" class="tab-menu__btn">{{key}}</button>
                    </li>
                </ul>
            </div>
        </div>

        <Search :searchArr="searchArr(itemData[menu])" @setState="setState" v-if="menu == '행위'"></Search>
        <div class="board__search" v-else>
            <div class="board__input">
                <input type="text" id="Search_doc" ref="searchInput" placeholder="검색어를 입력하세요." v-model="searchKeyword" @keyup.enter="search()" >
                <button type="button" class="board__input-btn" @click="search()"></button>
            </div>
        </div>

        <div class="contents depth__wrap">
            <div class="depth__bullet">
                <p class="small-text">※</p>
            </div>
            <div class="depth__text">
                <p class="small-text">의료법 제45조 제1항 및 제2항, 시행규칙 제42조의 제1항 및 제2항에 의거하여 비급여 진료비용을 고지합니다.</p>
            </div>
        </div>

        <div class="contents " v-for="(value, key) in itemData[menu]" :key="value">
            <div class="info-text depth__wrap depth01" v-if="value.length > 0">
                <div class="depth__bullet" v-if="menu == '행위'"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title type2">
                        <span v-if="menu == '행위'">{{ key }}</span>
                        <span v-else></span>
                        <span v-if="Object.keys(itemData[menu]).indexOf(key) === 0">
                            <span class="c-red">*</span> 최종 수정일 : {{$commonUtils.makeDateFormat(dt.toString(), '.')}}
                        </span>
                        <span v-else>
                            <span class="c-red"></span>
                        </span>
                    </h4>
                </div>
            </div>

            <!-- 행위 -->
            <div class="table-wrap table-type1 scroll">
            <table v-if="this.menu == '행위' && value.length > 0">
                <caption class="blind">
                    비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                </caption>
                <colgroup>
                    <!-- <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);">
                    <col style="width:calc(100% / 12);"> -->
                    <col style="width:4%;">
                    <col style="width:12%;">
                    <col style="width:13%;">
                    <col style="width:11%;">
                    <col style="width:12%;">
                    <col style="width:9%;">
                    <col style="width:4%;">
                    <col style="width:4%;">
                    <col style="width:6%;">
                    <col style="width:6%;">
                    <col style="width:10%;">
                    <col style="width:9%;">
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th rowspan="2">분류</th>
                        <th colspan="2">항목</th>
                        <th colspan="6">가격정보(단위: 원)</th>
                        <th rowspan="2">특이사항</th>
                        <th rowspan="2">최종<span>변경일</span></th>
                    </tr>
                    <tr>
                        <th style="border-left: 1px solid #d9d9d9;">명칭</th>
                        <th>코드</th>
                        <th>구분</th>
                        <th>비용</th>
                        <th>최저비용</th>
                        <th>최대비용</th>
                        <th>치료재료대<br>포함여부</th>
                        <th>약제비<br>포함여부</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(i, idx) in sortList(value)" :key="i">
                        <td>{{ idx + 1 }}</td>
                        <td>{{i.category3}}</td>
                        <td>{{i.itemName}}</td>
                        <td>{{i.itemCode}}</td>
                        <td>{{i.priceDesc}}</td>
                        <td>{{$commonUtils.commaNum(i.price)}}</td>
                        <td>{{$commonUtils.commaNum(i.minPrice)}}</td>
                        <td>{{$commonUtils.commaNum(i.maxPrice)}}</td>
                        <td>{{i.incMaterialYn}}</td>
                        <td>{{i.incMedicineYn}}</td>
                        <td>{{i.remark}}</td>
                        <td>{{$commonUtils.makeDateFormat(i.lastModifiedDt, '.')}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <!-- //행위 -->

            <!-- 치료재료 -->
            <div class="table-wrap table-type1 scroll">
            <table v-if="this.menu == '치료재료' && value.length > 0">
                <caption class="blind">
                    비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                </caption>
                <colgroup>
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th rowspan="2">분류</th>
                        <th colspan="2">항목</th>
                        <th colspan="4">가격정보(단위: 원)</th>
                        <th rowspan="2">특이사항</th>
                        <th rowspan="2">최종 변경일</th>
                    </tr>
                    <tr>
                        <th style="border-left: 1px solid #d9d9d9;">명칭</th>
                        <th>코드</th>
                        <th>구분</th>
                        <th>비용</th>
                        <th>최저비용</th>
                        <th>최대비용</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(i, idx) in sortList(value)" :key="i">
                        <td>{{ idx + 1 }}</td>
                        <td>{{i.category3}}</td>
                        <td>{{i.itemName}}</td>
                        <td>{{ i.itemCode }}</td>
                        <td>{{i.priceDesc}}</td>
                        <td>{{ $commonUtils.commaNum(i.price) }}</td>
                        <td>{{ $commonUtils.commaNum(i.minPrice) }}</td>
                        <td>{{ $commonUtils.commaNum(i.maxPrice) }}</td>
                        <td>{{ i.remark }}</td>
                        <td>{{$commonUtils.makeDateFormat(i.lastModifiedDt, '.')}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <!-- //치료재료 -->

            <!-- 약제 -->
            <div class="table-wrap table-type1 scroll">
                <table v-if="this.menu == '약제' && value.length > 0">
                    <caption class="blind">
                        비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                    </caption>
                    <colgroup>
                        <col style="width:calc(100% / 6);">
                        <col style="width:calc(100% / 6);">
                        <col style="width:calc(100% / 6);">
                        <col style="width:calc(100% / 6);">
                        <col style="width:calc(100% / 6);">
                        <col style="width:calc(100% / 6);">
                    </colgroup>
                    <thead>
                        <tr>
                            <th rowspan="2" style="border-right: 1px solid #d9d9d9;">번호</th>
                            <th colspan="2">항목</th>
                            <th rowspan="2">비용</th>
                            <th rowspan="2">특이사항</th>
                            <th rowspan="2">최종 변경일</th>
                        </tr>
                        <tr>
                            <th>명칭</th>
                            <th>코드</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(i, idx) in drugSortList(value)" :key="i">
                            <td>{{ idx + 1 }}</td>
                            <td>{{i.itemName}}</td>
                            <td>{{i.itemCode}}</td>
                            <td>{{$commonUtils.commaNum(i.price)}}</td>
                            <td>{{ i.remark }}</td>
                            <td>{{$commonUtils.makeDateFormat(i.lastModifiedDt, '.')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- //약제 -->

            <!-- 제증명수수료 -->
            <div class="table-wrap table-type1 scroll">
                <table v-if="this.menu == '제증명수수료' && value.length > 0">
                    <caption class="blind">
                        비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                    </caption>
                    <colgroup>
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                        <col style="width:calc(100% / 9);">
                    </colgroup>
                    <thead>
                        <tr>
                            <th rowspan="2">번호</th>
                            <th colspan="2">항목</th>
                            <th colspan="4">가격정보(단위: 원)</th>
                            <th rowspan="2">특이사항</th>
                            <th rowspan="2">최종 변경일</th>
                        </tr>
                        <tr>
                            <th style="border-left:1px solid #d9d9d9">명칭</th>
                            <th>코드</th>
                            <th>구분</th>
                            <th>비용</th>
                            <th>최저비용</th>
                            <th>최대비용</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(i, idx) in feeSortList(value)" :key="i">
                            <td>{{ idx + 1 }}</td>
                            <td>{{i.itemName}}</td>
                            <td>{{ i.itemCode }}</td>
                            <td>{{i.priceDesc}}</td>
                            <td>{{$commonUtils.commaNum(i.price)}}</td>
                            <td>{{$commonUtils.commaNum(i.minPrice)}}</td>
                            <td>{{$commonUtils.commaNum(i.maxPrice)}}</td>
                            <td>{{ i.remark }}</td>
                            <td>{{$commonUtils.makeDateFormat(i.lastModifiedDt, '.')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- //제증명수수료 -->
        </div>

        <div class="contents depth__wrap">
            <div class="depth__bullet">
                <p class="small-text">※</p>
            </div>
            <div class="depth__text">
                <p class="small-text">고지된 비급여진료비용은 단일 개별항목의 1회 비용이므로 진료과정에서 처방량에 따라 해당 항목의 비용이 달라질 수 있으며, 치료재료 및 약제가 필요한 경우 별도 산정됩니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
import Search from '../common/SearchBox.vue';
// import { gsap, ScrollTrigger } from "gsap/all";

export default {
    components: {
        Search
    },
    data() {
        return {
            orgData: {},
            itemData: {},
            menu: '행위',
            baseDt: {},
            dt: '',
        };
    },
    methods: {
        /** 
         * 행위, 치료재료 asc sorting
         * 정렬순서: sortSeq -> itemCode -> sugaCd
        */
        sortList(arr){
            if (!Array.isArray(arr)) {
                return []
            }

            return arr.sort((a, b) => {
                // 1. sortSeq
                const compareFirst = a.sortSeq.localeCompare(b.sortSeq);
                if (compareFirst !== 0) {
                    return compareFirst;
                }

                // 2. itemCode
                const compareSecond = a.itemCode.localeCompare(b.itemCode);
                if (compareSecond !== 0) {
                    return compareSecond;
                }

                // 3. sugaCd
                return a.sugaCd.localeCompare(b.sugaCd);
            })
        },
        /** 
         * 약제 sorting
         * 정렬순서: itemCode(W의 경우 마지막으로)
        */
        drugSortList(arr){
            if (!Array.isArray(arr)) {
                return []
            }

            return arr.sort((a, b) => {
                if (a.itemCode.toLocaleLowerCase() == 'w' && b.itemCode.toLocaleLowerCase() != 'w') {
                    return 1;
                }
                if (a.itemCode.toLocaleLowerCase() != 'w' && b.itemCode.toLocaleLowerCase() == 'w') {
                    return -1;
                }

                return a.itemCode.localeCompare(b.itemCode);
            })
        },
        /** 
         * 제증명 수수료 sorting
         * 정렬순서: itemCode(-의 경우 마지막으로)
        */
        feeSortList(arr){
            if (!Array.isArray(arr)) {
                return []
            }

            return arr.sort((a, b) => {
                if (a.itemCode == '-' && b.itemCode != '-') {
                    return 1;
                }
                if (a.itemCode != '-' && b.itemCode == '-') {
                    return -1;
                }

                return a.itemCode.localeCompare(b.itemCode);
            })
        },
        /** 
         * parameter: s(category) / v(value)
        */
        setState(s, v, p){
            this.itemData = JSON.parse(JSON.stringify(this.orgData));

            if(!s && !v) {
                return
            }

            if(!s){
                for(let i in this.itemData[this.menu]){
                    this.itemData[this.menu][i] = this.itemData[this.menu][i].filter(item => item.itemName.includes(v))
                }
            }else{
                if(v){
                    for(let i in this.itemData[this.menu]){
                        if(s == i){
                            this.itemData[this.menu][i] = this.itemData[this.menu][i].filter(item => item.itemName.includes(v))
                        }else{
                            this.itemData[this.menu][i] = []
                        }
                    }
                }else{
                    for(let i in this.itemData[this.menu]){
                        if(s == i){
                            this.itemData[this.menu][i] = this.itemData[this.menu][i]
                        }else{
                            this.itemData[this.menu][i] = []
                        }
                    }
                }
            }
        },
        search(){
            this.itemData = JSON.parse(JSON.stringify(this.orgData));
            if(!this.searchKeyword) return
            for(let i in this.itemData[this.menu]){
                this.itemData[this.menu][i] = this.itemData[this.menu][i].filter(item => item.itemName.includes(this.searchKeyword))
            }
        },
        searchArr(obj){
            const result = [{title: '전체', value: ''}]
            for(let i in obj){
                const object = {}
                object.title = i,
                object.value = i
                result.push(object)
            }
            return result
        },
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table && table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        },
        tableData() {
            this.axios.get(this.api.nonpayment.getList, {
                params: {
                    instNo: 1
                }
            }).then((res) => {
                const arr = res.data
                this.dt = Math.max(...arr.map(obj => obj.lastModifiedDt))
                for(let i of arr){
                    if(!this.itemData[i.category1]){
                        this.itemData[i.category1] = {}
                    }
                    if(!this.itemData[i.category1][i.category2]){
                        this.itemData[i.category1][i.category2] = []
                    }
                    this.itemData[i.category1][i.category2].push(i)
                }
                this.orgData = this.itemData
            })
        },
        tabMenu(el) {
            this.menu = el
            this.itemData = JSON.parse(JSON.stringify(this.orgData));

            document.querySelectorAll('.tab-menu__item button').forEach(item => {
                item.classList.remove('on');
            });
        },
        getBaseDt(){
            this.axios(this.api.nonpayment.baseDtList, {
                params: {
                    instNo: 1
                }
            }).then(res=>{
                for(let i of res.data){
                    this.baseDt[i.category1] = i.baseDt
                }
            })
        },
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
     updated() {
        this.$nextTick(() => {
            let tableItems = document.querySelectorAll(".table-wrap");

            tableItems.forEach((el) => {
                this.checkTableWidth(el);
                el.addEventListener("click", () => {
                el.classList.add('on');
                });
            });
        });
    },
    mounted() {
        this.tableData()
        // this.getBaseDt()

        setTimeout(() => {
            let tableItems = document.querySelectorAll(".table-wrap");

            tableItems.forEach((el) => {
                this.checkTableWidth(el);
            });

            let tableItem = document.querySelectorAll(".table-wrap");

            tableItem.forEach((el) => {
                el.addEventListener("click", () => {
                    el.classList.add('on');
                });
            });
        }, 100);
    }

};
</script>

<style scoped>
    .table-type1 table td{word-break: break-word; padding: 17px 10px;}
</style>