<template>
  <div class="healthNotice healthCheckupInfo">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">CT</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                CT 검사는 목적에 따라 조영제를 투여하여 검사할 수
                있습니다.(혈관주사)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>아래의 경우 검사 전 반드시 직원과 상담이 필요합니다.</p>
              <div class="depth__wrap depth03">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>메트포민성 당뇨약(다이아벡스, 글루코파지 등) 복용 시</p>
                </div>
              </div>
              <div class="depth__wrap depth03">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>과거병력이나, 알러지, 천식 등의 특이사항이 있는 경우</p>
                </div>
              </div>
              <div class="depth__wrap depth03">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>과거 조영제 관련 부작용을 경험한 경우</p>
                </div>
              </div>
              <div class="depth__wrap depth03">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>임신 중 혹은 임신가능성이 있는 경우</p>
                </div>
              </div>
              <div class="depth__wrap depth03">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>신장질환 또는 신장기능에 문제가 있는 경우</p>
                </div>
              </div>
            </div>
          </div>

          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                촬영 후 조영제의 배출을 돕기 위해 충분히 수분을 섭취해 주십시오.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                메트포민성 당뇨약은 검사 후 2일(48시간)이 경과한 후 복용
                가능합니다.
              </p>
            </div>
          </div>
        </div>
      </div>

       <div class="btn-wrap center add_drug_btn contents">
      <a href="javascript:;" class="btn dark-gray">상세 약물 보기<span>▾</span></a>
    </div>
    <div class="add_drug_box">
      <ul class="add_drug_list mt0">
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_01.gif" alt="가브스메트정 50.500mg">
          <p>가브스메트정 50.500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_02.gif" alt="다이아벡스정 250mg">
          <p>다이아벡스정 250mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_03.gif" alt="그리코민서방정 500mg">
          <p>그리코민서방정 500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_04.gif" alt="다이아벡스정 1000mg">
          <p>다이아벡스정 1000mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_05.gif" alt="글루리아드정 500..2.5">
          <p>글루리아드정 500..2.5</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_06.gif" alt="아마릴엠정 2.500mg">
          <p>아마릴엠정 2.500mg</p>
        </li>
         <li class="add_drug_item">
          <img src="/resource/icon/drug03_07.gif" alt="글루코닐정 500mg">
          <p>글루코닐정 500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_012.gif" alt="자누메트정 50.500mg">
          <p>자누메트정 50.500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_08.gif" alt="글루코다운오알서방정 500mg">
          <p>글루코다운오알서방정 500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_09.gif" alt="콤비글라이즈서방정 5.500mg">
          <p>콤비글라이즈서방정 5.500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_010.gif" alt="글루코파지정 500mg">
          <p>글루코파지정 500mg</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/icon/drug03_011.gif" alt="트라젠타듀오정 2.5..500">
          <p>트라젠타듀오정 2.5..500</p>
        </li>
      </ul>
      <button class="accordion_btn_wrap">
        <img src="/resource/icon/accordion_button-active.png" alt="이미지">
      </button>
    </div>

      <div class="info-text depth__wrap depth01 contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">MRI</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검사 전 특별한 처치는 필요하지 않습니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                인공심장 박동기나, 금속성 물질을 체내에 이식하신 분은 자석의
                영향이 크므로 검사가 불가합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                폐쇄공포증이나, 공황장애가 있으신 분은 검사가 어려울 수
                있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">PET-CT 기본사항</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검사 실시 전 8시간 금식이 필요합니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                폐쇄공포증이나, 공황장애가 있으신 분은 검사가 어려울 수
                있습니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                검사전날 과도한 운동은 방사성 약분의 체내 흡수에 영향을 미쳐
                검사의 정확성을 저하시키는 요인이 됩니다. 검사 전날에는 심한
                운동을 삼가 해 주십시오.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>임신 중에는 PET-CT 촬영이 불가합니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                혈당이 높을 경우(150mg/dl 이상) 당일 검사가 어려울 수 있습니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                당뇨가 있으신 분은 예약 시 직원에게 말씀해 주시고, 인슐린 또는
                당뇨 약물 치료를 하고 있는 분은 금식하는 동안 복용하지 마십시오.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">PET CT 검사 후 주의사항</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>검사가 끝난 후 하루 동안 충분한 수분섭취가 필요합니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>PET-CT 검사 시 위는 내시경 검사만 가능합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   mounted() {
        const button = document.querySelector('.btn.dark-gray');
        const drugBox = document.querySelector('.add_drug_box');
        const accordionButton = document.querySelector('.accordion_btn_wrap');

        button.addEventListener('click', function() {
          if (drugBox.style.display === 'none' || drugBox.style.display === '') {
            // 슬라이드 다운
            drugBox.style.display = 'block';
            const height = drugBox.scrollHeight + 'px';
            drugBox.style.height = height;
            button.classList.add('active');
          } else {
            // 슬라이드 업
            drugBox.style.height = '0';
            button.classList.remove('active');
            setTimeout(() => {
              drugBox.style.display = 'none';
            }, 100); // transition 시간과 동일하게 설정
          }
        });

        accordionButton.addEventListener('click', function() {
          // 버튼에서 active 클래스 제거
          button.classList.remove('active');
          
          // drugBox를 슬라이드 업하여 높이 0으로 설정하고 display를 none으로 변경
          drugBox.style.height = drugBox.scrollHeight + 'px';
          setTimeout(() => {
            drugBox.style.height = '0';
          }, 10); // 약간의 지연을 주어 높이가 설정되도록 함
          setTimeout(() => {
            drugBox.style.display = 'none';
          }, 100); // transition 시간과 동일하게 설정
        });
      }
};
</script>