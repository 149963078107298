<template>
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">이용방법 안내</h4>
                <!-- depth02 -->
                <div class="depth__wrap depth02">
                    <div class="depth__bullet">
                        <h4 class="depth02__title blue__title">01</h4>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">전화 055-270-1108</h4>
                        <!-- depth03 -->
                        <div class="depth__wrap depth03">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>진료협력센터로 전화 주시면 전담간호사가 진료과 안내 및 빠른 진료가 이루어질 수 있도록 도와드립니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet">
                        <h4 class="depth02__title blue__title">02</h4>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">팩스 055-270-1029</h4>
                        <!-- depth03 -->
                        <div class="depth__wrap depth03">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>진료의뢰서를 FAX로 전송해 주시면 진료 예약일을 유선으로 안내해드립니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet">
                        <h4 class="depth02__title blue__title">03</h4>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">운영시간</h4>
                        <!-- depth03 -->
                        <div class="depth__wrap depth03">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>평일 9:00~17:00 / 토요일 9:00~12:00</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth03">
                            <div class="depth__bullet">
                                <span class="bullet03"></span>
                            </div>
                            <div class="depth__text">
                                <p>야간이나 응급 시에는 응급의료센터를 이용하시기 바랍니다. (055-270-1119, 1120)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet">
                        <h4 class="depth02__title blue__title">04</h4>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth02__title title">센터위치</h4>
                    </div>
                </div>
            </div>
        </div>
        <img src="/resource/img/center-location.png" alt="센터지도" style="max-width: 100%;">
    </div>
</template>

<script>
export default {};
</script>