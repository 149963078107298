<template>
  <div class="myHealth_centerReserve">
    <div class="contents">
      <h3 class="myHealth_top">
        3일 이상 확인 지연시(상태가 ‘N’) 건강증진센터(270-1200)로 문의주시기
        바랍니다.
      </h3>
    </div>
    <div class="contents">
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 10%" />
              <col style="width: 15%" />
              <col style="width: 15%" />
              <col style="width: 35%" />
              <col style="width: 5%" />
              <col style="width: 20%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>접수일</th>
                <th>희망예약일</th>
                <th>검사항목</th>
                <th>상태</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>2024.06.03</td>
                <td>2024.06.27</td>
                <td>조기암(PET-CT)검진</td>
                <td>N</td>
                <td>
                    <div class="btn-wrap center">
                      <a href="javascript:;" class="btn">취소</a>
                    </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>2024.05.10</td>
                <td>2024.06.24</td>
                <td>그린코스 / 뇌심혈관계발병위험도</td>
                <td>Y</td>
                <td>
                    <div class="btn-wrap center">
                      <a href="javascript:;" class="btn">취소</a>
                    </div>
                </td>
              </tr>

              <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->
              <tr>
                <td colspan="6">내용이 없습니다.</td>
              </tr>
              <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

