<template>
    <p class="contents sub-text">회원님의 정보를 안전하게 보호하기 위해 <br>
        임시비밀번호를 실제 사용하시는 비밀번호로 변경해주시기 바랍니다.</p>
    <div class="join contents">
        <div class="input-form type1">
            <p class="input-form__top-text type2">&#183; 창원파티마병원은 <span class="blue">3개월</span>마다 비밀번호를 변경하도록 정책을 시행하고 있습니다.</p>
            <ul class="input-form__list">
                 <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호</div>
                        <div class="cont">

                            <!-- 조건 성립시 class='active' 추가-->
                            <div class="password active">
                                <!-- 조건 미성립시 class='b-red' 추가-->
                                <input type="text" class="cont__text-input">
                            </div>
                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                            <p class="desc">동일문자 연속 4개 사용금지</p>
                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호 확인</div>
                        <div class="cont">
                            <!-- 조건 성립시 class='active' 추가-->
                            <div class="password">
                                <!-- 조건 미성립시 class='b-red' 추가-->
                                <input type="text" class="cont__text-input b-red">
                            </div>
                            <p class="desc c-red">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn">3개월 후에 변경</a>
            <a href="javascript:;" class="btn blue">비밀번호 변경</a>
        </div>
    </div>


</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>