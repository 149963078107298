<template>
    <p class="contents sub-text">창원파티마병원 홈페이지에 방문해 주셔서 감사드립니다.</p>
    <p class="sub-text dp2">회원가입 시 개인화 맞춤 서비스를 제공받으실 수 있습니다.</p>

    <div class="login-box">
        <ul class="contents login-box__list join">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/join-icon1.png" alt="이미지"></div>
                <h3 class="tit">일반 회원가입</h3>
                <p class="desc">만 14세 이상 내국인</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">회원가입</a></div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/join-icon2.png" alt="이미지"></div>
                <h3 class="tit">어린이 회원가입</h3>
                <p class="desc">만 14세 미만 내국인</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">회원가입</a></div>
            </li>
            <li class="login-box__item">
                <h3 class="tit">SNS 회원가입</h3>
                <p class="desc">SNS 계정 사용자</p>
                <div class="join-sns-icon">
                    <button type="button" class="icon naver"></button>
                    <button type="button" class="icon kakao"></button>
                </div>
            </li>
        </ul>

        <div class="contents info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">유의사항</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>어린이 회원가입은 본인인증(휴대폰 또는 아이핀)과 법적대리인(보호자)인증 후 가입이 가능합니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>법정대리인 인증은 홈페이지 회원인증으로 진행합니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>SNS 계정(네이버, 카카오)관련 문의 사항은 해당 홈페이지 고객센터에서 확인 하시기 바랍니다.</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p class="c-red">만 14세 미만아동은 SNS 회원가입이 불가능하며 어린이 회원가입으로 진행이 가능합니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    export default {

    }
</script>