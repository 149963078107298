<template>
  <div class="healthCheckupInfo colon_wrap contents">
    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">기본사항</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>채변 : 종합건진과 함께 실시 할 경우 채변은 검사 전날 관장약을 드시기 전에 미리 해두십시오.</p>
          </div>
        </div>
        <div class="depth__wrap depth02 mt20">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>대장내시경을 하시는 여성분은 당일 부인과 검사가 불가하여 추후 예약해 드립니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>모유수유 중이신 여성분은 검사를 피해 주십시오.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>수면 유도제 사용으로 자가운전이 불가하오니, 대중교통이용 또는 보호자 동반이 필요합니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>뇌졸중, 혈전증, 심장질환(스텐트 포함), 폐질환을 앓고 있거나, 수술력이 있으신 분은 미리 연락 바랍니다.(예전 수술력 포함)</p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">복용약물 안내</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>뇌졸중, 심장질환, 폐질환 등의 질환이 있거나, 수술 및 입원치료를 받으신 분은 복용하시는 약물에 대하여 주치의에게 확인 한 후 검진 여부를 결정하셔야 합니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02 mt20">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>혈압약, 심장약, 항경련제를 복용하시는 분은 검진 당일 오전 6시경에 소량의 물로 복용하시면 됩니다.(단, 항응고제는 복용이 불가합니다.)</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검진 전날 저녁 또는 당일 아침 인슐린 주사나 당뇨약 복용은 불가합니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>아스피린 등 혈전용해제 성분의 약을 복용하시는 분은 조직검사 시 출혈의 위험이 있으므로 주치의 상담 후 검사 1주일 전부터 복용을 중단하셔야 합니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>복용하시는 약에 대한 조정이 안 되신 분은 조직검사를 실시하지 못할 수도 있습니다.(복용중단이 어려운 분은 사전에 검진센터로 연락 바랍니다.)</p>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrap center add_drug_btn contents">
      <a href="javascript:;" class="btn dark-gray">상세 약물 보기<span>▾</span></a>
    </div>
    <div class="add_drug_box">
      <h4 class="drug_tit">내시경 검사 시 출혈 유발 가능성이 있는 약</h4>
      <p class="drug_desc">현재 복용하고 계신 약 중 다음의 약이 포함되어 있거나 <b class="blue__title">지속적으로 복용하고 계시는 약물</b>이 있다면 <b class="red__title">미리 말씀</b>해 주시고, <b class="red__title">반드시 주치의와 상의 후 중단여부와 중단일자를 확인</b>하시기 바랍니다.</p>

      <ul class="add_drug_list">
        <li class="add_drug_item">
          <img src="/resource/img/drug01.gif" alt="아스피린 프로텍트정">
          <p>아스피린 프로텍트정</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug02.gif" alt="아스트릭스">
          <p>아스트릭스</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug03.gif" alt="플라빅스">
          <p>플라빅스</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug04.gif" alt="디스그렌">
          <p>디스그렌</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug05.gif" alt="프레탈정(50mg)">
          <p>프레탈정(50mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug06.gif" alt="프레탈정(100mg)">
          <p>프레탈정(100mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug07.gif" alt="크리드정">
          <p>크리드정</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug08.gif" alt="티클로돈정">
          <p>티클로돈정</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug09.gif" alt="베라실">
          <p>베라실</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug010.gif" alt="안플라그">
          <p>안플라그</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug011.gif" alt="와파린(2mg)">
          <p>와파린(2mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug012.gif" alt="와파린(5mg)">
          <p>와파린(5mg)</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug013.gif" alt="오팔몬">
          <p>오팔몬</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug014.gif" alt="플래리스">
          <p>플래리스</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug015.gif" alt="프라닥사">
          <p>프라닥사</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug016.gif" alt="자렐토">
          <p>자렐토</p>
        </li>
        <li class="add_drug_item">
          <img src="/resource/img/drug017.gif" alt="엘리퀴스">
          <p>엘리퀴스</p>
        </li>
      </ul>
      <button class="accordion_btn_wrap">
        <img src="/resource/icon/accordion_button-active.png" alt="이미지">
      </button>
    </div>

    <div class="contents">
        <div class="info-text depth__wrap">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">대장내시경 식이조절</h4>
          </div>
        </div>
        <div class="table-wrap table-type1">
            <div class="scroll">
                <table>
                    <caption class="blind">대장내시경 식이조절</caption>
                    <colgroup>
                        <col style="width:30%;">
                        <col style="width:70%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>일시</th>
                            <th>내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>대장검사 3일전</td>
                            <td style="text-align:left;">씨 있는 과일(참외,수박,포도,딸기등), 잡곡, 견과류, 해조류(김,미역,다시마 등)는 불가합니다.</td>
                        </tr>
                        <tr>
                            <td>검진 전날 (점심/저녁)</td>
                            <td style="text-align:left;">반찬 없이 쌀로만 만든 흰죽을 드십시오. (깨죽, 호박죽, 야채죽 등 찌꺼기가 남을 고형음식은 안됩니다.)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="contents">
       <div class="info-text depth__wrap">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">음식에 관한 주의 사항</h4>
          </div>
        </div>
        <div class="table-cont food_content">
          <ul class="table-list type02">
            <li class="table-item">
              <div class="item tit">검사 3일전</div>
              <div class="item flex_item">
                  <div class="food_wrap">
                      <h5 class="red_tip">주의</h5>
                      <div class="food_img_wrap">
                          <div class="food_img_list">
                            <img src="/resource/img/food01.png" alt="음식">
                            <img src="/resource/img/food02.png" alt="음식">
                            <img src="/resource/img/food03.png" alt="음식">
                            <img src="/resource/img/food04.png" alt="음식">
                            <img src="/resource/img/food05.png" alt="음식">
                            <img src="/resource/img/food06.png" alt="음식">
                            <img src="/resource/img/food07.png" alt="음식">
                            <img src="/resource/img/food08.png" alt="음식">
                            <img src="/resource/img/food09.png" alt="음식">
                            <img src="/resource/img/food010.png" alt="음식">
                            <img src="/resource/img/food011.png" alt="음식">
                          </div>
                          <p>김치류, 나물류, 해조류(미역, 김, 다시마), 콩나물 씨 있는 과일(수박, 참외, 딸기, 포도, 키위등), 고추씨, 옥수수, 견과류(땅콩, 잣, 호두) 잡곡밥, 현미밥, 깨죽 등</p>
                      </div>
                  </div>
                  <div class="food_wrap">
                      <h5 class="mint_tip">가능</h5>
                      <div class="food_img_wrap">
                          <div class="food_img_list">
                            <img src="/resource/img/food02_01.png" alt="음식">
                            <img src="/resource/img/food02_02.png" alt="음식">
                            <img src="/resource/img/food02_03.png" alt="음식">
                            <img src="/resource/img/food02_04.png" alt="음식">
                            <img src="/resource/img/food02_05.png" alt="음식">
                            <img src="/resource/img/food02_06.png" alt="음식">
                          </div>
                          <p>계란류, 두부류, 묵, 국물류, 빵종류, 맑은 름료류(맑은쥬스, 녹차, 이온음료 등), 사과, 배, 바나나, 감자</p>
                      </div>
                  </div>
              </div>
            </li>
            <li class="table-item">
              <div class="item tit">검진 전날<br>(점심/저녁)</div>
              <div class="item">
                <div class="food_wrap">
                      <h5 class="mint_tip">가능</h5>
                      <div class="food_img_wrap">
                          <div class="food_img_list">
                            <img src="/resource/img/food03_01.png" alt="음식">
                            <img src="/resource/img/food03_02.png" alt="음식">
                          </div>
                          <p>흰쌀밥, 흰죽만 가능</p>
                      </div>
                  </div>
              </div>
            </li>
          </ul>
        </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">대장정결제(대장약)의 복용</h4>
            <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>아래의 장결정제(대장약) 복용법을 확인하시기 바랍니다.</p>
            </div>
          </div>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>대변이 맑게 나오더라도 장정결제(대장약)를 모두 드십시오.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrap mt20">
        <a href="#howToDrug01" class="btn blue">쿨프렙산 복용법 안내</a>
        <a href="#howToDrug02" class="btn mint">수프랩미니정 복용법 안내</a>
        <a href="#howToDrug03" class="btn dark-gray">오라팡 복용법 안내</a>
      </div>

    </div>

    <div class="contents" id="howToDrug01">
      <h4 class="drug_tit">쿨프렙산 조제 및 복용방법</h4>
      <div class="how_to_drug_wrap">
          <ul class="how_to_drug_list">
            <li class="how_to_drug_items">
                <h4>쿨프렙산 조제 및 복용방법</h4>
                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con01">
                      <div class="num_label">1</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug01.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p>A제(1포)와 B제(1포)를 500mL 통에 넣은 후, 찬물 500mL 를 표시선 까지 채운 다음 뚜껑을 닫고 완전히 녹을 때까지 흔들어 줍니다.</p>
                      <div class="how_to_drug_tit_box">쿨프렙산 조제방법</div>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con02">
                      <div class="num_label">2</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug02.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p><b class="num_label02">❶</b>의 과정에서 조제한 쿨프렙산 500mL를 30분 안에 15분 간격으로 두번에 나누어 모두 복용합니다.</p>
                      <div class="how_to_drug_tit_box">쿨프렙산 복용방법</div>
                  </div>
                </div>
            </li>

            <li class="how_to_drug_items">
                <h4>쿨프렙산 분할복용방법</h4>
                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con03">
                      <div class="box_label_wrap">
                        <div class="box_label">검사 전날 - 1차복용</div>
                        <img src="/resource/img/drug_animate.png" alt="이미지">
                      </div> 
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug03_01.png" alt="쿨프렙산 분할복용방법">
                      </div>
                      <p><b>대장내시경 검사 전날 저녁,</b> 조제한 쿨프렙산 1L를 15분 간격으로 250mL씩 1시간 동안 복용합니다. 추가로 물 500mL를 마십니다.</p>
                      <div class="how_to_drug_tit_box blue_box">오후 8~9시 </div>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con04"> 
                      <div class="box_label_wrap">
                        <div class="box_label mint_label">검사 당일 - 2차복용</div>
                         <img src="/resource/img/drug_animate.png" alt="쿨프렙산 분할복용방법">
                      </div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug03_02.png" alt="쿨프렙산 분할복용방법">
                      </div>
                      <p><b>검사 당일 아침,</b> 전날 저녁과 동일하게 복용하고 가소콜 2포를 복용합니다.</p>
                      <div class="how_to_drug_tit_box mint_box">오전 5~6시</div>
                  </div>
                </div>
            </li>
          </ul>

      </div>
    </div>

    <div class="contents" id="howToDrug02">
      <h4 class="drug_tit">수프렙미니정 복용안내</h4>
      <div class="how_to_drug_wrap">
          <ul class="how_to_drug_list">
            <li class="how_to_drug_items">
                <h4>수프렙미니정 복용방법</h4>
                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con05">
                      <div class="num_label">1</div>
                      <div class="img_ex_wrap">
                          <div class="img_ex_tit">총16포 입니다.<br>(2회분)</div>
                          <div class="img_ex_tit_mini">
                              <p class="blue">검사전날</p>
                              <p class="mint">검사당일</p>
                          </div>
                          <img src="/resource/img/howtodrug04.png" alt="수프렙미니정 복용방법" class="pc">
                          <img src="/resource/img/howtodrug04_mo.png" alt="수프렙미니정 복용방법" class="mo">
                      </div>
                      <p><b>1회 복용 시</b> 수프렙미니정 <b>8포를 30~40분 동안 충분한 물과 함께 천천히</b><br> <b>복용합니다</b> (1포당 4~5분 간격으로 복용).</p>
                      <div class="how_to_drug_tit_box">수프렙미니정 8포를 30~40분 <br>동안 충분한 물과 함께 복용</div>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con06">
                      <div class="num_label">2</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug05.png" alt="수프렙미니정 복용방법">
                      </div>
                      <p>수프렙미니정 복용 후, 1시간 동안 <b>물 500mL를 두 번 더</b> 복용합니다.</p>
                      <div class="how_to_drug_tit_box">1시간</div>
                  </div>
                </div>
            </li>

            <li class="how_to_drug_items">
                <h4>수프렙미니정 복용시간</h4>
                <div class="box_label_wrap ml30">
                    <div class="box_label">검사 전날 - 1차복용</div>
                    <img src="/resource/img/drug_animate.png" alt="이미지">
                </div> 
                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con07">
                      <div class="num_label blue">1</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug06.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p><span class="red__title">*</span> 30분 동안 복용</p>
                      <div class="how_to_drug_tit_box blue_box">오후7시</div>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con08">
                      <div class="num_label blue">2</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug05.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p><span class="red__title">*</span> 한 시간 동안 물 500mL 두 번 추가 복용</p>
                      <div class="how_to_drug_tit_box blue_box">오후  7시 30분 ~ 8시 30분</div>
                  </div>
                </div>

                <div class="box_label_wrap mt40">
                    <div class="box_label mint_label">검사 당일 - 2차복용</div>
                      <img src="/resource/img/drug_animate.png" alt="쿨프렙산 분할복용방법">
                </div>

                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con09">
                      <div class="num_label mint">1</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug06.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p class="mt0"><span class="red__title">*</span> 30분 동안 복용</p>
                      <div class="how_to_drug_tit_box mint_box">오전5시</div>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con010">
                      <div class="num_label mint">2</div>
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug07.png" alt="쿨프렙산 조제 및 복용방법">
                      </div>
                      <p><span class="red__title">*</span> 한 시간 동안 물 500mL 두 번 추가 복용<br><span class="red__title">*</span> 당일 오전 6시 30분에 가소콜 2포 복용하세요.</p>
                      <div class="how_to_drug_tit_box mint_box">오전  5시 30분 ~ 6시 30분</div>
                  </div>
                </div>
                 <p class="how_to_drug_add_desc">* 1차 복용 (검사 전날 저녁) 후 10~12시간 뒤 동일한 방법으로 복용합니다.</p>
            </li>
          </ul>
      </div>
    </div>

    <div class="contents" id="howToDrug03">
      <h4 class="drug_tit">오라팡 복용안내</h4>
      <div class="how_to_drug_wrap">
          <ul class="how_to_drug_list">
            <li class="how_to_drug_items">
                <h4>오라팡 복용시간</h4>
                <div class="how_to_drug_con_flex_wrap">
                  <div class="how_to_drug_con how_to_drug_con011">
                      <div class="box_label_wrap mb0">
                        <div class="box_label">검사 전날 - 1차복용</div>
                        <img src="/resource/img/drug_animate.png" alt="이미지">
                      </div> 
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug08.png" alt="쿨프렙산 조제 및 복용방법" class="pc">
                          <img src="/resource/img/howtodrug08_mo.png" alt="쿨프렙산 조제 및 복용방법" class="mo">
                      </div>
                      <p><b class="blue__title">먼저, 물을 한 컵 마신 후,</b> 오라팡 14정을 1-2정씩 나누어 물(약 500ml)과 함께 30분동안 천천히 복용합니다.<br>그 후, 물 또는 무색의 이온음료 1L 이상을 1시간 동안 천천히 마십니다.</p>
                      <div class="how_to_drug_tit_box blue_box">오후 7시 30분 ~ 8시 30분</div>
                      <p class="red__title bottom_text mo"><b>※ 물이나 무색의 이온음료는 충분히 드시는 것이 좋습니다.</b></p>
                  </div>

                  <div class="how_to_drug_con how_to_drug_con012">
                      <div class="box_label_wrap mb0">
                        <div class="box_label mint_label">검사 전날 - 1차복용</div>
                        <img src="/resource/img/drug_animate.png" alt="이미지">
                      </div> 
                      <div class="img_ex_wrap">
                          <img src="/resource/img/howtodrug09.png" alt="쿨프렙산 조제 및 복용방법" class="pc">
                          <img src="/resource/img/howtodrug09_mo.png" alt="쿨프렙산 조제 및 복용방법" class="mo">
                      </div>
                      <p><b class="mint__title">먼저, 물을 두 컵 마신 후,</b> 오라팡 14정을 1-2정씩 나누어 물(약 500ml)과 함께 30분동안 천천히 복용합니다.<br>그 후, 물 또는 무색의 이온음료 1L 이상을 1시간 동안 천천히 마십니다.</p>
                      <div class="how_to_drug_tit_box mint_box">오전 5시 30분 ~ 6시 30분</div>
                       <p class="bottom_text mo">※ 적어도 검사 2시간 전 또는 의사가 지시한 시간까지 이 약 복용 및 추가 물 섭취를 완료합니다.</p>
                  </div>
                </div>
                <div class="bottom_flex_wrap">
                  <p class="red__title bottom_text"><b>※ 물이나 무색의 이온음료는 충분히 드시는 것이 좋습니다.</b></p>
                  <p class="bottom_text">※ 적어도 검사 2시간 전 또는 의사가 지시한 시간까지 이 약 복용 및 추가 물 섭취를 완료합니다.</p>
                </div>
            </li>
          </ul>
          
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">검사 후 안내</h4>
            <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>물과 식사는 대장내시경 검사 30분 후부터 가능합니다.</p>
            </div>
          </div>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>검사 당일은 부드러운 음식으로 섭취하시길 권합니다. (술, 커피 등 자극적 음식 금지)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="contents">
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">복부 팽만감 및 통증</h4>
            <div class="depth__wrap depth02">
              <div class="depth__bullet"><span class="bullet03"></span></div>
              <div class="depth__text">
                <p>검사 시 대장을 관찰하기 위해 공기를 주입하기 때문에 검사 후 장에 남아있는 공기로 인해 하복부의 통증이 발생 할 수 도 있습니다. 통증이 있다면 배를 따뜻하게 마사지 하거나, 움직이시면 장의 가스가 배출되어 통증이 가라앉습니다.</p>
              </div>
            </div>
            <div class="depth__wrap info-reference">
                <div class="depth__bullet">
                    <p class="small-text">※</p>
                </div>
                <div class="depth__text">
                    <p class="small-text">간혹 대변에 피가 조금 섞여 나올 수 있습니다만, 보통의 경우 곧 멈추게 됩니다. 2~3일 이상 선홍색 피가 계속해서 나올 경우 병원으로 바로 연락주시기 바랍니다.
                    </p>
                </div>
            </div>
             <p class="table-desc mt10" style="color:#333;">(Tel : 055-270-1200 ~ 1201)</p>
        </div>
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">검사유의사항</h4>
            <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>검사 중 의사의 판단에 따라 조직검사를 시행 할 수 있으며, 용종의 제거나, 기타 치료내시경은 종합검진과 별도로 진행될 수 있습니다.</p>
            </div>
          </div>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>수검자의 장내 상태에 따라 검사시간이 오래 걸릴 수 있고, 경우에 따라 검사가 중지 될 수도 있습니다.</p>
            </div>
          </div>
            <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>검사 소요시간은 보통 1시간정도 소요됩니다. (수검자의 상태에 따라 소요시간은 변동될 수 있습니다.)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
      mounted() {
        const button = document.querySelector('.btn.dark-gray');
        const drugBox = document.querySelector('.add_drug_box');
        const accordionButton = document.querySelector('.accordion_btn_wrap');

        button.addEventListener('click', function() {
          if (drugBox.style.display === 'none' || drugBox.style.display === '') {
            // 슬라이드 다운
            drugBox.style.display = 'block';
            const height = drugBox.scrollHeight + 'px';
            drugBox.style.height = height;
            button.classList.add('active');
          } else {
            // 슬라이드 업
            drugBox.style.height = '0';
            button.classList.remove('active');
            setTimeout(() => {
              drugBox.style.display = 'none';
            }, 100); // transition 시간과 동일하게 설정
          }
        });

        accordionButton.addEventListener('click', function() {
          // 버튼에서 active 클래스 제거
          button.classList.remove('active');
          
          // drugBox를 슬라이드 업하여 높이 0으로 설정하고 display를 none으로 변경
          drugBox.style.height = drugBox.scrollHeight + 'px';
          setTimeout(() => {
            drugBox.style.height = '0';
          }, 10); // 약간의 지연을 주어 높이가 설정되도록 함
          setTimeout(() => {
            drugBox.style.display = 'none';
          }, 100); // transition 시간과 동일하게 설정
        });
      }

};
</script>