<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">칭찬합니다</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">고객의소리</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">건강상담</button></li>
        </ul>
    </div>

    <div class="contents">
        <ul>
            <li class="c-gray5">접수 상태의 글은 수정 가능합니다. 단, 제목, 칭찬 직원, 부서/병동은 수정할 수 없으며 내용만 수정 가능합니다.</li>
            <li class="c-gray5">내용 외 다른 수정을 원하시면, 해당 글 삭제 후 <b class="c-blue">이용안내 > 고객 서비스 > 칭찬합니다</b> 에서 신규 글을 등록해주시기 바랍니다.</li>
        </ul>
    </div>


    <div class="contents input-form type1">
        <ul class="input-form__list">
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">제목</div>
                    <div class="cont">선재업고튀어</div>
                </div>
            </li>
            <li class="input-form__item item2">
                <div class="input-form__box">
                    <div class="tit">진행상황</div>
                    <div class="cont">승인완료</div>
                </div>
                <div class="input-form__box">
                    <div class="tit">등록일자</div>
                    <div class="cont">2024.05.07</div>
                </div>
            </li>
            <li class="input-form__item item2">
                <div class="input-form__box">
                    <div class="tit">칭찬 직원(의료진)</div>
                    <div class="cont">이준용 교수님</div>
                </div>
                <div class="input-form__box">
                    <div class="tit">부서/병동</div>
                    <div class="cont">신경외과</div>
                </div>
            </li>
            <li class="input-form__item desc">
                <div class="input-form__box">
                    <div class="tit">내용</div>
                    <div class="cont desc">
                        교수님 덕분에 상태가 많이 호전되었습니다. <br>
                        늘 따스하게 맞아 주시고 웃으면서 대해 주셔서 진심으로 감사드립니다.

                    </div>
                </div>
            </li>
            <li class="input-form__item desc">
                <div class="input-form__box">
                    <div class="tit">답변</div>
                    <div class="cont">
                        <textarea name="" id=""></textarea>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="contents btn-wrap center">
        <a href="javascript:;" class="btn red">삭제</a>
        <a href="javascript:;" class="btn gray">목록</a>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>