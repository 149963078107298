<template>
    <!-- 호스피스완화센터 -->
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
           <li class="tab-menu__item" v-for="(tab, index) in tabs" :key="index" :class="{ on: selectedTab === index }"  @click="selectTab(index)">
                <button type="button" class="tab-menu__btn">{{ tab }}</button>
            </li>
        </ul>
    </div>
     <div class="btn-wrap contents right">
        <router-link to="/hospital/department-doctor/centerClinic.do" class="btn mint">
        <img src="/resource/icon/depart_potal_icon.png" alt="이미지">전체 센터&클리닉</router-link>
    </div>
    <div class="info-text contents" v-if="selectedTab === 0">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">호스피스 완화의료의 정의</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>호스피스․완화의료는 치료에 반응하지 않는 환자와 그 가족들이 남은 생애동안 질적인 삶을 유지 할 수 있도록 신체적, 사회적 영적인 문제를 해결 또는 상담하고, 사별간호를 포함한 아름다운 마무리를 돕고자 각 분야의 전문가와 자원봉사자가 함께하는 전인적인 돌봄을 말합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">운영철학</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>모든 인간을 존엄한 존재로서 존중하며 대상자의 생의 마지막 과정에서 경험하게 되는 신체적, 정신적, 영적, 사회적 및 경제적 고통을 함께 나눌 수 있도록 돕는다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>죽음을 삶의 한 과정으로 받아들이고 삶을 연장시키거나 단축시키지 않으며 마지막 순간까지 가족처럼 섬기고 돌본다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>환자 개인의 종교를 존중하며, 영원한 삶을 평안하게 준비하도록 돕는다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">운영원칙</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>전문 의료인들의 선도적인 연구와 협력을 통해 의료의 질을 유지하며, 새로운 의학분야의 발전을 도모하는 대표적인 의료기관이 된다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>가톨릭교회의 정신을 본받아 인간생명의 존엄성이 진료활동 전반에 걸쳐 존중되도록 하고, 특히 가난하고 소외된 이들에게 각별한 관심을 가지고 돌보며, 병을 치유하시는 분이 하느님이심을 알게 한다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>이를 위해 우리는 각자 맡은 분야에서 전문성을 기르고 존중하며, 다학제간 협력정신을 바탕으로 최상의 의료서비스를 제공한다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text contents" v-if="selectedTab === 1">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">호스피스 완화의료팀</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text hospis_img_wrap contents">
                        <img src="/resource/img/hospis_team_pc.png" alt="호스피스완화의료팀" class="hosipis_team_pc">
                         <img src="/resource/img/hospis_team_mo.png" alt="호스피스완화의료팀" class="hosipis_team_mo">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text contents" v-if="selectedTab === 2">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">상담 및 입원 안내</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">입원대상</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>말기암환자</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">본원 입원절차</h4>
                    </div>
                </div>
                 <div class="depth__wrap depth02 step-box_wrap">
                      <ul class="step-box">
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step01.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">주치의<br>호스피스 의뢰</h4>
                                    <p class="step-box__text">Step01</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step02.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">호스피스<br>의사동의</h4>
                                    <p class="step-box__text">Step02</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step03.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">호스피스 병동<br>면담 및 안내</h4>
                                    <p class="step-box__text">Step03</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step04.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">입원</h4>
                                    <p class="step-box__text">Step04</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="depth__wrap info-reference step_bottom">
                    <div class="depth__bullet">※</div>
                    <div class="depth__text">
                        <h4 class="depth03__title">TEL. 270-1650(문의사항에 대해 24시간 안내해 드립니다.)</h4>
                    </div>
                </div>

                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">타기관 입원절차</h4>
                    </div>
                </div>
                 <div class="depth__wrap depth02 step-box_wrap">
                      <ul class="step-box">
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step05.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">타기관 주치의<br>소견서</h4>
                                    <p class="step-box__text">Step01</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step06.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">본원 호스피스<br>외래방문<span>(혈액종양내과)</span></h4>
                                    <p class="step-box__text">Step02</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step02.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">호스피스<br>의사동의</h4>
                                    <p class="step-box__text">Step03</p>
                                </div>
                            </div>
                        </li>
                        <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step03.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">호스피스 병동<br>면담 및 안내</h4>
                                    <p class="step-box__text">Step04</p>
                                </div>
                            </div>
                        </li>
                         <li class="step-box__item">
                            <div class="step-box__item-inner">
                                <div class="step-box__img">
                                    <img src="/resource/icon/hospis_clinic-step04.png" alt="이미지">
                                </div>
                                <div class="step-box__text">
                                    <h4 class="depth02__title">입원</h4>
                                    <p class="step-box__text">Step05</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">상담 및 입원서류 준비</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>말기환자임을 나타내는 의사소견서(진료의뢰서)</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>의무기록사본 : 최근 검사결과(혈액검사, MRI 판독 등), 최근 약 처방 내역</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>최근 촬영한 MRI 등 영상검사 CD</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">호스피스 완화의료 서비스 안내</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>포괄적인 초기평가 및 돌봄계획수립과 상담</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>통증 및 신체증상 완화</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심리, 사회, 영적지지 및 상담</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>음악, 미술요법 외 프로그램 </p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>임종 준비 및 돌봄 지원</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>사별가족 돌봄 서비스</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text contents" v-if="selectedTab === 3">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">프로그램</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">경락요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>손이나 인체 각 부위를 사용하여 쓰다듬고 주무르며 마사지를 통해  피로 및 혈액순환 개선으로 부종이 완화되며, 스트레스 감소되어 심신의 안녕감 증대에 도움을 줍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">음악요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>음악을 듣고 신체와 정신을 회복, 유지하며, 기운을 향상시키는 방법으로 마음의 안도감과 감정적 스트레스를 완화시키는 활동입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">미술요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>오랜 투병으로 지친 환자에게 자신의 마음을 표현할 수 있도록 하고, 힘든 느낌, 생각들을 미술활동을 통해 표출함으로써 마음의 안정에 도움을 줍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">독서요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>책을 통해 정신적 건강을 증진시키는 치료방법으로 환자 스스로 자신의 문제를 파악하고 해결하는데 도움이 되고, 유익한 도서를 매개체로 내재된 생각이나 심리적 질병 치료에 도움을 줍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">아로마요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>식물의 향과 약효를 이용해서 몸과 마음의 균형을 회복시켜 면역력을 개선시키고, 환자의 불안, 우울감, 수면증진, 생리적 스트레스 반응 완화에 도움을 줍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">원예요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>꽃을 피우고 식물을 키우는 활동을 통해 몸과 마음의 병을 치유하고 식물을 보고 좋은 향기를 맡으며 정서적 안정을 줍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">다도요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>다양한 종류의 차를 마시면서 자유로운 주제로 이야기를 나누고 공감을 통해 마음을 치유 받을 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">전환요법</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>그림과 단어가 표시되어 있는 감정카드를 통해 자신의 감정을 지각하고, 내재된 마음 표현을 통한 상담기법을 접목할 수 있습니다. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text contents" v-if="selectedTab === 4">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">주요시설</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <ul class="hospice_list">
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice01.png" alt="상담실">
                                        <p>상담실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice02.png" alt="병실">
                                        <p>병실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice03.png" alt="가족실">
                                        <p>가족실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice04.png" alt="목욕실">
                                        <p>목욕실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice05.png" alt="요셉실">
                                        <p>요셉실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice06.png" alt="기도실">
                                        <p>기도실</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice07.png" alt="옥외정원">
                                        <p>옥외정원</p>
                                    </li>
                                    <li class="hospice_item">
                                        <img src="/resource/img/hospice08.png" alt="옥외정원">
                                        <p>옥외정원</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <!-- <div class="btn-wrap center contents">
        <a href="javascript:;" class="btn blue">센터/클리닉 전체</a>
    </div> -->
</template>


<script>
    export default {
        data() {
            return {
                tabs: [
                    '호스피스 완화의료의 정의',
                    '호스피스완화의료팀',
                    '상담 및 입원안내',
                    '프로그램',
                    '주요시설'
                ],
                selectedTab: 0
            };
        },
        methods: {
            selectTab(index) {
                this.selectedTab = index;
            }
        }
    };
</script>