<template>
    <div>
        <div class="contact-box">
            <div class="contact-box__wrap">
                <h3>통합컨택센터</h3>
                <div class="box">
                    <div class="item">
                        <div class="icon"><img src="@/assets/resource/icon/contact-icon01.png" alt="이미지"></div>
                        <div class="text">
                            <h4>예약·접수·상담</h4>
                            <a href="javascript:;" class="link">270-1000 <span class="arrow-blue"></span></a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon"><img src="@/assets/resource/icon/contact-icon02.png" alt="이미지"></div>
                        <div class="text">
                            <h4>FAX</h4>
                            <a href="javascript:;" class="link">265-9393 <span class="arrow-blue"></span></a>
                        </div>
                    </div>
                </div>
                <p class="room-num">병실 270-1***(병실번호)</p>
            </div>
            <ul class="contact-box__list">
                <li class="contact-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/contact-icon03.png" alt="이미지"></div>
                    <div class="text">
                        <h4>응급의료센터</h4>
                        <a href="javascript:;" class="link">270-1119 <span class="arrow-blue"></span></a>
                    </div>
                </li>
                <li class="contact-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/contact-icon04.png" alt="이미지"></div>
                    <div class="text">
                        <h4>건강증진센터</h4>
                        <a href="javascript:;" class="link">270-1200 <span class="arrow-blue"></span></a>
                    </div>
                </li>
                <li class="contact-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/contact-icon05.png" alt="이미지"></div>
                    <div class="text">
                        <h4>직업환경의학센터</h4>
                        <a href="javascript:;" class="link">270-1800 <span class="arrow-blue"></span></a>
                    </div>
                </li>
                <li class="contact-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/contact-icon06.png" alt="이미지"></div>
                    <div class="text">
                        <h4>진료협력센터</h4>
                        <a href="javascript:;" class="link">270-1108 <span class="arrow-blue"></span></a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- 진료과 -->
        <div class="contents table-wrap">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">진료과</h4>
                </div>
            </div>

            <div class="table-cont">
                <ul class="table-list">
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item">
                        <div class="item">소화기내과</div>
                        <div class="item">270-1282, 270-1288</div>
                    </li>
                    <li class="table-item">
                        <div class="item">호흡기내과</div>
                        <div class="item">1287</div>
                    </li>
                    <li class="table-item">
                        <div class="item">심장내과</div>
                        <div class="item">1280, 1283</div>
                    </li>
                    <li class="table-item">
                        <div class="item">내분비대사내과</div>
                        <div class="item">1285, 1286</div>
                    </li>
                    <li class="table-item">
                        <div class="item">혈액종양내과</div>
                        <div class="item">1281</div>
                    </li>
                    <li class="table-item">
                        <div class="item">신장내과</div>
                        <div class="item">1268</div>
                    </li>
                    <li class="table-item">
                        <div class="item">류마티스내과</div>
                        <div class="item">1035</div>
                    </li>
                    <li class="table-item">
                        <div class="item">감염내과</div>
                        <div class="item">1284</div>
                    </li>
                    <li class="table-item">
                        <div class="item">소아청소년과</div>
                        <div class="item">1219, 1220</div>
                    </li>
                    <li class="table-item">
                        <div class="item">신경과</div>
                        <div class="item">1589, 1590</div>
                    </li>
                    <li class="table-item">
                        <div class="item">피부과</div>
                        <div class="item">1300</div>
                    </li>
                    <li class="table-item">
                        <div class="item">외과</div>
                        <div class="item">1240</div>
                    </li>
                    <li class="table-item">
                        <div class="item">흉부외과</div>
                        <div class="item">1295</div>
                    </li>
                    <li class="table-item">
                        <div class="item">정형외과</div>
                        <div class="item">1190</div>
                    </li>
                    <li class="table-item">
                        <div class="item">신경외과</div>
                        <div class="item">1179, 1180</div>
                    </li>
                    <li class="table-item">
                        <div class="item">성형외과</div>
                        <div class="item">1290</div>
                    </li>
                    <li class="table-item">
                        <div class="item">산부인과</div>
                        <div class="item">1229, 1230</div>
                    </li>
                    <li class="table-item">
                        <div class="item">안과</div>
                        <div class="item">1270</div>
                    </li>
                    <li class="table-item">
                        <div class="item">이비인후과</div>
                        <div class="item">1275</div>
                    </li>
                    <li class="table-item">
                        <div class="item">비뇨기과</div>
                        <div class="item">1210</div>
                    </li>
                    <li class="table-item">
                        <div class="item">재활의학과</div>
                        <div class="item">1215</div>
                    </li>
                    <li class="table-item">
                        <div class="item">마취통증의학과(통증치료실)</div>
                        <div class="item">1315</div>
                    </li>
                    <li class="table-item">
                        <div class="item">치과</div>
                        <div class="item">1090</div>
                    </li>
                    <li class="table-item">
                        <div class="item">병리과</div>
                        <div class="item">1360</div>
                    </li>
                    <li class="table-item">
                        <div class="item">진담검사의학과</div>
                        <div class="item">1350</div>
                    </li>
                    <li class="table-item">
                        <div class="item">영상의학과</div>
                        <div class="item">1150</div>
                    </li>
                    <li class="table-item">
                        <div class="item">정신건강의학과</div>
                        <div class="item">1580</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 진료지원 -->
        <div class="contents table-wrap">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">진료지원</h4>
                </div>
            </div>

            <div class="table-cont">
                <ul class="table-list">
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item">
                        <div class="item">재활치료센터</div>
                        <div class="item">1571</div>
                    </li>
                    <li class="table-item">
                        <div class="item">인공신장센터</div>
                        <div class="item">1450</div>
                    </li>
                    <li class="table-item">
                        <div class="item">소화기내시경센터</div>
                        <div class="item">1370</div>
                    </li>
                    <li class="table-item">
                        <div class="item">호스피스완화의료센터</div>
                        <div class="item">1650</div>
                    </li>
                    <li class="table-item">
                        <div class="item">결핵관리실</div>
                        <div class="item">1482</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 원무과 -->
        <div class="contents table-wrap">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">원무과</h4>
                </div>
            </div>

            <div class="table-cont">
                <ul class="table-list">
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item">
                        <div class="item">외래 접수∙수납</div>
                        <div class="item">1102, 1103 <br>
                            1112, 1113</div>
                    </li>
                    <li class="table-item">
                        <div class="item">응급 접수∙수납</div>
                        <div class="item">1124, 1125</div>
                    </li>
                    <li class="table-item">
                        <div class="item">입원</div>
                        <div class="item">1105</div>
                    </li>
                    <li class="table-item">
                        <div class="item">퇴원</div>
                        <div class="item">1106</div>
                    </li>
                    <li class="table-item">
                        <div class="item">제증명/의무기록</div>
                        <div class="item">1104</div>
                    </li>
                    <li class="table-item">
                        <div class="item">산업재해</div>
                        <div class="item">1111</div>
                    </li>
                    <li class="table-item">
                        <div class="item">자동차보험</div>
                        <div class="item">1110</div>
                    </li>
                    <li class="table-item">
                        <div class="item">검사통합예약</div>
                        <div class="item">1936, 1937</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 기타 -->
        <div class="contents table-wrap">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">기타</h4>
                </div>
            </div>

            <div class="table-cont">
                <ul class="table-list">
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item tit">
                        <div class="item">부서</div>
                        <div class="item">전화번호</div>
                    </li>
                    <li class="table-item">
                        <div class="item">사회복지과</div>
                        <div class="item">1115</div>
                    </li>
                    <li class="table-item">
                        <div class="item">원목실</div>
                        <div class="item">1004</div>
                    </li>
                    <li class="table-item">
                        <div class="item">자원봉사자실</div>
                        <div class="item">1016</div>
                    </li>
                    <li class="table-item">
                        <div class="item">장례식장</div>
                        <div class="item">1900, 1949</div>
                    </li>
                    <li class="table-item">
                        <div class="item">안내</div>
                        <div class="item">1080</div>
                    </li>

                </ul>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        methods: {
        }
    };
</script>