<template>
    <div class="contents">
        <Search :searchArr="searchArr" @setState="search"></Search>
        <div class="re-search__result contents">
            <p>총 <span class="c-blue">{{totCnt}}</span>건의 글이 검색되었습니다.</p>
        </div>
        <div class="table-wrap table-type1 search-table">
            <table>
                <caption class="blind">표</caption>
                <colgroup>
                    <col style="width:25%;">
                    <col style="width:50%;">
                    <col style="width:25%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>의료기관명</th>
                        <th>주소</th>
                        <th>전화번호</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list" :key="item">
                        <td>{{item.coophospnm}}</td>
                        <td>{{item.zipcdaddr}}</td>
                        <td>{{item.telno}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
    </div>
</template>

<script>
import Search from '@/components/common/SearchBox.vue';
import Pagination from '@/components/common/Pagination.vue';
export default {
    components: {
        Search,
        Pagination
    },
    data() {
        return {
            searchArr: [
                { 'title': '의료기관명', 'value': 'name' },
                { 'title': '주소', 'value': 'address' },
            ],
            orgList: [],
            list: [],

            pageList: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 10,
            //검색 관련 parameter
            name: this.$route.query.name || '',
            address: this.$route.query.address || '',
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);  
            }
            // 동일 게시판 안에서 이동
            if (to.query != from.query && to.path == from.path) {
                if (Object.keys(to.query).length === 0) this.init()
                for (let i in this.$route.query) {
                    this[i] = this.$route.query[i]
                }

                this.sliceList()
            }
        }
    },
    methods: {
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        },
        getList(){
            this.axios
                .get(this.api.location.partnerLocations)
                .then(res => {
                    const arr = []
                    for(let i of res.data){
                        if(!i.msg) arr.push(i)
                    }
                    this.orgList = arr
                    this.list = arr
                    this.totCnt = res.data.length
                    this.sliceList()
                })
        },
        sliceList(){
            const start = (this.currentNum - 1) * 10
            const end = this.currentNum * 10
            let obj = ''
            if(this.name) obj = {key: 'coophospnm', value: this.name}
            if(this.address) obj = {key: 'zipcdaddr', value: this.address}
            
            if(obj) {
                this.list = this.orgList.filter(i => i[obj.key].indexOf(obj.value) > -1)
            }else {
                this.list = this.orgList
            }
            this.totCnt = this.list.length
            this.list = this.list.slice(start, end)
        },
        // 검색 emit
        search(s, v, p) {
            this[p] = ''
            this.currentNum = 1
            this[s] = v
            if(!v) this.init()
            this.goPage()
        },
        // pagination emit
        setNum(n) {
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = { currentNum: this.currentNum, name: this.name, address: this.address };
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj });
        },
        // 데이터 초기화
        init() {
            this.currentNum = 1
            this.name = ''
            this.address = ''
        }
    },
    mounted() {
        this.getList()
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>