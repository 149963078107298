<template>
  <div class="healthCheckupReserve">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">단체건강검진 절차</h4>
        </div>
      </div>
      <ul class="step-textbox type02 center_type02">
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">계약체결</h3>
              <!-- <img
                src="/resource/img/hospital-qut-step01.png"
                class="step-textbox__title--icon"
                alt="이미지"
              /> -->
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">건강검진 예약</h3>
              <!-- <img
                src="/resource/img/clinic-step01.png"
                class="step-textbox__title--icon" alt="이미지"/> -->
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">건강검진 실시</h3>
              <!-- <img
                src="/resource/img/clinic-step02.png"
                class="step-textbox__title--icon"
                alt="이미지"
              /> -->
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">건강검진 결과확인</h3>
              <!-- <img
                src="/resource/img/clinic-step04.png"
                class="step-textbox__title--icon"
                alt="이미지"
              /> -->
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01">
              <div class="depth__bullet">
                  <span class="bullet01"></span>
              </div>
              <div class="depth__text">
                  <h4 class="depth01__title title">단체건강검진 협의사항</h4>
                  <div class="depth__wrap depth02">
                      <div class="depth__text">
                          <div class="depth__wrap depth03">
                              <div class="depth__text">
                                  <div class="depth__wrap">
                                      <div class="depth__bullet">
                                          <span class="bullet03"></span>
                                      </div>
                                      <div class="depth__text">
                                          <p>협약기준 : 일정인원 이상의 임직원 대상 기업 및 단체</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="depth__wrap depth02">
                      <div class="depth__text">
                          <div class="depth__wrap depth03">
                              <div class="depth__text">
                                  <div class="depth__wrap">
                                      <div class="depth__bullet">
                                          <span class="bullet03"></span>
                                      </div>
                                      <div class="depth__text">
                                          <p>협의내용 : 인원, 일정, 건강검진 Package 및 추가검사항목의 내용, 검진비용, 청구 및 결제방법 등 </p>
                                      </div> 
                                  </div>
                              </div>
                          </div>
                          <div class="depth__wrap depth03">
                              <div class="depth__text">
                                  <div class="depth__wrap">
                                      <div class="depth__bullet">
                                          <span class="depth__bullet" style="color:#777;">※</span>
                                      </div>
                                      <div class="depth__text">
                                          <p style="color:#777;">최소 건강검진 실시 1개월 전 사전협의 필요</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div class="depth__wrap depth03 mt10">
                              <div class="depth__text">
                                  <div class="depth__wrap">
                                      <div class="depth__bullet">
                                          <span class="bullet03"></span>
                                      </div>
                                      <div class="depth__text">
                                          <p>전화 : 055)270-1206 , 1207</p>
                                      </div> 
                                  </div>
                              </div>
                          </div>
                            <div class="depth__wrap depth03">
                              <div class="depth__text">
                                  <div class="depth__wrap">
                                      <div class="depth__bullet">
                                          <span class="bullet03"></span>
                                      </div>
                                      <div class="depth__text">
                                          <p>문의 : health@fatimahosp.co.kr</p>
                                      </div> 
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01">
          <div class="depth__bullet">
              <span class="bullet01"></span>
          </div>
          <div class="depth__text">
              <h4 class="depth01__title title">사후관리</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <div class="depth__wrap depth03">
                          <div class="depth__text">
                              <div class="depth__wrap">
                                  <div class="depth__bullet">
                                      <span class="bullet03"></span>
                                  </div>
                                  <div class="depth__text">
                                      <p>건강검진 실시 후 유소견 발견 즉시 외래진료를 연계하고 있습니다.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <div class="depth__wrap depth03">
                          <div class="depth__text">
                              <div class="depth__wrap">
                                  <div class="depth__bullet">
                                      <span class="bullet03"></span>
                                  </div>
                                  <div class="depth__text">
                                      <p>필요 시 국내 대형병원 및 유수의 대학병원으로 신속한 진료의뢰를 해드립니다.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <div class="depth__wrap depth03">
                          <div class="depth__text">
                              <div class="depth__wrap">
                                  <div class="depth__bullet">
                                      <span class="bullet03"></span>
                                  </div>
                                  <div class="depth__text">
                                      <p>수진자가 원하는 경우 전문의와의 결과 상담을 실시하고 있습니다.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {};
</script>

