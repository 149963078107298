<template>
    <div class="translate_con">
        <div class="text-box type1 top-title">
            <img src="/resource/icon/parking_iconpng.png" alt="이미지">
            <h3 class="depth02__title">창원파티마병원에서는 보다 편리한 입·출차와 정산을 위해 차량번호 인식시스템을 운영 중입니다.<br>무료 주차 차량의 경우 미리 차량번호를 등록하면 빠른 출차가 가능합니다.</h3>
        </div>
        <div class="contents table_font_777">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">무료주차</h4>
                </div>
            </div>
            <div class="table-wrap table-type1 scroll">
                <div class="">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                            <col style="width:33.333%;">
                            <col style="width:33.333%;">
                            <col style="width:33.333%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>대상</th>
                                <th>무료주차시간</th>
                                <th>차량등록처</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>진료,검사,검진</td>
                                <td>완료 시 까지</td>
                                <td rowspan="3">진료과 접수데스크<br>중앙안내 진료의뢰센터</td>
                            </tr>
                            <tr>
                                <td>입, 퇴원</td> 
                                <td>입, 퇴원 당일</td>
                            </tr>
                            <tr>
                                <td>중환자실</td>
                                <td>중환자실 입원기간</td>
                            </tr>
                            <tr>
                                <td>단시간 입차</td>
                                <td>10분</td>
                                <td>자동무료출차</td>
                            </tr>
                            <tr>
                                <td>공무</td>
                                <td>2시간</td>
                                <td>중앙안내</td>
                            </tr>
                            <tr>
                                <td>장례식장 유족</td>
                                <td>출상 시 까지</td>
                                <td>장례식장</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <p class="color_777">※ 환자 및 보호자는 등록된 차량 1대에 한해 적용 됩니다.</p>
        </div>
        <div class="contents">
           <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">주차요금</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet">
                                            <span class="bullet03"></span>
                                        </div>
                                        <div class="depth__text">
                                            <p>최초 30분 : 600원, 30분 초과 15분마다 : 300원</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet">
                                            <span class="bullet03"></span>
                                        </div>
                                        <div class="depth__text">
                                            <p>1일 최대요금 : 7,000원</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="depth__wrap info-reference">
                        <div class="depth__bullet">※</div>
                        <div class="depth__text">
                            <h4 class="depth03__title">호스피스 봉사 : 호스피스 자원봉사자 교육 수료증 소지자</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents table_font_777">
             <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">주차요금</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__text">
                                            <p>최초 30분 : 600원, 30분 초과 15분마다 : 300원</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-wrap table-type1 scroll mt25">
                <div class="">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                            <col style="width:25%;">
                            <col style="width:25%;">
                            <col style="width:25%;">
                            <col style="width:25%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>대상</th>
                                <th>무료주차시간</th>
                                <th>1개월권</th>
                                <th>비고(판매처)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>금액</td>
                                <td>30,000원</td>
                                <td>70,000원</td>
                                <td>원무과 사무실 : 270-1018<br>주차 정산소 : 270-1996</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        mounted() {
            document.addEventListener("DOMContentLoaded", function() {
                var element = document.querySelector(".table-wrap.table-type1.scroll");     
                if (element) {
                    element.addEventListener("click", function() {
                
                    element.classList.add("on");
                    });
                }
            });
            
        }
    }
</script>