<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">혈압</button></li>
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">혈당</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">체질량</button></li>
        </ul>
    </div>

    <div class="date_wrap contents">
        <div class="time-input">
            <TimePicker v-model="time" format="HH:mm" minute-step="1" is24/>
            <img src="@/assets/resource/icon/time_icon.png" alt="아이콘">
        </div>
        <div class="date-input">
            <VueDatePicker v-model="date" locale="ko" :enable-time-picker="false" :format="customFormat"/>
            <img src="@/assets/resource/icon/date_icon.png" alt="아이콘">
        </div>
    </div>

    <div class="measure_wrap blood_sugar_box">
        <ul class="measure_input_list">
            <li class="measure_input_item measure_it01">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/measure_icon04.png" alt="이미지">
                        <h3>현재상태</h3>
                    </div>
                    <div class="agree-input">
                        <label for="before-meal" class="agree-input__item">
                            <input type="radio" id="before-meal" class="agree-input__radio" name="mealTime" v-model="mealTime" value="식전">
                            <span class="icon"></span> 식전 
                        </label>
                        <label for="after-meal" class="agree-input__item">
                            <input type="radio" id="after-meal" class="agree-input__radio" name="mealTime" v-model="mealTime" value="식후">
                            <span class="icon"></span> 식후 
                        </label>
                    </div>
                </div>
            </li>
            <li class="measure_input_item measure_it02">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/measure_icon05.png" alt="이미지">
                        <h3>혈당수치</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="bloodSugar" @input="validateInput" class="cont__text-input">
                        <span>mg/dL</span>
                    </div>
                </div>
            </li>
        </ul>

        <div class="btn-wrap center contents">
            <a href="javascript:;" class="btn blue" @click="calculateResult">측정하기</a>
        </div>
    </div>

    <div class="contents" v-if="!result">
        <div class="text-box type1 info-text">
            <h3 class="depth02__title title">혈당 측정 방법</h3>
            <ul class="text-box__list">
                <li class="text-box__item">혈당 측당 전 채혈이 잘 되도록 충분한 손마사지를 해줍니다. </li>
                <li class="text-box__item">검사 전 따뜻한 물로 손을 씻어줍니다.</li>
                <li class="text-box__item">채혈할 손을 알코올로 소독 후 완전히 말린 후 혈당을 측정합니다.</li>
                <li class="text-box__item">매번 채혈부위를 변경하여 채혈합니다.</li>
                <li class="text-box__item">검사 결과가 정상보다 너무 낮게 나오면 다시 측정합니다.</li>
                <li class="text-box__item">병원에서의 혈청 검사와 가정에서 혈당 검사는 약 12%정도 낮게 측정되며, 10~15% 오차가 발생할 수 있습니다.</li>
            </ul>
        </div>
    </div>

    <div class="measure_result_wrap contents" v-if="result">
        <div class="measure_result_box">
            <div class="measure_result_con">
                <img src="@/assets/resource/icon/measure_icon03.png" alt="이미지">
                <p>현재 {{ mealTime }} 혈당 상태는&nbsp;<span class="blue__title">'{{ result }}'</span>&nbsp;입니다.</p>
            </div>
        </div>
    </div>

    <div class="contents" v-if="result">
        <div class="table-wrap table-type1">
            <div class="scroll">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width:33.333%;">
                        <col style="width:33.333%;">
                        <col style="width:33.333%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>혈당분류</th>
                            <th>식전혈당</th>
                            <th>식후혈당</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>저혈당</td>
                            <td>70mg/dL 미만</td>
                            <td>70mg/dL 미만</td>
                        </tr>
                        <tr>
                            <td>정상</td>
                            <td>70~100mg/dL</td>
                            <td>70~140mg/dL</td>
                        </tr>
                        <tr>
                            <td>조절필요</td>
                            <td>100~124mg/dL</td>
                            <td>140~200mg/dL</td>
                        </tr>
                        <tr>
                            <td>관리필요</td>
                            <td>125mg/dL 이상</td>
                            <td>200mg/dL 이상</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="btn-wrap center contents" v-if="result">
        <a href="javascript:;" class="btn">결과저장</a>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import TimePicker from 'vue3-timepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {
    components: {
        TimePicker,
        VueDatePicker
    },
    data() {
        return {
            result: '',
            bloodSugar: '',
            mealTime: '식전',
            customFormat: 'yyyy.MM.dd'
        };
    },
    setup() {
        const time = ref(null);
        const date = ref(null);
        
        return { time, date };
    },
    watch: {
        mealTime() {
            if (this.result) {
                this.calculateResult();
            }
        }
    },
    methods: {
        validateInput(event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3);
            this.bloodSugar = input;
        },
        calculateResult() {
            const bloodSugar = parseInt(this.bloodSugar);

            if (!this.bloodSugar) {
                alert("혈당 수치를 입력해 주세요");
                return;
            }

            if (this.mealTime === '식전') {
                if (bloodSugar < 70) {
                    this.result = '저혈당';
                } else if (bloodSugar >= 70 && bloodSugar <= 100) {
                    this.result = '정상';
                } else if (bloodSugar > 100 && bloodSugar <= 124) {
                    this.result = '조절필요';
                } else if (bloodSugar >= 125) {
                    this.result = '관리필요';
                }
            } else if (this.mealTime === '식후') {
                if (bloodSugar < 70) {
                    this.result = '저혈당';
                } else if (bloodSugar >= 70 && bloodSugar <= 140) {
                    this.result = '정상';
                } else if (bloodSugar > 140 && bloodSugar <= 200) {
                    this.result = '조절필요';
                } else if (bloodSugar > 200) {
                    this.result = '관리필요';
                }
            } else {
                this.result = '잘못된 값';
            }
        }
    }
};
</script>
