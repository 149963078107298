<template>
    <!-- 피부과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>피부과는 외부자극으로부터 보호하는 피부 및 피부 부속기관(모발, 손, 발톱)의 질환의 진단 및 치료를 담당하는 진료과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>가려움증, 피부발진 등 일반피부질환, 손발톱질환, 탈모증 등 모발질환, 아토피피부염, 여드름, 색소질환, 냉동치료, 스케일링, 레이저치료, 피부 불편감 및 변화 등 상담, 약진</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 피부과에서는 피부질환의 정확한 진단을 위해서 우드램프 및 진단용 확대경을 이용한 진료를 시행하고 있으며, 필요 시 조직검사를 통해 피부암 등을 조기에 발견할 수 있도록 진료하고 있습니다.</p>
                                <p>그 외에도 혈액 검사를 통한 알레르기클리닉를 비롯해 탈모클리닉, 손발톱클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>