<template>
  <div class="healthCheckupReserve">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">예약자정보</h4>
          <div class="depth__wrap depth02">
            <div class="depth__text">
              <div class="depth__wrap">
                <div class="depth__text">
                  <p>
                    수신자의 정보가 정확하지 않을 경우 정상적으로 예약이 되지 않을 수
                    있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-form type1 mt40">
        <ul class="input-form__list">
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">이름</div>
              <div class="cont">
                <p>이준용</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">생년월일</div>
              <div class="cont">
                <p>1999.01.01</p>
              </div>
            </div>
          </li>
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">성별</div>
              <div class="cont">
                <p>남성</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">연락처</div>
              <div class="cont">
                <p>010-000-0000</p>
              </div>
            </div>
          </li>
        </ul>
        <p class="input_additional">※ 개인정보수정은 병원 홈페이지에서 가능합니다.</p>
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01 mb20">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">안내사항</h4>
          <div class="depth__wrap depth02">
            <div class="depth__text">
              <div class="depth__wrap">
                <div class="depth__text">
                  <p>
                    검사인원에 제한이 있는 검사(대장내시경, CT, 유방초음파,PET-CT, MRI
                    등)를 선택하실 경우, 직원과 상담 후 일정이 변경될 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info__con">
        <div class="info__right">
          <ul class="accordion">
            <!-- Accordion Item 1 -->
            <li :class="['accordion__item', { active: isAccordion1Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(1)">
                <div class="title">
                  <h3>STEP01.</h3>
                  <sub class="text">건강검진 유형 선택</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion1Style" ref="accordion1Content">
                <div class="accordion__con">
                  <div class="accordion__con--text">
                    <h3 class="depth02__title title">생애주기별프로그램</h3>
                    <div class="price_btn_wrap">
                      <button class="price_btn" @click="toggleActive($event)">
                        <p>그린코스</p>
                        <p>430,000원</p>
                      </button>
                      <button class="price_btn" @click="toggleActive($event)">
                        <p>블루코스</p>
                        <p>320,000원</p>
                      </button>
                      <button class="price_btn" @click="toggleActive($event)">
                        <p>골드코스</p>
                        <p>920,000원</p>
                      </button>
                      <button class="price_btn" @click="toggleActive($event)">
                        <p>실버코스</p>
                        <p>1,400,000</p>
                      </button>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">질환별 정밀 프로그램</h3>
                      <div class="price_btn_wrap">
                        <button
                          class="price_btn menProgram"
                          @click="toggleActive($event)"
                         >
                          <p>남성정밀코스</p>
                          <p>750,000원</p>
                        </button>
                        <button
                          class="price_btn womenProgram"
                          @click="toggleActive($event)"
                         >
                          <p>여성정밀A코스</p>
                          <p>950,000원</p>
                        </button>
                        <button
                          class="price_btn womenProgram"
                          @click="toggleActive($event)"
                          >
                          <p>여성정밀B코스</p>
                          <p>780,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>소화기정밀코스</p>
                          <p>800,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>호흡기정밀코스</p>
                          <p>700,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>순환기정밀코스</p>
                          <p>850,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>뇌정밀코스</p>
                          <p>1.400,000원</p>
                        </button>
                      </div>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">암전문프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>조기암(PET-CT)검진</p>
                          <p>1,400,000원</p>
                        </button>
                      </div>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">프리미엄 프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>종합정밀 Ⅰ(당일)</p>
                          <p>1,990,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive($event)">
                          <p>종합정밀 Ⅱ(숙박)</p>
                          <p>2,950,000원</p>
                        </button>
                      </div>
                    </div>

                    <div class="btn-wrap center">
                      <a href="javascript:;" class="btn blue-line" @click="toggleAccordion(2)">선택완료</a>
                    </div>
                  </div>

                  <div class="contents">
                    <div class="program_info_btn_wrap">
                      <button @click="toggleProgramInfo">
                        선택한 프로그램 상세정보<span>▼</span>
                      </button>
                    </div>
                  </div>

                  <!-- 추가되는 프로그램 테이블 -->
                  <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
                    <h3 class="depth02__title title">남성정밀코스</h3>
                    <p class="desc_top">
                      남성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한
                      맞춤형 건강검진 프로그램입니다.
                    </p>
                    <p class="desc_bottom">
                      · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 :
                      750,000원
                    </p>
                    <div class="table-wrap table-type1">
                      <div class="scroll">
                        <table>
                          <caption class="blind">표</caption>
                          <colgroup>
                            <col style="width: 13%" />
                            <col style="width: 17%" />
                            <col style="width: 70%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th colspan="2">구분</th>
                              <th>내용</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td rowspan="14" style="text-align: center">공통</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">문진</td>
                              <td>건강상담</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                              <td>신체, 체중, 허리둘레, 비만도</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">
                                청력, 안과, 치과
                              </td>
                              <td>청력, 시력, 안압, 안저, 구강검사</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                              <td>
                                일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능,
                                지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B,
                                C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사,
                                정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암,
                                전립선암)
                              </td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">
                                대, 소변 검사
                              </td>
                              <td>대변(잠혈, 기생충), 소변</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                              <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                              <td>혈압검사, 심전도</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                              <td>복부 초음파</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                              <td>위내시경 또는 위장조영촬영 택1</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                              <td>대장내시경 또는 저선량 흉부 CT 택1</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                              <td>전립선초음파</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                              <td>남성호르몬</td>
                            </tr>
                            <tr>
                              <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                              <td>생체나이측정검사</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- 추가되는 프로그램 테이블 -->
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn mt40" @click="handleAccordionBottomBtnClick"></a>
            </li>

            <li :class="['accordion__item', { active: isAccordion2Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(2)">
                <div class="title">
                  <h3>STEP02.</h3>
                  <sub class="text">추가선택검사</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion2Style" ref="accordion2Content">
                <div class="accordion__con">
                  <div class="accordion__con--text">
                    <!-- 남성전용 필수 검사 택 -->
                    <div class="add_box_btn_wrap">
                      <h3 class="depth02__title title">
                        남성정밀코스<span class="red__title"
                          >&nbsp;&nbsp;필수검사 선택(택1)<img
                            src="/resource/icon/Triangle_red_icon.png"
                            alt="이미지"
                        /></span>
                      </h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" @click="toggleActive03($event)">
                          MD-CT (흉부)
                        </button>
                        <button class="price_btn" @click="toggleActive03($event)">
                          대장내시경
                        </button>
                      </div>
                    </div>
                    <!-- 남성전용 필수 검사 택 -->

                    <div class="contents">
                      <h3 class="depth02__title title">기본 포함 항목</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn basic_pick">
                          <p>남성호르몬검사</p>
                        </button>
                        <button class="price_btn basic_pick"><p>생채나이검사</p></button>
                        <button class="price_btn basic_pick"><p>전립선초음파</p></button>
                      </div>
                    </div>

                    <div class="contents">
                      <h3 class="depth02__title title">질환별 정밀 프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>MRI + MRA</p>
                          <p>800,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>MD-CT (흉부)</p>
                          <p>180,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>MD-CT(뇌)</p>
                          <p>180,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>MD-CT (복부/골반)</p>
                          <p>200,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>심장 CT (Ca)</p>
                          <p>150,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>유방초음파</p>
                          <p>160,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>경동맥초음파</p>
                          <p>120,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>뇌혈류초음파</p>
                          <p>190,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>갑상선초음파</p>
                          <p>120,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>심장초음파</p>
                          <p>180,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>전립선초음파</p>
                          <p>120,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>골밀도검사</p>
                          <p>70,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>운동부하검사</p>
                          <p>50,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>수면내시경</p>
                          <p>70,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>대장내시경</p>
                          <p>190,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>남성호르몬검사</p>
                          <p>50,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>여성호르몬검사</p>
                          <p>50,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>뇌심혈관계발병위험도</p>
                          <p>30,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>스트레스검사</p>
                          <p>30,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>동맥경화도검사</p>
                          <p>40,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>PET-CT(전신)</p>
                          <p>1,100,000원</p>
                        </button>
                        <button class="price_btn" @click="toggleActive02($event)">
                          <p>PET-CT(상반신)</p>
                          <p>750,000원</p>
                        </button>
                      </div>
                    </div>
                    <div class="contents pb60">
                      <div class="btn-wrap center">
                        <a href="javascript:;" class="btn" style="font-weight: 700" @click="toggleAccordion(3)">건너뛰기</a>
                        <a href="javascript:;" class="btn blue-line" @click="toggleAccordion(3)">선택완료</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn" @click="toggleAccordion(2)"></a>
            </li>

            <li :class="['accordion__item', { active: isAccordion3Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(3)">
                <div class="title">
                  <h3>STEP03.</h3>
                  <sub class="text">희망예약일 선택</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion3Style" ref="accordion3Content">
                <div class="accordion__con">
                  <div class="accordion__con--text calendar_wrap">

                    <div class="calendar">
                      <div class="calendar__header">
                        <button class="calendar__header--button prev"></button>
                        <h4 class="calendar__header--title">2022.08</h4>
                        <button class="calendar__header--button next"></button>
                      </div>
                      <div class="calendar__hcon">
                        <table>
                          <caption>
                            예약일 선택 테이블
                          </caption>
                          <colgroup>
                            <col width="14.29%" />
                            <col width="14.29%" />
                            <col width="14.29%" />
                            <col width="14.29%" />
                            <col width="14.29%" />
                            <col width="14.29%" />
                            <col width="14.29%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>일</th>
                              <th>월</th>
                              <th>화</th>
                              <th>수</th>
                              <th>목</th>
                              <th>금</th>
                              <th>토</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td class="today"><a href="javascript:;">1</a></td>
                              <td><a href="javascript:;">2</a></td>
                              <td class="pos active"><a href="javascript:;">3</a></td>
                              <td><a href="javascript:;">4</a></td>
                              <td class="pos"><a href="javascript:;">5</a></td>
                              <td><a href="javascript:;">6</a></td>
                            </tr>
                            <tr>
                              <td><a href="javascript:;">7</a></td>
                              <td><a href="javascript:;">8</a></td>
                              <td class="pos"><a href="javascript:;">9</a></td>
                              <td><a href="javascript:;">10</a></td>
                              <td><a href="javascript:;">11</a></td>
                              <td class="pos"><a href="javascript:;">12</a></td>
                              <td><a href="javascript:;">13</a></td>
                            </tr>
                            <tr>
                              <td><a href="javascript:;">14</a></td>
                              <td><a href="javascript:;">15</a></td>
                              <td class="pos"><a href="javascript:;">16</a></td>
                              <td><a href="javascript:;">17</a></td>
                              <td><a href="javascript:;">18</a></td>
                              <td class="pos"><a href="javascript:;">19</a></td>
                              <td><a href="javascript:;">20</a></td>
                            </tr>
                            <tr>
                              <td><a href="javascript:;">21</a></td>
                              <td><a href="javascript:;">22</a></td>
                              <td class="pos"><a href="javascript:;">23</a></td>
                              <td><a href="javascript:;">24</a></td>
                              <td><a href="javascript:;">25</a></td>
                              <td class="pos"><a href="javascript:;">26</a></td>
                              <td><a href="javascript:;">27</a></td>
                            </tr>
                            <tr>
                              <td><a href="javascript:;">28</a></td>
                              <td><a href="javascript:;">29</a></td>
                              <td class="pos"><a href="javascript:;">30</a></td>
                              <td><a href="javascript:;">31</a></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="calendar__bottom">
                        <p><span class="today"></span> 오늘</p>
                        <p><span class="active"></span> 선택</p>
                      </div>
                    </div>

                    <div class="calendar_line"></div>

                    <div class="calender_reserve">
                      <div class="calender_reserve_top">
                        <p>선택하신날짜</p>
                        <h3>2024년 6월 3일(수)</h3>
                      </div>
                      <div class="calender_reserve_bottom">
                        <h4>이용안내</h4>
                        <p>예약사항은 나의건강검진 > 예약정보에서 확인 또는 취소가 가능합니다.</p>
                        <p>예약상담은 전화 055)270-1200~1로 문의 주시기 바라며,<br>상담 가능 시간은 평일 09:00~17:00, 토요일은<br>09:00~12:00입니다.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn" @click="toggleAccordion(3)"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="contents">
      <div class="reserve_result_box">
          <div class="result_box_left">
             <h3>건강검진 프로그램 선택내역</h3>
              <ul class="result_desc_list">
                <li class="result_desc_item">
                    <h4>건강검진 프로그램 종류</h4>
                    <ul>
                      <li class="blue__title">남성정밀코스 : 850,000원</li>
                    </ul>
                </li>
                <li class="result_desc_item ml60">
                    <h4>추가검진 종류</h4>
                    <ul>
                      <li class="blue__title">PET-CT(상반신) : 14,000원</li>
                      <li class="blue__title">스트레스검사 : 25,000원</li>
                      <li class="blue__title">수면내시경 : 1,100,000 원</li>
                      <li class="blue__title">뇌혈관계발병위험도 : 30,000 원</li>
                    </ul>
                </li>
              </ul>
          </div>
          <div class="result_box_right">
              <p>총검사비용 : <span>1,530,000 원</span></p>
          </div>
      </div>
    </div>

     <div class="contents mt60">
          <div class="btn-wrap center">
            <a href="javascript:;" class="btn blue-line">예약하기</a>
            <a href="javascript:;" class="btn" style="font-weight: 700">취소</a>
          </div>
      </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: null,
      activeButton: null,
      activeButtonBasic: null,
      isProgramInfoActive: false,
      programInfoHeight: "0px",
      programInfoDisplay: "none",
      isAccordion1Active: "true",
      accordion1Height: "auto",
      accordion1Display: "block",
      isAccordion2Active: false,
      accordion2Height: "0px",
      accordion2Display: "none",
      isAccordion3Active: false,
      accordion3Height: "0px",
      accordion3Display: "none",
    };
  },
  watch: {
      $route(to, from){
        if(to.path != from.path){
          setTimeout(() => {
              let tableItems = document.querySelectorAll(".table-wrap");

              tableItems.forEach((el) => {
              this.checkTableWidth(el);
              });

              let tableItem = document.querySelectorAll(".table-wrap");

              tableItem.forEach((el) => {
                  el.addEventListener("click", () => {
                      el.classList.add('on');
                  });
              }); 
          }, 100);    
        }
      }
    },
  computed: {
    programInfoStyle() {
      return {
        height: this.programInfoHeight,
        display: this.programInfoDisplay,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion1Style() {
      return {
        height: this.accordion1Height,
        display: this.accordion1Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion2Style() {
      return {
        height: this.accordion2Height,
        display: this.accordion2Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion3Style() {
      return {
        height: this.accordion3Height,
        display: this.accordion3Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
  },
  methods: {
    toggleActive(event) {
      if (this.activeButton) {
        this.activeButton.classList.remove("active");
      }
      event.target.classList.add("active");
      this.activeButton = event.target;
    },
    toggleActive03(event) {
      if (this.activeButtonBasic) {
        this.activeButtonBasic.classList.remove("active");
      }
      event.target.classList.add("active");
      this.activeButtonBasic = event.target;
    },
    toggleActive02(event) {
      event.target.classList.toggle("active");
    },
    toggleProgramInfo() {
      if (this.isProgramInfoActive) {
        this.programInfoHeight = "0px";
        setTimeout(() => {
          this.programInfoDisplay = "none";
        }, 200);
      } else {
        this.programInfoDisplay = "block";
        this.$nextTick(() => {
          const scrollHeight = `${this.$refs.programInfo.scrollHeight}px`;
          this.programInfoHeight = scrollHeight;
          setTimeout(() => {
            this.programInfoHeight = "auto";
          }, 600);
        });
      }
      this.isProgramInfoActive = !this.isProgramInfoActive;
    },
    toggleAccordion(number) {
      if (number === 1) {
        if (this.isAccordion1Active) {
          this.accordion1Height = "0px";
          setTimeout(() => {
            this.accordion1Display = "none";
          }, 200);
        } else {
          this.accordion1Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion1Content.scrollHeight}px`;
            this.accordion1Height = scrollHeight;
            setTimeout(() => {
              this.accordion1Height = "auto";
            }, 600);
          });
        }
        this.isAccordion1Active = !this.isAccordion1Active;
      } else if (number === 2) {
        if (this.isAccordion2Active) {
          this.accordion2Height = "0px";
          setTimeout(() => {
            this.accordion2Display = "none";
          }, 200);
        } else {
          this.accordion2Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion2Content.scrollHeight}px`;
            this.accordion2Height = scrollHeight;
            setTimeout(() => {
              this.accordion2Height = "auto";
            }, 600);
          });
        }
        this.isAccordion2Active = !this.isAccordion2Active;
      } else if (number === 3) {
        if (this.isAccordion3Active) {
          this.accordion3Height = "0px";
          setTimeout(() => {
            this.accordion3Display = "none";
          }, 200);
        } else {
          this.accordion3Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion3Content.scrollHeight}px`;
            this.accordion3Height = scrollHeight;
            setTimeout(() => {
              this.accordion3Height = "auto";
            }, 600);
          });
        }
        this.isAccordion3Active = !this.isAccordion3Active;
      }
    },
    handleAccordionBottomBtnClick() {
      if (this.isProgramInfoActive) {
        this.toggleProgramInfo();
      } else {
        this.toggleAccordion(1);
      }
    },
    checkTableWidth(element) {
      let table = element.querySelector('table');
      if (table.offsetWidth > element.offsetWidth) {
          element.classList.add('scroll');
      } else {
          element.classList.remove('scroll');
      }
    }
  },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
  },
  mounted() {
    this.isAccordion1Active = true;
    this.accordion1Height = "auto";
    this.accordion1Display = "block";
  },
};
</script>

