<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">보호자 및 본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>

    <div class="contents info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">법정대리인(보호자) 확인 및 정보수집 동의</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>법정대리인(보호자) 아이디와 비밀번호, 성명을 기재하여 회원 확인이 필요합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>법정대리인이 회원으로 가입되어 있지 않을 경우 법정대리인의 회원가입 진행이 필요합니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>만 14세 미만 아동이 창원파티마병원의 웹페이지에 회원가입을 하기 위해서는 법정대리인의 동의가 필요합니다. 이에 따라 창원파티마병원은 법정대리인의 다음 정보를 수집합니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contents text-box type2">
        <div class="text-box__wrap type2">
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>①</p>
                </div>
                <div class="depth__text">
                    <p>항목 : 기존에 가입되어 있는 법정대리인의 개인정보 항목 중 아이디, 비밀번호, 성명, 법정대리인 동의 여부</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>②</p>
                </div>
                <div class="depth__text">
                    <p>목적 : 만 14세 미만 아동의 창원파티마병원 웹페이지 회원가입</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p>③</p>
                </div>
                <div class="depth__text">
                    <p>기간 : 법정대리인이 회원가입을 탈퇴하거나 회원에서 제명된 때</p>
                </div>
            </div>
            <div class="depth__wrap depth04">
                <div class="depth__bullet">
                    <p class="c-red">*</p>
                </div>
                <div class="depth__text">
                    <p class="c-red">법정대리인은 위 내용에 대해 동의를 거부할 수 있습니다. 다만 법정대리인의 동의 거부 시, 만 14세 미만 아동의 웹페이지 회원가입은 제한됨을 알려 드립니다.</p>
                </div>
            </div>
        </div>
        <div class="agree-input">
            <label for="agree-y" class="agree-input__item">
                <input type="radio" id="agree-y" class="agree-input__radio" name="agree">
                <span class="icon"></span> 동의합니다 </label>
        </div>
    </div>

    <div class="contents text-btn">
        <div class="text">
            <p><b>법정대리인(보호자)</b>가 병원 홈페이지의 <b>회원 아이디/패스워드</b>를 알고 계셔야 합니다.</p>
        </div>
        <div class="btn">
            <div class="btn-wrap">
                <a href="javascript:;" class="btn">아이디 찾기</a>
                <a href="javascript:;" class="btn">비밀번호 찾기</a>
            </div>
        </div>
    </div>

    <div class="join contents">
        <div class="input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>아이디</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <input type="text" class="cont__text-input">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>성명</div>
                        <div class="cont">
                            <input type="text" class="cont__text-input">
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn blue">확인</a>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>