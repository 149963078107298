<template>
    <div class="contents">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item"><button type="button" @click="tabMenu('행위')" class="tab-menu__btn" :class="{ 'on': menu === '행위' }">행위</button></li>
                <li class="tab-menu__item"><button type="button" @click="tabMenu('치료재료')" class="tab-menu__btn" :class="{ 'on': menu === '치료재료' }">치료재료</button></li>
                <li class="tab-menu__item"><button type="button" @click="tabMenu('약재')" class="tab-menu__btn" :class="{ 'on': menu === '약재' }">약재</button></li>
                <li class="tab-menu__item"><button type="button" @click="tabMenu('제증명수수료')" class="tab-menu__btn" :class="{ 'on': menu === '제증명수수료' }">제증명수수료</button></li>
            </ul>
        </div>

        <div class="board__search">
            <div class="board__select"><select name="" id="Sel_Inst" class="select_department">
                    <option value="">전체</option>
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                </select></div>
            <div class="board__input"><input type="text" id="Search_doc" placeholder="검색어를 입력하세요."><button type="button" class="board__input-btn"></button></div>
        </div>
        <div class="contents depth__wrap">
            <div class="depth__bullet">
                <p class="small-text">※</p>
            </div>
            <div class="depth__text">
                <p class="small-text">의료법 제45조 제1항 및 제2항, 시행규칙 제42조의 제1항 및 제2항에 의거하여 비급여 진료비용을 고지합니다.</p>
            </div>
        </div>

        <div class="contents table-wrap table-type1 scroll">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title type2"><span>1장 기본진료료</span> <span><span class="c-red">*</span> 최종 수정일 : 2024.06.25</span></h4>
                </div>
            </div>

            <!-- <template v-if="this.itemData">-->


            <!-- 행위 -->
            <div>
                <table v-if="this.menu == '행위'">
                    <caption class="blind">
                        비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                    </caption>
                    <colgroup>
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                        <col style="width:calc(100% / 12);">
                    </colgroup>
                    <thead>
                        <tr>
                            <th rowspan="2">번호</th>
                            <th rowspan="2">분류</th>
                            <th colspan="2">항목</th>
                            <th colspan="6">가격정보(단위: 원)</th>
                            <th rowspan="2">특이사항</th>
                            <th rowspan="2">최종<span>변경일</span></th>
                        </tr>
                        <tr>
                            <th style="border-left: 1px solid #d9d9d9;">명칭</th>
                            <th>코드</th>
                            <th>구분</th>
                            <th>비용</th>
                            <th>최저비용</th>
                            <th>최대비용</th>
                            <th>치료재료대포함여부</th>
                            <th>약제비포함여부</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>교육상담료</td>
                            <td>교육상담료-당뇨병교육</td>
                            <td>AZ001</td>
                            <td>(비)당뇨병교육(일반,신환교육)</td>
                            <td>20,000</td>
                            <td>0</td>
                            <td>0</td>
                            <td></td>
                            <td></td>
                            <td>개별(일반, 신환교육)</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- //행위 -->

            <!-- 치료재료 -->
            <table v-if="this.menu == '치료재료'">
                <caption class="blind">
                    비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                </caption>
                <colgroup>
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                    <col style="width:calc(100% / 10);">
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th rowspan="2">분류</th>
                        <th colspan="2">항목</th>
                        <th colspan="4">가격정보(단위: 원)</th>
                        <th rowspan="2">특이사항</th>
                        <th rowspan="2">최종 변경일</th>
                    </tr>
                    <tr>
                        <th style="border-left: 1px solid #d9d9d9;">명칭</th>
                        <th>코드</th>
                        <th>구분</th>
                        <th>비용</th>
                        <th>최저비용</th>
                        <th>최대비용</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>1</td>
                        <td>환자제공</td>
                        <td>ARM SLING "L" 두원(집에가져감)</td>
                        <td>W</td>
                        <td>(비)ARM SLING "L" 두원(집에가져감)</td>
                        <td>5,000</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>24.01.01</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>환자제공</td>
                        <td>CAST SHOES[WALKER]</td>
                        <td>W</td>
                        <td>(비)CAST COLOR SHOES(대,중,소)</td>
                        <td>6,330</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>24.01.01</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>환자제공</td>
                        <td>ARM SLING "S" 두원(집에가져감)</td>
                        <td>W</td>
                        <td>(비)ARM SLING "S" 두원(집에가져감)</td>
                        <td>5,000</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>24.01.01</td>
                    </tr>
                </tbody>
            </table>
            <!-- //치료재료 -->

            <!-- 약제 -->
            <table v-if="this.menu == '약재'">
                <caption class="blind">
                    비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                </caption>
                <colgroup>
                    <col style="width:calc(100% / 6);">
                    <col style="width:calc(100% / 6);">
                    <col style="width:calc(100% / 6);">
                    <col style="width:calc(100% / 6);">
                    <col style="width:calc(100% / 6);">
                    <col style="width:calc(100% / 6);">
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2" style="border-right: 1px solid #d9d9d9;">번호</th>
                        <th colspan="2">항목</th>
                        <th rowspan="2">비용</th>
                        <th rowspan="2">특이사항</th>
                        <th rowspan="2">최종 변경일</th>
                    </tr>
                    <tr>
                        <th>명칭</th>
                        <th>코드</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>1</td>
                        <td>코네티비나겔 30g 후메딕스</td>
                        <td>059900011</td>
                        <td>62,445</td>
                        <td></td>
                        <td>23.12.27</td>
                    </tr>

                </tbody>
            </table>
            <!-- //약제 -->

            <!-- 제증명수수료 -->
            <table v-if="this.menu == '제증명수수료'">
                <caption class="blind">
                    비급여 진료비용을 번호, 분류, 항목(명칭/코드), 가격정보(단위:원)-구분/비용/최저비용/최대비용/치료재료대포함여부/약제비포함여부, 특이사항으로 구분하여 나타내는 표입니다
                </caption>
                <colgroup>
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                    <col style="width:calc(100% / 9);">
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th colspan="2">항목</th>
                        <th colspan="4">가격정보(단위: 원)</th>
                        <th rowspan="2">특이사항</th>
                        <th rowspan="2">최종 변경일</th>
                    </tr>
                    <tr>
                        <th>명칭</th>
                        <th>코드</th>
                        <th>구분</th>
                        <th>비용</th>
                        <th>최저비용</th>
                        <th>최대비용</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>1</td>
                        <td>기타증명서</td>
                        <td>-</td>
                        <td>건강보험 보조기기 처방전 원본</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>24.01.01</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>기타증명서</td>
                        <td>-</td>
                        <td>건강보험 보조기기 처방전 원본</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>24.01.01</td>
                    </tr>

                </tbody>
            </table>
            <!-- //제증명수수료 -->
        </div>
    </div>
</template>

<script>
    // import { gsap, ScrollTrigger } from "gsap/all";

    export default {
        data() {
            return {
                itemData: {
                    '행위': [],
                    list2: [],
                    list3: [],
                    list4: []
                },
                menu: '행위',

            };
        },
        methods: {
            checkTableWidth(element) {
                let table = element.querySelector('table');
                if (table.offsetWidth > element.offsetWidth) {
                    element.classList.add('scroll');
                } else {
                    element.classList.remove('scroll');
                }
            },
            tableData() {
                this.axios.get(this.api.nonpayment.list, {
                    params: {
                        instNo: 1
                    }
                })
                    .then((res) => {

                        this.itemData = res.data
                        this.itemData = this.itemData.filter((item) => item.category1 == '치료재료' && item.category2 == '2장 검사료')

                        console.log(this.itemData)
                    })
            },
            tabMenu(el) {
                this.menu = el

                document.querySelectorAll('.tab-menu__item button').forEach(item => {
                    item.classList.remove('on');
                });
            }
        },
        mounted() {
            this.tableData()

            setTimeout(() => {
                let tableItems = document.querySelectorAll(".table-wrap");

                tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                });

                let tableItem = document.querySelectorAll(".table-wrap");

                tableItem.forEach((el) => {
                    el.addEventListener("click", () => {
                        el.classList.add('on');
                    });
                });
            }, 100);
        }

    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>