<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
          <ul class="join-tab__list">
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>
    <div class="find-info">
        <div class="contents find-info__box">
            <img src="@/assets/resource/icon/logo-b.png" class="logo" alt="이미지">
            <p class="find-info__text-type2">환영합니다 <span class="blue">이준용</span>님!</p>
            <p>창원파티마병원 홈페이지에 가입해 주셔서 감사합니다.</p>
            <ul class="contents find-info__list">
                <li class="find-info__item">
                    <div class="tit">아이디</div>
                    <div class="desc">hah***</div>
                </li>
                <li class="find-info__item">
                    <div class="tit">이메일</div>
                    <div class="desc">hah***@naver.com</div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center"><a href="javascript:;" class="btn">메인으로 이동</a><a href="javascript:;" class="btn blue">로그인</a></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>