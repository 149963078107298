<template>
    <div class="praise full-width">
        <div class="praise__tit">
            <h3>
                창원파티마병원에서 만난 <b>고마운 인연</b>이 있으신가요? <br>
                따뜻한 사연과 마음을 전해주세요
            </h3>
            <div class="praise__btn-wrap">
                <button type="button" class="praise__btn" @click="$router.push('/praise/register.do')">칭찬합니다 글쓰기</button>
                <button type="button" class="praise__btn" @click="$router.push('/mypage/board/thanks.do')">작성한 글로 바로가기</button>
            </div>
        </div>
        <div class="inner">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">칭찬이야기</h4><!-- depth02 -->
                </div>
            </div>
            <ul class="praise__list">
                <!-- <li class="praise__item" v-if="list.length == 0">작성된 글이 없습니다.</li> -->
                <li class="praise__item doctor-list__data-none " v-if="list.length == 0">
                    <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                    <h4 class="depth03__title">등록된 게시글이 없습니다.</h4>
                </li>
                <li class="praise__item" v-for="(article, idx) in list" :key="idx">
                    <router-link :to="`/${board.boardId}/view.do?boardNo=${board.boardNo}&articleNo=${article.articleNo}`" class="praise__link">
                        <div class="effect">
                            <div class="crown"></div>
                            <div v-for="item in items" :key="item" :class="`item item${item}`"></div>
                        </div>
                        <span class="name" v-if="$commonUtils.isNotEmpty(article.item3)">[칭찬 직원 : {{ article.item3 }}]</span>
                        <span class="tit">{{ article.title }}</span>
                        <span class="desc">{{ $commonUtils.removeTags(article.content) }}</span>
                        <div class="date">
                            <span class="year">{{ $commonUtils.timestampToYear(article.createdDt) + '.' + $commonUtils.timestampToMonthDay(article.createdDt)}}</span>
                            <div class="arrow-wrap">
                                <div class="arrow-btn"></div>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>

            <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
        </div>
    </div>
</template>

<script>
import Search from '../common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'

export default {
    components: {
        Search,
        Pagination
    },
    props: {
        boardInfo: ''
    },
    data() {
        return {
            board: {},
            list: [],
            pageList: '',
            totalPage: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 9,
            //검색 관련 parameter
            title: this.$route.query.title || '',
            content: this.$route.query.content || '',
            searchKeyword: this.$route.query.searchKeyword || '',

        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if (to.query != from.query && to.path == from.path) {
                if(Object.keys(to.query).length === 0) this.init()
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }

                this.getList()
            }
        },
        // 다른 게시판으로 이동
        boardInfo(oldVal, newVal) {
            this.init()
            this.getList()
        }
    },
    methods: {
        getList() {
            const boardNo = this.boardInfo.boardNo
            const params = {
                instNo: 1,
                boardNo: boardNo,
                startIndex: this.currentNum,
                pageRow: this.pageRow,
                title: this.title,
                content: this.content,
                searchKeyword: this.searchKeyword,
                item18: 'O'
            }
            this.axios.get(this.api.article.list + boardNo, {
                params: params
            })
                .then(res => {
                    if(res.data.list) this.list = res.data.list
                    this.board = res.data.board
                    this.totCnt = res.data.totCnt
                })
        },
        // 검색 emit
        setState(s, v, p) {
            this[p] = ''
            this.currentNum = 1
            this[s] = v
            this.goPage()
        },
        // pagination emit
        setNum(n) {
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = { currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword };
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj });
        },
        // 데이터 초기화
        init() {
            this.currentNum = 1
            this.title = ''
            this.content = ''
            this.searchKeyword = ''
        }
    },
    mounted() {
        this.getList()
    },
}
</script>