<template>
    <div class="contents">
        <div class="env-intro">
            <div class="env-intro__top">
                <img src="/resource/img/env-intro__top--img.png" alt="이미지" class="env-intro__top--img" data-aos="fade-left" data-aos-duration="1000">
                <img src="/resource/img/env-intro__top--bg-text.png" alt="이미지" class="env-intro__top--bg-text">
                <div class="env-intro__top--text info-text" data-aos="fade-left" data-aos-duration="1000">
                    <h3 class="depth01__title title">직업환경의학은</h3>
                    <p>
                        근로자가 일을 하는 과정에서 발생하는 질병의 예방과 진단, 치료, 재활까지 담당하는 의학의 한 분야입니다. <br>
                        일반적인 건강문제 뿐만 아니라 소음, 분진, 진동, 방사선, 유해화학물질 등의 노출, <br>
                        근골격계 부담작업, 업무스트레스 및 감정노동 등으로 발생한 건강문제를 전문적으로 관리하고 있으며, 
                        환경오염에 대한 건강관리도 포함하고 있습니다. <br>
                        다양한 사업장이 있는 경남 창원지역에서는 꼭 필요한 의학의 한 분야입니다.
                    </p>
                </div>
            </div>
            <div class="env-intro__bottom">
                <ul class="env-intro__text-list info-text">
                    <li data-aos="fade-up" data-aos-duration="1000">
                        <h3 class="depth02__title title">창원파티마병원 직업환경의학센터에서는</h3>
                        <p>
                            근로자 건강진단, 보건관리위탁, 작업환경측정·분석, 직업환경의학과 외래를 통해 
                            직업환경의학의 여러 업무에 대한 One-Stop 의료서비스를 제공하고 있습니다.
                        </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1000">
                        <h3 class="depth02__title title">창원파티마병원 직업환경의학센터는</h3>
                        <p>
                            이러한 서비스를 통해 2022년 지역 내 집단적으로 발생한 트리클로로메탄에 의한 독성 간염을 조기에 밝혀 
                            간염에 걸린 근로자들이 치명적인 결과 없이 회복하고, <br>
                            다른 사업장에서 해당 물질을 사용하지 않게 하는데 결정적인 역할을 수행했습니다. 
                        </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1000">
                        <h3 class="depth02__title title">창원파티마병원 직업환경의학센터는</h3>
                        <p>
                            근로자의 건강 이상에 대한 조기진단 및 관리, 작업현장방문 건강 상담과 업무환경평가 등으로 
                            지역 근로자의 건강문제에 대해 실질적으로 접근해서 종합적으로 관리하고 있으며, <br> 
                            지역 근로자의 건강증진을 목표로 경남 직업병 안심센터와 연계하여  <br>
                            직업성 질환의 의학적 관리와 직업병 예방 관리 프로그램 또한 실시하고 있습니다.
                        </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1000">
                        <h3 class="depth02__title title">창원파티마병원 직업환경의학과 외래에서는</h3>
                        <p>
                            질병을 가진 환자의 의학적 관리에만 머물지 않고, <br>
                            근로자의 질병이 업무수행을 하면서 노출된 물리적 요인, 화학적 요인, 인간공학적 요인, 업무스트레스 요인, 
                            감정노동 등이 주요한 원인인지를 밝히는 업무관련성 평가를 제공하고 있습니다.
                        </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1000">
                        <p>
                            또한, 업무 수행 전 근로자가 가진 질병이 향후 업무를 수행하는데 문제가 없는지, 그리고 건강상태에 따라 적정한 업무를 권고하는 업무적합성 평가도 제공합니다. <br>
                            그 외 근로자 뇌·심혈관계 질환, 니코틴 중독, 환경성 질환 등에 관한 상담, 진단, 치료, 관리 등의 업무를 수행합니다. 
                        </p>
                    </li>
                </ul>
            </div>
        </div>  
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    
</style>