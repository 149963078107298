<template>
  <div class="healthCheckupReserve healthCheckupReserve_intro_main">
    <div class="contents">
      <p class="healthCheckupReserve_intro">
        창원파티마병원에서는 검진 희망자분들을 위해 쉽고 간편한 예약 서비스를
        제공하고 있습니다.
      </p>
    </div>
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">개인 건강검진절차</h4>
        </div>
      </div>
      <ul class="step-textbox type_center01">
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">예약자 정보 및 건강검진 유형 선택</h3>
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>유형별 건강검진내용을 확인하고 선택합니다.</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">추가검사선택</h3>
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>본인의 희망에 따라 필요한 추가검사를 선택합니다.</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">희망예약일 선택</h3>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">건강검진 예약확인</h3>
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>전화 혹은 홈페이지를 통해 예약사항 확인 가능합니다.</p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="contents">
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">온라인예약</h4>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text kcc_btn_wrap">
              <p>
                이름, 전화번호, 검진항목 등을 남기시면 전문 상담원이 연락하여
                예약을 도와드립니다.
              </p>
              <div class="btn-wrap right"><a href="/deptHp/1/healthcenter/PersonalReservation.do" class="btn blue">온라인예약 바로가기</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">전화예약</h4>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>055)270-1200, 1201</p>
              <p class="blue__title call_bold">상담시간 : 평일 09:00~17:00 / 토 09:00~12:00</p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">방문예약</h4>
          <div class="depth__wrap depth02">
            <div class="depth__bullet"><span class="bullet03"></span></div>
            <div class="depth__text">
              <p>4층 건강증진센터 방문</p>
            </div>
          </div>
           <div class="depth__wrap info-reference">
                <div class="depth__bullet">
                  <p class="small-text">※</p>
                </div>
                <div class="depth__text">
                  <p class="small-text">
                    건강검진 예약 시 등록하신 전화번호로 예약안내 및 알림 문자 서비스가 제공됩니다.
                  </p>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

