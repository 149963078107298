<template>
    <!-- 사경측경클리닉-->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 재활의학과에서는 목을 바로 가누지 못하고 한쪽으로 회전하거나 기우는 아이들의 치료를 위해 사경·측경 클리닉을 운영하고 있습니다. 사경·측경 클리닉에서는 머리가 한쪽으로 회전하는 사경, 머리가 한쪽으로 기우는 측경의 진단과 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">사경·측경 클리닉이 필요한 경우</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p><b>사경</b></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>사경은 아이의 머리가 한쪽으로 회전하는 경우로 영유아기에 나타나는 두경부 이상 자세의 약 60~70%를 차지할 정도로 흔합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>초음파 검사를 통해 진단할 수 있으며 재활치료를 통해 85~90% 정도는 호전되나 나머지는 주사요법이나 수술이 필요한 경우도 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>정도가 심한 경우 교정시기를 놓치면 아이의 얼굴에 변형이 올 수 있으므로 빠른 시기에 치료하는 것이 좋습니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p><b>측경</b></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>측경은 아이의 머리가 한쪽으로 기울어 있는 경우를 말합니다. 안과 검사와 발달평가가 필요하며, 경추에 대한 방사선 검사와 초음파 검사를 실시해 원인을 파악하고 그에 따른 치료를 실시합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>


<script>
export default {
   
};
</script>