<template>
    <div class="board-list" @click="resetActiveCont">
        <div class="board__search">
            <div class="board__select" :class="{ 'active': selectOn }" @click="selectFn">
                <select name="" id="">
                    <option value="전체">전체</option>
                    <option value="제목">제목</option>
                    <option value="글">글</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" placeholder="검색어를 입력해주세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>

        <div class="contents board-gallery__list type2" @click.stop>
            <div class="item-wrap" v-for="(group, groupIndex) in galleryGroups" :key="groupIndex">
                <div class="item-group">
                    <div class="board-gallery__item" v-for="(item, index) in group" :key="index" :class="{ show: activeCont === item.articleNo }" @click="showCont(item.articleNo, $event,item)">
                        <a href="javascript:;">
                            <img :src="item.image" alt="이미지">
                            <div class="board-gallery__text">
                                <h3>{{ item.title }}</h3>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="board-gallery__cont">
                    <div class="board-gallery__cont-inner">
                        <div v-html="this.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        data() {
            return {
                activeCont: null,
                content: null,
                galleryList: [
                    {
                        image: require('@/assets/resource/img/center-bg.png'),
                        title: 'PET-CT',
                        articleNo: 1,
                        content: `
                    <div class="info-text">
    <p class="info-text__desc">암, 알츠하이머, 파킨슨병 조기 진단</p>
    <h4 class="depth01__title title">
        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
    </h4>
    <h5 class="depth03__title title">암의 조기진단</h5>
    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
        매우 높다.</p>
    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
    <div class="line"></div>
    <h5 class="depth03__title title">암의 조기진단</h5>
    <div class="depth__wrap">
        <div class="depth__bullet"><span class="bullet03"></span></div>
        <div class="depth__text">
            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
        </div>
    </div>
    <div class="depth__wrap">
        <div class="depth__bullet"><span class="bullet03"></span></div>
        <div class="depth__text">
            <p class="c-gray5">양성종양과 악성종양의 감별</p>
        </div>
    </div>
    <div class="depth__wrap">
        <div class="depth__bullet"><span class="bullet03"></span></div>
        <div class="depth__text">
            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
        </div>
    </div>
    <div class="depth__wrap">
        <div class="depth__bullet"><span class="bullet03"></span></div>
        <div class="depth__text">
            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
        </div>
    </div>
</div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/테스트이미지-사람.png'),
                        articleNo: 2,
                        title: '128채널 MD-CT',
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/center-bg.png'),
                        title: 'PET-CT',
                        articleNo: 3,
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>

                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/테스트이미지-사람.png'),
                        articleNo: 4,
                        title: '128채널 MD-CT',
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/center-bg.png'),
                        title: 'PET-CT',
                        articleNo: 5,
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/테스트이미지-사람.png'),
                        articleNo: 6,
                        title: '128채널 MD-CT',
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/center-bg.png'),
                        title: 'PET-CT',
                        articleNo: 7,
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                    {
                        image: require('@/assets/resource/img/테스트이미지-사람.png'),
                        articleNo: 8,
                        title: '128채널 MD-CT',
                        content: `
                      <div class="info-text">
                    <h4 class="depth01__title title">
                        <span class="c-mint">PET-CT</span>(양전자 컴퓨터 단층촬영기)
                    </h4>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다.
                        PET-CT는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가
                        매우 높다.</p>
                    <h5 class="depth03__title title">알츠하이머병, 파킨슨병의 조기 진단</h5>
                    <p class="c-gray5">PET-CT는 암세포가 정상세포보다 더 많은 포도당을 필요로 한다는 특성을 이용해 포도당을 결합한 방사성의약품을 체내에 주사하고 그 물질의 분포를 촬영해 조기에 암을 진단할 수 있는 첨단 장비이다. PET-CT
                        는 암의 조기 발견과, 전이, 악성여부의 판별뿐만 아니라 암세포의 위치까지도 쉽게 파악할 수 있으며, 이는 암치료에 있어 어떤 방법으로 치료하는 것이 효율적인지를 결정할 수 있어 임상적인 가치가 매우 높다.</p>
                    <div class="line"></div>
                    <h5 class="depth03__title title">암의 조기진단</h5>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암의 진단(유방암, 폐암, 대장암, 위암, 식도암, 갑상선암, 자궁경부암, 악성림프종 등)</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">양성종양과 악성종양의 감별</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">암 치료 효과 평가 및 재발진단</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet"><span class="bullet03"></span></div>
                        <div class="depth__text">
                            <p class="c-gray5">알츠하이머병, 파킨슨병 진단</p>
                        </div>
                    </div>
                </div>
                    `
                    },
                ]
            };
        },
        computed: {
            galleryGroups() {
                const groups = [];
                const groupSize = window.innerWidth <= 720 ? 1 : 3;

                for (let i = 0; i < this.galleryList.length; i += groupSize) {
                    groups.push(this.galleryList.slice(i, i + groupSize));
                }

                return groups;
            }
        },
        methods: {
            showCont(articleNo, event, item) {
                event.stopPropagation(); // 부모 엘리먼트로의 클릭 이벤트 전파를 막기 위해 stopPropagation() 사용
                if (this.activeCont === articleNo) {
                    this.activeCont = null;
                } else {
                    this.activeCont = articleNo;
                }
                this.content = item.content;
            },
            resetActiveCont() {
                this.activeCont = null;
            }
        },
        mounted() {
            document.addEventListener('click', this.resetActiveCont);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.resetActiveCont);
        }
    };
</script>