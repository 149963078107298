<template>
    <!-- 산부인과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>산부인과는 임신이라는 환경 변화에 산모가 잘 적응해 건강한 태아를 분만하게 하는 산과와 생식, 내분비 및 불임, 부인암 그리고 여성의 일반 질환을 치료하는 부인과로 구분됩니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>산과, 부인과, 생식 내분비질환</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>1969년 개원과 함께 신설되어 오랜 전통과 역사를 자랑하는 창원파티마병원 산부인과는 지역 산모들의 건강하고 안전한 출산을 책임지고 있으며, 전세대의 출산력있는 어머니는 부인과로, 최근 출산을 준비하는 딸은 산과로 모녀가 함께 진료받을 수 있는 최적의 진료 여건을 완비하고 있습니다.</p>
                                <p>또한 지난 20년간 약 3,000건 이상의 분만을 실시했으며, 꾸준히 고위험 산모의 안전한 분만을 시행하고 있으며, 풍부한 경험과 숙련된 의료진들이 연 1,000건 이상의 복강경하 자궁적출술 등을 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">산과</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>고위험 임신(임신성 당뇨, 임신성 고혈압, 전치태반, 후기 조기진통, 자궁경관무력증, 심한 입덧 및 절박유산, 쌍태아 등), 고위험 분만 및 제왕절개수술, 태아정밀 초음파, 양수검사, 임산부 약물 및 유전상담 등</p>
                            </div>
                        </div> 
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">부인과</h4>
                       
                    </div>
                </div>
                 <div class="depth__wrap">
                            <div class="depth__text">
                                <div class="table-cont" style="margin-top:0;">
                                    <ul class="table-list type03">
                                        <li class="table-item">
                                            <div class="item tit">복강경 및 단일공 수술</div>
                                            <div class="item">
                                               단일공 자궁적출술, 단일공 근종 절제술, 단일공 자궁부분절제술, 단일공 난소 낭종 절제술, 단일공 자궁부속기절제술, 단일공 자궁외임신 수술 등
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">비뇨부인과</div>
                                            <div class="item">요실금 수술, 자궁탈출증 수술</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">자궁내시경 수술</div>
                                            <div class="item">자궁내시경 용종절제술, 자궁내시경 근종 절제술, 자궁내시경 내막 격벽 제거술 및 부정출혈 진단 및 지혈치료 등</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">질·소음순 성형수술</div>
                                            <div class="item"></div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">생식내분비질환</div>
                                            <div class="item">무월경, 월경 불순, 다낭성 난포 증후군, 배란장애 등</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">개복 없이 단일공·복강경 수술로 제거 - 자궁근종</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>자궁근종은 근육조직으로 이뤄진 덩어리로 대부분 아무런 증상이나 문제를 일으키지 않지만 근종이 갑자기 커지거나 증상이 발생하는 경우가 적지 않기 때문에 치료가 필요하지 않은 경우라도 6~12개월 간격으로 정기적인 추척 관찰을 받는 것이 좋습니다. 근종의 크기와 위치, 개수에 따라 임신과 출산에 영향을 줄 수 있으며, 환자의 상태에 따라 적합한 치료 방법을 선택합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">자궁근종과 예후·치료 다른 자궁선근증</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>자궁선근증 역시 자궁근종과 마찬가지로 가임기 여성에서 흔히 발견되는 양성 질환이지만 자궁에 혹이 생기는 것이 아니라 자궁내막층을 이루는 세포가 자궁근육층을 침범해 지속적으로 자라나 자궁의 근육층을 두껍고 딱딱하게 만드는 질환입니다. 별다른 증상이 없다면 6개월 주기로 추적 관찰을 시행하지만, 자궁근종에 비해 생리통, 생리 과다, 골반통 등 증상이 흔하고 심하기 때문에 대부분 진통제, 피임약, 호르몬 주사 요법, 자궁 내 호르몬 장치 삽입술 등의 치료를 바로 시작합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">시술로 지연 가능, 완치하기 위해서는 자궁적출술</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>수술적 치료가 필요할 정도로 진행된 경우 자궁근종과는 달리 자궁벽 자체의 전반적인 문제이기 때문에 근종절제술과 같은 수술은 불가능하며 완치를 위해서는 반드시 자궁적출술을 시행해야 하는 경우가 대부분입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">‘폐경기’ 추적관찰로 적절한 호르몬대체요법 시행</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>폐경이란 난소기능이 소진해 호르몬 분비가 사라지고 월경이 중지되는 증상 혹은 과정을 말합니다. 사람마다 다르지만 40대 중반부터 시작해 50세 전후에 완결되는데, 이 기간을 폐경 이행기 혹은 갱년기라고 통칭합니다. 여성은 폐경기 전후로 급격한 여성호르몬 결핍에 따른 여러 내분비적인 증상과 대사장애를 경험하게 되는데, 대개 첫 3~4년간 심하다가 차츰 줄어들지만 개인차가 심합니다.</p>
                                <br>
                                <p>안면 홍조, 화끈거리는 느낌, 수면 중 식은땀, 수면장애와 불안증, 심계항진 등 이차적 증상까지 유발하므로 환자를 가장 힘들게 하는 주요 증상이라고 할 수 있으며, 정신과적 치료를 필요로 할 만큼 심각한 결과를 초래하기도 합니다. 중추신경계에도 영향을 미쳐 기억력 감퇴, 인지기능 저하, 무기력증을 유발하기도 합니다.</p>
                                <br>
                                <p>여성호르몬은 골밀도 유지에 중요한 역할을 하는 호르몬 중 하나로, 갱년기에는 여성 호르몬이 감소하면서 골밀도가 감소하고 골다공증으로 이어질 수 있으며, 폐경 후 5년이 되면 폐경 전에 비해 골밀도가 반 정도로 감소한다는 보고도 있습니다. 그러나 한번 발생한 골다공증은 비가역적인 변화로 치료를 해도 정상 회복이 어렵기 때문에 초기에 진단해 빠르게 치료를 시작함으로써 더욱 악화되지 않게 유지하는 것이 최선입니다. 창원파티마병원 산부인과에서는 폐경기 여성의 삶의 질 향상을 위해 면밀한 추적관찰을 병행하면서 적절히 사용하는 단기간 호르몬대체요법을 시행하고 있습니다.</p>
                           </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>