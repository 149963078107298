<template>
        <Header></Header>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home"><a href="" class="Location__item--btn"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQ4ja3TP0sDQRAF8J+HiGAKLQVFsbIQ4p/SRrDKB0hhZyXYiFjnQ9jZCJYigo2CRUDBNkr8ArGytRAUooVaZALHceeZ4IOFtzPz3s4uOyP1el0JFnAafAtPvxUnJWZ1tPEYqx2xQowWxMdxGB3t4CziNzjGJvbR/UuHi2hhDaspM8FXI9eK2l8Nt3GPJtbRyTmwE7lm1G6nk/0rV3CEmt41L3OM0vjEAW5xovcEu3hLsIwHzAcvM0vjMjTz4bGcROvn2MDzAGZ9PIf2HM0EM2jgAtUhDKuhbWB2FB+RmMNUjmAEK8Hb+M7kp0IL3aJ/2McErjOGNbwXCcomZVfvB0zHqkSsEGUdLuEKb7G/ilghyjqErwI+tOFA+HfD7BtWMJnaj+VoxjI1lSLDV/ljt5fiL3qzvpWpueuTH1quOlOE3j3aAAAAAElFTkSuQmCC"
                            alt="이미지"></a></li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과/의료진</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/department-doctor/department.do" class="">진료과/의료진</a></li>
                            <li><a href="/reserve/appointment.do" class="">예약/발급</a></li>
                            <li><a href="/hospital/information/outpatient.do" class="">이용안내</a></li>
                            <li><a href="/hospital/healthInfo/consulting.do" class="">건강정보</a></li>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">병원소개</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">창원파티마병원</a></li>
                            <li><a href="/hospital/hospitalIntro/story/newsBoard.do" class="">파티마이야기</a></li>
                            <li><a href="/hospital/hospitalIntro/active/prayBoard.do" class="">병원활동</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item">
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a aria-current="page" href="/kr/news/list.do"
                                    class="router-link-active router-link-exact-active">화이팅</a></li>
                            <li><a href="/kr/press/list.do" class="">언론보도</a></li>
                            <li><a href="/kr/event/list.do" class="">행사안내</a></li>
                            <li><a href="/kr/job/list.do" class="">퍼블팀</a></li>
                            <li><a href="https://fliphtml5.com/bookcase/nrkwy/"
                                    to="https://fliphtml5.com/bookcase/nrkwy/"></a></li>
                            <li><a href="/kr/gallery/list.do" class=""></a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sub-tit">
            <h2>진료일정표</h2>
        </div>
        <div class="inner">



            <div class="tab-menu type02">
                <ul class="tab-menu__list">
                    <li class="tab-menu__item"><button type="button" class="tab-menu__btn">진료과 안내</button></li>
                    <li class="tab-menu__item"><button type="button" class="tab-menu__btn">의료진 검색</button></li>
                    <li class="tab-menu__item"><button type="button" class="tab-menu__btn ">센터&클리닉</button></li>
                    <li class="tab-menu__item"><button type="button" class="tab-menu__btn on">진료일정표</button></li>
                </ul>
            </div>
            <div class="contents">
                <div class="med-calendar">
                    <div class="med-calendar__left reservation__step">
                        <div class="med-calendar__header">
                            <a href="javascript:;" class="reservation__header">
                                <h3 class="reservation__header--title">STEP01</h3><span
                                    class="reservation__header--arrow"></span>
                                <h3 class="reservation__header--title">진료과 선택</h3>
                            </a>
                        </div>
                        <div class="med-calendar--con-wrap">
                            <div class="med-calendar--con reservation__con tablet-show">
                                <div class="reservation__consonant">
                                    <ul class="initial-search__list">
                                        <li><a href="javascript:;" class="all active">ALL</a></li>
                                        <li><a href="javascript:;" class="disable">ㄱ</a></li>
                                        <li><a href="javascript:;" class="">ㄴ</a></li>
                                        <li><a href="javascript:;" class="disable">ㄷ</a></li>
                                        <li><a href="javascript:;" class="">ㄹ</a></li>
                                        <li><a href="javascript:;" class="">ㅁ</a></li>
                                        <li><a href="javascript:;" class="">ㅂ</a></li>
                                        <li><a href="javascript:;" class="">ㅅ</a></li>
                                        <li><a href="javascript:;" class="">ㅇ</a></li>
                                        <li><a href="javascript:;" class="">ㅈ</a></li>
                                        <li><a href="javascript:;" class="disable">ㅊ</a></li>
                                        <li><a href="javascript:;" class="disable">ㅋ</a></li>
                                        <li><a href="javascript:;" class="disable">ㅌ</a></li>
                                        <li><a href="javascript:;" class="disable">ㅍ</a></li>
                                        <li><a href="javascript:;" class="">ㅎ</a></li>
                                    </ul>
                                </div>
                                <div class="reservation__department-list">
                                    <ul>
                                        <li><a href="#">내분비대사내과</a></li>
                                        <li><a href="#">류마티스내과</a></li>
                                        <li><a href="#">마취통증의학과</a></li>
                                        <li><a href="#">비뇨의학과</a></li>
                                        <li><a href="#">산부인과</a></li>
                                        <li><a href="#">성형외과</a></li>
                                        <li><a href="#">소아청소년과</a></li>
                                        <li><a href="#">소화기내과</a></li>
                                        <li><a href="#">신경과</a></li>
                                        <li><a href="#">신경외과</a></li>
                                        <li><a href="#">신장내과</a></li>
                                        <li><a href="#">심장내과</a></li>
                                        <li><a href="#">심장혈관흉부외과</a></li>
                                        <li><a href="#">외과</a></li>
                                        <li><a href="#">이비인후과</a></li>
                                        <li><a href="#">정형외과</a></li>
                                        <li><a href="#">혈액종양내과</a></li>
                                        <li><a href="#">호흡기내과</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="med-calendar--con tablet-pc-none">
                                <ul class="initial-search__list">
                                    <li v-for="(item, index) in items" :key="index">
                                        <a href="javascript:;"
                                            :class="{ 'active': activeIndex === index, 'disable': !item.initial_list }"
                                            @click="scrollToItem($event, index)">{{ item.initial }}</a>
                                    </li>
                                </ul>
                                <ul class="med-calendar__initial-list">
                                    <li class="med-calendar__initial-list--item" v-for="(item, index) in items" :key="index">
                                        <h3>{{ item.initial }}</h3>
                                        <ul>
                                            <li><a href="javascript:;">{{ item.initial_list }}</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="med-calendar__right reservation__step active">
                        <div class="med-calendar__header">
                            <a href="javascript:;" class="reservation__header">
                                <h3 class="reservation__header--title">STEP02</h3><span
                                    class="reservation__header--arrow"></span>
                                <h3 class="reservation__header--title">진료 일정표 확인</h3>
                            </a>
                        </div>
                        <div class="med-calendar--con-wrap active">
                            <div class="calendar__header-wrap">
                                <div class="calendar__header"><button class="calendar__header--button prev"></button>
                                    <h4 class="calendar__header--title">2024.05.20 <span>~</span> 2024.05.27</h4>
                                    <button class="calendar__header--button next"></button>
                                </div>
                            </div>
                            <p class="med-calendar--day-text tablet-show"><span class="day"></span> 외래진료</p>

                            <div class="med-calendar--con table-wrap table-type1">
                                <table>
                                    <caption class="blind">의료진 , 주진료분야, 오전진료, 오후진료, 안내사항</caption>
                                    <colgroup>
                                        <col style="width: 175px;">
                                        <col style="width: calc(60% - 177.5px);">
                                        <col style="width: 90px;">
                                        <col style="width: 90px;">
                                        <col style="width: calc(40% - 177.5px);">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>의료진</th>
                                            <th>주진료분야</th>
                                            <th>오전진료</th>
                                            <th>오후진료</th>
                                            <th>안내사항</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="data-none" colspan="5">
                                                <img src="../../../assets/resource/icon/med-calendar_data-none.png" alt="이미지">
                                                <h3 class="depth01__title"><span class="blue__title">진료과</span>를 먼저 선택해주세요</h3>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>의료진</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <h3>박종호 </h3>
                                                    <div class="btn-wrap">
                                                        <a href="javascript:;" class="btn blue">진료예약</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>주진료분야</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <p>위, 식도, 대장, 간, 췌담도</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오전진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>월<span class="day"></span></li>
                                                    <li>화<span class="day"></span></li>
                                                    <li>목<span class="day"></span></li>
                                                    <li>토<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오후진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>월<span class="day"></span></li>
                                                    <li>금<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>안내사항</h4>
                                                </div>
                                                <ul class="med-calendar__table-td">
                                                    <p></p>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>의료진</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <h3>김완철</h3>
                                                    <div class="btn-wrap">
                                                        <a href="javascript:;" class="btn blue">진료예약</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>주진료분야</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <p>위, 식도, 대장, 간, 췌담도</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오전진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>목<span class="day"></span></li>
                                                    <li>금<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오후진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>월<span class="day"></span></li>
                                                    <li>화<span class="day"></span></li>
                                                    <li>목<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>안내사항</h4>
                                                </div>
                                                <ul class="med-calendar__table-td">
                                                    <p></p>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>의료진</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <h3>신재욱 </h3>
                                                    <div class="btn-wrap">
                                                        <a href="javascript:;" class="btn blue">진료예약</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>주진료분야</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <p>위, 식도, 대장, 간, 췌담도</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오전진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>월<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                    <li>금<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오후진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>화<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>안내사항</h4>
                                                </div>
                                                <ul class="med-calendar__table-td">
                                                    <p></p>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>의료진</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <h3>진수신 </h3>
                                                    <div class="btn-wrap">
                                                        <a href="javascript:;" class="btn blue">진료예약</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>주진료분야</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <p>위, 식도, 대장, 간, 췌담도</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오전진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>화<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오후진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>화<span class="day"></span></li>
                                                    <li>화<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>안내사항</h4>
                                                </div>
                                                <ul class="med-calendar__table-td">
                                                    <p></p>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>의료진</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <h3>이현수 </h3>
                                                    <div class="btn-wrap">
                                                        <a href="javascript:;" class="btn blue">진료예약</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>주진료분야</h4>
                                                </div>
                                                <div class="med-calendar__table-td">
                                                    <p>위, 식도, 대장, 간, 췌담도</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오전진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>화<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>오후진료</h4>
                                                </div>
                                                <ul class="med-calendar__table-td day">
                                                    <li>화<span class="day"></span></li>
                                                    <li>화<span class="day"></span></li>
                                                    <li>수<span class="day"></span></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <div class="med-calendar__table-th pc-none">
                                                    <h4>안내사항</h4>
                                                </div>
                                                <ul class="med-calendar__table-td">
                                                    <p></p>
                                                </ul>
                                            </td>
                                        </tr> -->
                                       
                                    </tbody>
                                </table>
            
            
            
                            </div>
                        </div>
                    </div>
                </div>
                <p class="med-calendar--day-text info-reference tablet-pc-none"><span class="day"></span> 외래진료</p>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>


<script>
import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Footer from '@/components/common/Footer.vue';
export default {
    components: {
            Header,
            LocationBar,
            Footer
        },
    data() {
        return {
            items: [
                { initial: 'ㄱ', initial_list: "감염내과" },
                { initial: 'ㄴ', initial_list: "내분비대사내과" },
                { initial: 'ㄷ', initial_list: "" },
                { initial: 'ㄹ', initial_list: "류마티스내과"},
                { initial: 'ㅁ', initial_list: "마취통증의학과" },
                { initial: 'ㅂ', initial_list: "비뇨의학과" },
                { initial: 'ㅅ', initial_list: "산부인과" },
                { initial: 'ㅇ', initial_list: "이비인후과" },
                { initial: 'ㅈ', initial_list: "정형외과" },
                { initial: 'ㅊ', initial_list: "" },
                { initial: 'ㅋ', initial_list: "" },
                { initial: 'ㅌ'},
                { initial: 'ㅍ', initial_list: "피부과" },
                { initial: 'ㅎ', initial_list: "혈액종양내과" },
            ],
            activeIndex: 0
        };
    },
    methods: {
        scrollToItem(event, index) {
            this.activeIndex = index;
            // .med-calendar__initial-list--item 요소를 찾아 스크롤 위치를 계산하여 스크롤
            const itemList = document.querySelectorAll('.med-calendar__initial-list--item');
            const targetElement = itemList[index];
            
            if (targetElement) {
                // targetElement의 위치로 스크롤
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    },
};
</script>