<template>
    <div class="reserv_intro">
        <div class="contents">
            <ul class="reserv_list">
                <li class="reserv_item first_reserve new-reserv">
                    <div class="info-text">
                        <h3 class="depth01__title title">첫방문 간편예약</h3>
                        <p>처음 오시는 고객의 편의를 위하여 전문 상담원이<br>
                            전화를 통해 진료예약을 도와 드리는 서비스입니다.</p>
                    </div>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn blue" @click="showPopup">간편예약 바로가기</a>
                    </div>
                    <p class="text__bold">상담시간 : 평일 08:30 ~ 16:30, <br>토/공휴일 제외</p>
                </li>

                <li class="reserv_item online_reserve new-reserv">
                    <div class="info-text">
                        <h3 class="depth01__title title">온라인 예약</h3>
                        <p>창원파티마병원 홈페이지를 통해<br>
                            편리하게 진료 예약 서비스를 이용하세요.</p>
                    </div>
                    <div class="btn-wrap center">
                        <router-link to="/reserve/reservation.do" class="btn mint">온라인예약 바로가기</router-link>
                    </div>
                </li>
            </ul>

        </div>

        <div class="contents">
            <div class="info-text depth__wrap depth01" >
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">전화예약</h4>
                    <div class="depth__wrap depth02" style="margin-bottom: 0;">
                        <div class="depth__text">
                            <div class="depth__wrap depth03" style="margin-bottom: 0;">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet"><span class="bullet03"></span></div>
                                        <div class="depth__text">
                                            <p>270-1000(컨택센터) 전화로 편리하게<br>예약하실 수 있습니다.</p>
                                        </div>
                                    </div>
                                    <p class="blue__title">상담시간 : 평일 07:30 ~ 17:30 / <br>토요일 07:30 ~ 12:30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">진료협력센터 초진예약</h4>
                    <div class="depth__wrap depth02">
                        <div class="depth__text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <div class="depth__wrap">
                                        <div class="depth__bullet"><span class="bullet03"></span></div>
                                        <div class="depth__text">
                                            <p>1,2차 의료기관에서 진료의뢰서를 받으신 초진환자는 진료협력센터에서 진료예약이 가능합니다.</p>
                                            <p>진료협력센터 : 270-1108</p>
                                        </div>
                                    </div>
                                    <p class="blue__title">상담시간 : 평일 08:30 ~ 17:30 / <br>토요일 08:30 ~ 12:30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FirstReservePopUp :popupVisible="popupVisible" @hide-popup="hidePopup"/>
</template>


<script>
import FirstReservePopUp from '@/components/reserve/FirstReservePopUp.vue'

export default {
    components: {
        FirstReservePopUp
    },
    data() {
        return {
            popupVisible: false
        };
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        }
    }
};
</script>