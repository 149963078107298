import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import memberStore from './memberStore'
import reserveStore from './reserveStore'
import myInfoStore from './myInfoStore'
import redirectUrlStore from './redirectUrlStore'

export default createStore({
    plugins: [
        createPersistedState({
            paths: ['memberStore', 'reserveStore','myInfoStore', 'redirectUrlStore']
        })
    ],
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        /**
         * 회원가입
         */        
        memberStore: memberStore,
        reserveStore: reserveStore,
        myInfoStore: myInfoStore,
        redirectUrlStore: redirectUrlStore,
    }
})
