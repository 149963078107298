<template>
  
<div class="contents">
    <div class="funeral-swiper__wrap">
        <a href="javascript:;" title="팝업닫기" class="funeral-swiper__close"></a>
        <div class="funeral-swiper swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide"> <img src="/resource/img/tour-country01.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country02.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country03.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country04.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country05.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country06.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country07.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country08.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country09.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country10.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country11.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country12.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country13.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country14.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country15.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country16.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country17.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country18.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country19.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country20.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country21.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country22.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country23.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country24.jpg" alt="이미지"></div>
                <div class="swiper-slide"> <img src="/resource/img/tour-country25.jpg" alt="이미지"></div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div>
    </div>
    <div thumbsslider="" class="funeral-sub-swiper swiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide"> <img src="/resource/img/tour-country01.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country02.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country03.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country04.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country05.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country06.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country07.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country08.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country09.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country10.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country11.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country12.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country13.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country14.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country15.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country16.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country17.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country18.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country19.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country20.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country21.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country22.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country23.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country24.jpg" alt="이미지"></div>
            <div class="swiper-slide"> <img src="/resource/img/tour-country25.jpg" alt="이미지"></div>
        </div>
        <a href="javascript:;" class="funeral-sub-swiper__more"><p>이미지 더보기</p></a>
    </div>
</div>

<!-- <script>
      var swiper2 = new Swiper('.funeral-sub-swiper', {
      slidesPerView: 4,
      loop: true,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      allowTouchMove: false
    });
    var swiper = new Swiper(".funeral-swiper", {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        thumbs: {
            swiper: swiper2,
        },
    });

</script> -->
</template>

<script>
export default {
    mounted() {

        var swiper2 = new Swiper('.funeral-sub-swiper', {
      slidesPerView: 4,
      loop: true,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      allowTouchMove: false
    });
    var swiper = new Swiper(".funeral-swiper", {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        thumbs: {
            swiper: swiper2,
        },
    });
    $(function(){
        $(".funeral-sub-swiper .swiper-slide").click(function(){
            $(".funeral-swiper__wrap").addClass("on");
        });
        $(".funeral-swiper__close").click(function(){
            $(".funeral-swiper__wrap").removeClass("on");
        });
        
        $(".funeral-sub-swiper__more").click(function(){
            if ($(".funeral-sub-swiper").hasClass("on") == true) {
                $(".funeral-sub-swiper").removeClass("on");
            }else{
                $(".funeral-sub-swiper").addClass("on");
            }
        });
    });
  }


};
</script>