<template>
  <div class="jobHealthCenter contents">
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              어떤 일터에서는 반복작업과 중량물 취급으로 인해 팔이 쑤시고 붓거나, 허리통증에 시달리는 사람들이 많고, 또 어떤 일터에서는 소리를 잘 듣지 못하거나, 냄새를 잘 맡지 못하는 사람들이 있습니다. 언론을 통해 다양한 직업병이 소개되고 있지만, 정작 직업병과 관련된 증상이 있는 사람들이 자신의 증상이 직업병인지 상담할 곳이 마땅치 않습니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              의학적인 전문평가와 함께 현행 산재, 직업병 인정기준에 합당한지에 대한 평가를 함께 내려주는 곳이 직업환경의학과입니다. 모든 질환의 원인이 한 가지 때문으로 설명되는 경우는 매우 드물고, 대부분은 복합적으로 작용합니다. 유전적 요인, 생활습관 요인, 연령증가에 따른 퇴행성 요인 등 질병발생에 영향을 미치는 다양한 요인들 중 직업적 요인을 밝히고, 직업적 요인이 어느 정도 비중을 차지하는 것인지를 평가하는 것이 바로 업무관련성 평가입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              업무관련성 평가는 근로자의 증상호소에 대한 의학적인 진단을 한 후에 해당 근로자의 작업부담 정도와의 관련성에 대한 의학적 소견을 제공하고 해당 결과를 확실함(Definite) / 가능성높음(Probable) / 가능성있음(Possible) / 확인불가능(Unidentable) / 관련성없음(NO) 등의 등급으로 구분하고 있습니다
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">업무관련성 평가 예시</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">업무관련성 평가 예시</caption>
                <colgroup>
                    <col style="width:30%;">
                    <col style="width:70%;">
                </colgroup>
                <tbody>
                    <tr>
                        <td>소 견<br>(작업관련성)</td>
                        <td style="text-align:left;">상기 환자는 약 1년 전부터 요통 및 허리 저림 등의 증상이 발생했으며, 2022년 9월 상기 상병을 진단받았습니다.<br>상기 환자의 진술에 의하면 조선소 취부, 배달, 절단, 운반, 원자력발전소 철근 고정, 건설 일용직 등의 등 여러 작업을 약 10년 이상 수행하였습니다. 신청인의 작업에는 반복적인 중량물 취급과 허리의 굴곡, 신전, 비틀림 등의 부자연스런 작업자세 등 허리 부담 작업이 상당 부분 이루어졌고, 약 10년 간 수행한 총 직업력을 전체적으로 고려할 때 상기 상병의 업무관련성은 높다고 판단됩니다.
                        </td>
                    </tr>
                    <tr>
                        <td>작업관련정도</td>
                        <td style="text-align:left;">
                            <ul class="job_check_list">
                                <li class="job_check_item">□&nbsp;&nbsp;확실함(Definite)</li>
                                <li class="job_check_item">■&nbsp;&nbsp;가능성 높음(Probable)</li>
                                <li class="job_check_item">□&nbsp;&nbsp;가능함(Possible)</li>
                                <li class="job_check_item">□&nbsp;&nbsp;가능성 희박하나 의심됨(Suspected)</li>
                                <li class="job_check_item">□&nbsp;&nbsp;가능성 없음(None)</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              위 소견과 같이 평가되었음을 증명합니다.
            </p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="depth02__title title contents">진료예약 055)270-1800</h4>
  </div>
</template>
<script>
export default {};
</script>
