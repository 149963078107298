<template>
    <!-- 건강증진센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>건강증진센터는 "건강한 삶"을 위한 최선의 방법을 찾기 위해 각 분야 전문 의료진에 의한 맞춤형 건강검진을 실시하여 질병의 조기 발견과 치료를 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>건강증진센터 상·하부 소화기내시경, 초음파 및 CT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">풍부한 임상경험을 가진 숙련된 전문의에 의한 안전한 검진</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>임상 경력 10년 이상의 전문의가 직접 검사와 상담을 진행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">지역 내 최고 수준의 검사환경과 아늑한 분위기에서 제공하는 검진 경험</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>최신 설비를 갖춘 내시경검사실과 안정을 위한 넓고 쾌적한 회복실을 갖추고 있으며, 편안하고 세련된 인테리어와 수검자의 프라이버시 및 동선을 고려한 공간구획으로 최상의 검진 경험을 제공하고 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">기본에 충실한 체계적이고 정밀한 헬스케어</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 건강증진센터는 개인별 특성을 고려한 다양한 건강검진 프로그램을 운영하고 있습니다. 전문 간호사와 상담을 통해 맞춤형 검진 후 각 전문 분야 전문의와 결과 상담을 지원합니다. 또한 단계별 중증도에 따라 체계적인 관리 플랜을 제시하여 건강한 삶을 유지할 수 있도록 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">유소견 발견 시 신속한 진료 연계</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>검진 실시 중, 후 이상 소견이 발견되는 즉시 해당 진료과 전문의에게 진료 받을 수 있도록 신속히 진료 연계를 실시하고 있으며, 중증질환 발견 즉시 수검자에게 해피콜 서비스를 통한 전화 상담을 제공하고 있습니다. 또한 수도권 Big5(삼성서울/서울대/서울성모/서울아산/세브란스)를 비롯한 상급 의료기관에 신속한 진료를 의뢰하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02 blue_bullet"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">스마트 검진 시스템으로 최적 동선 구현</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>RFID 시스템 등 스마트 검진체계와 최적의 동선으로 검사 시간을 최소화하며, 수검자의 검사 순서를 최우선으로 고려해 동선을 배치했습니다</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;" title="새 창 이동" target="_blank">건강증진센터</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>