<template>
    <div class="contents">
        <div class="info-text-box">
            <div class="icon"></div>
            <div class="text">
                <p>인터넷을 이용해 언제 어디서나 신청 발급이 가능합니다. <br>
                    <span>(23년 이후 진료분)</span>
                </p>
            </div>
        </div>

        <div class="contents depth__text">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">발급가능 서류</h4><!-- depth02 -->
                </div>
            </div>

            <div class="table-wrap table-type1">
                <div class="scroll">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                            <col style="width:20%; min-width: 50px;">
                            <col style="width:20%; min-width: 100px;">
                            <col style="width:20%; min-width: 100px;">
                            <col style="width:20%; min-width: 100px;">
                            <col style="width:20%; min-width: 100px;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>종류</th>
                                <th>진단명포함</th>
                                <th>발급가능년도</th>
                                <th>금액(건당)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>일반진단서(재발행)</td>
                                <td>포함</td>
                                <td>3년</td>
                                <td>1,100원</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>출생증명서(재발행)</td>
                                <td>포함</td>
                                <td>3년</td>
                                <td>1,100원</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="table-desc">증명서 사본발급 문의 : 270-1104 <br class="pc-none"> (1층 원무과 제증명창구)</p>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn blue">온라인 증명서 발급 바로가기</a>
            </div>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="">

</style>