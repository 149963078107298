<template>
    <div class="contents healthInfoDet">
        <div class="board-view__top">
            <ul class="board-view__share-list" :class="{active : this.shareList}">
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.clip"><img src="@/assets/resource/icon/copy-icon.png" alt="아이콘"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('band')"><img src="@/assets/resource/icon/band-icon.png" alt="아이콘"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('twitter')"><img src="@/assets/resource/icon/x-icon.png" alt="아이콘"></a></li>
                <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('facebook')"><img src="@/assets/resource/icon/facebook-icon.png" alt="아이콘"></a></li>
            </ul>

            <button type="button" class="board-view__btn share" @click="shareFn()"></button>
            <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
            <button type="button" class="board-view__btn print" @click="print()"></button>

        </div>
        <div class="board-view__wrap info-wrap">
            <div class="info__tit">
                <h3>{{ $route.query.key }}</h3>
                <!-- <ul class="board-view__share-list" :class="{active : this.shareList}">
                <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
                </ul>

                <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
                <button type="button" class="board-view__btn print" @click="print()"></button> -->
            </div>
            <div class="info__con">
                <div class="info__left" :class="{ 'active': isBbOnTop }">
                    <ul class="accordion_lnb">
                        <li v-for="(item, index) in list" :key="index" :class="{ 'active': accordionIndex === index }">
                            <a href="javascript:;" @click="activateAccordion(index)">{{ item.title }}</a>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="info__right">
                    <ul class="accordion">
                        <li  v-for="(item, index) in list" :key="index" class="accordion__item" :class="{ 'active': accordionIndex === index }">
                            <a href="javascript:;" class="accordion__header" @click="accordionActive($event, index)">
                                <div class="title">
                                    <h3>{{ item.title }}</h3>
                                    <sub class="text">{{ item.subtitle }}</sub>
                                </div>
                                <span class="accordion__arrow"></span>
                            </a>
                            <div class="accordion__con--wrap" ref="wrap">
                                <div class="accordion__con" ref="con">
                                    <div class="accordion__con--text">
                                        <template v-for="(i, idx) in item.value" :key="idx">
                                            <h3 v-if="i.depth == 1">{{ i.title }}</h3>
                                            <p v-if="i.depth == 1" v-html="i.contents"></p>
                                            <h4 v-if="i.depth == 2">{{ i.title }}</h4>
                                            <p v-if="i.depth == 2" v-html="i.contents"></p>
                                            <h5 v-if="i.depth == 3">{{ i.title }}</h5>
                                            <p v-if="i.depth == 3" v-html="i.contents"></p>
                                            <div class="file" v-if="i.file">
                                                <template v-for="(fileItem, fileIdx) in i.file" :key="fileIdx">
                                                    <img :src="fileItem.url" v-if="fileItem.board_type == 'I'" alt="이미지">
                                                    <video :src="fileItem.url" controls loop muted preload="auto" v-if="fileItem.board_type == 'M'" alt="비디오"></video>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                    <a href="javascript:;" class="accordion__con--botton" @click="accordion_close()">접음</a>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="nuri_type04 contents">
                        <img src="/resource/img/img_opentype04.png" alt="공공누리 4유형">
                        <p class="nuri_notice">
                            본 공공저작물은 공공누리 <span class="blue__title"><b>“출처표시+상업적이용금지+변경금지”</b></span> 조건에 따라 이용할 수 있습니다.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FavoriteHeartVue from '../common/FavoriteHeart.vue';

    export default {
        components:{
            FavoriteHeartVue
        },
        data() {
            return {
                shareList: false,
                isBbOnTop: false,
                accordionIndex: 0,
                list: [
                    {title: "요약설명", subtitle: "Summary explanation", value: []},
                    {title: "암이란", subtitle: "What is cancer", value: []},
                    {title: "예방", subtitle: "Prevention", value: []},
                    {title: "진단", subtitle: "Diagnosis", value: []},
                    {title: "치료", subtitle: "Treatment", value: []},
                    {title: "생활가이드", subtitle: "Life Guide", value: []}
                ],
                info: null
            };
            
        },
        methods: {
            shareFn() {
                this.shareList = !this.shareList
            },
            print(){
                window.print()
            },
            // info__left fixed 이벤트
            checkBbPosition() {
                const bbElement = document.querySelector('.info__con');
                if (bbElement) {
                    const bbPosition = bbElement.getBoundingClientRect().top;
                    this.isBbOnTop = bbPosition <= 0;
                }
            },

            // accordion__header 이벤트
            accordionActive(event,index) {
                const target = event.currentTarget;
                const parentLayer = target.closest('.accordion__item').querySelector('.accordion__con--wrap');
                if (this.accordionIndex === index) {
                    // 두 번째 클릭 시 높이를 0으로 설정
                    parentLayer.style.height = '0';
                    this.accordionIndex = -1; // 비활성화
                } else {
                    const layers = this.$el.querySelectorAll('.accordion__con--wrap');
                    layers.forEach(layer => {
                        layer.style.height = '0';
                    });
                    const ulHeight = parentLayer.querySelector('.accordion__con').offsetHeight + 'px';
                    parentLayer.style.height = ulHeight;
                    this.accordionIndex = index; // 활성화
                }
            },
            // 아코디언 닫기 이벤트
            accordion_close(event){
                    const layers = this.$el.querySelectorAll('.accordion__con--wrap');
                    layers.forEach(layer => {
                        layer.style.height = '0';
                    });
                    this.accordionIndex = -1; // 비활성화
            },
            // info__left 클릭 이벤트
            activateAccordion(index) {
                // 아코디언 닫기
                const layers = this.$el.querySelectorAll('.accordion__con--wrap');
                    layers.forEach(layer => {
                    layer.style.height = '0';
                });
                // 엑티브 되는 박스의 index
                this.accordionIndex = index;
                const accordionItem = this.$el.querySelectorAll('.accordion__item')[index];
                const accordionWrap = accordionItem.querySelector('.accordion__con--wrap');
                
                // 해당 아코디언 아이템의 내용 높이를 가져와서 설정
                const ulHeight = accordionWrap.querySelector('.accordion__con').offsetHeight + 'px';
                accordionWrap.style.height = ulHeight;
                setTimeout(() => {
                    
                    accordionItem.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
                }, 400);
                
                
            },
            getInfo(){
                const key = this.$route.query.key
                const params = {
                    name: key
                }

                this.axios.get(this.api.health.cancer, {
                    params: params
                })
                .then(res => {
                    const arr = this.$commonUtils.groupArrayByKey(res.data.result, 'menu_seq')
                    for(let i in arr){
                        let index;
                        if (i == 0) {
                            index = 0;
                        } else if (i >= 1 && i <= 3) {
                            index = 1;
                        } else if (i >= 4 && i <= 6) {
                            index = 2;
                        } else if (i >= 7 && i <= 10) {
                            index = 3;
                        } else if (i >= 11 && i <= 14) {
                            index = 4;
                        } else {
                            index = 5;
                        }

                        for(let j of Object.values(arr[i])[0]){
                            this.list[index]['value'].push(j)
                        }
                    }
                    this.list.forEach(function(obj) {
                        var items = obj['value'];

                        items.sort(function(a, b) {
                            return a.data_seq - b.data_seq;
                        });
                    });
                    this.$nextTick(() => {
                        if(this.$refs.con && this.$refs.con.length > 0){
                            const cH = this.$refs.con[0].offsetHeight
                            this.$refs.wrap[0].style.height = cH + "px"
                        }else{
                            console.error('첫번째accordion__item 요소를 찾을 수 없습니다.');
                        }
                    })
                })
            },
            favoInfo(){
                const query = this.$route.query
                this.axios.get(this.api.article.view + query.boardNo + '/' + query.articleNo, {
                    params: {
                        instNo: 1,
                        articleNo: query.articleNo,
                        boardNo: query.boardNo
                    }
                }).then(res => {
                    this.info = {}
                    this.info.favoYn = res.data.favoInfoYn == 'Y' ? 'Y' : 'N'
                    this.info.no = query.articleNo
                    this.info.type = 'BBS'
                    this.info.mypageYn = 'N'
                })
            }
        },
        created() {
            this.favoInfo()
        },
        mounted() {
            this.getInfo()
            // info__left 스크롤 이벤트
            window.addEventListener('scroll', this.checkBbPosition);

            // 첫번째 accordion__item 기본 엑티브
            // const first_parentLayer = document.querySelector('.accordion__item:first-of-type').querySelector('.accordion__con--wrap');
            // const first_ulHeight = first_parentLayer.querySelector('.accordion__con').offsetHeight + 'px';
            // console.log(first_parentLayer)
            // console.log(first_ulHeight)
            // if (first_parentLayer) {
            //     first_parentLayer.style.height = first_ulHeight;
            // }else {
            //     console.error('첫번째accordion__item 요소를 찾을 수 없습니다.');
            // }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.checkBbPosition);
        }
    };
</script>