<template>
    <div class="board-gallery ">
        <div class="board__search">
            <div class="board__select" :class="{ 'active': selectOn }" @click="selectFn">
                <select name="" id="">
                    <option value="전체">전체</option>
                    <option value="제목">제목</option>
                    <option value="글">글</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" placeholder="검색어를 입력해주세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>

        <ul class="board-gallery__list contents">
            <li class="board-gallery__item">
                <a href="javascript:;">
                    <img src="@/assets/resource/img/center-bg.png" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>[온생명CARE] 하천을 살리는 'EM 흙공 던지기' 행사 실시</h3>
                        <div class="board-gallery__info">
                            <span class="date">2024.05.22</span>
                            <span class="view"><span class="icon"></span>100</span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-gallery__item">
                <a href="javascript:;">
                    <img src="@/assets/resource/img/테스트이미지-사람.png" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>[온생명CARE] 하천을 살리는 'EM 흙공 던지기' 행사 실시'EM 흙공 던지기' 행사 실시'EM 흙공 던지기' 행사 실시'EM 흙공 던지기' 행사 실시</h3>
                        <div class="board-gallery__info">
                            <span class="date">2024.05.22</span>
                            <span class="view"><span class="icon"></span>100</span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-gallery__item">
                <a href="javascript:;">
                    <img src="@/assets/resource/img/center-bg.png" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>[온생명CARE] 하천을 살리는 'EM 흙공 던지기' 행사 실시</h3>
                        <div class="board-gallery__info">
                            <span class="date">2024.05.22</span>
                            <span class="view"><span class="icon"></span>100</span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-gallery__item">
                <a href="javascript:;">
                    <img src="@/assets/resource/img/center-bg.png" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>[온생명CARE] 하천을 살리는 'EM 흙공 던지기' 행사 실시</h3>
                        <div class="board-gallery__info">
                            <span class="date">2024.05.22</span>
                            <span class="view"><span class="icon"></span>100</span>
                        </div>
                    </div>
                </a>
            </li>

        </ul>

        <div class="paging">
            <a href="javascript:;" class="board__pagination-btn first">처음</a>
            <a href="javascript:;" class="board__pagination-btn prev">이전</a>
            <a href="javascript:;" class="board__pagination-btn num active"><span>1</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>2</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>3</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>4</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>5</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>6</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>7</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>8</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>9</span></a>
            <a href="javascript:;" class="board__pagination-btn num"><span>10</span></a>
            <a href="javascript:;" class="board__pagination-btn next">다음</a>
            <a href="javascript:;" class="board__pagination-btn last">마지막</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        methods: {

        },
        mounted() {
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>