<template>
  <div class="jobHealthCenter contents">
    <div class="btn-wrap right"><a href="/hospital/department-doctor/centerClinicDetail.do?deptCd=9" class="btn blue" title="새 창 이동" target="_blank">금연클리닉 바로가기</a></div>
    <div class="info-text depth__wrap contents">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              흡연은 본인뿐만 아니라 간접흡연으로 주변까지도 영향을 미칠 수 있으며, 국제암연구소는 간접흡연 역시 인체 발암성이 충분히 입증된 1급 발암물질로 분류하고 있습니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              아직까지도 흡연을 나쁜 생활습관 정도로만 여기는 사람들이 많지만, 흡연은 ‘니코틴 중독’이라는 질병입니다. 일부 흡연자들은 마음만 먹으면 언제든지 끊을 수 있는 습관으로 아직 마음을 먹지 않았을 뿐이라고 얘기하지만 흡연은 쉽게 고쳐지지 않습니다. 의지만으로 금연할 수도 있지만 너무나 어렵고 성공률도 약 3% 미만으로 낮습니다. 금연 의지만 있다면 수월하면서도 훨씬 효과적으로 금연할 수 있는 방법이 전문약물치료입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              창원파티마병원 직업환경의학과에서 2009년부터 금연치료를 시작한 이후 2024년 현재까지 약 4,500명 이상의 흡연자를 치료하고 있습니다. 순조롭게 1차 치료로 금연에 성공하는 환자들도 많지만 치료 도중 포기하는 환자들도 있었고, 1차 치료에 실패했지만 포기하지 않고 여러 차례 치료를 계속해서 끝내 성공하는 환자들도 많았습니다. 금연을 시작하기에 이미 늦었다고 생각하더라도, 몇 번의 실패를 거듭하더라도 본인이 포기하지 않으면 반드시 금연에 성공할 수 있습니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              저희 센터 내의 직업환경의학과 외래에서는 질환이나 나쁜 생활습관이 있는 근로자에게 알맞은 의학적 상담, 해당 진료과로 의뢰, 추적검사 또는 약물치료 등 적절한 의학적 관리를 제공하고 있으며, 본원과 보건대행사업장으로 계약이 되어있는 경우 사업장에서도 정기적인 추적검사와 상담으로 종합적이고 지속적인 관리를 하고 있습니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              나 자신과 소중한 가족들의 건강을 위해 더 이상 미루지 말고 금연을 시작하시기 바랍니다. 금연은 질병 예방과 건강 증진을 위한 가장 효과적이고 쉬운 방법입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <h4 class="depth02__title title contents">진료예약 055)270-1000</h4>
  </div>
</template>
<script>
export default {};
</script>
