<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>

    <div class="join contents">
        <div class="input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>아이디</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                                <button class="btn">중복확인</button>
                            </div>
                            <p class="desc">영문,숫자조합 3~12자리, 첫 자는 반드시 영문 소문자 사용</p>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                        <div class="cont">
                            <div class="password active">
                                <input type="text" class="cont__text-input">
                            </div>
                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                            <p class="desc">동일문자 연속 4개 사용금지</p>
                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>비밀번호 확인</div>
                        <div class="cont">
                            <!-- 조건 성립시 class='active' 추가-->
                            <div class="password">
                                <!-- 조건 미성립시 class='b-red' 추가-->
                                <input type="text" class="cont__text-input b-red" placeholder=" 영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지">
                            </div>
                            <p class="desc c-red">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>이름</div>
                        <div class="cont">이준용</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>휴대전화</div>
                        <div class="cont">010-1234-5678</div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box email">
                        <div class="tit"><span class="c-red">* </span>이메일</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input"> <span class="line">@</span>
                            <input type="text" class="cont__text-input">
                            <select class="cont__select">
                                <option value="직접입력">직접입력</option>
                                <option value="naver.com">naver.com</option>
                                <option value="gmail.com">daum.net</option>
                                <option value="gmail.com">gmail.com</option>
                                <option value="yahoo.com">yahoo.com</option>
                                <option value="nate.com">nate.com</option>
                                <option value="korea.com">korea.com</option>
                                <option value="chol.com">chol.com</option>
                            </select>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box phon">
                        <div class="tit">유선 전화번호</div>
                        <div class="cont row">
                            <input type="text" class="cont__text-input"> <span class="line">-</span>
                            <input type="text" class="cont__text-input"> <span class="line">-</span>
                            <input type="text" class="cont__text-input">
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box address">
                        <div class="tit">주소</div>
                        <div class="cont">
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                                <button class="btn">우편번호 검색</button>
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                            </div>
                            <div class="cont-item">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn">가입취소</a>
            <a href="javascript:;" class="btn blue">다음단계</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>