<template>
    <!-- 성장장건강대사증후군클리닉 -->
    <div class="info-text">
        <div class="btn-wrap right">
            <a href="javascript:;" class="btn blue">성장·장건강·대사증후군클리닉 FAQ 바로가기 </a>
        </div>
        <div class="board-view__file">
            <div class="tit">첨부파일</div>
            <ul class="file-list">
                <li class="file-item">
                    <a href="javascript:;" type="button" download="성장클리닉_임상정보지.hwp">
                        <span class="icon"></span>
                        <em class="num">1 .</em>장·장건강·대사증후군클리닉 임상정보지
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>어떤 아이들이 건강하게 자라나요? 우리 아이 건강하게 자라고 있는지 알 수 있을까요? 우리아이의 성장이 궁금합니다. 우리 아이는 장이 좋지 않은데...자녀를 키우면서 자녀의 건강을 걱정하시지 않은 부모는 없을 것입니다. 간단한 피 검사와 키, 몸무게를 통해 아이들의 건강을 알 수 있을까요? 최근에는 장내미생물 검사(마이크로바이옴 검사)를 통한 장건강을 비롯해 전반적인 건강상태를 점검하여 맞춤 유산균 선택, 음식 선택이 가능하게 되었습니다.</p>
                                <br>
                                <p>코로나 유행 이후에 아이들의 몸매가 너무나 커지고 있습니다. 이런 아이들은 조기에 대사증후군 질환과 각종 합병증에 시달릴 수 있어 조기 발견과 관리가 무엇보다 필요합니다</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">대상 환자들은 누구인가요?</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>키가 작다고 생각되는 경우</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>다른 아이들 보다 이차성징이 빠르다고 생각되는 경우</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑자기 체중이 늘어난 경우, 아이의 몸매가 예쁘지 않는 경우</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>비만, 당뇨, 고지혈증의 가족력이 있는 경우</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>1형, 2형 당뇨 대상 다수 연구에서 혈당조절 효과가 검증 됐습니다.</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>출생 시 몸무게가 작은 아이</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>배가 자주 아픈 아이</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>잘 먹지 않는 아이</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>머리가 자주 아픈 아이</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>아침을 안 먹고 학교 가는 아이</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">무슨 검사를 하게 되나요?</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>각종 혈액검사, 소변검사</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>장내 미생물 검사 : 기본검사로 하여 먹거리 선택을 도와줍니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>스트레스검사, 심리검사(선택)</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>성장판검사(선택)</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>청력, 시력검사(선택)</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>종합검진(혈액검사, 장건강검사, 성장판검사, 척추측만검사, 스트레스검사, 시력·청력검사, 심리검사)</p>
                            </div>
                        </div>   
                    </div>


                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">치료는 어떻게 하나요?</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>키가 작은 아이 : 성장호르몬 치료</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>이차성징 빠른 아이 : 성조숙증 치료</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>장내미생물 검사 결과를 기반으로 하여 먹거리 선택과 유산균 선택</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>고지혈증, 당뇨검사에서 이상이 있는 경우 치료 : 프리바이오틱스, 고지혈증 치료제 사용</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>생활습관 교정, 운동법 교육</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>장건강 수첩으로 먹거리 교육</p>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>


<script>
export default {
   
};
</script>