<template>
    <!-- 남성정밀코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">남성정밀코스</h3>
      <p class="desc_top">
        남성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한
        맞춤형 건강검진 프로그램입니다.
      </p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 :
        750,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="14" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능,
                  지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B,
                  C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사,
                  정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암,
                  전립선암)
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경 또는 저선량 흉부 CT 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>전립선초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>남성호르몬</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 남성정밀코스 -->

    <!-- 여성정밀A코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">여성정밀A코스</h3>
      <p class="desc_top">여성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 :
        850,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="17" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 난소암
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>갑상선초음파, 유방초음파</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                <td>골밀도검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>여성호르몬</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 여성정밀A코스 -->

     <!-- 여성정밀B코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">여성정밀B코스</h3>
      <p class="desc_top">여성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 :
        780,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="16" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 난소암
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>갑상선초음파, 유방초음파</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                <td>골밀도검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>여성호르몬</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 여성정밀b코스 -->

    <!-- 소화기정밀코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">소화기정밀코스</h3>
      <p class="desc_top">각종 성인병의 조기발견 및 소화기 계통의 질환, 암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 750,000원 / 여 800,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="13" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀)), 헬리코박터 면역검사
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                <td>골밀도검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 소화기정밀코스 -->

    <!-- 호흡기정밀코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">호흡기정밀코스</h3>
      <p class="desc_top">각종 성인병의 조기발견 및 폐질환, 폐암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~토요일<br />· 검사비용 : 남 650,000원 / 여 700,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="11" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암2종(NSE, CYFRA21-1), 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 객담세포진 검사, 저선량 흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 호흡기정밀코스 -->

    <!-- 순환기정밀코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">순환기정밀코스</h3>
      <p class="desc_top">각종 성인병의 조기발견 및 심혈관 질환의 조기진단과 예방, 흡연, 고혈압, 당뇨, 심장질환의 가족력 등 심혈관 질환의 위험인자가 있는 분들을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 800,000원 / 여 850,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="12" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK, CRF)
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도, 심장초음파, 심장CT(Ca-scoring)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>경동맥 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도 검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 순환기정밀코스 -->

    <!-- 뇌 정밀코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">뇌 정밀코스</h3>
      <p class="desc_top">각종 성인병의 조기발견, 뇌혈관 질환(뇌졸중 등)의 조기진단과 예방 및 가족력, 위험인자가 있는 분들을 위한 맞춤형 건강검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 1,350,000원 / 여 1,400,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="13" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                <td>뇌 MRI & MRA, 뇌혈류 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>경동맥 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도 검사, 스트레스 측정검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 뇌 정밀코스 -->

    <!-- 그린 코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">그린 코스</h3>
      <p class="desc_top">우리나라에서 유병률 및 발생률이 높은 생활습관병 및 암을 조기진단하기 위한 가장 기본적인 건강검진 프로그램 입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 2시간이상 / 월요일~토요일<br />· 검사비용 : 남 430,000원 / 여 480,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="10" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 그린 코스 -->

    <!-- 블루 코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">블루 코스</h3>
      <p class="desc_top">미래세대(수험생 또는 20대초반)의 신체적 특성을 고려한 전문건강검진 프로그램 입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 2시간이상 / 월요일~토요일<br />· 검사비용 : 남 320,000원 / 여 340,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="11" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(A, B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">척추측만검사</td>
                <td>요추 X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>스트레스 측정검사</td>
              </tr>
              <tr>
                <td style="text-align: center;">여성</td>
                <td>풍진검사</td>
                <td>풍진 항체검사</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 블루 코스 -->

    <!-- 골드 코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">골드 코스</h3>
      <p class="desc_top">중년이후의 남녀를 대상으로 갱년기에 흔히 발생할 수 있는 질환의 가능성을 확인하고 건강한 노후를 대비하고자 하시는 분을 위한 건강검진 프로그램 입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 920,000원 / 여 970,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="15" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암, 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량 흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>전립선초음파(♂), 유방초음파(♀)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                <td>골밀도검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>남성호르몬(♂), 여성호르몬(♀)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사, 동맥경화도 검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 골드 코스 -->

    <!-- 실버 코스 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">실버 코스</h3>
      <p class="desc_top">건강한 노후를 보내기 위해 협심증, 뇌경색, 암 등 조기검사가 필요한 항목들로 이루어진 포괄적인 건강검프로그램 입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 1,400,000원 / 여 1,450,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="14" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암, 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK, CRF)
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량 흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                <td>뇌 MRI & MRA</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>전립선초음파(♂), 유방초음파(♀)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증 검사</td>
                <td>골밀도검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>생체나이측정검사, 동맥경화도 검사, 뇌심혈관계발병 위험도 평가</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암 검사</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 실버 코스 -->

    <!-- 조기암(PET-CT) 검진 -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">조기암(PET-CT) 검진</h3>
      <p class="desc_top">건강증진코스(Green course)에 PET-CT를 시행하여 조기에 암을 발견하기 위한 암전문 검진 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 3시간이상 / 월요일~금요일<br />· 검사비용 : 남 1,350,000원 / 여 1,400,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="11" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">PET검사</td>
                <td>PET-CT</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 조기암(PET-CT) 검진 -->

    <!-- 종합정밀 Ⅰ(당일) -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">종합정밀 Ⅰ(당일)</h3>
      <p class="desc_top">MRI-MRA가 포함된 뇌심혈관계 질환 확인 및 주요장기의 암을 확인하기 위한 당일 종합정밀 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 약 4시간이상 / 월요일~금요일<br />· 검사비용 : 남 1,950,000원 / 여 1,990,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="16" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암, 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK, CRF)
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도, 심장초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파, 복부/골반CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                <td>뇌 MRI & MRA</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>전립선초음파(♂), 유방초음파(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증검사</td>
                <td>골밀도검사</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>남성호르몬(♂), 여성호르몬(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도 검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 종합정밀 Ⅰ(당일) -->

    <!-- 종합정밀 Ⅱ(숙박) -->
    <div ref="programInfo" class="accordion__con--text program_add_wrap" :style="programInfoStyle">
      <h3 class="depth02__title title">종합정밀 Ⅱ(숙박)</h3>
      <p class="desc_top">심장마비, 뇌졸중, 각종 주요장기의 암 등의 위험인자를 조기발견하기 위한 1박 2일 검진으로 검진 시 전담간호사가 동행하는 가장 광범위한 숙박 종합정밀 프로그램입니다.</p>
      <p class="desc_bottom">
        · 검사 소요시간 : 1박2일(숙박) / 월요일~목요일<br />· 검사비용 : 2,950,000원
      </p>
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="17" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                 일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암2종(NSE, CYFRA21-1), 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK, CRF), 헬리코박터 면역검사
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 객담세포진 검사, 저선량 흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도, 심장초음파, 심장CT(Ca-scoring), 운동부하검사, 홀터검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파, 복부/골반CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                <td>뇌 MRI & MRA, 뇌혈류 초음파</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>경동맥 초음파, 갑상선초음파, 전립선초음파(♂), 유방초음파(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증검사</td>
                <td>골밀도검사</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>남성호르몬(♂), 여성호르몬(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">척추측만검사</td>
                <td>요추 X-ray</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도 검사, 스트레스 측정검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 종합정밀 Ⅱ(숙박) -->
</template>

<script>
export default {
  
};
</script>

