<template>
    <div class="contents layout">
        <div class="layout__inner">
            <div class="layout__floor">
                <ul class="layout__floor-list" v-if="depthList['2'] != null">
                    <li class="layout__floor-item" :class="{active: current.floor == item.placeNm}" v-for="(item, idx) in depthList['2']" :key="idx">
                        <button type="button" @click="getLookAroundList(3, item)">{{item.placeNm}}</button>
                    </li>
                </ul>
            </div>
            <div class="layout__store">
                <ul class="layout__store-list">
                    <template v-for="(item, idx) in depthObj[current.placeNm]" :key="idx">
                        <li class="layout__store-item" :class="{active: current.name == item.name}" v-if="current.floor == item.floor">
                            <button type="button" @click="setCurrent(item)"><span>{{ item.seq }}</span>{{item.name}}</button>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="layout__map" v-if="img">
                <h3 class="tit" :class="current.floor && current.floor.indexOf('지하') > -1 ? 'b-floor' : ''"><span class="num">{{current.floor}}</span><span class="circle"></span></h3>
                <div class="pin" v-show="showPin"><img src="@/assets/resource/icon/pin.png" alt="이미지"></div>
                <img :src="`/displayFile.do?name=${ img.attachBag.lookAroundPhoto[0].savedName }`" :alt="img.attachBag.lookAroundPhoto[0].altValue" v-if="img.attachBag.lookAroundPhoto">
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            depthList:{},
            place:null,
            placeTypeList:[],
            placeType:'A',
            searchPlaceCd:'',

            img: '',
            pinArr: [],
            depthObj: {},
            current: {},
            goTwo: true,
            searchList: {},
            building: [],

            showPin: false
        }
    },
    methods: {
        getLookAroundList(depth, item, chng){
            var params = {
                instNo: 1
            }
            if(item) params.parentPlaceCd = item.placeCd;
            this.axios
                .get(this.api.lookAround.lookAroundList, {params})
                .then(res => {
                    this.depthList[depth] = res.data.list;
                    var totCnt = res.data.totCnt;

                    if(totCnt > 0){
                        this.getLookAroundList(depth + 1, this.depthList[depth][0]);
                    }else{
                        this.getLookAround(item.placeCd);
                    }

                    if(this.building == ''){
                        this.building = res.data.list;
                        this.current.placeNm = res.data.list[0].placeNm;
                        this.getPin();
                    }

                    if(chng != null) {
                        this.goTwo = chng;
                    }

                    if(depth == 2){
                        this.current.placeNm = item.placeNm;
                    }

                    if(depth == 3){
                        if(this.goTwo){
                            for (let i of this.depthList['2']) {
                                if (i.placeNm == '1층') {
                                    this.getImg(i.placeCd);
                                    this.goTwo = false;
                                    const move = setInterval(() => {
                                        if(this.pinArr.length > 0) {
                                            this.setCurrent(i);
                                            clearInterval(move);
                                        }
                                    }, 100);
                                    setTimeout(() => {
                                        clearInterval(move);
                                    }, 2000);
                                    break;
                                }
                            }
                        }else{
                            this.getImg(item.placeCd);
                            const move = setInterval(() => {
                                if(this.pinArr.length > 0) {
                                    this.setCurrent(item);
                                    clearInterval(move);
                                }
                            }, 100);
                            setTimeout(() => {
                                clearInterval(move);
                            }, 2000);
                        }
                    }
                })
        },
        getImg(placeCd){
            this.axios.get(this.api.lookAround.lookAround, {
                params: {
                    instNo: 1,
                    placeCd: placeCd,
                }
            }).then(res => {
                this.img = res.data.query;
            })
        },
        getLookAround(placeCd){
            this.axios
                .get(this.api.lookAround.lookAround,
                    {
                        params: {
                            instNo: 1,
                            placeCd: placeCd,
                        }
                    })
                .then(res => {
                    this.place = res.data.query;
                })
        },
        getSearchLookAround(depth, item, curItem){
            var params = {
                instNo: 1
            }
            if(item) params.parentPlaceCd = item.placeCd;
            this.axios
                .get(this.api.lookAround.lookAroundList, {params})
                .then(res => {
                    this.depthList[depth] = res.data.list;

                    if(depth == 2){
                        this.current.placeNm = item.placeNm;
                        for(let i of res.data.list){
                            if(i.placeNm == curItem.floor){
                                this.getSearchLookAround(depth + 1, i, curItem);
                            }
                        }
                    }

                    if(depth == 3){
                        this.getSearchLookAround(depth + 1, item, curItem);
                        this.getImg(item.placeCd);
                        this.setCurrent(curItem);
                    }

                    if(depth == 4){
                        this.getLookAround(item.placeCd);
                    }
                })
        },
        getPin(){
            this.axios.get(this.api.lookAround.pinList, {
                params: {
                    instNo: 1
                }
            })
            .then(res => {
                this.pinArr = res.data;
                this.getTypeList();

                if(this.pinArr.length == 0) return;
                // 2뎁스로 구분 짓은 array
                this.pinArr.forEach(item => {
                    var building = item.floor.split('_')[0];

                    this.depthObj[building] = this.depthObj[building] || [];

                    var floorInfo = {
                        floor: item.floor.replace(building + '_', ''),
                        name: item.name,
                        color: item.color,
                        seq: item.seq,
                        x: item.x,
                        y: item.y
                    };

                    this.depthObj[building].push(floorInfo);
                });

                // x, y값을 array 형태로 전환
                for(let i of this.building){                    
                    if(this.depthObj[i.placeNm] != null){
                        var grouped = {};

                        this.depthObj[i.placeNm].forEach(function (obj) {
                            var name = obj.name;
                            var floor = obj.floor;
                            if (!grouped[name + '_' + floor]) {
                                grouped[name + '_' + floor] = {
                                    name: name,
                                    x: [],
                                    y: [],
                                    floor: obj.floor,
                                    seq: obj.seq,
                                    color: obj.color,
                                };
                            }
                            grouped[name + '_' + floor].x.push(obj.x);
                            grouped[name + '_' + floor].y.push(obj.y);
                        });

                        this.depthObj[i.placeNm] = Object.values(grouped);
                    }
                }

                for(let i of this.building){
                    if(this.depthObj[i.placeNm] != null){
                        this.depthObj[i.placeNm].sort((a, b) => {
                            return a.seq - b.seq;
                        })   
                    }                    
                }
            })
        },
        simpleMove(xArr, yArr){
            var x = xArr;
            var y = yArr;

            $('.pin.multiple').remove();

            if (x == null && y == null) {
                $('.pin').removeClass('active');
            } else {
                $('.pin > img').addClass('active');
                let width = Number($('.layout__map  > img').css("width").replace('px', ''))
                if(width == 0) width = window.innerWidth > 1024 ? window.innerWidth * 0.56 : window.innerWidth - 51;
                let paddingTop = Number($('.layout__map').css('padding-top').replace('px', ''))
                let pointerX = Number($('.pin > img').css("width").replace('px', '')) || 44;
                let pointerY = Number($('.pin > img').css("height").replace('px', '')) || 56;
                if (x.length > 0) {
                    let xC = (width / 960) * Number(x[0]) - pointerX / 2
                    let yC = (width / 960) * Number(y[0]) * (8 / 9) - pointerY + (window.innerWidth > 1024 ? 0 : paddingTop);
                    
                    if ($('.pin').hasClass('active')) {
                        $('.pin').animate({ top: yC, left: xC }, 1000);
                    } else {
                        $('.pin').addClass('active');
                        $('.pin').animate({ top: yC, left: xC }, 0);
                    }

                    if (x.length > 1) {
                        for (let i = 1; i < x.length; i++) {
                            let xC = (width / 960) * Number(x[0]) - pointerX / 2
                            let yC = (width / 960) * Number(y[0]) * (8 / 9) - pointerY + (window.innerWidth > 1024 ? 0 : paddingTop);

                            const pointer = $('.pin').clone();
                            pointer.addClass('multiple');
                            pointer.css('top', yC + 'px');
                            pointer.css('left', xC + 'px');
                            pointer.find('p').text(this.current.name);
                            $('.pin').after(pointer);
                        }
                    }
                }
            }
        },
        setCurrent(item){
            var floor = item.floor != null ? item.floor : item.placeNm;
            var name = item.name;
            var x = item.x;
            var y = item.y;
            if(name == null){
                for(let i of this.depthObj[this.current.placeNm]){
                    if(floor == i.floor){
                        name = i.name;
                        x = i.x;
                        y = i.y;
                        break;
                    }
                }
            }
            
            var placeNm = '';
            this.current = { floor: floor, name: name, x: x, y: y, placeNm: this.current.placeNm };
            
            if(this.current.x != null && this.current.y != null){
                const move = setInterval(() => {
                    if($('.pin').length > 0) {
                        this.simpleMove(this.current.x, this.current.y);
                        clearInterval(move);
                    }
                }, 100);
                setTimeout(() => {
                    clearInterval(move);
                }, 2000);
            }else{
                $('.pin').removeClass('active');
            }
            this.showPin = true
        },
        getTypeList(){
            this.searchPlaceCd = '';
            this.placeTypeList = this.searchList[this.placeType];
        },
    },
    computed: {
    },
    mounted() {
        this.getLookAroundList(1);
    },
}
</script>