<template>
    <div>
        <div class="medical-chart">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">대기순번</h4>
                    <div class="contents loding">
                        <span v-for="item in 5" class="dot"></span>
                    </div>
                    <ul class="contents medical-chart__list">
                        <li class="medical-chart__item">
                            <div class="icon">
                                <img src="@/assets/resource/icon/medical-icon1.png" alt="이미지">
                                <div class="name">류마티스내과</div>
                            </div>
                            <div class="count">순번6</div>
                        </li>
                        <li class="medical-chart__item">
                            <div class="icon">
                                <img src="@/assets/resource/icon/medical-icon2.png" alt="이미지">
                                <div class="name">신경외과</div>
                            </div>
                            <div class="count">순번2</div>
                        </li>
                    </ul>
                    <p class="contents medical-chart__text">
                        진료 당일 진료과 방문하여 성함을 말씀하시고 도착 확인이 된 이후부터 대기 순번을 조회를 할 수 있습니다. <br>
                        <span class="c-red">※ 응급 환자나 응급 면담으로 인해 대기 순번이 조정될 수 있으며, 응급 시술 및 수술로 인해 대기시간이 지연 될 수있으니 양해 바랍니다.</span>
                    </p>
                </div>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn">
                    <div class="icon">
                        <img src="@/assets/resource/icon/refresh.png" alt="이미지">
                    </div> 새로고침
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>