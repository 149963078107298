<template>
    <!-- 심장혈관흉부외과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심장혈관흉부외과는 흉막(늑막)질환과 폐질환의 수술적 치료, 종격동 종양, 동맥질환, 하지정맥류 등에 대한 진단과 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>일반 흉부질환 (폐, 식도, 늑막, 횡격막, 늑골, 다한증, 흉곽기형, 기흉 등)</p>
                                <p>혈관질환 (말초동맥질환, 하지정맥류, 심부정맥혈전증, 레이노드증후군, 버거씨병, 동정맥루조성술 등)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">하지정맥류 진단과 치료에 풍부한 경험 보유한 의료진</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심장혈관흉부외과 전문의가 직접 초음파 검사를 시행해 수술 여부 및 정확한 수술 부위를 결정합니다. 치료 방법 또한 근치적 절제술 또는 열치료술(고주파) 등 정맥류 상태와 환자의 요구에 따라 수술 방법을 적용하고 있습니다. 척추마취를 통한 수술로 수술 시 통증이 없으며, 마취통증의학과 전문의가 직접 안전한 수면 마취를 유도하고 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">하지동맥질환 진단을 위한 단계적 검사 시스템</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>혈류량측정장비(Farcon Pro)를 통해 처음 내원한 환자에게 신속하고 간편하게 하지동맥질환의 유무를 파악합니다. 이상 소견이 있을 경우 추가적인 혈관 CT, 혈관촬영술 등을 통해 정밀 진단과 치료 부위를 결정하며, 심장혈관흉부외과와 영상의학과 전문의가 각각 전문 분야를 맡아 수술과 시술(스텐트 시술)을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">기흉 재발 감소를 위한 적극적 수술치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>기흉 환자의 재발 가능 부위를 발견하기 위해 입원 초기 폐정밀CT(MD-CT) 촬영을 시행하며, 결과에 따라 예방적 수술을 적극적으로 시행하여 재발률을 낮추고 있습니다. 또한 흉강경수술을 통해 수술 후 통증과 입원 기간을 최소화하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">갑상선암 클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>갑상선암은 우리나라에서 발생률이 가장 높은 암 중 하나입니다. 갑상선암은 조기에 발견하면 완치율이 높은 편이지만, 증상이 없는 경우가 많아 조기검진이 매우 중요합니다. 우리 병원 갑상선암 클리닉은 갑상선암 환자의 진단과 치료를 위해 전문 의료진 및 최첨단 시설로 맞춤형 치료계획을 제공하며 환자 중심의 치료서비스와 정기적인 추적 관찰 및 관리를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">대장(직장)암 클리닉,</a>
                                <a href="javascript:;">유방암 클리닉,</a>
                                <a href="javascript:;">비만대사수술 클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>