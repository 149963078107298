<template>
  <div class="healthCheckupInfo">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">검진절차</h4>
        </div>
      </div>
      <ul class="step-textbox">
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">상담 및 예약</h3>
              <!-- <img src="/resource/img/step_center01.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>방 문 : 창원파티마병원 4층 건강증진센터</p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>전 화 : 055)270-1200~1</p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    온라인 : 홈페이지<br />(상담시간 : 평일 09:00~17:00, 토요일
                    09:00~12:00)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">문진표작성<br />(온라인)</h3>
              <!-- <img src="/resource/img/step_center02.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>검진당일 내원 시 작성도 가능합니다.</p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    온라인으로 문진표 작성 시 종이문진표 작성은 필요하지
                    않습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">준비물 발송<br />및 예약 알림</h3>
              <!-- <img src="/resource/img/step_center03.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    건강검진 예약 시 : 준비물 택배발송<br />(채변통,
                    대장내시경용 약 등)
                  </p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>건강검진 실시 전 : 예약문자발송, 예약안내전화</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">건강검진실시</h3>
              <!-- <img src="/resource/img/step_center04.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    검진순서 : 문진 > 수납 > 탈의 > 검사진행 > 검사종료 > 귀가
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">결과상담</h3>
              <!-- <img src="/resource/img/step_center05.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    내원 상담, 우편발송, E-MAIL, 모바일 중 선택할 수 있습니다.
                  </p>
                </div>
              </div>
              <div class="depth__wrap info-reference">
                <div class="depth__bullet">
                  <p class="small-text">※</p>
                </div>
                <div class="depth__text">
                  <p class="small-text">
                    내원상담 : 건강검진 실시 7일 이후 전문의와 결과상담
                  </p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <p class="small-text">※</p>
                </div>
                <div class="depth__text">
                  <p class="small-text">
                    우편발송 : 건강검진 실시 7~10일 이후 등기발송
                  </p>
                </div>
              </div>
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <p class="small-text">※</p>
                </div>
                <div class="depth__text">
                  <p class="small-text">
                    검진이력 및 결과조회는 홈페이지에서 가능 – 본인인증 필요
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="step-textbox__item">
          <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
              <h3 class="depth02__title">유소견 관리</h3>
              <!-- <img src="/resource/img/step_center06.png" class="step-textbox__title--icon" alt="이미지"> -->
            </div>
            <div class="step-textbox__con">
              <div class="depth__wrap">
                <div class="depth__bullet">
                  <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                  <p>
                    유소견 발생 시 본원 외래 진료과로 즉시 연계해 드립니다.<br />(희망하는
                    경우 국내 대형병원 및 유수의 병원으로 진료의뢰 가능합니다.)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="contents btn-wrap center">
      <a href="javascript:;" class="btn blue">증명서/의무기록 사본발급</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>