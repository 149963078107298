<template>
    <td class="hart" :class="favoYn" @click="favoAddDel" v-if="info.type == 'BBS' && info.mypageYn == 'Y'"><span class="icon"></span></td>
    <a href="javascript:;" class="doctor-list--heart" :class="favoYn" @click="favoAddDel" v-if="info.type == 'DOCTOR' && info.mypageYn == 'N'"></a>
    <button class="board-view__btn heart" :class="favoYn" @click="favoAddDel" v-if="info.type == 'BBS' && info.mypageYn == 'N'"></button>
</template>

<script>
export default {
    props: {
        info: Object
    },
    data() {
        return {
            favoYn: this.info.favoYn == 'Y' ? 'on' : ''
            // favoYn: 'on'
        }
    },
    methods: {
        favoAddDel() {
            const path = this.$route.path
            if(this.$store.getters.getStatus){
                const txt = this.info.type == 'DOCTOR' ? '의료진' : '게시글'
                if(this.info.favoYn == 'Y'){
                    if (confirm(`관심 ${txt}을 해제하시겠습니까?`)) {
                        this.axios
                            .delete(this.api.favorite.delete, {
                                params: {
                                    favoNo: this.info.no,
                                    favoTypeCd: this.info.type
                                }
                            })
                            .then(res => {
                                if (res.status == 200) {
                                    this.favoYn = ''
                                    this.info.favoYn = 'N'

                                    alert(`해당 ${txt}이 즐겨찾기에서 해제되었습니다.`);
                                    if(path.indexOf('/mypage') > -1) this.$parent.getList()
                                } else {
                                    alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                                }
                            })
                    }
                }else{
                    this.axios
                        .get(this.api.favorite.add, {
                            params: {
                                favoNo: this.info.no,
                                favoTypeCd: this.info.type
                            }
                        })
                        .then(res => {
                            if (res.status == 200) {
                                this.favoYn = 'on'
                                this.info.favoYn = 'Y'

                                alert(`해당 ${txt}이 즐겨찾기에 등록되었습니다. 마이페이지 > 관심 정보에서 확인하실 수 있습니다.`);
                            } else {
                                alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                            }
                        })
                }
            }else{
                alert('로그인 후 이용하실 수 있습니다.')
                const url = this.$route.path.indexOf('/deptHp/') > -1 ? `/deptHp/${this.$route.params.deptCd}/${this.$route.params.hpUrl}/member/deptLogin.do` : '/member/login.do'
                this.$router.push(url)
            }
        },
    },
}
</script>