<template>
    <div class="tab-menu__wrap" v-if="!($route.params.deptCd && $route.params.hpUrl)">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('department')">진료과 안내</button></li>
                <li class="tab-menu__item on"><button type="button" class="tab-menu__btn">의료진 검색</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('centerClinic')">센터&클리닉</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('treatmentChart')">진료일정표</button></li>
            </ul>
        </div>
    </div>
    <div class="board__search" v-if="!($route.params.deptCd && $route.params.hpUrl)">
        <div class="board__select">
            <select name="" id="Sel_Inst" class="select_department" v-model="deptClsf" @change="getDept">
                <option value="A">진료과</option>
                <option value="C">센터</option>
                <option value="B">클리닉</option>
            </select>
        </div>
        <div class="board__select">
            <select name="" id="Sel_Inst" class="select_department" v-model="deptCd">
                <option value="">전체</option>
                <option :value="dept.deptCd" v-for="dept in deptList" :key="dept">{{dept.deptNm}}</option>
            </select>
        </div>
        <div class="board__input">
            <input type="text" id="Search_doc" placeholder="의료진 이름을 입력하세요." v-model="drName" @keyup.enter="search()">
            <button type="button" class="board__input-btn" @click=search()></button>
        </div>
    </div>
    <div class="initial-search" v-if="!($route.params.deptCd && $route.params.hpUrl)">
        <h3>초성검색</h3>
        <consonant @consonant="searchConsonant" :list="consonantList" ></consonant>
    </div>

    <div class="contents">
        <ul class="doctor-list">
            <template v-if="doctorList.length > 0">
                <li class="doctor-list__item" v-for="doctor in doctorList" :key="doctor">
                    <div class="doctor-list--img">
                        <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지" @error="errorImgCall">
                    </div>
                    <div class="doctor-list--con">
                        <div class="doctor-list--text">
                            <FavoriteHeartVue :info="favoInfo(doctor)"></FavoriteHeartVue>
                            <h3>{{doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName}}<sub>{{doctor.deptNm}}</sub></h3>
                            <h4>주진료분야</h4>
                            <p>{{doctor.doctorDept.special}}</p>
                        </div>
                        <div class="doctor-list--button">
                            <a :href="`/hospital/department-doctor/doctorDetail.do?drNo=${doctor.drNo}`" target="_blank">의료진소개</a>
                            <a href="javascript:;" @click="getAbsence(doctor)" v-if="doctor.doctorDept.drRsvOpenYn === 'Y'">진료예약</a>
                        </div>
                    </div>
                </li>
            </template>
            <li v-else class="doctor-list__data-none">
                <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                <h4 class="depth03__title">검색된 의료진이 없습니다.</h4>
            </li>
        </ul>
        <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
    </div>
</template>

<script>
import Consonant from '../common/Consonant.vue'
import Pagination from '../common/Pagination.vue'
import errorImg from '@/assets/resource/img/doctor-list--img.png'
import FavoriteHeartVue from '../common/FavoriteHeart.vue'
export default {
    components: {
        Consonant,
        Pagination,
        FavoriteHeartVue
    },
    data() {
        return {
            deptClsf: this.$route.query.deptClsf || 'A',
            deptCd: this.$route.query.deptCd || '',
            drName: this.$route.query.drName || '',
            deptList: '',
            doctorList: [],
            consonantList: '',

            currentNum: this.$route.query.currentNum || 1,
            pageRow: 10,
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,
            chosung: this.$route.query.chosung || '',
        }
    },
    watch: {
        $route(to, from){
            if(to.query != from.query){
                this.getDoctor()
            }
        }
    },
    methods: {
        goMenu(path){
            this.$router.push(`/hospital/department-doctor/${path}.do`)
        },
        getDept(){
            this.axios
                .get(this.api.department.list,
                    {
                        params: {
                            instNo: 1,
                            langType: 'kr',
                            deptClsf: this.deptClsf,
                        }
                    })
                .then(res => {
                    this.deptList = res.data.deptList
                })
        },
        getDoctor(){
            const searchParam = {
                startIndex: this.currentNum,
                pageRow: this.pageRow,
                drName: this.drName,
                langType: 'kr',
                instNo: 1,
                deptClsf: this.deptClsf,
                deptCd: this.deptCd,
                chosung: this.chosung
            }

            this.axios
                .get(this.api.doctor.doctorList,
                    {
                        params: searchParam
                    })
                .then(res => {
                    this.getDoctorConsonant()
                    this.doctorList = res.data.doctorList ? res.data.doctorList : []
                    this.totCnt = res.data.doctorTotCnt
                })
        },
        async getAbsence(info){
            await this.axios.get(this.api.doctor.absence, {
                params: {
                    drNo: info.drNo
                }
            }).then(res => {
                const reason = res.data.reason
                if(reason){
                    const startDt = new Date(res.data.staDt)
                    const endDt = new Date(res.data.endDt)
                    const today = new Date()
                    if(today >= startDt && today <= endDt){
                        alert(reason)
                        return
                    }
                }
                this.$store.commit('setReserveInfo', info)
                this.$router.push('/reserve/reservation.do')
            }).catch(err => {
                alert('오류가 발생했습니다.')
            })
        },
        getDoctorConsonant(){
            this.axios
                .get(this.api.doctor.doctorCons, {
                    params: {
                        instNo: 1,
                        deptClsf: this.deptClsf,
                        deptCd: this.deptCd
                    }
                })
                .then(res => {
                    const list = res.data.list
                    this.consonantList =  [
                        {cho:'ㄱ', disable: 'disable'},
                        {cho:'ㄴ', disable: 'disable'},
                        {cho:'ㄷ', disable: 'disable'},
                        {cho:'ㄹ', disable: 'disable'},
                        {cho:'ㅁ', disable: 'disable'},
                        {cho:'ㅂ', disable: 'disable'},
                        {cho:'ㅅ', disable: 'disable'},
                        {cho:'ㅇ', disable: 'disable'},
                        {cho:'ㅈ', disable: 'disable'},
                        {cho:'ㅊ', disable: 'disable'},
                        {cho:'ㅋ', disable: 'disable'},
                        {cho:'ㅌ', disable: 'disable'},
                        {cho:'ㅍ', disable: 'disable'},
                        {cho:'ㅎ', disable: 'disable'}
                    ]
                    for(let i of this.consonantList){
                        for(let j of list){
                            if(i.cho == j){
                                i.disable = ''
                            }
                        }
                    }
                })
        },
        searchConsonant(consonant) {
            this.chosung = consonant
            this.currentNum = 1
            this.drName = ''
            this.goPage()
        },
        search(){
            this.currentNum = 1
            this.chosung = ''
            this.goPage()
        },
        goPage() {
            const obj = {
                currentNum: this.currentNum, 
                drName: this.drName, 
                deptClsf: this.deptClsf, 
                deptCd: this.deptCd,
                chosung: this.chosung
            };
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj});
        },
        setNum(n){
            this.currentNum = n
            this.goPage()
        },
        errorImgCall(e){
            e.target.src = errorImg;
        },
        favoInfo(info){
            const obj = {}
            obj.favoYn = info.favoYn == 'Y' ? 'Y' : 'N'
            obj.no = info.drNo
            obj.type = 'DOCTOR'
            obj.mypageYn = 'N'
            return obj
        }
    },
    mounted() {
        this.getDept('A')
        this.getDoctor()
    },
}
</script>
<style>
    /* @import url('../../assets/resource/css/sub.css'); */
</style>

