<template>
    <div class="my_health_wrap contents">
        <div class="day_result_box">
            <div class="day_result_item my_day">
                <div class="calendar">
                    <div class="btn-wrap">
                        <a href="javascript:;" class="btn" @click="todayCalendar">오늘</a>
                    </div>

                    <div class="calendar__header">
                        <button class="calendar__header--button prev" @click="prevCalendar"></button>
                        <h4 class="calendar__header--title">{{calYear}}.{{calMonth}}</h4>
                        <button class="calendar__header--button next" @click="nextCalendar"></button>
                    </div>
                    
                    <div class="calendar__hcon">
                        <table>
                            <caption> 예약일 선택 테이블 </caption>
                            <colgroup>
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                                <col width="14.29%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>일</th>
                                    <th>월</th>
                                    <th>화</th>
                                    <th>수</th>
                                    <th>목</th>
                                    <th>금</th>
                                    <th>토</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- today: 금일 / pos: 일정 / active: 선택 -->
                                <!-- <tr v-for="item in arrChunk(calendarList)" :key="item"> -->
                                <tr v-for="item in arrChunk(calendarList)" :key="item">
                                    <td v-for="i in item" :key="i" :class="[i.dayType, {'active' : i.text == activeIndex}]">
                                        <a href="javascript:;" @click="[selectDate(i.year, i.month, i.day, $event), activeIndex = i.text]">
                                            {{ i.dayType == 'pastMonth' || i.dayType == 'futureMonth' ? '' : i.day }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="calendar__bottom">
                        <div class="agree-input">
                          <label for="all-health" class="agree-input__item">
                            <input type="radio" id="all-health" class="agree-input__radio" name="agree" value="ALL" v-model="selectHmCd">
                            <span class="icon"></span> 전체 
                          </label>
                          <label for="blood-power" class="agree-input__item">
                            <input type="radio" id="blood-power" class="agree-input__radio" name="agree" value="BP" v-model="selectHmCd">
                            <span class="icon"></span> 혈압 
                          </label>
                          <label for="blood-sugar" class="agree-input__item">
                            <input type="radio" id="blood-sugar" class="agree-input__radio" name="agree" value="BS" v-model="selectHmCd">
                            <span class="icon"></span> 혈당 
                          </label>
                          <label for="bmi-body" class="agree-input__item">
                            <input type="radio" id="bmi-body" class="agree-input__radio" name="agree" value="BM" v-model="selectHmCd">
                            <span class="icon"></span> 체질량 
                          </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="day_result_item my_health">
                <div class="health_register_wrap">
                  <p class="day_register"><b>{{this.$commonUtils.makeDateFormat(selectDay, '.')}}</b>&nbsp;측정결과</p>

                  <div class="measure_resister">
                      <ul class="measure_resister_list">
                        <li class="measure_resister_item resister_item01">
                          <h3>혈압</h3>
                          <img src="@/assets/resource/icon/bloodpower_icon.png" alt="아이콘">
                          <div class="resister_item_txt" v-if="selectItem != null && selectItem.BP != null">
                            <p>{{selectItem.BP.result}}</p>
                            <h4>{{selectItem.BP.record2}}~{{selectItem.BP.record1}}<span>&nbsp;mmHg</span></h4>
                          </div>
                          <div class="resister_item_txt resister_not_data" v-else>
                            <p class="not_data_txt">등록된 데이터가 없습니다.</p>
                          </div>
                          <button @click="this.$router.push('/mypage/health/bloodPressure.do')">+ &nbsp;혈압 등록</button>
                        </li>

                        <li class="measure_resister_item resister_item02">
                          <h3>혈당</h3>
                          <img src="@/assets/resource/icon/bloodsugar_icon.png" alt="아이콘">
                          <div class="resister_item_txt" v-if="selectItem != null && selectItem.BS != null">
                            <p>{{selectItem.BS.result}}</p>
                            <h4>{{selectItem.BS.record2}}<span>&nbsp;mg/dl</span></h4>
                          </div>
                          <div class="resister_item_txt resister_not_data" v-else>
                            <p class="not_data_txt">등록된 데이터가 없습니다.</p>
                          </div>
                          <button @click="this.$router.push('/mypage/health/bloodSugar.do')">+ &nbsp;혈당 등록</button>
                        </li>

                        <li class="measure_resister_item resister_item03">
                          <h3>체질량</h3>
                          <img src="@/assets/resource/icon/bmibody_icon.png" alt="아이콘">
                          <div class="resister_item_txt" v-if="selectItem != null && selectItem.BM != null">
                            <p>{{selectItem.BM.result}}</p>
                            <h4>{{Number(selectItem.BM.record3).toFixed(1)}}<span>&nbsp;mmHg</span></h4>
                          </div>
                          <div class="resister_item_txt resister_not_data" v-else>
                            <p class="not_data_txt">등록된 데이터가 없습니다.</p>
                          </div>
                          <button @click="this.$router.push('/mypage/health/bodyMass.do')">+ &nbsp;체질량 등록</button>
                        </li>

                      </ul>
                  </div>

                </div>
            </div>
        </div>

        <div class="contents health_info_wrap">
          <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
              <span class="bullet01"></span>
            </div>
            <div class="depth__text">
              <h4 class="depth01__title title">검사정보</h4>
            </div>
          </div>

          <div class="tab-menu__wrap">
            <div class="tab-menu type02">
                <ul class="tab-menu__list">
                <li class="tab-menu__item" :class="subHmCd == 'BP' ? 'active' : ''">
                    <button type="button" class="tab-menu__btn" @click.prevent="changeSubPage('BP');">혈압</button>
                </li>
                <li class="tab-menu__item" :class="subHmCd == 'BS' ? 'active' : ''">
                    <button type="button" class="tab-menu__btn" @click.prevent="changeSubPage('BS');">혈당</button>
                </li>
                <li class="tab-menu__item" :class="subHmCd == 'BM' ? 'active' : ''">
                    <button type="button" class="tab-menu__btn" @click.prevent="changeSubPage('BM');">체질량</button>
                </li>
                </ul>
            </div>
          </div>

          <div class="date_wrap contents">
            <div class="date-input">
                <VueDatePicker v-model="selectedDateRange" locale="ko"
                    :enable-time-picker="false" :format="customFormat"
                    :week-start="0" :day-class="getDayClass"
                    :select-text="'선택'" :cancel-text="'취소'"
                    :range="true" :multi-calendars="true"/>
              <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
            </div>

            <div class="tab-menu type_date">
              <ul class="tab-menu__list">
                <li :class="{'tab-menu__item': true, 'active': period === 3}">
                  <button type="button" class="tab-menu__btn" @click="changePeriod(3)">3개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': period === 6}">
                  <button type="button" class="tab-menu__btn" @click="changePeriod(6)">6개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': period === 12}">
                  <button type="button" class="tab-menu__btn" @click="changePeriod(12)">12개월</button>
                </li>
                <li :class="{'tab-menu__item': true, 'active': period === 24}">
                  <button type="button" class="tab-menu__btn" @click="changePeriod(24)">24개월</button>
                </li>
              </ul>
            </div>
            
            <div class="health_info_search">
              <button>검색<img src="@/assets/resource/icon/search-white.png" alt="이미지" @click="getChartList"></button>
            </div>

          </div>
        </div>

        <div class="contents">
            <div class="chart_wrap">
               <HealthChart :chartDataSet="chartDataSet" ref="HealthChart"/>
            </div>
        </div>

        <div class="contents pt60">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">측정 상세정보</h4>
                </div>
            </div>

            <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                <div class="scroll" >
                    <table class="scroll" v-show="subHmCd == 'BP'">
                        <caption class="blind">측정 상세정보</caption>
                        <colgroup>
                            <col style="width:5%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>선택</th>
                                <th>측정일자</th>
                                <th>측정시간</th>
                                <th>수축기혈압</th>
                                <th>이완기혈압</th>
                                <th>혈압상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="subDataList.BP.length == 0">
                                <td colspan="6"><p class="sub-txt">검색된 내용이 없습니다.</p></td>
                            </tr>
                            <tr v-for="(item, index) in subDataList.BP" :key="item.hmSn">
                                <td class="check">
                                    <input type="checkbox" :id="`checkBp${index}`" :value="item.hmSn" v-model="dataDel">
                                    <label :for="`checkBp${index}`"></label>
                                </td>
                                <td>{{$commonUtils.makeDateFormat(item.logDay, '.')}}</td>
                                <td>{{item.logDt.split(" ")[1]}} {{item.logDt.split(" ")[2]}}</td>
                                <td>{{item.record1}}</td>
                                <td>{{item.record2}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="scroll" v-show="subHmCd == 'BS'">
                        <caption class="blind">측정 상세정보</caption>
                        <colgroup>
                            <col style="width:5%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>선택</th>
                                <th>측정일자</th>
                                <th>측정시간</th>
                                <th>식전혈당</th>
                                <th>식후혈당</th>
                                <th>혈당상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="subDataList.BS.length == 0">
                                <td colspan="6"><p class="sub-txt">검색된 내용이 없습니다.</p></td>
                            </tr>
                            <tr v-for="(item, index) in subDataList.BS" :key="item.hmSn">
                                <td class="check">
                                    <input type="checkbox" :id="`checkBs${index}`" :value="item.hmSn" v-model="dataDel">
                                    <label :for="`checkBs${index}`"></label>
                                </td>
                                <td>{{$commonUtils.makeDateFormat(item.logDay, '.')}}</td>
                                <td>{{item.logDt.split(" ")[1]}} {{item.logDt.split(" ")[2]}}</td>
                                <td>{{item.record1 == '식전' ? item.record2 : ''}}</td>
                                <td>{{item.record1 == '식후' ? item.record2 : ''}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="scroll" v-show="subHmCd == 'BM'">
                        <caption class="blind">측정 상세정보</caption>
                        <colgroup>
                            <col style="width:auto;">
                            <col style="width:19%;">
                            <col style="width:19%;">
                            <col style="width:14%;">
                            <col style="width:14%;">
                            <col style="width:14%;">
                            <col style="width:14%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>선택</th>
                                <th>측정일자</th>
                                <th>측정시간</th>
                                <th>키</th>
                                <th>몸무게</th>
                                <th>BMI 지수</th>
                                <th>판정분류</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="subDataList.BM.length == 0">
                                <td colspan="7"><p class="sub-txt">검색된 내용이 없습니다.</p></td>
                            </tr>
                            <tr v-for="(item, index) in subDataList.BM" :key="item.hmSn">
                                <td class="check">
                                    <input type="checkbox" :id="`checkBm${index}`" :value="item.hmSn" v-model="dataDel">
                                    <label :for="`checkBm${index}`"></label>
                                </td>
                                <td>{{$commonUtils.makeDateFormat(item.logDay, '.')}}</td>
                                <td>{{item.logDt.split(" ")[1]}} {{item.logDt.split(" ")[2]}}</td>
                                <td>{{item.record1}}</td>
                                <td>{{item.record2}}</td>
                                <td>{{Number(item.record3).toFixed(1)}}</td>
                                <td>{{item.result}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="contents btn-wrap right"><a href="javascript:;" class="btn remove_btn" @click="delItems">삭제</a></div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import HealthChart from '@/components/common/HealthChart.vue';

export default {
    components: {
        VueDatePicker,
        HealthChart,
    },
    setup() {
        const customFormat = 'yyyy.MM.dd'; 
        const selectedDateRange = ref([new Date(), new Date()]);
        const selectedPeriod = ref(3);

        const setSelectedPeriod = (months) => {
            const startDate = new Date();
            const endDate = new Date();
            startDate.setMonth(startDate.getMonth() - months);
            selectedDateRange.value = [startDate, endDate]; 
            selectedPeriod.value = months; 
        };

        const getDayClass = (date) => {
            const day = date.getDay();
            if (day === 0) {
            return 'sunday-class';
            } else if (day === 6) {
            return 'saturday-class';
            }
            return '';
        };
            
        const addClass = (event) => {
            event.currentTarget.classList.add('on');
        };

        onMounted(() => {
            setSelectedPeriod(3);
        });

        return {
            customFormat,
            selectedDateRange,
            selectedPeriod,
            setSelectedPeriod,
            getDayClass,
            addClass
        };
    },
    watch:{
		selectHmCd(){
            this.calendarList.forEach(obj => {
                obj.dayType = obj.dayType.replace(/pos/g, '').trim();
            });

            const stringYear = this.calYear.toString()
            const stringMonth = this.calMonth.toString()
            this.calendarList.forEach(i => {
                if(i.year == stringYear && i.month == stringMonth){
                    const hasPos = this.healthScheduleData.some(j => {
                        if (this.selectHmCd === 'ALL') {
                            return i.text === j.date;
                        } else {
                            return i.text === j.date && j[this.selectHmCd] > 0;
                        }
                    });

                    if (hasPos) {
                        i.dayType += ' pos';
                    }
                }
            });

		}
    },
    data() {
        return {
            healthAllList: '',
            healthScheduleData: '',
            selectHmCd: 'ALL',


            // 캘린더
            classList: '',
            startDate: '',
            endDate: '',
            today: new Date(),
            calendarList: '',
            calYear: '',
            calMonth: '',
            activeIndex: '',

            toDay: this.$commonUtils.getToday(),
            selectDay: '',
            selectItem: {},
            hmCdList : ['BP', 'BS', 'BM'],
            subHmCd: 'BP',
            subDataList : {
				'BP' : [],
				'BS' : [],
				'BM' : []
			},
            period: 3,
            dataDel: [],

            chartDataSet: {}
        }
    },
    methods: {
        getList(){
            this.axios.get(this.api.mypage.healthList)
            .then(response => {
                let prevDate = '';
				this.healthAllList = response.data.result.list;
                this.healthScheduleData = []

                let arr = []
				this.healthAllList.forEach(item => {
                	if(prevDate !== item.logDay){
                		prevDate = item.logDay;
                		this.healthScheduleData.push( { 'date' : prevDate, 'BP' : 0, 'BS' : 0, 'BM' : 0 } );
                	}
                    const obj = {}
                    obj.text = item.logDay
                    obj.date = item.logDt
                    obj.dayType = 'pos'
                    arr.push(obj)
                });
                this.classList = arr
				
				this.healthScheduleData.forEach(item => {
                	this.healthAllList.forEach(item2 => {
                		if(item.date == item2.logDay){
							if(item2.hmCd == 'BP'){
								item.BP =+ 1;
							}
							if(item2.hmCd == 'BS'){
								item.BS =+ 1;
							}
							if(item2.hmCd == 'BM'){
								item.BM =+ 1;
							}
                		}
                	});
                });

                if(this.classList){
                    for(let i of this.calendarList){
                        for(let j of this.classList){
                            if(i.text == j.text){
                                i.dayType += ` ${j.dayType}`
                            }
                        }
                    }
                }


                this.selectDay = this.toDay
                this.selectDate2(this.toDay)
                this.getChartList()

                const type = this.$route.query.type
                if(type){
                    this.$nextTick(()=>{
                        this.selectHmCd = type
                        this.changeSubPage(type)
                    })
                }
            });
        },
        selectDate(year, month, day, event){
            this.selectDate2(year + '' + month + '' + day, event);
        },
		selectDate2(_date, event){
			this.tempDay = _date;
            this.healthScheduleData.forEach(item => {
				if(this.tempDay === item.date){
					document.querySelectorAll('.calendar  td').forEach(el => {
                        el.classList.remove('active')
                    })

					if(event){
						event.target.closest('td').classList.add('active');
					}else{
						document.querySelector('.today').classList.add('active');
					}

					this.selectDay = this.tempDay;
				}
			});

			this.selectItem = {};
			this.hmCdList.forEach(cd => {
				for(var i = 0; i < this.healthAllList.length; i++ ){
					if(this.selectDay == this.healthAllList[i].logDay && cd == this.healthAllList[i].hmCd){			
						this.selectItem[this.healthAllList[i].hmCd] = this.healthAllList[i];
						break;
					}
				}
			});
		},
        changePeriod(_period) {
		    this.period = _period;
			var startDate = new Date();
			var endDate = new Date();

			startDate.setMonth(startDate.getMonth() - this.period);

			this.selectedDateRange = [startDate, endDate];
		    this.getChartList();
		},   	
        getChartList(){
        	var searchParam = {
        		'inqrStrtYmd' : this.$commonUtils.dateToStr(this.selectedDateRange[0],''),
        		'inqrFnshYmd' : this.$commonUtils.dateToStr(this.selectedDateRange[1],'')
        	};

			this.subDataList = {
				'BP' : [],
				'BS' : [],
				'BM' : []
			};

			this.healthAllList.forEach(item => {
				if(searchParam.inqrStrtYmd <= item.logDay && searchParam.inqrFnshYmd >= item.logDay){
					if(item.hmCd == 'BP'){
						this.subDataList.BP.push(item);
					}
					if(item.hmCd == 'BS'){
						this.subDataList.BS.push(item);
					}
					if(item.hmCd == 'BM'){
						this.subDataList.BM.push(item);
					}
				}
			});

            this.chartDataSet.labels = []
            this.chartDataSet.record1 = []
            this.chartDataSet.record2 = []
            for(let i of this.subDataList[this.subHmCd].sort((a, b) => a.logDay - b.logDay)){
                if(this.subHmCd == 'BM'){
                    this.chartDataSet.labels.push(this.$commonUtils.makeDateFormat(i.logDay, '.'))
                    this.chartDataSet.record1.push(Number(i.record3).toFixed(1))
                }else{
                    this.chartDataSet.labels.push(this.$commonUtils.makeDateFormat(i.logDay, '.'))
                    this.chartDataSet.record1.push(i.record1)
                    this.chartDataSet.record2.push(i.record2)
                }
            }
            this.chartDataSet.type = this.subHmCd
		},
        changeSubPage(_cd) {
			this.subHmCd = _cd;
			this.dataDel = [];
			this.changePeriod(3);
            this.getChartList()
            this.$refs.HealthChart.selectedDataset = 'all'
		},
        delItems(){
			if(this.dataDel.length == 0){
				alert('삭제할 항목을 선택해주세요.');
				return false;
			}else{
				if(confirm('선택한 항목을 삭제하시겠습니까?')){
					this.axios({
						method:"post",
						url: this.api.mypage.healthDel, 
						params: { 'delSeq' : this.dataDel},
						paramsSerializer : function(paramObj) {
							const params = new URLSearchParams()
							for (const key in paramObj) {
								params.append(key, paramObj[key])
							}

							return params.toString();
						}
					}).then(response => {
						this.getList();
					});
				}
			}
		},

        /**
         * 이번 달의 첫 날, 마지막 날 구하기
         */
        init(){
            this.startDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
            this.endDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
            this.createCalendar();
        },
        /**
         * 달력 생성
         */
        createCalendar(){
            this.activeIndex = ''
            this.calendarList = [];
            this.calYear = this.today.getFullYear();
            this.calMonth = this.$commonUtils.leftPad(this.today.getMonth() + 1);

            if(this.startDate.getDay() > 0){
                let preLastSunDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), ((this.startDate.getDay() - 1) * -1));
                let preLastDate = new Date(preLastSunDate.getFullYear(), preLastSunDate.getMonth() + 1, 0);

                // 지난달 구하기
                for (let preDay = preLastSunDate; preDay <= preLastDate; preDay.setDate(preDay.getDate() + 1)) { 
                    let date = {
                        year : preDay.getFullYear(),
                        month : this.$commonUtils.leftPad(preDay.getMonth() + 1),
                        day : this.$commonUtils.leftPad(preDay.getDate()),
                        week : preDay.getDay(), // 0~6
                        text : this.$commonUtils.dateToStr(preDay, ''),
                        dayType : 'pastMonth'
                    }

                    this.calendarList.push(date);
                }
            }

            let today = new Date();
            today.setHours(0, 0, 0, 0); // 시간 초기화

            // 이번달 구하기
            for (let nowDay = this.startDate; nowDay <= this.endDate; nowDay.setDate(nowDay.getDate() + 1)) { 
                let date = {
                    year : nowDay.getFullYear(),
                    month : this.$commonUtils.leftPad(nowDay.getMonth() + 1),
                    day : this.$commonUtils.leftPad(nowDay.getDate()),
                    week : nowDay.getDay(),
                    text : this.$commonUtils.dateToStr(nowDay, ''),
                    dayType : ''
                }

                if (nowDay < today) {
                    date.dayType = "pastDay";
                } else if (nowDay.getFullYear() == today.getFullYear() 
                            && nowDay.getMonth() == today.getMonth() && nowDay.getDate() == today.getDate()) {    
                    date.dayType = "today";
                }else {
                    date.dayType = "futureDay";
                }

                this.calendarList.push(date);
            }
           
            // 다음달 구하기
            if(this.endDate.getDay() < 6){
                let nextFirstDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() + 1, 1);
                let nextFirstSunDate = new Date(nextFirstDate.getFullYear(), nextFirstDate.getMonth(), ((this.endDate.getDay() - 7 ) * -1) -1);
                
                for (let nextDay = nextFirstDate; nextDay <= nextFirstSunDate; nextDay.setDate(nextDay.getDate() + 1)) { 
                    let date = {
                        year : nextDay.getFullYear(),
                        month : this.$commonUtils.leftPad(nextDay.getMonth() + 1),
                        day : this.$commonUtils.leftPad(nextDay.getDate()),
                        week : nextDay.getDay(),
                        text : this.$commonUtils.dateToStr(nextDay, ''),
                        dayType : 'futureMonth'
                    }

                    this.calendarList.push(date);
                }
            }

            // dayType에 class 부여
            const stringYear = this.calYear.toString()
            const stringMonth = this.calMonth.toString()
            if(this.classList){
                for(let i of this.calendarList){
                    for(let j of this.classList){
                        if(i.text == j.text && i.year == stringYear && i.month == stringMonth){
                            i.dayType += ` ${j.dayType}`
                        }
                    }
                }
            }
        },
        todayCalendar(){
            this.today = new Date();
            this.init();
            this.selectDay = this.toDay
            this.selectDate2(this.toDay)
        },
        prevCalendar(){
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
            this.selectHmCd = 'ALL'
            this.init();
        },
        nextCalendar(){
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
            this.selectHmCd = 'ALL'
            this.init();
        },
        arrChunk(arr){
            return arr.reduce(function (acc, val, index) {
                const chunkIndex = Math.floor(index / 7);
                if (!acc[chunkIndex]) {
                    acc[chunkIndex] = [];
                }
                acc[chunkIndex].push(val);
                return acc;
            }, []);
        },
    },
    created() {
        this.init();
        this.getList()
    },
};

</script>
