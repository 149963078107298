<template>
  <div class="jobHealthCenter contents job_health_check">

    <div class="info-text depth__wrap contents">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">검진 전 유의사항</h4>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검진시간은 (월~금)8:40 ~ 12:00, 13:40 ~ 16:40, (토)08:40 ~ 12:00 입니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검진 전 8시간 이상 금식하시기 바랍니다.(커피, 우유 포함 일체 음식물)</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검사 전 3시간 이내에는 금연하시기 바랍니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검사 전날에는 심한 운동, 과로, 음주, 과식을 삼가십시오.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>생리 기간 중 검진할 경우 결과에 영향을 줄 수 있으므로 피해 주십시오.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>국민보험공단 관련 검진 시(일반검진, 국가암검진) 국민건강보험공단에서 발송한 건강진단표 또는 신분증을 지참 하시고, 건강진단표에 기재된 주의 사항을 읽어 보신 후 내원해 주시기 바랍니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>채용건강검진은 공휴일을 제외한 4일 후에 결과가 나옵니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>공무원채용검진 및 기타신체검사의 경우 신분증과 6개월 이내 촬영한 증명사진(3*4) 1매가 필요합니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>생물학적 노출지표 검사 시 소변 채취 시간에 따라 검사 값이 크게 차이가 날 수 있습니다.</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검사 전에는 되도록 소변을 참아 주시고 물을 너무 섭취하지 마십시오.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">예약 시 유의사항</h4>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>특수검진 예약은 희망일 10일 전 전화(055-270-1051~3)로 연락해 주시기 바랍니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>국가암 검진 중 위암, 간암, 폐암 대상자분은 반드시 사전예약을 하시기 바랍니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>특수건강검진은 수검업체의 인원과 작업공정, 유해물질 등을 정확히 파악해 주시기 바랍니다.</p>
          </div>
        </div>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>신규 거래업체는 검진 전에 이메일 fatimaoem@naver.com로 사업자 등록증 및 작업환경측정서를 보내 주십시오.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">예약 시 확인사항</h4>
        <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>예약 단위 선택 : 개인/사업장</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>성명 또는 사업장명</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>수검 인원수 : 예) 특수 00명, 일반 00명, 채용 00명, 기타 00명</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>예약자 정보 : 성명, 생년월일, 부서, 작업공정, 입사일, 전화번호</p>
          </div>
        </div>
         <div class="depth__wrap depth02">
          <div class="depth__bullet"><span class="bullet03"></span></div>
          <div class="depth__text">
            <p>검진 희망 일시</p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="depth02__title title contents">문의 : 직업환경의학센터 270-1051~3</h4>
  </div>
</template>
<script>
export default {};
</script>