<template>
    <div class="container">
        <div class="sub-tit">
            <h2>{{info.deptNm}}</h2>
        </div>
        <div class="inner">
            <div class="info-text">
                <div class="btn-wrap right clinic" data-aos="fade-up" data-aos-duration="1000">
                    <a href="javascript:;" class="btn blue" @click="goReservationDept(info)" v-if="info.rsvOpenYn == 'Y'">
                        <img src="/resource/icon/depart_reserve_icon.png" alt="이미지">진료예약</a>
                    <router-link to="/hospital/department-doctor/department.do" class="btn mint">
                    <img src="/resource/icon/depart_potal_icon.png" alt="이미지">전체 진료과</router-link>
                </div>
                <div class="board-view__file" v-if="info.attachBag && info.attachBag.departmentFile" data-aos="fade-up" data-aos-duration="1500">
                    <div class="tit">첨부파일</div>
                    <ul class="file-list">
                        <li class="file-item" v-for="file in info.attachBag.departmentFile" :key="file">
                            <button type="button" @click="$commonUtils.fileDownload(file.attachNo, file.displayName)"><span class="icon"></span>{{file.displayName}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-text" v-if="$commonUtils.removeTags(info.greetings)">
                <div class="info-text depth__wrap depth01"  data-aos="fade-up" data-aos-duration="2000">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">소개</h4>
                    </div>
                </div>
                <p v-html="info.greetings"  data-aos="fade-up" data-aos-duration="2500"></p>
            </div>
            <div class="info-text" v-if="doctorList.length > 0">
                <div class="info-text depth__wrap depth01" data-aos="fade-up" data-aos-duration="1500">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">의료진</h4>
                    </div>
                </div>
                <div class="info__doctor-list" data-aos="fade-up" data-aos-duration="2000">
                    <div class="calendar__header">
                        <button class="calendar__header--button prev" @click="prevCalendar"></button>
                        <h4 class="calendar__header--title">{{$commonUtils.dateToStr(setMonday(nowDate), '.')}} <span>~</span> {{$commonUtils.dateToStr(setSunday(nowDate), '.')}}</h4>
                        <button class="calendar__header--button next" @click="nextCalendar"></button>
                    </div>
                    <ul class="doctor-list">
                        <li class="doctor-list__item" v-for="doctor in doctorList" :key="doctor">
                            <div class="doctor-list--img">
                                <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지" @error="errorImgCall">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <FavoriteHeartVue :info="favoInfo(doctor)"></FavoriteHeartVue>
                                    <h3>{{doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName}}<sub>{{doctor.doctorDept.deptNm}}</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>{{doctor.doctorDept.special}}</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;" @click="goDoctor(doctor.drNo)">의료진소개</a>
                                    <a href="javascript:;" @click="getAbsence(doctor)" v-if="info.rsvOpenYn == 'Y' && doctor.doctorDept.drRsvOpenYn == 'Y'">진료예약</a>
                                </div>
                            </div>
                            <div class="weekly__calendar">
                                <div class="weekly__wrap">
                                    <div class="weekly__none-inner" v-if="!doctor.scheduleStatus">
                                        <div class="weekly__none">
                                            <p>조회 가능한 진료 일정이 없습니다.</p>
                                            <img src="@/assets/resource/icon/search-img.png" alt="이미지">
                                        </div>
                                    </div>
                                    <table>
                                        <caption class="blind">의료진시간표</caption>
                                        <colgroup>
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                            <col style="width:calc(100% / 7);">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>진료시간</th>
                                                <th v-html="getWeek()[0]"></th>
                                                <th v-html="getWeek()[1]"></th>
                                                <th v-html="getWeek()[2]"></th>
                                                <th v-html="getWeek()[3]"></th>
                                                <th v-html="getWeek()[4]"></th>
                                                <th v-html="getWeek()[5]"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>오전</td>
                                                <td v-for="am in doctor.am" :key="am"><span :class="am.value"></span></td>
                                            </tr>
                                            <tr>
                                                <td>오후</td>
                                                <td v-for="pm in doctor.pm" :key="pm"><span :class="pm.value"></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-text" v-if="$commonUtils.removeTags(info.medicalField)" data-aos="fade-up" data-aos-duration="1000">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">진료분야</h4>
                        <p v-html="info.medicalField"></p>
                    </div>
                </div>
            </div>
            <div class="info-text" v-if="$commonUtils.removeTags(info.content)" data-aos="fade-up" data-aos-duration="1500">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">특장점</h4>
                        <p v-html="info.content"></p>
                    </div>
                </div>
            </div>
            <div class="info-text" v-if="$commonUtils.removeTags(info.etc3)" data-aos="fade-up" data-aos-duration="2000">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">관련센터/클리닉</h4>
                        <p v-html="info.etc3"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import errorImg from '@/assets/resource/img/doctor-list--img.png'
import FavoriteHeartVue from '../common/FavoriteHeart.vue'
export default {
    components:{
        FavoriteHeartVue
    },
    data() {
        return {
            info: '',
            doctorList: '',
            nowDate: new Date,
            startDate: '',
            endDate: '',
        }
    },
     watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
    methods: {
        favoInfo(info){
            const obj = {}
            obj.favoYn = info.favoYn == 'Y' ? 'Y' : 'N'
            obj.no = info.drNo
            obj.type = 'DOCTOR'
            obj.mypageYn = 'N'
            return obj
        },
        getInfo() {
            const deptCd = this.$route.query.deptCd
            this.axios
                .get(this.api.department.view, {
                    params: {
                        langType: 'kr',
                        instNo: 1,
                        deptCd: deptCd
                    }
                })
                .then(res => {
                    if(!res.data){
                        alert('존재하지 않는 과입니다.')
                        this.$router.push('/hospital/department-doctor/department.do')
                        return
                    }
                    this.info = res.data
                    this.getSchedule()
                })
        },
        setMonday(now){
            now.setHours(0, 0, 0, 0)
            const day = now.getDay()
            const diff = now.getDate() - day + (day == 0 ? -6 : 1)
            const monday = new Date(now.setDate(diff))
            
            return monday
        },
        setSunday(now){
            const day = now.getDay()
            const diff = now.getDate() - day + 7
            const sunday = new Date(now.setDate(diff))
            
            return sunday;
        },
        getWeek() {
            const monday = new Date(this.nowDate);
            const dayOfWeek = this.nowDate.getDay();
            monday.setDate(this.nowDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
            
            const saturday = new Date(monday);
            saturday.setDate(monday.getDate() + 5);
            
            const result = [];
            const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
            for (let day = new Date(monday); day <= saturday; day.setDate(day.getDate() + 1)) {
                const dayOfWeek = weekdays[day.getDay()]; 
                const date = `${day.getMonth() + 1}/${day.getDate()}`; 
                result.push(`${dayOfWeek}<br class="mo-br">(${date})`);
            }
            return result;
        },
        prevCalendar(){
            const prev = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() - 7)
            if(prev < new Date()){
                alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                return
            }
            this.nowDate = prev
            this.getSchedule()
        },
        nextCalendar(){
            const next = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 7)
            const now = new Date()
            if(next > new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30)){
                alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                return
            }
            this.nowDate = next
            this.getSchedule()
        },
        getSchedule(){
            const deptCd = this.$route.query.deptCd
            this.axios.get(this.api.doctor.schedule, {
                params: {
                    fromdd: this.$commonUtils.dateToStr(this.setMonday(this.nowDate), ''),
                    todd: this.$commonUtils.dateToStr(this.setSunday(this.nowDate), ''),
                    deptCd: deptCd,
                    orddeptcd: this.info.emrDeptCd ? this.info.emrDeptCd : deptCd,
                    exposeYn: 'Y'
                }
            })
            .then(res => {
                this.doctorList = res.data

                for(let i of this.doctorList){
                    const schedule = i.schedule
                    let count = 0
                    if(schedule.length > 0){
                        const amObj = {
                            "MON": "",
                            "TUE": "",
                            "WED": "",
                            "THU": "",
                            "FRI": "",
                            "SAT": ""
                        }
                        const pmObj = {
                            "MON": "",
                            "TUE": "",
                            "WED": "",
                            "THU": "",
                            "FRI": "",
                            "SAT": ""
                        }
                        for(let j of schedule){
                            if(j.jinyn == 1 && j.day != "SUN"){
                                if(j.ampm == 'AM'){   
                                    amObj[j.day] = "check"
                                    count++
                                }
                                if(j.ampm == 'PM'){
                                    pmObj[j.day] = "check"
                                    count++
                                }       
                            }
                        }
                        
                        i.am = this.transObj(amObj)
                        i.pm = this.transObj(pmObj)
                    }
                    i.scheduleStatus = count > 0 ?  true : false
                }
            })
            .catch(err => {
                console.error(err)
            })
        },
        transObj(obj){
            const keys = Object.keys(obj)
            const arr = keys.map(key => ({
                key: key,
                value: obj[key]
            }))
            return arr
        },
        goReservationDept(info){
            info.drYn = 'N'
            this.$store.commit('setReserveInfo', info)
            this.$router.push('/reserve/reservation.do')
        },
        async getAbsence(info){
            await this.axios.get(this.api.doctor.absence, {
                params: {
                    drNo: info.drNo
                }
            }).then(res => {
                const reason = res.data.reason
                if(reason){
                    const startDt = new Date(res.data.staDt)
                    const endDt = new Date(res.data.endDt)
                    const today = new Date()
                    if(today >= startDt && today <= endDt){
                        alert(reason)
                        return
                    }
                }
                this.$store.commit('setReserveInfo', info)
                this.$router.push('/reserve/reservation.do')
            }).catch(err => {
                alert('오류가 발생했습니다.')
            })
        },
        goDoctor(drNo){
            const url = `/hospital/department-doctor/doctorDetail.do?drNo=${drNo}`
            window.open(url);
        },
        errorImgCall(e){
            e.target.src = errorImg;
        },
         checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
    },
    mounted() {
        this.getInfo();

        // var element = document.querySelector(".table-wrap.table-type1.scroll");
        // if (element) {
        //     element.addEventListener("click", function() {
        //         element.classList.add("on");
        //     });
        // }
    },
     updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>
