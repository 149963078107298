<template>
    <!-- 당뇨합병증클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 내분비대사내과에서는 당뇨병 환자의 최적 혈당 관리는 물론 당뇨병 관련 합병증을 체계적으로 예방, 관리, 치료함으로써 당뇨병 환자의 삶의 질 개선과 최상의 건강상태 유지를 위해 노력하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text heart_appointment center_box">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">합병증클리닉</h4>

                       
                    </div>
                </div>
                 <div class="depth__wrap">
                            <div class="depth__text">
                                <div class="table-cont" style="margin-top:0;">
                                    <ul class="table-list type03">
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">혈액검사 포함<br>기본검사</div>
                                            <div class="item" style="display:block">
                                               혈액검사를 포함한 <b>기본검사</b>는 당뇨병 환자의 <b>혈당 관리상태</b>와 <b>심혈관질환을 선별</b>하기 위한 검사로 합병증 위험도를 종합적으로 알려주는 중요한 검사입니다.
                                               <br><span class="blue__title">검사주기</span> 검사에 따라 다름<br><span class="blue__title">예방가능한 합병증</span> 뇌졸중, 고혈압, 동맥경화증, 고지혈증, 신부전 등
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">소변 및 혈액검사</div>
                                            <div class="item" style="display:block"><b>당뇨병성 신증</b>은 당뇨병 환자의 20~40%에서 발생하지만 초기 무증상인 경우가 많기 때문에 <b>투석치료</b>나 <b>신장이식</b>이 필요한 말기 신부전으로 진행하지 않도록 적극적인 혈당조절은 물론 적정 혈압유지 및 정기검사를 통한 신장합병증 발생 여부를 확인해야 합니다. 특히 신장은 한 번 손상되면 회복이 어려우므로 정기적인 검사가 중요합니다.
                                                <br><span class="blue__title">검사주기</span> 1년에 한 번 이상 정기검사<br><span class="blue__title">예방가능한 합병증</span> 만성 신부전증
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">안저검사</div>
                                            <div class="item" style="display:block"><b>당뇨병성 망막병증</b>은 당뇨병 유병기간이 길다면 더욱 조심해야할 합병증으로 합병증이 어느 정도 진행된 경우 <b>시력장애</b>가 올 수 있고, 더욱 진행되면 <b>망막 내 심각한 출혈에 의해 영구적 실명</b>이 올 수 있습니다. 그러나 상당히 진행되기 전까지 아무런 증상이 없는 경우가 대부분이기 때문에 정기검사를 통해 합병증 여부나 진행상황의 평가가 필요합니다.
                                                <br><span class="blue__title">검사주기</span> 1년에 한 번씩 정기검사<br><span class="blue__title">예방가능한 합병증</span> 당뇨망막병증, 백내장, 외안근마비, 신생혈관녹내장, 시신경병증 등
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">경동맥검사<br>및 관상동맥CT</div>
                                            <div class="item" style="display:block"><b>심뇌혈관질환</b>은 당뇨병 환자의 <b>가장 흔한 사망 원인</b>으로, 당뇨병이 없는 사람에 비해 남자는 2~3배, 여자는 3~5배 정도 위험도가 높아집니다. <b>뇌졸중 및 관상동맥질환을 포함한 심뇌혈관질환의 예방 및 조기 진단</b>을 위해 정기검사가 매우 중요합니다.
                                                <br><span class="blue__title">검사주기</span> 1년에 한 번씩 정기검사<br><span class="blue__title">예방가능한 합병증</span> 뇌졸중, 협심증, 심근경색
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">말초·자율 신경검사,<br>말초 동맥경화검사<br>(ABI/TBI)</div>
                                            <div class="item" style="display:block"><b>당뇨병성 신경병증</b>은 발가락, 발, 다리, 손, 팔과 같은 말초신경에 가장 빈번히 나타나며, 이로 인해 감각 기능이 소실되면 <b>당뇨병성 족부병변</b>이 생기기 쉽습니다. 족부병변으로 인한 <b>하지절단</b> 상대 위험률은 일반인의 40배 이상으로 진단과 관리가 매우 중요합니다. 관련 증상이 있거나 의심되는 경우, 그리고 관련 위험도가 높은 환자의 경우 말초.자율신경 및 말초동맥경화 검사를 주기적 실시하여 조기 발견 및 치료하는 것이 중요합니다.
                                                <br><span class="blue__title">검사주기</span> 1년에 한 번씩 정기검사<br><span class="blue__title">예방가능한 합병증</span> 족부 절단, 기립성 저혈압, 위장관계, 비뇨생식계 질환 등
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">자동화 당뇨병클리닉</h4>
                       
                    </div>
                </div>
                 <div class="depth__wrap">
                            <div class="depth__text">
                                <img src="/resource/img/center01.png" alt="연속혈당측정(CGMS)" style="background-color: #fef3df; display:block; padding:20px; width:100%; max-width:400px;">
                                <p>[이미지] 연속혈당측정기(CGMS)</p>
                                <br>
                                <p>팔이나 복부의 피하지방에 센서를 삽입하여 세포 간질액에서 혈당을 측정한 뒤 스마트폰 앱에 연동시켜 실시간으로 혈당을 확인할 수 있습니다.</p>
                            </div>
                        </div> 
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">연속혈당측정기의 특·장점</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>채혈 없이 혈당을 측정할 수 있습니다.</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>한 번 착용으로 장시간 혈당 측정이 가능합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>샤워 및 수영 등 일상생활이 가능합니다.</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>저혈당과 고혈당에 대한 알림 기능으로 응급상황 대처 및 약물, 식사, 운동 조절이 가능합니다.</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>1형, 2형 당뇨 대상 다수 연구에서 혈당조절 효과가 검증 됐습니다.</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>1형 당뇨병 치료 가이드라인에서 사용을 권장합니다.</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>2020년 1월부터 1형 당뇨병에서 건강보험 적용이 가능합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <img src="/resource/img/center02.png" alt="인슐린 펌프" style="background-color: #fef3df; display:block; padding:56px; width:100%; max-width:360px;">
                                <p>[이미지] 인슐린 펌프 </p>
                                <br>
                                <p>인슐린 펌프는 인슐린 다회 주사를 기계로 대체한 것입니다. 기저 인슐린 용량을 시간마다 다르게 설정 가능하고, 설정된 주입속도와 용량을 자동으로 주입해줍니다. 식사 인슐린은 주입 버튼을 누르면 추가로 주입 가능합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>자동인슐린전달장치 (인공췌장)</p>
                                <p>창원파티마병원 당뇨·합병증클리닉은 혈당을 최적 상태로 유지하는 자동인슐린전달시스템 (인공췌장)을 통해 맞춤 혈당조절 서비스를 제공하는 인공췌장클리닉을 운영하고 있습니다.</p>
                                <br>
                                <p>신속하고 이상적인 혈당조절을 위해 최신의 자동인슐린전달장치를 이용한 관리 시스템을 적용하고 있으며, 조속한 혈당 안정화와 향후 지속적인 최적혈당 관리방안을 모색합니다. 또한 환자의 평소 생활습관과 평가된 자료를 활용하여 환자별 개별화된 맞춤형 혈당조절 서비스를 제공합니다.</p>
                                <br>
                                <img src="/resource/img/center03.png" alt="연속혈당측정기 이미지" style="width:100%; max-width:700px">
                                <br>
                                <p>자동인슐린전달장치는 신체 외부에 장착된 연속혈당측정기와 자동화된 인슐린 펌프를 최신의 제어 알고리즘을 통해 유기적으로 결합하여 당뇨병 환자의 혈당을 최적의 상태로 유지 관리하는 인슐린 주입 시스템입니다. 특히 1형 당뇨병 환자와 다회인슐린주사요법을 유지 중인 2형 당뇨병 환자에게 많은 이익을 줄 수 있습니다.</p>
                                <br>
                                <p>자동인슐린전달장치를 활용하면 자가혈당측정과 다회인슐린주사요법의 불편함 없이 탄수화물 섭취량 확인만으로 최적의 인슐린 용량을 설정할 수 있고, 자동으로 세밀하게 혈당 조절이 가능해 고혈당 및 저혈당 등 부작용을 예방할 수 있습니다. 또한 혈당 데이터를 가족, 의료진 등 다른 사람과 공유하여 활용할 수 있다는 장점이 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>