<template>
    <!-- 신장내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신장내과는 사구체 질환, 급성 혹은 만성 신부전, 신장이식 등 여러 신장 질환의 진단 및 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>급만성신부전, 사구체신염, 신증후군, 당뇨병성 신질환, 고혈압성 신질환, 부종, 요로감염, 혈뇨, 단백뇨</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">사구체 질환의 안전하고 정확한 진단</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>풍부한 경험의 신장내과 전문의가 직접 신장 조직검사를 시행하고 있으며, 신장 병리 전문의 판독으로 정확한 진단과 우수한 치료 성과를 내고 있습니다. 또한 신장이식 대상자 판단 및 의학적 관리를 지원하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">최신 혈액투석여과 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>독립된 인공신장센터에 60개 병상을 운영하여 환자들의 신장 기능 회복 및 유지를 돕고 있습니다. 특히 일반적인 혈액투석이 아닌 ‘혈액투석여과’를 시행해 일반투석에서 제거할 수 없는 분자량이 큰 요독물질을 제거할 수 있어 신부전 환자에게 나타나는 여러 요독 증상을 줄일 수 있습니다. 또한 고효율 프리미엄 장비를 통해 매 투석 시 온라인 혈액투석여과를 실시하여 장기 투석 환자의 합병증을 예방하고 있습니다. 빈혈 교정, 심혈관질환 위험 감소 등에 효과적이며, 결과적으로 신부전 환자의 생존율 향상을 기대할 수 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">투석혈관 중재시술</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>성공적인 혈액투석을 위해서는 안정된 혈류량 유지가 중요하며, 이를 유지할 수 있는 투석혈관 혹은 투석도관이 필요합니다. 이러한 혈관 통로는 혈액투석환자의 생명선으로 투석 환자 건강의 필수적 요소라 할 수 있습니다. 안전하고 반복적으로 천자하기 편리하도록 피부에 가까우면서 굵기가 굵어 혈류량이 충분한 혈관은 자연 상태의 인체에서는 존재하지 않기 때문에 인위적으로 동맥과 정맥을 연결하는 수술을 통해 투석에 적합한 ‘투석혈관’을 만들어야 합니다. 투석혈관은 시간이 지남에 따라 좁아지거나 막히는 협착이 발생할 수 있으며, 이는 투석의 효율을 떨어트리고, 더 진행되어 혈전증으로 막혀 버리면 투석 자체를 시행할 수 없기 때문에 적절한 치료가 필요합니다. 창원파티마병원에서는 환자의 상태를 가장 잘 파악하고 있는 신장내과 전문의가 직접 ‘경피적 혈관성형술(Percutaneous Transluminal Angioplasty, PTA)’, ‘풍선확장술’ 등 투석혈관 중재 시술을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">인공신장센터</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>