<template>
    <MypagePopupVue ref="MypagePopupVue"></MypagePopupVue>

    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn"
                            @click="this.$router.push('/mypage/health/bloodPressure.do')">혈압
                    </button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn" @click="this.$router.push('/mypage/health/bloodSugar.do')">
                        혈당
                    </button>
                </li>
                <li class="tab-menu__item active">
                    <button type="button" class="tab-menu__btn">체질량</button>
                </li>
            </ul>
        </div>
    </div>

    <div class="date_wrap contents">
        <div class="time-input">
            <TimePicker v-model="time" format="HH:mm" minute-step="1" is24/>
            <img src="@/assets/resource/icon/time_icon.png" alt="아이콘">
        </div>
        <div class="date-input">
            <VueDatePicker
                v-model="date"
                locale="ko"
                :enable-time-picker="false"
                :format="customFormat"
                :week-start="0"
                :select-text="'선택'"
                :cancel-text="'취소'"
            />
            <img src="@/assets/resource/icon/date_icon.png" alt="아이콘">
        </div>
    </div>

    <div class="measure_wrap body_mass">
        <ul class="measure_input_list">
            <li class="measure_input_item measure_it01">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/gender_icon.png" alt="아이콘">
                        <h3>성별</h3>
                    </div>
                    <div class="agree-input">
                        <label for="gender-man" class="agree-input__item">
                            <input type="radio" id="gender-man" class="agree-input__radio" v-model="gender" value="M"
                                   checked>
                            <span class="icon"></span> 남자
                        </label>
                        <label for="gender-woman" class="agree-input__item">
                            <input type="radio" id="gender-woman" class="agree-input__radio" v-model="gender" value="F">
                            <span class="icon"></span> 여자
                        </label>
                    </div>
                </div>
            </li>
            <li class="measure_input_item measure_it02">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/ruler_icon.png" alt="아이콘">
                        <h3>키</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="height" @input="validateHeight" class="cont__text-input">
                        <span>cm</span>
                    </div>
                </div>
            </li>
            <li class="measure_input_item measure_it03">
                <div class="measure_tit">
                    <div class="measure_icon">
                        <img src="@/assets/resource/icon/scale_icon.png" alt="아이콘">
                        <h3>몸무게</h3>
                    </div>
                    <div class="measure_input cont">
                        <input type="text" v-model="weight" @input="validateWeight" class="cont__text-input">
                        <span>Kg</span>
                    </div>
                </div>
            </li>
        </ul>

        <div class="btn-wrap center contents">
            <a href="javascript:;" class="btn blue" @click="calculateBMI">측정하기</a>
        </div>
    </div>

    <div class="contents" v-if="!result">
        <div class="text-box type1 info-text">
            <h3 class="depth02__title title">체질량 측정 방법</h3>
            <ul class="text-box__list">
                <li class="text-box__item">신체질량지수(BMI) = 체중(kg) / [신장(m)]²</li>
                <li class="text-box__item">판정기준 - 저체중 : 18.5 미만, 정상 : 18.5 ~ 23, 과체중 : 23 ~ 25, 비만 : 25 ~ 30, 고도비만 : 30
                    이상
                </li>
            </ul>
        </div>
    </div>

    <div class="measure_result_wrap contents body_mass_result" v-if="result">
        <div class="measure_result_box">
            <div class="measure_result_con">
                <img src="@/assets/resource/icon/measure_icon03.png" alt="이미지">
                <p>현재 신체정보는&nbsp;<span class="blue__title">{{ staticHeight }}cm &nbsp;/&nbsp;{{ staticWeight }}Kg</span>으로<br>BMI
                    지수는 {{ staticBmi.toFixed(2) }}Kg/m²&nbsp;<span class="blue__title">'{{ result }}'</span>&nbsp;입니다.</p>
            </div>
        </div>
    </div>

    <div class="contents" v-if="result">
        <div class="table-wrap table-type1">
            <div class="scroll">
                <table>
                    <caption class="blind">표</caption>
                    <colgroup>
                        <col style="width:50%;">
                        <col style="width:50%;">
                    </colgroup>
                    <thead>
                    <tr>
                        <th>판정분류</th>
                        <th>BMI 지수</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>저체중</td>
                        <td>18.5 미만</td>
                    </tr>
                    <tr>
                        <td>정상</td>
                        <td>18.5~23</td>
                    </tr>
                    <tr>
                        <td>과체중</td>
                        <td>23~25</td>
                    </tr>
                    <tr>
                        <td>비만</td>
                        <td>25~30</td>
                    </tr>
                    <tr>
                        <td>고도비만</td>
                        <td>30이상</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="btn-wrap center contents" v-if="result">
        <a href="javascript:;" class="btn" @click="saveSubmit">결과저장</a>
    </div>
</template>

<script>
import {ref} from 'vue'
import TimePicker from 'vue3-timepicker'
import VueDatePicker from '@vuepic/vue-datepicker'
import MypagePopupVue from '../popup/MypagePopup.vue'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css'

export default {
    components: {
        TimePicker,
        VueDatePicker,
        MypagePopupVue
    },
    data() {
        return {
            gender: '',
            height: '',
            weight: '',
            result: '',
            bmi: 0,
            customFormat: 'yyyy.MM.dd',
            time: {HH: '', mm: ''},
            date: ref(new Date),

            staticHeight: '',
            staticWeight: '',
            staticBmi: '',
        }
    },
    methods: {
        validateHeight(event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3)
            this.height = input
        },
        validateWeight(event) {
            const input = event.target.value.replace(/[^\d]/g, '').slice(0, 3)
            this.weight = input
        },
        calculateBMI() {
            if (!this.height || !this.weight) {
                alert("수치를 입력해 주세요")
                return
            }

            this.staticHeight = this.height
            this.staticWeight = this.weight

            const heightInMeters = this.height / 100
            this.bmi = this.weight / (heightInMeters * heightInMeters)
            this.staticBmi = this.bmi

            if (this.bmi < 18.5) {
                this.result = '저체중'
            } else if (this.bmi >= 18.5 && this.bmi < 23) {
                this.result = '정상'
            } else if (this.bmi >= 23 && this.bmi < 25) {
                this.result = '과체중'
            } else if (this.bmi >= 25 && this.bmi < 30) {
                this.result = '비만'
            } else if (this.bmi >= 30) {
                this.result = '고도비만'
            }
        },
        saveSubmit() {
            this.$refs.MypagePopupVue.openPopup()
        },
        submit() {
            let reqData = {
                hmCd: 'BM'
            }

            var year = this.date.getFullYear()
            var month = ('0' + (this.date.getMonth() + 1)).slice(-2)
            var day = ('0' + this.date.getDate()).slice(-2)
            var hours = this.time.HH ? this.time.HH : ('0' + this.time.getHours()).slice(-2)
            var min = this.time.mm ? this.time.mm : ('0' + this.time.getMinutes()).slice(-2)

            reqData.logDt = year + '' + month + '' + day + '' + hours + '' + min + '00'
            reqData.gender = this.gender
            reqData.record1 = this.staticHeight
            reqData.record2 = this.staticWeight
            reqData.record3 = this.staticBmi
            reqData.result = this.result
            this.axios({
                method: "post",
                url: this.api.mypage.healthReg,
                params: reqData
            }).then(response => {
                alert('저장되었습니다.')
                this.height = ''
                this.weight = ''
                this.bmi = ''
                this.result = ''
                this.staticHeight = ''
                this.staticWeight = ''
                this.staticBmi = ''

                this.$router.push('/mypage/health/healthCare.do')
            })
        },
    },
    mounted() {
        this.gender = this.$store.state.myInfoStore.memGender ? this.$store.state.myInfoStore.memGender : 'M'

        const now = new Date();

        this.time.HH = now.getHours().toString().padStart(2, '0');
        this.time.mm = now.getMinutes().toString().padStart(2, '0');

        this.date = now;
    },
}
</script>
