<template>
    <div class="mypage">
        <ul class="mypage__list">
            <li class="mypage__item item1">
                <p class="item1__tit"><b>파티마 </b>님 좋은 하루 보내세요!</p>
                <div class="mypage__text-box mt30">
                    <p class="text">환자등록번호 : <b>1234567</b></p>
                </div>
                <ul class="mypage__box-list">
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/my-icon.png" alt="이미지"></div>
                            <div class="tit">회원정보수정</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/setting-icon.png" alt="이미지"></div>
                            <div class="tit">비밀번호변경</div>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="mypage__item item2">
                <h3 class="item__tit">최근예약일정</h3>
                <div class="cont">
                    <!-- 일정이 없을 경우 노출 active : show -->
                    <p class="hiden-text show">예약된 진료일정이 없습니다.</p>
                    <!-- 
                    <div class="img">
                        <img src="@/assets/resource/img/profile-sample3-1.png" alt="이미지">
                    </div>
                    <div class="text">
                        <div class="date">2024-05-07(화) 14:20</div>
                        <div class="info">[외래] 신장내과 <b>나정구</b></div>
                        <div class="btn">
                            <button type="button">예약변경</button>
                            <button type="button">예약취소</button>
                        </div>
                    </div> -->
                </div>
            </li>

            <li class="mypage__item item3">
                <h3 class="item__tit">나의 작성글</h3>
                <div class="cont">
                    <ul class="item3__list">
                        <li class="item3__item">
                            <a href="#">
                                <div class="tit">칭찬합니다.</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>0 </span> 건
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="item3__item">
                            <a href="#">
                                <div class="tit">고객의 소리</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>0 </span> 건
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="item3__item">
                            <a href="#">
                                <div class="tit">건강상담</div>
                                <div class="num">
                                    <div class="num__inner">
                                        <span>0 </span> 건
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="mypage__item item4">
                <h3 class="item__tit"><a href="#">예약 조회 <span class="arrow"></span></a></h3>
                <div class="line">
                    <div class="arrow arrow--bottom"></div>
                </div>
                <div class="cont">
                    <!-- 일정이 없을 경우 노출 active : show -->
                    <p class="hiden-text">예약된 진료일정이 없습니다.</p>
                    <div class="mypage__reservation-list mt-n" v-for="(item, index) in dataList">
                        <div class="mypage__text-box">
                            <div class="outpatient">외래</div>
                            <p class="text">{{ formatDate(item[0].list.orddd) }}</p>
                        </div>
                        <div class="mypage__time">
                            <span>{{index}}</span>
                            <!-- <span>{{item[index].drName}}</span> -->
                            <!-- <span>{{clo}}</span> -->
                            <!-- <span>{{formatTime(this)}} 신장내과</span> -->
                            <span>나정구 교수</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="mypage__item item5">
                <h3 class="item__tit"><a href="#">결과조회</a></h3>
                <ul class="mypage__box-list">
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon1.png" alt="이미지"></div>
                            <div class="tit">외래진료 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon2.png" alt="이미지"></div>
                            <div class="tit">약 처방 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon3.png" alt="이미지"></div>
                            <div class="tit">입/퇴원 내역</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon4.png" alt="이미지"></div>
                            <div class="tit">검사결과</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon5.png" alt="이미지"></div>
                            <div class="tit">종합건강검진결과 <br>
                                (건강증진센터)</div>
                        </a>
                    </li>
                    <li class="mypage__box-item">
                        <a href="javascript:;" class="mypage__box-link">
                            <div class="icon"><img src="@/assets/resource/icon/item5-icon6.png" alt="이미지"></div>
                            <div class="tit">국가건강검진결과 <br>
                                (직업환경의학센터)</div>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="mypage__item item6">
                <h3 class="item__tit"><a href="#">나의 건강관리 <span class="arrow"></span></a></h3>
                <div class="cont">
                    <ul class="health-list">
                        <li class="health-item item1">
                            <div class="tit">
                                <h4>혈압</h4>
                                <div class="img">
                                    <img src="@/assets/resource/icon/item6-icon1.png" alt="이미지">
                                </div>
                            </div>
                            <div class="desc">
                                <div class="num"><b>80~120</b> mmHg</div>
                                <div class="state">전고혈압</div>
                                <!-- <div class="no-data">
                                    <p>등록된 데이터가 없습니다.</p>
                                    <button type="button"><span class="plus"></span>체중 등록</button>
                                </div> -->
                            </div>
                        </li>

                        <li class="health-item item2">
                            <div class="tit">
                                <h4>혈압</h4>
                                <div class="img">
                                    <img src="@/assets/resource/icon/item6-icon2.png" alt="이미지">
                                </div>
                            </div>
                            <div class="desc">
                                <div class="num"><b>98</b> mg/dL</div>
                                <div class="state">정상입니다.</div>
                                <!-- <div class="no-data">
                                    <p>등록된 데이터가 없습니다.</p>
                                    <button type="button"><span class="plus"></span>체중 등록</button>
                                </div> -->
                            </div>
                        </li>

                        <li class="health-item item3">
                            <div class="tit">
                                <h4>체질량</h4>
                                <div class="img">
                                    <img src="@/assets/resource/icon/item6-icon3.png" alt="이미지">
                                </div>
                            </div>
                            <div class="desc">
                                <div class="no-data">
                                    <p>등록된 데이터가 없습니다.</p>
                                    <button type="button"><span class="plus"></span>체중 등록</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="mypage__item item7">
                <h3 class="item__tit"><a href="#">외래진료 순번조회</a></h3>
                <div class="cont">
                    <a href="javascript:;" class="mypage__link">순번조회</a>
                </div>
            </li>

            <li class="mypage__item item8">
                <h3 class="item__tit"><a href="#">외래진료 순번조회</a></h3>
                <div class="cont">
                    <a href="javascript:;" class="mypage__link">관심 의료진</a>
                    <a href="javascript:;" class="mypage__link">관심 콘텐츠</a>
                </div>
            </li>

            <li class="mypage__item item9">
                <h3 class="item__tit"><a href="#">회원탈퇴 <span class="arrow"></span></a></h3>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                today: '',
                nextYear: '',
                dataList: []
            };
        },
        methods: {
            getDates() {
                const today = new Date();
                const nextYear = new Date(today);
                nextYear.setFullYear(today.getFullYear() + 1);

                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const date = String(today.getDate()).padStart(2, '0');

                const nextYearYear = nextYear.getFullYear();
                const nextYearMonth = String(nextYear.getMonth() + 1).padStart(2, '0');
                const nextYearDate = String(nextYear.getDate()).padStart(2, '0');

                this.today = `${year}${month}${date}`;
                this.nextYear = `${nextYearYear}${nextYearMonth}${nextYearDate}`;
            },
            async dataFn() {
                this.getDates();

                try {
                    const response = await this.axios.get(this.api.mypage.select, {
                        params: {
                            fromdd: this.today,
                            todd: this.nextYear
                        }
                    });
                    console.log(response.data)
                    for (let i of response.data.list) {
                        let doc = response.data.doclist.find((val) => i.orddrid == val.drNo)
                        i.docList = doc;
                        this.dataList.push(i);
                    }
                    console.log(this.dataList);
                    //this.dataList = response.data
                } catch (err) {
                    console.error(err);
                }
            },
            formatDate(dateString) {
                const date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8));

                const month = date.getMonth() + 1;
                const day = date.getDate();
                const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
                const weekDay = weekDays[date.getDay()];

                return `${month}월 ${day}일 (${weekDay})`;
            },
            formatTime(timestamp) {
                const date = new Date(timestamp);

                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                return `${minutes}:${seconds}`;
            }
        },
        mounted() {
            this.dataFn();
            console.log(this.dataList)
        }
    };
</script>