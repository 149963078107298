<template>
    <div class="wrap enviro">
        <div class="main-container">
            <div class="main-visual">
                <img src="../../assets/resource/img/canter-main-visual.png" alt="이미지" class="main-visual__back">
                <div class="main-visual__con">
                    <h3 class="main-visual__title">창원파티마병원 진료협력센터</h3>
                    <p class="main-visual__text">
                        <strong> 유수한 의료기관과 협력</strong>해 <br>
                        최선의 서비스를 제공하고 있습니다 
                    </p>
                </div>
            </div>
            <div class="quick-menu main-inner">
                <div class="quick-menu__text">
                    <h3 class="quick-menu__text--title">문의 및 예약</h3>
                    <ul class="quick-menu__text--number">
                        <li><img src="../../assets/resource/icon/quick-menu__text--number01.png" alt="이미지">055)270-1051~3</li>
                    </ul>
                    <p>
                        평일 09:00~16:40 · 토요일 09:00~12:00
                    </p>
                </div>
                <ul class="quick-menu__list">
                    <li class="quick-menu__item">
                        <a href="javascript:;">
                            <img src="../../assets/resource/icon/enviro-quick-menu-icon01.png" alt="이미지">
                            <h4>센터소개</h4>
                        </a>
                    </li>
                    <li class="quick-menu__item">
                        <a href="javascript:;">
                            <img src="../../assets/resource/icon/enviro-quick-menu-icon04.png" alt="이미지">
                            <h4>의료진 검색</h4>
                        </a>
                    </li>
                    <li class="quick-menu__item">
                        <a href="javascript:;">
                            <img src="../../assets/resource/icon/enviro-quick-menu-icon05.png" alt="이미지">
                            <h4>협력 병/의원 현황</h4>
                        </a>
                    </li>
                    <li class="quick-menu__item">
                        <a href="javascript:;">
                            <img src="../../assets/resource/icon/enviro-quick-menu-icon02.png" alt="이미지">
                            <h4>협력병원 체결 절차</h4>
                        </a>
                    </li>
                    <li class="quick-menu__item">
                        <a href="javascript:;">
                            <img src="../../assets/resource/icon/enviro-quick-menu-icon03.png" alt="이미지">
                            <h4>협력병원 체결 절차</h4>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="canter-board">
                <div class="canter-board__title main-inner">
                    <h3>파티마뉴스</h3>
                    <a href="javascript:;">
                        <p>더보기</p>
                    </a>
                </div>
                <div class="canter-board__list">
                    <div class="canter-board__header">
                        <ul class="canter-board__tr main-inner">
                            <li>NO.</li>
                            <li>제목</li>
                            <li>등록일</li>
                            <li></li>
                        </ul>
                    </div>
                    <ul class="canter-board__body">
                        <li class="canter-board__item">
                            <ul class="canter-board__tr main-inner">
                                <li>
                                    공지
                                </li>
                                <li>
                                    <a href="javascript:;">제목입니다.</a>
                                </li>
                                <li>2024-03-18</li>
                                <li><a href="javascript:;" class="plus"></a></li>
                            </ul>
                        </li>
                        <li class="canter-board__item">
                            <ul class="canter-board__tr main-inner">
                                <li>
                                    10
                                </li>
                                <li>
                                    <a href="javascript:;">제목입니다.</a>
                                </li>
                                <li>2024-03-18</li>
                                <li><a href="javascript:;" class="plus"></a></li>
                            </ul>
                        </li>
                        <li class="canter-board__item">
                            <ul class="canter-board__tr main-inner">
                                <li>
                                    9
                                </li>
                                <li>
                                    <a href="javascript:;">제목입니다 제목입니다 제목입니다 제목입니다 제목입니다 제목입니다 제목입니다.</a>
                                </li>
                                <li>2024-03-18</li>
                                <li><a href="javascript:;" class="plus"></a></li>
                            </ul>
                        </li>
                        <li class="canter-board__item">
                            <ul class="canter-board__tr main-inner">
                                <li>
                                    8
                                </li>
                                <li>
                                    <a href="javascript:;">제목입니다.</a>
                                </li>
                                <li>2024-03-18</li>
                                <li><a href="javascript:;" class="plus"></a></li>
                            </ul>
                        </li>
                        <li class="canter-board__item">
                            <ul class="canter-board__tr main-inner">
                                <li>
                                    7
                                </li>
                                <li>
                                    <a href="javascript:;">제목입니다.</a>
                                </li>
                                <li>2024-03-18</li>
                                <li><a href="javascript:;" class="plus"></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <ul class="photo-link main-inner">
                <li class="photo-link__item">
                    <img src="../../assets/resource/img/photo-link01.png" alt="이미지" class="photo-link__item--back">
                    <a href="javascript:;">
                        <h3 class="photo-link__item--title">검진유의사항</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                </li>
                <li class="photo-link__item">
                    <img src="../../assets/resource/img/photo-link02.png" alt="이미지" class="photo-link__item--back">
                    <a href="javascript:;">
                        <h3 class="photo-link__item--title">검진결과조회</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                </li>
                <li class="photo-link__item">
                    <img src="../../assets/resource/img/photo-link03.png" alt="이미지" class="photo-link__item--back">
                    <a href="javascript:;">
                        <h3 class="photo-link__item--title">업무적합성평가</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                </li>
                <li class="photo-link__item">
                    <img src="../../assets/resource/img/photo-link04.png" alt="이미지" class="photo-link__item--back">
                    <a href="javascript:;">
                        <h3 class="photo-link__item--title">금연클리닉</h3>
                        <p class="photo-link__item--text">바로가기</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
    // import gsap from 'gsap'
    import 'swiper/css';
    import 'swiper/css/effect-creative';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import { Navigation, Autoplay, Pagination, EffectCreative } from 'swiper/modules';
    // import { gsap, ScrollTrigger, Draggable, MotionPathPlugin, Power1 } from "gsap/all";


    export default {
        components: {
        },
        data() {
        },
        setup() {
        },
        methods: {
        },
        mounted() {
            
        }
    }
</script>


