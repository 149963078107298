<template>
    <div class="sub-tit">
        <h2>{{ menuName }}</h2>
    </div>
</template>


<script>
export default {
    data() {
        return {
            menuName:'',
        };
    },
    watch: {
        $route(to, from){
            this.menuName = this.$commonUtils.getMenuName(this.menuList)
        }
    },
    mounted() {
        this.menuName = this.$commonUtils.getMenuName(this.menuList)
    }
}
</script>
