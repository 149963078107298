<template>
    
</template>

<script>
export default {
    data() {
        return {
            cvrData: this.$route.query.data || '',
        }
    },
    mounted() {
        if(this.cvrData){
            const param = {data: this.cvrData}
            this.axios.get(this.api.cvr.select,{
                params: param
            }, {
                headers: {
                    'Accept': 'application/json'
                }
            }).then(res => {
                alert(res.data.msg)
                this.closePage()
            }).catch(err => {
                alert('서버 오류가 발생했습니다.')
                this.closePage()
            })
        }else{
            alert('data 값이 비어있습니다.\nURL을 확인하십시오.')
            this.closePage()
        }
    },
    methods: {
        closePage(){
            self.opener = self
            window.close()
        }
    },
}
</script>