<template>
    <div class="contents healthInfoDet">
        <div class="board-view">
            <div class="board-view__top">
                <ul class="board-view__share-list" :class="{active : this.shareList}">
                    <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.clip"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('band')"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('twitter')"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="javascript:;" @click="$commonUtils.fn_sendFB('facebook')"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
                </ul>

                <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
                <button type="button" class="board-view__btn print" @click="print()"></button>
            </div>
            <div class="board-view__wrap info-wrap">
                <div class="info__tit">
                    <h3>{{ title }}</h3>
                    <!-- <ul class="board-view__share-list" :class="{active : this.shareList}">
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt="이미지"></a></li>
                    <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt="이미지"></a></li>
                </ul>

                <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                <FavoriteHeartVue v-if="info" :info="info"></FavoriteHeartVue>
                <button type="button" class="board-view__btn print" @click="print()"></button> -->
                </div>
                <div class="info__con">
                    <div class="info__left" :class="{ 'active': isBbOnTop }" v-if="!list.some(item => item.title === undefined)">
                        <ul>
                            <li v-for="(item, index) in list" :key="index" :class="{ 'active': accordionIndex === index }">
                                <a href="javascript:;" @click="activateAccordion(index)">{{ item.title }}</a>
                                <span></span>
                            </li>
                        </ul>
                    </div>
                    <div class="info__right" ref="con" @scroll="handleScroll">
                        <div class="info__item" v-for="(item, index) in list" :key="index">
                            <div class="info-text">
                                <h3 class="depth01__title title">{{ item.title }}</h3>
                                <template v-for="(i, idx) in item.value" :key="idx">
                                    <img :src="i.replace('health.kdca.go.kr', 'chs.kdca.go.kr')" v-if="i.indexOf('http') == 0" alt="이미지"/>
                                    <p v-html="formatText(i)" v-else></p>
                                </template>
                            </div>
                        </div>
                        <div class="nuri_type04 contents">
                            <img src="/resource/img/img_opentype04.png" alt="공공누리 4유형">
                            <p class="nuri_notice">
                                본 공공저작물은 공공누리 <span class="blue__title"><b>“출처표시+상업적이용금지+변경금지”</b></span> 조건에 따라 이용할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import FavoriteHeartVue from '../common/FavoriteHeart.vue';

    export default {
        components: {
            FavoriteHeartVue
        },
        data() {
            return {
                shareList: false,
                isBbOnTop: false,
                title: '',
                list: [],
                accordionIndex: 0,
                subTitleList: {
                    '101': '개요'
                    , '102': '개요-정의'
                    , '103': '개요-종류'
                    , '104': '개요-원인'
                    , '105': '개요-경과 및 예후'
                    , '106': '개요-병태생리'
                    , '107': '역학 및 통계'
                    , '108': '증상'
                    , '109': '진단 및 검사'
                    , '110': '치료'
                    , '111': '치료-약물 치료'
                    , '112': '치료-비약물 치료'
                    , '113': '자가 관리'
                    , '114': '정기 진찰'
                    , '115': '합병증'
                    , '116': '위험요인 및 예방'
                    , '117': '생활습관 관리'
                    , '118': '대상별 맞춤 정보'
                    , '119': '지원체계'
                    , '120': '자주하는 질문'
                    , '121': '연관 주제어'
                    , '317': '참고문헌'
                    , '143': '개요'
                    , '144': '개요-신고대상 및 방법'
                    , '145': '개요-원인 및 감염경로'
                    , '146': '역학 및 통계'
                    , '147': '증상'
                    , '148': '진단 및 검사'
                    , '149': '예방 및 예방접종'
                    , '150': '치료'
                    , '151': '치료-약물 치료'
                    , '152': '치료-비약물 치료'
                    , '153': '합병증'
                    , '154': '대상별 맞춤 정보'
                    , '319': '참고문헌'
                    , '158': '개요'
                    , '159': '개요-정의'
                    , '160': '개요-종류'
                    , '161': '개요-원인'
                    , '162': '개요-경과 및 예후'
                    , '164': '역학 및 통계'
                    , '165': '진단 및 검사'
                    , '167': '연관 증상'
                    , '168': '치료'
                    , '169': '치료-약물 치료'
                    , '170': '치료-비약물 치료'
                    , '171': '예방'
                    , '172': '자가 관리'
                    , '174': '병원을 방문해야 하는 경우'
                    , '175': '자주하는 질문'
                    , '320': '참고문헌'
                    , '211': '개요'
                    , '212': '개요-정의'
                    , '213': '개요-종류건강에 미치는 영향'
                    , '214': '건강에 미치는 영향'
                    , '215': '건강에 미치는 영향-중요성'
                    , '217': '평가 및 검사'
                    , '218': '실천 방법'
                    , '219': '실천 방법-일반적 실천 방법'
                    , '220': '실천 방법-맞춤형 실천 방법'
                    , '221': '도움 및 지지'
                    , '222': '자주하는 질문'
                    , '223': '연관 주제어'
                    , '325': '참고문헌'
                    , '224': '개요'
                    , '225': '개요-정의'
                    , '226': '개요-발생원/원인'
                    , '227': '개요-종류'
                    , '228': '통계'
                    , '229': '건강에 미치는 영향'
                    , '230': '건강에 미치는 영향-중요성'
                    , '231': '건강에 미치는 영향-건강문제'
                    , '232': '예방 및 대처'
                    , '234': '자주하는 질문'
                    , '235': '참고문헌'
                    , '326': '연관 주제어'
                    , '248': '개요'
                    , '249': '목적'
                    , '251': '세부 내용'
                    , '252': '제공 절차'
                    , '328': '참고문헌'
                },
                info: null
            };
        },
        methods: {
            shareFn() {
                this.shareList = !this.shareList
            },
            print() {
                window.print()
            },
            formatText(text) {
                const urlRegex = /(http:\/\/|https:\/\/)?[a-zA-Z0-9-.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;

                let formattedText = text.replace(urlRegex, (url) => {
                    return `<a href="${url}" target="_blank">${url}</a>`;
                });

                return formattedText;
            },
            checkBbPosition() {
                const bbElement = document.querySelector('.info__con');
                if (bbElement) {
                    const bbPosition = bbElement.getBoundingClientRect().top;
                    this.isBbOnTop = bbPosition <= 0;
                }
            },
            handleScroll() {
                const infoItems = document.querySelectorAll('.info__item');
                const infoLeftItems = document.querySelectorAll('.info__left li');

                infoItems.forEach((item, index) => {
                    const rect = item.getBoundingClientRect();
                    const threshold = 2;

                    if (rect.top < threshold && rect.bottom > 0) { // 변경된 부분: 요소의 top이 0보다 작고 bottom이 0보다 큰 경우를 화면 상단에 위치한 것으로 간주합니다.
                        infoLeftItems.forEach((leftItem, leftIndex) => {
                            if (leftIndex === index) {
                                leftItem.classList.add('active');
                            } else {
                                leftItem.classList.remove('active');
                            }
                        });
                    }
                });
            },
            activateAccordion(index) {
                // 엑티브 되는 박스의 index
                const accordionItem = this.$el.querySelectorAll('.info__item')[index];
                accordionItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
            },
            getInfo() {
                const key = this.$route.query.key
                const params = {
                    key: key
                }

                this.axios.get(this.api.health.kdca, {
                    params: params
                })
                    .then(res => {
                        this.title = res.data.XML.svc.CNTNTSSJ
                        const arr = this.$commonUtils.groupArrayByKey(res.data.XML.svc.cntntsClList.cntntsCl, 'CNTNTSCLSN')
                        for (let i in arr) {
                            const obj = {}
                            for (let key in arr[i]) {
                                if (arr[i].hasOwnProperty(key)) {
                                    const valArr = []
                                    let values = arr[i][key];
                                    values.forEach(value => {
                                        let tit = value.CNTNTS_CL_NM
                                        if (!tit) {
                                            tit = this.subTitleList[key]
                                        }
                                        obj.title = tit
                                        valArr.push(value.CNTNTS_CL_CN)
                                    });
                                    obj.value = valArr
                                }
                            }
                            this.list.push(obj)
                            for(let i of this.list){
                                i
                            }
                        }
                    })
            },
            favoInfo() {
                const query = this.$route.query
                this.axios.get(this.api.article.view + query.boardNo + '/' + query.articleNo, {
                    params: {
                        instNo: 1,
                        articleNo: query.articleNo,
                        boardNo: query.boardNo
                    }
                }).then(res => {
                    this.info = {}
                    this.info.favoYn = res.data.favoInfoYn == 'Y' ? 'Y' : 'N'
                    this.info.no = query.articleNo
                    this.info.type = 'BBS'
                    this.info.mypageYn = 'N'
                })
            }
        },
        created() {
            this.favoInfo()
        },
        mounted() {
            this.getInfo()
            window.addEventListener('scroll', this.checkBbPosition);
            window.addEventListener('scroll', this.handleScroll);

            // URL 리스트
            // const url1 = 'https://chs.kdca.go.kr/cscdnhfile/health/healthNewDown/healthInfoFileDown.do?SEQ=17a74d010764'
            // const url2 = 'https://chs.kdca.go.kr/cscdnhfile/health/healthNewDown/healthInfoFileDown.do?SEQ=176cbf2919d1'

            // setTimeout(() => {
            //     this.axios.get(url1, {
            //         responseType: 'json'  // 파일 다운로드를 위해 responseType을 'stream'으로 설정
            //     })
            //     .then(res => {
            //         const test = res
            //         const test1 = res.headers
            //         const test2 = res.headers['content-disposition']
            //         const test3 = res.headers['Content-Disposition']
            //         console.log(test, test1, test2, test3)
            //     })
            //     this.axios.get(url2, {
            //         responseType: 'text'  // 파일 다운로드를 위해 responseType을 'stream'으로 설정
            //     })
            //     .then(res => {
            //         const test = res
            //         const test1 = res.headers
            //         const test2 = res.headers['content-disposition']
            //         const test3 = res.headers['Content-Disposition']
            //         console.log(test, test1, test2, test3)
            //     })
            // }, 2000);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.checkBbPosition);
            window.removeEventListener('scroll', this.handleScroll);
        }
    };
</script>