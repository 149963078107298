<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item active"><button type="button" class="tab-menu__btn" @click="this.$router.push('/mypage/favo/favoDoctors.do')">관심 의료진</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="this.$router.push('/mypage/favo/favoContents.do')">관심 콘텐츠</button></li>
            </ul>
        </div>
    </div>

    <div class="contents">
        <ul class="doctor-list">
            <template v-if="doctorList.length > 0">
                <li class="doctor-list__item" v-for="doctor in doctorList" :key="doctor">
                    <div class="doctor-list--img">
                        <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지">
                    </div>
                    <div class="doctor-list--con">
                        <div class="doctor-list--text">
                            <FavoriteHeartVue :info="favoInfo(doctor)"></FavoriteHeartVue>
                            <h3>{{doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName}}<sub>{{doctor.deptNm}}</sub></h3>
                            <h4>주진료분야</h4>
                            <p>{{doctor.doctorDept.special}}</p>
                        </div>
                        <div class="doctor-list--button">
                            <a :href="`/hospital/department-doctor/doctorDetail.do?drNo=${doctor.drNo}`" target="_blank">의료진소개</a>
                            <a href="javascript:;" @click="getAbsence(doctor)">진료예약</a>
                        </div>
                    </div>
                </li>
            </template>
            <li v-else class="doctor-list__data-none">
                <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                <h4 class="depth03__title">검색된 의료진이 없습니다.</h4>
            </li>
        </ul>
        <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
    </div>
</template>

<script>
import FavoriteHeartVue from '@/components/common/FavoriteHeart.vue';

    export default {
        components: {
            FavoriteHeartVue
        },
        data() {
            return {
                doctorList: [],
            };
        },
        methods: {
            getList(){
                this.axios({
                    method:"get",
                    url: this.api.favorite.list, 
                    params:  { 'favoTypeCd' : 'DOCTOR' }
                }).then(res => {
                    this.doctorList = res.data.list
                });
            },
            async getAbsence(info){
                await this.axios.get(this.api.doctor.absence, {
                    params: {
                        drNo: info.drNo
                    }
                }).then(res => {
                    const reason = res.data.reason
                    if(reason){
                        const startDt = new Date(res.data.staDt)
                        const endDt = new Date(res.data.endDt)
                        const today = new Date()
                        if(today >= startDt && today <= endDt){
                            alert(reason)
                            return
                        }
                    }
                    for(let i of info.doctorDeptList){
                        if(i.deptNm == info.deptNm) {
                            info.doctorDept.rsvOpenYn = i.rsvOpenYn
                            break
                        }
                    }
                    this.$store.commit('setReserveInfo', info)
                    this.$router.push('/reserve/reservation.do')
                }).catch(err => {
                    alert('오류가 발생했습니다.')
                })
            },
            favoInfo(info){
                const obj = {}
                obj.favoYn = 'Y'
                obj.no = info.drNo
                obj.type = 'DOCTOR'
                obj.mypageYn = 'N'
                return obj
            }
        },
        mounted() {
            this.getList()
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>