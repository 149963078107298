<template>
    <div class="privacy-policy">

        <!-- sec1 -->
        <div id="sec1">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제 1조 (목적)</h4>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>창원파티마병원 홈페이지(www.fatimahosp.co.kr) 이용약관(이하 "본 약관"이라 합니다)은 "이용자"가 창원파티마병원(이하 "병원"이라 합니다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 합니다)를 이용함에 있어 "이용자"와 "병원"의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>이용자가 되고자 하는 자가 "병원"이 정한 소정의 절차를 거쳐서 "동의" 단추를 누르면 본 약관에 의하는 것으로 간주합니다. 본 약관에 정하는 이외의 “이용자”와 "병원"의 권리, 의무 및 책임사항에 관해서는 대한민국의 관련 법령과 상관습에 의합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec2 -->
        <div id="sec2">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제 2조 (정의)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"란 "병원"에 접속하여 본 약관에 따라 "병원" 회원으로 가입하여 "병원"이 제공하는 “서비스”를 받는 자를 말합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>“회원”이란 “병원”이 회원으로 적합하다고 인정하는 일반 개인으로 본 약관에 동의하며 "서비스"의 회원가입양식을 작성하고 아이디와 비밀번호를 이용하여 "서비스"를 제공받는 사람을 말합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"개인정보"란 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 특정개인을 식별할 수 있는 정보(당해 정보만으로는 특정개인을 인식할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함한다)를 말합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p>“해지”란 "이용자"가 "서비스" 가입 후에 이용계약을 해약하는 것을 말합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec3 -->
        <div id="sec3">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제3조 (약관의 게시 및 변경)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 본 약관의 내용과 상호, 병원 소재지 등을 "이용자"가 알 수 있도록 홈페이지에 게시합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 불가피한 사정이 있는 경우 관계법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p> “병원”은 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 "서비스" 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>⑤</p>
                            </div>
                            <div class="depth__text">
                                <p> "이용자"는 변경된 약관에 대해 거부할 권리가 있습니다. "이용자"는 변경된 약관이 공지된 후 15일 이내에 거부의사를 표명할 수 있습니다. "이용자"가 거부하는 경우 "병원"은 당해 "이용자"와의 계약을 해지할 수 있습니다. 만약 "이용자"가 변경된 약관이 공지된 후 15일 이내에 거부의사를 표시하지 않는 경우에는 동의하는 것으로 간주합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec4 -->
        <div id="sec4">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제4조 (회원 가입)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>“이용자”가 되고자 하는 자는 약관의 내용에 대하여 동의를 한 다음 "병원"이 정한 가입양식에 따라 회원정보를 기입하여 회원가입을 신청하고 “병원”이 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다음 각 호에 해당하는 신청에 대해서는 승낙하지 않거나 사후에 이용계약 해지를 할 수 있습니다.</p>
                                <div class="depth__text">
                                    <p>이용자가 다음 각 호의 사유에 해당하는 경우, "병원"은 이용자의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.</p>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>가입신청자가 본 약관 제5조 제4항에 의하여 이전에 회원자격을 상실한 적이 있는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>실명이 아니거나 타인의 명의를 이용한 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 회원으로 등록하는 것이 "병원" 홈페이지 운영상 현저히 지장이 있다고 판단되는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>6.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"이용자"의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하 며 신청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>7.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>“병원”이 정한 회원가입요건에 미비한 경우</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>회원가입계약의 성립 시기는 "병원"의 승낙이 가입신청자에게 도달한 시점으로 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p>제1항에 따른 신청에 있어 “병원”은 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>⑤</p>
                            </div>
                            <div class="depth__text">
                                <p> 회원은 제1항의 회원정보 기재 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>⑥</p>
                            </div>
                            <div class="depth__text">
                                <p>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec5 -->
        <div id="sec5">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제5조 (이용자 탈퇴 및 자격 상실 등)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 "병원"에 언제든지 자신의 회원 등록을 말소해 줄 것(탈퇴)을 요청할 수 있으며 "병원"은 위 요청을 받은 즉시 해당 "이용자"의 회원 등록 말소를 위한 절차를 밟습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"가 "탈퇴"할 경우, 관련법 및 개인정보처리방침에 따라 "병원"이 회원정보를 보유하는 경우를 제외하고는 탈퇴 즉시 "이용자"의 모든 데이터는 소멸됩니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"가 이 계약을 해지하는 경우, "이용자"가 작성한 게시물 중 고객의 소리, 칭찬, 상담 등과 같은 게시물 일체는 삭제되지 않을 수 있으니 사전에 삭제 후 탈퇴해야 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"가 다음 각 호의 사유에 해당하는 경우, "병원"은 "이용자"의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>등록 신청 시에 허위 내용을 등록한 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 질서를 위협하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>“병원” 내에 제공되는 정보를 변경하는 등 홈페이지 운영을 방해한 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>사이트를 이용하여 법령 및 본 약관, 공서양속에 반하는 행위를 하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 회원으로서의 자격을 지속시키는 것이 부적절하다고 판단되는 경우</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>⑤</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 "이용자"의 회원자격을 상실시키기로 결정한 경우에는 회원등록을 말소합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>⑥</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"가 본 약관에 의해서 회원 가입 후 "서비스"를 이용하는 도중, 12개월 동안 "서비스"를 이용하기 위해 로그인한 기록이 없는 경우에 "병원"은 "이용자"로 하여금 개인정보수정 요청 및 "서비스" 제한을 가할 수 있습니다.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- sec6 -->
        <div id="sec6">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제6조 (회원정보의 수정)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, "서비스" 관리를 위해 필요한 실명, 주민등록번호, 아이디 등은 수정이 불가능합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 "병원"에 대하여 그 변경사항을 알려야 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>제2항의 변경사항을 "병원"에 알리지 않아 발생한 불이익에 대하여 "병원"은 책임지지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec7 -->
        <div id="sec7">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제7조 (이용자에 대한 통지)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 특정 "이용자"에 대한 통지를 하는 경우 "이용자"가 회원 가입 시 기재한 메일주소 또는 휴대폰 번호를 이용할 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 불특정다수 "이용자"에 대한 통지를 하는 경우 "병원" 홈페이지에 사전 게시함으로써 개별지에 갈음할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec8 -->
        <div id="sec8">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제8조 (서비스의 제공 및 변경)</h4><!-- depth03 -->

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 "이용자"에게 아래와 같은 "서비스"를 제공합니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>의료진 및 진료일정 안내</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>진료 등 예약 서비스</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>건강정보</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>의료정보 통합검색서비스</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 "병원"이 정하는 서비스</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 그 변경될 "서비스"의 내용 및 제공일자를 제7조 2항에서 정한 방법으로 "이용자"에게 통보하고, 제1항에 정한 "서비스"를 변경하여 제공할 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 "서비스" 내용의 변경으로 인하여 "이용자"가 입은 손해에 대하여 배상하지 아니합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec9 -->
        <div id="sec9">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제9조 (진료예약서비스)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>“이용자”는 홈페이지상에서 이하의 방법에 의하여 진료예약을 신청합니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>주민등록번호, 비밀번호, 성명, 주소, 전화번호, 환자상태 입력</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>이 약관에 동의한다는 표시</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 제1항의 예약신청에 대하여 다음 각호의 사유에 해당하지 않는 한 승낙합니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>신청 내용에 허위, 기재누락, 오기가 있는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 예약신청에 승낙하는 것이 기술상 또는 내부 운영상 현저히 지장이 있다고 판단하는 경우</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”의 승낙 통지가 "이용자"의 전자우편이나 SMS으로 도달된 때에 예약이 성립된 것으로 봅니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec10 -->
        <div id="sec10">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제10조 (건강상담서비스)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"의 상담 내용이 다음 각호의 사유로 인하여 훼손된 경우 "병원"은 책임을 지지 않습니다.</p>
                                <div class="depth__text">
                                    <p>2댑스내용</p>
                                    <div class="depth__text">
                                        <div class="depth__wrap depth04">
                                            <div class="depth__bullet">
                                                <p>1.</p>
                                            </div>
                                            <div class="depth__text">
                                                <p>“이용자”의 부주의로 비밀번호가 유출된 경우</p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap depth04">
                                            <div class="depth__bullet">
                                                <p>2.</p>
                                            </div>
                                            <div class="depth__text">
                                                <p>“이용자”가 '상담삭제' 기능을 사용한 경우</p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap depth04">
                                            <div class="depth__bullet">
                                                <p>3.</p>
                                            </div>
                                            <div class="depth__text">
                                                <p>“이용자”가 개인정보를 상담내용에 기입한 경우</p>
                                            </div>
                                        </div>
                                        <div class="depth__wrap depth04">
                                            <div class="depth__bullet">
                                                <p>4.</p>
                                            </div>
                                            <div class="depth__text">
                                                <p>천재지변등 기타 불가항력에 의한 경우</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>상담에 대한 답변 내용은 "병원"의 공식적인 의견이 될 수 없으며, 상담내용에 대하여 "병원"은 일체의 책임을 지지 않습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>다음과 같은 상담신청의 경우에는 상담을 거절할 수 있습니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>같은 내용의 상담을 반복하여 신청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>상식에 어긋나는 표현을 사용하여 상담을 신청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>진단명을 요구하는 상담을 신청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>치료비, 검사비, 의약품 가격 등에 대하여 상담을 신청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>객관적 근거 없이 타병원 진료내용에 대해 판단을 요청하는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>6.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>설명이 부족하거나, 설명만으로 정확한 판단이 불가능한 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>7.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 상담 내용이 법률이나 의료질서에 반하는 경우</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec11 -->
        <div id="sec11">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제11조 (건강관련정보 제공 서비스)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"에서 제공하는 건강관련정보는 개략적이며 일반적인 것으로서 특정인의 의견에 지나지 않으며 어떠한 경우에도 전문적인 의학적 진단, 진료, 치료를 대신할 수 없습니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 건강관련정보 제공 서비스에서 언급된 어떠한 특정한 검사나 제품 또는 치료법을 보증하지 않습니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 제공하는 건강관련정보는 전적으로 "이용자"의 판단에 따라 이용되는 것으로, "병원"은 건강관련정보의 제공과 관련하여 어떠한 책임도 지지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec12 -->
        <div id="sec12">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제12조 (서비스의 중단)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우 "서비스"의 제공을 일시적으로 중단할 수 있고, 새로운 "서비스"로의 교체 혹은 기타 "병원"이 적절하고 판단하는 사유에 기하여 현재 제공되는 "서비스"를 완전히 중단할 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>제1항에 의한 "서비스" 중단의 경우에는 "병원"은 제7조 제2항에서 정한 방법으로 "이용자"에게 통지합니다. 다만, "병원"이 통제할 수 없는 사유로 인한 "서비스"의 중단(시스템 관리자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"의 고의 또는 과실이 없는 "서비스" 내용의 변경으로 인하여 "이용자"가 입은 손해에 대하여 보호하지 아니합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec13 -->
        <div id="sec13">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제13조 (서비스 이용시간)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"서비스" 이용시간은 "병원"의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>제1항의 이용시간 중 정기점검 등의 필요로 인하여 "병원"이 정한 날 또는 시간은 예외로 합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec14 -->
        <div id="sec14">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제14조 (이용자의 개인정보보호)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 관련법령이 정하는 바에 따라서 "이용자" 등록정보를 포함한 "이용자"의 개인정보를 보호하기 위하여 노력합니다. "이용자"의 개인정보보호에 관해서는 관련법령 및 "병원"이 정하는 "개인정보처리방침"에 정한 바에 의합니다. 다만, "병원"의 공식 사이트 이외의 링크된 사이트에서는 "병원"의 개인정보취급방침이 적용되지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec15 -->
        <div id="sec15">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제15조 (이용자의 ID 및 비밀번호에 대한 의무)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 관계법령, "개인정보처리방침"에 의해서 그 책임을 지는 경우를 제외하고, 자신의 ID와 비밀번호에 관한 관리책임은 "이용자"에게 있습니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "병원"에 통보하고 "병원"의 안내가 있는 경우에는 그에 따라야 합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p>제3항의 경우에 해당 "회원"이 "병원"에 그 사실을 통지하지 않거나, 통지한 경우에도 "병원"의 안내에 따르지 않아 발생한 불이익에 대하여 "병원"은 책임지지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec16 -->
        <div id="sec16">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제16조 (병원의 의무)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 본 약관이 정하는 의무에 따라 지속적이고 안정적으로 “서비스”를 제공하기 위해서 노력합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"은 "이용자"가 안전하게 "서비스"를 이용할 수 있도록 "이용자"의 개인정보보호를 위한 보안 시스템을 구축합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p> "병원"은 "이용자"가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec17 -->
        <div id="sec17">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제17조 (이용자의 의무)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 다음 각 호의 행위를 하여서는 안 됩니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>회원가입신청 또는 변경 시 허위내용을 등록하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"병원"에 게시된 정보를 변경하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"병원", 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>타인의 정보나 다른 회원의 ID를 도용하는 행위 및 자신의 ID와 비밀번호를 제3자에게 빌려주는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>광고 또는 외설, 폭력적인 메시지·화상·음성 등이 담긴 메일을 보내는 등 기타 공서양속 에 반하는 정보를 공개 또는 게시하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>6.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>관련 법령에 의하여 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는 게시하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>7.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"병원"의 직원이나 "서비스" 관리자를 가장 혹은 사칭하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>8.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>9.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>스토킹(stalking) 등 다른 "이용자"를 괴롭히는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>10.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>다른 "이용자"에 대한 개인정보를 동의 없이 수집, 저장, 공개하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>11.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>불특정 다수를 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송하는 등 의 방법으로 “병원"의 "서비스"를 이용하여 영리목적의 활동을 하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>12.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>다른 "이용자"에 대한 건강진료 및 상담을 하거나 알선하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>13.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>제3자의 진료행위를 선전하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>14.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"서비스"의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>15.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"병원"이 제공하는 "서비스"에 정한 약관 혹은 기타 "서비스" 이용에 관한 규정을 위 반하는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>16.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>"병원"이 제공하는 "서비스"에 정한 약관 혹은 기타 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의 7 제1항에 위배되는 행위</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>17.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 부적절하다고 판단되는 행위</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>제1항에 해당하는 행위를 한 "이용자"가 있을 경우 "병원"은 본 약관 제5조 제4, 5항에서 정한 바에 따라 "이용자"의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 그 귀책사유로 인하여 "병원"이나 다른 "이용자"가 입은 손해를 배상할 책임이 있습니다.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- sec18 -->
        <div id="sec18">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제18조 (게시물 또는 내용물의 삭제)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"의 공개게시물의 내용이 다음 각 호에 해당하는 경우 "병원"은 "이용자"에게 사전 통지 없이 당 공개게시물을 삭제할 수 있고, 해당 "이용자"의 회원 자격을 제한, 정지 또는 상실시킬 수 있습니다.</p>
                                <div class="depth__text">
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>1.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>다른 "이용자" 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>2.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>공서양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>3.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>범죄행위와 관련이 있다고 판단되는 내용</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>4.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>다른 "이용자" 또는 제3자의 저작권 등 기타 권리를 침해하는 내용</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>5.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>기타 관계 법령에 위배된다고 판단되는 내용</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>6.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>종교적, 정치적 분쟁을 야기하는 내용으로서, 이러한 분쟁으로 인하여 “병원”의 업무가 방해되거나 해되리라고 판단되는 경우</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>7.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>타인의 정보 도용, 신청 또는 변경 시 허위 내용의 등록</p>
                                        </div>
                                    </div>
                                    <div class="depth__wrap depth04">
                                        <div class="depth__bullet">
                                            <p>8.</p>
                                        </div>
                                        <div class="depth__text">
                                            <p>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또 는 게시하는 행위</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"의 공개게시물로 인한 법률상 이익 침해를 근거로, 다른 "이용자" 또는 제3자가 "이용자" 또는 “병원"을 대상으로 하여 민형사상의 법적 조치(예:고소, 가처분신청, 손해배상청구소송)를 취하는 동시에 법적 조치와 관련된 게시물의 삭제를 요청해오는 경우, "병원"은 동 법적 조치의 결과(예: 검찰의 기소, 법원의 가처분결정, 손해배상판결)가 있을 때까지 관련 게시물에 대한
                                    접근을 잠정적으로 제한할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec19 -->
        <div id="sec19">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제19조 (저작권의 귀속 및 이용제한)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "병원"에 귀속합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"는 "병원"을 이용함으로써 얻은 정보를 "병원"의 사전승낙 없이 복제, 전송, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec20 -->
        <div id="sec20">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제20조 (분쟁해결)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>본 이용약관에 규정된 것을 제외하고 발생하는 "서비스" 이용에 관한 제반 문제에 관한 분쟁은 최대한 쌍방합의에 의해 해결하도록 합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>"이용자"가 홈페이지 이용과 관련하여 불만이 있거나 의견을 제시하고자 하는 경우에는 고객의 소리, 전화, 우편 등의 방법으로 홈페이지에 대한 불만사항 또는 의견을 제출하면 됩니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 "이용자"로부터 제출되는 불만사항 및 의견이 정당하다고 판단하는 경우 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 "이용자"에게 그 사유와 처리일정을 통보해야 합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec21 -->
        <div id="sec21">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제21조 (면책사항)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 “이용자”의 귀책사유로 인한 "서비스"의 이용장애에 대하여 책임을 지지않습니다.</p>
                                <p>“병원”은 “이용자”가 "서비스"를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 "서비스"를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.</p>

                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>③</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 “이용자”가 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>④</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”은 “이용자” 상호간 또는 “이용자”와 제3자 상호간에 "서비스"를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec22 -->
        <div id="sec22">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">제22조 (재판권 및 준거법)</h4><!-- depth03 -->
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>①</p>
                            </div>
                            <div class="depth__text">
                                <p>"병원"과 "이용자"간에 발생한 "서비스" 이용에 관한 분쟁에 대하여는 대한민국 법을 적용합니다.</p>
                            </div>
                        </div>

                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>②</p>
                            </div>
                            <div class="depth__text">
                                <p>“병원”과 "이용자"간에 "서비스" 이용으로 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원으로 합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- end -->
    </div>
</template>

<script>

    export default {
        data() {
            return {

            };
        },
        methods: {

        },
        mounted() {

        }

    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>