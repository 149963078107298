<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">개인정보수정</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">비밀번호변경</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">회원탈퇴</button></li>
        </ul>
    </div>

    <div class="contents input-form type1">
        <div class="input-form__top-wrap">
            <p class="input-form__top-text type2">&#183; 부정확한 휴대전화, 이메일 주소 입력 시 다른 사람에게 나의 정보가 전송될 수도 있습니다.</p>
            <p class="input-form__top-text type2">&#183; 정확하게 입력하여 주시기 바랍니다.</p>
        </div>

        <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
        <ul class="input-form__list">
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">아이디</div>
                    <div class="cont">
                        vjqmffltu
                    </div>
                </div>
            </li>

            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">이름</div>
                    <div class="cont">이준용</div>
                </div>
            </li>
            <li class="input-form__item">
                <div class="input-form__box phon">
                    <div class="tit"><span class="c-red">* </span>휴대전화</div>
                    <div class="cont row"><input type="text" class="cont__text-input"> <span class="line">-</span><input type="text" class="cont__text-input"> <span class="line">-</span><input type="text" class="cont__text-input"></div>
                </div>
            </li>
            <li class="input-form__item">
                <div class="input-form__box phon">
                    <div class="tit">유선 전화번호</div>
                    <div class="cont row"><input type="text" class="cont__text-input"> <span class="line">-</span><input type="text" class="cont__text-input"> <span class="line">-</span><input type="text" class="cont__text-input"></div>
                </div>
            </li>

            <li class="input-form__item">
                <div class="input-form__box email">
                    <div class="tit"><span class="c-red">* </span>이메일</div>
                    <div class="cont row"><input type="text" class="cont__text-input"> <span class="line">@</span><input type="text" class="cont__text-input"><select class="cont__select">
                            <option value="직접입력">직접입력</option>
                            <option value="naver.com">naver.com</option>
                            <option value="gmail.com">daum.net</option>
                            <option value="gmail.com">gmail.com</option>
                            <option value="yahoo.com">yahoo.com</option>
                            <option value="nate.com">nate.com</option>
                            <option value="korea.com">korea.com</option>
                            <option value="chol.com">chol.com</option>
                        </select></div>
                </div>
            </li>


            <li class="input-form__item">
                <div class="input-form__box address">
                    <div class="tit">주소</div>
                    <div class="cont">
                        <div class="cont-item"><input type="text" class="cont__text-input"><button class="btn">우편번호 검색</button></div>
                        <div class="cont-item"><input type="text" class="cont__text-input"></div>
                        <div class="cont-item"><input type="text" class="cont__text-input"></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="contents btn-wrap center">
        <a href="javascript:;" class="btn">취소</a>
        <a href="javascript:;" class="btn blue">확인</a>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>