<template>
  <div class="jobHealthCenter contents job_health_check">

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">보건관리위탁</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              산업안전보건법상 보건관리에 대한 사업주의 의무사항을 보다 경제적이고 효율적으로 수행할 수 있도록 중‧소규모 사업장을 대상으로 (보건관리자를 두어야 하는 50인 이상 사업장)산업보건사업 전문기관이 사업장 보건관리업무를 지도‧지원해주는 제도입니다.
            </p>
            <div class="depth__wrap info-reference">
              <div class="depth__text">
                <p class="small-text">[산업안전보건법 시행령 제23조]</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">보건관리자 선임</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              사업장의 유해인자, 작업방법 및 업무부담 등으로 인해 발생할 수 있는 각종 질병으로부터 근로자를 보호하기 위해 사업주 및 안전보건관리책임자‧관리감독자 등에게 보건에 관한 기술적인 사항을 지도‧조언할 수 있도록 사업장에 보건관리자를 두어야 합니다.
            </p>
            <div class="depth__wrap info-reference">
              <div class="depth__text">
                <p class="small-text">[산업안전보건법 시행령 제20조]</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">보건관리위탁 사업목표</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
             근로자의 건강보호와 건강증진, 질병예방, 생산성 향상 등이며 이를 위해 쾌적한 작업환경을 조성하는 것이 가장 중요한 목표입니다.사업의 성과는 사업주, 근로자, 보건관리전문기관의 공동의 노력으로 얻어지는 것이며 목표달성을 위해 사업주와 근로자의 자발적 참여가 가장 중요합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">보건관리전문기관 사업장 방문주기</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table>
          <caption class="blind">
            보건관리전문기관 사업장 방문주기
          </caption>
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 40%" />
            <col style="width: 40%" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>100인 이상 사업장</th>
              <th>100인 미만 사업장</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>의사</th>
              <td style="border-left: 1px solid #d9d9d9;">1회/분기</td>
              <td>1회/반기</td>
            </tr>
            <tr>
              <th>간호사</th>
              <td style="border-left: 1px solid #d9d9d9;">1회/월</td>
              <td>1회/월</td>
            </tr>
             <tr>
              <th>산업위생사</th>
              <td style="border-left: 1px solid #d9d9d9;">1회/격월</td>
              <td>1회/분기</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="depth__wrap info-reference">
      <div class="depth__text">
        <p class="small-text">[안전〮보건관리전문기관 및 재해예방 전문지도기관 관리규정 예규]</p>
      </div>
    </div>

     <div class="info-text depth__wrap contents">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">보건관리 주요 업무내용</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table>
          <caption class="blind">
            보건관리 주요 업무내용
          </caption>
          <colgroup>
            <col style="width: 30%" />
            <col style="width: 70%" />
          </colgroup>
          <tbody>
            <tr>
              <td>작업환경관리</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업환경관리 실태 파악 및 순회점검
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업환경 점검 및 평가
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업환경 측정 결과 사후 조치
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      유해작업환경 개선에 대한 지도
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      적정 보호구 선정 및 착용 지도
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      MSDS게시/비치 및 관리 지도
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      위험성평가에 관한 보좌 및 지도〮조언
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>건강상담 및 건강증진</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>정기적 건강상담 통한 질병조기 발견</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>질병자 및 유소견자에 대한 지도</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>생활습관 개선에 대한 지도</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>질병의 예방에 관한 지도</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>건강상담이 필요한 근로자 파악</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>건강진단관리</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>건강진단결과 설명</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>근로자 건강관리</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      질병 유소견자 사후관리
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      직업병 유소견자 관리
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      만성질환자 관리
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      근골격계 질환 예방 관리
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      근로자 보건관리현황 점검
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>보건정보관리</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      보건정보자료 제공
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      사업장 관리카드 작성
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      보건교육 실시
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      법적 준수사항 체크
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      보건업무기록 작성
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>