<template>
    <!-- 성형외과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>성형외과는 선천적 또는 후천적으로 피부와 그 밑에 있는 근골격에 결손 또는 변형이 있을 때 그 형태와 기능을 개선해 주는 외과의 한 분과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>외상, 재건, 미용</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>성형외과에 대해 많이 하는 오해 중 하나는 성형외과를 미용수술만 하는 임상분과로만 알고 있다는 것입니다. 하지만 미용수술은 성형외과의 많은 분야 중 일부에 불과합니다. 성형외과의 진료 영역은 어느 한 부위에 국한되어 있지 않고 광범위하기 때문에 다른 진료과와 협진하는 경우가 많습니다. </p>
                                <br>
                                <p>성형외과의 수술적 진료분야는 크게 재건수술과 미용수술로 나눌 수 있습니다. 재건 수술은 각종 안면 및 수부 외상, 선천성 기형, 화상, 두경부 종양, 조직 이식술 등으로 외형적 및 기능적 결함을 정상과 유사하게 재건합니다. </p>
                                <br>
                                <p>미용수술은 눈, 코 수술, 안면골격수술, 주름살 제거 수술, 지방흡입술, 유방성형술, 흉터수술, 레이져나 보톡스 시술 등을 통해 콤플렉스 극복과 보다 자신감을 갖고 생활할 수 있도록 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>