<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('department')">진료과 안내</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('doctor')">의료진 검색</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('centerClinic')">센터&클리닉</button></li>
                <li class="tab-menu__item on"><button type="button" class="tab-menu__btn">진료일정표</button></li>
            </ul>
        </div>
    </div>
    <div class="contents">
        <div class="med-calendar">
            <div class="med-calendar__left reservation__step" :class="{active : !searchChk}">
                <div class="med-calendar__header" @click="[this.searchChk = false, this.selectedDept = '']">
                    <a href="javascript:" class="reservation__header">
                        <h3 class="reservation__header--title">STEP01</h3><span
                            class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">{{selectedDept ? selectedDept : '진료과 선택'}}</h3>
                    </a>
                </div>
                <div class="med-calendar--con-wrap" :class="{active : !searchChk}">
                    <div class="med-calendar--con reservation__con tablet-show">
                        <div class="reservation__consonant">
                            <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
                        </div>
                        <div class="reservation__department-list">
                            <ul>
                                <li v-for="dept in deptList" :key=dept>
                                    <a href="javascript:;" @click="getSchedule(dept)">{{dept.deptNm}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="med-calendar--con tablet-pc-none">
                        <ul class="initial-search__list">
                            <li v-for="(item, index) in setPcList(deptList)" :key="index">
                                <a href="javascript:"
                                    :class="{ 'active': activeIndex === index, 'disable': item.initial_list.length == 0 }"
                                    @click="scrollToItem($event, index)">{{ item.initial }}</a>
                            </li>
                        </ul>
                        <ul class="med-calendar__initial-list">
                            <template v-for="(item, index) in setPcList(deptList)" :key="index">
                                <li class="med-calendar__initial-list--item" v-if="item.initial_list.length > 0">
                                    <h3>{{ item.initial }}</h3>
                                    <ul>
                                        <li v-for="i in item.initial_list" :key=i>
                                            <a href="javascript:" @click="[this.transChk = false, getSchedule(i)]">{{ i.deptNm }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="med-calendar__right reservation__step" :class="{active : searchChk}">
                <div class="med-calendar__header">
                    <a href="javascript:" class="reservation__header">
                        <h3 class="reservation__header--title">STEP02</h3><span
                            class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">진료 일정표 확인</h3>
                    </a>
                </div>
                <div class="med-calendar--con-wrap" :class="{active : searchChk}">
                    <div class="calendar__header-wrap">
                        <div class="calendar__header">
                            <button class="calendar__header--button prev" @click="prevCalendar"></button>
                            <h4 class="calendar__header--title">{{$commonUtils.dateToStr(setMonday(nowDate), '.')}} <span>~</span> {{$commonUtils.dateToStr(setSunday(nowDate), '.')}}</h4>
                            <button class="calendar__header--button next" @click="nextCalendar"></button>
                        </div>
                    </div>
                    <p class="med-calendar--day-text tablet-show"><span class="day"></span> 외래진료</p>

                    <div class="med-calendar--con table-wrap table-type1">
                        <table>
                            <caption class="blind">의료진 , 주진료분야, 오전진료, 오후진료, 안내사항</caption>
                            <colgroup>
                                <col style="width: 175px;">
                                <col style="width: auto;">
                                <col style="width: 90px;">
                                <col style="width: 90px;">
                                <col style="width: 25%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>의료진</th>
                                    <th>주진료분야</th>
                                    <th>오전진료</th>
                                    <th>오후진료</th>
                                    <th>안내사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="scheduleList.length == 0 && !searchChk">
                                    <td class="data-none" colspan="5">
                                        <img src="@/assets/resource/icon/med-calendar_data-none02.png" alt="이미지">
                                        <h3 class="depth01__title"><span class="blue__title">진료과</span>를 먼저 선택해주세요</h3>
                                    </td>
                                </tr>
                                <tr v-if="scheduleList.length == 0 && searchChk">
                                    <td class="data-none" colspan="5">
                                        <img src="@/assets/resource/icon/med-calendar_data-none.png" alt="이미지">
                                        <h3 class="depth01__title">조회된 의료진이 없습니다</h3>
                                    </td>
                                </tr>
                                <tr v-for="(i, idx) in scheduleList" :key="i">
                                    <td>
                                        <div class="med-calendar__table-th pc-none">
                                            <h4>의료진</h4>
                                        </div>
                                        <div class="med-calendar__table-td">
                                            <h3>{{i.hptlJobTitle ? i.hptlJobTitle : i.drName}}</h3>
                                            <div class="btn-wrap" v-if="!rsvChk && transChk && i.doctorDept.drRsvOpenYn == 'Y'">
                                                <a href="javascript:" class="btn blue" @click="goReservation(i)">진료예약</a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="med-calendar__table-th pc-none">
                                            <h4>주진료분야</h4>
                                        </div>
                                        <div class="med-calendar__table-td">
                                            <p>{{i.doctorDept.special}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="med-calendar__table-th pc-none">
                                            <h4>오전진료</h4>
                                        </div>
                                        <ul class="med-calendar__table-td day">
                                            <li v-for="am in i.am" :key="am">
                                                <template v-if="am.jinyn == 1">
                                                    {{am.daydisp.split('(')[0]}}<span class="day"></span>
                                                </template>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <div class="med-calendar__table-th pc-none">
                                            <h4>오후진료</h4>
                                        </div>
                                        <ul class="med-calendar__table-td day">
                                            <li v-for="pm in i.pm" :key="pm">
                                                <template v-if="pm.jinyn == 1">
                                                    {{pm.daydisp.split('(')[0]}}<span class="day"></span>
                                                </template>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <div class="med-calendar__table-th pc-none">
                                            <h4>안내사항</h4>
                                        </div>
                                        <ul class="med-calendar__table-td">
                                            <p v-if="i.doctorAbsence" v-html="i.doctorAbsence.reason"></p>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <p class="med-calendar--day-text info-reference tablet-pc-none"><span class="day"></span> 외래진료</p>
    </div>
</template>


<script>
import Consonant from '../common/Consonant.vue'

export default {
    components: {
        Consonant
    },
    data() {
        return {
            activeIndex: 0,
            deptList: '',
            orgList: '',
            consonantList: '',
            nowDate: new Date,
            startDate: '',
            endDate: '',
            scheduleList: [],
            rsvChk: false,
            transChk: false,
            searchChk: false,
            prevData: null,
            selectedDept: '',
        }
    },
    methods: {
        setMonday(now){
            now.setHours(0, 0, 0, 0)
            const day = now.getDay()
            const diff = now.getDate() - day + (day == 0 ? -6 : 1)
            const monday = new Date(now.setDate(diff))
            
            return monday
        },
        setSunday(now){
            const day = now.getDay()
            const diff = now.getDate() - day + 7
            const sunday = new Date(now.setDate(diff))
            
            return sunday;
        },
        goMenu(path){
            this.$router.push(`/hospital/department-doctor/${path}.do`)
        },
        searchConsonant(str){
            if(str == ''){
                this.deptList = this.orgList
            }else{
                this.deptList = this.$commonUtils.listToConList(this.orgList, 'deptNm', str)
            }
        },
        async getDept(){
            await this.axios.get(this.api.department.list, {
                params: {
                    instNo: 1,
                    deptClsf: 'A',
                    startIndex: 1,
                    langType: 'kr'
                }
            }).then(res => {
                this.deptList = res.data.deptList
                this.orgList = this.deptList
                if(!this.consonantList){
                    this.consonantList = this.$commonUtils.setConsonantList(this.deptList, 'deptNm')
                }
            })
        },
        async getSchedule(item){
            if(item){
                this.prevData = item
                this.selectedDept = item.deptNm
            }
            if(this.prevData.rsvOpenYn != 'Y'){
                alert('온라인으로 예약할 수 없는 진료과입니다.\n270-1000 번으로 문의 부탁드립니다.')
                this.rsvChk = true
            }else{
                this.rsvChk = false
            }
            this.axios.get(this.api.doctor.schedule, {
                params: {
                    fromdd: this.$commonUtils.dateToStr(this.setMonday(this.nowDate), ''),
                    todd: this.$commonUtils.dateToStr(this.setSunday(this.nowDate), ''),
                    deptCd: this.prevData.deptCd,
                    orddeptcd: this.prevData.emrDeptCd ? this.prevData.emrDeptCd : this.prevData.deptCd,
                    exposeYn: 'Y'
                }
            })
            .then(res => {
                this.searchChk = true
                const list = res.data
                this.scheduleList = []

                for(let i of list){
                    const obj = i
                    const schedule = i.schedule
                    if(schedule.length > 0){
                        const am = []
                        const pm = []
                        for(let j of schedule){
                            if(j.ampm == 'AM') am.push(j)
                            if(j.ampm == 'PM') pm.push(j)
                        }
                        obj.am = am
                        obj.pm = pm
                    }
                    this.scheduleList.push(obj)
                }
                this.transChk = true
            })
            .catch(err => {
                console.error(err)
            })
        },
        prevCalendar(){
            const prev = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() - 7)
            if(prev < new Date()){
                alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                return
            }
            this.nowDate = prev
            this.getSchedule()
        },
        nextCalendar(){
            const next = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 7)
            const now = new Date()
            if(next > new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30)){
                alert("선택한 기간의 진료일정은 조회할 수 없습니다.")
                return
            }
            this.nowDate = next
            this.getSchedule()
        },
        setPcList(arr){
            const obj = {
                'ㄱ':[]
                ,'ㄴ':[]
                ,'ㄷ':[]
                ,'ㄹ':[]
                ,'ㅁ':[]
                ,'ㅂ':[]
                ,'ㅅ':[]
                ,'ㅇ':[]
                ,'ㅈ':[]
                ,'ㅊ':[]
                ,'ㅋ':[]
                ,'ㅌ':[]
                ,'ㅍ':[]
                ,'ㅎ':[]
            }

            for(let i of arr){
                const initial = this.$commonUtils.getConsonant(i.deptNm)

                obj[initial].push(i)
            }

            const result = Object.keys(obj).map(initial => ({
                initial,
                initial_list: obj[initial]
            }))

            return result
        },
        scrollToItem(event, index) {
            this.activeIndex = index
            const itemList = document.querySelectorAll('.med-calendar__initial-list--item > h3')
            const cho = this.setPcList(this.deptList)[index]['initial']
            let targetElement
            itemList.forEach((i) => {
                if(i.textContent == cho){
                    targetElement = i
                }
            })
            
            if (targetElement) {
                // targetElement의 위치로 스크롤
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        },
        goReservation(info){
            if(info.doctorAbsence && info.doctorAbsence.reason){
                alert(info.doctorAbsence.reason)
            }
            this.$store.commit('setReserveInfo', info)
            this.$router.push('/reserve/reservation.do')
        },
    },
    mounted() {
        this.getDept()
    },
}
</script>