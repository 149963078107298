// src/utils/blockedUrls.js
export const noAuthCheckUrls = [
    '/idResolution.do',
    '/pwResolution.do',
    '/idInquiry.do',
    '/pwInquiry.do',
    'login.do',
    'deptLogin.do',
    '/member/join.do',
    '/member/agree.do',
    '/member/cert.do',
    '/util/checkSuccess.do',
    '/member/register.do',
    '/member/complete.do',
    '/member/sleep.do',
    '/member/pwChange.do',
    '/member/lastPwChange.do'
    // 건진센터
    // 직업환경의학센터
];