<template>
    <!-- 이비인후과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>이비인후과는 귀, 코, 부비동, 인두, 후두, 구강 두경부, 갑상선, 침샘, 기관지, 식도 등의 부위에 대한 치료와 외과적 수기 및 수술을 시행하는 진료과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>만성중이염, 만성부비동염</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">만성중이염</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>중이염은 중이강 내에 발생하는 모든 염증성 변화를 총칭하는 질환명입니다. 만성 중이염은 고막 안쪽인 중이와, 중이와 연결된 귀 뒤쪽 머리뼈의 일부분인 유양돌기에 비가역적인 염증이 존재하는 상태입니다. 만성 중이염은 고막이 천공된 소견을 보이는 천공성(비진주종성) 만성 중이염과 고막의 천공 여부와 관계없이 진주종 형성이 나타나는 진주종성 중이염으로 다시 세분할 수 있습니다. 천공성(비진주종성) 만성 중이염은 고막에 천공이 있으면서, 중이 내부의 만성적 염증에서 생긴 고름이 지속적으로 발생하며, 난청이 점차 진행되는 병입니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
              
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">부비동염</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>흔히 축농증으로 잘 알려진 부비동염은 코 주위 머리뼈 속 공기가 차 있는 ‘부비동’이라는 공간에 염증이 생겨 점막이 붓거나 고름성 콧물이 고여 있는 상태를 말합니다. 부비동과 콧속은 작은 구멍으로 통해 있어 코안과 환기가 이루어지고, 부비동 내 분비물이 자연적으로 코안으로 배출되는데, 염증으로 인해 콧물이 나가지 못하고 고여 콧물, 코막힘 등의 증상이 나타나며, 기온 및 습도의 변화, 대기 오염, 유전적 요인 등에 의해 발생할 수 있습니다. 급성 부비동염은 피로감, 미열, 코막힘, 누런 콧물과 안면부 통증 등이 나타나며, 만성 부비동염은 코막힘, 지속적인 누런 콧물, 목뒤로 넘어가는 콧물, 더 진행될 시 냄새를 잘 맡지 못하거나 두통을 호소하거나 집중력이 저하되기도 합니다. </p>
                                <p>내시경을 통해 코안을 관찰하며, X-ray, CT 등 방사선학적 검사를 시행하거나 알레르기 검사가 필요하기도 합니다. 이러한 여러 가지 검사로 부비동염을 임상적으로 진단하며 대부분의 경우 단순 부비동염으로 진단되나 일부의 경우에서 수술 후 현미경을 통한 조직검사 등이 필요하기도 하고, 수술 후에 수술 전 진단과는 다른 양성 혹은 악성 종양 등으로 진단될 수 있으며, 질병의 종류에 따라 추가적인 치료가 필요한 경우도 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">알레르기 비염</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>알레르기 비염은 코점막이 특정 물질에 대한 과민반응을 나타내는 질환입니다. 개인에 따라 다르지만, 계절에 상관없이 연중 증상이 지속되기도 하고, 특정 계절에만 발생하기도 하는데, 이 경우는 꽃가루나 온도 변화에 특히 민감한 경우가 많습니다. 알레르기 비염의 진단에 있어 가장 중요한 것은 환자가 일상에서 경험한 임상 증상입니다. 환자의 연령과 직업, 증상의 종류 및 정도, 언제부터 증상이 발생했는지, 가족력과 주거환경, 알레르기 과거력, 치료 경력 등을 자세히 파악합니다. 추가로 피부 반응시험, 혈액 알레르기 검사 등을 시행해 볼 수 있습니다. 또한 약물치료만큼이나 중요한 것이 환경요법입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>