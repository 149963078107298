<template>
  <div class="first_reserve_popup name_certify_popup my_health_popup" v-if="isPopupVisible">
      <div class="first_reserve_wrap">
          <div class="first_tit">
              <h4>개인정보 수집·이용 동의</h4>
              <a href="javascript:;" @click="closePopup">
                  <span></span>
                  <span></span>
              </a>
          </div>

          <div class="contents text-box type2 first_info_box">
              <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의
                  <span class="c-red">(필수)</span>
              </h3>
              <div class="text-box__wrap">
                  <h3>1. 개인정보의 수집·이용목적</h3>
                  <p>나의 건강관리</p>
                  <h3>2. 수집하는 개인정보의 항목 필수 정보 </h3>
                  <p>날짜, 시간, 혈압(수축기혈압, 이완기혈압), 혈당(현재상태, 혈당수치), 체질량(성별, 키, 몸무게)</p>
                  <h3>3. 개인정보의 보유·이용기간</h3>
                  <p>회원 탈퇴 시까지</p>
                  <h3>4. 귀하는 위와 같은 개인정보 수집·이용에 동의하지 않으실 수 있습니다. 동의 하지 않을 시 나의 건강관리 서비스가 제한될 수 있습니다</h3>
              </div>
          </div>
          <div class="agree-input">
              <label for="agree-y" class="agree-input__item">
                  <input type="radio" id="agree-y" class="agree-input__radio" name="agree" v-model="agreeStatus" value="yes">
                  <span class="icon"></span> 동의합니다 </label>
              <label for="agree-n" class="agree-input__item">
                  <input type="radio" id="agree-n" class="agree-input__radio" name="agree" v-model="agreeStatus" value="no">
                  <span class="icon"></span> 동의하지 않습니다
              </label>
          </div>

          <div class="btn-wrap center first_btn">
              <a href="javascript:;" class="btn" @click="closePopup">취소</a>
              <a href="javascript:;" class="btn gray" @click="confirmAgreement">확인</a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
          isPopupVisible: false,
          agreeStatus: 'no'
      };
  },
  methods: {
      openPopup() {
          this.isPopupVisible = true;
          this.agreeStatus = 'no'
      },
      closePopup() {
          this.isPopupVisible = false;
      },
      confirmAgreement() {
          if (this.agreeStatus === 'no') {
              alert("개인정보 수집·이용 동의에 동의가 필요합니다");
              return
          }
          
          this.$parent.submit();
          this.closePopup();
      }
  }
};
</script>