<template>
    <div class="vision contents">
        <img src="@/assets/resource/img/vision1.png" alt="VISION 2025, always, LOVE, _ifelong health care 평생 건강관리, One stop full service 원 스톱 풀 서비스, alue of customer centered 고객 가치 중심, Easy asy & often communication 더 쉽고 자주 소통">
        <img src="@/assets/resource/img/vision2.png" alt="VISION 2025, 고객의 평생건강을 지키는 정직한 동반자">
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>