<template>
    <div class="first_reserve_popup name_certify_popup my_health_popup" v-if="isPopupVisible">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>개인정보 수집·이용 동의</h4>
                <a href="javascript:;" @click="closePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="contents text-box type2 first_info_box">
                <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의
                    <span class="c-red">(필수)</span>
                </h3>
                <div class="text-box__wrap">
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                    <h3>1. 개인정보의 수집·이용 목적</h3>
                    <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                </div>
            </div>
            <div class="agree-input">
                <label for="agree-y" class="agree-input__item">
                    <input type="radio" id="agree-y" class="agree-input__radio" name="agree" v-model="agreeStatus" value="yes">
                    <span class="icon"></span> 동의합니다 </label>
                <label for="agree-n" class="agree-input__item">
                    <input type="radio" id="agree-n" class="agree-input__radio" name="agree" v-model="agreeStatus" value="no">
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>

            <div class="btn-wrap center first_btn">
                <a href="javascript:;" class="btn" @click="closePopup">취소</a>
                <a href="javascript:;" class="btn gray" @click="confirmAgreement">확인</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isPopupVisible: true,
            agreeStatus: ''
        };
    },
    methods: {
        closePopup() {
            this.isPopupVisible = false;
        },
        confirmAgreement() {
            if (this.agreeStatus === 'yes') {
                alert("저장되었습니다.");
                this.closePopup();
            } else if (this.agreeStatus === 'no') {
                alert("개인정보 수집·이용 동의에 동의가 필요합니다");
            } else {
                alert("동의 여부를 선택해 주세요.");
            }
        }
    }
};
</script>