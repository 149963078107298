<template>
  <div class="contents">
    <ul class="step-textbox consignment-step">
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                        작업환경측정 의뢰
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item step-textbox__sub-item step-textbox__sub-item1-2">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                      작업장 구비서류
                    </h3>
                </div>
                <div class="step-textbox__con">
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <p>①</p>
                        </div>
                        <div class="depth__text">
                            <p>
                              공정흐름도 및 공정별 인원현황
                            </p>
                        </div>
                    </div>
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <p>②</p>
                        </div>
                        <div class="depth__text">
                            <p>
                              공장배치도
                            </p>
                        </div>
                    </div>
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <p>③</p>
                        </div>
                        <div class="depth__text">
                            <p>
                              공정별 화학물질 목록
                            </p>
                        </div>
                    </div>
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <p>④</p>
                        </div>
                        <div class="depth__text">
                            <p>
                              목록에 따른 MSDS
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                        작업환경측정 <br> 예비조사
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                        측정 실시일 확정
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                        작업환경측정
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item step-textbox__sub-item step-textbox__sub-item4-5">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                      30일 이내 결과서 발송
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                       측정결과서 송부 및 노동부 보고
                    </h3>
                </div>
            </div>
        </li>
    </ul>
    <h4 class="depth02__title title contents">문의 : 보건대행실 270-1060</h4>
  </div>
</template>
<script>
export default {};
</script>