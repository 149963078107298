<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <template v-for="(menu, idx) in menuList" :key="idx">
                    <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '591'">
                        <router-link :to="menu.menuUrl" >
                            <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                        </router-link>
                    </li>
                </template>
            </ul>
        </div>
    </div>

    <div class="join contents">
        <div class="input-form type1">
            <p class="input-form__top-text type2">&#183; 사용 중이신 현재 비밀번호를 입력하신 후 새로 사용할 비밀번호를 입력해주시기 바랍니다.</p>
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>

            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>현재 비밀번호</div>
                        <div class="cont">
                            <input type="password" class="cont__text-input" v-model="memOrgPwd" maxlength="12">
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호</div>
                        <div class="cont">

                            <!-- 조건 성립시 class='active' 추가-->
                            <div class="password active">
                                <!-- 조건 미성립시 class='b-red' 추가-->
                                <input type="password" class="cont__text-input" v-model="memPwd" maxlength="12">
                            </div>
                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                            <p class="desc">동일문자 연속 4개 사용금지</p>
                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>새로운 비밀번호 확인</div>
                        <div class="cont">
                            <!-- 조건 성립시 class='active' 추가-->
                            <div class="password">
                                <!-- 조건 미성립시 class='b-red' 추가-->
                                <input type="password" class="cont__text-input b-red" v-model="memPwdChk" maxlength="12">
                            </div>
                            <p class="desc c-red" v-show="memPwd != '' && memPwdChk != '' && memPwd != memPwdChk">비밀번호가 일치하지 않습니다.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <router-link to="/mypage/mypage.do" class="btn">취소</router-link>
            <a href="javascript:;" class="btn blue" @click="changePassword">비밀번호 변경</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                memOrgPwd: '',
                memPwd: '',
                memPwdChk: ''
            };
        },
        methods: {
            changePassword() {

                this.axios({
                    url: this.api.member.pwChange,
                    method: 'post',
                    params: {
                        memOrgPwd: btoa(this.memOrgPwd),
                        memPwd: btoa(this.memPwd)
                    }
                }).then(res => {
                    if (res.data.result.code === 'OK') {
                        this.$store.commit("getLoginStatus", false);
                        alert('비밀번호가 변경되었습니다.\n다시 로그인 해주세요.');
                        location.href="/member/login.do";
                    } else {
                        alert(res.data.result.message);
                    }
                });
            }

        },
        mounted() {

        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>