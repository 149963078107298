<template>
    <footer class="footer" :class="{'list-show': mainPage}">
        <div class="footer__bar">
            <div class="footer__center-list">
                <ul>
                    <li class="footer__center-item">
                        <a href="javascript:;" class="footer__center-link"><span class="name">주요 센터 이동하기</span><span class="icon"><img src="../../assets/resource/icon/menu-icon1.png" alt="이미지"></span></a>
                    </li>
                    <li class="footer__center-item">
                    <a href="javascript:;" class="footer__center-link"> <span class="name">창원파티마병원</span><span class="icon"><img src="../../assets/resource/icon/menu-icon2.png" alt="이미지"></span></a>
                    </li>
                    <li class="footer__center-item">
                        <a href="https://www.fatimahosp.co.kr/deptHp/1/healthcenter/main.do" class="footer__center-link" title="새창열림" target="_blank"> <span class="name">건강증진센터</span><span class="icon"><img src="../../assets/resource/icon/menu-icon3.png" alt="이미지"></span></a>
                    </li>
                    <li class="footer__center-item">
                        <a href="https://www.fatimahosp.co.kr/deptHp/3/medicalcenter/main.do" class="footer__center-link" title="새창열림" target="_blank"> <span class="name">진료협력센터</span><span class="icon"><img src="../../assets/resource/icon/menu-icon4.png" alt="이미지"></span></a>
                    </li>
                    <li class="footer__center-item">
                        <a href="https://www.fatimahosp.co.kr/deptHp/2/onecenter/main.do" class="footer__center-link" title="새창열림" target="_blank"> <span class="name">직업환경의학센터</span><span class="icon"><img src="../../assets/resource/icon/menu-icon5.png" alt="이미지"></span></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer__inner">
            <img src="../../assets/resource/icon/footer-logo.png" class="footer__logo" alt="창원파티마병원 로고">
            <div class="footer__menu">
                <ul class="footer__list">
                    <li class="footer__item">
                        <a href="/hospital/etc/privacy.do" class="footer__link blue">개인정보처리방침</a>
                    </li>
                    <li class="footer__item">
                        <a href="/hospital/etc/email.do" class="footer__link">이메일무단수집거부</a>
                    </li>
                    <li class="footer__item">
                        <a href="/hospital/etc/terms.do" class="footer__link">이용약관</a>
                    </li>
                    <li class="footer__item">
                        <a href="/hospital/etc/nonpayment.do" class="footer__link">비급여진료비용</a>
                    </li>
                    <li class="footer__item">
                        <a href="/hospital/etc/patientRight.do" class="footer__link">환자권리존중 및 보호에 관한 선언문</a>
                    </li>
                </ul>
                <hr class="mo-line">
                <p class="footer__desc mt30">주소 : 경상남도 창원시 의창구 창이대로 45 창원파티마병원 (우 51394)</p>
                <p class="footer__desc">대표번호 : 055-270-1000(통합컨텍센터) <span class="line"></span> <br> 팩스 : 055-265-7766</p>
                <p class="footer__desc mt50">Copyrightⓒ Changwon Fatima Hospital.</p>
            </div>
            <div class="footer__site">
                <div class="footer__btn">
                        <div class="footer__site-wrap" :style="{ height: dynamicHeight }">
                            <ul class="footer__site-list">
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">의료기관</p>
                                    <a href="https://www.fatima.or.kr/main/main.do" title="새창열림" target="_blank">대구파티마병원</a>
                                    <!-- <a href="https://www.cmc.or.kr/main.main.sp" title="새창열림" target="_blank">가톨릭중앙의료원</a> -->
                                    <!-- <a href="https://www.cmcseoul.or.kr/page/main" title="새창열림" target="_blank">가톨릭대학교서울성모병원</a> -->
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">종교</p>
                                    <a href="https://www.benedictine.or.kr" title="새창열림" target="_blank">(재)대구포교베네딕도수녀회</a>
                                    <a href="http://cathms.kr" title="새창열림" target="_blank">천주교마산교구</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">관공서</p>
                                    <a href="https://www.mohw.go.kr/" title="새창열림" target="_blank">보건복지부</a>
                                    <!-- 이동안됨 -->
                                    <a href="http://health.changwon.go.kr/" title="새창열림" target="_blank">창원시보건소</a>
                                    <a href="https://www.nhis.or.kr/nhis/index.do" title="새창열림" target="_blank">국민건강보험공단</a>
                                    <a href="https://www.hira.or.kr/main.do" title="새창열림" target="_blank">건강보험심사평가원</a>
                                    <a href="https://www.comwel.or.kr/comwel/landing.jsp" title="새창열림" target="_blank">근로복지공단</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">기타</p>
                                    <a href="http://fatimahosp-irb.co.kr" title="새창열림" target="_blank">IRB</a>
                                    <a href="https://www.kha.or.kr/main" title="새창열림" target="_blank">대한병원협회</a>
                                    <a href="https://www.kosqua.net/" title="새창열림" target="_blank">한국의료질향상학회</a>
                                    <a href="https://www.qi.or.kr/" title="새창열림" target="_blank">한국QI간호사회</a>
                                    <a href="https://www.koiha.or.kr/web/kr/index.do" title="새창열림" target="_blank">의료기관평가인증원</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">센터바로가기</p>
                                    <a href="/deptHp/1/healthcenter/main.do" title="새창열림" target="_blank">건강증진센터</a>
                                    <a href="/deptHp/2/onecenter/main.do" title="새창열림" target="_blank">직업환경의학센터</a>
                                    <a href="/deptHp/3/medicalcenter/main.do" title="새창열림" target="_blank">진료협력센터</a>
                                </li>
                            </ul>
                        </div>
                    <button type="button" class="footer__plusBtn" @click="toggleMenu()">관련사이트 <span class="plus"></span></button>
                </div>
            </div>
        </div>
        <a href="#" class="go-top" v-if="!mainPage"></a>
    </footer>

    <FirstReservePopUp :popupVisible="popupVisible" @hide-popup="hidePopup"/>
</template>


<script>
import FirstReservePopUp from '../reserve/FirstReservePopUp.vue';
// import { gsap } from "gsap/all";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    components: {
        FirstReservePopUp
    },
    data() {
        return {
            isMenuOpen: false,
            dynamicHeight: '0',
            popupVisible: false,
            mainPage: '',
            previousHeight: 0,
            mutationObserver: null,
        };  
    },
    methods: {
         checkSize() {
            const currentHeight = document.documentElement.scrollHeight;
            if (currentHeight !== this.previousHeight) {
                this.previousHeight = currentHeight;
                this.gsapGT()
            }
        },
        showPopup() {
            if(!this.patName){
                alert('이름을 입력해주세요.')
                return
            }
            if(!this.patHpNo){
                alert('전화번호를 입력해주세요.')
                return
            }
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        toggleMenu() {
            let footerSiteHeight = document.querySelector(".footer__site-list").offsetHeight;
            this.isMenuOpen = !this.isMenuOpen;
                if (this.isMenuOpen) {   
                    this.dynamicHeight = `${footerSiteHeight}px`;
                } else {
                    this.dynamicHeight = `0`;
                }
        },
        gsapGT(){
            setTimeout(() => {
                if(this.$route.path != '/'){
                    gsap.killTweensOf('.footer__center-list'); // 모든 항목의 애니메이션을 제거합니다.
                    ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // 모든 ScrollTrigger 인스턴스를 제거합니다.
                }
                if (window.matchMedia("(max-width: 720px)").matches) {
                    gsap.to('.go-top', {scrollTrigger: {trigger: '.footer',start: 'top bottom-=10px',toggleClass: { targets: '.go-top', className: 'on' },scrub: true}});
                }else{
                    gsap.to('.go-top', {scrollTrigger: {trigger: '.footer',start: 'top bottom-=60px',toggleClass: { targets: '.go-top', className: 'on' },scrub: true}});
                }
                gsap.to('.go-top', {scrollTrigger: {trigger: '.container',start: 'top top',end: '+=100',toggleClass: { targets: '.go-top', className: 'show' },scrub: true},opacity: 1,pointerEvents: 'all'});                 
            }, 1000);
        },
        removeGsapGT(){
            ScrollTrigger.getAll().forEach((el) => {
                const t = el.vars.trigger
                const s = el.vars.start
                if(typeof t == 'string' && t.indexOf('container') > -1){
                    el.kill()
                }
                if(typeof s == 'string' && s.indexOf('top bottom-=10px') > -1 || s.indexOf('top bottom-=60px') > -1){
                    el.kill()
                }
            })
        },
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                this.removeGsapGT()
                this.gsapGT()
            }
        }
    },
    mounted() {
        setTimeout(() => {
            gsap.to('.footer__center-list', { scrollTrigger: { trigger: '.information', start: '30% center', end: '-100%', scrub: true }, bottom: '0' });
            gsap.to('.footer__center-list', { scrollTrigger: { trigger: '.footer', start: 'top 100%', scrub: true, toggleClass: { targets: '.footer__center-list', className: 'on' } } });
        }, 1000);
  
        this.removeGsapGT()
        this.gsapGT()
        

        if (this.$route.path === '/') {
                this.mainPage = true
            } else {
                this.mainPage = false
            }
    },
    beforeDestroy() {
        gsap.killTweensOf('.footer__center-list'); // 모든 항목의 애니메이션을 제거합니다.
        ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // 모든 ScrollTrigger 인스턴스를 제거합니다.
    },
}
</script>

