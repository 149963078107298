<template>
  <div class="contents">
    <div class="info-text depth__wrap depth01">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">절차</h4>
      </div>
    </div>
    <ul class="step-textbox">
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
                <h3 class="depth02__title">신청</h3>
                <!-- <img
                src="/resource/img/certification_step01.png"
                class="step-textbox__title--icon"
                alt="이미지"
                /> -->
            </div>
            <div class="step-textbox__con">
                <div class="depth__wrap">
                <div class="depth__bullet">
                    <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                    <p>4층 건강증진센터 접수대</p>
                </div>
                </div>
            </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
            <div class="step-textbox__title">
                <h3 class="depth02__title">수납 및 수령</h3>
                <!-- <img
                src="/resource/img/certification_step02.png"
                class="step-textbox__title--icon"
                alt="이미지"
                /> -->
            </div>
            <div class="step-textbox__con">
                <div class="depth__wrap">
                <div class="depth__bullet">
                    <span class="bullet03"></span>
                </div>
                <div class="depth__text">
                    <p>1층 원무과 의무기록사본발급창구</p>
                </div>
                </div>
            </div>
            </div>
        </li>
    </ul>
    <div class="depth__wrap info-reference">
        <div class="depth__bullet">
            <p class="small-text">※</p>
        </div>
        <div class="depth__text">
            <p class="small-text">
            의무기록 열람이나 사본발급은 의료법 제21조(기록 열람 등)에 의거 본인에게만 허용되는 것이 원칙입니다.<br>단, 친족이나 대리인의 경우 아래의 구비서류 지참하시면 발급 가능합니다.
            </p>
        </div>
    </div>

    <div class="info-text contents">
      <h3 class="depth02__title title">환자가 동의한 경우</h3>
      <div class="table-wrap table-type1">
        <table>
          <caption class="blind">표</caption>
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 20%" />
            <col style="width: 60%" />
          </colgroup>
          <thead>
            <tr>
              <th colspan="2">수령자</th>
              <th>구비서류</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>환자</b></td>
              <td><b>본인</b></td>
              <td><b>본인신분증</b></td>
            </tr>
            <tr>
              <td>친족</td>
              <td>
                배우자, 직계존속, <br />
                직계비속, 배우자의 직계존속
              </td>
              <td>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>수령인의 신분증사본</p>
                  </div>
                </div>
                 <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>환자의 신분증사본 (17세 미만 제외)</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>환자의 자필동의서 (14세 미만 제외)</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>대리인</td>
              <td>
                친족이외의 지정대리인 <br />
                (형제, 자매, 자부, 사위 보험회사 등)
              </td>
              <td>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>수령인의 신분증사본</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>환자의 신분증사본</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>환자의 자필동의서 및 자필 위임장 (만 14세 미만은 법정대리인이 작성)</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="info-text">
      <h3 class="depth02__title title">
        환자 동의가 불가능한 경우
      </h3>
      <div class="table-wrap table-type1">
        <table>
          <caption class="blind">표</caption>
          <colgroup>
            <col style="width: 33.33%" />
            <col style="width: 33.33%" />
            <col style="width: 33.33%" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>수령자</th>
              <th>구비서류</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                1. 환자사망 <br />
                2. 의식불명 <br />
                3. 행방불명 <br />
                4. 의사무능력자
              </td>
              <td>
                환자친족만 가능 <br />
                (배우자, 직계존속, 직계비속, 배우자의 직계존속)
              </td>
              <td>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <p>1.</p>
                  </div>
                  <div class="depth__text">
                    <p>신청자의 신분증사본</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <p>2.</p>
                  </div>
                  <div class="depth__text">
                    <p>
                      친족관계 확인가능한 서류 (가족관계증명서, 주민등록등본 등)
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <p>3.</p>
                  </div>
                  <div class="depth__text">
                    <p>환자의 동의가 불가함을 확인가능한 서류</p>
                  </div>
                </div>
                <div class="depth__wrap depth02 info-reference">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>사망사실확인 서류(가족관계증명서 등)</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>의식불명 확인 진단서</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      행방불명확인 서류(주민등록등본, 법원 실종선고 결정문 등)
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      의사무능력자 증명서류(법원선고결정문, 정신과 전문의
                      진단서)
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="depth__wrap info-reference">
        <div class="depth__bullet">
            <p class="small-text">※</p>
        </div>
        <div class="depth__text">
            <p class="small-text">
            형제, 자매의 경우 친족 기준으로 발급 가능하나 환자의 친족이 아무도 없는 경우만 가능합니다.(증빙서류(제적등본 등) 제출 필요)
            </p>
        </div>
    </div>
      <div class="mt60 btn-wrap center">
          <a
            href="javascript:;"
            class="btn blue"
            download="진료기록 열람 및 사본발급 동의서.hwp"
            >진료기록 열람 및 사본발급 동의서</a
          >
          <a
            href="javascript:;"
            class="btn mint"
            download="진료기록 열람 및 사본발급 위임장.hwp"
            >진료기록 열람 및 사본발급 위임장</a
          >
        </div>
  </div>

</template>
<script>
export default {};
</script>