<template>
   <div class="tab-menu__wrap">
      <div class="tab-menu type02">
          <ul class="tab-menu__list">
              <li class="tab-menu__item on">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_reserve.do`" type="button" class="tab-menu__btn">예약</router-link>
              </li>
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_Check.do`" type="button" class="tab-menu__btn">검사</router-link>
              </li>
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_etc.do`" type="button" class="tab-menu__btn">기타</router-link>
              </li>
          </ul>
      </div>
  </div>
  <div class="board-list">
    <div class="contents faq">
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="faq-title" :class="{ 'active': activeIndex === index }" @click.stop="toggle(index)">
          <div class="faq-title__text">
            <span class="icon">Q </span>
            <em>{{ faq.question }}</em>
          </div>
          <div class="faq-title__btn"></div>
        </div>
        <slide-up-down :active="activeIndex === index">
          <div class="faq-content">
            <span>A</span>
            <p v-html="faq.answer"></p>
          </div>
        </slide-up-down>
        <div class="faq-title__btn--mo" :class="{ 'active': activeIndex === index }"   @click.stop="toggle(index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  components: {
    SlideUpDown,
  },
  data() {
    return {
      activeIndex: null,
      deptCd: this.$route.params.deptCd,
      hpUrl: this.$route.params.hpUrl,
      faqs: [
        {
          question: "종합검진을 받는데 소요시간은 어떻게 되나요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p><b>건강검진 프로그램에 따라 2시간~ 1박2일까지 소요시간은 다양합니다.</b></p>
                          <p><b>검진예약 후 해당 프로그램에 따른 검사소요시간을 안내해 드립니다.</b></p>
                      </div>
                    </div>
                    <br>
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                          <p><b>대략적인 소요시간은 아래와 같습니다.</b></p>
                           <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>기본 프로그램 : 2~3시간 전후 소요</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>정밀 프로그램 : 3시간 이상</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>종합정밀 Ⅰ(당일) 프로그램 : 4시간 이상</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="depth__wrap depth03">
                      <div class="depth__text">
                          <p><b>선택검사 추가 시 소요시간</b></p>
                           <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>수면내시경 : 1시간 추가 소요</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>대장내시경 : 2시간 추가 소요</p>
                            </div>
                        </div>
                        <div class="depth__wrap depth04">
                            <div class="depth__bullet">
                                <p>-</p>
                            </div>
                            <div class="depth__text">
                                <p>PET-CT : 1시간 30분 ~ 2시간 추가 소요</p>
                            </div>
                        </div>
                      </div>
                    </div>`
        },
        {
          question: "금식은 언제부터 해야 하나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>검진 2~3일 전부터는 술, 육류, 기름진 음식은 불가합니다.</p>
                        <p>검사 전날 저녁식사는 8시 이전에 가볍게 드시고 저녁 9시부터 검사 종료 시까지 금식이 필요합니다.(물, 담배, 껌 등 불가)</p>
                    </div>
                  </div>`
        },
        {
          question: "건강검진 당일 혈압약과 당뇨약은 복용해도 되나요?",
          answer: `<div class="depth__wrap depth04">
                    <div class="depth__text">
                        <p>항고혈압제를 복용 중이신 분은 위내시경이 예정된 경우 건강검진 당일 새벽 6시경 소량의 물로 복용하시기 바랍니다.</p>
                        <p>대장내시경이 같이 예약 된 분은 마지막 관장약 복용 후 소량의 물로 복용하시기 바랍니다.</p>
                        <p>혈당강하제(당뇨약)을 복용하시는 분은 건강검진 당일은 복용이 불가합니다.</p>
                    </div>
                  </div>`
        },
        {
          question: "건강검진 전 아스피린, 항혈소판제, 항응고제 복용은 어떻게 해야 하나요?",
          answer: `<div class="depth__wrap depth03">
                    <div class="depth__text">
                        <p><b>심근경색, 협심증, 심실세동, 뇌졸중(뇌혈관 질환)과 같은 질환의 병력이 없이 건강한 고객님이 1차 예방을 위해 아스피린을 복용하고 있을 때는 건강검진 1주일 전부터 복용을 금합니다.</b></p>
                        <br>
                        <p><b>단, 심근경색, 협심증, 뇌졸중(뇌혈관 질환)과 같은 질환의 병력이 있어 동일질환의 2차 예방을 위해 아스피린, 항혈전제를 복용하고 있거나, 심실세동, 심장판막증, 색전증의 병력이 있어 항응고제를 복용하고 있을 때는 건강검진 7일 전까지 반드시 처방을 받은 병원의 담당의사와 약복용 여부에 대해 상의가 필요합니다.</b></p>
                        <br>
                         <p><b>건강검진 당일까지 아래 약제를 계속 복용하셨다면 당일 검사 전에 상담간호사에게 알려 주십시오.</b></p>
                         <div class="depth__wrap depth04">
                          <div class="depth__bullet">
                              <p>-</p>
                          </div>
                          <div class="depth__text">
                              <p>항혈전제(플레탈, 플라빅스, 플라비톨 등)</p>
                          </div>
                      </div>
                      <div class="depth__wrap depth04">
                          <div class="depth__bullet">
                              <p>-</p>
                          </div>
                          <div class="depth__text">
                              <p>항응고제(쿠마딘, 와파린 등)</p>
                          </div>
                      </div>
                    </div>
                  </div>`
        }
      ]
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    handleClickOutside(event) {
      const isClickInside = this.$el.contains(event.target);
      if (!isClickInside) {
        this.activeIndex = null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
