<template>
            <div class="tab-menu__wrap">
                <div class="tab-menu type02">
                    <ul class="tab-menu__list">
                        <li class="tab-menu__item on"><button type="button" class="tab-menu__btn">진료과 안내</button></li>
                        <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('doctor')">의료진
                                검색</button></li>
                        <li class="tab-menu__item"><button type="button" class="tab-menu__btn"
                                @click="goMenu('centerClinic')">센터&클리닉</button></li>
                        <li class="tab-menu__item"><button type="button" class="tab-menu__btn"
                                @click="goMenu('treatmentChart')">진료일정표</button></li>
                    </ul>
                </div>
            </div>
            <div class="initial-search">
                <h3>초성검색</h3>
                <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
            </div>

            <div class="contents">
                <ul class="med-departments__list">
                    <li class="med-departments__list--item" v-for="dept in deptList" :key="dept">
                        <router-link :to="`/hospital/department-doctor/departmentDetail.do?deptCd=${dept.deptCd}`">
                            <div class="med-departments__list--img">
                                <img :src="`/displayFile.do?name=${dept.thumbAttach.savedName}`" alt="이미지"
                                    v-if="dept.thumbAttach.savedName">
                                <img src="@/assets/resource/icon/med-departments__list--img.png" alt="이미지" v-else>
                            </div>
                            <h4>{{ dept.deptNm }}</h4>
                        </router-link>
                    </li>
                </ul>
            </div>
</template>

<script>
import Consonant from '../common/Consonant.vue';
export default {
    components: {
        Consonant
    },
    data() {
        return {
            deptList: '',
            orgList: '',
            consonantList: '',
        }
    },
    methods: {
        async getDept(){
            await this.axios.get(this.api.department.list, {
                params: {
                    instNo: 1,
                    deptClsf: 'A',
                    startIndex: 1,
                    langType: 'kr',
                    orderType: 'sortOrd'
                }
            }).then(res => {
                this.deptList = res.data.deptList
                this.orgList = res.data.deptList
                if(!this.consonantList){
                    this.consonantList = this.$commonUtils.setConsonantList(this.orgList, 'deptNm')
                }
            })
        },
        goMenu(path){
            this.$router.push(`/hospital/department-doctor/${path}.do`)
        },
        searchConsonant(str){
            if(str == ''){
                this.deptList = this.orgList
            }else{
                this.deptList = this.$commonUtils.listToConList(this.orgList, 'deptNm', str)
            }
        }
    },
    mounted() {
        this.getDept();
    },
}
</script>
