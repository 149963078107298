<template>
  <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>

  <div class="healthCheckupReserve">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">예약자정보</h4>
          <div class="depth__wrap depth02">
            <div class="depth__text">
              <div class="depth__wrap">
                <div class="depth__text">
                  <p>
                    수신자의 정보가 정확하지 않을 경우 정상적으로 예약이 되지 않을 수
                    있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-form type1 mt40">
        <ul class="input-form__list">
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">이름</div>
              <div class="cont">
                <p>{{$store.state.myInfoStore.memName}}</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">생년월일</div>
              <div class="cont">
                <p>{{$commonUtils.makeDateFormat($store.state.myInfoStore.memBirth, '.')}}</p>
              </div>
            </div>
          </li>
          <li class="input-form__item item2">
            <div class="input-form__box">
              <div class="tit">성별</div>
              <div class="cont">
                <p>{{memGender == 'M' ? '남성' : memGender == 'F' ? '여성' : ''}}</p>
              </div>
            </div>
            <div class="input-form__box">
              <div class="tit">연락처</div>
              <div class="cont">
                <p>{{$store.state.myInfoStore.memHpNo}}</p>
              </div>
            </div>
          </li>
        </ul>
        <p class="input_additional">※ 개인정보수정은 병원 홈페이지에서 가능합니다.</p>
      </div>
    </div>

    <div class="contents">
      <div class="info-text depth__wrap depth01 mb20">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">안내사항</h4>
          <div class="depth__wrap depth02">
            <div class="depth__text">
              <div class="depth__wrap">
                <div class="depth__text">
                  <p>
                    검사인원에 제한이 있는 검사(대장내시경, CT, 유방초음파,PET-CT, MRI
                    등)를 선택하실 경우, 직원과 상담 후 일정이 변경될 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info__con">
        <div class="info__right">
          <ul class="accordion">
            <!-- Accordion Item 1 -->
            <li :class="['accordion__item', { active: isAccordion1Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(1)">
                <div class="title">
                  <h3>STEP01.</h3>
                  <sub class="text">{{selectedProgram ? selectedProgram : '건강검진 유형 선택'}}</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion1Style" ref="accordion1Content">
                <div class="accordion__con">
                  <div class="accordion__con--text">
                    <!-- <div class="contents">
                      <h3 class="depth02__title title">성별</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn"><p>남성</p></button>
                        <button class="price_btn"><p>여성</p></button>
                      </div>
                    </div> -->
                    <h3 class="depth02__title title">생애주기별프로그램</h3>
                    <div class="price_btn_wrap">
                      <button class="price_btn" :class="{active : program == '그린코스'}" @click="setProgram('그린코스')">
                        <p>그린코스</p>
                        <p>{{$commonUtils.commaNum(course['그린코스'][memGender]['PRICE'])}}원</p>
                      </button>
                      <button class="price_btn" :class="{active : program == '블루코스'}" @click="setProgram('블루코스')">
                        <p>블루코스</p>
                        <p>{{$commonUtils.commaNum(course['블루코스'][memGender]['PRICE'])}}원</p>
                      </button>
                      <button class="price_btn" :class="{active : program == '골드코스'}" @click="setProgram('골드코스')">
                        <p>골드코스</p>
                        <p>{{$commonUtils.commaNum(course['골드코스'][memGender]['PRICE'])}}원</p>
                      </button>
                      <button class="price_btn" :class="{active : program == '실버코스'}" @click="setProgram('실버코스')">
                        <p>실버코스</p>
                        <p>{{$commonUtils.commaNum(course['실버코스'][memGender]['PRICE'])}}원</p>
                      </button>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">질환별 정밀 프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn menProgram" :class="{active : program == '남성정밀코스'}" @click="setProgram('남성정밀코스')" v-if="memGender == 'M'">
                          <p>남성정밀코스</p>
                          <p>{{$commonUtils.commaNum(course['남성정밀코스'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn womenProgram" :class="{active : program == '여성정밀 A코스'}" @click="setProgram('여성정밀 A코스')" v-if="memGender == 'F'">
                          <p>여성정밀A코스</p>
                          <p>{{$commonUtils.commaNum(course['여성정밀 A코스'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn womenProgram" :class="{active : program == '여성정밀 B코스(대장내시경 제외)'}" @click="setProgram('여성정밀 B코스(대장내시경 제외)')" v-if="memGender == 'F'">
                          <p>여성정밀B코스</p>
                          <p>{{$commonUtils.commaNum(course['여성정밀 B코스(대장내시경 제외)'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn" :class="{active : program == '소화기정밀코스'}" @click="setProgram('소화기정밀코스')">
                          <p>소화기정밀코스</p>
                          <p>{{$commonUtils.commaNum(course['소화기정밀코스'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn" :class="{active : program == '호흡기정밀코스'}" @click="setProgram('호흡기정밀코스')">
                          <p>호흡기정밀코스</p>
                          <p>{{$commonUtils.commaNum(course['호흡기정밀코스'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn" :class="{active : program == '순환기정밀코스'}" @click="setProgram('순환기정밀코스')">
                          <p>순환기정밀코스</p>
                          <p>{{$commonUtils.commaNum(course['순환기정밀코스'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn" :class="{active : program == '뇌정밀코스'}" @click="setProgram('뇌정밀코스')">
                          <p>뇌정밀코스</p>
                          <p>{{$commonUtils.commaNum(course['뇌정밀코스'][memGender]['PRICE'])}}원</p>
                        </button>
                      </div>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">암전문프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" :class="{active : program == '조기암(PET-CT)검진'}" @click="setProgram('조기암(PET-CT)검진')">
                          <p>조기암(PET-CT)검진</p>
                          <p>{{$commonUtils.commaNum(course['조기암(PET-CT)검진'][memGender]['PRICE'])}}원</p>
                        </button>
                      </div>
                    </div>
                    <div class="contents">
                      <h3 class="depth02__title title">프리미엄 프로그램</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" :class="{active : program == '종합정밀 I(당일)'}" @click="setProgram('종합정밀 I(당일)')">
                          <p>종합정밀 I(당일)</p>
                          <p>{{$commonUtils.commaNum(course['종합정밀 I(당일)'][memGender]['PRICE'])}}원</p>
                        </button>
                        <button class="price_btn" :class="{active : program == '종합정밀 II(숙박)'}" @click="setProgram('종합정밀 II(숙박)')">
                          <p>종합정밀 II(숙박)</p>
                          <p>{{$commonUtils.commaNum(course['종합정밀 II(숙박)'][memGender]['PRICE'])}}원</p>
                        </button>
                      </div>
                    </div>

                    <div class="btn-wrap center">
                      <a href="javascript:;" class="btn blue-line" @click="toggleAccordion(2)">선택완료</a>
                    </div>
                  </div>

                  <div class="contents">
                    <div class="program_info_btn_wrap">
                      <button @click="selectProgram">
                        선택한 프로그램 상세정보<span v-if="!programShow">▼</span><span v-else>▲</span>
                      </button>
                    </div>
                  </div>

                  <!-- 추가되는 프로그램 테이블 -->
                  <PersonalReservationTableVue :txt="program" :style="programInfoStyle" :show="programShow"></PersonalReservationTableVue>
                  <!-- 추가되는 프로그램 테이블 -->
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn mt40" @click="handleAccordionBottomBtnClick"></a>
            </li>

            <li :class="['accordion__item step02', { active: isAccordion2Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(2)">
                <div class="title">
                  <h3>STEP02.</h3>
                  <sub class="text">추가선택검사</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion2Style" ref="accordion2Content">
                <div class="accordion__con">
                  <div class="accordion__con--text">
                    <!-- 남성전용 필수 검사 택 -->
                    <div class="add_box_btn_wrap" v-if="program == '남성정밀코스'">
                      <h3 class="depth02__title title">
                        남성정밀코스<span class="red__title">&nbsp;&nbsp;필수검사 선택(택1)
                        <img src="/resource/icon/Triangle_red_icon.png" alt="이미지"/></span>
                      </h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn" :class="{active : option.includes('MD-CT(흉부)')}" @click="optionCheck('MD-CT(흉부)')">
                          MD-CT(흉부)
                        </button>
                        <button class="price_btn" :class="{active : option.includes('대장내시경')}" @click="optionCheck('대장내시경')">
                          대장내시경
                        </button>
                      </div>
                    </div>
                    <!-- 남성전용 필수 검사 택 -->

                    <div class="contents" v-if="selectedProgram && course[selectedProgram][memGender]['optionList']">
                      <h3 class="depth02__title title">기본 포함 항목</h3>
                      <div class="price_btn_wrap">
                        <button class="price_btn basic_pick" v-for="i in course[selectedProgram][memGender]['optionList']" :key="i"><p>{{i}}</p></button>
                      </div>
                    </div>

                    <div class="contents">
                      <h3 class="depth02__title title">추가선택검사</h3>
                      <div class="price_btn_wrap">
                        <template v-for="(value, key) in optionList" :key="value">
                          <button class="price_btn" :class="[
                            {active : option.includes(key) || (selectedProgram && course[selectedProgram][memGender]['optionList'] && course[selectedProgram][memGender]['optionList'].includes(key))},
                            {gray: deactiveList.includes(key)}]"
                            v-if="!(selectedProgram && course[selectedProgram][memGender]['disabledOptionList'] && course[selectedProgram][memGender]['disabledOptionList'].includes(key))
                                && !(selectedProgram && course[selectedProgram][memGender]['optionList'] && course[selectedProgram][memGender]['optionList'].includes(key))
                                && !disabled[memGender]['option'].includes(key)"
                                @click="optionCheck(key)">
                            <p>{{key}}</p>
                            <p>{{$commonUtils.commaNum(value['PRICE'])}}원</p>
                          </button>
                        </template>
                      </div>
                    </div>
                    <div class="contents pb60">
                      <div class="btn-wrap center">
                        <a href="javascript:;" class="btn blue-line" @click="toggleAccordion(3)">선택완료</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn" @click="toggleAccordion(2)"></a>
            </li>

            <li :class="['accordion__item', { active: isAccordion3Active }]">
              <a href="javascript:;" class="accordion__header" @click="toggleAccordion(3)">
                <div class="title">
                  <h3>STEP03.</h3>
                  <sub class="text">희망예약일 선택</sub>
                </div>
                <span class="accordion__arrow"></span>
              </a>
              <div class="accordion__con--wrap" :style="accordion3Style" ref="accordion3Content">
                <div class="accordion__con">
                  <div class="accordion__con--text calendar_wrap">

                    <calendar :classList="classList" @clickFnc="setTime" @getClass="getTime" ref="calendar"></calendar>

                    <div class="calendar_line"></div>

                    <div class="calender_reserve">
                      <div class="calender_reserve_top">
                        <p>선택하신날짜</p>
                        <h3>{{selectedDate.date}}</h3>
                      </div>
                      <div class="calender_reserve_bottom">
                        <h4>이용안내</h4>
                        <p>예약사항은 나의건강검진 > 예약정보에서 확인 또는 취소가 가능합니다.</p>
                        <p>예약상담은 전화 055)270-1200~1로 문의 주시기 바라며,<br>상담 가능 시간은 평일 09:00~16:40, 토요일은<br>09:00~12:00입니다.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <a href="javascript:;" class="accordion_bottom_btn" @click="toggleAccordion(3)"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="contents" v-if="selectedProgram && selectedDate.text">
      <div class="reserve_result_box">
          <div class="result_box_left">
             <h3>건강검진 프로그램 선택내역</h3>
              <ul class="result_desc_list">
                <li class="result_desc_item">
                    <h4>건강검진 프로그램 종류</h4>
                    <ul>
                      <li class="blue__title">{{selectedProgram}} : {{$commonUtils.commaNum(course[selectedProgram][memGender]['PRICE'])}}원</li>
                    </ul>
                </li>
                <li class="result_desc_item ml60" v-if="option.length > 0">
                    <h4>추가검진 종류</h4>
                    <ul>
                      <li class="blue__title" v-for="item in filteredOptions" :key="item">{{item}} : {{$commonUtils.commaNum(optionList[item]['PRICE'])}}원</li>
                    </ul>
                </li>
              </ul>
          </div>
          <div class="result_box_right">
              <p>총검사비용 : <span>{{$commonUtils.commaNum(sumPrice())}} 원</span></p>
          </div>
      </div>
    </div>

     <div class="contents mt60">
          <div class="btn-wrap center">
            <a href="javascript:;" class="btn blue-line" @click="reservation">예약하기</a>
            <a href="javascript:;" class="btn" style="font-weight: 700">취소</a>
          </div>
      </div>

  </div>
</template>

<script>
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'
import Calendar from '@/components/common/Calendar.vue';
import PersonalReservationTableVue from '../healthcenter/PersonalReservationTable.vue';
import {disabled, course, optionList} from './course_option'

export default {
  components: {
    NameCertifyPopUp,
    Calendar,
    PersonalReservationTableVue
  },
  data() {
    return {
      myInfo: '',
      popupVisible: false,

      activeButtonBasic: null,
      isProgramInfoActive: false,
      programInfoHeight: "0px",
      programInfoDisplay: "none",
      isAccordion1Active: "true",
      accordion1Height: "auto",
      accordion1Display: "block",
      isAccordion2Active: false,
      accordion2Height: "0px",
      accordion2Display: "none",
      isAccordion3Active: false,
      accordion3Height: "0px",
      accordion3Display: "none",

      memGender: this.$store.state.myInfoStore.memGender,
      //step01
      program: '',
      selectedProgram: '',
      selectedDate: {
        text: '',
        date: ''
      },
      //step02
      option: [],
      programShow: false,
      step: 1,
      classList: [],

      //disabled
      disabled: disabled,
      //course
      course: course,
      //option
      optionList: optionList,

      deactiveList: [],
    };
  },
  watch: {
      $route(to, from){
        if(to.path != from.path){
          setTimeout(() => {
            let tableItems = document.querySelectorAll(".table-wrap");

              tableItems.forEach((el) => {
                this.checkTableWidth(el);
              });

              let tableItem = document.querySelectorAll(".table-wrap");

              tableItem.forEach((el) => {
                el.addEventListener("click", () => {
                  el.classList.add('on');
                });
              }); 
          }, 100);    
        }
      },
      program(){
        this.programShow = false;
      }
    },
  computed: {
    programInfoStyle() {
      return {
        height: this.programInfoHeight,
        display: this.programInfoDisplay,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion1Style() {
      return {
        height: this.accordion1Height,
        display: this.accordion1Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion2Style() {
      return {
        height: this.accordion2Height,
        display: this.accordion2Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    accordion3Style() {
      return {
        height: this.accordion3Height,
        display: this.accordion3Display,
        overflow: "hidden",
        transition: "height 0.5s ease-in-out",
      };
    },
    filteredOptions() {
      let arr = this.option
      if(this.selectedProgram == '남성정밀코스'){
        const exclude = ['MD-CT(흉부)', '대장내시경']
        arr = this.option.filter(item => !exclude.includes(item));
      }
      return arr
    }
  },
  methods: {
    showPopup() {
        this.popupVisible = true;
    },
    hidePopup() {
        this.popupVisible = false;
    },
    toggleProgramInfo() {
      if (this.isProgramInfoActive) {
        this.programInfoHeight = "0px";
        setTimeout(() => {
          this.programInfoDisplay = "none";
        }, 200);
      } else {
        this.programInfoDisplay = "block";
        this.$nextTick(() => {
          const scrollHeight = `${this.$refs.programInfo.scrollHeight}px`;
          this.programInfoHeight = scrollHeight;
          setTimeout(() => {
            this.programInfoHeight = "auto";
          }, 600);
        });
      }
      this.isProgramInfoActive = !this.isProgramInfoActive;
    },
    toggleAccordion(number) {
      if (number === 1) {
        if (this.isAccordion1Active) {
          this.accordion1Height = "0px";
          setTimeout(() => {
            this.accordion1Display = "none";
          }, 200);
        } else {
          this.isAccordion2Active = true
          this.toggleAccordion(2)
          this.isAccordion3Active = true
          this.toggleAccordion(3)
          this.accordion1Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion1Content.scrollHeight}px`;
            this.accordion1Height = scrollHeight;
            setTimeout(() => {
              this.accordion1Height = "auto";
            }, 600);
          });
        }
        this.isAccordion1Active = !this.isAccordion1Active;
      }
      if (number === 2) {
        if (this.isAccordion2Active) {
          this.accordion2Height = "0px";
          setTimeout(() => {
            this.accordion2Display = "none";
          }, 200);
        } else {
          if(!this.program){
            alert('프로그램 클릭 후 선택이 가능합니다.')
            return
          }
          this.isAccordion1Active = true
          this.toggleAccordion(1)
          this.isAccordion3Active = true
          this.toggleAccordion(3)
          this.selectedProgram = this.program
          this.accordion2Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion2Content.scrollHeight}px`;
            this.accordion2Height = scrollHeight;
            setTimeout(() => {
              this.accordion2Height = "auto";
            }, 600);
          });
        }
        this.isAccordion2Active = !this.isAccordion2Active;
      }
      if (number === 3) {
        if (this.isAccordion3Active) {
          this.accordion3Height = "0px";
          setTimeout(() => {
            this.accordion3Display = "none";
          }, 200);
        } else {
          if(!this.program){
            alert('프로그램 클릭 후 선택이 가능합니다.')
            return
          }
          if(this.program == '남성정밀코스' && (!this.option.includes('MD-CT(흉부)') && !this.option.includes('대장내시경'))){
            alert('필수 검사를 선택해주세요.')
            return
          }
          this.isAccordion1Active = true
          this.toggleAccordion(1)
          this.isAccordion2Active = true
          this.toggleAccordion(2)
          this.accordion3Display = "block";
          this.$nextTick(() => {
            const scrollHeight = `${this.$refs.accordion3Content.scrollHeight}px`;
            this.accordion3Height = scrollHeight;
            setTimeout(() => {
              this.accordion3Height = "auto";
            }, 600);
          });
        }
        this.isAccordion3Active = !this.isAccordion3Active;
      }
      this.step = number
    },
    handleAccordionBottomBtnClick() {
      if (this.isProgramInfoActive) {
        this.toggleProgramInfo();
      } else {
        this.toggleAccordion(1);
      }
    },
    checkTableWidth(element) {
      let table = element.querySelector('table');
      if (table.offsetWidth > element.offsetWidth) {
          element.classList.add('scroll');
      } else {
          element.classList.remove('scroll');
      }
    },
    selectProgram(){
      if(!this.program){
        alert('선택된 검진프로그램이 없습니다.')
        return
      }
      this.programShow = !this.programShow;
    },
    optionCheck(txt){
      if(!txt){
        return
      }
      if(this.program == '남성정밀코스'){
        if(txt == 'MD-CT(흉부)'){
          this.option = this.option.filter(item => item !== '대장내시경')
        }
        if(txt == '대장내시경'){
          this.option = this.option.filter(item => item !== 'MD-CT(흉부)')
        }
      }
      const proArr = ['그린코스', '실버코스', '여성정밀 B코스(대장내시경 제외)', '호흡기정밀코스', '순환기정밀코스', '뇌정밀코스', '조기암(PET-CT)검진']
      if(proArr.includes(this.program)){
        if(txt == '수면내시경'){
          this.option = this.option.filter(item => item !== '대장내시경')
        }
        if(txt == '대장내시경'){
          this.option = this.option.filter(item => item !== '수면내시경')
        }
      }
      this.option.includes(txt) ? this.option = this.option.filter(item => item !== txt) : this.option.push(txt)
      if(this.option.includes('대장내시경') && this.program == '남성정밀코스'){
        this.deactiveList = ['대장내시경', '수면내시경']
        this.option = this.option.filter(item => item !== '수면내시경')
      }else{
        this.deactiveList = []
      }
    },
    setProgram(txt){
      this.program = txt
      this.option = []
      this.deactiveList = []
      
      this.accordion2Height = "0px";
      setTimeout(() => {
        this.accordion2Display = "none";
      }, 200);
      this.isAccordion2Active = false;
    },
    sumPrice(){
      let sum = 0
      let arr = this.option
      if(this.selectedProgram == '남성정밀코스'){
        const exclude = ['MD-CT(흉부)', '대장내시경']
        arr = this.option.filter(item => !exclude.includes(item));
      }
      for(let i of arr){
        sum += this.optionList[i]['PRICE']
      }
      sum += this.course[this.selectedProgram][this.memGender]['PRICE']
      return sum
    },
    getTime(){
      this.classList = []
      const today = new Date()
      const thisMonth = today.getMonth()
      const thisDate = Number(today.getFullYear() + this.$commonUtils.leftPad(today.getMonth() + ""))
      const calToday = this.$refs.calendar.today
      const calDate = Number(calToday.getFullYear() + this.$commonUtils.leftPad(calToday.getMonth() + ""))
      const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
      const startMonth = startDate.getMonth()

      let forDay = startDate.getDate();
      let forMonth = null
      let forEndDay = 0
      
      // 1. 이번 달인 경우 
      // 1.1 14일이 지나면 다음달 return
      if(thisMonth != startMonth && thisDate == calDate){
        return
      }
      // 1.2 14일이 지나도 이번달 for 문
      if(thisMonth == startMonth && thisDate == calDate){
        forMonth = today
      }
      // 2. 이번 달보다 높은 경우
      // 2.1 다음 달
      if(thisDate < calDate && thisDate + 1 == calDate){
        forMonth = calToday
      }
      // 2.2 2달 이후
      if(thisDate < calDate && thisDate + 1 < calDate){
        forDay = 1
        forMonth = calToday
      }
      // 2.3 1년 경과
      if(thisDate + 100 == calDate){
        forDay = 1
        forMonth = calToday
        forEndDay = new Date().getDate() - 1
      }
      // 2.4 1년 이후
      if(thisDate + 100 < calDate){
        return
      }
      // 3. 이전 달인 경우
      if(thisDate > calDate){
        return
      }

      for (let i = forDay; i <= new Date(forMonth.getFullYear(), forMonth.getMonth() + 1, forEndDay).getDate(); i++) {
        const date = new Date(forMonth.getFullYear(), forMonth.getMonth(), i);
        const week = date.getDay()
        
        if(week != 0 && week != 6){
          const yyyy = date.getFullYear();
          const mm = String(date.getMonth() + 1).padStart(2, '0');
          const dd = String(date.getDate()).padStart(2, '0');
          this.classList.push({text: `${yyyy}${mm}${dd}`, dayType: 'pos'});
        }
      }
    },
    setTime(item){
      const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
      const weekDay = weekDays[item.week];
      this.selectedDate = {
        text: item.text,
        date: `${item.text.slice(0,4)}년 ${item.text.slice(4,6)}월 ${item.text.slice(6)}일(${weekDay})`
      }
    },
    reservation(){
      if(!this.selectedProgram || !this.selectedDate.text){
          alert('예약정보 입력 후 예약 가능합니다.')
          return
      }

      if(confirm('현재 내용으로 예약하시겠습니까?')){
        // 일정 조회
        const params = {
            healexamflag: 'C'
        }
        this.axios.get(this.api.appointment.selectIntApp, {
            params: params
        }).then(res => {
            let checkDup = false
            for(let i of res.data){
                if(i.reser_date == this.selectedDate.text){
                    alert('해당 일자에 예약이 존재합니다.')
                    checkDup = true
                    break
                }
            }

            if(checkDup) return

            // 예약
            // let memo = this.course[this.selectedProgram][this.memGender]['CODE']
            let memo = ''
            for(let i of this.option){
                memo += !memo ? i :  '/' + i
            }
            const param = {
                reser_date : this.selectedDate.text,
                schlgrde: this.course[this.selectedProgram][this.memGender]['CODE'],
                remcnts: memo
            }

            this.axios({
                method: 'post',
                url: this.api.appointment.cprsvReservation,
                params: param
            }).then(res => {
                if(res.data.errormsg){
                    alert(res.data.errormsg)
                    if(res.data.errortype == 'currentMember_null') location.reload(true)
                    return
                }
                const data = {
                healexamflag: 'C',
                pkgcd: this.course[this.selectedProgram][this.memGender]['CODE'],
                reser_date : this.selectedDate.text,
                remcnts: memo
                }
                const path = this.$route.path
                this.$store.commit('setCprsv', data)
                this.$router.push(path.replace('PersonalReservation', 'PersonalReservationComplete'))
            }).catch(err => {
                alert('오류가 발생했습니다.')
            })
        }).catch(err => {
            console.error(err)
        })
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      let tableItems = document.querySelectorAll(".table-wrap");

      if(tableItems.length > 0){
        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
      }
    });
  },
  mounted() {
    this.myInfo = this.$store.state.myInfoStore
    this.popupVisible = this.myInfo.memPatId == '' || this.myInfo.memPatId == null ? true : false
    this.isAccordion1Active = true;
    this.accordion1Height = "auto";
    this.accordion1Display = "block";
    this.getTime()
    if(this.$store.state.reserveStore.cprsvInfo){
      const data = this.$store.state.reserveStore.cprsvInfo
      this.setProgram(data.program)      
      setTimeout(() => {
        this.toggleAccordion(2)
        if(data.option.length > 0){
          for(let i of data.option) this.optionCheck(i)
        }
        // this.toggleAccordion(3)
      }, 600)
    }
    // window.addEventListener('beforeunload', () => {
    //   this.$store.commit('setCprsvInfo', '')
    // });
  },
  beforeUnmount() {
    this.$store.commit('setCprsvInfo', '')
  },
};
</script>

