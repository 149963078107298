<template>
    <p class="contents sub-text">진료예약 {{$store.state.reserveStore.reserve.clsf == 'N' ? '접수가' : '변경이'}} 
        <span class="c-blue">정상적으로 완료되었습니다.</span>
    </p>

    <div class="contents">
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">환자번호</h4>
                </div>
            </div>
            <div class="table-cont">
                <ul class="table-list type02">
                    <li class="table-item">
                        <div class="item tit">이름</div>
                        <div class="item">{{myInfo.memName}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">환자번호</div>
                        <div class="item">{{myInfo.memPatId}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">휴대전화</div>
                        <div class="item">{{myInfo.memHpNo}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">이메일</div>
                        <div class="item">{{myInfo.memEmail}}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">예약정보</h4>
                </div>
            </div>
            <div class="table-cont">
                <ul class="table-list type02">
                    <li class="table-item">
                        <div class="item tit">진료과</div>
                        <div class="item">{{$store.state.reserveStore.reserve.deptNm}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">예약일</div>
                        <div class="item">{{$store.state.reserveStore.reserve.dt}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">의료진</div>
                        <div class="item">{{$store.state.reserveStore.reserve.hptlJobTitle ? $store.state.reserveStore.reserve.hptlJobTitle : $store.state.reserveStore.reserve.drName}}</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">예약시간</div>
                        <div class="item" v-if="$store.state.reserveStore.reserve.ampm === ''">{{`${$store.state.reserveStore.reserve.time.slice(0,2)}:${$store.state.reserveStore.reserve.time.slice(2)}`}}</div>
                        <div class="item" v-else>{{$store.state.reserveStore.reserve.ampm}}</div>
                    </li>
                </ul>
            </div>
            <div class="depth__wrap info-reference">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title"> 예약일시를 꼭 확인 후 내원해 주시기 바랍니다.</h4>
                </div>
            </div>
            <div class="depth__wrap">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title">예약 진료과로 바로 가셔서 성함을 말씀하시고 직원의 안내를 받으십시오.</h4>
                </div>
            </div>
            <div class="depth__wrap">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title">본인확인 의무화제도 시행으로 진료 전 반드시 신분증을 챙겨서 방문해주십시오.</h4>
                </div>
            </div>
        </div>
        <div class="info-text">
            <div class="btn-wrap center">
                <!-- 수정필요 -->
                <router-link to="/mypage/reservation/selectReservation.do" class="btn blue">예약 현황</router-link>
                <a href="javascript:;" class="btn" @click="changeReservation">예약 변경</a>
                <a href="javascript:;" class="btn" @click="reservationCancel">예약 취소</a>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            myInfo: '',
            reserv: '',
        }
    },
    mounted() {
        // 진료예약조회
        this.axios.get(this.api.mypage.reservationList,
            {
                params: {
                    fromdd: this.$store.state.reserveStore.reserve.otpt_orddd,
                    todd: this.$store.state.reserveStore.reserve.otpt_orddd
                }
            })
            .then(res => {
                for(let i of res.data.list){
                    if(i.cretno == this.$store.state.reserveStore.reserve.otpt_cretno){
                        this.reserv = i
                        break
                    }
                }
            })
            .catch(err => {
                console.error(err)
            })

        this.axios.get(this.api.member.info)
            .then(res => {
                if(res.data) this.myInfo = res.data
            })
            .catch(err => {
                console.error(err)
            })
        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('setReserve', '')
        // });
    },
    methods: {
        reservationCancel(){
            if (confirm('예약을 취소하시겠습니까?')) {
                const reserveInfo = this.$store.state.reserveStore.reserve
                const param = {
                    sessuserid : '90000300',
                    flag : 'D', // 접수: I, 취소: D
                    // pid : this.myInfo.memPatId,
                    orddd : reserveInfo.otpt_orddd,
                    ordtm : reserveInfo.otpt_ordtm,
                    orddeptcd : reserveInfo.otpt_orddeptcd,
                    orddrid : reserveInfo.otpt_orddrid,
                    cretno : reserveInfo.otpt_cretno, // 일련번호
                    // insukind : '',
                    // suppkind : '',
                    // rsrvflag : '6',
                    // etcordflag : 'R6'
                }

                this.axios({
                    method: 'post',
                    url: this.api.appointment.reservation,
                    params: param
                }).then(res => {
                    if(res.data.errormsg){
                        alert(res.data.errormsg)
                        if(res.data.errortype == 'currentMember_null') location.reload(true)
                        return
                    }
                    if(res.data.code){
                        alert(res.data.code)
                        return
                    }
                    this.$router.push('/')
                }).catch(err => {
                    alert('오류가 발생했습니다.')
                    location.reload(true);
                })
            }
        },
        changeReservation() {
			if (confirm('예약 변경 시 기존 예약은 취소됩니다. 진행하시겠습니까?')) {
                this.$store.commit('setChange', this.reserv)
				this.$router.push({ path: '/reserve/reservation.do'});
			}
		},
    },
    beforeUnmount() {
        this.$store.commit('setReserve', '')
    },
}
</script>