<template>
  <div class="jobHealthCenter contents job_health_check">
    
    <div class="btn-wrap right"><a href="https://www.nhis.or.kr/nhis/healthin/retrieveWrittenStatementPerson.do" class="btn blue" title="새 창 이동" target="_blank">문진표 작성</a></div>
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>국민건강보험 지역가입자, 피부양자, 직장가입자, 의료급여수급권자가 검진주기 대상년도에 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
          <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">검사대상</h4><!-- depth02 -->
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet03"></span></div>
                    <div class="depth__text">
                        <p>지역 및 피부양자 : 만 20세 이상 (2년 1회).</p>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet03"></span></div>
                    <div class="depth__text">
                        <p>의료급여수급권자 : 만 19세 ~ 64세 (2년 1회), 만 66세 이상 의료급여생애전환기검진 실시 (2년 1회).</p>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet03"></span></div>
                    <div class="depth__text">
                        <p>직장가입자 : 사무직 2년 1회, 비사무직 1년 1회.</p>
                    </div>
                </div>
                <div class="depth__wrap info-reference">
                    <div class="depth__bullet">
                        <p class="small-text">※</p>
                    </div>
                    <div class="depth__text">
                        <p class="small-text">사무직에 종사하는 근로자란 공장 또는 공사현장과 같은 구역에 있지 않은 사무실에서 서무·인사·경리·판매·설계 등 사무업무에 종사하는 근로자를 말하며, 판매업무 등에 직접 종사하는 근로자는 제외한다.
                        </p>
                    </div>
                </div>
            </div>
    </div>
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">검사항목</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">활동내용 폼</caption>
                <colgroup>
                    <col style="width:10%;">
                    <col style="width:20%;">
                    <col style="width:25%;">
                    <col style="width:25%;">
                    <col style="width:20%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>대상질환</th>
                        <th>검사항목</th>
                        <th>대상질환</th>
                        <th>검사항목</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="5">공통</td>
                        <td style="border-left:1px solid #d9d9d9">비만</td>
                        <td>키,몸무게,허리둘레,체질량지수</td>
                        <td>당뇨병</td>
                        <td>공복혈당</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">시각/청각이상</td>
                        <td>시력,청력</td>
                        <td>간장질환</td>
                        <td>AST,ALT,감마지티피</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">고혈압</td>
                        <td>혈압</td>
                        <td>폐결핵/흉부질환</td>
                        <td>흉부방사선촬영</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">신장질환</td>
                        <td>요단백,혈청크레아티닌,e-GFR</td>
                        <td>구강질환</td>
                        <td>구강검진</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">빈혈</td>
                        <td>혈색소</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">활동내용 폼</caption>
                <colgroup>
                    <col style="width:15%;">
                    <col style="width:20%;">
                    <col style="width:30%;">
                    <col style="width:35%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>대상질환</th>
                        <th>검사항목</th>
                        <th>대상질환</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="8">성별<br>연령별<br>검사항목</td>
                        <td style="border-left:1px solid #d9d9d9">총콜레스테롤<br>HDL-콜레스테롤<br>LDL-콜레스테롤<br>트리글리세라이드</td>
                        <td>남자: 만 24세, 여자: 만 40세 이상(4년 주기)</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">B형간염 항원,항체</td>
                        <td>만 40세</td>
                        <td>보균자 및 면역자는 제외</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">골밀도검사(골다공증)</td>
                        <td>만 54, 66세 여성</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">인지기능검사</td>
                        <td>만 66세 이상(2년 주기)</td>
                        <td>만 66세,68세,70세,72세......</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9">정신건강검사(우울증)</td>
                        <td>만 20, 30, 40, 50, 60,70세</td>
                        <td></td>
                    </tr>
                      <tr>
                        <td style="border-left:1px solid #d9d9d9">생활습관평가</td>
                        <td>만 40, 50, 60, 70세</td>
                        <td></td>
                    </tr>
                      <tr>
                        <td style="border-left:1px solid #d9d9d9">노인신체기능검사</td>
                        <td>만 66, 70, 80세</td>
                        <td></td>
                    </tr>
                      <tr>
                        <td style="border-left:1px solid #d9d9d9">구강검진</td>
                        <td>치균세균막 검사 (만 40세)</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:30</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">소요시간 : 1시간 이내</h4><!-- depth02 -->
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">당일 수검자 현황에 따라 시간이 달라질 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">유의사항</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>검진 전 8시간 이상 금식하시기 바랍니다. (껌, 커피, 우유 포함 일체 음식물)</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소변검사가 있으니 검사 전 되도록 소변을 참아 주시기 바랍니다.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증 및 국민건강보험공단에서 발송한 검진 안내서.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소변검사가 있으니 검사 전 되도록 소변을 참아 주시기 바랍니다.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">결과통보</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 15일 이내</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 모바일(카카오톡), 우편, 내원수령</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>