<template>
  <div class="jobHealthCenter contents job_health_check">
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">운전면허 적성검사</h4>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4>
          </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">준비물</caption>
                <colgroup>
                    <col style="width:30%;">
                    <col style="width:70%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검사구분</th>
                        <th>준비물</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="2">신규발급</td>
                        <td>신분증</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9;">6개월이내 촬영한 증명사진(3*4) 2매</td>
                    </tr>
                    <tr>
                        <td rowspan="2">갱신</td>
                        <td>신분증</td>
                    </tr>
                    <tr>
                        <td style="border-left:1px solid #d9d9d9;">6개월이내 촬영한 증명사진(3*4) 1매</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:30</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
        </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">소요시간 : 20분 내외</h4>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">당일 수검자 현황에 따라 시간이 달라질 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
    
   
   
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">결과통보</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>즉시수령</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>