<template>
  <div class="jobHealthCenter contents">
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              직장 일로 인해 건강상태가 악화될 염려도 없고, 또한 자신의 질병이 남에게 전염될 가능성이 없는데도 막연한 판단으로 취업기회를 놓치거나 오랫동안 몸담아온 직장생활을 포기해야 하는 경우가 우리 주변에서 드물지 않게 벌어지고 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              똑같은 질병이라도 질병의 정도나 건강상태, 수행할 업무내용에 따라 어느 경우에는 특별한 조치 없이, 어느 경우에는 의학적 관리를 받으면서 업무 수행을 하면 되고, 아주 드물게는 한시적으로 업무를 전환하거나 제한하기도 합니다. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              저희 직업환경의학과에서는 이러한 질병들에 대해서 현재 건강상태 및 향후 업무로 인해 악화될 가능성 그리고 동료에 대한 전염성에 대해서 의학적인 전문지식을 근거로 합리적인 판단을 제공합니다. 아울러 회사 내에서 특별히 제한되어야 하는 업무 및 조치에 대해서도 의학적인 판단을 제공합니다.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">업무적합성 평가 소견 예시</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">업무적합성 평가 소견 예시</caption>
                <colgroup>
                    <col style="width:30%;">
                    <col style="width:70%;">
                </colgroup>
                <tbody>
                    <tr>
                        <td>업무수행<br>적합여부</td>
                        <td style="text-align:left;">조건부로 업무 가능함</td>
                    </tr>
                    <tr>
                        <td>조치사항</td>
                        <td style="text-align:left;">주치의의 의학적 관리에 따른 상기 상병의 지속적인 약물치료<br>금연, 금주, 규칙적인 운동 등 좋은 생활습관 유지<br>충분한 업무적응시간 보장<br>계속해서 업무수행이 어려운 경우에는 직업전환이나 재평가 고려
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              위 소견과 같이 평가되었음을 증명합니다.
            </p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="depth02__title title contents">진료예약 055)270-1800</h4>
  </div>
</template>
<script>
export default {};
</script>
