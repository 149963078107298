<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="my_health_wrap my_result_wrap contents">
        <div class="contents health_info_wrap">
            <div class="tab-menu__wrap">
                <div class="tab-menu type02">
                    <ul class="tab-menu__list center">
                        <template v-for="(menu, idx) in menuList" :key="idx">
                            <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '580'">
                                <a :href="menu.menuUrl" :target="menu.linkMethod" v-if="menu.menuUrl.indexOf('http') > -1">
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </a>
                                <router-link :to="menu.menuUrl" v-else>
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="contents result_box04" >
                <div class="text-box type1 info-text">
                    <ul class="text-box__list">
                        <li class="text-box__item">기본 진단검사에 한해서만 결과를 제공하며 검사 3일 후에 조회 됩니다..</li>
                    </ul>
                </div>
                <div class="date_wrap contents">
                    <div class="board__select">
                        <select name="" id="Sel_Inst" class="select_department" ref="tclss" @change="changeT">
                            <option value="">전체</option>
                            <option :value="i" v-for="i in tclssList" :key="i">{{i}}</option>
                        </select>
                    </div>
                    <div class="date-input">
                        <VueDatePicker v-model="selectedDateRange" locale="ko"
                                       :enable-time-picker="false" :format="customFormat"
                                       :week-start="0" :day-class="getDayClass"
                                       :select-text="'선택'" :cancel-text="'취소'"
                                       :range="true" :multi-calendars="true"/>
                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                    </div>

                    <div class="tab-menu type_date">
                        <ul class="tab-menu__list">
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                            </li>
                        </ul>
                    </div>

                    <div class="health_info_search">
                        <button @click="getCheckList">검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
                    </div>
                </div>

                <div class="contents"  v-if="checkDateList.constructor == Object && Object.keys(checkDateList).length == 0">
                    <h3 class="deps-tit">{{ idx }}</h3>
                    <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                        <div class="scroll">
                            <table>
                                <caption class="blind">결과조회-외래진료 폼</caption>
                                <colgroup>
                                    <col style="width:23%;">
                                    <col style="width:24%;">
                                    <col style="width:14%;">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>검사명</th>
                                    <th>결과</th>
                                    <th>참고치</th>
                                </tr>
                                </thead>
                                <tbody class="no-data-table">
                                    <tr>
                                        <td colspan="5">검색된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="contents" v-for="(checkList, idx) in checkDateList" :key="idx">
                        <h3 class="deps-tit">{{ idx }}</h3>
                        <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                            <div class="scroll">
                                <table>
                                    <caption class="blind">결과조회-외래진료 폼</caption>
                                    <colgroup>
                                        <col style="width:23%;">
                                        <col style="width:24%;">
                                        <col style="width:14%;">
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>검사명</th>
                                        <th>결과</th>
                                        <th>참고치</th>
                                    </tr>
                                    </thead>
                                    <tbody class="no-data-table" v-if="$commonUtils.isEmpty(checkList)">
                                        <tr>
                                            <td colspan="5">검색된 내용이 없습니다.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(check, idx) in checkList" :key="idx">
                                            <td>{{check.tclsscrnnm}}</td>
                                            <td>{{ check.reptrslt }}</td>
                                            <td>{{ check.ref }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css'
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    data() {
        return {
            searchParam: {},
            selectedDateRange: [new Date(), new Date()],
            selectedPeriod: 3,
            customFormat: 'yyyy.MM.dd',
            checkDateList: {},
            popupVisible: false,

            tclssList: [],
            orgList: [],
        }
    },
    components: {
        VueDatePicker,
        NameCertifyPopUp
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.setSelectedPeriod(3)
        this.getCheckList()
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        setSelectedPeriod(months) {
            const startDate = new Date()
            const endDate = new Date()
            startDate.setMonth(endDate.getMonth() - months)
            this.selectedDateRange = [startDate, endDate]
            this.selectedPeriod = months
        },
        getDayClass(date) {
            const day = date.getDay()
            if (day === 0) {
                return 'sunday-class'
            } else if (day === 6) {
                return 'saturday-class'
            }
            return ''
        },
        addClass(event) {
            event.currentTarget.classList.add('on')
        },
        getCheckList() {
            this.searchParam.fromdd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[0], 'yyyyMMdd', '')
            this.searchParam.todd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[1], 'yyyyMMdd', '')

            this.axios.get(this.api.mypage.checkList, {
                params: this.searchParam
            }).then(res => {
                const infoList = res.data.result
                this.checkDateList = {}
                let checkList = []
                if(Array.isArray(infoList)){
                    checkList = infoList
                } else {
                    checkList = [infoList]
                    return
                }

                let prcpdd = this.$commonUtils.makeDateFormat(checkList[0].prcpdd,'.')

                //날짜별 데이터 입력 key가 prcpdd
                for(const check of checkList){
                    check.prcpdd = this.$commonUtils.makeDateFormat(check.prcpdd,'.')
                    if(prcpdd != check.prcpdd){
                        prcpdd = check.prcpdd
                    }

                    //날짜별로 데이터를 담을 배열 생성
                    if(!this.checkDateList[prcpdd]){
                        this.checkDateList[prcpdd] = []
                    }

                    this.checkDateList[prcpdd].push(check)

                }

                //checkDateList key값을 날짜로 내림차순 정렬
                this.checkDateList = Object.keys(this.checkDateList).sort().reverse().reduce((obj, key) => {
                    obj[key] = this.checkDateList[key]
                    return obj
                }, {})
                
                this.orgList = this.checkDateList

                const keys = Object.keys(this.checkDateList)
                this.tclssList = []
                this.$refs.tclss.value = ''
                for(let i of keys){
                    for(let j of this.checkDateList[i]){
                        this.tclssList.push(j.tclsscrnnm)
                    }
                }
                this.tclssList = this.$commonUtils.dupArr(this.tclssList)
            })
        },
        changeT(){
            const t = this.$refs.tclss.value
            this.checkDateList = Object.entries(this.orgList)
                .reduce((acc, [date, items]) => {
                    const filteredItems = items.filter(item => t ? item.tclsscrnnm === t : true);
                    
                    if (filteredItems.length > 0) {
                        acc[date] = filteredItems;
                    }
                    
                    return acc;
                }, {});
        }
    }
}

</script>
