<template>
    <div class="contents ideal-talent">
        <div class="ideal-talent__inner">
            <div class="pc-text">
                <div class="text">
                    <h2 class="tit"><span class="icon"></span> <em>Listen</em></h2>
                    <span class="sub-tit">고객중심</span>
                    <p class="desc">경청과 공감으로 고객의 마음을 읽어내고 그에 따라 최고의 서비스를 제공하는 사람</p>
                </div>
            </div>
            <div class="slide">
                <ul class="slide__list">
                    <li class="slide__item">
                        <div class="img">
                            <img src="@/assets/resource/img/ideal-talent1.png" alt="이미지">
                        </div>
                        <div class="text">
                            <h2 class="tit"><span class="icon"></span> <em>Listen</em></h2>
                            <span class="sub-tit">고객중심</span>
                            <p class="desc">경청과 공감으로 고객의 마음을 읽어내고 그에 따라 최고의 서비스를 제공하는 사람</p>
                        </div>
                    </li>
                    <li class="slide__item">
                        <div class="img">
                            <img src="@/assets/resource/img/ideal-talent2.png" alt="이미지">
                        </div>
                        <div class="text">
                            <h2 class="tit"><span class="icon"></span> <em>Obedience</em></h2>
                            <span class="sub-tit">봉사헌신</span>
                            <p class="desc">생명의 근원인 하느님과 치유자 예수 그리스도의 사랑을 실천하는 사람</p>
                        </div>
                    </li>
                    <li class="slide__item">
                        <div class="img">
                            <img src="@/assets/resource/img/ideal-talent3.png" alt="이미지">
                        </div>
                        <div class="text">
                            <h2 class="tit"><span class="icon"></span> <em>Virtue</em></h2>
                            <span class="sub-tit">상호협력</span>
                            <p class="desc">더불어 살아가는 미덕과 공동의 선을 추구하는 인성(德)을 지닌 사람</p>
                        </div>
                    </li>
                    <li class="slide__item">
                        <div class="img">
                            <img src="@/assets/resource/img/ideal-talent4.png" alt="이미지">
                        </div>
                        <div class="text">
                            <h2 class="tit"><span class="icon"></span> <em>Excellence</em></h2>
                            <span class="sub-tit">전문성</span>
                            <p class="desc">전문성과 탁월함으로 최상의 성과를 이끌어내는 역량있는 사람</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    // import { gsap, ScrollTrigger } from "gsap/all";

    export default {
        data() {
            return {

            };
        },
        mounted() {
            function slideFn() {

                let slideData = [
                    { tit: 'Listen', text: '경청과 공감으로 고객의 마음을 읽어내고 그에 따라 최고의 서비스를 제공하는 사람', subTitle: '고객중심' },
                    { tit: 'Obedience', text: '생명의 근원인 하느님과 치유자 예수 그리스도의 사랑을 실천하는 사람', subTitle: '봉사헌신' },
                    { tit: 'Virtue', text: '더불어 살아가는 미덕과 공동의 선을 추구하는 인성(德)을 지닌 사람', subTitle: '상호협력' },
                    { tit: 'Excellence', text: '전문성과 탁월함으로 최상의 성과를 이끌어내는 역량있는 사람', subTitle: '전문성' }
                ];

                let slideNum = 0;
                const subTitleElement = document.querySelector('.ideal-talent .pc-text .sub-tit');
                const descElement = document.querySelector('.ideal-talent .pc-text .desc');
                const titleElement = document.querySelector('.ideal-talent .pc-text .tit em');

                function updateText(index) {
                    titleElement.textContent = slideData[index].tit;
                    subTitleElement.textContent = slideData[index].subTitle;
                    descElement.textContent = slideData[index].text;
                }

                updateText(slideNum);

                let boxItems = document.querySelectorAll(".ideal-talent .slide__item");
                let heightBox = Array.from(boxItems).reduce((totalHeight, el) => totalHeight + el.offsetHeight, 0);

                boxItems.forEach((item, index) => {
                    gsap.to(item, {
                        scrollTrigger: {
                            trigger: item,
                            start: "top center",
                            end: "40% center",
                            scrub: 4,
                            onEnter: () => {
                                slideNum = index;
                                updateText(slideNum);
                            },
                            onEnterBack: () => {
                                slideNum = index;
                                updateText(slideNum);
                            },
                        },
                    });
                });

                gsap.to(".ideal-talent__inner", {
                    scrollTrigger: {
                        trigger: ".ideal-talent",
                        start: "-120px top",
                        end: heightBox + 'px',
                        scrub: 2,
                        pin: true,
                        pinSpacing: true,
                    }
                });

                gsap.to('.ideal-talent .slide__item', {
                    scrollTrigger: {
                        trigger: ".ideal-talent",
                        start: "-120px top",
                        end: "+=" + (heightBox + 300),
                        scrub: 2,
                    },
                    y: -heightBox,
                });
            }
            if (window.matchMedia('(min-width: 1025px)').matches) {
                setTimeout(() => {
                    slideFn();
                }, 500);
            }
        }

    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>