<template>
    <!-- 기억장애치매클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 신경과에서는 기억장애/치매클리닉에서는 뇌질환으로 인한 환자의 인지기능, 행동(이상행동)에 대한 신경심리학적 평가 및 치료를 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">기억장애·치매 클리닉의 장점</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>2002년 도내 최초로 신경심리검사실 개설</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>2018년 현재 도내 유일하게 최대규모인 3개 검사실 운영</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>뇌졸중 및 파킨슨 전문의와의 원활한 협진으로 혈관성치매, 파킨슨병을 동반한 치매 환자에게 효과적인 진료 가능</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>전국적인 치매 임상연구에 적극적인 참여로 최신 지견을 반영한 치료</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">창원파티마병원 기억장애 · 치매 클리닉을 방문하시면</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치매인지, 치매 전 단계(경도인지장애)인지, 정상(건망증)인지 알 수 있습니다.</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치매의 경중도를 알 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치매의 원인질환을 알 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">기억장애·치매 클리닉 방문이 필요한 경우</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>기억력의 감퇴를 느끼는 경우</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>계산능력, 방향감각, 언어능력의 감퇴를 느끼는 경우</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>뇌졸중(뇌경색, 뇌출혈 등)의 과거력이 있으며, 인지기능의 감퇴를 느끼는 경우</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>평소와 다른 행동 및 성격변화가 나타난 경우</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>조기에 치매 예방을 하고 싶은 경우</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치매 가족력이 있는 경우</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>급격히 진행하는 치매증상이 있는 경우</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <img src="/resource/img/center06.jpg" alt="치매관련이미지">
                                <img src="/resource/img/center07.jpg" alt="치매관련이미지">
                                <img src="/resource/img/center08.jpg" alt="치매관련이미지">
                                <p style="color:#888; margin-top:5px;">이미지3개 출처 | www.neuro.or.kr 대한신경과학회</p>
                            </div>
                        </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">진단과 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>문진, 신체 및 신경학적 진찰</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>인지기능검사 : 한국형-간이정신상태검사, SNSB-Ⅱ 신경심리검사</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>신경영상 검사 : 뇌컴퓨터단층촬영(CT). 뇌자기공명영상(Brain MRI), 아밀로이드-PET</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치료 : 약물치료 및 그룹/재가 인지훈련</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">진료안내</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="color:#e8508d;"><b>* 기억장애·치매클리닉 초진 환자 우선진료</b></p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>기억장애·치매클리닉을 처음 방문하시는 환자분들을 우선진료하고 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>월, 화, 목, 금 08:50 / 08:54 / 08:59 예약가능 전화예약) 270-1000</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p><b>※ 영상CD 및 판독지, 진료기록, 투약자료 등을 지참하시면 도움이 됩니다.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>


<script>
export default {
   
};
</script>