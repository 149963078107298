<template>
   <div class="tab-menu__wrap">
      <div class="tab-menu type02">
          <ul class="tab-menu__list">
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_reserve.do`" type="button" class="tab-menu__btn">예약</router-link>
              </li>
              <li class="tab-menu__item">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_Check.do`" type="button" class="tab-menu__btn">검사</router-link>
              </li>
              <li class="tab-menu__item on">
                  <router-link :to="`/deptHp/${deptCd}/${hpUrl}/CheckUpFaq_etc.do`" type="button" class="tab-menu__btn">기타</router-link>
              </li>
          </ul>
      </div>
  </div>
  <div class="board-list">
    <div class="contents faq">
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="faq-title" :class="{ 'active': activeIndex === index }" @click.stop="toggle(index)">
          <div class="faq-title__text">
            <span class="icon">Q </span>
            <em>{{ faq.question }}</em>
          </div>
          <div class="faq-title__btn"></div>
        </div>
        <slide-up-down :active="activeIndex === index">
          <div class="faq-content">
            <span>A</span>
            <p v-html="faq.answer"></p>
          </div>
        </slide-up-down>
        <div class="faq-title__btn--mo" :class="{ 'active': activeIndex === index }"  @click.stop="toggle(index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  components: {
    SlideUpDown,
  },
  data() {
    return {
      activeIndex: null,
      deptCd: this.$route.params.deptCd,
      hpUrl: this.$route.params.hpUrl,
      faqs: [
        {
          question: "건강검진 당일 주차장 이용이 가능한가요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p>건강검진 당일 주차는 1대에 한하여 무료입니다.</p>
                      </div>
                    </div>`
        },
        {
          question: "대변을 받지 못했습니다. 어떻게 해야 하나요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p>결과상담 내원 또는 병원방문(평 일 8:00~17:00/ 토요일 8:00~12:00) 시 건강증진센터로 제출해 주시면 됩니다.</p>
                      </div>
                    </div>`
        },
        {
          question: "대장검사 관장약을 다 먹지 못했거나, 먹다가 토했어요 검사가 가능할까요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p>구토 양에 따라서 검사가능 여부가 달라집니다. 그 양이 소량인 경우에는 검진 당일 장 정결 여부를 확인한 후 검사 여부를 결정하며, 그 양이 다량인 경우에는 내시경 검사일정을 연기해 드립니다.</p>
                      </div>
                    </div>`
        },
        {
          question: "임신 또는 수유 시에 건강검진을 할 수 있나요?",
          answer: `<div class="depth__wrap depth04">
                      <div class="depth__text">
                          <p>임신부는 정확한 검사를 위해 출산 후에 건강검진을 받으시길 권합니다. 모유수유 중인 경우에는 유방검사가 불가합니다.</p>
                      </div>
                    </div>`
        }
      ]
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    handleClickOutside(event) {
      const isClickInside = this.$el.contains(event.target);
      if (!isClickInside) {
        this.activeIndex = null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
