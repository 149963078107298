<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn on">진료과 예약</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">의료진 예약</button></li>
        </ul>
    </div>
    <div class="contents">
        <div class="reservation input-form type1">
            <ul class="reservation__left">
                <li class="reservation__step active">
                    <a href="javascript:;" class="reservation__header">
                        <h3 class="reservation__header--title">STEP01</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">진료과를 선택해주세요</h3>
                    </a>
                    <div class="reservation__con active">
                        <div class="reservation__consonant">
                            <ul class="initial-search__list">
                                <li><a href="javascript:;" class="all active">ALL</a></li>
                                <li><a href="javascript:;">ㄱ</a></li>
                                <li><a href="javascript:;">ㄴ</a></li>
                                <li><a href="javascript:;">ㄷ</a></li>
                                <li><a href="javascript:;">ㄹ</a></li>
                                <li><a href="javascript:;">ㅁ</a></li>
                                <li><a href="javascript:;">ㅂ</a></li>
                                <li><a href="javascript:;">ㅅ</a></li>
                                <li><a href="javascript:;">ㅇ</a></li>
                                <li><a href="javascript:;">ㅈ</a></li>
                                <li><a href="javascript:;">ㅊ</a></li>
                                <li><a href="javascript:;" class="none">ㅋ</a></li>
                                <li><a href="javascript:;" class="none">ㅌ</a></li>
                                <li><a href="javascript:;">ㅍ</a></li>
                                <li><a href="javascript:;">ㅎ</a></li>
                            </ul>
                        </div>
                        <div class="reservation__department-list">
                            <ul>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                                <li><a href="javascript:;">진료과 예시</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="reservation__step">
                    <a href="javascript:;" class="reservation__header">
                        <h3 class="reservation__header--title">STEP02</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">의료진을 선택해주세요</h3>
                    </a>
                    <div class="reservation__con">
                        <ul class="reservation__input">
                            <li class="input-form__item">
                                <div class="input-form__box">
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" name="radio1" checked>
                                                <span class="icon"></span>
                                                기본순
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" name="radio1">
                                                <span class="icon"></span>
                                                예약빠른순
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="reservation__doctor-list">
                            <ul>
                                <li class="reservation__doctor">
                                    <a href="javascript:;" class="reservation__doctor--burron">선택</a>
                                    <div class="reservation__doctor--img">
                                        <img src="@/assets/resource/img/reservation__doctor--img.png" alt="이미지">
                                    </div>
                                    <div class="reservation__doctor--con">
                                        <div class="reservation__doctor--title">
                                            <h4>신재욱</h4>
                                            <a href="javascript:;">의료진소개</a>
                                        </div>
                                        <ul class="reservation__doctor--text">
                                            <li>
                                                <span>주진료분야</span>
                                                <p>위, 식도, 대장, 간, 췌담도</p>
                                            </li>
                                            <li>
                                                <span>빠른진료</span>
                                                <p>2024.08.03. (수) 09:55</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="reservation__doctor">
                                    <a href="javascript:;" class="reservation__doctor--burron">선택</a>
                                    <div class="reservation__doctor--img">
                                        <img src="@/assets/resource/img/reservation__doctor--img.png" alt="이미지">
                                    </div>
                                    <div class="reservation__doctor--con">
                                        <div class="reservation__doctor--title">
                                            <h4>신재욱</h4>
                                            <a href="javascript:;">의료진소개</a>
                                        </div>
                                        <ul class="reservation__doctor--text">
                                            <li>
                                                <span>주진료분야</span>
                                                <p>위, 식도, 대장, 간, 췌담도</p>
                                            </li>
                                            <li>
                                                <span>빠른진료</span>
                                                <p>2024.08.03. (수) 09:55</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="reservation__doctor">
                                    <a href="javascript:;" class="reservation__doctor--burron">선택</a>
                                    <div class="reservation__doctor--img">
                                        <img src="@/assets/resource/img/reservation__doctor--img.png" alt="이미지">
                                    </div>
                                    <div class="reservation__doctor--con">
                                        <div class="reservation__doctor--title">
                                            <h4>신재욱</h4>
                                            <a href="javascript:;">의료진소개</a>
                                        </div>
                                        <ul class="reservation__doctor--text">
                                            <li>
                                                <span>주진료분야</span>
                                                <p>위, 식도, 대장, 간, 췌담도</p>
                                            </li>
                                            <li>
                                                <span>빠른진료</span>
                                                <p>2024.08.03. (수) 09:55</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="reservation__step">
                    <a href="javascript:;" class="reservation__header">
                        <h3 class="reservation__header--title">STEP03</h3>
                        <span class="reservation__header--arrow"></span>
                        <h3 class="reservation__header--title">예약일시를 선택해주세요</h3>
                    </a>
                    <div class="reservation__con">
                        <div class="reservation__day-wrap">
                            <sub>
                                * 해당 진료일정은 <span>구본원 의료진</span>의 소속된 <span>전체(과/클리닉) 진료 일정</span>입니다.
                            </sub>
                            <div class="reservation__day">
                                <div class="reservation__day--header">
                                    <p>현재 가장 빠른 진료예약 일자는 <br class="pc-none"><span>2022-08-03(수) 09:55</span> 입니다.</p>
                                </div>
                                <div class="reservation__day--con">
                                    <div class="reservation__calendar">
                                        <div class="calendar">
                                            <div class="calendar__header">
                                                <button class="calendar__header--button prev"></button>
                                                <h4 class="calendar__header--title">2022.08</h4>
                                                <button class="calendar__header--button next"></button>
                                            </div>
                                            <div class="calendar__hcon">
                                                <table>
                                                    <caption>
                                                        예약일 선택 테이블
                                                    </caption>
                                                    <colgroup>
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                        <col width="14.29%">
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>일</th>
                                                            <th>월</th>
                                                            <th>화</th>
                                                            <th>수</th>
                                                            <th>목</th>
                                                            <th>금</th>
                                                            <th>토</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td class="today"><a href="javascript:;">1</a></td>
                                                            <td><a href="javascript:;">2</a></td>
                                                            <td class="pos active"><a href="javascript:;">3</a></td>
                                                            <td><a href="javascript:;">4</a></td>
                                                            <td class="pos"><a href="javascript:;">5</a></td>
                                                            <td><a href="javascript:;">6</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="javascript:;">7</a></td>
                                                            <td><a href="javascript:;">8</a></td>
                                                            <td class="pos"><a href="javascript:;">9</a></td>
                                                            <td><a href="javascript:;">10</a></td>
                                                            <td><a href="javascript:;">11</a></td>
                                                            <td class="pos"><a href="javascript:;">12</a></td>
                                                            <td><a href="javascript:;">13</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="javascript:;">14</a></td>
                                                            <td><a href="javascript:;">15</a></td>
                                                            <td class="pos"><a href="javascript:;">16</a></td>
                                                            <td><a href="javascript:;">17</a></td>
                                                            <td><a href="javascript:;">18</a></td>
                                                            <td class="pos"><a href="javascript:;">19</a></td>
                                                            <td><a href="javascript:;">20</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="javascript:;">21</a></td>
                                                            <td><a href="javascript:;">22</a></td>
                                                            <td class="pos"><a href="javascript:;">23</a></td>
                                                            <td><a href="javascript:;">24</a></td>
                                                            <td><a href="javascript:;">25</a></td>
                                                            <td class="pos"><a href="javascript:;">26</a></td>
                                                            <td><a href="javascript:;">27</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="javascript:;">28</a></td>
                                                            <td><a href="javascript:;">29</a></td>
                                                            <td class="pos"><a href="javascript:;">30</a></td>
                                                            <td><a href="javascript:;">31</a></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="calendar__bottom">
                                                <p>
                                                    <span class="today"></span>
                                                    오늘
                                                </p>
                                                <p>
                                                    <span class="pos"></span>
                                                    예약가능
                                                </p>
                                                <p>
                                                    <span class="active"></span>
                                                    선택
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="reservation__time">
                                        <ul>
                                            <li><a href="javascript:;" class="active">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                            <li><a href="javascript:;">00:00</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="reservation__right">
                <ul class="reservation__step-list">
                    <li class="active">
                        <a href="javascript:;">
                            <span>01</span>
                            <h4>진료과 선택</h4>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <span>02</span>
                            <h4>의료진 선택</h4>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <span>03</span>
                            <h4>예약일/시간 선택</h4>
                        </a>
                    </li>
                </ul>
                <div class="reservation__patient">
                    <h4 class="reservation__patient--title">환자정보</h4>
                    <ul class="reservation__patient--text">
                        <li>
                            <span>성함</span> 이준용 (환자번호:1234567)
                        </li>
                        <li>
                            <span>전화</span> 010-1234-5678
                        </li>
                        <li class="mail">
                            <span>메일</span> haha@naver.com
                        </li>
                    </ul>
                    <button class="reservation__patient--button">개인정보 수정</button>
                </div>
                <a href="javascript:;" class="reservation__right--button">예약하기</a>
            </div>
        </div>
    </div>
</template>


<script>
  export default {

}
</script>