import store from "./store";
const memberStore = {
    state: {
        // 회원가입
        memId: '',
        memName: '',
        memEmail: '',
        memHpNo: '',
        memBirth: '',
        memGender: '',
        memType: '',
        marketingCheck: '',
        memSnsType: '',
        memCi: '',
        memDi: '',
        parent: '',
        finish: '',

        // 로그인 여부
        status: '',

        // 아이디, 비밀번호 찾기
        find: '',

        // 로그인 필요 페이지 회원 정보
        info: '',

        // 실명인증
        niceType: '',
        ipinType: '',
        findMemId: '',
        memJoinRoute: '',

        nice: '',
        niceIpin: '',

        // 마이페이지 모달 여부
        showMypageNameCheckModal: true       //마이페이지에서 실명확인 팝업 노출 여부 처리, 최최 1회만 노출
    },
    mutations: {
        // 회원가입
        setMemId: function (state, memId) {
            state.memId = memId;
        },
        setMemName: function (state, memName) {
            state.memName = memName;
        },
        setMemEmail: function (state, memEmail) {
            state.memEmail = memEmail;
        },
        setMemHpNo: function (state, memHpNo) {
            state.memHpNo = memHpNo;
        },
        setMemBirth: function (state, memBirth) {
            state.memBirth = memBirth;
        },
        setMemGender: function (state, memGender) {
            state.memGender = memGender;
        },
        setMemType: function (state, memType) {
            state.memType = memType;
        },
        setMarketingCheck: function (state, marketingCheck) {
            state.marketingCheck = marketingCheck;
        },
        setMemSnsType: function (state, memSnsType) {
            state.memSnsType = memSnsType;
        },
        setMemCi: function (state, memCi) {
            state.memCi = memCi;
        },
        setMemDi: function (state, memDi) {
            state.memDi = memDi;
        },
        setParent: function (state, parent) {
            state.parent = parent;
        },
        setFinish: function (state, finish) {
            state.finish = finish;
        },

        // 로그인 여부
        setStatus: function (state, status) {
            state.status = status;
        },

        // 아이디, 비밀번호 찾기
        setFind: function (state, find) {
            state.find = find;
        },

        setInfo: function (state, info) {
            state.info = info;
        },

        // 실명인증
        getNiceType: function (state, niceType) {
            state.niceType = niceType;
        },
        getIpinType: function (state, ipinType) {
            state.ipinType = ipinType;
        },
        getFindMemId: function (state, findMemId) {
            state.findMemId = findMemId;
        },
        getMemJoinRoute: function (state, memJoinRoute) {
            state.memJoinRoute = memJoinRoute;
        },

        setNice: function (state, nice) {
            state.nice = nice;
        },
        setNiceIpin: function (state, niceIpin) {
            state.niceIpin = niceIpin;
        },

        // 마이페이지 모달 여부
        getShowMypageNameCheckModal: function (state, status) {
            state.showMypageNameCheckModal = status;
        },

        // 초기화
        init: function(state){
            for(let i in state) {
                if(i != 'status' || i != 'showMypageNameCheckModal') state[i] = ''
            }
        }
    },
    getters: {
        getStatus: state => {
            return state.status
        },
        getMypageModal: state => {
            return state.showMypageNameCheckModal
        }
    },
    actions: {
        setLoginStatus({commit}, status){
            if(store.state.memberStore.status){
                commit('setStatus', status);
                setTimeout(() => {
                    commit('setStatus', false);
                }, 1790000);
            }
        }
    }
}

export default memberStore