<template>
    <div class="tab-menu__wrap" v-if="board.boardId == 'pd_center_faq_reserve' || board.boardId == 'pd_center_faq_checkup' || board.boardId == 'pd_center_faq_etc'">
      <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item" :class="$route.path.indexOf('pd_center_faq_reserve') > -1 ? 'on' : ''">
                    <router-link :to="`/deptHp/${$route.params.deptCd ? $route.params.deptCd : '1'}/${$route.params.hpUrl ? $route.params.hpUrl : 'healthcenter'}/pd_center_faq_reserve/list.do`" type="button" class="tab-menu__btn">예약</router-link>
                </li>
                <li class="tab-menu__item" :class="$route.path.indexOf('pd_center_faq_checkup') > -1 ? 'on' : ''">
                    <router-link :to="`/deptHp/${$route.params.deptCd ? $route.params.deptCd : '1'}/${$route.params.hpUrl ? $route.params.hpUrl : 'healthcenter'}/pd_center_faq_checkup/list.do`" type="button" class="tab-menu__btn">검사</router-link>
                </li>
                <li class="tab-menu__item" :class="$route.path.indexOf('pd_center_faq_etc') > -1 ? 'on' : ''">
                    <router-link :to="`/deptHp/${$route.params.deptCd ? $route.params.deptCd : '1'}/${$route.params.hpUrl ? $route.params.hpUrl : 'healthcenter'}/pd_center_faq_etc/list.do`" type="button" class="tab-menu__btn">기타</router-link>
                </li>
            </ul>
        </div>
    </div>

    <div class="board-list">
        <Search :searchArr="searchArr" @setState="setState" v-if="!(board.boardId == 'pd_center_faq_reserve' || board.boardId == 'pd_center_faq_checkup' || board.boardId == 'pd_center_faq_etc')"></Search>

        <div class="contents faq">
            <div v-for="(faq, index) in list" :key="index" class="faq-item" @click.stop="toggle(index)">
                <div class="faq-title" :class="{ 'active': activeIndex === index }" >
                    <div class="faq-title__text">
                        <span class="notice" v-if="faq.noticeYn == 'Y'">중요</span>
                        <span class="icon" v-else>Q </span>
                        <em>{{faq.category ? `[${faq.category}]` : ''}} <span>{{ faq.title }}</span></em>
                    </div>
                    <div class="faq-title__btn"></div>
                </div>
                <slide-up-down :active="activeIndex === index">
                    <div class="faq-content">
                        <span>A</span>
                        <p v-html="faq.content"></p>
                    </div>
                </slide-up-down>
                <div class="faq-title__btn--mo" :class="{ 'active': activeIndex === index }"></div>
            </div>
        </div>

        <div class="contents" v-if="!(board.boardId == 'pd_center_faq_reserve' || board.boardId == 'pd_center_faq_checkup' || board.boardId == 'pd_center_faq_etc')">
            <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
        </div>
    </div>
</template>

<script>
import Search from '../common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'
import SlideUpDown from 'vue-slide-up-down'

export default{
    components: {
        Search,
        Pagination,
        SlideUpDown
    },
    props: {
        boardInfo: ''
    },
    data() {
        return {
            board: {},
            list: '',
            pageList: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 10,
            //검색 관련 parameter
            title: this.$route.query.title || '',
            content: this.$route.query.content || '',
            searchKeyword: this.$route.query.searchKeyword || '',
            category: this.$route.query.category || '',

            // 검색박스 셀렉트 옵션 리스트
            searchArr: [
                {'title':'전체', 'value':'searchKeyword'}
                , {'title':'제목', 'value':'title'}
                , {'title':'내용', 'value':'content'}
            ],
            activeIndex: null
        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if(to.query != from.query && to.path == from.path){
                if(Object.keys(to.query).length === 0) this.init()
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }

                this.getList()
            }
        },
        // 다른 게시판으로 이동
        boardInfo(oldVal, newVal){
            this.init()
            this.getList()
        }
    },
    methods: {
        getList(){
            const boardNo = this.boardInfo.boardNo
            const params = {
                instNo: 1,
                boardNo: boardNo,
                startIndex: this.currentNum,
                pageRow: this.$route.path.indexOf('pd_center_faq') > -1 ? 999 : this.pageRow,
                title: this.title,
                content: this.content,
                searchKeyword: this.searchKeyword,
                category: this.category
            }
            this.axios.get(this.api.article.list + boardNo, {
                params: params
            })
            .then(res => {
                this.list = res.data.list
                this.board = res.data.board
                this.totCnt = res.data.totCnt

            })
        },
        // 검색 emit
        setState(s, v, p){
            this[p] = ''
            this.currentNum = 1
            this[s] = v
            this.goPage()
        },
        // pagination emit
        setNum(n){
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            this.activeIndex = null;

            const obj = {currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword};
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj});
        },
        // 데이터 초기화
        init(){
            this.currentNum = 1
            this.title = ''
            this.content = ''
            this.searchKeyword = ''
        },
        toggle(index) {
                this.activeIndex = this.activeIndex === index ? null : index;
      
        },
        handleClickOutside(event) {
                const isClickInside = this.$el.contains(event.target);
                if (!isClickInside) {
                    this.activeIndex = null;
                }
        }
    },
    mounted() {
        this.getList()
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
}
</script>

