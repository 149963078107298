<template>
    <!-- 비만대사수술클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 외과에서는 비만환자의 효과적인 체중감량과 건강관리를 돕는 비만대사수술클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">비만대사수술이란?</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>위장관 구조의 변화를 통해 체중감소를 유도하는 수술로 식사량과 식욕을 조절하는 것을 목적으로 하며 수술방법에는 위소매절제술, 루와이 위 우회술 등이 있습니다. 체질량지수가 높은 환자의 경우 관절이 약해진 경우가 많아 운동으로 체중감량이 어려우며 약물치료나 생활습관개선 등 내과적인 치료로 호전이 되지 않는 고도비만환자의 경우 수술만이 유일한 치료법입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">비만대사수술 대상 (건강보험 적용)</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>체질량지수 35이상 초고도비만 환자</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>체질량지수 30이상이면서 당뇨병, 고혈압 등 합병증을 동반한 환자</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>체질량지수 27.5이상에서 30미만인 제2형 당뇨병 환자</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">BMI 계산기</h4>
                        
                    </div>
                </div>
                <div class="depth__wrap">
                              <div class="bmi_wrap">
                                <ul class="bmi_calc_list">
                                    <li class="bmi_calc_item calc_item01">
                                        <img src="/resource/icon/gender_icon.png" alt="아이콘">
                                        <h3>성별</h3>
                                        <div class="agree-input">
                                        <label for="agree-man" class="agree-input__item">
                                            <input type="radio" id="agree-man" class="agree-input__radio" name="gender" value="man" v-model="gender">
                                            <span class="icon"></span>남자
                                        </label>
                                        <label for="agree-woman" class="agree-input__item">
                                            <input type="radio" id="agree-woman" class="agree-input__radio" name="gender" value="woman" v-model="gender">
                                            <span class="icon"></span>여자
                                        </label>
                                        </div>
                                    </li>
                                    <li class="bmi_calc_item calc_item02">
                                        <img src="/resource/icon/ruler_icon.png" alt="아이콘">
                                        <h3>키</h3>
                                        <input type="text" class="cont__text-input" v-model="height">
                                        <span style="margin-left:10px; font-size:20px; color:#555;">cm</span>
                                    </li>
                                    <li class="bmi_calc_item calc_item03">
                                        <img src="/resource/icon/scale_icon.png" alt="아이콘">
                                        <h3>몸무게</h3>
                                        <input type="text" class="cont__text-input" v-model="weight">
                                        <span style="margin-left:10px; font-size:20px; color:#555;">Kg</span>
                                    </li>
                                </ul>
                                <div class="btn-wrap center contents">
                                    <a href="javascript:;" class="btn blue" @click="calculateBMI">측정하기</a>
                                </div>
                                <ul class="bmi_result_list contents">
                                    <li class="bmi_result_item bmi_result01">
                                        <img src="/resource/icon/bmi_icon.png" alt="아이콘">
                                        <h3>BMI 지수 : </h3>
                                        <p class="blue__title">{{ bmi }}</p>
                                    </li>
                                    <li class="bmi_result_item bmi_result02">
                                        <img src="/resource/icon/thumbsup_icon.png" alt="아이콘">
                                        <h3>비만도 결과 : </h3>
                                        <p class="blue__title">{{ bmiResult }}</p>
                                    </li>
                                </ul>
                                <div class="bmi_bar_wrap">
                                    <div class="bmi_box" :style="{ left: trianglePosition }">
                                        <div class="bmi_con">나의 BMI 지수 : <span>{{ bmi }}</span></div>
                                        <img src="/resource/icon/bmi_triangle.png" alt="이미지" class="bmi_triangle">
                                    </div>
                                    <ul class="bmi_bar_list">
                                        <li class="bmi_bar_item bar01">
                                            저체중
                                            <p class="bar_num01">BMI</p>
                                            <p class="bar_num02">18.5</p>
                                        </li>
                                        <li class="bmi_bar_item bar02">
                                            정상
                                            <p class="bar_num02">23</p>
                                        </li>
                                        <li class="bmi_bar_item bar03">
                                            과체중
                                            <p class="bar_num02">25</p>
                                        </li>
                                        <li class="bmi_bar_item bar04">
                                            비만
                                            <p class="bar_num02">30</p>
                                        </li>
                                        <li class="bmi_bar_item bar05">
                                            고도비만
                                            <p class="bar_num03">35</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                <br>
                <br>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">비만대사수술 효과</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>당뇨, 고혈압 등 대사질환의 개선</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>효과적인 체중감소(초과체중의 60~70%)와 장기적인 체중 유지</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">문의</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>270-1240 (외과 외래)</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

   
</template>


<script>
export default {
  data() {
    return {
      gender: '',
      height: '',
      weight: '',
      bmi: 0,
      bmiResult: '',
      trianglePosition: '0%'
    };
  },
  methods: {
    calculateBMI() {
      const heightInMeters = this.height / 100;
      this.bmi = (this.weight / (heightInMeters * heightInMeters)).toFixed(1);

      if (this.bmi < 18.5) {
        this.bmiResult = '저체중';
        this.trianglePosition = (this.bmi / 18.5) * 15 + '%';
      } else if (this.bmi < 23) {
        this.bmiResult = '정상';
        this.trianglePosition = 23 + ((this.bmi - 18.5) / (23 - 18.5)) * 2 + '%';
      } else if (this.bmi < 25) {
        this.bmiResult = '과체중';
        this.trianglePosition = 40 + ((this.bmi - 23) / (25 - 23)) * 13 + '%';
      } else if (this.bmi < 30) {
        this.bmiResult = '비만';
        this.trianglePosition = 60 + ((this.bmi - 25) / (30 - 25)) * 10 + '%';
      } else {
        this.bmiResult = '고도비만';
        this.trianglePosition = 77 + ((this.bmi - 30) / (35 - 30)) * 9 + '%';
      }

      // 최대값 35로 제한하고 포지션 조정
      if (this.bmi > 35) {
        this.bmi = 35;
        this.trianglePosition = '87%';
      }
    }
  }
};
</script>
