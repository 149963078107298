<template>
    <div class="login-box">
        <p class="contents sub-text dp2">아이디를 찾기 위해서는 아래와 같이 인증이 필요합니다. <br>
            아이디 찾기를 위한 인증방법을 선택해주세요.</p>

        <ul class="contents login-box__list id">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit">본인 인증</h3>
                <p class="desc">본인명의 휴대폰, 공동인증서</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit">아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">휴대전화 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름">
                    </label>
                    <label class="login-box__phone">
                        <span class="input-tit">휴대전화</span>
                        <div class="input-text">
                            <input type="text"><span class="line">-</span>
                            <input type="text"><span class="line">-</span>
                            <input type="text">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">이메일 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름">
                    </label>
                    <label class="login-box__email">
                        <span class="input-tit">이메일</span>
                        <div class="input-text">
                            <input type="text"> <span class="line">@</span>
                            <input type="text">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
            </li>
        </ul>
        <div class="contents">
            <div class="btn-wrap right"><a href="javascript:;" class="btn dark-gray">비밀번호 찾기</a></div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>