<template>
    <!-- 응급의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>응급의학과는 언제 어디서든 발생할 수 있는 급성질환이나 손상 환자에 대한 응급 진료를 전문적으로 담당합니다. 일차적인 의료 행위뿐만 아니라 포괄적이고 효과적인 접근을 통해 불안정한 상태의 환자에게 적절한 소생의 기회를 제공하여 후속 치료로 연결될 수 있게 해주며 심근경색이나 뇌혈관 질환 등과 같은 중증질환의 신속한 진단과 치료가 가능하게 해주는 진료과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>중환자의학, 소생의학</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 응급의학과는 지역에 응급의학과라는 과조차 생소하던 2000년대부터 일찍이 24시간 전문의 직접 진료체계를 갖추고, 창원 및 경상남도 내 응급환자 진료에 임해왔습니다. 지역응급의료센터로 넓은 진료 공간과 최신 장비를 갖추고 있으며, 우수한 응급의학 전문의 9명이 응급환자 발생 시 신속 정확한 진료를 제공하고자 노력하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">진료단계 및 중증도별 구역 나눠 신속한 응급진료 제공</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>응급의료센터는 격리, 환자 분류, 진료, 검사 구역으로 구획하고 진료단계와 중증도에 따라 응급환자 진료구역과 중증응급환자 진료구역을 나눠 안정 상태에서 집중도 높은 치료를 받을 수 있도록 운영하고 있습니다. 또한 언제라도 발생할 수 있는 팬데믹 사태에 즉각 대처 가능하도록 센터 내 코호트진료구역으로 전환 가능한 진료구역을 운영하고 있으며, 발열 환자 증가 시 신속히 전환, 진료할 수 있도록 강도 높은 감염예방 및 관리를 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">환자 편의 최우선, 센터 내 전용 검사실 운영</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>보다 신속한 치료와 환자들의 회복을 위해 소생실에서 다수 환자의 동시 처치가 가능하며, 소생 이후 중환자실에 준하는 처치가 가능하도록 모니터링 장비를 갖추고 있습니다. 또한 환자 편의를 최우선으로 한 동선과 골든타임 내 신속, 효율적인 응급의료서비스 제공을 위해 센터 내 응급 환자 전용 X-ray, CT검사실을 배치해 대기 없이 빠른 검사를 제공합니다. 응급실 내에서 검사가 이뤄지므로 조영제 알레르기쇼크 등 발생 시 즉각 대처할 수 있어 보다 안정적인 환경에서 검사 받을 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">초고속 하드웨어 및 인공지능 알고리즘 탑재 CT, 응급환자 전용으로 신속한 검사</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>특히 응급의료센터 내 3D 스펙트럴 CT 장비는 경남에서는 최초로, 국내에서는 3번째로 도입했으며, 응급환자의 빠르고 정확한 진단을 위해 응급의료센터 전용으로 운영 중입니다. 응급환자 전용 ‘스펙트럴 CT 7500'은 초고속 하드웨어와 최신 인공지능 기술 및 알고리즘을 탑재한 최상위 장비로 단 한 번의 촬영으로 3D 스펙트럴 CT 영상 구현이 가능하며, 초미세 병변을 검출해 진단 정확도를 극대화하는 최고 사양 CT입니다. 또 방사선량을 저감하면서 영상 품질은 유지해 방사선에 민감한 소아의 촬영도 가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>