<template>
    <Header></Header>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home"><a href="" class="Location__item--btn"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQ4ja3TP0sDQRAF8J+HiGAKLQVFsbIQ4p/SRrDKB0hhZyXYiFjnQ9jZCJYigo2CRUDBNkr8ArGytRAUooVaZALHceeZ4IOFtzPz3s4uOyP1el0JFnAafAtPvxUnJWZ1tPEYqx2xQowWxMdxGB3t4CziNzjGJvbR/UuHi2hhDaspM8FXI9eK2l8Nt3GPJtbRyTmwE7lm1G6nk/0rV3CEmt41L3OM0vjEAW5xovcEu3hLsIwHzAcvM0vjMjTz4bGcROvn2MDzAGZ9PIf2HM0EM2jgAtUhDKuhbWB2FB+RmMNUjmAEK8Hb+M7kp0IL3aJ/2McErjOGNbwXCcomZVfvB0zHqkSsEGUdLuEKb7G/ilghyjqErwI+tOFA+HfD7BtWMJnaj+VoxjI1lSLDV/ljt5fiL3qzvpWpueuTH1quOlOE3j3aAAAAAElFTkSuQmCC"
                            alt="이미지"></a></li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과/의료진</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/department-doctor/department.do" class="">진료과/의료진</a></li>
                            <li><a href="/reserve/appointment.do" class="">예약/발급</a></li>
                            <li><a href="/hospital/information/outpatient.do" class="">이용안내</a></li>
                            <li><a href="/hospital/healthInfo/consulting.do" class="">건강정보</a></li>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">병원소개</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item"><a href="javascript:;" class="Location__item--btn">
                        <p>진료과</p><span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a href="/hospital/hospitalIntro/fatima/greetings.do" class="">창원파티마병원</a></li>
                            <li><a href="/hospital/hospitalIntro/story/newsBoard.do" class="">파티마이야기</a></li>
                            <li><a href="/hospital/hospitalIntro/active/prayBoard.do" class="">병원활동</a></li>
                        </ul>
                    </div>
                </li>
                <li class="Location__item">
                    <div class="Location__item--Layer">
                        <ul>
                            <li><a aria-current="page" href="/kr/news/list.do"
                                    class="router-link-active router-link-exact-active">화이팅</a></li>
                            <li><a href="/kr/press/list.do" class="">언론보도</a></li>
                            <li><a href="/kr/event/list.do" class="">행사안내</a></li>
                            <li><a href="/kr/job/list.do" class="">퍼블팀</a></li>
                            <li><a href="https://fliphtml5.com/bookcase/nrkwy/"
                                    to="https://fliphtml5.com/bookcase/nrkwy/"></a></li>
                            <li><a href="/kr/gallery/list.do" class=""></a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sub-tit">
            <h2>OOO과</h2>
        </div>
        <div class="inner">
            <div class="info-text">
                <div class="btn-wrap right">
                    <a href="javascript:;" class="btn blue">성장·장건강·대사증후군클리닉 FAQ 바로가기 </a>
                </div>
                <div class="board-view__file">
                    <div class="tit">첨부파일</div>
                    <ul class="file-list">
                        <li class="file-item"><button type="button"><span class="icon"></span>
                                입사지원서(지원부서명-지원직종-이름)-2024.hwp</button></li>
                    </ul>
                </div>
            </div>
            <div class="info-text">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">소개</h4>
                        <p>창원파티마병원 소아청소년과에서는 전국 최초로 성장, 성조숙증, 장건강, 대사증후군 질환을 통합해서 관리하는 성장·장건강·대사증후군클리닉을 개설하여 운영하고 있습니다</p>
                    </div>
                </div>
            </div>
            <div class="info-text">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">의료진</h4>
                    </div>
                </div>
                <div class="info__doctor-list">
                    <div class="calendar__header"><button class="calendar__header--button prev"></button>
                        <h4 class="calendar__header--title">2022.08</h4><button class="calendar__header--button next"></button>
                    </div>
                    <ul class="doctor-list">
                        <li class="doctor-list__item">
                            <div class="doctor-list--img">
                                <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart"></a>
                                    <h3>마상혁<sub>소아청소년과</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;">의료진소개</a>
                                    <a href="javascript:;">진료예약</a>
                                </div>
                            </div>
                            <div class="weekly__calendar">
                                <table>
                                    <colgroup>
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>진료시간</th>
                                            <th>월 (6/20)</th>
                                            <th>화 (5.21)</th>
                                            <th>수 (5.22)</th>
                                            <th>목 (5.23)</th>
                                            <th>금 (5.24)</th>
                                            <th>토 (5.25)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>오전</td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>오전</td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li class="doctor-list__item">
                            <div class="doctor-list--img">
                                <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart"></a>
                                    <h3>마상혁<sub>소아청소년과</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;">의료진소개</a>
                                    <a href="javascript:;">진료예약</a>
                                </div>
                            </div>
                            <div class="weekly__calendar">
                                <table>
                                    <colgroup>
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>진료시간</th>
                                            <th>월 (6/20)</th>
                                            <th>화 (5.21)</th>
                                            <th>수 (5.22)</th>
                                            <th>목 (5.23)</th>
                                            <th>금 (5.24)</th>
                                            <th>토 (5.25)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>오전</td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>오전</td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li class="doctor-list__item">
                            <div class="doctor-list--img">
                                <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart"></a>
                                    <h3>마상혁<sub>소아청소년과</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;">의료진소개</a>
                                    <a href="javascript:;">진료예약</a>
                                </div>
                            </div>
                            <div class="weekly__calendar">
                                <table>
                                    <colgroup>
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>진료시간</th>
                                            <th>월 (6/20)</th>
                                            <th>화 (5.21)</th>
                                            <th>수 (5.22)</th>
                                            <th>목 (5.23)</th>
                                            <th>금 (5.24)</th>
                                            <th>토 (5.25)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>오전</td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>오전</td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li class="doctor-list__item">
                            <div class="doctor-list--img">
                                <img src="@/assets/resource/img/doctor-list--img.png" alt="이미지">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart"></a>
                                    <h3>마상혁<sub>소아청소년과</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO, OOOOO,</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;">의료진소개</a>
                                    <a href="javascript:;">진료예약</a>
                                </div>
                            </div>
                            <div class="weekly__calendar">
                                <table>
                                    <colgroup>
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                        <col style="width:calc(100% / 7);">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>진료시간</th>
                                            <th>월 (6/20)</th>
                                            <th>화 (5.21)</th>
                                            <th>수 (5.22)</th>
                                            <th>목 (5.23)</th>
                                            <th>금 (5.24)</th>
                                            <th>토 (5.25)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>오전</td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>오전</td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                            <td></td>
                                            <td><span class="check"></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-text">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">특장점</h4>
                        <p>
                            어떤 아이들이 건강하게 자라나요? 우리 아이 건강하게 자라고 있는지 알 수 있을까요? 우리아이의 성장이 궁금합니다. 우리 아이는 장이 좋지 않은데... <br>
                            자녀를 키우면서 자녀의 건강을 걱정하시지 않은 부모는 없을 것입니다. 간단한 피 검사와 키, 몸무게를 통해 아이들의 건강을 알 수 있을까요? <br>
                            최근에는 장내미생물 검사(마이크로바이옴 검사)를 통한 장건강을 비롯해 전반적인 건강상태를 점검하여 맞춤 유산균 선택, 음식 선택이 가능하게 되었습니다.<br>
                            <br>
                            코로나 유행 이후에 아이들의 몸매가 너무나 커지고 있습니다. 이런 아이들은 조기에 대사증후군 질환과 각종 합병증에 시달릴 수 있어 조기 발견과 관리가 무엇보다 필요합니다
                            <br>
                            <br>
                        </p>
                        <div class="depth__wrap depth02">
                            <div class="depth__bullet"><span class="bullet02"></span></div>
                            <div class="depth__text">
                                <h4 class="depth02__title blue__title title">대상 환자들은 누구인가요?</h4>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>키가 작다고 생각되는 경우</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>다른 아이들 보다 이차성징이 빠르다고 생각되는 경우</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>갑자기 체중이 늘어난 경우, 아이의 몸매가 예쁘지 않는 경우</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>비만, 당뇨, 고지혈증의 가족력이 있는 경우</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>출생 시 몸무게가 작은 아이</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>배가 자주 아픈 아이</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>잘 먹지 않는 아이</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>머리가 자주 아픈 아이</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>아침을 안 먹고 학교 가는 아이</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap depth02">
                            <div class="depth__bullet"><span class="bullet02"></span></div>
                            <div class="depth__text">
                                <h4 class="depth02__title blue__title title">무슨 검사를 하게 되나요?</h4>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>각종 혈액검사, 소변검사</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>장내 미생물 검사 : 기본검사로 하여 먹거리 선택을 도와줍니다</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>스트레스검사, 심리검사(선택)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>성장판검사(선택)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>청력, 시력검사(선택)</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>종합검진(혈액검사, 장건강검사, 성장판검사, 척추측만검사, 스트레스검사, 시력·청력검사, 심리검사)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap depth02">
                            <div class="depth__bullet"><span class="bullet02"></span></div>
                            <div class="depth__text">
                                <h4 class="depth02__title blue__title title">치료는 어떻게 하나요?</h4>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>키가 작은 아이 : 성장호르몬 치료 </p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>이차성징 빠른 아이 : 성조숙증 치료</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>장내미생물 검사 결과를 기반으로 하여 먹거리 선택과 유산균 선택</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>고지혈증, 당뇨검사에서 이상이 있는 경우 치료 : 프리바이오틱스, 고지혈증 치료제 사용</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>생활습관 교정, 운동법 교육</p>
                                    </div>
                                </div>
                                <div class="depth__wrap">
                                    <div class="depth__bullet"><span class="bullet03"></span></div>
                                    <div class="depth__text">
                                        <p>장건강 수첩으로 먹거리 교육</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
    import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Footer from '@/components/common/Footer.vue';

    export default {
        components: {
            Header,
            LocationBar,
            Footer
        },
        mounted() {
            console.log(123)
        },
    }
</script>
