<template>
    <div class="contents">
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료절차</h4>
                <p>창원파티마병원과 협력 병,의원 협약을 신청하신 의료기관에 대해 절차에 따라 협약 체결이 진행됩니다.</p>
            </div>
        </div>
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">협약체결 절차</h4>
                    <div class="depth__wrap depth02">
                        
                    </div>
                </div>
            </div>
            <ul class="step-textbox">
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">온라인 협약 신청</h3>
                                    </div>
                                </div>
                            </li>
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">검토, 심의</h3>
                                    </div>
                                </div>
                            </li>
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">협약 체결</h3>
                                    </div>
                                </div>
                            </li>
                        </ul>
        </div>
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">체결관련 문의</h4>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>주소 : 경남 창원시 의착구 창이대로 45(51394) 창원파티마병원 진료협력센터</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>전화 : 055-270-1108</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>팩스 : 055-270-1029</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>이메일 : fatima2901@hanmail.net</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">구비서류</h4>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>협력병원 신청 공문 1부</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>협력신청 의료기관 현황 1부</p>
                    </div>
                </div>
                <div class="depth__wrap ">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>병원 홍보자료</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contents coop02">
        <a href="javascript:;" class="cooperation_intro__button">
            <span>협력병원신청서 양식 다운로드</span>
        </a>
    </div>
</template>

<script>
export default {};
</script>