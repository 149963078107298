<template>
    <div v-html="content.content" class="contents"></div>
</template>

<script>
export default {
    data() {
        return {
            content: '',
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
            }
        }
    },
    methods: {
        getContent(){
            const path = this.$route.path
            let menuNo
            for(let i of this.menuList){
                if(i.menuUrl == path && i.useYn == 'Y' && i.menuType == 'C') menuNo = i.menuNo
            }
            if(!menuNo) return
            this.axios.get(this.api.content.menuNo, {
                params: {
                    menuNo: menuNo
                }
            })
            .then(res => {
                this.content = res.data

                // img 태그의 alt 속성 및 테이블 캡션 추가
                this.$nextTick(() => {
                    this.addAltToImages();
                    this.addCaptionToTables();
                    this.createScript(res.data.contentScript);
                })
            })
        },
        createScript(item) {
            this.removeScript()
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.id = 'cntntScript'
            script.textContent = item

            script.onload = () => {
                console.log('Script loaded and executed.');
            };

            script.onerror = () => {
                console.error('Script loading failed.');
            };
            head.appendChild(script);
        },
        removeScript(){
            const existingScript = document.querySelector('#cntntScript');
            if (existingScript) {
                existingScript.remove();
            }
        },
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table && table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        },
        checkAllTableWidths() {
            const elements = document.querySelectorAll('.your-class');
            elements.forEach(element => {
                this.checkTableWidth(element);
            });
        },
        addAltToImages() {
            const images = this.$el.querySelectorAll('img');
            images.forEach((img) => {
                if (!img.getAttribute('alt')) {
                    img.setAttribute('alt', '이미지');
                }
            });
        },
        addCaptionToTables() {
            const tables = this.$el.querySelectorAll('table');
            tables.forEach((table) => {
                let caption = table.querySelector('caption.blind');
                if (caption) {
                    if (!caption.textContent.trim()) {
                        caption.textContent = '표';
                    }
                } else {
                    // 캡션이 없으면 추가
                    caption = document.createElement('caption');
                    caption.classList.add('blind');
                    caption.textContent = '표';
                    table.insertBefore(caption, table.firstChild);
                }
            });
        }
    },
    mounted() {
        this.getContent()
    },
    updated() {
        this.$nextTick(() => {
            let tableItems = this.$el.querySelectorAll(".table-wrap");

            tableItems.forEach((el) => {
                this.checkTableWidth(el);
                el.addEventListener("click", () => {
                    el.classList.add('on');
                });
            });

            // 이미지 alt 속성 및 테이블 캡션 추가
            this.addAltToImages();
            this.addCaptionToTables();
        });
    },
}
</script>
