<template>
    <div v-html="content.content" class="contents"></div>
</template>

<script>
export default {
    data() {
        return {
            content: '',
            scriptId: 'content_script_' + Date.now()
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                this.removeScript()
                this.getContent()
            }
        }
    },
    methods: {
        async getContent(){
            const deptCd = this.$route.params.deptCd
            const hpUrl = this.$route.params.hpUrl
            const path = this.$route.path.replace(`/deptHp/${deptCd}/${hpUrl}`, '')
            let cntntmstNo

            const list = this.$parent.menuList.sort((a, b) => a.depth - b.depth)
            for(let i of list){
                if(i.menuUrl == path && i.useYn == 'Y' && i.menuType == 'C') {
                    cntntmstNo = i.cntntmstNo
                    break
                }
            }
            if(!cntntmstNo) return

            const res = await this.axios.get(this.api.content.cntntmstNo, {
                params: {
                    cntntmstNo: cntntmstNo
                }
            })
            
            this.content = res.data
            this.createScript(res.data.contentScript);

            this.$nextTick(() => {
                let tableItems = document.querySelectorAll(".table-wrap");

                tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                });

                let tableItem = document.querySelectorAll(".table-wrap");

                tableItem.forEach((el) => {
                    el.addEventListener("click", () => {
                        el.classList.add('on');
                    });
                }); 
            })
        },
        createScript(item) {
            this.removeScript()
            setTimeout(() => {
                const head = document.getElementsByTagName('head')[0];
                const script = document.createElement('script');
                script.id = this.scriptId
                script.textContent = item.indexOf('(function') > -1 ? item : `(function() { ${item} })();`;
                head.appendChild(script);
            }, 500);
        },
        removeScript() {
            const script = document.getElementById('content_script');
            if (script) {
                script.parentNode.removeChild(script);  // 스크립트를 부모 노드에서 제거
            }
        },
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
    },
    mounted() {
        this.getContent()
    
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

            tableItems.forEach((el) => {
                this.checkTableWidth(el);
                el.addEventListener("click", () => {
                el.classList.add('on');
                });
            });
        });
    },
}
</script>