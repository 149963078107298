<template>
    <!-- 마취통증학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>마취통증의학과는 크게 마취 분야와 통증치료 분야로 나뉩니다. 마취를 통해 수술 및 시술, 검사 시 원활한 진행과 환자의 안전을 책임지고 있으며, 통증클리닉을 운영하여 다양한 통증의 효과적인 관리를 돕기 위해 진료 서비스를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>마취분야 : 일반마취, 부위마취, 소아마취, 노인마취, 심폐마취, 중환자마취</p>
                                <p>통증치료분야 : 수술 후 통증, 척추질환, 골반통, 관절통(어깨, 무릎 등), 신경병증성 통증, 두통, 대상포진 후 신경통, 근근막통증증후군</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                         <h4 class="depth02__title blue__title title">첨단 마취 기기와 환자 감시 장비로 안전하게</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>마취통증의학과에서는 수술실에서 시행되는 모든 수술과 마취가 필요한 시술들이 원활하고 안전하게 진행될 수 있도록 전신 마취와 부분 마취를 시행하고 있습니다. 이를 위해 환자 상태와 수술에 따라 가장 적합한 마취 방법을 선택해 실행하고, 빠른 회복을 위한 여러 치료와 수술 후 통증 관리도 담당하고 있습니다. 또한 산모들의 무통분만 시술도 시행하고 있습니다.</p>
                                <br>
                                <p>창원파티마병원 마취통증의학과에서는 모든 마취를 전문의가 직접 시행하고 있으며, 환자의 안전을 최우선으로 의사 모두가 서로 협력하고 있습니다. 수술 전 환자 평가를 통해 마취 계획에 만전을 기하고, 수술 전·중·후에 나타날 수 있는 모든 위험으로부터 환자를 안전하게 보호하기 위해 첨단 마취기기 및 환자 감시 장비로 최상의 마취 서비스를 제공하고 있습니다. 또한 다양한 질환군을 가진 환자들도 충분히 안전하게 치료 받을 수 있도록 마취를 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">통증이 있는 어느 부위라도</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>마취통증의학과에서는 머리(도퉁)에서부터 발바닥(족저근막염)까지 통증이 있는 어떤 부위라도 치료가 가능합니다. 주로 두통, 대상포진, 단순 염좌, 무릎과 어깨를 포함한 관절염, 척추디스크, 척추협착 뿐 아니라 중증 난치성 질환인 암성 통증, 복합부위 통증 증후군도 치료 하고 있습니다.</p>
                                <br>
                                <p>창원파티마병원은 통증 유발점 주사, 연골주사로도 알려진 관절강내 주사, 경추를 포함한 척추의 신경차단술, 말초신경의 신경차단술을 시행하고 있으며, 난치성 통증 조절을 위한 교감신경 차단술과 암성 통증 조절을 위한 교감신경 파괴술도 시행하고 있습니다. 또한 척추 신경성형술 및 고주파 열응고술도 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">신경차단술</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경손상으로 발생하는 통증을 완화하고자 신경 주위에 약물을 투여하는 방법으로 마취통증의학과의 가장 핵심이자 강점인 치료입니다. 신경차단술 시행 시신경 주위의 염증과 부종이 가라앉게 되고, 주위 근육과 혈관의 흐름이 호전되면서 통증을 전달하는 신경회복을 통해 통증에서 해방하게 해주는 방법입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">효과적이고 빠른 통증 조절 - 통증클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>환자마다 통증의 양상 및 정도가 다르기 때문에 섬세한 병력 청취 및 이학적 검사를 통해 개인별 맞춤치료를 제공하고 있습니다. 또한 종합병원으로써 진단을 위해 필요한 검사 및 장비를 보유하고 있으며, 여러 진료과와의 협력을 통해 보다 정밀하고 폭넓은 다학제적 치료가 가능합니다. 통증클리닉은 환자분들의 대기 시간을 최소화하기 위해 예약제로 운영하고 있으며, 몇 가지 시술을 제외하고는 진료 당일 바로 시술을 실시하여 효과적이고 빠르게 통증 조절이 가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">통증클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>