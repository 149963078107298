<template>
    <div class="reserv_intro health_reserve_wrap">
        <div class="contents">
            <ul class="reserv_list">
                <li class="reserv_item first_reserve">
                    <div class="info-text">
                        <h3 class="depth01__title title">건강증진센터 예약</h3>
                        <p>창원파티마병원에서는 질병의 조기 발견과 치료를 돕기 위해 생애주기별,
                            <br>성별, 질환별로 다양한 종합검진프로그램을 운영하고있습니다.</p>
                    </div>                   
                   <div class="contents">
                        <div class="flex_wrap">
                            <div class="text__bold_wrap">
                                <p class="text__bold">전화문의 :<br>055)270-1200(건강증진센터)</p>
                                <p class="text__bold mt10">운영시간 :<br>평일 9:00~17:00, 토9:00 ~ 12:00</p>
                            </div>
                            <div class="btn-wrap center">
                                <a href="javascript:;" class="btn blue" title="새 창 이동" target="_blank">예약 바로가기</a>
                            </div>
                        </div>
                   </div>  
                </li>

                <li class="reserv_item online_reserve">
                    <div class="info-text">
                        <h3 class="depth01__title title">국가암검진 예약</h3>
                        <p>국민건강보험 가입자에게 제공되는 국가암검진을 쉽고 빠르게 예약할 수 있습니다.</p>
                        <p class="text__mint">예약이 필요한 국가 암검진 위암(위내시경, 위장조영술 중 택1), 폐암(폐CT), 간암(간초음파)</p>
                    </div>
                    <div class="contents">
                        <div class="flex_wrap">
                            <div class="text__bold_wrap">
                                <p class="text__bold">전화문의 :<br>055)270-1200(직업환경의학센터)</p>
                                <p class="text__bold mt10">운영시간 :<br>평일 8:30~16:40, 토8:30 ~ 12:00</p>
                            </div>
                            <div class="btn-wrap center">
                                <a href="javascript:;" class="btn mint" title="새 창 이동" target="_blank">예약 바로가기</a>
                            </div>
                        </div>   
                   </div> 
                </li>
            </ul>
        </div> 
    </div>
</template>


<script>
    export default {
        data() {
           
        },
        methods: {
           
        }
    };
</script>