<template>
    <div class="paging" v-if="totCnt > 0">
        <a href="javascript:;" class="board__pagination-btn first" @click="goPage(1)" v-if="currentNum > 1">첫 페이지</a>
        <a href="javascript:;" class="board__pagination-btn prev" @click="goPage(Math.floor((currentNum - 1) / pageCount) * pageCount)" v-if="currentNum > pageCount">이전 페이지</a>
        <a v-for="i in pageList" :key="i" class="board__pagination-btn num" :class="currentNum == i ? 'on' : ''" href="javascript:;" @click="goPage(i)">{{ i }}</a>
        <a href="javascript:;" class="board__pagination-btn next" @click="goPage(Math.ceil(currentNum / pageCount) * pageCount + 1)" v-if="currentNum < Math.floor((totalPage - 1) / pageCount) * pageCount + 1">다음 페이지</a>
        <a href="javascript:;" class="board__pagination-btn last" @click="goPage(totalPage)" v-if="currentNum != totalPage">마지막 페이지</a>
    </div>

</template>

<script>

//totCnt, pageRow, pageCount, currentNum을 받아와서 totalPage를 계산
//:totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"
export default {
    props: {
        currentNum: '',
        pageRow: '',
        pageCount: '',
        totCnt: '',
    },
    data() {
        return {
            pageList: '',
        }  
    },
    methods: {
        goPage(pageCount){
            // this.$router.replace({
            //     path: this.$route.path,
            //     query: {
            //         currentNum: pageCount,
            //     }
            // })
            this.$emit("setNum", pageCount);
        }
    },
    computed: {
        //totCnt를 받아와서 totalPage를 계산
        totalPage() {

            let end = Math.ceil(this.currentNum / this.pageCount) * this.pageCount;
            let start = end - (this.pageCount - 1);
            let totalPage = Math.ceil(this.totCnt / this.pageRow);
            if (end > totalPage) {
                end = totalPage
            }
            this.pageList = [];
            for (let i = start; i <= end; i++) {
                this.pageList.push(i);
            }
            
            return totalPage
        }
    },
    mounted() {
    }
}
</script>
