<template>
    <!-- 재활치료센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>재활치료센터는 중추신경계 및 말초신경질환, 근골격계 이상 등으로 인한 여러 장애가 있는 환자의 진료부터 치료까지 One-stop 통합재활 서비스를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">재활의학과</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>다양한 재활 프로그램을 통해 신체, 정신, 사회적 능력이 최대한 회복 되도록 돕고 있으며, 소아보행장애클리닉, 사경·사두증클리닉, 경직클리닉, 연하장애클리닉 등을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">재활치료실</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>질병이나 사고로 인해 상실된 신체적 기능을 최대한 회복 및 치유하고, 일상과 사회로의 복귀 및 기능적 능력의 증진을 위해 양질의 체계적인 재활프로그램을 제공합니다. 창원파티마병원 재활치료실은 170평 규모로 각 세부 치료실별로 물리치료사, 작업치료사, 언어재활사 등 팀을 이루어 환자별 맞춤형 재활치료를 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <br>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <div class="table-wrap table-type1">
                                    <div class="scroll">
                                        <table>
                                            <caption class="blind">재활치료실</caption>
                                            <colgroup>
                                                <col style="width:20%;">
                                                <col style="width:20%;"> 
                                                <col style="width:30%;">
                                                <col style="width:30%;">
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th colspan="2">치료실</th>
                                                    <th colspan="2">치료대상</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td rowspan="5">물리치료</td>
                                                    <td>정형물리치료실</td>
                                                    <td colspan="2" style="text-align: left;">근골격계통증, 운동제한, 말초신신경계 손상마비, 만성질환 등</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left: 1px solid #d9d9d9;">성인재활치료실</td>
                                                    <td colspan="2" style="text-align: left;">중추신경계 손상으로 인한 신체기능 저하<br>(뇌졸중, 척추손상, 각종 외상성 뇌손상, 파킨슨 등)</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left: 1px solid #d9d9d9;">소아재활치료실</td>
                                                    <td colspan="2" style="text-align: left;">뇌성마비, 발달지연, 진행성근육위축증, 희귀난치성질환 등<br>사경, 측경, 측만 등 자세교정</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left: 1px solid #d9d9d9;">림프치료실</td>
                                                    <td colspan="2" style="text-align: left;">암(유방암, 자궁암 등)으로 인한 림프부종 증상이 있는 환자,<br>수술이후 림프순환저하 환자</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left: 1px solid #d9d9d9;">심장호흡재활실</td>
                                                    <td colspan="2" style="text-align: left;">심장 수술 · 시술 받은 환자<br>인공심박동기 삽입술을 받은 환자급성 심근경색,심부전,부정맥 환자 등<br>COPD, 천식 등의 만성호흡부전 환자</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2">작업치료</td>
                                                    <td>성인작업치료실</td>
                                                    <td colspan="2" style="text-align: left;">뇌졸중, 척추손상, 외상성 뇌손상, 노인성질환 등으로 인한<br>신체기능 및 인지 저하, 삼킴 곤란, 시야 결손 등</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left: 1px solid #d9d9d9;">성인작업치료실</td>
                                                    <td colspan="2" style="text-align: left;">뇌성마비, 발달지연, 시지각 및 인지저하, 학습 장애 등</td>
                                                </tr>
                                                <tr>
                                                    <td>언어치료</td>
                                                    <td>성인/소아 언어치료실</td>
                                                    <td colspan="2" style="text-align: left;">신경언어장애, 언어발달지체, 조음·음운장애, 유창성 장애 등</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>
    </div>

    <div class="img-box-wrap">
        <img src="/resource/img/Rehabilitation01.jpg" alt="이미지">
        <img src="/resource/img/Rehabilitation02.Jpg" alt="이미지">
        <img src="/resource/img/Rehabilitation03.Jpg" alt="이미지">
    </div>
</template>


<script>
    export default {

    };
</script>