<template>
    <!-- 심장재활클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 재활의학과에서는 심혈관질환이 있는 분들의 심폐기능, 운동능력, 심리상태를 효과적으로 안정·회복시키고 합병증을 예방하기 위해 맞춤형 심장재활클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심장재활치료란 심장질환을 가진 환자를 대상으로 운동평가와 치료 및 위험인자 교정을 위한 교육을 통해 질환의 회복을 돕고 질병의 악화와 재발을 방지해 안전하고 행복한 삶을 도모할 수 있도록 여러분야의 전문가가 한 팀을 이루어 체계적인 치료를 제공하는 재활프로그램 입니다. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">심장재활프로그램 구성 </h4>
                      
                    </div>
                </div>
                  <div class="depth__wrap">
                            <div class="depth__text">
                               <img src="/resource/img/center05.png" alt="심장재활프로그램의 구성" style="width:100%; max-width:1000px;">
                            </div>
                        </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">심장재활치료효과</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심장재활치료는 심장질환의 재발율, 사망률, 고혈압, 당뇨병, 비만, 불안 감소시키고 심폐기능, 사회복귀, 운동능력, 삶의 질을 향상시킵니다. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">심장재활클리닉이 필요하신 분</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심장 수술, 시술 받은 환자</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>인공심박동기 삽입술을 받은 환자</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>급성 심근경색, 불안정성 협심증 입원 치료 환자</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>보상된 심부전 환자</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>말초동맥질환 수술, 시술, 약물치료 환자</p>
                            </div>
                        </div>
                         <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심방, 심실성부정맥, 심실세동, 심장정지 경험자</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>선천성 심질환자</p>
                            </div>
                        </div>   
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>주요 폐수술을 시행한 환자(폐전적출술, 폐엽절제술)</p>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>


<script>
export default {
   
};
</script>