<template>
    <!-- 치과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>치과는 치아와 잇몸 및 턱 얼굴에 관련된 다양한 질병을 치료하는 진료과입니다. 구강악안면영역의 외상 및 질환에 대한 외과적인 치료뿐만 아니라 치아상실에 따른 보철치료, 보존치료, 치주수술, 치과 임플란트 등의 시술을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>구강악안면외과, 임플란트 수술, 턱관절질환, 치과보철과, 임플란트 보철</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>