// 컴포넌트를 동적으로 스캔
const requireComponent = require.context(
    '@/components', // 컴포넌트들이 위치한 디렉토리
    true, // 하위 디렉토리까지 탐색
    /\.vue$/ // .vue 파일만 대상으로 함
)

// 경로-컴포넌트 매핑 객체 생성
const routeComponentMapping = {}

requireComponent.keys().forEach(fileName => {
    // 파일 경로에서 컴포넌트 이름 추출
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')

    // 중복되는 디렉토리 이름과 파일 이름을 처리하는 로직 추가
    const pathSegments = fileName.split('/');
    let path = componentName.toLowerCase().replace(/\s+/g, '-');
    if (pathSegments.length > 2) {
        const directoryName = pathSegments[pathSegments.length - 2].toLowerCase();
        if (path.startsWith(directoryName)) {
            path = path.replace(directoryName + '-', '');
        }
    }

    // 매핑 객체에 추가
    routeComponentMapping[`/${path}.do`] = fileName.replace('./', '@/components/')
});

export default routeComponentMapping