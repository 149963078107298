<template>
    <div class="contents patient">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">환자의 권리</h4><!-- depth02 -->
            </div>
        </div>
        <div class="text-box type2">
            <div class="text-box__wrap type2">
                <h5 class="text-box__tit">1. 진료받을 권리</h5>
                <p class="text-box__desc">환자는 자신의 건강보호와 증진을 위하여 적절한 보건의료서비스를 받을 권리를 갖고, 성별ㆍ나이ㆍ종교ㆍ신분 및 경제적 사정 등을 이유로 건강 에 관한 권리를 침해받지 아니하며, 의료인은 정당한 사유 없이 진료를 거부하지 못한다.</p>

                <h5 class="text-box__tit">2. 알권리 및 자기결정권</h5>
                <p class="text-box__desc">환자는 담당 의사ㆍ간호사 등으로부터 질병 상태, 치료 방법, 의학적 연 구 대상 여부, 장기이식 여부, 부작용 등 예상 결과 및 진료 비용에 관하 여 충분한 설명을 듣고 자세히 물어볼 수 있으며, 이에 관한 동의 여부를 결정할 권리를 가진다.</p>

                <h5 class="text-box__tit">3. 비밀을 보호받을 권리</h5>
                <p class="text-box__desc">환자는 진료와 관련된 신체상ㆍ건강상의 비밀과 사생활의 비밀을 침해 받지 아니하며, 의료인과 의료기관은 환자의 동의를 받거나 범죄 수사 등 법률에서 정한 경우 외에는 비밀을 누설ㆍ발표하지 못한다.</p>

                <h5 class="text-box__tit">4. 상담ㆍ조정을 신청할 권리</h5>
                <p class="text-box__desc">환자는 의료서비스 관련 분쟁이 발생한 경우, 한국의료분쟁조정중재원 등에 상담 및 조정 신청을 할 수 있다.</p>
            </div>
        </div>

        <div class="contents info-text depth__wrap depth01 ">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">환자의 의무</h4><!-- depth02 -->
            </div>
        </div>
        <div class=" text-box type2">
            <div class="text-box__wrap type2">
                <h5 class="text-box__tit">1. 의료인에 대한 신뢰ㆍ존중 의무</h5>
                <p class="text-box__desc">환자는 자신의 건강 관련 정보를 의료인에게 정확히 알리고, 의료인의 치 료계획을 신뢰하고 존중하여야 한다.</p>

                <h5 class="text-box__tit">2. 부정한 방법으로 진료를 받지 않을 의무</h5>
                <p class="text-box__desc">환자는 진료 전에 본인의 신분을 밝혀야 하고, 다른 사람의 명의로 진료 를 받는 등 거짓이나 부정한 방법으로 진료를 받지 아니한다.</p>

                <h5 class="text-box__tit">3. 병원 내 공공질서 준수 의무</h5>
                <p class="text-box__desc">환자는 병원 내 규정을 준수하고 병원 직원 및 다른 환자에 대한 존중의 의무가 있다.</p>

                <h5 class="text-box__tit">4. 진료비 지불 의무</h5>
                <p class="text-box__desc">환자는 병원과 체결된 재정적 의무에 대한 책임이 있다.</p>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>