<template>
    <div class="full-width praise type2">
        <div class="praise__tit">
            <h3> 병원을 이용하시면서 느끼신 <b>불편사항</b>이나 <b>건의사항</b>을 제안해주시면 <br>
                신속히 처리하도록 하겠습니다 </h3>
            <div class="praise__btn-wrap">
                <button type="button" class="praise__btn">고객의 소리 글쓰기</button>
                <button type="button" class="praise__btn">작성한 글로 바로가기</button>
            </div>
        </div>

        <div class="inner">
            <div class="contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">제안 방법</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1">
                    <div class="scroll">
                        <table>
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width:50%;">
                                <col style="width:50%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>접수처</th>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>전화접수</td>
                                    <td>환자공감디자인팀 055-270-1095</td>
                                </tr>
                                <tr>
                                    <td>우편접수</td>
                                    <td>창원시 의창구 창이대로 45 고객지원담당 앞</td>
                                </tr>
                                <tr>
                                    <td>면담</td>
                                    <td>환자공감디자인팀 고객지원담당자 면담</td>
                                </tr>
                                <tr>
                                    <td>고객소리함</td>
                                    <td>병동 휴게실 및 로비</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
            };
        },
        methods: {

        },
        mounted() {
        },
    };
</script>