<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap contents">
          <div class="depth__text">
              <div class="depth__wrap depth02">
                  <div class="depth__text">
                      <p>『공무원 채용 신체검사 규정』을 기준으로 공무원 직무를 담당할 수 있는 신체상의 능력을 판정하기 위하여 실시하는 건강진단.</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">검진대상</h4>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>국가 공무원, 지방직 공무원, 법원, 검찰, 국세청, 공공기관, 공단, 공사, 공기업, 사립학교 교사, 교육공무원, 유치원, 어린이집 등</p>
                  </div>
              </div>
          </div>
    </div>
   
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">검사항목</h4>
        </div>
    </div>
    <div class="table-wrap table-type1">
        <div class="scroll">
            <table>
                <caption class="blind">검사항목</caption>
                <colgroup>
                    <col style="width:30%;">
                    <col style="width:70%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>검사분류</th>
                        <th>세부검사항목</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>기본체위</td>
                        <td style="text-align:left;">신장/체중/허리둘레/체질량/시력/청력/혈압/색각</td>
                    </tr>
                    <tr>
                        <td>소변검사</td>
                        <td style="text-align:left;">요검사 4종<br>TBPE(마약검사)</td>
                    </tr>
                    <tr>
                        <td>혈액검사</td>
                        <td style="text-align:left;">혈색소/공복혈당/총콜레스테롤/B형간염항원/B형간염항체/혈액형(ABO,Rh)/매독검사/AST(SGOT)/ALT(SGPT)</td>
                    </tr>
                    <tr>
                        <td>장비검사</td>
                        <td style="text-align:left;">흉부방사선(X-Ray)<br>심전도</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">접수시간</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>평일 오전 08:30 ~ 11:30, 오후 13:40 ~ 16:00</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>토요일 오전 08:30 ~ 11:30</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">소요시간 : 1시간 이내</h4>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">당일 수검자 현황에 따라 시간이 달라질 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>
     <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">준비물</h4>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>신분증</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>최근 6개월 이내 촬영한 증명사진(3.5*4.5) 1매</p>
                  </div>
              </div>
          </div>
    </div>
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">유의사항</h4><!-- depth02 -->
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>검진 전 8시간 이상 금식하시기 바랍니다. (껌, 커피, 우유 포함 일체 음식물)</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소변검사가 있으니 검사 전 되도록 소변을 참아 주시기 바랍니다.</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>복용하는 약이 있는 경우 전화문의 후 내원하시기 바랍니다.(문의 055-270-1051~3)</p>
                  </div>
              </div>
              <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>잠복결핵 검사가 있는 경우 토요일 및 공휴일 전날 검사가 불가합니다.</p>
                  </div>
              </div>
          </div>
    </div>
   
    <div class="info-text depth__wrap contents">
        <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
              <h4 class="depth01__title title">서류발급</h4>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>소 요 일 : 검사 후 5일</p>
                  </div>
              </div>
               <div class="depth__wrap depth02">
                  <div class="depth__bullet"><span class="bullet03"></span></div>
                  <div class="depth__text">
                      <p>수령방법 : 우편, 내원수령</p>
                  </div>
              </div>
              <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                      <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                      <p class="small-text">우편수령은 우체국 배송기간에 따라 추가 소요일이 발생 될 수 있습니다.</p>
                  </div>
              </div>
          </div>
    </div>

  </div>
</template>
<script>
export default {};
</script>