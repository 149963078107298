<template>
    <!-- 재활의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>재활의학과는 중추신경계 및 말초신경질환, 근골격계 이상 등으로 여러 장애가 있는 환자에게 다양한 재활 프로그램을 실시해 주어진 환경에서 신체적, 정신적, 사회적 능력을 최대한 회복할 수 있도록 돕는 진료과입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>경직, 연하장애, 뇌병변, 척수손상, 척추측만증, 심장재활, 호흡재활, 전정재활, 소아재활, 소아보행장애(안짱다리, 평발), 사경, 림프부종, 사두증</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>

                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 재활의학과는 심장질환자의 심장 회복 및 재발 방지를 위한 심장재활치료와 더불어 만성폐쇄성폐질환과 근육병 환자를 위한 호흡재활, 림프부종 재활치료, 어지럼증 치료를 위한 전정재활 등 다양한 재활치료를 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">재활치료</h4>
                        
                    </div>
                </div>
                <div class="depth__wrap">
                            <div class="depth__text">
                                <div class="table-cont" style="margin-top:0;">
                                    <ul class="table-list type03">
                                        <li class="table-item">
                                            <div class="item tit">뇌졸중 재활</div>
                                            <div class="item">
                                               뇌졸중 환자의 빠른 회복 및 후유증 최소화를 위해 침상 운동 및 다면적인 감각 자극을 통한 조기 재활치료를 시행하며, 이후 침대를 벗어나 기립자세를 유도하는 프로그램을 통해 종국적으로는 스스로 걸을 수 있도록 유도합니다. 일상에서의 독립적인 활동 수행을 위해 상지기능과 인지기능 회복을 돕는 작업치료를 시행하며, 언어기능에 현저한 장애를 가진 경우 빠른 회복을 위해 언어치료를 시행합니다.
                                            </div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">심장 재활</div>
                                            <div class="item">2018년 지역 최초로 심장재활치료실을 개설하여 운영 중이며, 심장질환으로 시술 등을 받은 환자를 위해 심장기능을 회복시켜 다시 정상적인 건강한 삶을 살 수 있도록 돕는 재활치료를 실시합니다. 심장병 위험인자의 관리를 통해 재발 예방 및 수명 연장을 기대할 수 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">호흡재활</div>
                                            <div class="item">만성폐쇄성폐질환 등 호흡기 환자를 위한 호흡재활을 실시하고 있으며, 호흡곤란의 호전, 불안 및 우울증 감소, 운동능력 및 삶의 질 향상을 목표로 치료합니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">전정 재활<br>(어지럼증 재활)</div>
                                            <div class="item">전정신경염 등으로 인한 어지럼증 환자의 증상 완화 및 전정기능 회복을 위한 재활치료를 실시하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">림프부종 재활</div>
                                            <div class="item">유방암이나 자궁암 수술 후 발생하는 림프부종의 체계적인 관리 및 재활치료를 통해 암 환자의 삶의 질 향상에 기여하고 있습니다.</div>
                                        </li>

                                        <li class="table-item">
                                            <div class="item tit">비만수술환자 운동처방</div>
                                            <div class="item">비만수술 환자의 수술 후 관리를 위해 지역 최초로 비만 평가 및 운동처방, 지속적인 체중관리를 실시하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">경직 재활</div>
                                            <div class="item">뇌졸중이나 뇌성마비 후 발생하는 경직 치료를 위해 투약과 더불어 보조기와 재활 치료를 시행하며, 필요 시 국소적 경직 완화를 위한 보톨리눔 독소 주사요법을 실시하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">파킨슨병·파킨슨증후군 재활</div>
                                            <div class="item">파킨슨병이나 파킨슨증후군 등 신경퇴행성 질환 환자의 낙상예방을 위한 보행운동 및 연하장애 치료를 제공하고 있으며, 2020년 지역 최초로 파킨슨병 환자를 위한 재활체조를 개발해 보급하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">척추측만증 재활</div>
                                            <div class="item">척추측만증 환자의 경과관찰 및 운동교육, 보조기 치료를 실시하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">근육병·척수성근위축증<br>·근위축성측색경화증 재활</div>
                                            <div class="item">근육병 등 신경근육성질환의 합병증을 예방하고, 삶의 질 증진을 위해 관절 운동 및 연하장애치료, 호흡 재활 등을 실시하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">뇌졸중 이후 복시<br>및 동측 반맹 재활</div>
                                            <div class="item">2022년 지역 최초로 뇌졸중 이후 발생한 복시 및 동측 반맹 재활치료를 시행하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">연하장애 재활</div>
                                            <div class="item">연하장애 환자의 합병증 예방 및 삶의 질 향상을 위해 주기적인 연하장애검사를 통한 식이조절 및 재활치료를 시행하고 있으며, 지역 최초로 소아연하장애 환아를 위한 소아연하장애운동을 개발해 보급하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">소아 재활</div>
                                            <div class="item">뇌성마비를 비롯해 발달 지연 아동의 회복을 위한 물리·작업치료 등 재활치료를 실시하고 있으며, 말과 언어기능 증진을 위한 언어치료실을 운영하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit">사경 및 측경 클리닉</div>
                                            <div class="item">고개가 한쪽을 바라보거나 한쪽으로 기우는 소아를 위해 사경 및 측경에 대한 체계적인 진단 및 치료를 제공하고 있습니다.</div>
                                        </li>
                                        <li class="table-item">
                                            <div class="item tit" style="text-align:center;">소아보행장애<br>(안짱걸음/평발)</div>
                                            <div class="item">안짱걸음이나 평발을 보이는 소아의 자세교정 및 운동교육, 보조기 치료를 실시하고 있습니다.</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">재활치료 의료진·환자를 위한 다양한 영상 콘텐츠 제공</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 재활의학과는 파킨슨병환자를 위한 파티마체조, 소아연하장애 환자를 위한 파티마운동, 비만대사환자의 치료 및 재활 등 다양한 온라인 세미나를 개최해오고 있으며, 유튜브 채널을 통해 의료진과 환자들에게 도움이 될 수 있는 유익한 콘텐츠를 제공하고 있습니다. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">재활치료센터,</a>
                                <a href="javascript:;">사경·측경클리닉,</a>
                                <a href="javascript:;">심장재활클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>