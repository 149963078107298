<template>
    <!-- 외과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>외과는 수술을 통해 환자의 질병이나 상태를 치료하는 진료과입니다. 창원파티마병원 외과는 위·장관외과, 간담췌외과, 유방·갑상선외과, 항문대장외과, 탈장외과, 소아외과로 나눠 전문화된 진단 및 치료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>복강경 위암수술, 복강경 대장(직장)암수술, 항문질환, 복강경 담석(담낭염)수술, 간담도암, 췌장질환, 갑상선암, 유방암, 성인탈장(서혜부, 배꼽탈장, 절개탈장), 비만대사수술, 충수염, 게실증 기타 복강수술), 응급 및 외상 수술</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 외과에서는 24시간 365일 응급수술이 가능하며, 3D 복강경을 이용해 장관 혈류 및 복부혈관촬영, 담도촬영이 가능하여 보다 안전하고 정밀한 수술이 가능합니다. 특히 암환자의 경우 소화기내과, 혈액종양내과 등 관련 진료과와 신속한 협진을 통해 환자 맞춤형 치료가 가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">대장(직장)암 클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 외과에서는 대장(직장)암 클리닉을 통해 조기 대장(직장)암의 내시경적 치료부터 진행성 대장(직장)암의 수술 및 항암치료까지 소화기내과, 혈액종양내과, 병리과, 영상의학과 등 각 분야별 전문가의 협진을 통해 최적의 치료 계획을 설계하고, 환자의 종합적인 상태를 고려한 맞춤 치료를 제공하고 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">유방암 클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>유방암은 치료 방법이 다양하고, 환자의 상태에 따라 적합한 치료 방법이 달라질 수 있습니다. 유방암 환자는 다학제 진료를 통해 자신의 상태에 가장 적합한 치료 계획을 수립하는 것이 중요합니다. 창원파티마병원 유방암 클리닉은 유방암 환자의 진단 및 치료를 위해 외과, 병리과, 혈액종양내과, 영상의학과 등 다양한 분야의 의료진이 다학제 진료를 통해 환자 상태를 종합적으로 평가하고 치료 계획을 수립하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">비만대사수술 클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 외과에서는 비만환자의 효과적인 체중감량과 건강관리를 돕는 비만대사수술클리닉을 운영하고 있습니다. 위장관 구조의 변화를 통해 체중감소를 유도하고 식사량 및 식욕 조절을 위한 위소매절제술, 루와이 위 우회술 등을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">갑상선암 클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>갑상선암은 우리나라에서 발생률이 가장 높은 암 중 하나입니다. 갑상선암은 조기에 발견하면 완치율이 높은 편이지만, 증상이 없는 경우가 많아 조기검진이 매우 중요합니다. 우리 병원 갑상선암 클리닉은 갑상선암 환자의 진단과 치료를 위해 전문 의료진 및 최첨단 시설로 맞춤형 치료계획을 제공하며 환자 중심의 치료서비스와 정기적인 추적 관찰 및 관리를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">대장(직장)암 클리닉,</a>
                                <a href="javascript:;">유방암 클리닉,</a>
                                <a href="javascript:;">비만대사수술 클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>