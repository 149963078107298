<template>
    <div>
        <div class="text-box type1 info-text">
            <ul class="text-box__list">
                <li class="text-box__item">근거없는 비방 및 욕설 광고성 글은 관리자의 판단에 따라 무통보 삭제될 수 있습니다.</li>
                <li class="text-box__item">등록된 글은 마이페이지 > 나의 작성글 > 건강상담 에서 확인이 가능하며 승인 된 글은 수정이 불가합니다.</li>
                <li class="text-box__item">승인된 글은 타인이 열람 가능하므로 작성 시 개인정보는 노출되지 않게 유의해주시기 바랍니다.</li>
                <li class="text-box__item">개인정보수정을 원할 경우 마이페이지 > 회원정보 > 회원정보수정 메뉴를 이용해주시기 바랍니다.</li>
            </ul>
        </div>

        <div class="contents text-box type2">
            <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의 <span class="c-red">(필수)</span></h3>
            <div class="text-box__wrap">
                <p> 자료 수급예정 </p>
            </div>
        </div>
        <div class="agree-input">
            <label for="agree-y" class="agree-input__item">
                <input type="radio" id="agree-y" class="agree-input__radio" name="agree">
                <span class="icon"></span> 동의합니다
            </label>
            <label for="agree-n" class="agree-input__item"><input type="radio" id="agree-n" class="agree-input__radio" name="agree" checked="">
                <span class="icon"></span> 동의하지 않습니다
            </label>
        </div>

        <div class="contents input-form type1">
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>
            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">이름</div>
                        <div class="cont">이준용</div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">휴대전화</div>
                        <div class="cont">010-1234-5678</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>sms답변 수신여부</div>
                        <div class="cont radio">
                            <div class="input-form__radio">
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" value="수신">
                                    <span class="icon"></span> 수신
                                </label>
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio1" value="거부" checked>
                                    <span class="icon"></span> 거부
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item item2">
                    <div class="input-form__box">
                        <div class="tit">이메일</div>
                        <div class="cont">hahaha0800</div>
                    </div>
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>이메일답변 수신여부</div>
                        <div class="cont radio">
                            <div class="input-form__radio">
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio2" value="수신">
                                    <span class="icon"></span> 수신
                                </label>
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio2" value="거부" checked>
                                    <span class="icon"></span> 거부
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>공개여부</div>
                        <div class="cont radio">
                            <div class="input-form__radio">
                                <label class="input-item">
                                    <input type="radio" class="radio" name="radio3" value="본인">
                                    <span class="icon"></span> 본인
                                </label><label class="input-item">
                                    <input type="radio" class="radio" name="radio3" value="보호자">
                                    <span class="icon"></span> 보호자
                                </label>
                                <p class="present"><span class="c-red">* </span>비공개 글은 마이페이지 > 나의 작성글 > 건강상담에서 확인 가능합니다</p>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="input-form__item">
                    <div class="input-form__box address">
                        <div class="tit"><span class="c-red">* </span>제목</div>
                        <div class="cont">
                            <div class="cont-item"><input type="text" class="cont__text-input"></div>
                        </div>
                    </div>
                </li>

                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>내용</div>
                        <div class="cont"><textarea name="" id=""></textarea></div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn">취소</a>
            <a href="javascript:;" class="btn blue">등록</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>