<template>
    <div class="spinner-div" style="color:#1e4e95;">
      <q-spinner-dots color="#1e4e95" size="5em"/>
    </div>
</template>


<script>
import {QSpinnerDots} from 'quasar'
export default {
    components: {
        QSpinnerDots
    },
    props: {
    },
    data() {
        return {
            
        }
    },
    watch: {
    },
    created() {
    },
    methods: {
        
    }
}
</script>
