<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">약관동의</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">본인인증</div>
            </li>
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">회원정보 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">회원가입 완료</div>
            </li>
        </ul>
    </div>

    <div class="contents join-agree">

        <div class="join-agree__total">
            <div class="agree-input">
                <label class="join-agree__total-input">
                    <input type="checkbox" class="agree-input__radio">
                    <span class="icon"></span> 서비스 전체 약관에 동의합니다.
                </label>
            </div>
        </div>

        <!-- 이용약관 -->
        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">이용약관 <span class="c-red">(필수)</span></h3>
                <div class="text-box__wrap">
                    <p> 자료 수급예정 </p>
                </div>
            </div>
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree1">
                    <span class="icon"></span> 동의합니다
                </label>
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree1" checked>
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>
        </div>

        <!-- 개인정보 수집 및 이용 -->
        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">개인정보 수집 및 이용 <span class="c-red">(필수)</span></h3>
                <div class="text-box__wrap">
                    <p> 자료 수급예정 </p>
                </div>
            </div>
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree2">
                    <span class="icon"></span> 동의합니다
                </label>
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree2" checked>
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>
        </div>


        <!-- 수집하는 개인정보의 항목 및 수집방법 -->
        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">수집하는 개인정보의 항목 및 수집방법 <span class="c-red">(필수)</span></h3>
                <div class="text-box__wrap">
                    <p> 자료 수급예정 </p>
                </div>
            </div>
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree3">
                    <span class="icon"></span> 동의합니다
                </label>
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree3" checked>
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>
        </div>

        <!-- 개인정보의 보유 및 이용기간  -->
        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">개인정보의 보유 및 이용기간 <span class="c-red">(필수)</span></h3>
                <div class="text-box__wrap">
                    <p> 자료 수급예정 </p>
                </div>
            </div>
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree4">
                    <span class="icon"></span> 동의합니다
                </label>
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree4" checked>
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>
        </div>

        <!-- 수집하는 개인정보 및 마케팅 및 광고활용  -->
        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">수집하는 개인정보 및 마케팅 및 광고활용 <span class="c-gray">(선택)</span></h3>
                <div class="text-box__wrap">
                    <p> 자료 수급예정 </p>
                </div>
            </div>
            <div class="agree-input">
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree5">
                    <span class="icon"></span> 동의합니다
                </label>
                <label class="agree-input__item">
                    <input type="radio" class="agree-input__radio" name="agree5" checked>
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>
        </div>


        <div class="contents join-agree__total">
            <div class="agree-input">
                <label class="join-agree__total-input">
                    <input type="checkbox" class="agree-input__radio">
                    <span class="icon"></span> 서비스 전체 약관에 동의합니다.
                </label>
            </div>
        </div>


        <div>
            <div class="contents text-box type2">
                <h3 class="text-box__tit">동의거부시 불이익에 관한 사항</h3>
            </div>
            <div class="text-box type1 info-text mt20">
                <ul class="text-box__list">
                    <li class="text-box__item">귀하는 위 항목에 대하여 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다.</li>
                    <li class="text-box__item">다만, 수집하는 개인정보는 원활한 서비스 제공을 위해 필요한 최소한의 기본정보로서, 동의를 거부하실 경우에는 회원에게 제공되는 서비스 이용에 제한될 수 있음을 알려드립니다.</li>
                </ul>
            </div>
        </div>


        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn">가입취소</a>
            <a href="javascript:;" class="btn blue">다음단계</a>
        </div>


    </div>


</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>