<template>
    <!-- 신경과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경과는 우리 몸의 중추신경과 말초신경으로 이루어진 신경계와 관련한 신체적 질병의 진단 및 치료를 담당하는 진료과입니다. 창원파티마병원 신경과에서는 치매 등의 뇌기능장애 진단을 위한 신경심리검사실을 운영하고 있으며, PET-CT를 이용해 알츠하이머병(아밀로이드 PET) 및 파킨슨병(뇌 양전자 단층촬영)의 조기 발견과 진단을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>치매(알츠하이머, 혈관성), 기억장애(경도인지장애), 행동신경학, 뇌졸중, 파킨슨병, 두통, 어지럼증, 손떨림, 말초신경질환 및 척수질환, 급성기 뇌졸중, 만성 뇌졸중(진단/치료/예방), 청장년기 뇌졸중, 뇌혈관질환, 경동맥질환, 뇌경동맥 혈관내시술(스텐트, 혈전제거술), 뇌전증</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">정확한 진단과 치료를 위한 뇌신경센터 검사실</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>조용하고 쾌적한 환경에서 다양한 신경계 검사를 받을 수 있도록 뇌신경센터 검사실을 독립적인 공간으로 운영하고 있습니다. 2002년 경남 도내 최초로 개설하여 운영 중인 신경심리검사실은 3명의 심리사가 직접 치매 정밀검사를 실시해 치매 진단과 치료에 있어 양질의 진료를 제공하고 있습니다. 그 외에도 경동맥 초음파검사, 경두개 초음파검사를 통한 뇌혈관의 비침습적 검사도 활발히 실시하고 있으며, 아울러 뇌파검사, 신경전도검사, 근전도검사, 자율신경계검사도 진행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">도내 최초, 인지장애 및 치매 환자를 위한 뇌 인지훈련 프로그램</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>2021년부터 인지장애 및 치매 환자를 대상으로 뇌 인지훈련 프로그램을 실시하고 있습니다. 인지훈련은 대상자의 인지기능 및 연령을 고려해 4~5인의 소규모 그룹을 구성, 수행 능력에 따라 난이도를 조절하여 뇌의 각 영역을 활발히 사용할 수 있도록 고안된 과제들을 의료진 지도하에 수행합니다. 인지훈련을 통해 성취감을 경험할 뿐만 아니라 매일 다른 주제로 쓰는 일기를 통해 기억력과 언어적 인지능력을 향상시키며, 그룹 구성원과의 소통으로 우울감을 감소시키고, 사회성 증가에도 효과적입니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">급성기 치료부터 회복까지, 뇌졸중 집중치료실</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>2010년 경남 지역 최초로 뇌졸중 집중치료실을 운영해 독립된 공간에서 뇌졸중 환자에게 체계적이고 전문적인 진료 서비스를 제공하고 있습니다. 특히 뇌졸중 환자의 후유증 최소화를 위해 신속한 재활 평가를 통한 조기 재활치료를 실시하고 있으며, 전담간호사가 24시간 상주해 혈압, 산소포화도, 심전도 등 수시로 변화하는 환자 상태를 모니터링하여 빠르게 대처하고 있습니다. 이를 통해 환자의 안정적인 상태 유지와 입원 기간 단축이 가능하며, 합병증 예방을 통해 더 나은 예후와 사망률 감소를 기대할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">재관류 치료 뇌졸중센터 인증 병원</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원은 2022년 3월 대한뇌졸중학회로부터 재관류 치료 뇌졸중센터 인증을 받았습니다. 이는 뇌졸중센터 기준에 부합하는 뇌졸중 진료 프로세스를 구축해 전문 치료를 제공하고, 뇌졸중 전문치료실, 응급실, 중환자실의 의료진 구성, 시설 및 운영체계 등이 우수하게 운영되고 있음을 의미합니다.</p>
                                <br>
                                <p>그 밖에도 창원파티마병원은 뇌질환 진단에 가장 중요한 MRI 검사와, 파킨슨병, 알츠하이머병 진단을 위한 PET검사(FP CIT-PET, 아밀로이드 PET)를 위한 장비를 보유하고 있으며, 전국적인 대규모 임상 연구에도 매년 활발히 참여해 지역 환자 및 보호자들이 많은 혜택을 받을 수 있도록 지속적으로 참여하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">뇌혈관센터,</a>
                                <a href="javascript:;">기억장애·치매클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>