<template>
  <div class="centerProgram myProgram">
    <div class="contents" v-if="currentStep === 0">
      <div class="myProgram_intro">
        <p class="myProgram_intro_top">
          내게 맞는 <b class="blue__title">검진프로그램</b> 찾기
        </p>
        <p class="myProgram_intro_middle">
          안내에 따라 자신의 요구에 맞는 정보를 선택하시면 창원파티마병원에서
          운영하는 다양한<br />프로그램 중
          <span class="hlightCenter">고객님께 꼭 맞는 검진프로그램</span>을
          찾아드립니다.
        </p>
        <div class="btn-wrap center">
          <a href="javascript:;" class="btn mint" @click="nextStep">시작하기</a>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 1">
      <div class="myProgram_step myProgram_step01">
        <div class="step_header">
          <h3>Step01</h3>
          <p>본인의 성별을 선택 해 주세요.</p>
        </div>
        <div class="step_main_box">
          <a href="javascript:;" class="step_men" @click="nextStep">
            <img src="/resource/icon/step_men.png" alt="이미지" />
            <h4 class="blue__title">남성</h4>
          </a>
          <a href="javascript:;" class="step_women" @click="nextStep">
            <img src="/resource/icon/step_women.png" alt="이미지" />
            <h4>여성</h4>
          </a>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 2">
      <div class="myProgram_step myProgram_step02">
        <div class="step_header">
          <h3>Step02</h3>
          <p>건강진단을 받고자 하는 동기를 선택하여 주세요.</p>
        </div>
        <div class="step_desc_box">
          <div class="agree-input">
            <label for="agree-y" class="agree-input__item" :class="{'checked-label': isChecked === 'agree-y'}">
              <input
                type="radio"
                id="agree-y"
                class="agree-input__radio"
                name="agree"
                value="agree-y"
                v-model="isChecked"/>
              <span class="icon"></span> 현재는 별 이상 없지만 전반적인 건강상태를 체크하고 싶다.
            </label>
          </div>
          <div class="agree-input">
            <label for="agree-n" class="agree-input__item" :class="{'checked-label': isChecked === 'agree-n'}">
              <input
                type="radio"
                id="agree-n"
                class="agree-input__radio"
                name="agree"
                value="agree-n"
                v-model="isChecked"/>
              <span class="icon"></span> 건강진단을 받고자 하는 동기를 선택하여 주세요.
            </label>
          </div>

          <div class="contents step_move_btn">
            <a href="javascript:;" @click="prevStep">
              <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
            </a>
            <a href="javascript:;" @click="nextStep" :class="{ disabled: !isChecked }">
              <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 3">
      <div class="myProgram_step myProgram_step03">
        <div class="step_header">
          <h3>Step03</h3>
          <p>연령을 선택해 주세요.</p>
        </div>
        <div class="step_desc_box">
          <div class="agree-input">
            <label for="age01" class="agree-input__item" :class="{'checked-label': isChecked === 'age01'}">
              <input
                type="radio"
                id="age01"
                class="agree-input__radio"
                name="agree"
                value="age01"
                v-model="isChecked"/>
              <span class="icon"></span> 19세 이하
            </label>
          </div>
          <div class="agree-input">
            <label for="age02" class="agree-input__item" :class="{'checked-label': isChecked === 'age02'}">
              <input
                type="radio"
                id="age02"
                class="agree-input__radio"
                name="agree"
                value="age02"
                v-model="isChecked"/>
              <span class="icon"></span> 20대 ~ 30대
            </label>
          </div>
          <div class="agree-input">
            <label for="age03" class="agree-input__item" :class="{'checked-label': isChecked === 'age03'}">
              <input
                type="radio"
                id="age03"
                class="agree-input__radio"
                name="agree"
                value="age03"
                v-model="isChecked"/>
              <span class="icon"></span> 40대 ~ 60대
            </label>
          </div>
          <div class="agree-input">
            <label for="age04" class="agree-input__item" :class="{'checked-label': isChecked === 'age04'}">
              <input
                type="radio"
                id="age04"
                class="agree-input__radio"
                name="agree"
                value="age04"
                v-model="isChecked"/>
              <span class="icon"></span> 60대 이상
            </label>
          </div>

          <div class="contents step_move_btn">
            <a href="javascript:;" @click="prevStep">
              <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
            </a>
            <a href="javascript:;" @click="nextStep" :class="{ disabled: !isChecked }">
              <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 4">
      <div class="myProgram_step myProgram_step04">
        <div class="step_header">
          <h3>Step04</h3>
          <p>원하는 검진형태를 선택 하십시오.</p>
        </div>
        <div class="step_desc_box">
          <div class="agree-input">
            <label for="agree-y02" class="agree-input__item" :class="{'checked-label': isChecked === 'agree-y02'}">
              <input
                type="radio"
                id="agree-y02"
                class="agree-input__radio"
                name="agree"
                value="agree-y02"
                v-model="isChecked"/>
              <span class="icon"></span> 생애주기 건강진단을 원한다.
            </label>
          </div>
          <div class="agree-input">
            <label for="agree-n02" class="agree-input__item" :class="{'checked-label': isChecked === 'agree-n02'}">
              <input
                type="radio"
                id="agree-n02"
                class="agree-input__radio"
                name="agree"
                value="agree-n02"
                v-model="isChecked"/>
              <span class="icon"></span> 특별한 가족력 및 생활습관에 따른 특정질환을 원한다.
            </label>
          </div>

          <div class="contents step_move_btn">
            <a href="javascript:;" @click="prevStep">
              <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
            </a>
            <a href="javascript:;" @click="nextStep" :class="{ disabled: !isChecked }">
              <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 5">
      <div class="myProgram_step myProgram_step05">
        <div class="step_header">
          <h3>Step05</h3>
          <p>당일 혹은 숙박 여부를 선택하십시오.</p>
        </div>
        <div class="step_desc_box">
          <div class="agree-input">
            <label for="day01" class="agree-input__item" :class="{'checked-label': isChecked === 'day01'}">
              <input
                type="radio"
                id="day01"
                class="agree-input__radio"
                name="agree"
                value="day01"
                v-model="isChecked"/>
              <span class="icon"></span> 당일
            </label>
          </div>
          <div class="agree-input">
            <label for="day02" class="agree-input__item" :class="{'checked-label': isChecked === 'day02'}">
              <input
                type="radio"
                id="day02"
                class="agree-input__radio"
                name="agree"
                value="day02"
                v-model="isChecked"/>
              <span class="icon"></span> 숙박
            </label>
          </div>

          <div class="contents step_move_btn">
            <a href="javascript:;" @click="prevStep">
              <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
            </a>
            <a href="javascript:;" @click="nextStep" :class="{ disabled: !isChecked }">
              <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="contents" v-if="currentStep === 6">
      <div class="myProgram_step myProgram_stepLast">
        <div class="step_header">
          <h3>고객님께 추천 드리는 검진</h3>
        </div>
        <div class="step_desc_box">
          <!-- 추천 코스 추가 내용 -->
          <div class="step_box_last">
            <h3><span class="mint__title">그린코스 + 전립선 초음파 & 호르몬 검사</span>를 추천합니다.</h3>
            <p>주요 질환 조기진단을 위한 그린코스에 전립선 초음파, 호르몬검사 추가로 남성질환 진단 프로그램입니다.</p>
            <div class="btn-wrap center">
              <a href="javascript:;" class="btn">자세한 내용보기</a>
              <a href="javascript:;" class="btn blue-line">건강검진 예약 바로가기</a>
            </div>
          </div>
          <!-- 추천 코스 추가 내용 -->

          <!-- 추천 코스 추가 내용 -->
          <div class="step_box_last">
            <h3><span class="mint__title">남성정밀 검사</span>를 추천합니다.</h3>
            <p>남성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.</p>
            <div class="btn-wrap center">
              <a href="javascript:;" class="btn">자세한 내용보기</a>
              <a href="javascript:;" class="btn blue-line">건강검진 예약 바로가기</a>
            </div>
          </div>
          <!-- 추천 코스 추가 내용 -->

          <div class="contents step_move_btn">
            <a href="javascript:;" @click="prevStep">
              <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
            </a>
          </div>
        </div>
      </div>

      <div class="btn-wrap center contents">
        <a href="javascript:;" class="btn gray">처음으로</a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 0,
      isChecked: ''
    };
  },
  methods: {
    nextStep() {
      // 라디오 버튼이 필요한 스텝일 때 체크 상태 확인
      if (this.requiresCheck && !this.isChecked) {
        alert('하나를 선택해주세요.');
        return;
      }
      if (this.currentStep < 6) {
        this.currentStep++;
        this.isChecked = ''; // 다음 스텝으로 넘어갈 때 isChecked 초기화
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    }
  },
  computed: {
    requiresCheck() {
      // 라디오 버튼이 필요한 스텝 확인
      return this.currentStep === 2 || this.currentStep === 3 || this.currentStep === 4 || this.currentStep === 5;
    }
  }
};
</script>
