const memberStore = {
    state: {
        prevUrl: null,
    },
    getters: {
        getPrevUrl: state => state.prevUrl
    },
    mutations: {
        setPrevUrl(state, url){
            state.prevUrl = url
        }
    },
}

export default memberStore