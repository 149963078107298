<template>
    <div class="login">
        <div class="sub-text dp2">홈페이지의 다양한 정보와 맞춤 서비스를 이용하시려면 로그인이 필요합니다.</div>
        <div class="login__inner">
            <div class="contents login__input">
                <label class="login__id">
                    <input type="text" placeholder="아이디를 입력해 주세요.">
                </label>
                <label class="login__password">
                    <input type="text" placeholder="비밀번호를 입력해 주세요.">
                    <div class="icon"></div>
                </label>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue">아이디 찾기</a></div>
                <ul class="login__list">
                    <li class="login__item">
                        <a href="javascript:;">아이디 찾기</a>
                    </li>
                    <li class="login__item">
                        <a href="javascript:;">비밀번호 찾기</a>
                    </li>
                    <li class="login__item">
                        <a href="javascript:;">회원가입</a>
                    </li>
                </ul>
            </div>
            <div class="contents login__sns">
                <p>SNS 계정으로 로그인하기</p>
                <div class="btn-wrap">
                    <a href="javascript:;" class="btn naver">아이디 찾기</a>
                    <a href="javascript:;" class="btn kakao">아이디 찾기</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>