<template>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home">
                    <router-link :to="`/deptHp/${$route.params.deptCd}/${$route.params.hpUrl}/main.do`" class="Location__item--btn">
                        <img src="@/assets/resource/icon/Location_home.png" alt="이미지">
                    </router-link>
                </li>
                <template v-for="(item, index) in menuItems" :key="index">
                    <li class="Location__item" :class="{ 'active': activeIndex === index }" v-if="index < 3">
                        <a href="javascript:;" class="Location__item--btn" @click="toggleActive($event, index)">
                            <p>{{ item.label }}</p>
                            <span></span>
                        </a>
                        <div class="Location__item--Layer">
                            <ul>
                                <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex" @click="toggleActive($event, index)">
                                    <component :is="subItem.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="subItem.menuUrl" :to="subItem.menuUrl.indexOf('/doctor.do') > -1 ? `/deptHp/${deptCd}/${hpUrl}${subItem.menuUrl}?deptClsf=C&deptCd=${deptCd}` : `/deptHp/${deptCd}/${hpUrl}${subItem.menuUrl}`">{{ subItem.menuName }}</component>
                                </li>
                            </ul>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
  </div>
</template>


<script>
export default {
    props: {
        menuList: Array
    },
    data() {
        return {
            menuItems: [],
            activeIndex: -1,
            activeMenu: {},
            deptCd: this.$route.params.deptCd,
            hpUrl: this.$route.params.hpUrl,
        };
    },
    watch: {
        menuList(val){
            let path = this.$commonUtils.getMenuPath().replace(`/deptHp/${this.deptCd}/${this.hpUrl}`, '')

            const list = val.sort((a, b) => {
                return b.depth - a.depth
            })

            // menuList의 url과 현재 url이 같으면 activeMenu에 저장, menuType이 B,C인 메뉴만 적용
            let menuNo = ''
            for(let i = 0; i < list.length; i++){
                if(list[i].menuUrl == path && list[i].useYn == 'Y'){
                    this.activeMenu = list[i]
                    if(this.activeMenu.depth == 4){
                        menuNo = this.activeMenu.parentMenuNo
                        this.activeMenu = {}
                    }
                    if(this.activeMenu.menuType){
                        break
                    }
                }
                if(menuNo != '' && list[i].menuNo == menuNo) this.activeMenu = list[i]
            }

            // activeMenu의 depth를 구하고, 상위 메뉴들도 설정해준다.
            let depth = this.activeMenu.depth

            this.menuItems = []

            for(let i = depth - 1; i >= 0; i--){
                let menuItem = {subItems: []}

                menuItem.label = this.activeMenu.menuName

                for(let j = 0; j < this.menuList.length; j++){
                    if(this.menuList[j].parentMenuNo == this.activeMenu.parentMenuNo && this.menuList[j].gnbYn == 'Y'){
                        menuItem.subItems.push(this.menuList[j])
                    }
                }

                this.menuItems[i] = menuItem

                for(let j = 0; j < this.menuList.length; j++){
                    if(this.menuList[j].menuNo == this.activeMenu.parentMenuNo){
                        this.activeMenu = this.menuList[j]
                        break
                    }
                }

            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleActive(event,index) {
            const target = event.currentTarget;
            const parentLayer = target.closest('.Location__item').querySelector('.Location__item--Layer');
            if (this.activeIndex === index) {
                // 두 번째 클릭 시 높이를 0으로 설정
                parentLayer.style.height = '0';
                this.activeIndex = -1; // 비활성화
            } else {
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
                const ulHeight = parentLayer.querySelector('ul').offsetHeight + 'px';
                parentLayer.style.height = ulHeight;
                this.activeIndex = index; // 활성화
            }
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                // 메뉴 외부를 클릭하면 엑티브 제거
                this.activeIndex = -1;

                // .Location__item--Layer의 높이를 0으로 설정
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
            }
        }

    }
}
</script>
