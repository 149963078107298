<template>
    <div class="first_reserve_popup name_certify_popup" v-show="popupVisible">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>실명인증</h4>
                <a href="javascript:;" @click="hidePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="contents first_desc">
                <div class="text-box type1 info-text">
                    <p>창원파티마병원은 의료법 시행령 제42조 2, 국민건강보험법 제 12조에 의거하여 진료예약 환자 확인을
                        <br>위한 <b>고유식별번호</b>를 수집하고 있습니다. </p>

                </div>
            </div>

            <div class="contents text-box type2 first_info_box">
                <h3 class="text-box__tit">고유식별정보 처리 동의
                    <span class="c-red">(필수)</span>
                </h3>
                <div class="text-box__wrap">
                    <p>창원파티마병원은 본인확인과 관련하여 고객으로부터 수집한 개인정보를 이용할 때에는 ‘개인정보보호법’에 따라 고객의 동의를 얻어야 합니다.</p>
                    <h3>제1조 개인정보의 이용목적</h3>
                    <p>병원은 홈페이지에 회원가입하지 않은 고객을 대상으로 전화, 인터넷 등을 통한 병원 내 진료, 검사 예약 시 건강보험 가입여부, 건강검진 대상여부 등 일정 사항 확인을 목적으로 주민등록번호를 수집합니다.
                    </p>
                    <h3>제2조 수집 개인정보의 항목 및 수집방법
                    </h3>
                    <p>병원은 전화, 인터넷 등을 통해 고객의 이름, 주민등록번호를 수집합니다.
                    </p>
                    <h3>제3조 개인정보 보유 및 이용기간
                    </h3>
                    <p>이용목적이 달성되면 지체 없이 파기하며, 별도 보관을 하지 않습니다. <br><br>
                        상기 내용과 같이 개인정보 이용에 동의합니다.</p>
                </div>
            </div>
            <div class="agree-input">
                <label for="agree-y" class="agree-input__item">
                    <input type="radio" id="agree-y" class="agree-input__radio" name="agree" v-model="agree" value="Y">
                    <span class="icon"></span> 동의합니다 </label>
                <label for="agree-n" class="agree-input__item">
                    <input type="radio" id="agree-n" class="agree-input__radio" name="agree" v-model="agree" value="N">
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>

            <div class="contents first_input_box">
                <div class="input-form type1">
                    <p class="input-form__top-text">
                        <span class="c-red">* </span> 필수 입력항목
                    </p>
                    <ul class="input-form__list">
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>이름
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" v-model="memName" readonly>
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>주민등록번호
                                </div>
                                <div class="cont row">
                                    <input type="text" class="cont__text-input" placeholder="000000" maxlength="6" v-model="frrn" @input="this.$commonUtils.numberOnly">
                                    <span class="line">-</span>
                                    <input type="password" class="cont__text-input" placeholder="0000000" maxlength="7" v-model="brrn" @input="this.$commonUtils.numberOnly">
                                </div>
                            </div> 
                        </li>
                        <!-- 퍼블리싱필요 -->
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>휴대폰번호
                                </div>
                                <div class="cont row">
                                    <input type="text" class="cont__text-input" maxlength="3" v-model="hpNo1" @input="this.$commonUtils.numberOnly">
                                    <span class="line">-</span>
                                    <input type="text" class="cont__text-input" maxlength="4" v-model="hpNo2" @input="this.$commonUtils.numberOnly">
                                    <span class="line">-</span>
                                    <input type="text" class="cont__text-input" maxlength="4" v-model="hpNo3" @input="this.$commonUtils.numberOnly">
                                </div>
                            </div> 
                        </li>
                    </ul>
                </div>
            </div>

            <div class="certify_content">
                <p>미성년자 실명인증이 안될 경우 </p>
                <p>아래의 사이트에서 실명 등록을 먼저 해주시기 바랍니다.</p>
            </div>
            <div class="certify_content">
                <p>개인 실명등록 홈페이지 접속<span>(www.niceid.co.kr)</span>→ 실명확인 바로가기→ 해당페이지 실명등록 (실명등록 완료까지 약 24시간 소요) → 익일 창원파티마병원 홈페이지에서 실명인증 재시도</p>
            </div>


            <div class="btn-wrap center first_btn">
                <a href="javascript:;" class="btn" @click="hidePopup">취소</a>
                <a href="javascript:;" class="btn blue" @click="nameCheck">확인</a>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            popupVisible: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                agree: 'N',
                memName: this.$store.state.myInfoStore.memName,
                frrn: '',
                brrn: '',
                hpNo1: '',
                hpNo2: '',
                hpNo3: '',
                isDisabled: false,
            };
        },
        methods: {
            hidePopup() {
                this.$emit('hide-popup');
                this.init()

                if(this.$route.path.indexOf('/deptHp/') > -1){
                    this.$router.push({name: 'deptHpMain'});
                }else{
                    if(this.$route.path.indexOf('/mypage/') > -1){
                        if(this.$route.path.indexOf('/mypage/mypage.do') > -1){
                            this.$store.commit("getShowMypageNameCheckModal", false);
                        }
                    }else{
                        this.$router.push('/');
                    }
                }
            },
            nameCheck(){
                if(this.isDisabled){
                    alert('처리 중입니다.')
                    return
                }

                this.isDisabled = true

                if(this.agree !== 'Y'){
                    alert("고유식별정보 처리 동의가 필요합니다.");
                    this.isDisabled = false
                    
                    return;
                }

                if(this.memName == '' || this.frrn == '' || this.brrn == '' || this.hpNo1 == '' || this.hpNo2 == '' || this.hpNo3 == ''){
                    alert("개인정보 입력이 필요합니다.");
                    this.isDisabled = false

                    return;
                }

                if(!(this.frrn.length == 6) || !(this.brrn.length == 7)){
                    alert("주민번호 길이가 맞지 않습니다.");
                    this.isDisabled = false
                    
                    return
                }

                this.axios.get(this.api.token.nameCheck, {
                    params: {
                        enc_name: this.memName,
                        enc_jumin_id: this.frrn + this.brrn
                    }
                }).then(response => {
                    this.isDisabled = false
                    const data = response.data.dataBody
                    if(data.rsp_cd == 'NOT LOGIN'){
                        alert('로그인이 해제되었습니다. 다시 로그인을 해주시길 바랍니다.')
                        this.$router.push('/member/login.do')
                        return
                    }
                    if(data.rsp_cd != 'P000'){
                        alert('오류가 발생했습니다.')
                        return
                    }
                    if(data.result_cd == '1'){
                        var patInfo = {
                            hngnm: this.memName,
                            rrgstno1: this.frrn,
                            rrgstno2: this.brrn,
                            mpphontel: this.hpNo1+this.hpNo2+this.hpNo3,
                        };
                        if(patInfo.hngnm == '' || patInfo.rrgstno1 == '' || patInfo.rrgstno2 == '' || patInfo.mpphontel == ''){
                            alert("오류가 발생했습니다. 정보가 올바르지 않습니다.");
                            location.reload(true)

                            return;
                        }
                        this.axios.get(this.api.appointment.getPatInfo, {
                            params: patInfo
                        }).then(res => {
                            if(res.data.pid == 'NOT LOGIN'){
                                alert('로그인이 해제되었습니다. 다시 로그인을 해주시길 바랍니다.')
                                this.$router.push('/member/login.do')
                                return
                            }
                            if(res.data.pid == 'NULL'){
                                alert("오류가 발생했습니다. 정보가 올바르지 않습니다.");
                                location.reload(true)

                                return;
                            }

                            this.setMemPatId(res.data.pid);

                            this.$emit('hide-popup');
                            this.init()
                        });
                    }else if(data.result_cd == '2'){
                        alert('성명이 일치하지 않습니다. 다시 확인하시고 입력해주세요.');
                    }else if(data.result_cd == '3'){
                        alert('당사에 성명을 보유하고 있지 않습니다.');
                    }else if(data.result_cd == '7'){
                        alert('명의도용으로 인해 차단된 상태입니다.');
                    }else{
                        alert('부정사용 의심 정보로 인해 차단된 상태입니다.');
                    }
                }).catch(err => {
                    this.isDisabled = false
                });
            },
            setMemPatId(memPatId){
                var memberParam = {};
                memberParam.memPatId = memPatId

                this.$store.commit('setMemPatId', memPatId)

                this.axios({
                    method:"post",
                    url: this.api.member.updateMember, 
                    params: memberParam
                }).then(response => {
                    console.log(response.data);
                    console.log(this.$store.state.myInfoStore);
                });
            },
            init(){
                for (let key in this.$data) {
                    this.$data[key] = '';
                }
                this.agree = 'N'
            }
        },
    };
</script>