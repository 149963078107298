<template>
    <!-- 영상의학과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>영상의학과는 방사선을 이용한 일반 단순촬영, 조영제를 이용한 위장관 및 비뇨생식기계 특수촬영을 비롯해 전산화 단층촬영, 디지털 혈관촬영 등 정교한 고해상도 영상검사, 초음파검사 및 자기공명영상 등 정확한 검사를 시행합니다. 이를 통해 환자의 질병을 찾아 정확한 진단을 내리고 치료 및 수술에 도움을 주며, 추적 검사로 질병의 상태를 정확히 판단하게 해주는 전문 의학 분야입니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경두경부영상의학, 흉부영상진단, 심장영상의학, 인터벤션 영상의학, 혈관내 치료, 영상유도하 진단 및 치료, 복부영상의학, 근골격, 유방, 복부영상의학, 신경·두경부 영상의학, 흉부, 심장</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 영상의학과는 최첨단 의료장비를 이용해 다양한 질병의 정확한 진단과 최소 침습적 치료를 시행하고 있습니다.</p>
                                <br>
                                <p>1969년 병원 개원과 함께 시작된 영상의학과는 꾸준한 장비 업그레이드와 기술 발전을 통해 환자들에게 최상의 의료 서비스를 제공하고 있습니다. 현재 최첨단 의료장비로는 3.0T MRI, 다중채널 & dual energy CT, 탄성 초음파, 디지털 촬영(DR) 등을 갖추고 있으며, 이를 통해 다양한 영상 검사와 중재적 시술을 실시하고 있습니다. 특히 PACS의 도입으로 영상 검사와 결과의 신속한 전송과 분석이 가능하며, 고해상도 영상 장비를 통해 더욱 정밀한 진단과 치료를 수행하고 있습니다. 더불어 AI 프로그램을 활용한 영상 재구성과 판독으로 더욱 정확하고 효율적인 진단이 가능합니다. 촬영된 영상은 각 세부전공 전문의가 해당 분야를 담당하여 더욱 체계적인 영상판독이 이루어지고 있으며, 임상진료 파트와의 유기적인 관계 속에 질병의 정확한 진단을 위해 최선을 다하고 있습니다.</p>
                                <p>앞으로도 창원파티마병원 영상의학과는 환자의 편안함과 신속한 진료를 최우선으로, 의료진의 전문성과 최첨단 장비를 통한 최상의 의료 서비스를 제공하겠습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">심혈관센터</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>