<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item">
                    <router-link to="/mypage/board/thanks.do" v-slot="thanks">
                        <button type="button" class="tab-menu__btn" @click="thanks">칭찬합니다</button>
                    </router-link>
                </li>
                <li class="tab-menu__item active">
                    <router-link to="/mypage/board/suggest.do" v-slot="suggest">
                        <button type="button" class="tab-menu__btn" @click="suggest">고객의 소리</button>
                    </router-link>
                </li>
                <li class="tab-menu__item">
                    <router-link to="/mypage/board/counsel.do" v-slot="counsel">
                        <button type="button" class="tab-menu__btn" @click="counsel">건강상담</button>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

    <div class="contents">
        <ul>
            <li class="c-gray5">접수 상태의 글은 수정 가능합니다. 단, 제목, 내용만 수정 가능합니다.</li>
            <li class="c-gray5">제목, 내용 외 다른 수정을 원하시면, 해당 글 삭제 후 <b class="c-blue" style="cursor: pointer;" @click="this.$router.push('/hospital/customer-service/voice-customer.do')">이용안내 > 고객 서비스 > 고객의 소리</b>에서 신규 글을 등록해주시기 바랍니다.  </li>
        </ul>
    </div>


    <div class="contents input-form type1" v-if="pageMode == 'detail'">
        <ul class="input-form__list">
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">제목</div>
                    <div class="cont">{{ articleInfo.title }}</div>
                </div>
            </li>
            <li class="input-form__item item2">
                <div class="input-form__box">
                    <div class="tit">진행상황</div>
                    <div class="cont">{{ articleInfo.item18 == 'R' ? '접수' : '답변완료' }}</div>
                </div>
                <div class="input-form__box">
                    <div class="tit">등록일자</div>
                    <div class="cont">{{ $commonUtils.timestampToDate(articleInfo.createdDt) }}</div>
                </div>
            </li>
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">답변등록여부</div>
                    <div class="cont">{{ articleInfo.item1 == 'Y' ? '이메일' : articleInfo.item2 == 'Y' ? '문자(SMS)' : '수신안함' }}</div>
                </div>
            </li>
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">환자와의 관계</div>
                    <div class="cont">{{ articleInfo.item3 }}{{ articleInfo.item3 == '보호자' ? `(${articleInfo.item5})` : '' }}</div>
                </div>
            </li>
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit">건의 유형</div>
                    <div class="cont">{{ articleInfo.item4 }}</div>
                </div>
            </li>
            <li class="input-form__item desc">
                <div class="input-form__box">
                    <div class="tit">내용</div>
                    <div class="cont desc" v-html="articleInfo.content"></div>
                </div>
            </li>
            <li class="input-form__item desc" v-if="articleInfo.item18 != 'R' && articleInfo.reply">
                <div class="input-form__box">
                    <div class="tit">답변</div>
                    <div class="cont desc" v-html="articleInfo.reply"></div>
                </div>
            </li>
            <li class="input-form__item desc" v-if="articleInfo.attachBag && Object.keys(articleInfo.attachBag).length > 0">
                <div class="input-form__box">
                    <div class="tit">첨부파일</div>
                    <div class="cont desc" style="cursor:pointer;" @click="$commonUtils.fileDownload(articleInfo.attachBag.attach[0].attachNo, articleInfo.attachBag.attach[0].displayName)">{{ articleInfo.attachBag.attach[0].displayName }}</div>
                </div>
            </li>
        </ul>
    </div>

    <div class="contents input-form type1" v-if="pageMode == 'modify'">
        <ul class="input-form__list">
            <li class="input-form__item">
                <div class="input-form__box">
                    <div class="tit"><span class="c-red">* </span>제목</div>
                    <div class="cont"><input type="text" class="cont__text-input" v-model="articleInfo.title"></div>
                </div>
            </li>
            <li class="input-form__item item2">
                <div class="input-form__box">
                    <div class="tit">진행상황</div>
                    <div class="cont">{{ articleInfo.item18 == 'R' ? '접수' : '답변완료' }}</div>
                </div>
                <div class="input-form__box">
                    <div class="tit">등록일자</div>
                    <div class="cont">{{ $commonUtils.timestampToDate(articleInfo.createdDt) }}</div>
                </div>
            </li>
            <li class="input-form__item desc">
                <div class="input-form__box">
                    <div class="tit"><span class="c-red">* </span>내용</div>
                    <div class="cont desc"><textarea v-model="articleInfo.content"></textarea></div>
                </div>
            </li>
        </ul>
    </div>

    <div class="contents btn-wrap center">
        <a href="javascript:;" class="btn" @click="showModify" v-if="articleInfo.item18 == 'R' && pageMode == 'detail'">수정</a>
        <a href="javascript:;" class="btn red" @click="deleteArticle" v-if="pageMode == 'detail'">삭제</a>
        <a href="javascript:;" class="btn blue" @click="updateContent" v-if="pageMode == 'modify'">등록</a>
        <router-link to="/mypage/board/suggest.do" class="btn gray">목록</router-link>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                selectOn: false,
                articleInfo: {},
                attachList: '',
                boardNo: this.$route.query.boardNo | '',
                articleNo: this.$route.query.articleNo | '',
                pageMode: 'detail',

            };
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            getView() {
                this.axios
                    .get(this.api.article.view + this.boardNo + '/' + this.articleNo, {
                        params: {
                            instNo: 1,
                            articleNo: this.articleNo,
                            boardNo: this.boardNo
                        }
                    })
                    .then((res) => {
                        this.articleInfo = res.data
                        this.attachList = res.data.attachBag.attach;
                    });
            },
            showModify(){
                this.pageMode = 'modify';
            },
            deleteArticle() {
                if (confirm('삭제 후 복구하실 수 없습니다. 삭제하시겠습니까?')) {
                    const articleNo = this.articleInfo.articleNo
                    const articleNos = [articleNo]


                    this.axios.delete(`${this.api.article.view}${this.boardNo}`, {
                        params: {
                            boardNo: this.boardNo,
                            articleNos: articleNos.join(',')
                        }
                    }).then(res => {
                        this.$router.push('/mypage/board/suggest.do')
                    })
                }
            },
            updateContent(){
                if(!this.articleInfo.title){
                    alert('제목을 입력해 주세요.');
                    return
                }
                if(!this.articleInfo.content){
                    alert('내용을 입력해 주세요.');
                    return
                }
                if(confirm('글을 등록하시겠습니까?')){
                    this.axios({
                        method:"post",
                        url: this.api.mypage.articleUpdate, 
                        params: {
                            'boardNo' : this.boardNo
                            ,'articleNo' : this.articleNo
                            ,'title' : this.articleInfo.title
                            ,'content' : this.articleInfo.content
                        }
                    }).then(response => {
                        this.$router.push('/mypage/board/suggest.do')
                    });
                }
            },
        },
        mounted() {
            this.getView()
        },
        computed: {
            radioValue: {
                get() {
                    return (this.articleInfo.item1 === 'N' && this.articleInfo.item2 === 'N') ? 'Y' : '';
                },
            }
        }
    };
</script>
