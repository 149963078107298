<template>
    <div class="board__search m-column">

        <div class="board__input">
            <input type="text" id="Search_doc" ref="searchInput" placeholder="검색어를 입력하세요." v-model="searchKeywordText" @keyup.enter="searchEvent" v-if="!inSearch">
            <input type="text" id="Search_doc" ref="searchInput" placeholder="재 검색어를 입력해주세요." v-model="inSearchKeywordText" @keyup.enter="searchEvent" v-if="inSearch">
            <button type="button" class="board__input-btn" @click="searchEvent()"></button>
        </div>

        <!-- 통합검색에서만 -->
        <div class="re-search__btn">
            <div class="agree-input" v-if="inSearchKeyword === ''">
                <label class="agree-input__item">
                    <input type="checkbox" class="agree-input__radio" v-model="inSearch">
                    <span class="icon"></span> 결과 내 재 검색
                </label>
            </div>
            <!-- ** 결과내재검색 후 버튼 노출 ** -->
            <button type="button" class="btn" v-if="inSearchKeyword !== ''" @click="clearSearch">재 검색 취소</button>
        </div>
    </div>

    <div class="re-search">
        <ul class="re-search__list">
            <li class="re-search__item" v-for="(hashtag, index) in hashTags" :key="index">
                <a href="javascript:;" @click="search(hashtag.codeName)">#{{hashtag.codeName}}</a>
            </li>
        </ul>
        <div class="re-search__result contents">
            <p v-if="totCnt !== 0 && !searching">“<span>{{ searchKeyword }}</span><span v-if="inSearchKeyword !== ''"> >> {{ inSearchKeyword }}</span>”에 대한 총 <span>{{ totCnt }}</span>건이 검색되었습니다.</p>
            <p v-else-if="searching">“<span>{{ searchingKeyword }}</span>”에 대해 검색중입니다.</p>
            <p v-else>“<span>{{ searchKeyword }}</span>”에 대한 검색 결과가 없습니다.</p>
        </div>


        <!-- 검색결과 없을 시 -->
        <div class="depth__wrap depth03 contents" v-if="totCnt === 0">
            <div class="depth__text">
                <div class="depth__wrap">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검색어가 바르게 입력 되었는지 확인해 주세요.</p>
                    </div>
                </div>
                <div class="depth__wrap">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검색어의 띄어쓰기를 정확하게 한 후 검색해 주세요.</p>
                    </div>
                </div>
                <div class="depth__wrap">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검색어에 영어나 특수문자를 제거 한 후 검색해 주세요</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-menu__wrap" v-else>
            <div class="tab-menu type02" >
                <ul class="tab-menu__list">
                    <li class="tab-menu__item" :class="{active : selectTab === 'total'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('total')">통합검색 (<span>{{ totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item" :class="{active : selectTab === 'dept'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('dept')">진료과/센터/클리닉 (<span>{{ deptResult.totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item":class="{on : selectTab === 'doctor'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('doctor')">의료진 (<span>{{ doctorResult.totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item":class="{on : selectTab === 'info'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('info')">이용안내 (<span>{{ infoResult.totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item":class="{on : selectTab === 'health'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('health')">건강정보 (<span>{{ healthResult.totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item":class="{on : selectTab === 'intro'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('intro')">병원소개 (<span>{{ introResult.totCnt }}</span>)</button>
                    </li>
                    <li class="tab-menu__item":class="{on : selectTab === 'faq'}">
                        <button type="button" class="tab-menu__btn" @click="moveTab('faq')">클리닉 FAQ (<span>{{ faqResult.totCnt }}</span>)</button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="re-search__box contents" v-if="deptResult.totCnt !== 0 && (selectTab === 'total' || selectTab === 'dept')">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">진료과/센터/클리닉 (총 {{ deptResult.totCnt }}건)</h4>
                    <a href="javascript:;" @click="moveTab('dept')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>
            <ul class="list">
                <li class="item" v-for="(dept, index) in deptResult.list" :key="index">
                    <router-link :to="`/department/departmentDetail.do?deptCd=${dept.deptCd}`">
                        <div class="img">
                            <img :src="`/displayFile.do?name=${dept.savedName}`" alt="이미지">
                        </div>
                        <div class="text">
                            <h4 class="tit" v-html="setHighlightText(dept.deptNm)"></h4>
                            <p class="desc" v-html="setHighlightText($commonUtils.removeTags(dept.content))">
                            </p>
                            <p class="tag">진료과/의료진 > 진료과 안내</p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>


        <!-- 의료진 통합검색 내 -->
        <div class="re-search__box contents" v-if="doctorResult.totCnt !== 0 && selectTab === 'total'">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">의료진 (총 {{ doctorResult.totCnt }}건)</h4>
                    <a href="javascript:;" @click="moveTab('doctor')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>

            <div class="doctor-wrap ">
                <ul class="doctor-list">
                    <li class="doctor-list__item" v-for="(doctor, index) in doctorResult.list" :key="index">
                        <div class="doctor-list--img">
                            <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지" @error="errorImgCall">
                        </div>
                        <div class="doctor-list--con">
                            <div class="doctor-list--text">
                                <a href="javascript:;" class="doctor-list--heart"></a>
                                <h3 v-html="`${setHighlightText(doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName)}<sub>${setHighlightText(doctor.deptNm)}</sub>`"></h3>
                                <h4>주진료분야</h4>
                                <p v-html="setHighlightText(doctor.doctorDept.special)"></p>
                            </div>
                            <div class="doctor-list--button">
                                <router-link :to="`/hospital/department-doctor/doctorDetail.do?drNo=${doctor.drNo}`" target="_blank">의료진소개</router-link>
                                <a href="javascript:;" @click="getAbsence(doctor)">진료예약</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="re-search__box contents" v-if="infoResult.totCnt !== 0 && (selectTab === 'total' || selectTab === 'info')">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">이용안내 (총 {{ infoResult.totCnt }}건) </h4>
                    <a href="javascript:;" @click="moveTab('info')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>
            <ul class="list">
                <li class="item" v-for="(article, index) in infoResult.list" :key="index">
                    <router-link :to="article.link">
                        <div class="text">
                            <h4 class="tit" v-html="setHighlightText(article.TITLE)"></h4>
                            <p class="desc" v-html="setHighlightText($commonUtils.removeTags(article.CONTENT))">
                            </p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="re-search__box contents" v-if="healthResult.totCnt !== 0 && (selectTab === 'total' || selectTab === 'health')">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">건강정보 (총 {{ healthResult.totCnt }}건) </h4>
                    <a href="javascript:;" @click="moveTab('health')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>
            <ul class="list">
                <li class="item" v-for="(article, index) in healthResult.list" :key="index">
                    <router-link :to="article.link">
                        <div class="text">
                            <h4 class="tit" v-html="setHighlightText(article.TITLE)"></h4>
                            <p class="desc" v-html="setHighlightText($commonUtils.removeTags(article.CONTENT))">
                            </p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="re-search__box contents" v-if="introResult.totCnt !== 0 && (selectTab === 'total' || selectTab === 'intro')">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">병원소개 (총 {{ introResult.totCnt }}건) </h4>
                    <a href="javascript:;" @click="moveTab('intro')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>
            <ul class="list">
                <li class="item" v-for="(article, index) in introResult.list" :key="index">
                    <router-link :to="article.link">
                        <div class="text">
                            <h4 class="tit" v-html="setHighlightText(article.TITLE)"></h4>
                            <p class="desc" v-html="setHighlightText($commonUtils.removeTags(article.CONTENT))">
                            </p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <!-- faq -->
        <div class="re-search__box contents" v-if="faqResult.totCnt !== 0 && (selectTab === 'total' || selectTab === 'faq')">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">클리닉FAQ ({{ faqResult.totCnt }})</h4>
                    <a href="javascript:;" @click="moveTab('faq')" v-if="searchType === 'total'">더보기 +</a>
                </div>
            </div>

            <div class="faq-wrap">
                <div class="board-list">
                    <div class="faq">
                        <!-- <div v-for="(faq, index) in faqResult.list" :key="index" class="faq-item"> -->
                        <div v-for="(faq, index) in faqResult.list" :key="index" class="faq-item" @click.stop="toggle(faq.articleNo)">
                            <div class="faq-title" :class="{ 'active': activeIndex === faq.articeNo }">
                                <div class="faq-title__text"><span class="icon">Q </span> <em>[{{faq.category}}] <span v-html="setHighlightText(faq.title)"></span></em> </div>
                                <div class="faq-title__btn"></div>
                            </div>
                            <slide-up-down :active="activeIndex === faq.articleNo">
                                <div class="faq-content">
                                    <span>A</span>
                                    <p v-html="setHighlightText(faq.content)"></p>
                                </div>
                            </slide-up-down>
                            <div class="faq-title__btn--mo" :class="{ 'active': activeIndex === faq.articleNo }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 의료진 탭 내 -->
        <div class="re-search__box contents" v-if="doctorResult.totCnt !== 0 && selectTab === 'doctor'">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text type2">
                    <h4 class="depth01__title title">의료진 (총 {{ doctorResult.totCnt }}건)</h4>
                </div>
            </div>

            <div class="doctor-wrap">
                <div class="initial-search mt-n">
                    <h3>초성검색</h3>
                    <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
                </div>

                <ul class="doctor-list contents">
                    <template v-if="doctorResult.list.length > 0">
                        <li class="doctor-list__item" v-for="(doctor, index) in doctorResult.list" :key="index">
                            <div class="doctor-list--img">
                                <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지" @error="errorImgCall">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart"></a>
                                    <h3 v-html="`${setHighlightText(doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName)}<sub>${setHighlightText(doctor.deptNm)}</sub>`"></h3>
                                    <h4>주진료분야</h4>
                                    <p v-html="setHighlightText(doctor.doctorDept.special)"></p>
                                </div>
                                <div class="doctor-list--button">
                                    <router-link :to="`/hospital/department-doctor/doctorDetail.do?drNo=${doctor.drNo}`" target="_blank">의료진소개</router-link>
                                    <a href="javascript:;" @click="getAbsence(doctor)">진료예약</a>
                                </div>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>

        </div>

        <template v-if="selectTab !== 'total'">
            <pagination :totCnt="pageTotCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
        </template>

        <!-- end -->
    </div>
</template>
<script>
    import SlideUpDown from 'vue-slide-up-down'
    import Search from '../common/SearchBox.vue'
    import Consonant from '@/components/common/Consonant.vue'
    import Pagination from '@/components/common/Pagination.vue'

    export default {
        components: {
            Pagination,
            Consonant,
            SlideUpDown,
            Search
        },
        data() {
            return {
                pageTotCnt: 0,
                searchKeyword: this.$route.query.searchKeyword,
                inSearchKeyword: '',
                activeIndex: '0',
                searchType: 'total',
                totCnt: 0,
                deptResult: { totCnt: 0 },
                doctorResult: { totCnt: 0 },
                infoResult: { totCnt: 0 },
                healthResult: { totCnt: 0 },
                introResult: { totCnt: 0 },
                faqResult: { totCnt: 0 },
                selectTab: 'total',
                consonantList: [],
                currentNum: 1,
                pageRow: 10,
                pageCount: window.innerWidth > 720 ? 10 : 5,
                chosung: [],
                searchChosung: '',
                searchKeywordText: '',
                inSearchKeywordText: '',
                inSearch: false,
                hashTags: [],
                searching: false,
                searchingKeyword: '',
            };
        },
        mounted() {
            this.searchKeywordText = this.searchKeyword
            this.search(this.searchKeyword)
            document.addEventListener('click', this.handleClickOutside);
            this.getHashTags()
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            toggle(index) {
                console.log(index);
                this.activeIndex = this.activeIndex === index ? null : index;
                console.log(this.activeIndex)
            },
            // handleClickOutside(event) {
            //     const isClickInside = this.$el.contains(event.target);
            //     if (!isClickInside) {
            //         this.activeIndex = null;
            //     }
            // },
            searchEvent() {
                if (this.inSearch) {
                    this.inSearchKeyword = this.inSearchKeywordText
                } else {
                    this.inSearchKeyword = ''
                }
                this.search(this.searchKeywordText)
            },
            search(keyword) {
                this.searching = true
                this.searchingKeyword = keyword
                if (keyword === '' || keyword === undefined) {
                    return false
                }
                const startCount = this.currentNum * this.pageRow - this.pageRow

                this.axios.get(this.api.search.search, {
                    params: {
                        query: keyword,
                        menu: this.searchType,
                        startCount: startCount,
                        chosung: this.searchChosung,
                        requery: this.inSearchKeyword
                    }
                }).then(res => {
                    window.scrollTo(0, 0)
                    const searchResult = res.data

                    if (this.searchType === 'dept' || this.searchType === 'total') {
                        this.deptResult.totCnt = searchResult.deptCount
                        this.deptResult.list = searchResult.deptList

                        if (this.searchType === 'dept') {
                            this.pageTotCnt = searchResult.deptCount
                        }
                    }

                    if (this.searchType === 'doctor' || this.searchType === 'total') {
                        this.doctorResult.totCnt = searchResult.doctorCount
                        this.doctorResult.list = searchResult.doctorList

                        if (this.searchType === 'doctor') {
                            this.chosung = searchResult.consonantList
                            this.getDoctorConsonant()
                            this.pageTotCnt = searchResult.doctorCount
                        }
                    }

                    if (this.searchType === 'info' || this.searchType === 'total') {
                        this.infoResult.totCnt = searchResult.infoCount
                        this.infoResult.list = searchResult.infoList

                        if (this.searchType === 'info') {
                            this.pageTotCnt = searchResult.infoCount
                        }
                    }

                    if (this.searchType === 'health' || this.searchType === 'total') {
                        this.healthResult.totCnt = searchResult.healthCount
                        this.healthResult.list = searchResult.healthList

                        if (this.searchType === 'health') {
                            this.pageTotCnt = searchResult.healthCount
                        }
                    }

                    if (this.searchType === 'intro' || this.searchType === 'total') {
                        this.introResult.totCnt = searchResult.introCount
                        this.introResult.list = searchResult.introList

                        if (this.searchType === 'intro') {
                            this.pageTotCnt = searchResult.introCount
                        }
                    }


                    this.axios.get(this.api.article.list + 7,
                        {
                            params: {
                                searchKeyword: keyword
                            }
                        }).then(faqres => {
                            this.faqResult.totCnt = faqres.data.totCnt
                            this.faqResult.list = faqres.data.list

                            if (this.searchType === 'faq') {
                                this.pageTotCnt = faqres.data.totCnt
                            }

                            if (this.selectTab === 'total') {
                                this.totCnt = this.deptResult.totCnt + this.doctorResult.totCnt + this.infoResult.totCnt + this.healthResult.totCnt + this.introResult.totCnt + this.faqResult.totCnt
                            }

                            this.searching = false
                        })


                    this.searchKeyword = keyword

                })
            },
            moveTab(type) {
                this.selectTab = type
                this.searchType = type

                if (type != 'total') {
                    this.search(this.searchKeyword)
                }
            },
            async getAbsence(info) {
                await this.axios.get(this.api.doctor.absence, {
                    params: {
                        drNo: info.drNo
                    }
                }).then(res => {
                    const reason = res.data.reason
                    if (reason) {
                        const startDt = new Date(res.data.staDt)
                        const endDt = new Date(res.data.endDt)
                        const today = new Date()
                        if(today >= startDt && today <= endDt){
                            alert(reason)
                            return
                        }
                    }
                    this.$store.commit('setReserveInfo', info)
                    this.$router.push('/reserve/reservation.do')
                }).catch(err => {
                    alert('오류가 발생했습니다.')
                })
            },
            getDoctorConsonant() {
                this.consonantList = [
                    { cho: 'ㄱ', disable: 'disable' },
                    { cho: 'ㄴ', disable: 'disable' },
                    { cho: 'ㄷ', disable: 'disable' },
                    { cho: 'ㄹ', disable: 'disable' },
                    { cho: 'ㅁ', disable: 'disable' },
                    { cho: 'ㅂ', disable: 'disable' },
                    { cho: 'ㅅ', disable: 'disable' },
                    { cho: 'ㅇ', disable: 'disable' },
                    { cho: 'ㅈ', disable: 'disable' },
                    { cho: 'ㅊ', disable: 'disable' },
                    { cho: 'ㅋ', disable: 'disable' },
                    { cho: 'ㅌ', disable: 'disable' },
                    { cho: 'ㅍ', disable: 'disable' },
                    { cho: 'ㅎ', disable: 'disable' }
                ]
                for (let i of this.consonantList) {
                    for (let j of this.chosung) {
                        if (i.cho == j) {
                            i.disable = ''
                        }
                    }
                }
            },
            searchConsonant(consonant) {
                this.searchChosung = consonant
                this.currentNum = 1
                this.drName = ''
                this.search(this.searchKeyword)
            },
            setNum(n) {
                this.currentNum = n

                this.search(this.searchKeyword)
            },
            clearSearch() {
                this.inSearch = false
                this.inSearchKeyword = ''
                this.inSearchKeywordText = ''
                this.searchKeywordText = ''
                this.search(this.searchKeyword)
            },
            getHashTags() {
                this.axios.get(this.api.search.hashKeywords).then(res => {
                    this.hashTags = res.data.result
                })
            },
            setHighlightText(text) {
                let result = text.replace(new RegExp((this.searchKeyword), 'g'), `<span class="highlight">${this.searchKeyword}</span>`)
                if(this.inSearchKeyword) {
                    result = result.replace(new RegExp((this.inSearchKeyword), 'g'), `<span class="highlight">${this.inSearchKeyword}</span>`)
                }
                return result
            },
        }
    };
</script>