<template>
    <div class="container">
        <div class="sub-tit">
            <h2>{{info.deptNm}}</h2>
        </div>
        <div class="inner">
            <div class="info-text">
                <div class="btn-wrap right clinic" data-aos="fade-up" data-aos-duration="1000">
                    <router-link :to="`/pd_clinic_faq/list.do`"  v-if="info.deptClsf == 'B' && info.boardYn == 'Y'" class="btn blue">
                        클리닉 FAQ 바로가기
                    </router-link>
                    <router-link to="/hospital/department-doctor/centerClinic.do" class="btn mint">
                    <img src="/resource/icon/depart_potal_icon.png" alt="이미지">전체 센터/클리닉</router-link>
                </div>
                <div class="board-view__file" v-if="info.attachBag && info.attachBag.departmentFile && info.deptClsf == 'B'"  data-aos="fade-up" data-aos-duration="1500">
                    <div class="tit">첨부파일</div>
                    <ul class="file-list">
                        <li class="file-item" v-for="file in info.attachBag.departmentFile" :key="file">
                            <button type="button" @click="$commonUtils.fileDownload(file.attachNo, file.displayName)"><span class="icon"></span>{{file.displayName}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-text" data-aos="fade-up" data-aos-duration="1500" v-if="$commonUtils.removeTags(info.greetings)">
                <div class="info-text depth01 depth__wrap">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">소개</h4>
                        <p v-html="info.greetings"></p>
                    </div>
                </div>
            </div>
            <div class="info-text" v-if="doctorList.length > 0">
                <div class="info-text depth01 depth__wrap" data-aos="fade-up" data-aos-duration="1500">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">의료진</h4>
                    </div>
                </div>
                <div class="info__doctor-list" data-aos="fade-up" data-aos-duration="2000">
                    <ul class="doctor-list">
                        <li class="doctor-list__item" v-for="doctor in doctorList" :key="doctor">
                            <div class="doctor-list--img">
                                <img :src="`/displayFile.do?drNo=${doctor.drNo}`" alt="이미지" @error="errorImgCall">
                            </div>
                            <div class="doctor-list--con">
                                <div class="doctor-list--text">
                                    <a href="javascript:;" class="doctor-list--heart" :class="{on: doctor.favoYn == 'Y'}" @click="favoAddDel(doctor)"></a>
                                    <h3>{{doctor.hptlJobTitle ? doctor.hptlJobTitle : doctor.drName}}<sub>{{doctor.deptNm}}</sub></h3>
                                    <h4>주진료분야</h4>
                                    <p>{{doctor.doctorDept.special}}</p>
                                </div>
                                <div class="doctor-list--button">
                                    <a href="javascript:;" @click="goDoctor(doctor.drNo)">의료진소개</a>
                                    <a href="javascript:;" @click="getAbsence(doctor)" v-if="info.rsvOpenYn == 'Y' && doctor.doctorDept.drRsvOpenYn == 'Y'">진료예약</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="info-text">
                <div class="info-text depth01" v-if="info.etc5">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">진료안내</h4>
                        <p v-html="info.etc5"></p>
                    </div>
                </div>
            </div> -->
            <div class="info-text" data-aos="fade-up" data-aos-duration="2500" v-if="$commonUtils.removeTags(info.content)">
                <div class="info-text depth01 depth__wrap">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">특장점</h4>
                        <p v-html="info.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import errorImg from '@/assets/resource/img/doctor-list--img.png'
export default {
    data() {
        return {
            info: '',
            doctorList: '',
            nowDate: new Date,
        }
    },
     watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
    methods: {
        favoAddDel(doctor) {
            const path = this.$route.path
            if(this.$store.getters.getStatus){
                const txt = '의료진'
                if(doctor.favoYn == 'Y'){
                    if (confirm(`관심 ${txt}을 해제하시겠습니까?`)) {
                        this.axios
                            .delete(this.api.favorite.delete, {
                                params: {
                                    favoNo: doctor.drNo,
                                    favoTypeCd: 'DOCTOR'
                                }
                            })
                            .then(res => {
                                if (res.status == 200) {
                                    doctor.favoYn = 'N'

                                    alert(`해당 ${txt}이 즐겨찾기에서 해제되었습니다.`);
                                } else {
                                    alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                                }
                            })
                    }
                }else{
                    this.axios
                        .get(this.api.favorite.add, {
                            params: {
                                favoNo: doctor.drNo,
                                favoTypeCd: 'DOCTOR'
                            }
                        })
                        .then(res => {
                            if (res.status == 200) {
                                doctor.favoYn = 'Y'

                                alert(`해당 ${txt}이 즐겨찾기에 등록되었습니다. 마이페이지 > 관심 정보에서 확인하실 수 있습니다.`);
                            } else {
                                alert('오류가 발생하였습니다. 다시 시도해주시길 바랍니다.');
                            }
                        })
                }
            }else{
                alert('로그인 후 이용하실 수 있습니다.')
                const url = this.$route.path.indexOf('/deptHp/') > -1 ? `/deptHp/${this.$route.params.deptCd}/${this.$route.params.hpUrl}/member/deptLogin.do` : '/member/login.do'
                this.$router.push(url)
            }
        },
        getInfo() {
            const deptCd = this.$route.query.deptCd
            this.axios
                .get(this.api.department.view, {
                    params: {
                        langType: 'kr',
                        instNo: 1,
                        deptCd: deptCd
                    }
                })
                .then(res => {
                    if(!res.data){
                        alert('존재하지 않는 과입니다.')
                        this.$router.push('/hospital/department-doctor/department.do')
                        return
                    }
                    this.info = res.data
                    // this.getSchedule(this.info)
                    this.getDoctor(this.info)
                })
        },
        getDoctor(info){
            const deptCd = info.deptCd
            const searchParam = {
                startIndex: 1,
                pageRow: 99,
                langType: 'kr',
                instNo: 1,
                deptCd: deptCd,
                orderType: 'dept',
            }
            this.axios
                .get(this.api.doctor.doctorList,
                    {
                        params: searchParam
                    })
                .then(res => {
                    const list = res.data.doctorList
                    for(let i of list){
                        for(let j of i.doctorDeptList){
                            if(j.deptClsf == 'A'){
                                i.deptNm = j.deptNm
                                if(j.mainDeptYn == 'Y') break
                            }
                        }
                    }
                    this.doctorList = list
                })
        },
        getSchedule(info){
            console.log(info)
            const deptCd = info.deptCd
            const nextWeek = new Date().setDate(this.nowDate.getDate() + 7)
            this.axios.get(this.api.doctor.schedule, {
                params: {
                    fromdd: this.$commonUtils.dateToStr(this.nowDate, ''),
                    todd: this.$commonUtils.dateToStr(new Date(nextWeek), ''),
                    deptCd: deptCd,
                    orddeptcd: info.emrDeptCd ? info.emrDeptCd : deptCd,
                    exposeYn: 'Y'
                }
            })
            .then(res => {
                this.doctorList = res.data
            })
            .catch(err => {
                console.error(err)
            })
        },
        async getAbsence(info){
            await this.axios.get(this.api.doctor.absence, {
                params: {
                    drNo: info.drNo
                }
            }).then(res => {
                const reason = res.data.reason
                if(reason){
                    const startDt = new Date(res.data.staDt)
                    const endDt = new Date(res.data.endDt)
                    const today = new Date()
                    if(today >= startDt && today <= endDt){
                        alert(reason)
                        return
                    }
                }
                this.$store.commit('setReserveInfo', info)
                this.$router.push('/reserve/reservation.do')
            }).catch(err => {
                alert('오류가 발생했습니다.')
            })
        },
        goDoctor(drNo){
            const url = `/hospital/department-doctor/doctorDetail.do?drNo=${drNo}`
            window.open(url);
        },
        errorImgCall(e){
            e.target.src = errorImg;
        },
         checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
        
    },
    mounted() {
        this.getInfo();
     
        var element = document.querySelector(".table-wrap.table-type1.scroll");
        if (element) {
            element.addEventListener("click", function() {
                element.classList.add("on");
            });
        }
       
    },
     updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>
