<template>
    <div class="find-info" v-if="obj">
        <p class="sub-text">
            <!-- 본인인증, ipin 경우 수정필요 -->
            입력하신 {{ Object.keys(obj)[0] == '이메일' ? '이메일 주소로' : '휴대전화번호로'}}
            <br>
            아이디를 전송하였습니다.
        </p>
        <div class="contents find-info__box">
            <p class="find-info__text">아이디를 확인 하신 후, 로그인 해주시기 바랍니다.</p>
            <ul class="contents find-info__list">
                <li class="find-info__item">
                    <div class="tit">{{Object.keys(obj)[0]}}</div>
                    <div class="desc">{{Object.values(obj)[0]}}</div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <router-link to="/" class="btn">메인으로 이동</router-link>
            <router-link to="/member/login.do" class="btn blue">로그인</router-link>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {
            obj: this.$store.state.memberStore.find,
        }
    },
    mounted() {
        if(!this.obj) {
            this.$router.replace('/member/idInquiry.do')
            alert('올바르지 않은 접근입니다.')
        }
        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('init')
        // });
    },
    beforeUnmount() {
        this.$store.commit('init');
    },
}
</script>