<template>
    <!-- 정형외과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>정형외과는 사지와 척추, 그 부속기관의 형태와 기능을 내·외과적, 물리학적인 방법으로 치료 회복시키는 진료과입니다. 외상 분야(사지골, 관절, 척추골의 골절, 탈구, 인대 손상 등)와 슬관절, 고관절, 척추, 수부 등의 분야로 나누어 진료하고 있으며, 특히 퇴행성질환의 빈도 증가에 따라 인공관절클리닉, 관절경클리닉을 개설해 시술하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>척추, 고관절, 슬관절, 인공관절, 골다공증, 일반 외상, 류마티스관절염, 관절내시경, 관절염, 견관절, 퇴행성질환, 수부, 족부(미세수술), 소아</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>1991년 개설된 창원파티마병원 정형외과는 골절, 관절염(인공 슬관절 및 고관절 수술 포함), 골다공증, 창상 및 양성종양 분야는 공통으로 진료하며, 척추, 견관절, 주관절, 수부, 고관절, 슬관절, 당뇨족을 포함한 족관절 및 족부, 소아질환은 분야별 전문의들이 각 분야를 세부적으로 나눠 환자 상태에 맞는 최적의 진료 서비스를 제공하고 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <br>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>특히 척추 및 견관절 및 슬관절 분야는 조성래 과장, 전도환 과장, 조제일 과장이, 수부 및 주관절 및 족부는 김정우 과장, 소아질환 및 수부, 당뇨족을 포함한 족관절 및 족부질환은 승형준 과장이 특화되어 있는 진료분야입니다. 개인 의원 및 병원에서 대증 치료에도 크게 호전을 보이지 않거나 수술적 치료가 필요한 분들을 주로 진료하고 있으며, 정밀검사는 필요한 경우에만 시행하고, 환자의 연령과 기저질환 등 상태를 종합적으로 고려해 최대한 비수술적 방법으로 치료를 시행한 뒤 꼭 필요한 경우에 한해 수술적 방법으로 치료하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>