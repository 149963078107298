<template>
    <div class="volunteerActive">
        <div class="contents">
            <div class="care-top inner">
                <div class="icon">
                    <img src="@/assets/resource/icon/volunteer-top-icon.png" alt="이미지">
                </div>
                <p>코로나19 확산방지를 위해 원내 자원봉사활동을 잠정중단합니다.</p>
            </div>
            <div class="volunteer_active_bg contents full-width">
                <div class="heart_bg">
                    <img src="@/assets/resource/icon/heart_bg01.png" alt="이미지">
                    <img src="@/assets/resource/icon/heart_bg02.png" alt="이미지">
                    <img src="@/assets/resource/icon/heart_bg03.png" alt="이미지">
                </div>
                <h3 class="red__title">주님이 주신 사랑을 이웃과 나누는 것이 바로 자원봉사입니다.</h3>
                <p>자원봉사자는 자발성, 연대성, 무보수성이라는 자원봉사활동의 원칙을 바탕으로<br>병원설립이념에 따라 빛과 소금의 소명을 다하고 있습니다.</p>
            </div>
        </div>

        <div class="contents">
            <div class="inner">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">자격요건</h4>
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>심신이 건강한 사람</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>자원봉사의 의미를 충분히 이해하며 성실하고 책임감 있는 사람</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap info-reference">
                            <div class="depth__bullet">※</div>
                            <div class="depth__text">
                                <h4 class="depth03__title">호스피스 봉사 : 호스피스 자원봉사자 교육 수료증 소지자</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contents">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">봉사시간</h4>
                            <div class="depth__wrap depth02">
                                <div class="depth__text">
                                    <div class="depth__wrap depth03">
                                        <div class="depth__text">
                                            <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>일반봉사자 : 09:30 ~ 12:00, 13:30 ~ 16:00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>대학생봉사자 : 09:00 ~ 12:00, 13:30 ~ 16:30</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>호스피스 봉사자 : 09:30 ~ 12:30, 13:30 ~ 16:30</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contents">
                     <div class="info-text depth__wrap depth01 scroll">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">활동내용</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 scroll">
                       <div class="scroll">
                            <table>
                                <caption class="blind">활동내용 폼</caption>
                                <colgroup>
                                    <col style="width:30%;">
                                    <col style="width:70%;">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>내용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>병동</td>
                                        <td style="text-align:left;">세발, 이발, 간호봉사</td>
                                    </tr>
                                    <tr>
                                        <td>약제부</td>
                                        <td style="text-align:left;">약품 분류, 라벨부착, 수약 따르기</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="6">외래</td>
                                        <td style="text-align:left;">병원 이용안내</td>
                                    </tr>
                                     <tr>
                                        <td style="border-left:1px solid #d9d9d9; text-align:left;">진료과 검사실 위치 안내</td>
                                    </tr>
                                     <tr>
                                        <td style="border-left:1px solid #d9d9d9; text-align:left;">무인수납 및 처방전 발급 안내</td>
                                    </tr>
                                     <tr> 
                                        <td style="border-left:1px solid #d9d9d9; text-align:left;">진료보조(체온, 혈압, 체중)</td>
                                    </tr>
                                     <tr>
                                        <td style="border-left:1px solid #d9d9d9; text-align:left;">원목실(행정보조)</td>
                                    </tr>
                                     <tr>
                                        <td style="border-left:1px solid #d9d9d9; text-align:left;">파티마 갤러리 안내</td>
                                    </tr>
                                     <tr>
                                        <td>제봉실</td>
                                        <td style="text-align:left;">환자복 시트 수선</td>
                                    </tr>
                                     <tr>
                                        <td>중앙공급실</td>
                                        <td style="text-align:left;">진료용 거즈 접기 외 진료 재료 준비</td>
                                    </tr>
                                     <tr>
                                        <td>파티마 어린이집</td>
                                        <td style="text-align:left;">어린이 돌보기 및 업무보조</td>
                                    </tr>
                                     <tr>
                                        <td>호스피스(은혜) 병동</td>
                                        <td style="text-align:left;">휴식간호, 손발 마사지, 식사보조, 세발목욕봉사, 말벗 등</td>
                                    </tr>
                                     <tr>
                                        <td>기타</td>
                                        <td style="text-align:left;">자료 입력 및 행정 업무 보조, 병원보, 소식지 등 홍보물 발송 지원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="contents">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">일반, 대학생 봉사 신청</h4>
                            <div class="depth__wrap depth02">
                                <div class="depth__text">
                                    <div class="depth__wrap depth03">
                                        <div class="depth__text">
                                            <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>방문접수 : 별관 지하 1층 자원봉사자실</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>접수시간 : 09:00 ~ 12:00, 14:00 ~ 17:00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>문의 : 270-1016</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contents">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">호스피스 봉사 신청</h4>
                            <div class="depth__wrap depth02">
                                <div class="depth__text">
                                    <div class="depth__wrap depth03">
                                        <div class="depth__text">
                                            <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>면담 후 결정</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__bullet">
                                                    <span class="bullet03"></span>
                                                </div>
                                                <div class="depth__text">
                                                    <p>문의 : 270-1648(호스피스 병동)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="contents">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">인증서 발급</h4>
                            <div class="depth__wrap depth02">
                                <div class="depth__text">
                                    <div class="depth__wrap depth03">
                                        <div class="depth__text">
                                            <div class="depth__wrap">
                                                <div class="depth__text">
                                                    <p>자원봉사 실적은 사회복지 봉사활동 인증관리 사이트<a href="www.vms.or.kr" class="mint_link" target="_blank" title="새 창 이동">(www.vms.or.kr)</a>에 회원가입을 해야하며, 자원봉사 종결 후 입력됩니다.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__text">
                                                    <p>자원봉사인증서는 VMS 사이트에서 발급 받아 출력 후 사용합니다.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                                <div class="depth__text">
                                                    <p>창원시 자원봉사자증은 1365 자원봉사포털<a href="(www.1365.go.kr)" class="mint_link" target="_blank" title="새 창 이동">(www.1365.go.kr)</a>에 직접 회원가입해서 발급 받으면 됩니다.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        mounted() {
            document.addEventListener("DOMContentLoaded", function() {
                var element = document.querySelector(".table-wrap.table-type1.scroll");     
                if (element) {
                    element.addEventListener("click", function() {
                
                    element.classList.add("on");
                    });
                }
            });
            
        }
    }
</script>