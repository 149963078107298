<template>
    <ul class="contents masonry">
        <li class="masonry__item" v-for="(article, index) in printList" :key="index">
            <a href="#" @click="articleView(article.item1, board, article.articleNo)" class="masonry__link">
                <div class="masonry__img" v-if="article.attachBag.thumbs != null">
                    <img :src="`/displayFile.do?name=${ article.attachBag.thumbs[0].savedName }`" alt="이미지">
                </div>
                <div class="masonry__text">
                    <h4>{{ article.title }}</h4>
                    <p class="desc">{{ $commonUtils.removeTags(article.content) }}</p>
                    <div class="arrow-wrap">
                        <span class="tag">{{ article.item2 }}</span>
                        <div class="arrow-btn"></div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            list: '',
            board: '',
            printList: [],
            totalPage: 0,
            currentNum: 1,
            pageCount: 5,
            itemLength: 4,
            itemWidth: 25,
            itemMargin: '20px'
        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if (to.query != from.query && to.path == from.path) {
                // 페이지네이션 초기화
                this.currentNum = 1;
                this.getList(this.currentNum);
            }
        }
    },
    mounted() {
        this.updateMasonryLayout();

        

    },
    // 타입2
    beforeDestroy() {
        const masonryItems = document.querySelectorAll('.masonry__item');
        masonryItems.forEach(item => {
            item.removeEventListener('mouseenter', this.handleMouseEnter);
            item.removeEventListener('mouseleave', this.handleMouseLeave);
        });
    },
    methods: {
        async getList(params) {
            let totCnt = 0
            await this.axios.get(this.api.article.list + params.boardNo, {
                params: params
            }).then(res => {
                this.list = res.data.list;
                this.board = res.data.board;
                totCnt = res.data.totCnt

                this.totalPage = Math.ceil(res.data.totCnt / this.pageRow);
                let end = Math.ceil(this.currentNum / this.pageCount) * this.pageCount;
                let start = end - (this.pageCount - 1);
                if (end > this.totalPage) {
                    end = this.totalPage;
                }
                this.pageList = [];
                for (let i = start; i <= end; i++) {
                    this.pageList.push(i);
                }
            });

            if (this.list != null) {
                for (let i = 0; i < this.list.length; i++) {
                    this.list[i].key = this.list[i].item1;
                }
                // 데이터 전달 전 가공 처리
                this.$emit('getContents', this.list, totCnt);
            }
        },
        updateMasonryLayout() {
            if (window.innerWidth >= 1440) {
                // PC
                this.itemLength = 4;
                this.itemWidth = 25;
                this.itemMargin = '20px';
            } else if (window.innerWidth >= 721) {
                // 테블릿
                this.itemLength = 2;
                this.itemWidth = 50;
                this.itemMargin = '10px';
            } else {
                // 모바일
                this.itemLength = 1;
                this.itemWidth = 100;
                this.itemMargin = '10px';
            }
            this.setupMasonryLayout();
            const masonryItems = document.querySelectorAll('.masonry__item');
            masonryItems.forEach(item => {
                item.addEventListener('mouseenter', this.handleMouseEnter);
                item.addEventListener('mouseleave', this.handleMouseLeave);
            });
        },
        handleMouseEnter(event) {
            this.setupMasonryLayout();
        },
        handleMouseLeave(event) {
            this.setupMasonryLayout();
        },
        setupMasonryLayout() {
            const masonryItems = document.querySelectorAll('.masonry__item');
            let columnHeights = Array.from({ length: this.itemLength }, () => 0);

            masonryItems.forEach(item => {
                const minHeight = Math.min(...columnHeights);
                const columnIndex = columnHeights.indexOf(minHeight);
                item.style.marginBottom = this.itemMargin;
                item.style.position = 'absolute';
                item.style.left = `${columnIndex * this.itemWidth}%`;
                item.style.top = `${columnHeights[columnIndex]}px`;
                columnHeights[columnIndex] += item.offsetHeight + parseInt(this.itemMargin);
            });

            const maxHeight = Math.max(...columnHeights);
            document.querySelector('.masonry').style.height = `${maxHeight}px`;
        },
        articleView(key, board, articleNo) {
            this.$emit('articleView', key, this.$route.path.indexOf("/healthinfo/cancerList.do") > -1 ? '' : board.boardNo, articleNo);
        }
    }
}
</script>
