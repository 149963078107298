<template>
    <header class="center-header coop" :class="{ 'show': isMenuActive }" @mouseenter="activateMenu" @mouseleave="resetHeaderActive">
        <div class="header__top">
            <div class="header__inner">
                <ul class="header__top--list">
                    <li>
                        <a href="javascript:;"><img src="../../assets/resource/icon/canter_home-icon.png" alt="이미지"></a>
                    </li>
                    <li>
                        <a href="javascript:;">창원파티마병원</a>
                    </li>
                    <li>
                        <div class="header__top--select-wrap" @mouseenter="activeSelect" @mouseleave="resetSelect">
                            <a href="javascript:;">센터바로가기</a>
                            <slide-up-down :active="isSelectActive" class="header__top--select">
                                <div class="header__top--option"><a href="javascript:;">진료협력센터</a></div>
                                <div class="header__top--option"><a href="javascript:;">직업환경의학센터</a></div>
                                <div class="header__top--option"><a href="javascript:;">떙떙센터</a></div>
                            </slide-up-down>
                        </div>
                    </li>
                </ul>
                <ul class="header__top--list">
                    <li>
                        <a href="javascript:;">로그인</a>
                    </li>
                    <li>
                        <a href="javascript:;">회원가입</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header__bottom">
            <div class="header__inner">
                <div class="header__front">
                    <h1 class="header__tit">
                        <a href="/">
                            <img src="@/assets/resource/icon/logo-b.png" alt="이미지">
                            <span>진료협력센터</span>
                        </a>
                    </h1>
                    <div class="header__menu-wrap">
                        <div class="line"></div>
                        <ul class="header__list">
                            <li class="header__item" @mouseenter="menuLine(1)">
                                <a href="javascript:;" class="header__link">센터소개</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(2)">
                                <a href="javascript:;" class="header__link">진료의뢰</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(3)">
                                <a href="javascript:;" class="header__link">협력네트워크</a>
                            </li>
                        </ul>
                    </div>
                    <slide-up-down class="header__cont-box" :active="isMenuActive">
                        <div class="header__inner">
                            <div class="header__cont-left">
                                <h3 class="header__cont-left__title">이용안내</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        <img src="../../assets/resource/icon/header__cont-left__number01.png"  alt="이미지">
                                        TEL : 055)270-1108
                                    </li>
                                    <li>
                                        <img src="../../assets/resource/icon/header__cont-left__number02.png"  alt="이미지">
                                        FAX : 055)270-1108
                                    </li>
                                </ul>
                                <P class="header__cont-left__number--sub-text">(평일 09:00~16:40/토요일 09:00~12:00)</P>
                                <h3 class="header__cont-left__emphasis-title">야간 및 응급</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        055)270-1119,1120
                                    </li>
                                </ul>
                                <div class="header__cont-left__bottom">

                                </div>
                            </div>
                            <ul class="header__cont-list">
                                <li class="header__cont-item" @mouseenter="menuLine(1)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">소개</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">센터장 인사말</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">이용안내</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(2)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">진료의뢰 절차</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">의료진 검색</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(3)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">협력 병의원 체결 절차</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">협력 병의원 현황</a>
                                    </h2>
                                </li>
                            </ul>
                        </div>
                    </slide-up-down>

                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import SlideUpDown from 'vue-slide-up-down';

    export default {
        components: {
            SlideUpDown,
        },
        data() {
            return {
                isMenuActive: false,
                isSelectActive: false,
            };
        },
        methods: {
            activateMenu() {
                this.isMenuActive = true;
            },
            resetHeaderActive() {
                this.isMenuActive = false;
            },
            activeSelect() {
                this.isSelectActive = true;
            },
            resetSelect() {
                this.isSelectActive = false;
            },
            menuLine(item) {
                let headerLine = document.querySelector(".header__menu-wrap .line")
                if (item == 1) {
                    headerLine.style.left = '0%'
                }
                if (item == 2) {
                    headerLine.style.left = '33.33%'
                }
                if (item == 3) {
                    headerLine.style.left = '66.66%'
                }
            },
        },
        mounted() {
        },
        beforeUnmount() {
        }
    };
</script>