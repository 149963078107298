<template>
  <div class="jobHealthCenter contents job_health_check">
    <div class="info-text depth__wrap">
      <div class="depth__text">
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              작업환경측정이란 작업환경실태를 파악하기 위해 해당 근로자 또는
              작업장에 사업주가 측정 계획을 수립하여 시료 채취 및 그 분석,
              평가하는 것을 말합니다.
            </p>
            <div class="depth__wrap info-reference">
              <div class="depth__text">
                <p class="small-text">[산업안전보건법 제125조]</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">작업환경측정 목적</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              작업장에서 발생하고 있는 유해인자에 근로자가 얼마나 노출되는지를
              측정·평가하여 유해한 작업장의 시설·설비를 개선하는 등 적절한
              근로자 보호대책을 마련함으로써 쾌적한 작업환경을 조성하고 근로자의
              건강을 보호하기 위해 실시합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-text depth__wrap contents">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">작업환경측정 대상</h4>
        <div class="depth__wrap depth02">
          <div class="depth__text">
            <p>
              상시 근로자 1인 이상 고용사업장에서 유기화합물(113종),
              금속류(23종), 산 및 알칼리(17종), 가스상(15종),
              허가대상유해물질(14종), 금속가공유, 소음(80dB 이상), 고열,
              분진(6종) 등에 근로자가 얼마나 노출되는지를 측정·평가한 후
              시설·설비 등 적절한 개선을 통하여 깨끗한 작업환경을 조성함으로써
              근로자의 건강 보호 및 생산성 향상에 기여하기 위해 실시합니다.
            </p>
            <div class="depth__wrap info-reference">
              <div class="depth__text">
                <p class="small-text">[산업안전보건법 제125조]</p>
              </div>
            </div>
            <br />
            <div class="depth__wrap depth02">
              <div class="depth__text">
                <p>단, 아래의 경우는 측정대상에서 제외 됩니다.</p>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>임시작업(월24시간 미만) 및 단시간작업(1일 1시간 미만)</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>관리대상 유해물질의 허용소비량을 초과하지 않는 작업장</p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>분진적용제외 작업장</p>
                  </div>
                </div>
                <div class="depth__wrap info-reference">
                  <div class="depth__text">
                    <p class="small-text">[산업안전보건법 제186조]</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">작업환경측정 주기</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table>
          <caption class="blind">
            검사항목
          </caption>
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 80%" />
          </colgroup>
          <thead>
            <tr>
              <th>측정주기</th>
              <th>대상</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30일 이내</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업장 또는 작업공정이 신규로 가동되거나 변경되는 등의
                      측정대상 작업장
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>6월 1회</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>정기적 측정주기</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>3월 1회</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      특별관리물질의 측정치가 노출기준을 초과하는 경우의 작업장
                      또는 작업공정의 해당 유해인자
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      특별관리물질을 제외한 화학적 인자의 측정치가 노출기준을
                      2배 이상 초과하는 경우
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>년 1회</td>
              <td style="text-align: left">
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업공정 내 소음의 작업환경측정 결과가 최근 2회 연속
                      85데시벨(dB) 미만인 경우
                    </p>
                  </div>
                </div>
                <div class="depth__wrap depth02">
                  <div class="depth__bullet">
                    <span class="bullet03"></span>
                  </div>
                  <div class="depth__text">
                    <p>
                      작업공정 내 소음 외의 다른 모든 인자의 작업환경측정 결과가
                      최근 2회 연속 노출기준 미만인 경우
                    </p>
                  </div>
                </div>
                <div class="depth__wrap info-reference">
                  <div class="depth__bullet">
                    <p class="small-text">※</p>
                  </div>
                  <div class="depth__text">
                    <p class="small-text">
                      단 특별관리물질을 취급하는 작업공정 제외
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="depth__wrap info-reference">
      <div class="depth__text">
        <p class="small-text">[산업안전보건법 제190조]</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>