<template>
    <!-- 금연클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 직업환경의학센터에서는 흡연자들이 건강하게 담배를 끊을 수 있도록 금연 진료 교육을 이수한 직업환경의학과 전문의가 상담하고 약물로 치료하는 금연클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text heart_appointment center_box">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">국민건강보험공단 선정 ‘금연치료 우수기관’</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 직업환경의학과에서는 2009년부터 금연치료를 시작한 이후 2024년 현재까지 약 4,500명 이상의 흡연자를 치료하고 있습니다. 전문의 상담을 통해 약물 및 행동요법을 12주간 체계적으로 진행하고 있으며, 흡연에 대한 욕구를 줄이고 금단 증상도 효과적으로 관리해서 금연에 성공할 수 있도록 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">금연약물치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>흡연은 본인 뿐만 아니라 간접흡연으로 주변까지도 영향을 미칠 수 있으며, 국제암연구소는 간접흡연 역시 인체 발암성이 충분히 입증된 1급 발암물질로 분류하고 있습니다. </p>
                                <br>
                                <p>아직까지도 흡연을 나쁜 생활습관 정도로만 여기는 분들이 많지만 흡연은 ‘니코틴 중독’이라는 질병입니다. 일부 흡연자들은 마음만 먹으면 언제든지 끊을 수 있는 습관으로 아직 마음을 먹지 않았을 뿐이라고 얘기하지만 흡연은 쉽게 고쳐지지 않습니다. 의지만으로 금연할 수도 있지만 너무나 어렵고, 성공률도 약 3% 미만으로 낮습니다. 금연 의지만 있다면 수월하면서도 훨씬 효과적으로 금연할 수 있는 방법이 전문약물치료입니다.</p>
                                <br>
                                <p>순조롭게 1차 치료로 금연에 성공하는 경우도 많지만 치료 도중 포기하는 경우도 있고, 1차 치료에 실패했지만 포기하지 않고 여러 차례 치료를 시도해 끝내 성공하는 경우도 많습니다. 금연을 시작하기에 이미 늦었다고 생각하더라도, 몇 번의 실패를 거듭하더라도 포기하지 않으면 반드시 금연에 성공할 수 있습니다.</p>
                                <br>
                                <p>금연은 질병 예방과 건강 증진을 위한 가장 효과적이고 쉬운 방법이며, 나 자신과 소중한 가족들의 건강을 위해 더 이상 미루지 말고 금연을 시작하시기 바랍니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">금연클리닉이 필요한 경우</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>금연의지가 있는 흡연자</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">진료안내</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>금연클리닉은 예약진료만 가능합니다</p>
                                <p><b>*예약문의 : 270-1000 (통합컨택센터)</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>