// src/utils/blockedUrls.js
export const authCheckUrls = [
    '/mypage',
    '/reserve/reservation.do',
    '/reserve/reservationDoctor.do',
    '/healthcare/counselRegister.do',
    '/customer-service/customerRegister.do',
    '/praise/register.do',
    // 건진센터
    '/deptHp/1/healthcenter/PersonalReservation.do',
    '/deptHp/1/healthcenter/PersonalReservationComplete.do',
    '/deptHp/1/healthcenter/reservationInfo.do',
    '/deptHp/1/healthcenter/checkUpResult.do',
    // 직업환경의학센터
    '/deptHp/2/onecenter/selectCancerReserve.do',
    '/deptHp/2/onecenter/cancerReservation.do'
];