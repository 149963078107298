<template>
    <div class="my-page__tab">
        <ul class="my-page__list">
            <li class="my-page__item">
                <a href="javascript:;">예약조회</a>
            </li>
            <li class="my-page__item">
                <a href="javascript:;">결과조회</a>
            </li>
            <li class="my-page__item">
                <a href="javascript:;">나의 작성글</a>
            </li>
            <li class="my-page__item active">
                <a href="javascript:;">나의 건강관리</a>
            </li>
            <li class="my-page__item">
                <a href="javascript:;">즐겨찾기</a>
            </li>
            <li class="my-page__item">
                <a href="javascript:;">회원정보수정</a>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {

    }
</script>