<template>
    <div class="centerProgram myProgram">
        <div class="contents" v-if="currentStep === 0">
            <div class="myProgram_intro">
                <p class="myProgram_intro_top">
                    내게 맞는 <b class="blue__title">검진프로그램</b> 찾기
                </p>
                <p class="myProgram_intro_middle">
                    안내에 따라 자신의 요구에 맞는 정보를 선택하시면 창원파티마병원에서 운영하는 다양한<br/>프로그램 중
                    <span class="hlightCenter">고객님께 꼭 맞는 검진프로그램</span>을 찾아드립니다.
                </p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn mint" @click="nextStep(1)">시작하기</a>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 1">
            <div class="myProgram_step myProgram_step01">
                <div class="step_header">
                    <h3>Step01</h3>
                    <p>본인의 성별을 선택 해 주세요.</p>
                </div>
                <div class="step_main_box">
                    <a href="javascript:;" class="step_men" @click="[nextStep(2), gender = 'M']">
                        <img src="/resource/icon/step_men.png" alt="이미지"/>
                        <h4 class="blue__title">남성</h4>
                    </a>
                    <a href="javascript:;" class="step_women" @click="[nextStep(2), gender = 'F']">
                        <img src="/resource/icon/step_women.png" alt="이미지"/>
                        <h4>여성</h4>
                    </a>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 2">
            <div class="myProgram_step myProgram_step02">
                <div class="step_header">
                    <h3>Step02</h3>
                    <p>건강진단을 받고자 하는 동기를 선택하여 주세요.</p>
                </div>
                <div class="step_desc_box">
                    <div class="agree-input">
                        <label for="reason01" class="agree-input__item"
                               :class="{'checked-label': reason === 'reason01'}">
                            <input type="radio" id="reason01" class="agree-input__radio" name="agree" value="reason01"
                                   v-model="reason"/>
                            <span class="icon"></span> 현재는 별 이상 없지만 전반적인 건강상태를 체크하고 싶다.
                        </label>
                    </div>
                    <div class="agree-input">
                        <label for="reason02" class="agree-input__item"
                               :class="{'checked-label': reason === 'reason02'}">
                            <input type="radio" id="reason02" class="agree-input__radio" name="agree" value="reason02"
                                   v-model="reason"/>
                            <span class="icon"></span> 요즘 몸의 이상을 느껴 건강진단을 받고 싶다.
                        </label>
                    </div>

                    <div class="contents step_move_btn">
                        <a href="javascript:;" @click="prevStep(1)">
                            <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
                        </a>
                        <a href="javascript:;" @click="nextStep(reason == 'reason02' ? 5 : 3)"
                           :class="{ disabled: !reason }">
                            <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 3">
            <div class="myProgram_step myProgram_step03">
                <div class="step_header">
                    <h3>Step03</h3>
                    <p>연령을 선택해 주세요.</p>
                </div>
                <div class="step_desc_box">
                    <div class="agree-input">
                        <label for="age01" class="agree-input__item" :class="{'checked-label': age === 'age01'}">
                            <input type="radio" id="age01" class="agree-input__radio" name="agree" value="age01"
                                   v-model="age"/>
                            <span class="icon"></span> 19세 이하
                        </label>
                    </div>
                    <div class="agree-input">
                        <label for="age02" class="agree-input__item" :class="{'checked-label': age === 'age02'}">
                            <input type="radio" id="age02" class="agree-input__radio" name="agree" value="age02"
                                   v-model="age"/>
                            <span class="icon"></span> 20대 ~ 30대
                        </label>
                    </div>
                    <div class="agree-input">
                        <label for="age03" class="agree-input__item" :class="{'checked-label': age === 'age03'}">
                            <input type="radio" id="age03" class="agree-input__radio" name="agree" value="age03"
                                   v-model="age"/>
                            <span class="icon"></span> 40대 ~ 60대
                        </label>
                    </div>
                    <div class="agree-input">
                        <label for="age04" class="agree-input__item" :class="{'checked-label': age === 'age04'}">
                            <input type="radio" id="age04" class="agree-input__radio" name="agree" value="age04"
                                   v-model="age"/>
                            <span class="icon"></span> 60대 이상
                        </label>
                    </div>

                    <div class="contents step_move_btn">
                        <a href="javascript:;" @click="prevStep(2)">
                            <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
                        </a>
                        <a href="javascript:;" @click="nextStep(4)" :class="{ disabled: !age }">
                            <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 4">
            <div class="myProgram_step myProgram_step04">
                <div class="step_header">
                    <h3>Step04</h3>
                    <p>원하는 검진형태를 선택 하십시오.</p>
                </div>
                <div class="step_desc_box">
                    <div class="agree-input" v-if="age == 'age02' || age == 'age03'">
                        <label for="checkup01" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup01'}">
                            <input type="radio" id="checkup01" class="agree-input__radio" name="agree" value="checkup01"
                                   v-model="checkup"/>
                            <span class="icon"></span> 생애주기 건강진단을 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age02' || age == 'age03'">
                        <label for="checkup02" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup02'}">
                            <input type="radio" id="checkup02" class="agree-input__radio" name="agree" value="checkup02"
                                   v-model="checkup"/>
                            <span class="icon"></span> 특별한 가족력 및 생활습관에 따른 특정질환을 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age01'">
                        <label for="checkup03" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup03'}">
                            <input type="radio" id="checkup03" class="agree-input__radio" name="agree" value="checkup03"
                                   v-model="checkup"/>
                            <span class="icon"></span> 비만, A형간염, 척추측만증 등 일반적 검사를 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age01'">
                        <label for="checkup04" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup04'}">
                            <input type="radio" id="checkup04" class="agree-input__radio" name="agree" value="checkup04"
                                   v-model="checkup"/>
                            <span class="icon"></span> 정밀한 검사를 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age04'">
                        <label for="checkup05" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup05'}">
                            <input type="radio" id="checkup05" class="agree-input__radio" name="agree" value="checkup05"
                                   v-model="checkup"/>
                            <span class="icon"></span> 기본적인 건강진단을 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age04'">
                        <label for="checkup06" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup06'}">
                            <input type="radio" id="checkup06" class="agree-input__radio" name="agree" value="checkup06"
                                   v-model="checkup"/>
                            <span class="icon"></span> 특별한 가족력에 따른 건강진단을 원한다.
                        </label>
                    </div>
                    <div class="agree-input" v-if="age == 'age03' || age == 'age04'">
                        <label for="checkup07" class="agree-input__item"
                               :class="{'checked-label': checkup === 'checkup07'}">
                            <input type="radio" id="checkup07" class="agree-input__radio" name="agree" value="checkup07"
                                   v-model="checkup"/>
                            <span class="icon"></span> 광범위하고 정밀한 건강진단을 원한다.
                        </label>
                    </div>

                    <div class="contents step_move_btn">
                        <a href="javascript:;" @click="prevStep(3)">
                            <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
                        </a>
                        <a href="javascript:;"
                           @click="nextStep(checkup == 'checkup01' || checkup == 'checkup03' || checkup == 'checkup05' ? 6 : 5)"
                           :class="{ disabled: !checkup }">
                            <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 5">
            <div class="myProgram_step myProgram_step05">
                <div class="step_header">
                    <h3>Step05</h3>
                    <p v-if="checkup !== 'checkup07'">희망하는 질환 혹은 정밀검사 부위를 선택하십시오.</p>
                    <p v-else>당일 혹은 숙박 여부를 선택하십시오.</p>
                </div>
                <div class="step_desc_box">
                    <div class="agree-input" v-if="checkup !== 'checkup07'">
                        <label for="part01" class="agree-input__item" :class="{'checked-label': part === 'part01'}">
                            <input type="radio" id="part01" class="agree-input__radio" name="agree" value="part01"
                                   v-model="part"/>
                            <span class="icon"></span> 폐
                        </label>
                    </div>
                    <div class="agree-input" v-if="(checkup !== 'checkup07' && gender == 'F')">
                        <label for="part02" class="agree-input__item" :class="{'checked-label': part === 'part02'}">
                            <input type="radio" id="part02" class="agree-input__radio" name="agree" value="part02"
                                   v-model="part"/>
                            <span class="icon"></span> 갑상선/유방/자궁
                        </label>
                    </div>
                    <div class="agree-input" v-if="(checkup !== 'checkup07' && gender == 'M')">
                        <label for="part03" class="agree-input__item" :class="{'checked-label': part === 'part03'}">
                            <input type="radio" id="part03" class="agree-input__radio" name="agree" value="part03"
                                   v-model="part"/>
                            <span class="icon"></span> 전립선
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup !== 'checkup07'">
                        <label for="part04" class="agree-input__item" :class="{'checked-label': part === 'part04'}">
                            <input type="radio" id="part04" class="agree-input__radio" name="agree" value="part04"
                                   v-model="part"/>
                            <span class="icon"></span> 소화기
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup !== 'checkup07'">
                        <label for="part05" class="agree-input__item" :class="{'checked-label': part === 'part05'}">
                            <input type="radio" id="part05" class="agree-input__radio" name="agree" value="part05"
                                   v-model="part"/>
                            <span class="icon"></span> 뇌
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup !== 'checkup07'">
                        <label for="part06" class="agree-input__item" :class="{'checked-label': part === 'part06'}">
                            <input type="radio" id="part06" class="agree-input__radio" name="agree" value="part06"
                                   v-model="part"/>
                            <span class="icon"></span> 심장
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup !== 'checkup07'">
                        <label for="part07" class="agree-input__item" :class="{'checked-label': part === 'part07'}">
                            <input type="radio" id="part07" class="agree-input__radio" name="agree" value="part07"
                                   v-model="part"/>
                            <span class="icon"></span> 각종 암
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup == 'checkup07'">
                        <label for="part08" class="agree-input__item" :class="{'checked-label': part === 'part08'}">
                            <input type="radio" id="part08" class="agree-input__radio" name="agree" value="part08"
                                   v-model="part"/>
                            <span class="icon"></span> 당일
                        </label>
                    </div>
                    <div class="agree-input" v-if="checkup == 'checkup07'">
                        <label for="part09" class="agree-input__item" :class="{'checked-label': part === 'part09'}">
                            <input type="radio" id="part09" class="agree-input__radio" name="agree" value="part09"
                                   v-model="part"/>
                            <span class="icon"></span> 숙박
                        </label>
                    </div>

                    <div class="contents step_move_btn">
                        <a href="javascript:;" @click="prevStep(flow == '1-2-5' ? 2 : 4)">
                            <img src="/resource/icon/step_prev.png" alt="이미지" title="이전">
                        </a>
                        <a href="javascript:;" @click="nextStep(6)" :class="{ disabled: !part }">
                            <img src="/resource/icon/step_next.png" alt="이미지" title="다음">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contents" v-if="currentStep === 6">
            <div class="myProgram_step myProgram_stepLast">
                <div class="step_header">
                    <h3>고객님께 추천 드리는 검진</h3>
                </div>
                <div class="step_desc_box">
                    <!-- 추천 코스 추가 내용 -->
                    <div class="step_box_last" v-if="checkup == 'checkup01' && age == 'age02'">
                        <h3><span class="mint_title">그린코스</span>를 추천합니다.</h3>
                        <p>우리나라에서 유병률 및 발생률이 높은 생활습관병 및 암을 조기진단하기 위한 가장 기본적인 건강검진 프로그램 입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('그린코스', [])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="checkup == 'checkup03' && age == 'age01'">
                        <h3><span class="mint_title">블루코스</span>를 추천합니다.</h3>
                        <p>미래세대(수험생 또는 20대초반)의 신체적 특성을 고려한 전문건강검진 프로그램 입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=blue" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('블루코스', [])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="checkup == 'checkup01' && age == 'age03'">
                        <h3><span class="mint_title">골드코스</span>를 추천합니다.</h3>
                        <p>중년이후의 남녀를 대상으로 갱년기에 흔히 발생할 수 있는 질환의 가능성을 확인하고 건강한 노후를 대비하고자 하시는 분을 위한 건강검진 프로그램 입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=gold" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('골드코스', [])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="checkup == 'checkup05' && age == 'age04'">
                        <h3><span class="mint_title">실버코스</span>를 추천합니다.</h3>
                        <p>우리나라에서 유병률 및 발생률이 높은 생활습관병 및 암을 조기진단하기 위한 가장 기본적인 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=silver" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('실버코스', [])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part01'">
                        <h3><span class="mint_title">그린코스 + 흉부CT</span> 를 추천합니다.</h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 흉부 CT 추가로 호흡기질환 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('그린코스', ['MD-CT(흉부)'])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="(part == 'part02' && gender == 'F')">
                        <h3><span class="mint_title">그린코스 + 갑상선 & 유방 초음파</span> / <span class="mint_title">호르몬 검사</span>를
                            추천합니다.</h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 갑상선 초음파, 유방초음파, 호르몬검사 추가로 여성질환 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line"
                               @click="getCheckup('그린코스', ['갑상선초음파', '유방초음파'])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="(part === 'part03' && gender == 'M')">
                        <h3><span class="mint_title">그린코스 + 전립선 초음파 & 호르몬 검사</span> 를 추천합니다.</h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 전립선 초음파, 호르몬검사 추가로 남성질환 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line"
                               @click="getCheckup('그린코스', ['전립선초음파', '남성호르몬검사'])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part04'">
                        <h3><span class="mint_title">그린코스 + 대장 내시경</span> 를 추천합니다.</h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 대장내시경 추가로 소화기질환 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('그린코스', ['대장내시경'])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part05'">
                        <h3><span class="mint_title">그린코스 + 경동맥 초음파</span> 를 추천합니다.</h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 경동맥초음파 추가로 뇌졸중 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('그린코스', ['경동맥초음파'])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part06'">
                        <h3><span class="mint_title">그린코스 + 심장 초음파</span> / <span class="color1">심장 CT</span>를 추천합니다.
                        </h3>
                        <p>주요 질환 조기진단을 위한 그린코스에 심장초음파, 심장CT 추가로 심장질환 진단 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program01.do?course=green" class="btn">그린코스 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('그린코스', ['심장초음파'])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part01'">
                        <h3><span class="mint_title">호흡기 정밀코스</span>를 추천합니다.</h3>
                        <p>각종 성인병의 조기발견 및 폐질환, 폐암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=resp" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('호흡기정밀코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="(part == 'part02' && gender == 'F')">
                        <h3><span class="mint_title">여성 정밀코스</span>를 추천합니다.</h3>
                        <p>여성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=femaleA" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('여성정밀 A코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="(part === 'part03' && gender == 'M')">
                        <h3><span class="mint_title">남성 정밀코스</span>를 추천합니다.</h3>
                        <p>남성에게서 흔히 보일 수 있는 성인병 및 각종 암의 조기발견을 위한 맞춤형 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=male" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('남성정밀코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part04'">
                        <h3><span class="mint_title">소화기 정밀코스</span>를 추천합니다.</h3>
                        <p>각종 성인병의 조기발견 및 소화기 계통의 질환, 암 등의 조기진단과 예방을 위한 맞춤형 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=dige" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('소화기정밀코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part05'">
                        <h3><span class="mint_title">뇌 정밀코스</span>를 추천합니다.</h3>
                        <p>각종 성인병의 조기발견, 뇌혈관 질환(뇌졸중 등)의 조기진단과 예방 및 가족력, 위험인자가 있는 분들을 위한 맞춤형 건강검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=brain" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('뇌정밀코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part06'">
                        <h3><span class="mint_title">순환기 정밀코스</span>를 추천합니다.</h3>
                        <p>각종 성인병의 조기발견 및 심혈관 질환의 조기진단과 예방, 흡연, 고혈압, 당뇨, 심장질환의 가족력 등 심혈관 질환의 위험인자가 있는 분들을 위한 맞춤형 건강검진
                            프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program02.do?course=circ" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('순환기정밀코스', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part07'">
                        <h3><span class="mint_title">PET 종합검진</span>를 추천합니다.</h3>
                        <p>건강증진코스(Green course)에 PET-CT를 시행하여 조기에 암을 발견하기 위한 암전문 검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program03.do" class="btn">자세한 내용보기</router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('조기암(PET-CT)검진', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part08'">
                        <h3><span class="mint_title">종합정밀 Ⅰ(당일)</span>를 추천합니다.</h3>
                        <p>MRI-MRA가 포함된 뇌심혈관계 질환 확인 및 주요장기의 암을 확인하기 위한 당일 프리미엄검진 프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program04.do" class="btn">자세한 내용보기</router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('종합정밀 I(당일)', [])">건강검진예약 바로가기</a>
                        </div>
                    </div>

                    <div class="step_box_last" v-if="part == 'part09'">
                        <h3><span class="mint_title">종합정밀 Ⅱ(숙박)</span>를 추천합니다.</h3>
                        <p>심장마비, 뇌졸중, 각종 주요장기의 암 등의 위험인자를 조기발견하기 위한 1박 2일 검진으로 검진 시 VIP 전담간호사가 동행하는 가장 광범위한 정밀검진
                            프로그램입니다.</p>
                        <div class="btn-wrap center">
                            <router-link to="/deptHp/1/healthcenter/program04.do?course=total2" class="btn">자세한 내용보기
                            </router-link>
                            <a href="javascript:;" class="btn blue-line" @click="getCheckup('종합정밀 I(숙박)', [])">건강검진예약
                                바로가기</a>
                        </div>
                    </div>
                    <!-- 추천 코스 추가 내용 -->
                </div>
            </div>

            <div class="btn-wrap center contents">
                <a href="/deptHp/1/healthcenter/ScreeningProgram.do" class="btn gray" @click="goFirstStep">처음으로</a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentStep: 0,
            prevCheck: '',
            flow: '',
            gender: '',
            reason: '',
            age: '',
            checkup: '',
            part: '',
        }
    },
    methods: {
        nextStep(step) {
            // 라디오 버튼이 필요한 스텝일 때 체크 상태 확인
            if (this.currentStep == 2 && !this.reason) {
                alert('하나를 선택해주세요.')
                return
            }
            if (this.currentStep == 3 && !this.age) {
                alert('하나를 선택해주세요.')
                return
            }

            this.currentStep = step
            this.flow = this.flow ? this.flow + `-${this.currentStep}` : this.currentStep
        },
        prevStep(step) {
            this.currentStep = step
            if (this.currentStep == 2) this.gender = ''
            if (this.currentStep == 3) this.reason = ''
            if (this.currentStep == 4) this.age = ''
            if (this.currentStep == 5) this.checkup = ''
            this.flow = this.flow.slice(0, this.flow.length - 2)
        },
        goFirstStep() {
            this.currentStep = 1
            this.flow = ''
            this.gender = ''
            this.reason = ''
            this.age = ''
            this.checkup = ''
            this.part = ''
        },
        getCheckup(program, option) {
            if (this.$store.state.memberStore.status && this.$store.state.myInfoStore.memGender) {
                if (program == '여성정밀 A코스' && this.$store.state.myInfoStore.memGender == 'M') {
                    alert('해당 코스는 여성전용 건강검진 코스입니다.')
                    return
                }
            }
            const data = {
                program: program,
                option: option
            }
            this.$store.commit('setCprsvInfo', data)
            const url = `/deptHp/${this.$route.params.deptCd}/${this.$route.params.hpUrl}/PersonalReservation.do`
            window.open(url)
            // this.$router.push(url)
        }
    },
}
</script>
  