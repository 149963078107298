<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('department')">진료과 안내</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('doctor')">의료진 검색</button></li>
                <li class="tab-menu__item on"><button type="button" class="tab-menu__btn">센터&클리닉</button></li>
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="goMenu('treatmentChart')">진료일정표</button></li>
            </ul>
        </div>
    </div>
    <div class="initial-search">
        <h3>초성검색</h3>
        <consonant @consonant="searchConsonant" :list="consonantList"></consonant>
    </div>


    <div class="contents">
        <div class="info-text" v-if="centerList.length > 0">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">특성화 센터</h4>
                </div>
            </div>
            <ul class="med-departments__list">
                <li class="med-departments__list--item" v-for="center in centerList" :key="center" >
                    <a :href="`/deptHp/${center.deptCd}/${center.hpUrl}/main.do`" target="_blank" class="target" v-if="center.hpUseYn == 'Y'">
                        <div class="med-departments__list--img">
                            <img :src="`/displayFile.do?name=${center.thumbAttach.savedName}`"
                                alt="이미지" v-if="center.thumbAttach.savedName">
                        </div>
                        <h4>{{center.deptNm}}</h4>
                    </a>
                    <router-link :to="center.urlAddress" v-else-if="center.urlAddress">
                        <div class="med-departments__list--img">
                            <img :src="`/displayFile.do?name=${center.thumbAttach.savedName}`"
                                alt="이미지" v-if="center.thumbAttach.savedName">
                        </div>
                        <h4>{{center.deptNm}}</h4>
                    </router-link>
                    <router-link  :to="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${center.deptCd}`" v-else>
                        <div class="med-departments__list--img">
                            <img :src="`/displayFile.do?name=${center.thumbAttach.savedName}`"
                                alt="이미지" v-if="center.thumbAttach.savedName">
                        </div>
                        <h4>{{center.deptNm}}</h4>
                    </router-link >
                </li>
            </ul>
        </div>
        <div class="info-text" v-if="clinicList.length > 0">
            <div class="depth__wrap depth01" v-if="clinicList.length > 0">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">전문 클리닉</h4>
                </div>
            </div>
            <ul class="med-departments__list">
                <li class="med-departments__list--item" v-for="clinic in clinicList" :key="clinic">
                    <router-link  :to="`/hospital/department-doctor/centerClinicDetail.do?deptCd=${clinic.deptCd}`">
                        <div class="med-departments__list--img">
                            <img :src="`/displayFile.do?name=${clinic.thumbAttach.savedName}`"
                                alt="이미지" v-if="clinic.thumbAttach.savedName">
                        </div>
                        <h4>{{clinic.deptNm}}</h4>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Consonant from '../common/Consonant.vue'
export default {
    components: {
        Consonant
    },
    data() {
        return {
            clinicList: [],
            centerList: [],
            orgList: '',
            consonantList: '',
        }
    },
    methods: {
        goMenu(path){
            this.$router.push(`/hospital/department-doctor/${path}.do`)
        },
        searchConsonant(str){
            this.clinicList = []
            this.centerList = []
            const list = str == '' ? this.orgList : this.$commonUtils.listToConList(this.orgList, 'deptNm', str)
            for(let i of list){
                if(i.deptClsf == 'B') this.clinicList.push(i)
                if(i.deptClsf == 'C') this.centerList.push(i)
            }
        }
    },
    mounted() {
        
    },
    async created() {
        const clinic = await this.axios.get(this.api.department.list, {
            params: {
                instNo: 1,
                deptClsf: 'B',
                startIndex: 1,
                langType: 'kr',
                orderType: 'sortOrd'
            }
        });
        const center = await this.axios.get(this.api.department.list, {
            params: {
                instNo: 1,
                deptClsf: 'C',
                startIndex: 1,
                langType: 'kr',
                orderType: 'sortOrd'
            }
        });
        this.clinicList = clinic.data.deptList
        this.centerList = center.data.deptList
        this.orgList = this.clinicList.concat(this.centerList)
        if(!this.consonantList){
            this.consonantList = this.$commonUtils.setConsonantList(this.orgList, 'deptNm')
        }
    },
}
</script>
