<template>
    <div class="board-webzine__back"></div>

    <template v-if="board.boardId == 'fatimasam'">
        <div class="contents" v-for="([key, value], index) in sortedEntries" :key="index">
            <div class="info-text depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">{{key}}</h4>
                </div>
            </div>
            <ul class="board-webzine__list type02">
                <li class="board-webzine__item" v-for="(i, idx) in value" :key="idx">
                    <a :href="i.linkUrl" target="_blank">
                        <div class="board-webzine__img">
                            <img :src="`/displayFile.do?name=${ i.attachBag.thumbs[0].savedName }`" alt="이미지" v-if="i.attachBag.thumbs != null">
                            <div class="board-webzine__text">
                                <span class="board-webzine__plus"></span>
                            </div>
                        </div>
                        <div class="board-webzine__con">
                            <h3 class="depth02__title">{{i.item2}}</h3>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </template>

    <template v-else>
        <div class="contents" v-for="([key, value], index) in sortedEntries" :key="key">
            <div class="info-text depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">{{key}}</h4>
                </div>
            </div>
            <ul class="board-webzine__list">
                <li class="board-webzine__item" v-for="(i, idx) in value" :key="idx">
                    <a :href="i.linkUrl" target="_blank">
                        <div class="board-webzine__img">
                            <img :src="`/displayFile.do?name=${ i.attachBag.thumbs[0].savedName }`" alt="이미지" v-if="i.attachBag.thumbs != null">
                            <div class="board-webzine__text">
                                <div class="board-webzine__title">
                                    <h3 class="depth02__title">{{i.item2}}</h3>
                                </div>
                                <span class="board-webzine__plus"></span>
                            </div>
                        </div>
                        
                    </a>
                </li>
            </ul>
        </div>
    </template>
</template>

<script>
import Search from '../common/SearchBox.vue'
import Pagination from '../common/Pagination.vue'

export default{
    computed: {
        sortedEntries() {
        return Object.entries(this.list)
            .sort(([keyA], [keyB]) => keyB.localeCompare(keyA));
        }
    },
    components: {
        Search,
        Pagination
    },
    props: {
        boardInfo: ''
    },
    data() {
        return {
            board: {},
            list: '',
            pageList: '',
            totalPage: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 999,
            //검색 관련 parameter
            title: this.$route.query.title || '',
            content: this.$route.query.content || '',
            searchKeyword: this.$route.query.searchKeyword || '',

            // 검색박스 셀렉트 옵션 리스트
            searchArr: [
                {'title':'전체', 'value':''}
                , {'title':'제목', 'value':'title'}
                , {'title':'내용', 'value':'content'}
            ],
        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if(to.query != from.query && to.path == from.path){
                if(Object.keys(to.query).length === 0) this.init()
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }

                this.getList()
            }
        },
        // 다른 게시판으로 이동
        boardInfo(oldVal, newVal){
            this.init()
            this.getList()
        }
    },
    methods: {
        getList(){
            const boardNo = this.boardInfo.boardNo
            const params = {
                instNo: 1,
                boardNo: boardNo,
                startIndex: this.currentNum,
                pageRow: this.pageRow,
                title: this.title,
                content: this.content,
                searchKeyword: this.searchKeyword,
            }
            this.axios.get(this.api.article.list + boardNo, {
                params: params
            })
                .then(res => {
                    this.list = res.data.list.reduce((acc, current) => {
                        const key = current.item1;
                        console.log(key)
                        
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        
                        acc[key].push(current);
                        
                        return acc;
                    }, {});
                    console.log(this.list)

                    this.board = res.data.board
                    this.totCnt = res.data.totCnt
                })
        },
        // 검색 emit
        setState(s, v, p){
            this[p] = ''
            this.currentNum = 1
            this[s] = v
            this.goPage()
        },
        // pagination emit
        setNum(n){
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = {currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword};
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj});
        },
        // 데이터 초기화
        init(){
            this.currentNum = 1
            this.title = ''
            this.content = ''
            this.searchKeyword = ''
        }
    },
    mounted() {
        this.getList()
    },
}
</script>

