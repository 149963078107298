<template>
    <div class="healthCheckupReserve healthCheckupReserve02">
      <div class="contents">
        <div class="healthCheckupReserve_top_box">
          <img src="/resource/icon/checkup_result_icon.png" alt="이미지" />
          <h3>건강검진 예약신청 접수가 완료되었습니다.<br>담당자 확인 후 곧 연락드리겠습니다.</h3>
          <p>문의사항 : 055-270-1200~1</p>
        </div>
      </div>
      <div class="contents">
        <div class="info-text depth__wrap depth01">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">수검자정보</h4>
            <div class="depth__wrap depth02">
              <div class="depth__text">
                <div class="depth__wrap">
                  <div class="depth__text">
                    <p>
                      수신자의 정보가 정확하지 않을 경우 정상적으로 예약이 되지
                      않을 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-form type1 mt40">
          <ul class="input-form__list">
            <li class="input-form__item item2">
              <div class="input-form__box">
                <div class="tit">이름</div>
                <div class="cont">
                  <p>{{$store.state.myInfoStore.memName}}</p>
                </div>
              </div>
              <div class="input-form__box">
                <div class="tit">생년월일</div>
                <div class="cont">
                  <p>{{$commonUtils.makeDateFormat($store.state.myInfoStore.memBirth, '.')}}</p>
                </div>
              </div>
            </li>
            <li class="input-form__item item2">
              <div class="input-form__box">
                <div class="tit">성별</div>
                <div class="cont">
                  <p>{{$store.state.myInfoStore.memGender == 'M' ? '남성' : $store.state.myInfoStore.memGender == 'F' ? '여성' : ''}}</p>
                </div>
              </div>
              <div class="input-form__box">
                <div class="tit">연락처</div>
                <div class="cont">
                  <p>{{$store.state.myInfoStore.memHpNo}}</p>
                </div>
              </div>
            </li>
          </ul>
          <p class="input_additional">
            ※ 개인정보수정은 병원 홈페이지에서 가능합니다.
          </p>
        </div>
      </div>
  
      <div class="contents">
        <div class="info-text depth__wrap depth01">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">예약정보</h4>
          </div>
        </div>
  
        <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
          <div class="scroll">
            <table>
              <caption class="blind">표</caption>
              <colgroup>
                <col style="width: 18%" />
                <col style="width: 18%" />
                <col style="width: 64%" />
              </colgroup>
              <tbody>
                <tr>
                  <td style="background-color: #f3f3f3;" colspan="2">건강검진 종류</td>
                  <td style="text-align:left">{{info.pkgnm}}</td>
                </tr>
                <tr>
                  <td style="background-color: #f3f3f3;" colspan="2">예약일</td>
                  <td style="text-align:left">{{$commonUtils.makeDateFormat(info.reser_date, '.')}}({{ ($commonUtils.getWeek($commonUtils.makeDateFormat(info.reser_date, '.'))) }})</td>
                </tr>
                <tr>
                  <td style="background-color: #f3f3f3;" colspan="2">추가검사 내용</td>
                  <td style="text-align:left">{{info.options}}</td>
                </tr>
                <tr>
                  <td style="background-color: #f3f3f3;" rowspan="3">건강진단<br>비용</td>
                  <td style="background-color: #f3f3f3;" >검진코스</td>
                  <td style="text-align:left">{{$commonUtils.commaNum(info.coursePrice)}} 원</td>
                </tr>
                <tr>
                <td style="background-color: #f3f3f3; border-left:1px solid #d9d9d9">추가검사</td>
                <td style="text-align:left">{{$commonUtils.commaNum(info.optionPrice)}} 원</td>
              </tr>
              <tr>
                <td style="background-color: #f3f3f3; border-left:1px solid #d9d9d9">합계</td>
                <td style="text-align:left">{{$commonUtils.commaNum(info.coursePrice + info.optionPrice)}} 원</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="input_additional">※ 직원과 상담 후 일정이 변경될 수 있습니다.</p>
      </div>
  
      <div class="contents">
          <div class="btn-wrap center">
            <router-link :to="`/deptHp/${$route.params.deptCd}/${$route.params.hpUrl}/reservationInfo.do`" class="btn blue-line">예약확인</router-link>
            <router-link :to="`/deptHp/${$route.params.deptCd}/${$route.params.hpUrl}/main.do`" class="btn" style="font-weight: 700">메인 페이지 이동</router-link>
          </div>
      </div>
  
    </div>
</template>

<script>
import {course, optionList} from './course_option'
export default {
    data() {
        return {
            info: '',
            course: course,
            option: optionList,
            isActive: false,
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
                
            }
        }
    },
    methods: {
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
      },
        addClass() {
            if (!this.isActive) {
                this.isActive = true;
            }
      },

    },
    mounted() {
        // 수검자조회
        const param = {
            healexamflag: 'C'
        }
        this.axios.get(this.api.appointment.selectIntApp, {
          params: param
        }).then(res => {
            const data = this.$store.state.reserveStore.cprsv
            for(let i of res.data){
                if(i.healexamflag == data.healexamflag && i.pkgcd == data.pkgcd && i.reser_date == data.reser_date){
                    this.info = i
                    break
                }
            }
            
            const weekdays = ['일', '월', '화', '수', '목', '금', '토']
            const reserDate = this.$commonUtils.makeDateFormat(this.info.reser_date, '.')
            const week = weekdays[new Date(reserDate).getDay()]
            this.info.date = `${reserDate}(${week})`
            
            const arr = this.info.remcnts.split('/')
            const options = arr.filter(i => i != this.info.pkgcd).join(', ')
            this.info.options = options
            this.info.option = []
            let optionPrice = 0
            if(options){
                for(let i in this.option){
                    for(let j of arr){
                        if(i == j){
                            this.info.option.push(i)
                            optionPrice += this.option[i]['PRICE']
                        }
                    }
                }
            }
            this.info.optionPrice = optionPrice

            for(let i in this.course){
                if(this.course[i][this.$store.state.myInfoStore.memGender] && this.course[i][this.$store.state.myInfoStore.memGender]['CODE'] == this.info.pkgcd){
                    this.info.coursePrice = this.course[i][this.$store.state.myInfoStore.memGender]['PRICE']
                }
            }

            for(let i in this.course){
                if(this.course[i][this.$store.state.myInfoStore.memGender] && this.course[i][this.$store.state.myInfoStore.memGender]['CODE'] == this.info.pkgcd){
                    this.info.coursePrice = this.course[i][this.$store.state.myInfoStore.memGender]['PRICE']
                }
            }
        }).catch(err => {
            console.error(err)
        })
        // window.addEventListener('beforeunload', () => {
        //     this.$store.commit('setCprsv', '')
        // });
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
    beforeUnmount() {
        this.$store.commit('setCprsv', '')
    },
}
</script>

  