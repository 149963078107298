<template>
    <div class="calendar">
        <div class="calendar__header">
            <button class="calendar__header--button prev" @click="prevCalendar"></button>
            <h4 class="calendar__header--title">{{calYear}}.{{calMonth}}</h4>
            <button class="calendar__header--button next" @click="nextCalendar"></button>
        </div>
        <div class="calendar__hcon">
            <table>
                <caption>
                    예약일 선택 테이블
                </caption>
                <colgroup>
                    <col width="14.29%">
                    <col width="14.29%">
                    <col width="14.29%">
                    <col width="14.29%">
                    <col width="14.29%">
                    <col width="14.29%">
                    <col width="14.29%">
                </colgroup>
                <thead>
                <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                </tr>
                </thead>
                <tbody>
                    <!-- today: 금일 / pos: 예약가능 / active: 선택 -->
                    <tr v-for="item in arrChunk(calendarList)" :key="item">
                        <td v-for="i in item" :key="i" :class="[i.dayType, {'active' : i.text == activeIndex}]">
                            <a href="javascript:;" @click="[callParentFunction(i), activeIndex = i.text]">
                                {{ i.dayType == 'pastMonth' || i.dayType == 'futureMonth' ? '' : i.day }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="calendar__bottom">
            <p><span class="today"></span>오늘</p>
            <p><span class="pos"></span>예약가능</p>
            <p><span class="active"></span>선택</p>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        // array object
        // ex: [{text: '20240505', dayType: 'pos'}]
        classList: ''
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            today: new Date(),
            calendarList: '',
            calYear: '',
            calMonth: '',
            activeIndex: '',
        }
    },
    watch: {
        classList(newVal) {
            this.init();
        }
    },
    created() {
        this.init();
    },
    methods: {
        /**
         * 이번 달의 첫 날, 마지막 날 구하기
         */
        init(){
            this.startDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
            this.endDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
            this.createCalendar();
        },
        /**
         * 달력 생성
         */
        createCalendar(){
            this.activeIndex = ''
            this.calendarList = [];
            this.calYear = this.today.getFullYear();
            this.calMonth = this.$commonUtils.leftPad(this.today.getMonth() + 1);

            if(this.startDate.getDay() > 0){
                let preLastSunDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), ((this.startDate.getDay() - 1) * -1));
                let preLastDate = new Date(preLastSunDate.getFullYear(), preLastSunDate.getMonth() + 1, 0);

                // 지난달 구하기
                for (let preDay = preLastSunDate; preDay <= preLastDate; preDay.setDate(preDay.getDate() + 1)) { 
                    let date = {
                        year : preDay.getFullYear(),
                        month : this.$commonUtils.leftPad(preDay.getMonth() + 1),
                        day : this.$commonUtils.leftPad(preDay.getDate()),
                        week : preDay.getDay(), // 0~6
                        text : this.$commonUtils.dateToStr(preDay, ''),
                        dayType : 'pastMonth'
                    }

                    this.calendarList.push(date);
                }
            }

            let today = new Date();
            today.setHours(0, 0, 0, 0); // 시간 초기화

            // 이번달 구하기
            for (let nowDay = this.startDate; nowDay <= this.endDate; nowDay.setDate(nowDay.getDate() + 1)) { 
                let date = {
                    year : nowDay.getFullYear(),
                    month : this.$commonUtils.leftPad(nowDay.getMonth() + 1),
                    day : this.$commonUtils.leftPad(nowDay.getDate()),
                    week : nowDay.getDay(),
                    text : this.$commonUtils.dateToStr(nowDay, ''),
                    dayType : ''
                }

                if (nowDay < today) {
                    date.dayType = "pastDay";
                } else if (nowDay.getFullYear() == today.getFullYear() 
                            && nowDay.getMonth() == today.getMonth() && nowDay.getDate() == today.getDate()) {    
                    date.dayType = "today";
                }else {
                    date.dayType = "futureDay";
                }

                this.calendarList.push(date);
            }
           
            // 다음달 구하기
            if(this.endDate.getDay() < 6){
                let nextFirstDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() + 1, 1);
                let nextFirstSunDate = new Date(nextFirstDate.getFullYear(), nextFirstDate.getMonth(), ((this.endDate.getDay() - 7 ) * -1) -1);
                
                for (let nextDay = nextFirstDate; nextDay <= nextFirstSunDate; nextDay.setDate(nextDay.getDate() + 1)) { 
                    let date = {
                        year : nextDay.getFullYear(),
                        month : this.$commonUtils.leftPad(nextDay.getMonth() + 1),
                        day : this.$commonUtils.leftPad(nextDay.getDate()),
                        week : nextDay.getDay(),
                        text : this.$commonUtils.dateToStr(nextDay, ''),
                        dayType : 'futureMonth'
                    }

                    this.calendarList.push(date);
                }
            }

            // dayType에 class 부여
            if(this.classList){
                for(let i of this.calendarList){
                    for(let j of this.classList){
                        if(i.text == j.text){
                            i.dayType += ` ${j.dayType}`
                        }
                    }
                }
            }
        },
        todayCalendar(){
            this.today = new Date();
            this.init();
        },
        prevCalendar(){
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
            this.$emit('getClass', this.$parent.selectedDoctor, `${this.today.getFullYear()}${this.$commonUtils.leftPad(this.today.getMonth()+1)}`)
            this.init();
        },
        nextCalendar(){
            this.today = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
            this.$emit('getClass', this.$parent.selectedDoctor, `${this.today.getFullYear()}${this.$commonUtils.leftPad(this.today.getMonth()+1)}`)
            this.init();
        },
        arrChunk(arr){
            return arr.reduce(function (acc, val, index) {
                const chunkIndex = Math.floor(index / 7);
                if (!acc[chunkIndex]) {
                    acc[chunkIndex] = [];
                }
                acc[chunkIndex].push(val);
                return acc;
            }, []);
        },
        callParentFunction(i){
            this.$emit('clickFnc', i);
        },
        setActiveIndex(idx){
            this.activeIndex = idx
        }
    }
}
</script>
