<template>
    <div class="join contents">
        <div class="certification">
            <ul class="contents login-box__list">
                <li class="login-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                    <h3 class="tit">본인 인증</h3>
                    <p class="desc">본인명의 휴대폰</p>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.fnPopup('sleep')">
                            <span>인증하기</span>
                        </a>
                    </div>
                </li>
                <li class="login-box__item">
                    <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                    <h3 class="tit">아이핀 인증</h3>
                    <p class="desc">아이핀 ID/PW</p>
                    <div class="btn-wrap center">
                        <a href="javascript:;" class="btn blue" title="새 창 열림" @click="this.$refs.NiceIdVue.popIpinAuth('sleep')">
                            <span>인증하기</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="contents info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">유의사항</h4>
            <div class="depth__wrap depth02">
                <div class="depth__text">
                    <div class="depth__wrap depth03">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>본인인증 방법 선택 후 팝업창이 나타나지 않으면 브라우저의 팝업차단을 해제해 주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet"><span class="bullet03"></span></div>
                                <div class="depth__text">
                                    <p>아이핀(I-PIN) 인증 또는 휴대폰 인증시 장애가 있으신 경우는 나이스평가정보 실명확인 서비스 기관에 문의하시기 바랍니다. <br>
                                        나이스평가정보 본인 인증 콜센터 : 1600-1522</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NiceIdVue from '../util/NiceId.vue'
export default {
    components: {
        NiceIdVue
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        
    },
}
</script>