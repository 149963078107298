<template>
    <div>
        <div class="medical-chart">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">대기순번</h4>
                    <div v-if="list.length == 0">
                        <div class="doctor-list__data-none">
                            <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                            <h4 class="depth03__title">대기중인 진료가 없습니다.</h4>
                        </div>
                    </div>
                    <ul class="contents medical-chart__list" v-else>
                        <li class="medical-chart__item" v-for="i in list" :key="i">
                            <div class="icon">
                                <img :src="`/displayFile.do?name=${i.attachBag.savedName}`" alt="이미지"
                                    v-if="i.attachBag">
                                <div class="name">{{i.orddeptnm}}</div>
                            </div>
                            <div class="count">순번{{ i.ordrsrvseqno }}</div>
                        </li>
                    </ul>
                    <p class="contents medical-chart__text">
                        진료 당일 진료과 방문하여 성함을 말씀하시고 도착 확인이 된 이후부터 대기 순번을 조회를 할 수 있습니다. <br>
                        <span class="c-red">※ 응급 환자나 응급 면담으로 인해 대기 순번이 조정될 수 있으며, 응급 시술 및 수술로 인해 대기 시간이 지연될 수 있으니 양해 바랍니다.</span>
                    </p>
                </div>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn" @click="refreshPage">
                    <div class="icon">
                        <img src="@/assets/resource/icon/refresh.png" alt="이미지">
                    </div> 새로고침
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
        }
    },
    methods: {
        refreshPage() {
            window.location.reload();
        },
        getQueue(){
            this.axios.get(this.api.mypage.queue)
            .then(res => {
                const list = res.data.result
                for(let i of list){
                    if(Object.keys(i).length > 0){
                        this.list.push(i)
                    }
                }
                if(this.list.length > 0){
                    this.axios.get(this.api.department.list, {
                        params: {
                            instNo: 1,
                            deptClsf: 'A',
                            startIndex: 1,
                            langType: 'kr'
                        }
                    }).then(dept => {
                        const deptList = dept.data.deptList
                        for(let i of this.list){
                            for(let j of deptList){
                                if(i.orddeptcd == j.deptCd){
                                    i.attachBag = j.thumbAttach
                                }
                            }
                        }
                        console.log(this.list)
                    })
                }
            })
        }
    },
    mounted() {
        this.getQueue()
    },
}
</script>
<style>

</style>