<template>
   <div class="map_area">
        <div class="contents">
            <div class="map full-width">
                <div id="daumRoughmapContainer1720157832966" class="root_daum_roughmap root_daum_roughmap_landing"></div>
                <div class="map_info">
                        <div class="map_info_con">
                            <div class="map_info_txt">
                                <div class="map_item map_item01" >
                                    <img src="/resource/icon/map_pin_icon.png" alt="이미지">
                                    <h4>주소</h4>
                                    <p>창원시 의창구 창이대로 45 (우편번호 51394)</p>
                                </div>
                                <div class="map_item map_item02">
                                    <img src="/resource/icon/map_phone_icon.png" alt="이미지">
                                    <h4>전화</h4>
                                    <p><b>055) 270-1000</b></p>
                                </div>
                                <div class="btn-wrap contents">
                                    <a href="https://map.kakao.com/?urlX=874546.0&urlY=486625.0&itemId=8103985&q=%EC%B0%BD%EC%9B%90%ED%8C%8C%ED%8B%B0%EB%A7%88%EB%B3%91%EC%9B%90&srcid=8103985&map_type=TYPE_MAP&from=roughmap" class="btn blue" target="_blank" title="새 창 열기"><img src="/resource/icon/map_pin_icon.png" alt="이미지">길찾기</a>
                                </div>
                            </div>`
                        </div>
                </div>
            </div>
            <div class="map_info_mo full-width">
                <div class="map_info_con">
                    <div class="map_info_txt">
                        <div class="map_item map_item01" >
                            <img src="/resource/icon/map_pin_icon.png" alt="이미지">
                            <h4>주소</h4>
                            <p>창원시 의창구 창이대로 45 (우편번호 51394)</p>
                        </div>
                        <div class="map_item map_item02">
                            <img src="/resource/icon/map_phone_icon.png" alt="이미지">
                            <h4>전화</h4>
                            <p><b>055) 270-1000</b></p>
                        </div>
                        <div class="btn-wrap contents">
                            <a href="https://map.kakao.com/?urlX=874546.0&urlY=486625.0&itemId=8103985&q=%EC%B0%BD%EC%9B%90%ED%8C%8C%ED%8B%B0%EB%A7%88%EB%B3%91%EC%9B%90&srcid=8103985&map_type=TYPE_MAP&from=roughmap" class="btn blue" target="_blank" title="새 창 열기"><img src="/resource/icon/map_pin_icon.png" alt="이미지">길찾기</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map_btn full-width">
                <div class="map_potal_btn naver">
                    <a href="https://map.naver.com/p/directions/-/14320797.5166638,4196175.2554716,%EC%B0%BD%EC%9B%90%ED%8C%8C%ED%8B%B0%EB%A7%88%EB%B3%91%EC%9B%90,13178547,PLACE_POI/-/transit?c=15.00,0,0,0,dh" target="_blank" title="새 창 열기">
                        <img src="/resource/icon/naver_logo.png" alt="이미지">
                        네이버 길찾기
                    </a>
                </div>
                <div class="map_potal_btn daum">
                    <a href="https://map.kakao.com/?urlX=874546.0&urlY=486625.0&itemId=8103985&q=%EC%B0%BD%EC%9B%90%ED%8C%8C%ED%8B%B0%EB%A7%88%EB%B3%91%EC%9B%90&srcid=8103985&map_type=TYPE_MAP&from=roughmap" target="_blank" title="새 창 열기">
                        <img src="/resource/icon/daum_logo.png" alt="이미지">
                        다음 길찾기
                    </a>
                </div>
                <div class="map_potal_btn google">
                    <a href="https://www.google.co.kr/maps/dir//%EA%B2%BD%EC%83%81%EB%82%A8%EB%8F%84+%EC%B0%BD%EC%9B%90%EC%8B%9C+%EC%9D%98%EC%B0%BD%EA%B5%AC+%EC%B0%BD%EC%9D%B4%EB%8C%80%EB%A1%9C+45+%EC%B0%BD%EC%9B%90%ED%8C%8C%ED%8B%B0%EB%A7%88%EB%B3%91%EC%9B%90/data=!4m8!4m7!1m0!1m5!1m1!1s0x356f3331d7f681a9:0x9cc50e8c3b1a736d!2m2!1d128.6459154!2d35.2372495?hl=ko&entry=ttu" target="_blank" title="새 창 열기">
                        <img src="/resource/icon/google_logo.png" alt="이미지">
                        구글 길찾기
                    </a>
                </div>
            </div>

            
        </div>
        <div class="inner">
             <div class="contents">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">자가용</h4>
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>(봉암동/창원대로)홈플러스에서 명곡교차로 방면 직진 ▶ 두 번째 신호에서 좌회전</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="depth__wrap depth02">
                            <div class="depth__text">
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <div class="depth__wrap">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>(창원대)명곡교차로 지나 두 번째 신호에서 우회전</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
             <div class="contents">
                 <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">대중교통</h4>
                    </div>
                </div>
                <ul class="transport_list">
                    <li class="transport_item">
                        <div class="transport_title">
                            <p>창원공고·파티마병원</p>
                            <p class="blue__title">농업기술센터 방면</p>
                        </div>
                        <ul class="transport_num_list">
                            <li class="transport_num_item">
                                <span class="green_num">지선</span>
                                <p>224</p>
                            </li>
                            <li class="transport_num_item mg10">
                                <span class="blue_num">간선</span>
                                <p>151</p>
                            </li>
                            <li class="transport_num_item">
                                <span class="red_num">좌석</span>
                                <p>59</p>
                            </li>
                        </ul>
                    </li>
                    <li class="transport_item">
                        <div class="transport_title">
                            <p>파티마병원</p>
                            <p class="blue__title">창원공고·도로교통공단 방면</p>
                        </div>
                        <ul class="transport_num_list">
                            <li class="transport_num_item">
                                <span class="green_num">지선</span>
                                <p>10, 11, 12, 13, 14, 30, 31,<br>32, 34, 35, 213, 214, 224</p>
                            </li>
                            <li class="transport_num_item mg10">
                                <span class="blue_num">간선</span>
                                <p>151</p>
                            </li>
                            <li class="transport_num_item">
                                <span class="red_num">좌석</span>
                                <p>59</p>
                            </li>
                        </ul>
                    </li>
                   <li class="transport_item">
                        <div class="transport_title">
                            <p>파티마병원</p>
                            <p class="blue__title">종합터미널 방면</p>
                        </div>
                        <ul class="transport_num_list">
                            <li class="transport_num_item">
                                <span class="green_num">지선</span>
                                <p>224</p>
                            </li>
                            <li class="transport_num_item mg10">
                                <span class="red_num">좌석</span>
                                <p>58</p>
                            </li>
                        </ul>
                    </li>
                    <li class="transport_item">
                        <div class="transport_title">
                            <p>창원공고·도로교통공단</p>
                        </div>
                        <ul class="transport_num_list">
                            <li class="transport_num_item">
                                <span class="green_num">지선</span>
                                <p>10, 11, 12, 13, 14, 30, 31,<br>32, 34, 35, 213, 214</p>
                            </li>
                            <li class="transport_num_item mg10">
                                <span class="blue_num">간선</span>
                                <p>151</p>
                            </li>
                            <li class="transport_num_item">
                                <span class="red_num">좌석</span>
                                <p>59</p>
                            </li>
                        </ul>
                    </li>
                </ul>
             </div>
        </div>
   </div>
</template>



<script>
    export default {
        mounted() {
            new daum.roughmap.Lander({
                "timestamp" : "1720157832966",
                "key" : "2jx6s",
                "mapWidth" : "100%",
                "mapHeight" : "100%"
            }).render();
        }
    }
</script>