<template>
    <!-- 안과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>안과는 안구와 안구를 보호하는 눈꺼풀을 포함한 안와부속기의 질병, 전신상태에서 비롯되는 안구 합병증을 다루는 진료과로 이들의 해부 생리에서 비롯한 안질환의 원인과 진단, 치료에 필요한 연구 및 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>백내장(노년성 백내장, 외상성 및 합병 백내장, 노안수술 등), 망막질환(황반변성, 유리체망막질환, 당뇨망막병증을 포함한 안내 합병증, 포도막염, 망막혈관질환, 망막박리, 미숙아망막병증, 외상성 망막병증 등), 녹내장(일반 녹내장, 급성 폐쇄각 녹내장, 합병 녹내장 등), 신경안과 (복시, 마비사시, 시신경질환, 시야이상 등), 소아안과(사시, 근시, 난시, 원시, 약시, 안검내반, 다래끼 등), 각결막 질환(안구건조증, 결막염, 각막염, 각막이물, 군날개 등), 시력검진 및 안과 종합검진</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 안과는 백내장 전문의, 망막 전문의, 녹내장 전문의, 소아사시/신경안과 전문의가 진료하고 있으며, 백내장, 각종 망막질환, 녹내장, 사시, 약시, 복시, 시신경질환, 안구건조증 등 안과질환 전체에 대한 진단 및 치료를 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">최신 장비 보유, 보다 정확한 진단 및 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>수술실 및 외래 검사실에서는 대학병원 수준의 최신 장비를 가동하고 있으며, 세부 분야별 전문 전문 의료진 4명이 다양한 안과질환에 대해 정확한 진단을 내리고, 치료 방향을 정합니다.</p>
                                <p>검사실 내 안저검사를 위한 광각 안저촬영기(Optus), 망막 및 맥락막검사를 위한 빛간섭단층촬영기(Spectralis OCT), 형광안저촬영기, 녹내장검사를 위한 Cirrus 6000 OCT, 시야검사기(Humphrey Filed analyzer) 등 대학병원 장비와 같은 최신 장비를 갖추고 있으며, 검사 결과를 바탕으로 상세히 설명하여 환자의 현재 안구 상태에 대한 이해를 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">협진 통해 다양한 합병증을 조기에 진단 및 관리</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>눈은 신체 중 단위면적에 비해 혈관조직이 많은 장기로 신체적질환으로 인한 합병증이 많이 발생하는 부위입니다. 대표적인 당뇨망막병증을 포함해 내과(당뇨·고혈압·류마티스질환·혈액암·간질환), 신경과 및 신경외과(뇌경색·뇌출혈·뇌종양)와 연관된 합병증이 많은 편이기 때문에 이에 대한 조기 진단 및 관리가 매우 중요합니다.</p>
                                <p>창원파티마병원 안과에서는 해당 과와의 협진을 통해 조기에 안과적 합병증을 진단하고 있으며, 시력 저하나 복시 등으로 안과적 이상 소견이 신체증상보다 먼저 생기더라도 원인이 될 수 있는 전신적 문제를 찾아 해당 과와 함께 치료할 수 있는 진료시스템을 갖추고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">망막 수술, 녹내장 수술 및 사시 수술을 포함한 안과수술</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 안과는 백내장 수술기구(Centurion, Alcon), 망막 수술기구(Constellation, Alcon), 수술 현미경(Lumira 700, zeiss)등 최신 수술장비를 보유하고 있으며, 일반적인 백내장 수술뿐 아니라 개인병원에서 시행하기 어려운 합병 백내장 수술도 시행하고 있습니다. 또한 타 도시 대학병원으로 전원하여 치료 및 수술을 받아야 했던 다양한 망막 및 녹내장질환, 사시를 포함한 여러 안과적 질환에 대해서도 수술 및 치료가 가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">소아 사시, 시력 및 안검내반 등 소아안과 진료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소아들은 본인의 불편감을 명확히 표현하지 못하는 경우가 많기 때문에 환아나 보호자가 이상 증상을 발견하지 못하고 지내다가 시력검사나 다른 진료를 위해 방문한 안과에서 우연히 발견하게 되는 경우가 많습니다. 환자 상태와 치료 적기 등을 고려해 보존적치료, 수술적치료 등 전문의 진료를 통한 치료가 시행되어야 하며, 제때 적절한 치료가 이뤄지지 않는다면 일상생활의 불편뿐 아니라 시력 발달에도 악영향을 끼칠 수 있으므로 정기 안과검진과 꾸준한 경과 관찰이 중요합니다.</p>
                                <br>
                                <p>창원파티마병원 안과에서는 사시, 근시, 난시, 원시, 약시, 안검내반에 대해 소아 안과전문의가 체계적인 진료와 치료를 제공하고 있습니다.</p>
                           </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    
</template>


<script>
export default {
   
};
</script>