<template>
    <div class="contents healthCenter_intro cooperation_intro">
      <div class="contents">
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">창원파티마병원 진료협력센터</h4>
                <p>
                    창원파티마병원 진료협력센터는 1, 2, 3차 의료기관에서 의뢰하는  환자의 빠르고 정확한 진료 예약과 전원, 회신 업무를 담당하고 있으며 급성기 치료 후 지역 병.의원으로 회송 업무를 담당하고 있습니다.
                </p>
            </div>
        </div>
      </div>
      <div class="contents mt60">
        <div class="ideology-list type02">
          <div class="ideology-list-back"></div>
          <ul class="ideology-list__item-wrap" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/cooperation_intro01.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">의뢰환자의 예약접수</h4>
                <p>Reservation reception for <br>
                    referral patient</p>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/cooperation_intro02.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">의뢰환자의 신속한 진료제공</h4>

                <p>Providing prompt treatment <br>
                    to referral patient</p>
              </div>
            </li>
          </ul>

          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/cooperation_intro03.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">진료결과 회신</h4>
                <p>Response to treatment
                    results</p>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/cooperation_intro04.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">환자 회송</h4>
                <p>	Patient transfer</p>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/cooperation_intro05.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">특수검사의뢰</h4>
                <p>Specialized test request</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
export default {};
</script>