<!-- 전송페이지 공통 -->
<template>
    <div class="find-info">
        <p class="sub-text">
            회원가입 시 등록하신 이메일 주소로 <br>
            아이디를 전송하였습니다.
        </p>
        <div class="contents find-info__box">
            <p class="find-info__text">아이디를 확인 하신 후, 로그인 해주시기 바랍니다.</p>
            <ul class="contents find-info__list">
                <li class="find-info__item">
                    <div class="tit">이메일</div>
                    <div class="desc">hah***@naver.com</div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center"><a href="javascript:;" class="btn">메인으로 이동</a><a href="javascript:;" class="btn blue">로그인</a></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isChecked: false
            };
        },
        methods: {
            isCheckedFn() {
                this.isChecked = true;
            }
        }
    };
</script>