<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="my_health_wrap my_result_wrap contents">
        <div class="contents health_info_wrap">
            <div class="tab-menu__wrap">
                <div class="tab-menu type02">
                     <ul class="tab-menu__list center">
                        <template v-for="(menu, idx) in menuList" :key="idx">
                            <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '580'">
                                <a :href="menu.menuUrl" :target="menu.linkMethod" v-if="menu.menuUrl.indexOf('http') > -1">
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </a>
                                <router-link :to="menu.menuUrl" v-else>
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="contents result_box03">
                <div class="date_wrap contents">
                    <div class="board__select">
                        <select name="" id="Sel_Inst" class="select_department" @change="changeDept" ref="dept">
                            <option value="">전체</option>
                            <option :value="i" v-for="i in deptList" :key="i">{{i}}</option>
                        </select>
                    </div>
                    <div class="date-input">
                        <VueDatePicker v-model="selectedDateRange" locale="ko"
                                       :enable-time-picker="false" :format="customFormat"
                                       :week-start="0" :day-class="getDayClass"
                                       :select-text="'선택'" :cancel-text="'취소'"
                                       :range="true" :multi-calendars="true"/>
                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                    </div>

                    <div class="tab-menu type_date">
                        <ul class="tab-menu__list">
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                            </li>
                        </ul>
                    </div>

                    <div class="health_info_search">
                        <button @click="getInList">검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
                    </div>
                </div>

                <div class="contents">
                    <div class="table-wrap table-type1 scroll" @click="addClass">
                        <div class="scroll">
                            <table>
                                <caption class="blind">결과조회-외래진료 폼</caption>
                                <colgroup>
                                    <col style="width:15%;">
                                    <col style="width:15%;">
                                    <col style="width:35%;">
                                    <col style="width:35%;">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>입원일자</th>
                                    <th>퇴원일자</th>
                                    <th>진료과</th>
                                    <th>주치의</th>
                                </tr>
                                </thead>
                                <tbody class="no-data-table" v-if="$commonUtils.isEmpty(inList)">
                                    <tr>
                                        <td colspan="4">검색된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(i, idx) in inList" :key="idx">
                                        <td>{{ $commonUtils.makeDateFormat(i.indd,'.') }}</td>
                                        <td>{{ $commonUtils.makeDateFormat(i.dschdd,'.') }}</td>
                                        <td>{{i.orddeptnm}}</td>
                                        <td>{{i.orddrnm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-timepicker/dist/VueTimepicker.css'
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    data() {
        return {
            searchParam: {},
            selectedDateRange: [new Date(), new Date()],
            selectedPeriod: 3,
            customFormat: 'yyyy.MM.dd',
            inList: [],
            popupVisible: false,
            
            deptList: [],
            orgList: [],
        }
    },
    components: {
        VueDatePicker,
        NameCertifyPopUp
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.setSelectedPeriod(3)
        this.getInList()
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        setSelectedPeriod(months) {
            const startDate = new Date()
            const endDate = new Date()
            startDate.setMonth(endDate.getMonth() - months)
            this.selectedDateRange = [startDate, endDate]
            this.selectedPeriod = months
        },
        getDayClass(date) {
            const day = date.getDay()
            if (day === 0) {
                return 'sunday-class'
            } else if (day === 6) {
                return 'saturday-class'
            }
            return ''
        },
        addClass(event) {
            event.currentTarget.classList.add('on')
        },
        getInList() {
            this.searchParam.fromdd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[0], 'yyyyMMdd', '')
            this.searchParam.todd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[1], 'yyyyMMdd', '')

            this.axios.get(this.api.mypage.inList, {
                params: this.searchParam
            }).then(res => {
                const infoList = res.data.list.patinfolist
                if(Array.isArray(infoList)){
                    this.inList = infoList
                } else {
                    this.inList = [infoList]
                }

                this.orgList = this.inList

                this.deptList = []
                this.$refs.dept.value = ''
                for(let i of this.inList){
                    this.deptList.push(i.orddeptnm)
                }
                this.deptList = this.$commonUtils.dupArr(this.deptList)

            })
        },
        changeDept(){
            const dept = this.$refs.dept.value
            this.inList = this.orgList.filter(item => {
                let matchDept = true;

                if (dept) {
                    matchDept = item.orddeptnm === dept;
                }

                return matchDept;
            });
        }
    }
}

</script>
