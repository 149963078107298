<template>
    <div class="contents healthCenter_intro">
      <div class="contents">
        <p class="healthCenter_intro_top" data-aos="fade-up" data-aos-duration="1000">
        창원파티마병원 건강증진센터는<br><b class="blue__title">“건강한 삶”을 위한 최선의 방법</b>을 찾고 있습니다
        </p>
      </div>
      <div class="contents mt60">
        <div class="ideology-list type02">
          <div class="ideology-list-back"></div>
          <ul class="ideology-list__item-wrap" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/healthcenter_intro01.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">풍부한 임상경험을 가진 숙련된 <br>전문의에 의한 안전한 검진</h4>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                        <p>임상경력 10년 이상의 전문의가 직접 검사와 상담 진행</p>
                    </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/healthcenter_intro02.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">지역 내 최고 수준의 검사환경과 아늑한 <br>분위기에서 제공하는 검진경험</h4>

                <div class="depth__wrap rightText">
                    <div class="depth__bullet mo_left_bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>수검자의 프라이버시와 동선을 고려한 공간구획과 편안하고 세련된 분위기의 인테리어</p>
                    </div>
                   
                </div>
                <div class="depth__wrap rightText">
                  <div class="depth__bullet mo_left_bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>최신 설비를 갖춘 내시경검사실과 안정을 위한 넓고 쾌적한 회복실</p>
                    </div>
                   
                </div>
              </div>
            </li>
          </ul>

          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/healthcenter_intro03.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">기본에 충실한 체계적이고 <br>정밀한 헬스케어</h4>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                        <p>개인별 특성을 고려한 다양한 건강검진 프로그램 운영</p>
                    </div>
                </div>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                      <p>전문 간호사와 상담을 통해 맞춤형 검진 후 각 전문분야 전문의와 결과상담</p>
                    </div>
                </div>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                        <p>단계별 중증도에 따라 체계적인 관리 플랜을 제시하여 건강한 삶을 유지할 수 있도록 도움</p>
                    </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/healthcenter_intro04.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">유소견 발견 시 신속한 진료 연계</h4>
                <div class="depth__wrap rightText">
                  <div class="depth__bullet mo_left_bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>검진 실시 중, 후 이상소견 발견되는 즉시 해당 진료과 전문의 진료</p>
                    </div>
                   
                </div>
                <div class="depth__wrap rightText">
                  <div class="depth__bullet mo_left_bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>중증질환 발견 즉시 수검자에게 전화상담하는 해피콜 서비스와 수도권 Big5<br>(삼성서울/서울대/서울성모/서울아산/세브란스)를 비롯한 상급의료기관에 신속한 진료의뢰</p>
                    </div>
                   
                </div>
              </div>
            </li>
          </ul>
          <ul class="ideology-list__item-wrap mt60" data-aos="fade-up" data-aos-duration="2000">
            <li class="ideology-list__item">
              <div class="ideology-list__img">
                <img src="/resource/img/healthcenter_intro05.png" alt="이미지" />
              </div>
              <div class="ideology-list__con">
                <h4 class="ideology-list__title">스마트 검진 시스템으로 최적의 동선 구현</h4>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                      <p>RFID 시스템 등 스마트 검진체계와 최적의 동선으로 검사시간 최소화</p>
                    </div>
                </div>
                <div class="depth__wrap">
                   
                    <div class="depth__text">
                        <p>수검자의 검사순서를 최우선으로 고려한 동선 배치</p>
                    </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
export default {};
</script>