<template>
    <!-- 심장내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>심장내과는 고혈압, 허혈성 심질환, 심부전, 심장판막질환, 부정맥 등 심장 및 혈관계 질환에 대한 진단과 치료를 담당하고 있습니다. 가슴이 아프거나 두근거릴 때, 숨이 찰 때, 어지럽고 쓰러질 것 같은 느낌이 들 때, 실신을 한 경우, 혈압이 높거나 낮을 때 저희 심장내과를 찾아 주시면 최선을 다해 진료해 드리도록 하겠습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>허혈성 심질환(급성 심근경색, 협심증), 고혈압, 심부전증, 심장 판막 질환, 부정맥, 대동맥 질환</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text heart_appointment">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">주요 검사 및 장비</h4>
                        
                    </div>
                </div>
                <div class="depth__wrap">
                    <div class="depth__text">
                        <div class="table-cont" style="margin-top:0;">
                            <ul class="table-list type03">
                                <li class="table-item">
                                    <div class="item tit">심전도실</div>
                                    <div class="item">
                                        창원파티마병원 심장내과는 자체 심박동기 분석기를 보유하고 있어 MRI 시행 전 모드 변경이 필요하거나 증상 발생 시 당일 즉시 분석이 가능합니다. 또한 자체 패치형 심전도 분석 장비 3대를 운용하여 패치형 심전도의 빠른 부착과 판독이 가능합니다.
                                        <br>
                                        <br>
                                        심전도 검사 / 운동부하 검사 / 홀터 검사 / 72시간 이상 패치형 심전도 모니터링
                                        <br>
                                        삽입형 비삽입형 사건 기록지 / 활동 혈압계 / 인공 심박동기 분석 / 심장 재활
                                    </div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">진찰실 내 자동 혈압 측정실</div>
                                    <div class="item">정확한 혈압 측정을 위해 독립된 공간에서 진찰실 내 자동 혈압 측정실을 운영하고 있으며, 9대의 활동 혈압기와 최신형 양팔 자동 혈압계( OMRON, HBP-9030)를 이용해 고혈압 환자에게 최적의 진료를 제공하고 있습니다.</div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">심초음파실</div>
                                    <div class="item">최신형 심초음파 장비(Philips Epiq 7)를 포함한 3대의 심장 초음파기를 보유하여 정밀 심기능 및 판막 평가가 가능합니다.
                                        <br>
                                        <br>
                                        경 흉부 심초음파 / 경식도 심초음파 / 도부타민 스트레스 심초음파 / 기립경 검사
                                    </div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">핵의학</div>
                                    <div class="item">심근 스캔</div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">혈관조영실</div>
                                    <div class="item">최신형 심장 전용 혈관 조영기(Philips Azurion 7)를 보유하고 있으며, 환자의 안전을 위한 저 방사선량 및 고화질의 혈관 조영 영상을 통해 관상동맥 중재술을 시행하고 있습니다. 특히 심근 분획 혈류 예비력(Fractional Flow Reserve, FFR) 검사 및 혈관내 초음파(Intravascular ultrasonography, IVUS), 심근 스캔 검사 등을 통해 심근 허혈 정도와 위치를 적절히 평가하고 있습니다. 또한 관상동맥 회전 죽종 절제술 시스템(ROTAPRO, Rotational Atherectomy System)을 이용해 고도 관상동맥 석회화 병변을 포함한 복합 고위험 병변 시술이 가능합니다.
                                            <br>
                                            <br>
                                            혈관 조영기 / 혈관 내 초음파 / 심근 분획 혈류 예비력 검사 / 동맥 경화반 절삭기
                                        <br>
                                        대동맥 내 풍선 펌프 / 경피적 관상동맥 스텐트 삽입술 / 경피적 관상동맥 풍선 성형술
                                        <br>
                                        혈관내 혈전 제거술 / 인공심박동기 / 이식형 제세동기 / 심낭 천자 / 초소형 무선 심박동기
                                    </div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">CT실</div>
                                    <div class="item">640 멀티 슬라이스 CT(AQUILION ONE NATURE, CANON)를 운용하고 있으며, 한 번의 스캔으로 저선량, 초고화질로 관상동맥 조영 CT 검사를 시행하고 있습니다.</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">응급상황 대비한 의료진 당직 체제</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>풍부한 경험의 심장전문의 5명과 전문 간호사, 방사선사가 급성심근경색증 등의 응급 시술에 대비해 365일 응급 당직 체제를 유지하여 응급상황 시 골든타임 내 신속히 치료받을 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
               <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">지역민의 심장지킴이로서 최선의 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">심장재활치료</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>2018년 지역 최초로 심장재활치료실을 개설했으며, 재활의학과와 협업을 통해 심장 질환자의 빠른 회복과 재발 방지, 삶의 질 향상을 위해 심장재활치료를 실시하고 있습니다. 심장내과, 재활의학과 전문의를 비롯해 간호사, 심장호흡물리치료사, 임상병리사, 영양사 등 각 분야 전문가가 팀을 구성하여 운동처방 및 심혈관질환 위험인자 관리 교육 등을 제공합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">심장질환 교육</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>심장내과 전문의와 간호사, 영양사가 심혈관 질환의 예방과 홍보를 위해 심장질환자 및 보호자를 대상으로 분기별 심장질환 교육을 시행하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
               
               
            </div>
        </div>
    </div>
</template>


<script>
    export default {

    };
</script>