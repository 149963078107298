const myInfoStore = {
    state: {
        memBirth:"",
        memCmcPersonYn:"",
        memEmail:"",
        memEmailAgreeYn:"",
        memGender:"",
        memHpNo:"",
        memId:"",
        memName:"",
        memTel:"",
        memType:"",
        memZipcode:"",
        username:"",
        memPatId:"",
    },
    mutations: {
        setMemBirth: function (state, memBirth) {
            state.memBirth = memBirth;
        },
        setMemCmcPersonYn: function (state, memCmcPersonYn) {
            state.memCmcPersonYn = memCmcPersonYn;
        },
        setMemEmail: function (state, memEmail) {
            state.memEmail = memEmail;
        },
        setMemEmailAgreeYn: function (state, memEmailAgreeYn) {
            state.memEmailAgreeYn = memEmailAgreeYn;
        },
        setMemGender: function (state, memGender) {
            state.memGender = memGender;
        },
        setMemHpNo: function (state, memHpNo) {
            state.memHpNo = memHpNo;
        },
        setMemId: function (state, memId) {
            state.memId = memId;
        },
        setMemName: function (state, memName) {
            state.memName = memName;
        },
        setMemTel: function (state, memTel) {
            state.memTel = memTel;
        },
        setMemType: function (state, memType) {
            state.memType = memType;
        },
        setMemZipcode: function (state, memZipcode) {
            state.memZipcode = memZipcode;
        },
        setUsername: function (state, username) {
            state.username = username;
        },
        setMemPatId: function (state, memPatId) {
            state.memPatId = memPatId;
        },
        // 초기화
        myInfoInit: function(state){
            for(let i in state) {
                state[i] = ''
            }
        }
    }
}

export default myInfoStore