<template>
    <div class="tab-menu type02">
        <ul class="tab-menu__list">
            <li class="tab-menu__item active"><button type="button" class="tab-menu__btn">개인정보수정</button></li>
            <li class="tab-menu__item"><button type="button" class="tab-menu__btn">비밀번호변경</button></li>
            <li class="tab-menu__item "><button type="button" class="tab-menu__btn">회원탈퇴</button></li>
        </ul>
    </div>

    <div class="contents info-text">
        <h3 class="depth01__title title center">개인정보 보호를 위해 <span class="c-blue">비밀번호를</span> <br>
            다시 한번 입력해주시기 바랍니다.
        </h3>
    </div>

    <div class="join contents">
        <div class="changing-information">
            <div class="input-form type1">
                <ul class="input-form__list">
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">아이디</div>
                            <div class="cont">
                                vjqmffltu
                            </div>

                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">비밀번호</div>
                            <div class="cont">
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                    <a href="javascript:;" class="btn password-link">비밀번호찾기 ></a>
                                </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="contents btn-wrap center">
            <a href="javascript:;" class="btn blue">확인</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                radioChecked: '',
                otherReason: ''
            };
        },
        watch: {
            radioChecked(newVal) {
                if (newVal !== 'other') {
                    this.otherReason = '';
                }
            }
        },
        methods: {
            selectFn() {
                this.selectOn = !this.selectOn;
            },
        },
        mounted() {
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>