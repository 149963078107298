<template>
    <div class="board-list">
        <div class="board__search">
            <div class="board__select" :class="{ 'active': selectOn }" @click="selectFn">
                <select name="" id="">
                    <option value="전체">전체</option>
                    <option value="제목">제목</option>
                    <option value="글">글</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" placeholder="검색어를 입력해주세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>

        <div class="contents faq">
            <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
                <div class="faq-title" :class="{ 'active': activeIndex === index }" @click="toggle(index)">
                    <span>Q </span> <em>{{faq.category}}</em> {{ faq.title }}
                </div>
                <slide-up-down :active="activeIndex === index">
                    <div class="faq-content">
                        <span>A</span>
                        <p>{{ faq.content }}</p>
                    </div>
                </slide-up-down>
            </div>
        </div>

        <div class="contents">
            <div class="paging">
                <a href="javascript:;" class="board__pagination-btn first">처음</a>
                <a href="javascript:;" class="board__pagination-btn prev">이전</a>
                <a href="javascript:;" class="board__pagination-btn num active"><span>90</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>91</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>93</span></a>
                <a href="javascript:;" class="board__pagination-btn num"><span>910</span></a>
                <a href="javascript:;" class="board__pagination-btn next">다음</a>
                <a href="javascript:;" class="board__pagination-btn last">마지막</a>
            </div>
        </div>
    </div>
</template>

<script>
    import SlideUpDown from 'vue-slide-up-down'

    export default {
        components: {
            SlideUpDown
        },
        data() {
            return {
                faqs: [
                    {
                        category: '[성장 장건강 대사증후군 클리닉] ',
                        title: '장내 미생물 검사(microblome 검사) 성인도 가능한가요?',
                        content: '아주 드물게 발생합니다. 성장호르몬 부작용이라고 하기 보다는 당뇨 발생의 경향을 가지고 있는 상황에서 성장호르몬 치료가 촉발했다고 보는 것이 맞습니다. 치료를 중단하거나 당뇨 치료를 하면서 성장호르몬 치료를 하기도 합니다.'
                    },
                    {
                        category: '[성장 장건강 대사증후군 클리닉] ',
                        title: '성장호르몬 치료 보험기준은 다음과 같습니다.',
                        content: '섹션 2의 내용입니다.'
                    },
                    {
                        category: '[성장 장건강 대사증후군 클리닉] ',
                        title: '성장호르몬 치료 보험기준은 다음과 같습니다.',
                        content: '섹션 3의 내용입니다.'
                    }
                ],
                activeIndex: null
            };
        },
        methods: {
            toggle(index) {
                this.activeIndex = this.activeIndex === index ? null : index;
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            toggle(index) {
                this.activeIndex = this.activeIndex === index ? null : index;
            },
            handleClickOutside(event) {
                const isClickInside = this.$el.contains(event.target);
                if (!isClickInside) {
                    this.activeIndex = null;
                }
            }
        }
    };
</script>

<style>

</style>