<template>
    <!-- 소화기내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소화기내과는 식도, 위, 소장, 대장, 간, 췌장, 담도 등 소화기계 질병의 진단과 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="department_organ_pic">
                            <img src="/resource/img/department_organ11.png" alt="이미지">
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>식도, 위, 소장, 대장, 간, 췌장, 담도 등 소화기계 질병</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">내시경에 최적화된 전문 의료진과 시설</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소화기질환 전반에 걸친 진료뿐만 아니라, 내시경 검사 및 시술을 활발히 시행하고 있으며, 풍부한 경험과 우수한 시술 성공 능력의 전문의가 내시경 관련 시술을 직접 시행하고 있습니다. 6명의 전문의가 진료 및 시술 시 조직적으로 협력하는 체계로 소화기내과·소화기내시경센터로 구성된 소화기센터를 운영하고 있으며, 외래진료실과 내시경실을 한데 묶어 환자 동선을 최소화, 보다 신속한 시술을 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">내시경으로 조기 암 발견·절제, 담관·췌장질환 스텐트 치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>위 대장 선종이나 조기 암 등을 수면내시경 관찰과 동시에 내시경 시술로 절제하고 있습니다. 또한 담석, 담관석, 담관암 췌장질환 등에 대해 역행성 담관촬영술을 통한 담관석 제거가 가능하며, 담관폐쇄 췌장염 등에 대해 담관 및 췌관스텐트시술, 배액술을 시행하고 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">간염, 간경화 등 간질환과 면역성 장 질환에는 약물치료</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>내시경 치료 및 진단 외에도 급·만성 간염과 B, C형 간염, 간경화 등 각종 간 질환을 적극적으로 치료하고 있으며, 크론병이나 궤양성 대장염 등 면역성 장 질환에 대한 약물치료를 돕고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">내시경 초음파 촬영</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 소화기내시경센터는 숙련된 내시경 술기와 풍부한 경험을 바탕으로 신속 정확한 검사를 제공하고 있습니다. 내시경 초음파 촬영은 내시경 끝부분에 초음파 변환기를 부착해 내시경과 동시에 초음파를 통한 관찰이 가능한 검사로 식도, 위, 대장, 췌담관질환 등 추적 관찰 및 조직 진단에 시행하고 있습니다</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>췌장낭종의 추적관찰 가능</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>위, 식도, 십이지장 점막하 종양의 관찰 및 진단 가능</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>췌장결절, 췌장암, 위장관 점막하 종양의 조직 진단 가능</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">소화기내시경센터</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>