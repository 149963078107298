<template>
  <div class="programInfo">
    <div class="tab-menu type02">
      <ul class="tab-menu__list">
        <li class="tab-menu__item active" data-tab="0">
          <button type="button" class="tab-menu__btn">종합정밀 Ⅰ (당일)</button>
        </li>
        <li class="tab-menu__item" data-tab="1">
          <button type="button" class="tab-menu__btn">종합정밀 Ⅱ (숙박)</button>
        </li>
      </ul>
    </div>

    <div class="contents active" data-content="0">

      <div ref="programInfo" class="program_add_wrap">

        <div class="info-text depth__wrap depth01">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">종합정밀 Ⅰ (당일)</h4>
            <!-- depth02 -->
            <div class="depth__wrap depth02">
              <div class="depth__text">
                <!-- depth03 -->
                <div class="depth__wrap depth03">
                  <div class="depth__text">
                    <p>
                      MRI-MRA가 포함된 뇌심혈관계 질환 확인 및 주요장기의 암을 확인하기 위한 당일 종합정밀 프로그램입니다.
                    </p>
                    <div class="depth__wrap mt20">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                           검사소요시간 : 약 4시간이상 / 월요일~금요일
                        </p>
                      </div>
                    </div>
                     <div class="depth__wrap">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                          검사비용 : 남 1,950,000원 여 1,990,000원
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrap table-type1">
          <div class="scroll">
            <table>
              <caption class="blind">표</caption>
              <colgroup>
                <col style="width: 13%" />
                <col style="width: 17%" />
                <col style="width: 70%" />
              </colgroup>
              <thead>
                <tr>
                  <th colspan="2">구분</th>
                  <th>내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="16" style="text-align: center">공통</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">문진</td>
                  <td>건강상담</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                  <td>신체, 체중, 허리둘레, 비만도</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">
                    청력, 안과, 치과
                  </td>
                  <td>청력, 시력, 안압, 안저, 구강검사</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                  <td>
                    일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능,
                    지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B,
                    C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사,
                    정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암,
                    전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK,
                    CRF)
                  </td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">대, 소변 검사</td>
                  <td>대변(잠혈, 기생충), 소변</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                  <td>폐활량 측정, 흉부 X-ray (정면, 측면), 저선량흉부CT</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                  <td>혈압검사, 심전도, 심장초음파</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                  <td>복부 초음파, 복부/골반CT</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                  <td>위내시경 또는 위장조영촬영 택1</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                  <td>대장내시경</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                  <td>뇌 MRI & MRA</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                  <td>전립선초음파(♂), 유방초음파(♀)</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">골다공증검사</td>
                  <td>골밀도검사</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                  <td>남성호르몬(♂), 여성호르몬(♀)</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                  <td>
                    뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도
                    검사
                  </td>
                </tr>
                <tr>
                  <td rowspan="2" style="text-align: center">여성</td>
                  <td>유방검사</td>
                  <td>유방X-ray</td>
                </tr>
                <tr>
                  <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                  <td>
                    자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="contents" data-content="1">

      <div ref="programInfo" class="program_add_wrap">
         <div class="info-text depth__wrap depth01">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">종합정밀 Ⅱ (숙박)</h4>
            <!-- depth02 -->
            <div class="depth__wrap depth02">
              <div class="depth__text">
                <!-- depth03 -->
                <div class="depth__wrap depth03">
                  <div class="depth__text">
                    <p>
                      심장마비, 뇌졸중, 각종 주요장기의 암 등의 위험인자를 조기발견하기 위한 1박 2일 검진으로 검진 시 전담간호사가 동행하는 가장  광범위한 숙박 종합정밀 프로그램입니다.
                    </p>
                    <div class="depth__wrap mt20">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                            검사소요시간 : 1박2일 (숙박) / 월요일~목요일
                        </p>
                      </div>
                    </div>
                     <div class="depth__wrap">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                          검사비용 : 2,950,000원
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="17" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                 일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 폐암2종(NSE, CYFRA21-1), 전립선암(♂), 난소암(♀)), 심혈관정밀3종(호모시스테인, CPK, CRF), 헬리코박터 면역검사
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면), 객담세포진 검사, 저선량 흉부CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도, 심장초음파, 심장CT(Ca-scoring), 운동부하검사, 홀터검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파, 복부/골반CT</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">대장검사</td>
                <td>대장내시경</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">뇌검사</td>
                <td>뇌 MRI & MRA, 뇌혈류 초음파</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">초음파검사</td>
                <td>경동맥 초음파, 갑상선초음파, 전립선초음파(♂), 유방초음파(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">골다공증검사</td>
                <td>골밀도검사</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">호르몬검사</td>
                <td>남성호르몬(♂), 여성호르몬(♀)</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">척추측만검사</td>
                <td>요추 X-ray</td>
              </tr>
               <tr>
                <td style="border-left: 1px solid #d9d9d9">기타검사</td>
                <td>뇌심혈관계발병 위험도 평가, 생체나이측정검사, 동맥경화도 검사, 스트레스 측정검사</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 액상자궁경부암검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>

    <div class="btn-wrap center"><a href="javascript:;" class="btn">예약하기</a></div>
  </div>
</template>

<script>
export default {
  mounted() {
    const tabItems = document.querySelectorAll('.tab-menu__item');
    const contents = document.querySelectorAll('.contents');

    tabItems.forEach(function (item) {
      item.addEventListener('click', function () {
       
        tabItems.forEach(function (tab) {
          tab.classList.remove('active');
        });

       
        item.classList.add('active');
        contents.forEach(function (content) {
          content.classList.remove('active');
        });

      
        const tabNumber = item.getAttribute('data-tab');
        document.querySelector(`.contents[data-content="${tabNumber}"]`).classList.add('active');
      });
    });
  }
};
</script>
<style scoped>

.programInfo .contents {
  display: none;
}
.programInfo .contents.active {
  display: block;
}
</style>