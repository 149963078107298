<template>
    <!-- 류마티스내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>류마티스내과는 관절에 질병을 일으킬 수 있는 근골격계 질환 및 자가면역질환의 진단과 치료를 담당하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>류마티스관절염, 전신홍반루푸스, 강직성척추염, 쇼그렌증후군, 통풍, 베체트병, 자가면역질환, 섬유근통</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">보다 안전하고 정확하게 초기 관절염을 찾아내는 관절 초음파 검사</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>관절 초음파는 뼈, 근육, 관절, 인대 등 다양한 근골격계 검사가 가능하며, 관절을 움직이며 검사할 수 있어 특정 자세에서만 보이는 이상소견까지 확인할 수 있습니다. 관절 초음파를 통해 관절의 삼출액 여부나 관절 주위 조직의 손상 여부의 확인에만 그치지 않고, 초기 류마티스관절염 같이 기존 X-ray 등의 영상 장비에서 확인하기 어려운 염증성 관절염의 초기 소견을 조기에 발견할 수 있으며, 임상적인 진찰만으로는 불확실한 관절염 의증 환자들을 진단할 수 있습니다. 또한 초음파를 활용해 관절강 내 주사의 정확성과 안정성을 더욱 보장할 수 있다는 장점이 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">정확한 통풍 감별을 위한 편광현미경</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>통풍은 혈액 속에 녹아 있던 ‘요산’이라는 물질이 결정체로 바뀌면서 관절에 쌓이고 이것이 염증을 유발하는 질환입니다. 관절이 붓고 아파서 진료 시 육안상으로만 통풍을 진단하는 경우가 많은데, 통풍과 유사한 증상을 유발하는 다른 질환과의 감별이 반드시 필요합니다. 편광현미경은 세포나 조직을 관찰하는 일반 현미경과는 달리 광물의 광학적 성질을 조사하기 위한 특수 현미경으로 통풍의 정확한 진단을 위해 요산 결정체를 관찰하기 위해 사용합니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">다학제 진료를 통한 통합 치료 제공</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>근골격계질환 환자의 통증 치료와 원활한 일상생활을 돕기 위해서는 약물치료뿐만 아니라 적절한 시기의 수술적 치료, 운동치료가 필수적이기 때문에 정형외과, 신경외과, 재활의학과와 연계해 협진을 시행하고 있습니다.</p>
                                <br>
                                <p>또한 류마티스관절염, 전신홍반루푸스, 쇼그렌증후군 등 자가면역질환은 관절뿐만 아니라 우리 몸의 여러 장기들에 모두 침범할 수 있는 질환으로 여러 과의 긴밀한 상호 협조를 통해 통합적인 진료를 하고 있으며, 기타 위나 대장, 신장, 눈, 피부, 폐, 심장 신경계 등의 문제 발생 시에도 타 과와의 협진을 통한 최적의 치료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>