<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <template v-for="(menu, idx) in menuList" :key="idx">
                    <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '591'">
                        <router-link :to="menu.menuUrl" >
                            <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                        </router-link>
                    </li>
                </template>
            </ul>
        </div>
    </div>

    <div class="join contents">
        <div class="input-form type1">
            <div class="input-form__top-wrap">
                <p class="input-form__top-text type2">&#183; 회원 탈퇴 시 창원파티마병원 홈페이지에서 온라인 서비스를 이용하실 수 없습니다.</p>
                <p class="input-form__top-text type2">&#183; 회원 탈퇴 시 회원님의 회원정보가 모두 삭제되며 복구할 수 없습니다.</p>
                <p class="input-form__top-text type2">&#183; 탈퇴한 회원 ID와 동일한 ID로의 재가입은 불가합니다.</p>
            </div>
            <p class="input-form__top-text"><span class="c-red">* </span> 필수 입력항목</p>

            <ul class="input-form__list">
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit">아이디</div>
                        <div class="cont">
                            {{ myInfo.memId }}
                        </div>

                    </div>
                </li>
                <li class="input-form__item">
                    <div class="input-form__box">
                        <div class="tit"><span class="c-red">* </span>탈퇴사유</div>
                        <div class="cont radio type2">
                            <div class="input-form__radio">
                                <div class="input-form__radio-inner">
                                    <div class="radio-list">
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="no-info" v-model="radioChecked"><span class="icon"></span> 원하는 정보가 없어서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="low-usage" v-model="radioChecked"><span class="icon"></span> 사용빈도가 낮아서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="privacy-concern" v-model="radioChecked"><span class="icon"></span> 개인정보 유출이 우려되서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="no-email" v-model="radioChecked"><span class="icon"></span> 메일 수신을 원하지 않아서
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="moving" v-model="radioChecked"><span class="icon"></span> 거주지 이전
                                        </label>
                                        <label class="input-item">
                                            <input type="radio" class="radio" name="radio1" value="inconvenient" v-model="radioChecked"><span class="icon"></span> 서비스 이용이 불편해서
                                        </label>
                                        <label class="input-item radio-input">
                                            <input type="radio" class="radio" name="radio1" v-model="radioChecked" value="other">
                                            <span class="icon"></span> 기타
                                            <input type="text" class="cont__text-input ml10" placeholder="의견을 남겨주세요." :disabled="radioChecked !== 'other'" v-model="otherReason">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </li>
            </ul>
        </div>
        <div class="contents btn-wrap center">
            <router-link to="/mypage/mypage.do" class="btn">취소</router-link>
            <a href="javascript:;" class="btn blue" @click="outMember">회원탈퇴</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                myInfo: this.$store.state.myInfoStore,
                otherReason: '',
                radioChecked: '',
                removeNote: '',
                removeEtcNote: ''
            };
        },
        watch: {
            radioChecked(newVal) {
                if (newVal !== 'other') {
                    this.otherReason = '';
                }
            }
        },
        methods: {
            outMember(){
                if(this.removeNote == '기타'){
                    if(!this.removeEtcNote){
                        alert('사유를 입력해주세요');
                        return;
                    }else{
                        this.removeNote = this.removeEtcNote;
                    }
                }

                if(confirm('탈퇴하시겠습니까?')){
                    this.axios({
                        url: this.api.mypage.myInfoDelete,
                        method: 'post',
                        params: { memRemoveNote: this.removeNote }
                    })
                        .then(res => {
                            if (res.data.result.code === 'OK') {
                                alert('탈퇴되었습니다. 그 동안 이용해주셔서 감사합니다.');
                                this.$store.commit("getLoginStatus", false);
                                location.href="/";
                            } else {
                                alert(res.data.result.message);
                            }
                        });
                }
            }
        },
        mounted() {
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>