<template>
    <div class="contents">
        <div class="board__search">
            <div class="board__select">
                <select name="" id="Sel_Inst" class="select_department">
                    <option value="">전체</option>
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                </select>
            </div>
            <div class="board__input">
                <input type="text" id="Search_doc" placeholder="검색어를 입력하세요.">
                <button type="button" class="board__input-btn"></button>
            </div>
        </div>
        <div class="re-search__result contents">
            <p>총 <span class="c-blue">152</span>건의 글이 검색되었습니다.</p>
        </div>
        <div class="table-wrap table-type1 search-table">
            <table>
                <caption class="blind">표</caption>
                <colgroup>
                    <col style="width:25%;">
                    <col style="width:50%;">
                    <col style="width:25%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>의료기관명</th>
                        <th>주소</th>
                        <th>전화번호</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>창원한솔내과의원</td>
                        <td>경상남도 창원시 의창구 우곡로217번길 34 (명서동) </td>
                        <td>055-238-6260</td>
                    </tr>
                    <tr>
                        <td>창원윤종즙이비인후과의원</td>
                        <td>경상남도 창원시 의창구 원이대로 587 (용호동) 정우상가 3층</td>
                        <td>055-287-8667</td>
                    </tr>
                    <tr>
                        <td>마산예일이비인후과의원</td>
                        <td>경상남도 창원시 마산회원구 3·15대로 645 (석전동) 예일메디컬</td>
                        <td>055-293-5775~6</td>
                    </tr>
                    <tr>
                        <td>창원백승호가정의학과의원</td>
                        <td>경상남도 창원시 의창구 도계로 57 (도계동) 304호</td>
                        <td>055-288-7588</td>
                    </tr>
                    <tr>
                        <td>창원시민내과의원</td>
                        <td>경상남도 창원시 의창구 원이대로 271 (봉곡동) 4층</td>
                        <td>055-276-0044</td>
                    </tr>
                    <tr>
                        <td>창원굿모닝내과병원</td>
                        <td>경상남도 창원시 의창구 사화로 6 (팔용동)</td>
                        <td>055-265-2800</td>
                    </tr>
                    <tr>
                        <td>진해예인여성병원</td>
                        <td>경상남도 창원시 진해구 진해대로 958 (자은동)</td>
                        <td>055-542-9000</td>
                    </tr>
                    <tr>
                        <td>진해김기민소아과의원</td>
                        <td>경상남도 창원시 진해구 충장로 133 (여좌동)</td>
                        <td>055-545-2802</td>
                    </tr>
                    <tr>
                        <td>창원희연요양병원</td>
                        <td>경상남도 창원시 성산구 원이대로393번길 25 (반지동)</td>
                        <td>055-270-2580</td>
                    </tr>
                    <tr>
                        <td>삼성창원병원</td>
                        <td>경상남도 창원시 마산회원구 팔용로 158 (합성동)</td>
                        <td>055-233-8360~2</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="paging"><!--v-if--><!--v-if--><a class="board__pagination-btn num on" href="javascript:;">1</a><a
                class="board__pagination-btn num" href="javascript:;">2</a><a class="board__pagination-btn num"
                href="javascript:;">3</a><a class="board__pagination-btn num" href="javascript:;">4</a><a
                class="board__pagination-btn num" href="javascript:;">5</a><a class="board__pagination-btn num"
                href="javascript:;">6</a><a class="board__pagination-btn num" href="javascript:;">7</a><a
                class="board__pagination-btn num" href="javascript:;">8</a><a class="board__pagination-btn num"
                href="javascript:;">9</a><a class="board__pagination-btn num" href="javascript:;">10</a><a
                href="javascript:;" class="board__pagination-btn next">다음 페이지</a><a href="javascript:;"
                class="board__pagination-btn last">마지막 페이지</a></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            content: '',
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
    methods: {
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>