<template>
    <div class="login-box">
        <p class="contents sub-text dp2">아이디를 찾기 위해서는 아래와 같이 인증이 필요합니다. <br>
            아이디 찾기를 위한 인증방법을 선택해주세요.</p>

        <ul class="contents login-box__list id">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit">본인 인증</h3>
                <p class="desc">본인명의 휴대폰</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="this.$refs.NiceIdVue.fnPopup('findId')">아이디 찾기</a>
                </div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit">아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="this.$refs.NiceIdVue.popIpinAuth('findId')">아이디 찾기</a>
                </div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">휴대전화 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름" maxlength="20" v-model="pName">
                    </label>
                    <label class="login-box__phone">
                        <span class="input-tit">휴대전화</span>
                        <div class="input-text">
                            <input type="text" maxlength="3" v-model="pHp1"><span class="line">-</span>
                            <input type="text" maxlength="4" v-model="pHp2"><span class="line">-</span>
                            <input type="text" maxlength="4" v-model="pHp3">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue" @click="phoneCert">아이디 찾기</a></div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">이메일 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름" maxlength="20" v-model="eName">
                    </label>
                    <label class="login-box__email">
                        <span class="input-tit">이메일</span>
                        <div class="input-text">
                            <input type="text" maxlength="100" v-model="email1"> <span class="line">@</span>
                            <input type="text" maxlength="100" v-model="email2">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center"><a href="javascript:;" class="btn blue" @click="emailCert">아이디 찾기</a></div>
            </li>
        </ul>
        <div class="contents">
            <div class="btn-wrap right"><router-link :to="`/member/pwInquiry.do`" class="btn dark-gray">비밀번호 찾기</router-link></div>
        </div>
    </div>
    <NiceIdVue ref="NiceIdVue"></NiceIdVue>
</template>

<script>
import NiceIdVue from '../util/NiceId.vue';
export default {
    components: {
        NiceIdVue
    },
    data() {
        return {
            pName: '',
            pHp1: '',
            pHp2: '',
            pHp3: '',

            eName: '',
            email1: '',
            email2: '',
        }
    },
    created() {

    },
    mounted() {
        
    },
    methods: {
        // 휴대전화 인증
        phoneCert() {
            if (!this.pName) {
                alert('이름을 입력해주세요.')
                return
            }
            if (!this.pHp1 || !this.pHp2 || !this.pHp3) {
                alert('휴대전화번호를 입력해주세요.')
                return
            }

            const phone = this.pHp1 + '-' + this.pHp2 + '-' + this.pHp3
            this.axios
                .get(this.api.member.findIdPhone, {
                    params: {
                        // hpCd: this.$store.state.hpInfoStore.hpCd,
                        memName: this.pName,
                        memHpNo: phone
                    }
                })
                .then(res => {
                    const result = res.data;
                    if (res.data.status) {
                        this.$store.commit('setFind', {'휴대폰번호': phone.substring(0, 9) + '****'});
                        this.$router.push(`/member/idResolution.do`);
                    } else {
                        alert(result.errMsg);
                    }
                })
        },

        // 이메일 인증
        emailCert() {
            if (!this.eName) {
                alert('이름을 입력해주세요.')
                return
            }
            if (!this.email1 || !this.email2) {
                alert('이메일을 입력해주세요.')
                return
            }
            const email = `${this.email1}@${this.email2}`.replaceAll(" ", "")

            this.axios
                .get(this.api.member.findId, {
                    params: {
                        memName: this.eName,
                        memEmail: email,
                        // hpCd: this.$store.state.hpInfoStore.hpCd
                    }
                })
                .then(res => {
                    const result = res.data;
                    if (res.data.status) {
                        this.$store.commit('setFind', {'이메일' : this.$commonUtils.maskingEmail(result.email)});
                        this.$router.push('/member/idResolution.do');
                    } else {
                        alert(result.errMsg);
                    }
                })
        },
    },
}
</script>