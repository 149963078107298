<template>
    <!-- 내분비대사내과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>내분비대사내과는 우리 몸에서 호르몬 분비를 담당하는 내분비 기관들의 문제를 다루는 진료과입니다. 이에 해당하는 기관으로는 뇌하수체, 갑상선, 부갑상선, 췌장, 부신, 난소, 고환 등이 있습니다. 주로 당뇨병, 갑상선 질환, 뇌하수체 질환, 부신 질환, 고지혈증과 같은 지질 대사 이상, 비만 등에 대한 진단과 치료를 제공합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>당뇨병, 갑상선 질환, 이상지질혈증, 부신질환, 골다공증, 비만, 남성갱년기</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text"> 
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 내분비대사내과에서는 당뇨 및 합병증, 갑상선, 비만, 근감소 등 다양한 클리닉과 전문적인 진료를 통해 환자들에게 최상의 의료 서비스를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">당뇨병 진단과 치료, 체계적인 관리와 교육</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>당뇨 분야의 풍부한 경험과 최신 지식을 갖춘 전문의가 진료하고 있으며, 당뇨병의 진단과 치료에 대한 상세한 상담을 제공하고 있습니다. 당뇨병 교육 전문 간호사를 통해 체계적인 교육을 실시하고, 혈당 패턴 분석이 필요한 경우 연속혈당측정기 장착과 활용 방법에 대한 교육도 실시하고 있습니다. 인슐린 치료 시에는 인슐린 투여와 용량 조절 방법에 대한 교육도 제공하고 있습니다.</p>
                            </div>
                        </div>
                         <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">당뇨병 합병증 검사</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>이상지질혈증 동반 여부와 신장 기능을 확인하기 위한 혈액검사와 신장 합병증인 단백뇨 확인을 위한 소변검사를 실시하고 있습니다. 또한 외래 검사실에서 간편하게 안저촬영을 통해 망막 합병증을 확인할 수 있으며, 상·하지 혈류검사와 경동맥 초음파검사로 동맥경화 여부를 진단하고 있습니다. 합병증이 발견되면 타 과와의 협진을 통해 전문적인 치료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                         <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">갑상선질환의 진단과 검사</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑상선질환은 갑상선 기능 이상과 갑상선 결절로 인한 질환으로 나뉩니다. 갑상선 기능 이상은 혈액검사를 통해 간단히 확인할 수 있으며, 갑상선암의 신속하고 정확한 진단을 위해 내분비대사내과 전문의와 영상의학과 전문의가 직접 세포 검사를 시행하고 있습니다.</p>
                            </div>
                        </div>
                         <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">당뇨·합병증클리닉</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>당뇨병 환자의 혈당을 최적의 상태로 유지 관리하는 인슐린 주입 시스템인 ‘인공췌장’을 통해 맞춤 혈당조절 서비스를 제공하고 있습니다.</p>
                            </div>
                        </div>
                         <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">갑상선클리닉</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>갑상선 기능 저하 및 항진증, 갑상선 결절과 갑상선암 등을 보다 신속하고 정확하게 진단 및 치료하기 위해 혈액검사, 갑상선 초음파, 갑상선 스캔 검사를 시행하고 있습니다.</p>
                            </div>
                        </div>
                        <br>
                         <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="font-weight:bold;">비만·근감소클리닉</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>비만과 근감소증의 진단 및 치료를 위해 혈액검사, 신체 조성 분석 등 다양한 검사를 실시하고, 맞춤형 치료 계획을 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>

     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">당뇨·합병증클리닉,</a>
                                <a href="javascript:;">갑상선클리닉,</a>
                                <a href="javascript:;">비만·근감소클리닉</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {

    };
</script>