<template>
    <div class="my_result_wrap_view contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">외래정보</h4>
            </div>
        </div>
        <div class="table-cont" >
            <ul class="table-list type02">
                <li class="table-item">
                    <div class="item tit">처방일자</div>
                    <div class="item">{{ drugList[0].prcpdd }}</div>
                </li>
                <li class="table-item">
                    <div class="item tit">구분</div>
                    <div class="item">{{ drugList[0].ioflag }}</div>
                </li>
                <li class="table-item">
                    <div class="item tit">진료과</div>
                    <div class="item">{{ deptNm }}</div>
                </li>
                <li class="table-item">
                    <div class="item tit">진료의</div>
                    <div class="item">{{ drugList[0].dronenm }}</div>
                </li>
            </ul>
        </div>

        <div class="contents">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">외래약 처방내역</h4>
                </div>`
            </div>
            <div class="table-wrap table-type1 scroll" @click="addClass">
                <div class="scroll">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                            <col style="width:40%;">
                            <col style="width:15%;">
                            <col style="width:15%;">
                            <col style="width:15%;">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>약품명</th>
                            <th>횟수</th>
                            <th>용법</th>
                            <th>일수</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(drug, idx) in drugList" :key="idx">
                                <td>{{ drug.drugnm }}</td>
                                <td>{{ drug.drprcpetc8 }}</td>
                                <td>{{ drug.mthdcd1 }}</td>
                                <td>{{ drug.prcpdayno }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="contents">
            <div class="btn-wrap center">
                <router-link to="/mypage/result/drug.do" class="btn gray">목록</router-link>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';

export default {
    data() {
        return {
            selectedDateRange: [new Date(), new Date()],
            drugList: [{}],
            deptNm: ''
        };
    },
    components: {
        VueDatePicker,
    },
    mounted() {
        this.deptNm = this.$route.query.deptnm
        this.getDrugDetail()
    },
    methods: {
        getDrugDetail() {
            const searchParam = {}

            searchParam.prcpdd = this.$route.query.prcpdd
            searchParam.orddeptcd = this.$route.query.orddeptcd
            searchParam.orddrid = this.$route.query.orddrid

            this.axios.get(this.api.mypage.drugDetail, {
                params: searchParam
            }).then(res => {
                this.drugList = res.data.drugList.receivedruglist
                if(!Array.isArray(this.drugList)) this.drugList = [this.drugList]
                console.log(this.drugList)

            })
        },
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table && table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        },
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
};

</script>
