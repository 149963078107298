<template>
    <div class="privacy-policy">

        <p>창원파티마병원(이하 '병원' 이라 함)은 귀하의 개인정보를 매우 중요시하며, '개인정보보호법'상의 규정을 준수하고 있습니다. 병원은 귀하께서 제공하신 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지는지 알려 드립니다.</p>

        <div class="contents info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">개인정보처리방침 목차

                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <p class="small-text">※</p>
                        </div>
                        <div class="depth__text">
                            <p class="small-text">이 개인정보처리지침의 순서는 다음과 같습니다.</p>
                        </div>
                    </div>
                </h4>

            </div>
        </div>
        <div class="text-box type3">
            <ul class="text-box__list">
                <li class="text-box__item">
                    <a href="#sec1"><span>1.</span> 처리하는 개인정보의 항목 및 수집방법</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec2"><span>2.</span> 개인정보의 처리목적</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec3"><span>3.</span> 개인정보의 처리 및 보유기간</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec4"><span>4.</span> 개인정보의 파기절차 및 그 방법</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec5"><span>5.</span> 개인정보의 제3자 제공</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec6"><span>6.</span> 개인정보처리의 위탁</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec7"><span>7.</span> 정보주체의 권리와 행사방법</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec6"><span>8.</span> 동의철회/회원탈퇴 방법</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec9"><span>9.</span> 가명정보 처리에 관한 사항</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec10"><span>10.</span> 개인정보의 안정성 확보조치에 관한 사항</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec11"><span>11.</span> 개인정보 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec12"><span>12.</span> 개인정보보호책임자 및 담당자</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec13"><span>13.</span> 권익침해 구제 방법</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec14"><span>14.</span> 개인정보의 안정성 확보조치</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec15"><span>15.</span> 영상정보처리기기 설치 및 운영</a>
                </li>
                <li class="text-box__item">
                    <a href="#sec16"><span>16.</span> 정책 변경에 따른 공지</a>
                </li>
            </ul>
        </div>

        <div class="contents info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">주요 개인정보처리표시</h4>
            </div>
        </div>
        <div class="privacy-policy__mark-box">
            <ul class="privacy-policy__mark-list">
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>일반 개인정보 수집</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img01.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>이름</p>
                        <p><span>-</span>생년월일</p>
                        <p><span>-</span>핸드폰번호</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>개인정보 처리 목적</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img02.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>회원가입 및 본인 식별</p>
                        <p><span>-</span>진료/검진예약, 예약조회의 회원제 서비스 제공</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>개인정보 보유기간</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img03.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>회원탈퇴 시 파기</p>
                        <p><span>-</span>1년 이상 미이용시 분류보관 후 2년 이상 미이용시 파기</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>개인정보 열람 등 접수 부서</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img04.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>원무과</p>
                        <p><span>-</span>055-270-1107</p>
                        <p style="word-break: break-all;"><span>-</span>hospoffice@fatimahosp.co.kr</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>제3자 제공</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img05.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>건강보험심사평가원 등 '제공목적' 및 '제공근거'에 따라 '제공항목'을 제공</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>처리위탁</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img06.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>위탁계약 종료까지 '위탁업무내용'을 위해 '위탁개인정보'를 제공</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>파기</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img07.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>회원탈퇴 시 즉시파기</p>
                        <p><span>-</span>목적달성 후 즉시파기</p>
                        <p><span>-</span>기록/출력된 문서는 분쇄/소각하여 파기</p>
                    </div>
                </li>
                <li class="privacy-policy__mark-item">
                    <div class="privacy-policy__mark-inner">
                        <h5>개인정보 자동 수집</h5>
                        <div class="img">
                            <img src="/resource/icon/icon-img08.png" alt="이미지">
                        </div>
                    </div>
                    <div class="privacy-policy__text-box">
                        <p><span>-</span>쿠키(Cookie)</p>
                    </div>
                </li>
            </ul>
        </div>

        <p class="sub-contents c-gray5">창원파티마병원(이하 '병원' 이라 함)은 귀하의 개인정보를 매우 중요시하며, '개인정보보호법'상의 규정을 준수하고 있습니다. 병원은 귀하께서 제공하신 개인정보가 어떠한 용도와 방식으로
            이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지는지 알려 드립니다</p>


        <!-- sec1 -->
        <div id="sec1">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">1. 처리하는 개인정보의 항목 및 수집방법</h4><!-- depth03 -->
                        <p>병원은 서비스 이용을 위해 필요한 최소의 개인정보만을 수집합니다. 개인정보 수집에 대해 거부할 권리가 있으며, 서비스 제공을 위한 최소 필수 항목 제공에 동의를 거부하실 경우 서비스 이용에 제한이 있을 수 있습니다.</p>
                    </div>
                </div>
            </div>

            <div class="text-box type4">
                <div class="text-box__wrap">
                    <div class="info-text">
                        <h3 class="depth03__title title">진료정보</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 필수항목 : 성명, 주민등록번호, 주소, 자택 전화번호, 휴대폰 전화번호</p>
                                <p class="small-text">※ 의료법에 의해 고유식별정보 및 진료정보를 의무적으로 보유하여야 합니다.</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">홈페이지 - 종합건강검진 예약</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 필수항목 : 이름, 연락처</p>
                                <p class="small-text">※ 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다. : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">개인정보 수집방법</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 필수항목 : 이름, 연락처</p>
                                <p class="small-text">- 서면양식, 홈페이지, 팩스, 전화</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec2 -->
        <div id="sec2">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">2. 개인정보의 처리목적</h4><!-- depth03 -->
                        <p>병원은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 『개인정보 보호법』 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                        <br>
                        <p class="small-text">※법률에 특별한 규정이 있거나 법령 상 의무 준수를 위하여 불가피한 경우</p>
                        <p>- 의료법 및 동법 시행규칙 : 진료신청서, 진료기록부, 조산기록부, 간호기록부, 환자명부, 처방전 등 각종 기록과 환자 진료기록의 열람 및 사본교구</p>
                        <p>- 국민건강보험법 : 요양급여의뢰</p>
                        <p>- 자원봉사자 정보 : 자원봉사자 정보</p>
                        <p>- 전자금융거래법 : 진료비 수납</p>
                        <p>- 감염병의 예방 및 관리에 관한 법률 : 감염병 환자, 감염병 의사환자 또는 병원체보유자 신고</p>
                        <p>- 응급의료에 관한 법률 : 응급환자 이송</p>
                        <p>- 후천성면역결핍증예방법 감염인 : 진단 검안사실 신고</p>
                        <p>- 혈액관리법 : 특정수혈부작용신고</p>
                        <p>- 장기 등 이식에 관한 법률 : 뇌사추정자신고</p>
                        <p>- 보건의료기본법 : 질병자 또는 질병의심대상자 발견 신고, 보고, 통지</p>
                    </div>
                </div>
            </div>
            <div class="text-box type4">
                <div class="text-box__wrap">
                    <div class="info-text">
                        <h3 class="depth03__title title">진료 및 예약 정보</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 진단 및 치료를 위한 진료(협의진료 포함), 영양상담, 사회복지상담, 진료비 청구, 수납, 환급 등의 보건 및 행정 서비스</p>
                                <p>- 진료/건진 예약, 예약조회에 따른 본인 확인 절차에 사용</p>
                                <p>- 진료, 입원예정, 검사일정, 건진 일정 등에 대한 안내</p>
                                <p>- 건진 서비스 및 관련 정보 제공</p>
                                <p>- 새로운 서비스 및 행사정보 안내</p>
                                <p>- 치료 목적상 병원간의 진료협력(진료의뢰, 진료회신 등)을 위한 의료정보 제공</p>
                                <p>- 위탁, 외부검사(외부판독 포함) 등을 위한 기초자료</p>
                                <p>- 교육, 연구, 진료서비스 향상에 필요한 분석자료</p>
                                <p>- 질 향상 및 환자안전(감염, 결핵포함)을 위한 감시 및 평가 활동</p>
                                <p>- 관계법령에 의거한 정보 제공</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- sec3 -->
        <div id="sec3">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">3. 개인정보의 처리 및 보유기간</h4><!-- depth03 -->
                        <p>병원은 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의 개인정보를 지체 없이 파기합니다. 법령에 따른 개인정보 보유/이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유/이용 기간 내에서 개인정보를 처리/보유합니다.
                            각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                        <br>
                        <p>1) 법률에 특별한 규정이 있거나 법령 상 의무 준수를 위하여 불가피한 경우</p>
                        <p>(의료법 제22조제2항 및 시행규칙 제15조)</p>
                        <p>- 환자 명부 : 5년</p>
                        <p>- 진료기록부 : 10년</p>
                        <p>- 처방전 : 2년</p>
                        <p>- 수술기록 : 10년</p>
                        <p>- 검사내용 및 검사소견기록 : 5년</p>
                        <p>- 방사선 사진(영상물을 포함) 및 그 소견서 : 5년</p>
                        <p>- 간호기록부 : 5년</p>
                        <p>- 조산기록부: 5년</p>
                        <p>- 진단서 등의 부본(진단서·사망진단서 및 시체검안서 등을 따로 구분하여 보존할것) : 3년</p>
                        <br>
                        <p>※ 또한, 지속적인 진료를 위하여 필요한 경우에는 1회에 한정하여 각 항목의 기간만큼 연장하여 보존할 수 있습니다.</p>
                        <br>
                        <p>2) 진료 및 예약 정보</p>
                        <p>- 의료법 시행규칙 제15조 “진료에 관한 기록의 보존”에 준하여 보유합니다.</p>
                        <p>- 홈페이지에서의 「센터진료과예약」 및 「의료진선택예약」 내용 : 의료법 시행규칙 제15조“ 진료에 관한 기록의 보존”에 준하여 보유합니다</p>
                        <p>- 증명서발급신청 : 의료법 시행규칙 제15조의 기준에 처리됩니다</p>
                        <p>- 종합건강검진예약 : 의료법 시행규칙 제15조의 기준에 처리됩니다</p>
                        <br>
                        <p>3) 민원사무 처리</p>
                        <p>- 차량번호 등록 : 본인 요청에 따라 변경/삭제 가능하며 제공받은 목적이 달성된 경우에도 기타 관련 법령의 규정에 따라 보존할 필요성이 있는 경우에는 차량번호를 계속 보관할 수 있습니다.</p>
                        <p>- 외부영상 CD 등록 : 무인 CD 등록기에서 입력하신 정보는 입력완료 후 별도 보관하지 않습니다.</p>
                        <br>
                        <p>※ 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보관합니다.</p>
                        <p>1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료시까지</p>
                        <p>2) 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec4 -->
        <div id="sec4">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">4. 개인정보의 파기절차 및 그 방법</h4>
                        <p>병원은 '개인정보의 수집 및 이용목적'이 달성된 후에는 귀하의 개인정보를 다음과 같이 파기합니다.</p>
                        <br>
                        <p>- 파기절차 : 이용자가 입력한 정보는 목적이 달성된 후 파기 사유가 발생한 개인정보를 선정하고 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p>
                        <p>- 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용, 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기 후 소각</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec5 -->
        <div id="sec5">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">5. 개인정보의 제3자 제공</h4>
                        <p>병원은 정보주체의 개인정보를 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
                            응급의료에 관한 법률 제11조에 따라 응급환자를 다른 의료기관으로 이송할 경우 이송받는 의료기관에 진료에 필요한 의무기록을 제공할 수 있습니다. 또한 의료법 제21조 제3항 각 호에 해당하는 경우 환자에 관한 기록을 열람하거나 사본을 내주는 등 내용을 확인할 수 있도록 하고 있습니다.
                            그 외 기타 관계법령(수사기관의 요청 등)에 의한 정보를 아래와 같이 제공하고 있습니다.</p>
                    </div>
                </div>
            </div>
            <!-- <caption class="blind">표</caption> -->

            <div class="table-cont">
                <div class="table-wrap table-type1">
                    <div class="scroll">
                        <table class="privacy-policy__table">
                            <caption class="blind">표</caption>
                            <colgroup>
                                <col style="width:20%;">
                                <col style="width:20%;">
                                <col style="width:20%;">
                                <col style="width:20%;">
                                <col style="width:20%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>제공받는 기관명</th>
                                    <th>제공항목</th>
                                    <th>제공목적</th>
                                    <th>제공근거</th>
                                    <th>제공일자</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>건강보험심사평가원</td>
                                    <td>성명, 주민등록번호, 진단명, 진단일, 처방, 보험종류, 진료정보</td>
                                    <td>요양급여비용 청구 목적</td>
                                    <td>「국민건강보험법」 제47조, 제87조, 제88조, 제96조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>건강보험심사평가원</td>
                                    <td>성명, 주소, 의무기록사본</td>
                                    <td>진료비 미수금 대지급 청구</td>
                                    <td>「응급의료에 관한 법률」 시행규칙 제10조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>건강보험심사평가원</td>
                                    <td>연락처</td>
                                    <td>건강보험심사평가원 환자경험평가</td>
                                    <td>「개인정보보호법」 제18조 제2항 제2호,「의료법」 제21조 제3항 제4호,「국민건강보험법」 제96조 제4항</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>건강보험심사평가원</td>
                                    <td>의무기록 사본, 영상자료, 진료비 명세서</td>
                                    <td>비급여진료비 적정성 확인에 따른 자료 제출</td>
                                    <td>「국민건강보험법」 제48조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>경찰서, 법원, 검찰청</td>
                                    <td>소견서 및 의무기록 사본</td>
                                    <td>수사협조 및 판결을 위한 자료 제공</td>
                                    <td>「의료법」 제21조, 「형사소송법」 제106조, 제215조, 제218조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>국립연명의료관리기관</td>
                                    <td>성명, 주민등록번호, 성별, 전화번호, 주소, 보호자 주민등록번호, 보호자 전화번호</td>
                                    <td>국립연명의료관리기관 정보시스템 등록 및 관리</td>
                                    <td>「호스피스 완화의료 및 임종과정에 있는 환자의 연명의료결정에 관한 법률」 제9조, 제10조, 제11조, 제12조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>국민건강보험공단</td>
                                    <td>성명, 주소, 주민등록번호, 의무기록 사본</td>
                                    <td>가입자의 수급권 보호와 수진질서 확립</td>
                                    <td>「국민건강보험법」 제53조, 제58조제2항, 「국민건강보험 요양급여의 기준에 관한 규칙」 제4조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>국민건강보험공단, 산업안전보건공단, 근로자 건강검진 사업장</td>
                                    <td>성명, 주민등록번호, 전화번호(자택, 핸드폰), 집주소, 이메일, 보험내역, 사업장명, 입사일자, 현부서발령일, 부서명, 공정명, 근무형태, 직종형태, 국적, 외국인등록번호, 검진결과</td>
                                    <td>검진비용 청구 및 검진결과 전송</td>
                                    <td>「보건복지부 고시 제2020-313호 건강검진 실시기준」 제13조, 「산업안전보건법」 시행규칙 제209조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>국민건강보험공단, 시·군·구</td>
                                    <td>의무기록 사본 및 진료비 명세서</td>
                                    <td>급여비용 심사, 지급, 급여비용 심사, 지급, 대상 여부 확인/요양급여의 적정성 평가 가감지급</td>
                                    <td>「의료급여법」 제32조, 「노인장기요양보험법」 제60조, 「의료법」 제21조, 「국민건강보험법」 제47조, 제48조, 제57조, 제63조, 제96조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>국민연금공단</td>
                                    <td>의무기록 사본</td>
                                    <td>부양가족연금, 장애연금 및 유족연금 급여의 지급심사</td>
                                    <td>「의료법」 제21조, 「국민연금법」 제123조, 「장애인복지법」 제32조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>성명, 주민등록번호, 본인부담금 의료비 자료</td>
                                    <td>연말정산 의료비 세액공제 증명자료 제출</td>
                                    <td>「소득세법」 제165조, 동법 시행령 제216조의3</td>
                                    <td>매년</td>
                                </tr>
                                <tr>
                                    <td>근로복지공단</td>
                                    <td>소견서 및 의무기록 사본</td>
                                    <td>산재보험 업무 처리</td>
                                    <td>「산업재해보상보험법」 제118조, 의료법 제21조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>보건소</td>
                                    <td>성명, 주민등록번호, 직업, 성별, 전화번호</td>
                                    <td>법정 감염병 관련 정책 지원, 예방 및 관리</td>
                                    <td>「감염병 예방 및 관리에 관한 법률」 제11조, 제16조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>보건소, 경상남도청</td>
                                    <td>의무기록 열람 및 사본, 제증명</td>
                                    <td>감염병 예방 및 관리를 위한 역학조사</td>
                                    <td>「감염병 예방 및 관리에 관한 법률」 제12조, 제16조, 제18조</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>시·군·구</td>
                                    <td>의무기록 사본</td>
                                    <td>의료급여 수급권자 확인, 급여비용의 심사·지급, 백신접종 후 이상반응 역학조사 및 피해보상</td>
                                    <td>「의료법」 제21조, 「의료급여법」 제11조, 제32조의 2, 「감염병의 예방 및 관리에 관한 법률」 제18조의4</td>
                                    <td>요청 시</td>
                                </tr>
                                <tr>
                                    <td>식품의약품안전처</td>
                                    <td>성명, 주민등록번호</td>
                                    <td>마약류 취급정보에 관한 사항 보고의무</td>
                                    <td>「마약류 관리에 관한 법률」 제11조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>중앙암등록본부</td>
                                    <td>등록번호, 성명, 성별, 나이, 진료관련자료 등</td>
                                    <td>암 발생관련 조사</td>
                                    <td>「암관리법」 제8조, 동법 시행령 제17조, 제18조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>중앙응급의료정보센터</td>
                                    <td>등록번호, 성별, 연령, 진료정보</td>
                                    <td>응급의료기관 평가 자료 제공</td>
                                    <td>「응급의료에 관한 법률」 제17조, 제25조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>진료의뢰 회송 의료기관, 보건복지부, 건강보험심사평가원, 국민건강보험공단</td>
                                    <td>성명, 연락처, 주민등록번호, 외국인등록번호, 주소, 진료정보, 검사결과, 영상자료</td>
                                    <td>진료의 연속성 유지, 건강보험심사평가원 심사평가</td>
                                    <td>「개인정보보호법」 제18조 제2항 제1호, 제2호,「의료법」 제21조 제3항 제4호, 제21조의2,「보건의료기본법」 제5조, 제26조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>질병관리청</td>
                                    <td>성별, 출생년월, 나이, 진단정보, 손상정보, 입퇴원일 등 진료정보</td>
                                    <td>손상환자의 규모, 특성 및 시계열적 추이 파악</td>
                                    <td>「보건의료기본법」 제41조, 제53조, 제54조, 제57조, 「통계법」 제18조, 제26조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>통계청</td>
                                    <td>의료기관정보, 등록번호, 성명, 생년월일, 주소, 질병 및 치료정보</td>
                                    <td>모자보건정책의 기초자료 생성</td>
                                    <td>「통계법」 제17조, 제18조, 제25조, 「모자보건법」 시행규칙 제7조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>한국소비자원</td>
                                    <td>성별, 나이, 내원일, 발생일, 발생원인</td>
                                    <td>소비자의 물품에 대한 상해 조사</td>
                                    <td>「소비자기본법」 제52조 및 동법 시행령 제39조</td>
                                    <td>지속적</td>
                                </tr>
                                <tr>
                                    <td>한국의료분쟁조정중재원, 한국소비자원, 법원</td>
                                    <td>진료기록부, 영상자료, 제증명</td>
                                    <td>민원 및 분쟁의 해결</td>
                                    <td>「개인정보보호법」 제18조 제2항 제2호, 제7호, 제8호,「의료법」 제21조 제3항 제6호, 제7호, 제13호,「의료사고 피해구제 및 의료분쟁 조정등에 관한 법률」 제28조</td>
                                    <td>발생 시</td>
                                </tr>
                                <tr>
                                    <td>한국전기안전공사</td>
                                    <td>성별, 나이, 직업, 사고경위, 진료관련 자료 등</td>
                                    <td>감전사고 경위 파악 및 통계 생성</td>
                                    <td>「전기안전관리법」 제41조, 「통계법」 제26조</td>
                                    <td>요청 시</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec6 -->
        <div id="sec6">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">6. 개인정보처리의 위탁</h4>
                        <p>병원은 서비스 이행을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
                            병원의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.</p>
                    </div>
                </div>
                <div class="text-box type4">
                    <div class="text-box__wrap">
                        <div class="info-text">
                            <div class="depth__wrap depth03">
                                <div class="depth__text">
                                    <p>- 위탁업체 : 메디에디지</p>
                                    <p>- 위탁업무내용 : 임상데이터를 분석하여 “메디에이지” 분석 결과 제공</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 21세기 영상의학과의원</p>
                                    <p>- 위탁업무내용 : 방사선 영상 판독</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 대한의료영상의학과의원</p>
                                    <p>- 위탁업무내용 : 원격 방사선 영상 판독</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 의료법인 삼광의료재단</p>
                                    <p>- 위탁업무내용 : 검체 검사 의뢰</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 녹십자 의료재단</p>
                                    <p>- 위탁업무내용 : 검체 검사 의뢰</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 인피니트 헬스케어(주)</p>
                                    <p>- 위탁업무내용 : PACS시스템 유지 보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 엘씨테크</p>
                                    <p>- 위탁업무내용 : 영상EMR 유지 보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 한텍시스템</p>
                                    <p>- 위탁업무내용 : 서버, 스토리지 유지 보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 아크시스 주식회사</p>
                                    <p>- 위탁업무내용 : 의료정보시스템 DB 유지보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 에이씨케이</p>
                                    <p>- 위탁업무내용 : 장비인터페이스</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 아름누리메디컴</p>
                                    <p>- 위탁업무내용 : 전자동의서 유지보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 케이모스(주)</p>
                                    <p>- 위탁업무내용 : 키오스크 유지보수</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : 피어나인</p>
                                    <p>- 위탁업무내용 : 건강검진 결과 모바일 조회</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                    <br>
                                    <p>- 위탁업체 : (주)중앙911 구급대</p>
                                    <p>- 위탁업무내용 : 환자이송</p>
                                    <p>- 개인정보의 보유 및 이용기간 : 위탁계약 종료 시까지</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec7 -->
        <div id="sec7">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">7. 정보주체의 권리와 행사방법</h4>
                        <p>정보주체는 병원에 대해 언제든지 다음 각 호의 권리를 행사할 수 있습니다.</p>
                        <br>
                        <p>1) 환자(본인)에 관한 기록 열람</p>
                        <p>2) 그 외 개인정보 열람·정정·삭제·처리정지 요구</p>
                        <p class="small-text">※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보 주체는 정보주체 의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.</p>
                        <br>
                        <p>제1항 각 호의 권리 행사는 다음의 방법으로 하실 수 있습니다.</p>
                        <p>- 환자(본인)에 관한 기록 열람 : 본인임을 확인할 수 있는 신분증을 창원한마음병원에 제시</p>
                        <p>- 그 외 개인정보 열람, 정정, 삭제, 처리정지 요구 : 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX)</p>
                        <br>
                        <p>제1항 각 호에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다.</p>
                        <p>- 환자(본인)에 관한 기록 열람 : 환자가 지정하는 대리인이 환자 본인의 동의서와 대리권이 있음을 증명하는 서류를 첨부하는 등 보건복지부령으로 정하는 요건을 갖추어 요청한 경우, 다음 서류를 제출</p>
                        <p>① 기록열람이나 사본발급을 요청하는 자의 신분증 사본</p>
                        <p>② 환자가 자필 서명한 「의료법 시행규칙」 별지 제9호의2서식의 동의서 및 별지 제9호의3서식의 위임장이 경우 환자가 만 14세 미만의 미성년자인 경우에는 환자의 법정대리인이 작성하여야 하며, 가족관계증명서 등 법정대리인임을 확인할 수 있는 서류를 첨부하여야 한다.</p>
                        <p>③ 환자의 신분증 사본 다만, 환자가 만 17세 미만으로 「주민등록법」 제24조제1항에 따른 주민등록증이 발급되지 아니한 자는 제외한다.</p>
                        <p>- 그 외 개인정보 열람,정정,삭제,처리정지 요구 : “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출</p>
                        <br>
                        <p>제1항 각 호에 따른 권리 행사는 다음의 경우에는 제한될 수 있습니다.</p>
                        <p>- 환자(본인)에 관한 기록 열람 : 국가안보에 긴요한 사안으로 “다른 법률에 따라 진행중인 감사 및 조사에 관한 업무”를 수행하는데 지장을 초래할 때</p>
                        <p>- 그 외 개인정보 열람, 정정, 삭제, 처리정지 요구 : 「개인정보 보호법」 제35조 제4항 각 호, 제37조 제2항 각 호에 해당되는 경우</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec8 -->
        <div id="sec8">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">8. 동의철회/회원탈퇴 방법</h4>
                        <p>귀하께서 제공하신 개인정보의 수집ㆍ이용 및 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 병원 내 접수, 수납창구 혹은 정보를 저장한 부서로 연락하시면 개인정보를 파기하는 등 지체 없이 조치하겠습니다.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec9 -->
        <div id="sec9">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">9. 가명정보 처리에 관한 사항</h4>
                        <p>창원파티마병원은 통계작성, 과학적 연구, 공익적 기록보존 등을 위해 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명정보처리하는 행위를 하지 않습니다.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec10 -->
        <div id="sec10">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">10. 개인정보의 안정성 확보조치에 관한 사항</h4>
                        <p>개인정보 보호법 제29조(안전조치의무), 같은 법 시행령 제30조 및 제48조의2에 따라 다음과 같이 안전성 확보에 필요한 관리적,기술적,물리적 조치를 취하고 있습니다.</p>
                        <br>
                        <p>1) 내부관리계획의 수립 및 시행- 개인정보의 안전한 처리를 위하여 내부관리계획을 수립/시행하고 있으며 내부관리계획의 이행 실태를 연1회이상 점검/관리하고 있습니다.</p>
                        <p>2) 개인정보 취급 직원의 최소화 및 교육- 업무 수행에 필요한 최소한의 취급 직원을 지정/관리하고 있으며 개인정보를 취급하는 직원을 대상으로 개인정보 보호의무 등에 대한 정기적인 사내 교육 및 외부 위탁교육을 시행하고 있습니다.</p>
                        <p>3) 정기적인 자체 실태점검 시행- 개인정보 취급 관련 안정성 확보를 위해 정기적으로 자체 실태 점검을 시행하고 있습니다.</p>
                        <p>4) 개인정보의 암호화- 이용자의 비밀번호 및 고유식별정보는 모두 유출 및 훼손을 대비하여 저장 및 전송시 암호화 처리를 하는 등의 별도 보안기능을 사용하고 있습니다.</p>
                        <p>5) 보안프로그램 설치 및 주기적 점검/갱신- 해킹,바이러스,랜섬웨어 등에 의한 개인정보 유출,도난,훼손을 방지하기 위하여 보안프로그램을 설치하고 주기적으로 점검과 갱신을 하고 있습니다.</p>
                        <p>6) 개인정보에 대한 접근제한- 개인정보를 저장/처리하는 모든 시스템에 대한 접근권한(부여,변경,말소)을 통하여 개인정보에 대한 접근통제에 필요한 모든 조치를 하고 있으며, 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>
                        <p>7) 접속기록의 보관 및 위변조방지- 개인정보처리 시스템에 접속한 기록은 관련 법령과 내부관리계획에 따라 관리하고 있으며, 접속기록이 위조,변조,도난,분실되지 않도록 보안기능을 사용하고 있습니다.</p>
                        <p>8) 문서보안을 위한 잠금장치 사용- 개인정보가 포함된 문서나 저장매체 등은 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</p>
                        <p>9) 비인가자에 대한 출입통제- 개인정보를 보관하고 있는 물리적인 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립/운영하고 있습니다.</p>
                        <p>10) 관리용 단말기 안전조치- 개인정보의 유출 및 침해사고 방지를 위하여 관리용 단말기에 대해 안전조치를 적용하고 있습니다.</p>
                        <p>11) 재해 및 재난 대비 안전조치- 화재,홍수,단전 등의 재해 및 재난 발생시 개인정보처리 시스템을 보호하기 위한 재난대비 매뉴얼 및 대응절차를 마련하고 정기적으로 점검하고 있습니다.</p>
                    </div>
                </div>
            </div>
        </div>


        <!-- sec11 -->
        <div id="sec11">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">11. 개인정보 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항</h4>
                        <p>병원은 귀하에 대한 정보를 저장하고 수시로 찾아내는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 병원 웹서버는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 아이디 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만
                            귀하를 개인적으로 식별하지는 않습니다. 또한
                            귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다. 쿠키 설정을 거부하는 방법으로는 고객님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                            귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec12 -->
        <div id="sec12">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">12. 개인정보관리책임자</h4>
                        <p>병원은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br>
                            정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</p>
                    </div>
                </div>
            </div>

            <div class="text-box type4">
                <div class="text-box__wrap">
                    <div class="info-text">
                        <h3 class="depth03__title title">개인정보관리책임자</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 성 명 : 행정부원장 노경화</p>
                                <p>- 연락처 :055-270-1524</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">개인정보보호 실무담당자</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 성 명 : 전산정보팀 팀장 서충기</p>
                                <p>- 연락처 : 055-270-1558</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec13 -->
        <div id="sec13">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">13. 권익침해 구제 방법</h4>
                        <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
                        <div class="text-box type4">
                            <div class="text-box__wrap">
                                <div class="info-text">
                                    <h3 class="depth03__title title"></h3>
                                    <div class="depth__wrap depth03">
                                        <div class="depth__text">
                                            <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
                                            <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
                                            <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
                                            <p>- 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.</p>
                        <br>
                        <p>※개인정보보호 관련 고객 상담 및 신고</p>
                        <p>부서명 : 전산정보팀</p>
                        <p>담당자 : 서충기</p>
                        <p>연락처 : 055-270-1558, cg.seo@fatimahosp.co.kr</p>
                        <br>
                        <p>「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의 한 요구에 대 하여 공공기관의 장이 행한 처분또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정 하는 바에 따라 행정심판을 청구할 수 있습니다.</p>
                        <p>- 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec14 -->
        <div id="sec14">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">14. 개인정보의 안전성 확보조치</h4>
                        <p>병원은 개인정보 보호법 제29조(안전조치의무), 같은 법 시행령 제30조 및 제48조의2에 따라 다음과 같이 안전성 확보에 필요한 관리적,기술적,물리적 조치를 취하고 있습니다.</p>
                    </div>
                </div>
            </div>

            <div class="text-box type4">
                <div class="text-box__wrap">
                    <div class="info-text">
                        <h3 class="depth03__title title">관리적 조치</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 개인정보의 안전한 처리를 위한 내부관리계획 수립 및 시행</p>
                                <p>- 개인정보 처리하는 소속 직원, 수탁자에 대한 교육계획 수립 및 실시</p>
                                <p>- 정기적인 자체감사 실시</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">기술적 조치</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 개인정보에 대한 접근 권한의 제한 및 관리 조치</p>
                                <p>- 개인정보에 대한 접근 권한을 확인하기 위한 식별 및 인증조치</p>
                                <p>- 개인정보에 대한 권한없는 접근을 차단하기 위한 시스템의 설치</p>
                                <p>- 개인정보의 암호화</p>
                                <p>- 접속 기록의 보관 및 위조ㆍ변조 방지</p>
                                <p>- 보안프로그램 설치 및 주기적인 갱신ㆍ점검</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">물리적 조치</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 개인정보 보관시설에 대한 출입통제ㆍ잠금장치 설치</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- sec15 -->
        <div id="sec15">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">15. 영상정보처리기기 설치 및 운영</h4>
                        <p>병원은 아래와 같이 영상정보처리기기를 설치 운영하고 있습니다.</p>
                    </div>
                </div>
            </div>

            <div class="text-box type4">
                <div class="text-box__wrap">
                    <div class="info-text">
                        <h3 class="depth03__title title">설치근거 및 목적</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>개인정보보호법 제25조 제1항에 따라 다음과 같은 목적으로 영상정보처리기기를 설치, 운영한다.</p>
                                <br>
                                <p>- 시설안전 및 화재 예방</p>
                                <p>- 고객의 안전을 위한 범죄 예방</p>
                                <p>- 차량도난 및 파손방지(주차장의 경우)</p>
                                <p class="small-text">※ 주차대수 30대 초과하는 규모의 경우 '주차장법 시행규칙' 제6조 제1항을 근거로 설치,운영 가능</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">설치 대수 및 위치, 촬영범위</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 병원동 : 272대 (병동, 복도, 외래, E/V, 지하주차장등)</p>
                                <p>- 기숙사동 : 23대 (복도, 출입구, E/V등)</p>
                                <p>- 야외주차장 : 22대 (주차면, 통행로)</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">관리책임자 및 접근권한자</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 관리책임자 : 시설환경팀장 (055-270-1470)</p>
                                <p>- 접근권한자 : 안전보건팀 안전보안담당자 (055-270-1030)</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">영상정보의 촬영시간, 보관기간, 보관장소, 처리방법</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 촬영시간 : 24시간</p>
                                <p>- 보관기간 : 촬영일로부터 14일</p>
                                <p>- 보관장소 : 병원 방재실</p>
                                <p>- 처리방법 : 개인영상정보의 목적외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 관리하고 보관기간 만료 시 복원이 불가능한 방법으로 영구 삭제</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">영상정보 확인방법</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 확인방법 : 직접방문(병원안전보안담당자에게 사전연락 후 방문 270-1030)</p>
                                <p>- 확인장소 : 방재실</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">정보주체의 영상정보 열람 등 요구에 대한 조치</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 개인정보에 대한 접근권한을 차등부여한다.</p>
                                <p>- 개인영상정보의 위ㆍ변조 방지를 위하여 개인영상정보의 생성일시, 열람 시 열람 목적ㆍ열람자ㆍ열람 일시 등을 기록하여 관리한다.</p>
                                <p>- 개인영상정보의 안전한 물리적 보관을 위하여 잠금장치를 설치한다.</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">영상정보보호의 안전성 확보조치</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>- 개인정보에 대한 접근권한을 차등부여</p>
                                <p>- 개인영상정보의 위·변조 방지를 위하여 개인영상정보의 생성 일시, 열람 시 열람 목적·열람자·열람 일시 등을 기록하여 관리</p>
                                <p>- 개인영상정보의 안전한 물리적 보관을 위하여 잠금장치를 설치</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec16 -->
        <div id="sec16">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">16. 정책 변경에 따른 공지</h4>
                        <p>이 개인정보처리방침은 2022년 10월 1일부터 적용되며, 법령·정책 또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는 시행하기 최소 7일전에 공지합니다.</p>
                        <br>
                        <p>공고일자 : 2022. 09. 22</p>
                        <p>시행일자 : 2022. 10. 01</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- sec17 -->
        <div id="sec17">
            <div class="info-text">
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <h4 class="depth02__title title">개인정보처리방침 변경 내역</h4><!-- depth03 -->
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2011.09.30.pdf" target="_blank" title="새창열림" class="c-gray5">2011. 09. 30 최초 시행(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2012.04.01.pdf" target="_blank" title="새창열림" class="c-gray5">2012. 04. 01 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2012.12.01.pdf" target="_blank" title="새창열림" class="c-gray5">2012. 12. 01 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2014.03.01.pdf" target="_blank" title="새창열림" class="c-gray5">2014. 03. 01 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2014.08.07.pdf" target="_blank" title="새창열림" class="c-gray5">2014. 08. 07 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2016.06.01.pdf" target="_blank" title="새창열림" class="c-gray5">2016. 06. 01 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2018.10.01.pdf" target="_blank" title="새창열림" class="c-gray5">2018. 10. 01 변경(보기)</a></p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p><a href="/resource/file/2020.10.01.pdf" target="_blank" title="새창열림" class="c-gray5">2020. 10. 01 변경(보기)</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        data() {
            return {

            };
        },
        methods: {

        },
        mounted() {

        }

    };
</script>

<style>
    /* 추가적인 스타일링은 생략 */
</style>