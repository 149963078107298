<template>
    <!-- 신경외과 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경외과는 뇌와 척수를 포함한 중추신경계와 말초신경계에 발생하는 질병을 진단하고, 시술 및 수술을 통해 치료하고 있습니다. 각종 신경계통 질환의 치료를 위해 4명의 전문의가 크게 뇌, 척추, 말초신경계통으로 나눠 전문 분야별 세부 진료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">진료분야</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>뇌혈관내 수술(뇌동맥류, 뇌지주막하출혈, 기계적 혈전제거술, 경동맥협착증), 두부외상, 뇌출혈 등 뇌혈관질환, 척추 디스크, 척추외상, 퇴행성척추질환, 미세척추수술, 말초신경</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">골든타임 지키는 24시간 응급 협진 시스템</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경계는 인체 기능을 조절하는 중요한 장기로 작은 손상에도 치명적이고 영구적인 후유증을 초래할 수 있어 신속 정확한 진단과 치료가 무엇보다 중요합니다. 뇌혈관이 갑자기 터지면 의식저하나 극심한 두통이 발생하고, 근본적인 치료인 뇌출혈을 멈추는 치료가 가장 시급하며, 이후 뇌압 조절, 수두증 조절 등이 필요합니다.</p>
                                <p>창원파티마병원 신경외과는 중증응급환자에게 골든타임 내 신속한 치료를 제공하기 위해 응급의료센터와 협진하여 365일 24시간 시술 및 수술이 가능하도록 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">대한신경중재치료의학회 지정 신경중재치료 인증병원</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원은 대한신경중재치료의학회(KSIN)가 지정한 ‘신경중재치료 인증병원’입니다. 신경중재치료는 첨단영상의학기기를 이용해 미세도관을 뇌혈관으로 삽입해 각종 뇌혈관과 신경계 질환을 진단 및 치료하는 분야로 대한신경중재치료의학회가 지정한 인증병원은 두경부 및 척추혈관 질환 환자를 전문으로 치료하면서 학회가 정한 요건을 충족하고, 이들 환자에게 최적의 치료를 제공할 수 있음을 인정하는 기관을 말합니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">머리·척추의 외상, 노령 환자의 골절 치료까지</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>신경외과에서는 가벼운 외상부터 교통사고, 낙상 등으로 인한 심각한 외상까지 머리나 척추의 손상으로 인한 두통, 어지럼, 마비, 통증 등을 진단 및 치료합니다. 또한 고령 환자의 경우 골다공증으로 작은 충격에도 등허리 골절이 흔히 발생하기 때문에 골절 시 골다공증 치료와 함께 골절된 뼈를 보강하는 척추성형술이나 골절로 무너진 뼈대를 세우는 척추 수술 등도 시행하고 있습니다. 특히 골다공증성 척추 압박골절은 신경통을 비롯해 운동 제한, 소화 기능 저하, 욕창, 하지 혈전증, 염증 및 호흡기 문제 등이 추가로 진행될 수 있어 조속한 일상 복귀와 후유증 최소화를 위해 척추체 성형술 등의 체계적인 치료를 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">목, 허리, 팔, 다리의 신경통 원인 정확히 찾아 증상 완화</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>척추디스크나 척추관절증 등은 목, 허리의 통증과 팔, 다리의 시림, 저림 등 신경통을 유발합니다. 너무 고령이거나 지병으로 수술이 쉽지 않은 환자의 신경통 완화를 위한 약물 및 물리치료를 제공하고 있으며, 호전이 없을 시 간단한 주사 치료나 시술 등을 통해 통증을 조절하여 삶의 질을 높일 수 있도록 돕고 있습니다. 또한 아무리 치료해도 호전이 없어 내원한 환자분들의 경우 말초신경통, 혈관협착 등 다른 방면으로도 가능성을 열어두고 검사 및 치료를 시도해 볼 수 있습니다.</p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>

     <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">관련센터/클리닉</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text mint_link">
                                <a href="javascript:;">뇌혈관센터</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>