const requireComponent = require.context(
    '.',
    true,
    // 기본 컴포넌트 파일명 정규식
    /[A-Za-z]\w+\.vue$/
);

const components = {};

requireComponent.keys().forEach(fileName => {
    // 컴포넌트 설정 가져오기
    const componentConfig = requireComponent(fileName);

    // 컴포넌트 이름 가져오기 (파일명에서 경로와 확장자 제거)
    const componentName = fileName.replace(/^\.\/(.*)\//, '').replace(/\.\w+$/, '');

    // 컴포넌트를 객체에 추가
    components[componentName] = componentConfig.default || componentConfig;
});

export default components;