<template>
    <Search @setState="search"></Search>

    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item" :class="params.category === '' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('')">전체</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '구강' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('구강')">구강</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '귀코목' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('귀코목')">귀,코,목</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '내분비' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('내분비')">내분비</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '뇌신경' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('뇌신경')">뇌신경</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '눈' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('눈')">눈</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '면역' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('면역')">면역</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '비뇨기' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('비뇨기')">비뇨기</button>
                </li>
            </ul>
            <ul class="tab-menu__list">
                <li class="tab-menu__item" :class="params.category === '뼈근육' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('뼈근육')">뼈근육</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '생식기' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('생식기')">생식기</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '소화기' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('소화기')">소화기</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '순환기' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('순환기')">순환기</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '피부' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('피부')">피부</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '호흡기' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('호흡기')">호흡기</button>
                </li>
                <li class="tab-menu__item" :class="params.category === '기타' ? 'on' : ''">
                    <button type="button" class="tab-menu__btn" @click="emitCategory('기타')">기타</button>
                </li>
            </ul>
        </div>
    </div>
    <!--  board-card component 호출 및 params 변수 전달  -->
    <board-card ref="boardCard" @articleView="articleView" @getContents="getContents"></board-card>
    <pagination :totCnt="totCnt" :pageRow="params.pageRow" :currentNum="params.startIndex" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
    <!-- <button @click="moreList">더보기</button> -->
</template>
<script>
    import BoardCard from '@/components/boards/BoardCard.vue'
    import Search from '@/components/common/SearchBox.vue'
    import Pagination from '../common/Pagination.vue'

    export default {
        components: {
            Search,
            BoardCard,
            Pagination
        },
        data() {
            return {
                params: {
                    instNo: 1,
                    boardNo: 25,
                    startIndex: 1,
                    pageRow: 16,
                    title: this.$route.query.title || '',
                    content: this.content,
                    orderValue: 'TITLE',
                    searchKeyword: this.searchKeyword,
                    category: ''
                },
                searchParam: {},
                pagingFlag: false,

                totalPage: 0,
                pageCount: 5,
                totCnt: 0,
            }
        },
        methods: {
            setNum(page) {
                this.$refs.boardCard.printList = []
                this.getList(page)
            },
            search(searchType, searchKeyword) {
                this.params.title = searchKeyword

                this.$refs.boardCard.printList = []

                this.getList(1)
            },
            articleView(key, boardNo, articleNo) {
                this.$router.push({ path: '/healthinfo/diseaseDetail.do', query: { key: key, boardNo: boardNo, articleNo: articleNo } });
            },
            async getContents(list, totCnt) {
                let keys = list.map(item => item.key)
                this.totCnt = totCnt
                this.totalPage = Math.ceil(this.totCnt / this.params.pageRow);

                await this.axios.post(this.api.health.kdcaList, keys).then(res => {
                    const resultList = res.data

                    for (let i = 0; i < list.length; i++) {
                        for (let j = 0; j < resultList.length; j++) {
                            if (list[i].key == resultList[j].key) {

                                if(Array.isArray(resultList[j].XML.svc.cntntsClList.cntntsCl)){
                                    list[i].content = resultList[j].XML.svc.cntntsClList.cntntsCl[0].CNTNTS_CL_CN
                                }else{
                                    list[i].content = resultList[j].XML.svc.cntntsClList.cntntsCl.CNTNTS_CL_CN
                                }
                                // for (let k in resultList[j].result) {
                                //     if (resultList[j].result[k].contents != null && resultList[j].result[k].contents != '') {
                                //         list[i].content = resultList[j].result[k].contents
                                //         break
                                //     }
                                //
                                // }
                                // break
                            }
                        }
                    }

                    this.$refs.boardCard.printList.push(...list)
                })

                this.$refs.boardCard.updateMasonryLayout()

            },
            getList(page) {
                this.params.startIndex = page
                this.$refs.boardCard.getList(this.params)
            },
            moreList() {
                this.getList(this.params.startIndex + 1)
            },
            emitCategory(category) {
                this.params.category = category
                // this.$emit('category', category)
                this.$refs.boardCard.printList = []
                this.getList(1)
            }
        },
        mounted() {
            this.getList(1)
        },


    }
</script>