<template>
    <Header></Header>
    <!-- <LocationBar></LocationBar> -->
    <div class="container">
        <div class="sub-tit">
            <menuTitle></menuTitle>
        </div>
        <div class="inner">
            <Search></Search>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
    import Header from '@/components/common/Header.vue';
    import LocationBar from '@/components/common/LocationBar.vue';
    import Search from '@/components/search/SearchPage.vue';
    import Footer from '@/components/common/Footer.vue';


    export default {
        components: {
            Header,
            Search,
            LocationBar,
            Footer
        },
        mounted() {
            
        },
    }
</script>
<style>
    /* @import url('../../assets/resource/css/sub.css'); */
</style>