<template>
    
</template>

<script>
export default {
    data() {
        return {
            memCertFindId: '',
            encodeData: this.$route.query.EncodeData,
            ipinEncData: this.$route.query.enc_data,

            encData: this.$route.query.enc_data,
        }
    },
    mounted() {
        if (this.$store.state.memberStore.niceType == 'findPw') {
            this.certInfoToPwResolution();
        } else if (this.$store.state.memberStore.niceType == 'findId') {
            this.certInfoToIdResolution();
        } else if (this.$store.state.memberStore.niceType == 'sleep') {
            this.certInfoToSleepMember();
        } else {
            this.certInfoToRegister();
        }
    },
    methods: {
        async decodeData(){
            var result;
            const data = this.$store.state.memberStore.niceType == '' ? this.$store.state.memberStore.niceIpin : this.$store.state.memberStore.nice
            // alert('check: ' + JSON.stringify(this.$store.state.memberStore.nice))
            await this.axios
                .get(this.api.token.decodeData, {
                    params: {
                        key: data.key,
                        iv: data.iv,
                        encData: this.encData,
                    }
                })
                .then(res => {
                    result = res.data;
                    result.name = decodeURIComponent(result.utf8_name)
                    result.DI = result.dupinfo
                    result.CI = result.coinfo1
                })

            return result;
        },

        async encDataCheck() {
            var result;
            const params = new URLSearchParams();
            params.append('EncodeData', this.encodeData);
            await this.axios
                .post(this.api.member.encDataChk, params)
                .then(res => {
                    result = res.data.result;
                })
                .catch(function (error) {
                    alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                });

            return result;
        },
        async encDataCheckIpin() {
            var result;
            const params = new URLSearchParams();
            params.append('enc_data', this.ipinEncData);
            await this.axios
                .post(this.api.member.ipinEncDataChk, params)
                .then(res => {
                    result = res.data.result;
                });

            return result;
        },
        
        // 본인인증 정보 회원정보입력 페이지로 보내기
        async certInfoToRegister() {
            const result = await this.decodeData();
            this.registerResolution(result);
        },

        // 본인인증 정보 아이디찾기 페이지로 보내기
        async certInfoToIdResolution() {
            const result = await this.decodeData();
            this.findId(result);

        },

        // 본인인증 정보 비밀번호찾기 완료 페이지로 보내기
        async certInfoToPwResolution() {
            const result = await this.decodeData();
            this.findPassword(result);

        },

        // 본인인증 정보 휴면회원수정 페이지로 보내기
        async certInfoToSleepMember() {
            const result = await this.decodeData();
            this.getSleepInfo(result);

        },

        // 아이핀 정보 회원정보입력 페이지로 보내기
        async certInfoToRegisterIpin() {
            const result = await this.encDataCheckIpin();
            this.registerResolution(result);
        },

        // 아이핀 정보 아이디찾기 페이지로 보내기
        async certInfoToIdResolutionIpin() {
            const result = await this.encDataCheckIpin();
            this.findId(result);

        },

        // 아이핀 정보 비밀번호찾기 완료 페이지로 보내기
        async certInfoToPwResolutionIpin() {
            const result = await this.encDataCheckIpin();
            this.findPassword(result);

        },

        // 아이핀 정보 휴면회원수정 페이지로 보내기
        async certInfoToSleepMemberIpin() {
            const result = await this.encDataCheckIpin();
            this.getSleepInfo(result);

        },

        registerResolution(result) {
            if (result != undefined) {
                this.axios
                    .get(this.api.member.checkDuplicateDi, {
                        params: {
                            DI: result.DI == null ? result.di : result.DI
                        }
                    })
                    .then(res => {
                        const duplResult = res.data.result;

                        if (duplResult.length == 0) {
                            this.$store.commit('getMemJoinRoute', result.authmethod == null ? result.authtype : result.authmethod);
                            this.$store.commit('setMemBirth', result.BIRTHDATE == null ? result.birthdate : result.BIRTHDATE);
                            this.$store.commit('setMemCi', result.CI == null ? result.ci : result.CI);
                            this.$store.commit('setMemDi', result.DI == null ? result.di : result.DI);

                            const gender = result.gender == null ? result.gendercode : result.gender
                            this.$store.commit('setMemGender', gender == 1 ? 'M' : 'F');
                            this.$store.commit('setMemName', result.NAME == null ? result.name : result.NAME);

                            opener.location.href = '/member/register.do';
                            alert('본인인증이 완료되었습니다.');
                            self.close();
                        } else {
                            alert('가입된 정보가 있습니다.');
                            self.close();
                        }
                    })
                    .catch(function (error) {
                        alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                        self.close();
                    })
                    ;
            } else {
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                self.close();
            }
        },

        findId(result) {
            if (result != undefined) {
                this.axios.get(this.api.member.findId, {
                    params: {
                        memDi: result.DI == null ? result.di : result.DI
                    }
                })
                    .then(res => {
                        const data = res.data;
                        if (data.status) {
                            if(data.email){
                                this.$store.commit('setFind', {'이메일' : this.$commonUtils.maskingEmail(data.email)});
                                opener.location.href = '/member/idResolution.do';
                                alert('본인인증이 완료되었습니다.');
                                self.close();
                            }else{
                                var pN = data.hpNo.substring(0, 9) + '****';
                                this.$store.commit('setFind', {'휴대폰번호': pN});
                                opener.location.href = '/member/idResolution.do';
                                alert('본인인증이 완료되었습니다.');
                                self.close();
                            }
                        } else {
                            alert(data.errMsg);
                            self.close();
                        }
                    });

            } else {
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                self.close();
            }
        },

        findPassword(result) {

            if (result != undefined) {
                this.axios.get(this.api.member.findPassword, {
                    params: {
                        memId: this.$store.state.memberStore.findMemId,
                        memDi: result.DI == null ? result.di : result.DI,
                    }
                })
                    .then(res => {
                        const data = res.data;
                        if (data.status) {
                            if(data.email){
                                this.$store.commit('setFind', {'이메일' : this.$commonUtils.maskingEmail(data.email)});
                                opener.location.href = '/member/pwResolution.do';
                                alert('본인인증이 완료되었습니다.');
                                self.close();
                            }else{
                                var pN = data.hpNo.substring(0, 9) + '****';
                                this.$store.commit('setFind', {'휴대폰번호': pN});
                                opener.location.href = '/member/pwResolution.do';
                                alert('본인인증이 완료되었습니다.');
                                self.close();
                            }
                        } else {
                            alert(data.errMsg);
                        }
                    });

            } else {
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                self.close();
            }
        },

        getSleepInfo(result){
            if (result != undefined){
                const params = new URLSearchParams();
                params.append('memId', this.$store.state.memberStore.find.id);
                var name = result.NAME == null ? result.name : result.NAME;
                var birth = result.BIRTHDATE == null ? result.birthdate : result.BIRTHDATE;
                params.append('memName', name);
                params.append('memBirth', birth);
                this.axios.post(this.api.member.sleep, params)
                .then(res => {
                    if(res.data.code == '0000'){
                        if(this.$store.state.memberStore.find.id == res.data.member.memId){
                            const id = this.$store.state.memberStore.find.id
                            const find = {
                                id: id,
                                name: result.NAME == null ? result.name : result.NAME
                            }
                            this.$store.commit('setFind', find);
                            opener.location.href = '/member/sleepModify.do';
                            alert('본인인증이 완료되었습니다.');
                            self.close();
                        }else{
                            alert('회원정보와 본인인증정보가 맞지 않습니다.');
                            self.close();
                        }
                    }else{
                        alert(res.data.msg);
                        self.close();
                    }
                })
            }else{
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
                self.close();
            }
		},

        iosAlert(msg) {
            var ios = (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));
            if (ios) {
                alert(msg);
            }
        },
    },
    created() {
    },
}
</script>