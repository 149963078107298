<template>
  <div class="chart-container">
    <Line :data="filteredChartData" :options="chartOptions" />
    <div class="agree-input contents">
      <label for="health-all01" class="agree-input__item" style="color:#00a6a5;">
        <input type="radio" id="health-all01" class="agree-input__radio health-all" name="agree" value="all" v-model="selectedDataset"/>
        <span class="icon"></span> 전체 
      </label>
      <label for="health-item01" class="agree-input__item" style="color:#1e4e95;">
        <input type="radio" id="health-item01" class="agree-input__radio health-item01" name="agree" value="item01" v-model="selectedDataset"/>
        <span class="icon"></span>{{ chartDataSet.type == 'BP' ? '수축기혈압' : chartDataSet.type == 'BS' ? '식전' : 'BMI 지수' }}
      </label>
      <label for="health-item02" class="agree-input__item" style="color:#e56d9c;" v-show="chartDataSet.type != 'BM'">
        <input type="radio" id="health-item02" class="agree-input__radio health-item02" name="agree" value="item02" v-model="selectedDataset"/>
        <span class="icon"></span>{{ chartDataSet.type == 'BP' ? '이완기혈압' : chartDataSet.type == 'BS' ? '식후' : 'BMI 지수' }}
      </label>
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  props: {
    chartDataSet: {}
  },
  components: {
    Line
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'HealthInfo 1',
            backgroundColor: 'transparent',
            borderColor: '#1e4e95',
            pointBackgroundColor: '#1e4e95',
            pointBorderColor: '#1e4e95',
            data: [],
            tension: 0.4,
            borderWidth: 1
          },
          {
            label: 'HealthInfo 2',
            backgroundColor: 'transparent',
            borderColor: '#e56d9c',
            pointBackgroundColor: '#e56d9c',
            pointBorderColor: '#e56d9c',
            data: [],
            tension: 0.4,
            borderWidth: 1
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              font: {
                family: 'Pretendard',
                size: window.innerWidth <= 720 ? 14 : 20
              },
              color: '#777',
              padding: 20 
            },
            grid: {
              display: false
            },
            offset: true,
          },
          y: {
            min: 0,
            max: 200,
            ticks: {
              font: {
                family: 'Pretendard',
                size: window.innerWidth <= 720 ? 16 : 20
              },
              color: '#777',
              stepSize: 40,
            },
            border: {
              display: false 
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },
      selectedDataset: 'all',
    }
  },
  computed: {
    filteredChartData() {
      if (this.selectedDataset === 'item01') {
        return {
          ...this.chartData,
          datasets: [this.chartData.datasets[0]]
        }
      } else if (this.selectedDataset === 'item02') {
        return {
          ...this.chartData,
          datasets: [this.chartData.datasets[1]]
        }
      } else {
        return {
          ...this.chartData,
          datasets: [this.chartData.datasets[0], this.chartData.datasets[1]]
        }
      }
    },
    resultData(){
      let newData = {
        labels: this.chartDataSet.labels || [],
        datasets: [
          {
            ...this.chartData.datasets[0],
            data: this.chartDataSet.record1 || []
          },
          {
            ...this.chartData.datasets[1],
            data: this.chartDataSet.record2 || []
          }
        ]
      };
      return newData;
    }
  },
  watch: {
    chartDataSet: {
      handler(newData) {
        let newLabels = newData.labels || [];
        let newRecord1 = newData.record1 || [];
        let newRecord2 = newData.record2 || [];

        this.chartData.labels = newLabels
        this.chartData.datasets[0].data = newRecord1
        this.chartData.datasets[1].data = newRecord2
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
* {
  font-family: 'Pretendard', sans-serif;
}
</style>
