<template>
  <div class="chart-container">
    <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'HealthChartBar',
  components: {
    Bar,
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
    };
  },
  mounted() {
    this.initializeChartData();
  },
  methods: {
    initializeChartData() {
      this.chartData = {
        labels: [
          ['귀하의', '관상동맥', '질환위험도'],
          ['비슷한연령대의', '관상동맥', '질환위험도'],
          ['비슷한연령대의', '지위함군의', '질환위험도']
        ],
        datasets: [
          {
            label: '질환위험도',
            backgroundColor: ['#bbcadf', '#b2e4e4', '#b2e1d4'], // 각 막대의 배경색
            borderColor: 'none', // 경계선 색상
            borderWidth: 0, // 경계선 두께
            data: [14, 21, 9], // 데이터
            barThickness: 30,
          },
        ],
      };

      this.chartOptions = {
        
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // 수평 막대 차트를 위해 y축으로 설정
        scales: {
          x: {
            beginAtZero: true, // 0부터 시작
            max: 30, // x축 최대값 설정
            min: 0, // x축 최소값 설정
            ticks: {
              stepSize:  window.innerWidth <= 720 ? 15 : 6, // x축 간격 설정
              color: '#777',
              font: {
                size: window.innerWidth <= 720 ? 18 : 20
              },
              callback: function (value) {
                return value + '%'; // % 표시 추가
              }
            },
            grid: {
              display: true, // x축 그리드 라인 숨기기
            }
          },
          y: {
            ticks: {
              color: '#777', // y축 텍스트 색상
              font: {
                size: window.innerWidth <= 720 ? 16 : 20
              },
              align: 'start', // 텍스트 왼쪽 정렬
            },
            grid: {
              drawBorder: false, // y축 끝선 숨기기
              borderColor: 'transparent',
              display: false, // y축 그리드 라인 숨기기
            },
            border: {
              display: false, // y축 끝선 숨기기
            },
          },
        },
        plugins: {
          legend: {
            display: false, // 범례 숨기기
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.raw + '%'; // 툴팁에 % 표시 추가
              }
            }
          },
        }
      };
    }
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 400px; 
}
* {
  font-family: 'Pretendard', sans-serif;
}

@media (max-width:720px){
.chart-container {
  width: 100%;
  height: 300px; 
}
}
</style>
