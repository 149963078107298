<template>
  <div class="contents">
    <ul class="step-textbox consignment-step type02">
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                        구비서류
                    </h3>
                </div>
                <div class="step-textbox__con">
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                            <p>사업자등록증사본</p>
                        </div>
                    </div>
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                            <p>산재보험, 건강보험관련자료</p>
                        </div>
                    </div>
                    <div class="depth__wrap ">
                        <div class="depth__bullet">
                            <span class="bullet03"></span>
                        </div>
                        <div class="depth__text">
                            <p>기타 보건관리 관련자료</p>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                      계약체결
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                      보건관리자 선정 <br>
                      방문일 결정
                    </h3>
                </div>
            </div>
        </li>
        <li class="step-textbox__item">
            <div class="step-textbox__item-wrap">
                <div class="step-textbox__title">
                    <h3 class="depth02__title">
                      통보 <br>
                      [해당 고용노동지청으로 선임 보고]
                    </h3>
                </div>
            </div>
        </li>
    </ul>
    <h4 class="depth02__title title contents">문의 : 보건대행실 270-1060</h4>
  </div>
</template>
<script>
export default {};
</script>