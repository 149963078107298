<template>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item"><button type="button" class="tab-menu__btn" @click="this.$router.push('/mypage/favo/favoDoctors.do')">관심 의료진</button></li>
                <li class="tab-menu__item active"><button type="button" class="tab-menu__btn" @click="this.$router.push('/mypage/favo/favoContents.do')">관심 콘텐츠</button></li>
            </ul>
        </div>
    </div>

    <div class="board-list">
        <div class="board__search type2">
            <div class="board__select" :class="{ 'active': selectOn }" @click="selectFn">
                <select name="" id="" v-model="searchBoardNo" @change="getList">
                    <option value="">전체</option>
                    <option value="25">건강질병정보</option>
                    <option value="28">검사/처치정보</option>
                    <option value="2">건강TV</option>
                </select>
            </div>
        </div>

        <div class=" board-list__table">
            <table>
                <colgroup>
                    <col style="width: 25%;">
                    <col style="width: auto">
                    <col style="width: 18%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>제목</th>
                        <th>관심설정</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="articleList.length == 0">
                        <td colspan="3">
                            <div class="doctor-list__data-none p20">
                                <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                                <h4 class="depth03__title">검색된 콘텐츠가 없습니다.</h4>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="article in articleList" :key="article">
                        <td class="number"><span class="num">{{nameCheck(article)}}</span><span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span></td>
                        <td class="left tit">
                            <a href="javascript:;" class="board-list__link" @click="articleView(article)">
                                <span class="new" v-if="parseInt((new Date().getTime() - article.createdDt) / 1000 / 60 / 60 / 24) <= 7"></span>
                                <p>{{article.articleTitle}}</p>
                            </a>
                        </td>
                        <FavoriteHeartVue :info="favoInfo(article)"></FavoriteHeartVue>
                    </tr>
                </tbody>
            </table>

            <!-- 우리금융저축은행과 같은 페이징가져왔습니다. -->
            <div class="contents">
                <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="pageCount" @setNum="setNum"></pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination.vue';
import FavoriteHeartVue from '@/components/common/FavoriteHeart.vue';

    export default {
        components: {
            Pagination,
            FavoriteHeartVue
        },
        data() {
            return {
                selectOn: false,

                articleList: [],
                searchBoardNo: '',
            };
        },
        methods: {
            getList(){
                let params = { 'favoTypeCd' : 'BBS' }

                if(this.selectOn){
                    params.boardNo = this.searchBoardNo
                }

                this.axios({
                    method:"get",
                    url: this.api.favorite.list, 
                    params: params
                }).then(res => {
                    const list  = res.data.favoList
                    if(Array.isArray(list)){
                        this.articleList = list
                    } else {
                        this.articleList = []
                    }
                });
            },
            favoInfo(info){
                const obj = {}
                obj.favoYn = 'Y'
                obj.no = info.articleNo
                obj.type = 'BBS'
                obj.mypageYn = 'Y'
                return obj
            },
            nameCheck(article){
                let name = [25,26,27].includes(article.boardNo) ? '건강질병정보' : 
                           [28,29].includes(article.boardNo) ? '검사/처치정보' : '건강TV'
                return name
            },
            articleView(article){
                let url = `/${article.boardId}/view.do?boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                if(article.boardId == 'kdca') url = `/healthinfo/diseaseDetail.do?key=${article.item1}&boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                if(article.boardId == 'healthMental') url = `/healthinfo/mentalDetail.do?key=${article.item1}&boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                if(article.boardId == 'cancer') url = `/healthinfo/cancerDetail.do?key=${article.item1}&boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                if(article.boardId == 'inspec') url = `/healthinfo/inspecDetail.do?key=${article.item1}&boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                if(article.boardId == 'treat') url = `/healthinfo/treatDetail.do?key=${article.item1}&boardNo=${article.boardNo}&articleNo=${article.articleNo}`
                this.$router.push(url)
            },
            selectFn() {
                this.selectOn = !this.selectOn;
            },
            outsideClick(event) {
                if (!event.target.closest('.board__select')) {
                    this.selectOn = false;
                }
            },
        },
        mounted() {
            this.getList()
            document.addEventListener('click', this.outsideClick);
        }
    };
</script>

<style>
    /* 스타일링을 원하는 경우 여기에 작성 */
</style>