<template>
    <div class="greetings contents">
        <div class="greetings_tit">
            <p data-aos="fade-up" data-aos-duration="1000">홈페이지를 방문하신 여러분</p>
            <h4 class="red__title" data-aos="fade-up" data-aos-duration="1500">사랑으로 환영합니다</h4>
        </div>

        <div class="contents">
            <div class="greetings_con_wrap">
                <div class="greeting_img">
                    <img src="@/assets/resource/img/greetings.png" alt="병원장 박정순 수녀" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                </div>
                <div class="greeting_txt contents" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <h5>안녕하십니까?<br>창원파티마병원 병원장<br> 박정순(도밍가) 수녀입니다.</h5>
                    <p class="mt40">
                        창원파티마병원은<br>재단법인 대구 포교 성 베네딕도 수녀회가 1969년 9월 1일 경상남도 마산에서<br>병자와 가난한 이들에 대한 각별한 관심을 가지고 계셨던 故김수환 추기경의 요청에<br>의해 가톨릭교회 전통인 예수그리스도의 치유사도직을 수행하기 위해 설립되었습니다.
                    </p>
                    <p class="mt40">
                        4개 진료과 10개 병상의 작은 겨자씨가<br>지역민의 사랑을 받아 성장하여 오늘에 이르렀습니다.
                    </p>
                    <p class="mt40">저희 병원의 전 구성원은 생명존중의 사랑으로<br>여러분들의 평생 건강 동반자로서 양질의 진료를 제공할 수 있도록 최선을 다할 것입니다.</p>
                    <p class="mt40">또한 병원을 찾는 많은 분들이 저희 병원을 통해<br>좋은 경험의 추억을 가질 수 있도록 작은 것에서부터 세심한 노력을 기울이는<br>고객 중심의 병원 문화를 추구할 것입니다.</p>
                    <p class="mt40">
                        보내주신 신뢰를 바탕으로 앞으로도 치유의 희망과 기쁨을 드리는 건강파트너가 되겠으며<br> 여러분들의 가정에 하느님의 축복과 은총이 가득하시길 기원합니다.
                    </p>
                </div>
                 
                <div class="greeting_name_wrap">
                    <div class="greeting_name" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
                        <p>창원파티마병원장</p>
                        <h6>박정순 도밍가 수녀</h6>
                    </div>
                </div>
                <div class="greeting_back_wrap" data-aos="zoom-in">
                    <div class="greeting_back"></div>
                </div>
                 <p class="greetings_back_tit" data-aos="fade-right" data-aos-duration="1500">GREETING</p>
            </div>
        </div>
    </div>
    
</template>



<script>
    export default {
     
    }
</script>
