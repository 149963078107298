<template>
    <div class="board-gallery">
        <Search :searchArr="searchArr" @setState="setState"></Search>

        <ul class="contents board-gallery__list">
            <li class="board-gallery__item" v-for="(article, idx) in list" :key="idx">
                <a :href="article.linkUrl" :target="article.linkMethod" v-if="article.linkUrl">
                    <img :src="$commonUtils.getFirstImgUrl(article.content)" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>{{ article.title }}</h3>
                        <div class="board-gallery__info">
                            <span class="date">{{ $commonUtils.timestampToDate(article.createdDt) }}</span>
                            <span class="view"><span class="icon"></span>{{ article.readCnt }}</span>
                        </div>
                    </div>
                </a>
                <router-link :to="`/deptHp/${deptCd}/${hpUrl}/${board.boardId}/view.do?boardNo=${board.boardNo}&articleNo=${article.articleNo}`" v-else>
                    <img :src="$commonUtils.getFirstImgUrl(article.content)" alt="이미지">
                    <div class="board-gallery__text">
                        <h3>{{ article.title }}</h3>
                        <div class="board-gallery__info">
                            <span class="date">{{ $commonUtils.timestampToDate(article.createdDt) }}</span>
                            <span class="view"><span class="icon"></span>{{ article.readCnt }}</span>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>

        <pagination :totCnt="totCnt" :pageRow="pageRow" :currentNum="currentNum" :totalPage="totalPage" :pageCount="pageCount" @setNum="setNum"></pagination>
    </div>
</template>

<script>
import Search from '../../common/SearchBox.vue'
import Pagination from '../../common/Pagination.vue'

export default{
    components: {
        Search,
        Pagination
    },
    props: {
        boardInfo: ''
    },
    data() {
        return {
            board: {},
            list: '',
            pageList: '',
            totalPage: '',
            pageCount: window.innerWidth > 720 ? 10 : 5,
            totCnt: 0,

            // 게시글 parameter
            currentNum: this.$route.query.currentNum || 1,
            pageRow: 9,
            //검색 관련 parameter
            title: this.$route.query.title || '',
            content: this.$route.query.content || '',
            searchKeyword: this.$route.query.searchKeyword || '',

            // 검색박스 셀렉트 옵션 리스트
            searchArr: [
                {'title':'전체', 'value':'searchKeyword'}
                , {'title':'제목', 'value':'title'}
                , {'title':'내용', 'value':'content'}
            ],
            deptCd: this.$route.params.deptCd,
            hpUrl: this.$route.params.hpUrl,
        }
    },
    watch: {
        $route(to, from) {
            // 동일 게시판 안에서 이동
            if(to.query != from.query && to.path == from.path){
                if(Object.keys(to.query).length === 0) this.init()
                for(let i in this.$route.query){
                    this[i] = this.$route.query[i]
                }

                this.getList()
            }
        },
        // 다른 게시판으로 이동
        boardInfo(oldVal, newVal){
            this.init()
            this.getList()
        }
    },
    methods: {
        getList(){
            const boardNo = this.boardInfo.boardNo
            const params = {
                instNo: 1,
                boardNo: boardNo,
                startIndex: this.currentNum,
                pageRow: this.pageRow,
                title: this.title,
                content: this.content,
                searchKeyword: this.searchKeyword,
            }
            this.axios.get(this.api.article.list + boardNo, {
                params: params
            })
                .then(res => {
                    this.list = res.data.list
                    this.board = res.data.board
                    this.totCnt = res.data.totCnt
                })
        },
        // 검색 emit
        setState(s, v, p){
            this[p] = ''
            this.currentNum = 1
            this[s] = v
            this.goPage()
        },
        // pagination emit
        setNum(n){
            this.currentNum = n
            this.goPage()
        },
        goPage() {
            const obj = {currentNum: this.currentNum, title: this.title, content: this.content, searchKeyword: this.searchKeyword};
            for (const key in obj) {
                if (obj[key] === null || obj[key] === '') {
                    delete obj[key];
                }
            }

            this.$router.push({ path: this.$route.path, query: obj});
        },
        // 데이터 초기화
        init(){
            this.currentNum = 1
            this.title = ''
            this.content = ''
            this.searchKeyword = ''
        }
    },
    mounted() {
        this.getList()
    },
}
</script>

