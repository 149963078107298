<template>
  <div class="healthNotice">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">기본사항</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>기본적인 사항은 건강검진 일반사항과 동일합니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                원활한 대장 내시경 검사 진행을 위해 건강검진 2~3일 전부터 술,
                육류, 기름진 음식을 금합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">당일준비</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                검진당일 점심식사 후 오후 1시까지 건강증진센터로 내원하시면
                됩니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>필요한 화장품이나 속옷은 지참해 주시기 바랍니다.</p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                2일째에 시행되는 수면 대장, 위내시경 검사로 인해 보호자 동반이
                필요합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">복용약물 안내</h4>
          <div class="depth__wrap">
            <div class="depth__text">
              <p>
                뇌졸중, 심장질환, 폐질환 등의 질환이 있거나, 수술 및 입원치료를
                받으신 분은 복용하시는 약물에 대하여 주치의에게 확인 한 후 검진
                여부를 결정하셔야 합니다.
              </p>
            </div>
          </div>
          <br />
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                혈압약, 심장약, 항경련제를 복용하시는 분은 검진 당일 오전
                6시경에 소량의 물로 복용하시면 됩니다. (단, 항응고제는 복용이
                불가합니다.)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                검진 전날 저녁 또는 당일 아침 인슐린 주사나 당뇨약 복용은
                불가합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                아스피린 등 혈전용해제 성분의 약을 복용하시는 분은 조직검사 시
                출혈의 위험이 있으므로 주치의 상담 후 검사 1주일 전부터 복용을
                중단하셔야 합니다.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                복용하시는 약에 대한 조정이 안 되신 분은 조직검사를 실시하지
                못할 수도 있습니다. (복용중단이 어려운 분은 사전에 검진센터로
                연락 바랍니다.)
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
          <h4 class="depth01__title title">대장내시경 검사준비 안내</h4>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                정확한 검사를 위해서는 장을 깨끗이 비우는 것이 매우 중요합니다.
                장이 깨끗하게 비워지지 않으면 정확한 검사결과를 알 수 없으므로
                다음 안내에 따라 준비를 해주십시오.
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>
                내시경 검사 <u>도중 조직검사 및 용종 절제술</u>이 시행 될 수
                있습니다. (<u>용종 절제술</u>을 시행할 경우는
                <u>추가 비용부담</u>이 필요할 수도 있습니다.)
              </p>
            </div>
          </div>
          <div class="depth__wrap">
            <div class="depth__bullet">
              <span class="bullet03"></span>
            </div>
            <div class="depth__text">
              <p>대장내시경 식이조절</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">
              대장내시경 식이조절
            </caption>
            <colgroup>
              <col style="width: 30%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th>일시</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>대장검사 3일전</td>
                <td>
                  씨 있는 과일(참외,수박,포도,딸기등), 육류, 잡곡, 견과류,
                  해조류(김,미역,다시마 등)는 불가합니다.
                </td>
              </tr>
              <tr>
                <td>검진 첫째날 (아침, 점심, 저녁)</td>
                <td>
                  반찬 없이 쌀로만 만든 흰죽을 드십시오. (깨죽, 호박죽, 야채죽
                  등 찌꺼기가 남을 고형음식은 안됩니다.)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="depth__text">
        <div class="depth__wrap">
          <div class="depth__text">
            <p>상세한 내용은 대장내시경 유의사항을 참조바랍니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>