
<template>
    <div class="board-webzine__back"></div>
    <div class="contents">
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">2024</h4>
            </div>
        </div>
        <ul class="board-webzine__list">
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">3월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                    
                </a>
            </li>
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">2월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">3월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <div class="contents">
        <div class="info-text depth__wrap">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">2023</h4>
            </div>
        </div>
        <ul class="board-webzine__list">
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">3월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">2월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="board-webzine__item">
                <a href="javascript:;">
                    <div class="board-webzine__img">
                        <img src="/resource/img/board-webzine.png" alt="이미지">
                        <div class="board-webzine__text">
                            <div class="board-webzine__title">
                                <h3 class="depth02__title">3월호</h3>
                            </div>
                            <span class="board-webzine__plus"></span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>


export default {
  data() {
    return {

    };
  },
  methods: {


  },
  mounted() {
  }
};
</script>