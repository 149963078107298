<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>

    <div class="myHealth_centerReserve">
        <div class="contents">
        <h3 class="myHealth_top">
            3일 이상 확인 지연시(상태가 ‘N’) 건강증진센터(270-1200)로 문의주시기
            바랍니다.
        </h3>
        </div>
        <div class="contents">
            <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
                <div class="scroll">
                    <table>
                        <caption class="blind">표</caption>
                        <colgroup>
                        <col style="width: 10%" />
                        <col style="width: 15%" />
                        <col style="width: 15%" />
                        <col style="width: 35%" />
                        <col style="width: 5%" />
                        <col style="width: 20%" />
                        </colgroup>
                        <thead>
                        <tr>
                            <th>번호</th>
                            <th>접수일</th>
                            <th>희망예약일</th>
                            <th>검사항목</th>
                            <th>상태</th>
                            <th>-</th>
                        </tr>
                        </thead>
                        <tbody>
                            <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->
                            <tr v-if="list.length == 0">
                                <td colspan="6">내용이 없습니다.</td>
                            </tr>
                            <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->
                            <tr v-for="(i, idx) in list" :key="i">
                                <td>{{ idx + 1 }}</td>
                                <td>{{$commonUtils.makeDateFormat(i.insertdd, '.')}}</td>
                                <td>{{$commonUtils.makeDateFormat(i.reser_date, '.')}}</td>
                                <!-- <td>{{setCoure(i.pkgcd)}}</td> -->
                                <td>{{i.pkgnm}}</td>
                                <td>{{ i.end_yn }}</td>
                                <td>
                                    <div class="btn-wrap center">
                                    <a href="javascript:;" class="btn" @click="cancelReservation(i)">취소</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'
import {course} from './course_option'
export default {
    components: {
        NameCertifyPopUp
    },
    data() {
        return {
            list: [],
            isActive: false,
            popupVisible: false,
        }
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        setCoure(cd){
            let name = ''
            for(let i in course){
                if(course[i][this.$store.state.myInfoStore.memGender] && course[i][this.$store.state.myInfoStore.memGender]['CODE'] == cd){
                    name = i
                }
            }
            return name
        },
        getList(){
            const param = {
                healexamflag: 'C'
            }
            this.axios.get(this.api.appointment.selectIntApp, {
            params: param
            }).then(res => {
                if(res.data.length > 0 && res.data[0].errortype == 'currentMember_null') {
                    alert('로그인을 해주시길 바랍니다.')
                    const path = this.$route.path
                    window.location.href = path.replace('reservationInfo', 'main')
                }
                this.list = res.data.length == 0 || res.data[0].errormsg ? [] : res.data
            })
        },
        cancelReservation(item){
            if(item.end_yn == 'Y'){
                alert('예약을 취소할 수 없습니다.')
                return
            }
            if(confirm('예약을 취소하시겠습니까?')){
                const params = new URLSearchParams();
                params.append('status', 'd');
                params.append('reser_date', item.reser_date);
                params.append('schlgrde', item.pkgcd);
                // const params = {
                //     status: 'd',
                //     reser_date: item.reser_date,
                //     schlgrde: item.pkgcd
                // }
                this.axios
                .post(this.api.appointment.internetAppCancel, params)
                .then(res => {
                    alert('예약이 취소되었습니다.')
                    this.getList()
                })
            }
        },
        addClass() {
            if (!this.isActive) {
                this.isActive = true;
            }
        },
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.getList()
    },
    computed: {
        
    }
};
</script>

  