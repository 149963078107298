<template>
  <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
  <div class="myHealth_centerReserve">
    <div class="contents">
      <div class="info-text depth__wrap depth01">
            <div class="depth__text">
                <div class="depth__wrap">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>담당자가 신청하신 검사항목, 희망검사일을 확인하여 <b>전화 연락을 통해 검사 일정을 확정</b>하고 있습니다.</p>
                    </div>
                </div>
                <div class="depth__wrap">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>
                           상태가 <b>3일 이상 ‘N’</b>으로 되어 있거나, <b>검사 일정 확정 후 취소</b>를 원할 경우 <b>직업환경의학센터(270-1051)로 연락</b> 주시기 바랍니다.
                        </p>
                    </div>
                </div>
            </div>
        </div>
      <!-- <h3 class="myHealth_top">
        3일 이상 확인 지연시(상태가 ‘N’) 직업환경의학센터(270-1051~3)로 문의주시기 바랍니다.
      </h3> -->
    </div>
    <div class="contents">
      <div class="table-wrap table-type1 scroll" :class="{ 'on': isActive }" @click="addClass">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 5%" />
              <col style="width: 17%" />
              <!-- <col style="width: 15%" /> -->
              <col style="width: 43%" />
              <col style="width: 5%" />
              <col style="width: 15%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>접수일</th>
                <!-- <th>희망검진일</th> -->
                <th class="present_line">검사항목(확정일시)</th>
                <th>상태</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->
              <tr v-if="list.length == 0">
                <td colspan="5">내용이 없습니다.</td>
              </tr>
              <!-- 예약 정보가 없을 경우 해당 테이블 내용 사용 -->
              <tr v-for="(i, idx) in list" :key="idx">
                <td>{{ idx + 1 }}</td>
                <td>{{$commonUtils.makeDateFormat(i.insertdd, '.')}}</td>
                <!-- <td>{{$commonUtils.makeDateFormat(i.reser_date, '.')}}</td> -->
                <!-- <td class="present_line">{{i.end_yn == 'Y' ? `${i.item}(${$commonUtils.makeDateFormat(i.reser_date, '.')})` : i.item}}</td> -->
                <td class="present_line">{{i.item}}</td>
                <td>{{ i.end_yn }}</td>
                <td>
                    <div class="btn-wrap center" v-if="i.end_yn == 'N'">
                      <a href="javascript:;" class="btn" @click="cancelReservation(i)">취소</a>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'
export default {
    components: {
        NameCertifyPopUp,
    },
    data() {
        return {
          list: [],
          isActive: false,
          popupVisible: false,
        }
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        getList(){
            const param = {
                healexamflag: 'G'
            }
            this.axios.get(this.api.appointment.selectIntApp, {
            params: param
            }).then(res => {
                if(res.data.length > 0 && res.data[0].errortype == 'currentMember_null') {
                    alert('로그인을 해주시길 바랍니다.')
                    const path = this.$route.path
                    window.location.href = path.replace('selectCancerReserve', 'main')
                }
                this.list = res.data.length == 0 || res.data[0].errormsg ? [] : res.data
                for(let i of this.list){
                    i.item = i.remcnts.split('/')[0].replace('예약항목: ', '').trim()
                }
            })
        },
        cancelReservation(item){
            if(item.end_yn == 'Y'){
                alert('예약을 취소할 수 없습니다.')
                return
            }
            if(confirm('예약을 취소하시겠습니까?')){
                const params = new URLSearchParams();
                params.append('status', 'd');
                params.append('reser_date', item.reser_date);
                // params.append('schlgrde', item.pkgcd);
                // const params = {
                //     status: 'd',
                //     reser_date: item.reser_date,
                //     schlgrde: item.pkgcd
                // }
                this.axios
                .post(this.api.appointment.internetAppCancel, params)
                .then(res => {
                    alert('예약이 취소되었습니다.')
                    this.getList()
                })
            }
        },
        addClass() {
          if (!this.isActive) {
              this.isActive = true;
          }
    },
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.getList()
    },
};
</script>

