<template>
    <!-- 소화기내시경센터 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소화기내시경센터는 소화기질환의 효과적인 치료를 위해 소화기내과 전문의와 전담 간호 인력이 축적된 진료 및 시술 경험을 바탕으로 내시경을 이용한 각종 소화기 질환의 검사와 진단 그리고 치료를 실시하고 있습니다. 정확한 진단과 시술을 위해 5명의 소화기내과 전문의가 조직적으로 협력하여 진료 및 검사를 하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">내시경에 최적화된 검사실과 전문 의료진</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>내시경 검사 및 시술을 활발히 시행하고 있는 창원파티마병원 소화기내시경센터는 내시경 관련 대부분의 시술이 가능하며, 높은 수준의 시술 성공능력을 보유하고 있습니다.</p>
                                <br>
                                <p>위, 대장의 선종이나 조기 암 등을 내시경으로 관찰하여 내시경 시술(점막절제술 및 점막 박리술)로 절제하고 있으며, 담석, 담관석, 담관암, 췌장질환에 대해 역행성 담관촬영술로 담관석을 제거하거나, 담관폐쇄(담관암), 췌장염 등에 대해서는 담관·췌관 스텐트 시술, 배액술 등을 실시하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">주요검사</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>위·대장·직장경 내시경</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>용종절제술</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>역행성 담도췌관 조영술</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>초음파 내시경</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>각종 치료내시경</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>요소호기검사</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">환자 프라이버시와 효율적인 동선을 위한 공간 설계</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>소화기내시경센터는 사생활 보호를 위해 4개의 독립된 검사실에서 검사를 진행하고 있으며, 청결구역과 오염구역의 구분을 위해 환자용과 직원용 복도가 분리 되어 있습니다. 또한 외래진료실과 검사실이 같은 공간에 배치되어 있어 고객의 동선을 최소화 하고 보다 신속한 시술을 제공하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">고객을 위한 편의 시설 및 공간</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>조용한 공간에서 검사에 대한 설명과 주의사항을 전문 상담실에서 안내하고 있으며 검사 전 후 쾌적한 환경에서 휴식을 취할 수 있도록 수면실, 환자대기실, 휴게실 등을 갖추고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-box-wrap">
    <img src="/resource/img/digestive01.jpg" alt="이미지">
    <img src="/resource/img/digestive02.JPG" alt="이미지">
    <img src="/resource/img/digestive03.jpg" alt="이미지">
    <img src="/resource/img/digestive04.jpg" alt="이미지">
</div>
</template>


<script>
export default {
   
};
</script>