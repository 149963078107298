<template>
  <div class="jobHealthCenter contents">
    <div class="info-text depth__wrap">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">직업환경의학센터</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table>
          <caption class="blind">표</caption>
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 45%" />
            <col style="width: 20%" />
          </colgroup>
          <thead>
            <tr>
              <th>직위</th>
              <th>이름</th>
              <th>담당업무</th>
              <th>전화번호</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>팀장</td>
              <td>최규상</td>
              <td>업무총괄</td>
              <td>055-270-1050</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="info-text depth__wrap contents">
      <div class="depth__bullet"><span class="bullet01"></span></div>
      <div class="depth__text">
        <h4 class="depth01__title title">건강검진</h4>
      </div>
    </div>
    <div class="table-wrap table-type1">
      <div class="scroll">
        <table>
          <caption class="blind">표</caption>
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 45%" />
            <col style="width: 20%" />
          </colgroup>
          <thead>
            <tr>
              <th>직위</th>
              <th>이름</th>
              <th>담당업무</th>
              <th>전화번호</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UM</td>
              <td>오세진</td>
              <td>사전조사 및 출장검진</td>
              <td>055-270-1053</td>
            </tr>
            <tr>
              <td>AM</td>
              <td>하주희</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1052</td>
            </tr>
            <tr>
              <td>AM</td>
              <td>정경화</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1052</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>박경석</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1051</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>박혜원</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1051</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>허세정</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1052</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>최나윤</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1053</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>신지은</td>
              <td>일반, 특수건강검진</td>
              <td>055-270-1052</td>
            </tr>
            <tr>
              <td>AM</td>
              <td>권은미</td>
              <td>채용검진</td>
              <td>055-270-1052</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>최윤영</td>
              <td>국가암검진, 일반건강검진</td>
              <td>055-270-1051</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>김소영</td>
              <td>국가암검진, 일반건강검진</td>
              <td>055-270-1053</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>조숙인</td>
              <td>방사선촬영</td>
              <td>055-270-1068</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>박재현</td>
              <td>방사선촬영</td>
              <td>055-270-1068</td>
            </tr>
            <tr>
              <td>매니저</td>
              <td>박현수</td>
              <td>특검분석</td>
              <td>055-270-1056</td>
            </tr>
            <tr>
              <td>AM</td>
              <td>조명제</td>
              <td>사무행정</td>
              <td>055-270-1051</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>임소은</td>
              <td>사무행정</td>
              <td>055-270-1800</td>
            </tr>
            <tr>
              <td>직원</td>
              <td>조가현</td>
              <td>사무행정</td>
              <td>055-270-1800</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

   
</template>
<script>
export default {};
</script>