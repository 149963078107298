<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="tab-menu__wrap">
        <div class="tab-menu type02">
            <ul class="tab-menu__list">
                <li class="tab-menu__item active">
                    <button type="button" class="tab-menu__btn">외래 예약</button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn"
                            @click="goPage('/deptHp/1/healthcenter/reservationInfo.do')">
                        종합건강검진예약
                    </button>
                </li>
                <li class="tab-menu__item">
                    <button type="button" class="tab-menu__btn"
                            @click="goPage('https://www.fatimahosp.co.kr/deptHp/2/onecenter/selectCancerReserve.do')">
                        국가암검진예약
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="contents">
        <div class="booking-check-wrap">
            <div class="booking-check">
                <div class="booking-check__line-wrap">
                    <span class="booking-check__line"></span>
                </div>
                <div>
                    <ul class="booking-check__list">
                        <!-- 퍼블리싱필요 -->
                        <li class="booking-check__item doctor-list__data-none" v-if="reservationList.length == 0">
                            <img src="@/assets/resource/icon/doctor-list__data-none.png" alt="이미지">
                            <h4 class="depth03__title">예약된 진료일정이 없습니다.</h4>
                        </li>
                        <li class="booking-check__item" v-for="item in reservationList" :key="item">
                            <div class="booking-check__item--con">
                                <div class="doctor-list__item">
                                    <div class="doctor-list--img">
                                        <img :src="`/displayFile.do?drNo=${item.docInfo.drNo}`" alt="이미지">
                                    </div>
                                    <div class="doctor-list--con">
                                        <div class="doctor-list--text">
                                            <h3>{{ item.docInfo && item.docInfo.hptlJobTitle ? item.docInfo.hptlJobTitle : item.orddrnm }}<sub>{{ item.orddeptnm }}</sub></h3>
                                            <h4>주진료분야</h4>
                                            <p>{{ item.docInfo.doctorDept.special }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="booking-check__item--mark"></span>
                            <div class="booking-check__item--day">
                                <h4 class="booking-check__item--day-text">
                                    {{ setInfoDate(item) }}
                                </h4>
                                <div class="btn-wrap">
                                    <a href="javascript:;" class="btn" @click="changeReservation(item)">예약변경</a
                                    ><a href="javascript:;" class="btn" @click="reservationCancel(item)">예약취소</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import gsap from "gsap";
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    components: {
        NameCertifyPopUp
    },
    data() {
        return {
            reservationList: [],
            popupVisible: false,
        }
    },
    methods: {
        goPage(url) {
            window.location.href = url
        },
        openInNewTab(url) {
            // 새 창에서 페이지 열기
            window.open(url, '_blank')
        },
        showPopup() {
            this.popupVisible = true
        },
        hidePopup() {
            this.popupVisible = false
        },
        fetchReservations() {
            this.axios
                .get(this.api.mypage.reservationList, {
                    params: {
                        fromdd: this.$commonUtils.getToday(),
                        todd: this.$commonUtils.addDate("y", 1, this.$commonUtils.getToday()),
                    },
                })
                .then((res) => {
                    this.reservationList = res.data.list.sort(function (a, b) {
                        return a.orddd - b.orddd
                    })

                    this.reservationList.forEach(item => {
                        res.data.doclist.forEach(doc => {
                            if (item.orddrid === doc.empId) {
                                item.docInfo = doc
                                return false
                            }
                        })
                    })
                })
                .catch((err) => {
                    alert('예약 조회 중 오류가 발생했습니다.')
                })
        },
        setInfoDate(item) {
            const days = ['일', '월', '화', '수', '목', '금', '토']
            const year = item.orddd.slice(0, 4)
            const month = parseInt(item.orddd.slice(4, 6), 10) - 1
            const day = item.orddd.slice(6, 8)
            const dayOfWeek = new Date(year, month, day).getDay()
            const date = this.$commonUtils.makeDateFormat(item.orddd, '.')
            const week = `(${days[dayOfWeek]})`

            let time = item.ordtm.slice(0, 2) + ':' + item.ordtm.slice(2, 4)

            if(item.ampmflagnm !== ''){
                time = item.ampmflagnm
            }

            return `${date} ${week} ${time}`
        },
        changeReservation(item) {
            if (confirm('예약 변경 시 기존 예약은 취소됩니다. 진행하시겠습니까?')) {
                this.$store.commit('setChange', item)
                this.$router.push({path: '/reserve/reservation.do'})
            }
        },
        reservationCancel(item) {
            if (confirm('예약을 취소하시겠습니까?')) {
                const param = {
                    sessuserid: '90000300',
                    flag: 'D', // 접수: I, 취소: D
                    // pid : this.myInfo.memPatId,
                    orddd: item.orddd,
                    ordtm: item.ordtm,
                    orddeptcd: item.orddeptcd,
                    orddrid: item.orddrid,
                    cretno: item.cretno, // 일련번호
                    // insukind : '',
                    // suppkind : '',
                    // rsrvflag : '6',
                    // etcordflag : 'R6'
                }

                this.axios({
                    method: 'post',
                    url: this.api.appointment.reservation,
                    params: param
                }).then(res => {
                    if (res.data.errormsg) {
                        alert(res.data.errormsg)
                        if (res.data.errortype == 'currentMember_null') location.reload(true)
                        return
                    }
                    if (res.data.code) {
                        alert(res.data.code)
                        return
                    }
                    alert('예약이 취소되었습니다.')
                    this.fetchReservations()
                }).catch(err => {
                    alert('오류가 발생했습니다.')
                    // location.reload(true);
                })
            }
        },
    },
    mounted() {
        const myInfo = this.$store.state.myInfoStore
        this.popupVisible = myInfo.memPatId == '' || myInfo.memPatId == null ? true : false
        this.fetchReservations()
        window.addEventListener("scroll", function () {
            var items = document.querySelectorAll(".booking-check__item")
            var lineWrap = document.querySelector(".booking-check__line-wrap")
            var line = document.querySelector(".booking-check__line")

            items.forEach(function (item) {
                var rect = item.getBoundingClientRect()
                var screenCenter = window.innerHeight / 1.3
                var itemCenter = rect.top + rect.height / 1.3
                var viewportBottom = window.scrollY + window.innerHeight
                var isBelowViewport = rect.bottom > viewportBottom

                if (Math.abs(itemCenter - screenCenter) < rect.height) {
                    item.classList.add("on")
                } else {
                    if (isBelowViewport) {
                        item.classList.remove("on")
                    }
                }
            })

            // .booking-check__line의 높이를 스크롤 위치에 따라 동적으로 조정
            if (lineWrap && line) {
                // 뷰포트의 전체 높이
                const viewportHeight = window.innerHeight
                // 문서의 전체 높이
                const documentHeight = document.documentElement.scrollHeight
                // 스크롤 위치
                const scrollTop = window.scrollY
                // .booking-check__line-wrap의 높이
                const wrapHeight = lineWrap.offsetHeight
                // 비율 (1.5배)
                const ratio = 1.2

                // 스크롤 비율 계산
                const scrollRatio = Math.min(scrollTop / (documentHeight - viewportHeight), 1)

                // .booking-check__line의 높이 조정
                line.style.height = `${wrapHeight * ratio * scrollRatio}px`
            }
        })

        // 페이지 로드 시 초기 설정
        document.addEventListener('DOMContentLoaded', function () {
            var lineWrap = document.querySelector(".booking-check__line-wrap")
            var line = document.querySelector(".booking-check__line")

            if (lineWrap && line) {
                line.style.height = `0px`
            }
        })

    },
}
</script>

<style>
@import "aos/dist/aos.css";
/* 스타일링을 원하는 경우 여기에 작성 */
</style>