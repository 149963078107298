<template>
    <div class="box-form">
        <div class="box-form__inner">
            <div class="icon">
                <img src="@/assets/resource/icon/mail-icon.png" alt="이미지">
            </div>
            <div class="text">
                <p>창원파티마병원은 우리 웹사이트 회원의 이메일 주소가 무단으로 수집되는 모든 프로그램이나 기술적 장치를 거부합니다. <br>
                    이를 위반 시 정보통신망법에 의해 형사 처벌됨을 유념하시길 바랍니다.</p>
                <em>[게시일 2007년 1월 3일]</em>
            </div>
        </div>
    </div>
</template>


<script>

</script>