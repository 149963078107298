<template>
  <div class="environmentWrap contents">
    <div class="contents funeral-step__top type02">
      <div class="info-text">
          <p class="contents">
              어린이를 포함한 일반인 중에 유해인자에 노출되는 사업장에 한 번도 근무한 적이 없지만<br> 석면, 수은 및 카드뮴 등의 중금속, 많은 유해 화학물질 등에 의해 비교적 가벼운 알러지성질환에서<br> 생명을 위협하는 폐암까지 여러 질환의 발생이 늘어나고 있습니다.
          </p>
          <p class="contents">
              이러한 노출은 주거지 주위에 유해인자를 취급하는 공장 및 폐금속광산 등에 의한 환경오염,<br> 유해인자가 포함된 생산품, 유해인자에 오염된 음식 등에 의해 일어나고,<br> 이로 인해 질병이 확인되지는 않지만 눈, 코, 목 등의 점막자극, 기억력 저하 및 학습능력 저하 등의<br> 중추 신경계 이상 증상, 재채기 및 기침, 피부습진 등의 증상을 호소하는 경우도 늘어나고 있습니다.
          </p>
      </div>
      <div class="funeral-step__top--right">
          <img src="/resource/img/environment01.jpg" alt="환경성 질환">
      </div>
  </div>

    <div class="contents mt80">
      <p class="gray__title">이러한 질병이나 증상이 의심되는 경우 관련 질환 및 증상에 대한 상담, 관련된 유해인자가 체내에 얼마나 노출되었는지를 혈액이나 소변으로 확인하는 생물학적 노출지표검사, 대부분의 유해물질이 영향을 미치는 곳으로 알려진 중추신경계에 손상이 있는지를 판단하는 한국형 컴퓨터 신경 행동검사(KCN : Korean Computerized Neurobehavioral Test) 등을 시행하여 환경성 질환을 진단하고 관리합니다.</p>
    </div>
    
  
    <div class="contents">
        <h4 class="environment_call">진료예약 : 055)270-1800</h4>
    </div>
  </div>
</template>



<script>
    export default {
       
    }
</script>