<template>
    <!-- 간호간병통합서비스 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>간호·간병통합서비스는 간병인이나 보호자 대신 24시간 간호사, 간호조무사, 지원사 등 전문 간호인력이 중심이 되어 입원환자를 케어하는 서비스입니다. 창원파티마병원 간호·간병통합서비스 병동에서는 환자의 활동능력 범위 내에서 보다 편리하게 입원치료가 이루어질 수 있도록 돕고, 일상 활동 및 거동이 어려운 환자에게 식사 보조, 체위 변경, 기본 위생 등을 제공하여 입원생활의 질을 높이고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-text center_box">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">간호·간병통합서비스 장점</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>건강보험 적용으로 간병비로 인한 경제적 부담이 감소합니다.</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>24시간 간호전문인력의 체계적인 관리를 받을 수 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>환자중심의 간호서비스와 쾌적하고 안전한 병실환경을 제공합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">운영병상</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="color:#e8508d;">5개 병동 220병상</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">운영인력</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>간호사 1:10</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>간호조무사 1:25</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>지원사 10명</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">입실기준</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>병동 이용에 동의하고 담당 주치의 소견 상 신체·정신적 측면의 제반 사항이 적합하다고 판단하는 환자</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">입원절차</h4>
                       
                    </div>
                </div>
                 <ul class="step-box appointStep">
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">진료</h4>
                                    <p class="">외래 또는 응급실</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">입원결정</h4>
                                    <p>담당주치의가 입원 적합성 판단 및 처방</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">입원수속</h4>
                                    <p>간호·간병통합서비스<br>병동 설명 및 입원 동의서 작성</p>
                                </div>
                            </li>
                            <li class="step-box__item">
                                <div class="step-box__text">
                                    <h4 class="depth02__title">병동입원</h4>
                                    <p>간호 상담<br>간호수행 계획 수립 및 간호 제공</p>
                                </div>
                            </li>
                        </ul>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p style="color: #888;">※ 환자의 요청 또는 진료과의 적합성 판단에 따라 입원 후에도 일반병동 ↔ 간호·간병통합서비스 병동 간 전동 가능</p>
                            </div>
                        </div>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">주의사항</h4>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>환자 입원기간 중에는 보호자의 비상 연락이 유지될 수 있어야 합니다.</p>
                            </div>
                        </div>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>환자 상태에 따라 의료진이 ‘보호자 상주 확인서’를 작성한 경우 보호자 1인 일시 상주가 가능합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>사적 고용 간병인은 상주 불가능합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="img-box-wrap">
    <img src="/resource/img/care-img01.JPG" alt="이미지">
    <img src="/resource/img/care-img02.JPG" alt="이미지">
    <img src="/resource/img/care-img03.JPG" alt="이미지">
    <img src="/resource/img/care-img04.JPG" alt="이미지">
</div>
    </div>
     <div class="btn-wrap center contents">
        <a href="javascript:;" class="btn blue">센터/클리닉 전체</a>
    </div>
</template>


<script>
    export default {

    };
</script>