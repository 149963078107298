<template>
  <div class="programInfo">
    <div class="contents active">

      <div ref="programInfo" class="program_add_wrap">

        <div class="info-text depth__wrap depth01">
          <div class="depth__bullet"><span class="bullet01"></span></div>
          <div class="depth__text">
            <h4 class="depth01__title title">조기암(PET-CT) 검진</h4>
            <!-- depth02 -->
            <div class="depth__wrap depth02">
              <div class="depth__text">
                <!-- depth03 -->
                <div class="depth__wrap depth03">
                  <div class="depth__text">
                    <p>
                      건강증진코스(Green course)에 PET-CT를 시행하여 조기에 암을 발견하기 위한 암전문 검진 프로그램입니다.
                    </p>
                    <div class="depth__wrap mt20">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                           검사 소요시간 : 약 3시간이상 / 월요일~금요일
                        </p>
                      </div>
                    </div>
                     <div class="depth__wrap">
                      <div class="depth__bullet">
                        <span class="bullet03"></span>
                      </div>
                      <div class="depth__text">
                        <p>
                         검사비용 : 남 1,350,000원 / 여 1,400,000원
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div class="table-wrap table-type1">
        <div class="scroll">
          <table>
            <caption class="blind">표</caption>
            <colgroup>
              <col style="width: 13%" />
              <col style="width: 17%" />
              <col style="width: 70%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">구분</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="11" style="text-align: center">공통</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">문진</td>
                <td>건강상담</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">신체계측</td>
                <td>신체, 체중, 허리둘레, 비만도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  청력, 안과, 치과
                </td>
                <td>청력, 시력, 안압, 안저, 구강검사</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">혈액검사</td>
                <td>
                  일반혈액검사, 혈액형, 간기능, 신장기능, 갑상선기능, 지질검사, 당뇨, 통풍, 췌장기능, 전해질검사, 간염검사(B, C형간염), 매독, AIDS, 류마티스인자, Vit.D, 염증검사, 정밀빈혈검사, 종양표지자(간암, 소화기암, 췌장암, 전립선암(♂), 난소암(♀))
                </td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">
                  대, 소변 검사
                </td>
                <td>대변(잠혈, 기생충), 소변</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">호흡기검사</td>
                <td>폐활량 측정, 흉부 X-ray (정면, 측면)</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">심장검사</td>
                <td>혈압검사, 심전도</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">복부검사</td>
                <td>복부 초음파</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">위장검사</td>
                <td>위내시경 또는 위장조영촬영 택1</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">PET검사</td>
                <td>PET-CT</td>
              </tr>
              <tr>
                <td rowspan="2" style="text-align: center;">여성</td>
                <td>유방검사</td>
                <td>유방X-ray</td>
              </tr>
              <tr>
                <td style="border-left: 1px solid #d9d9d9">부인과검사</td>
                <td>자궁초음파, 자궁세포진검사, 인유두종바이러스(HPV-DNA)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
    <div class="btn-wrap center"><a href="javascript:;" class="btn">예약하기</a></div>
  </div>
</template>

<script>
export default {

};
</script>
