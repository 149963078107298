<template>
    <NameCertifyPopUp ref="NameCertifyPopUp" :popupVisible="popupVisible" @hide-popup="hidePopup"></NameCertifyPopUp>
    <div class="my_health_wrap my_result_wrap contents">
        <div class="contents health_info_wrap">
            <div class="tab-menu__wrap">
                <div class="tab-menu type02">
                     <ul class="tab-menu__list center">
                        <template v-for="(menu, idx) in menuList" :key="idx">
                            <li class="tab-menu__item" :class="$route.path === menu.menuUrl ? 'active' : ''" v-if="menu.parentMenuNo === '580'">
                                <a :href="menu.menuUrl" :target="menu.linkMethod" v-if="menu.menuUrl.indexOf('http') > -1">
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </a>
                                <router-link :to="menu.menuUrl" v-else>
                                    <button type="button" class="tab-menu__btn">{{ menu.menuName }}</button>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="contents result_box02" >
                <div class="text-box type1 info-text">
                    <ul class="text-box__list">
                        <li class="text-box__item">입원환자는 퇴원 시 처방된 약만 조회됩니다.</li>
                    </ul>
                </div>
                <div class="date_wrap contents">
                    <div class="select_wrap_box">
                        <div class="board__select">
                            <select name="" id="Sel_Inst" class="select_department" @change="changeDept" ref="dept">
                                <option value="">전체</option>
                                <option :value="i" v-for="i in deptList" :key="i">{{i}}</option>
                            </select>
                        </div>
                        <div class="board__select">
                            <select name="" id="Sel_Inst02" class="select_department" @change="changeDept" ref="io">
                                <option value="">구분 전체</option>
                                <option value="외래">외래</option>
                                <option value="입원">입원</option>
                            </select>
                        </div>
                    </div>

                    <div class="date-input">
                        <VueDatePicker v-model="selectedDateRange" locale="ko"
                                       :enable-time-picker="false" :format="customFormat"
                                       :week-start="0" :day-class="getDayClass"
                                       :select-text="'선택'" :cancel-text="'취소'"
                                       :range="true" :multi-calendars="true"/>
                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                    </div>

                    <div class="tab-menu type_date">
                        <ul class="tab-menu__list">
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 3}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(3)">3개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 6}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(6)">6개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 12}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(12)">12개월</button>
                            </li>
                            <li :class="{'tab-menu__item': true, 'active': selectedPeriod === 24}">
                                <button type="button" class="tab-menu__btn" @click="setSelectedPeriod(24)">24개월</button>
                            </li>
                        </ul>
                    </div>

                    <div class="health_info_search">
                        <button @click="getDrugList">검색<img src="@/assets/resource/icon/search-white.png" alt="이미지"></button>
                    </div>
                </div>

                <div class="contents">
                    <div class="table-wrap table-type1 scroll table_hover" @click="addClass">
                        <div class="scroll">
                            <table>
                                <caption class="blind">결과조회-외래진료 폼</caption>
                                <colgroup>
                                    <col style="width:14%;">
                                    <col style="width:8%;">
                                    <col style="width:32%;">
                                    <col style="width:32%;">
                                    <col style="width:14%;">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>진료일자</th>
                                    <th>구분</th>
                                    <th>진료과</th>
                                    <th>진료의</th>
                                    <th>결과</th>
                                </tr>
                                </thead>
                                <tbody class="no-data-table" v-if="$commonUtils.isEmpty(drugList)">
                                    <tr>
                                        <td colspan="5">검색된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(drug, idx) in drugList" :key="idx">
                                        <td>{{ $commonUtils.makeDateFormat(drug.prcpdd,'.') }}</td>
                                        <td>{{ drug.ioflag }}</td>
                                        <td>{{ drug.deptnm }}</td>
                                        <td>{{ drug.dronenm }}</td>
                                        <td class="result_btn">
                                            <div class="btn-wrap center">
                                                <router-link :to="`/mypage/result/drugDetail.do?prcpdd=${drug.prcpdd}&orddeptcd=${drug.orddeptcd}&orddrid=${drug.orddrid}&deptnm=${drug.deptnm}`" class="btn">상세보기</router-link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import NameCertifyPopUp from '@/components/reserve/NameCertifyPopUp.vue'

export default {
    data() {
        return {
            searchParam: {},
            selectedDateRange: [new Date(), new Date()],
            selectedPeriod: 3,
            customFormat: 'yyyy.MM.dd',
            drugList: [],
            popupVisible: false,

            deptList: [],
            orgList: [],
        };
    },
    components: {
        VueDatePicker,
        NameCertifyPopUp
    },
    mounted() {
        this.popupVisible = this.$store.state.myInfoStore.memPatId == '' || this.$store.state.myInfoStore.memPatId == null ? true : false
        this.setSelectedPeriod(3)
        this.getDrugList()
    },
    methods: {
        showPopup() {
            this.popupVisible = true;
        },
        hidePopup() {
            this.popupVisible = false;
        },
        setSelectedPeriod(months) {
            const startDate = new Date();
            const endDate = new Date();
            startDate.setMonth(endDate.getMonth() - months);
            this.selectedDateRange = [startDate, endDate];
            this.selectedPeriod = months;
        },
        getDayClass(date) {
            const day = date.getDay();
            if (day === 0) {
                return 'sunday-class';
            } else if (day === 6) {
                return 'saturday-class';
            }
            return '';
        },
        addClass(event) {
            event.currentTarget.classList.add('on');
        },
        getDrugList() {
            this.searchParam.fromdd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[0],'yyyyMMdd','');
            this.searchParam.todd = this.$commonUtils.timestampToDateFormate(this.selectedDateRange[1],'yyyyMMdd','');

            this.axios.get(this.api.mypage.drugList, {
                params: this.searchParam
            }).then(res => {
                const drugList = res.data.drugList.receivedruglist

                if(Array.isArray(drugList)){
                    this.drugList = drugList
                }else{
                    this.drugList = [drugList]
                }
                
                this.orgList = this.drugList

                this.deptList = []
                this.$refs.dept.value = ''
                this.$refs.io.value = ''
                for(let i of this.drugList){
                    this.deptList.push(i.deptnm)
                }
                this.deptList = this.$commonUtils.dupArr(this.deptList)
            })
        },
        changeDept(){
            const dept = this.$refs.dept.value
            const io = this.$refs.io.value
            this.drugList = this.orgList.filter(item => {
                let matchDept = true;
                let matchIo = true;

                if (dept) {
                    matchDept = item.deptnm === dept;
                }

                if (io) {
                    matchIo = item.ioflag === io;
                }

                return matchDept && matchIo;
            });
        }
    }
};

</script>
