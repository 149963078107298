<template>
    <p class="contents sub-text">진료예약 접수가 <span class="c-blue">정상적으로 완료되었습니다.</span></p>

    <div class="contents">
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">환자번호</h4>
                </div>
            </div>
            <div class="table-cont">
                <ul class="table-list type02">
                    <li class="table-item">
                        <div class="item tit">이름</div>
                        <div class="item">이준용</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">환자번호</div>
                        <div class="item">1234567</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">휴대전화</div>
                        <div class="item">010-1234-5678</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">이메일</div>
                        <div class="item">abcde1234@naver.com</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="info-text">
            <div class="depth__wrap">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">예약정보</h4>
                </div>
            </div>
            <div class="table-cont">
                <ul class="table-list type02">
                    <li class="table-item">
                        <div class="item tit">진료과</div>
                        <div class="item">소화기내과</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">예약일</div>
                        <div class="item">2024.08.03(수)</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">의료진</div>
                        <div class="item">전수진</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">예약시간</div>
                        <div class="item">09:35</div>
                    </li>
                </ul>
            </div>
            <div class="depth__wrap info-reference">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title"> 예약일시를 꼭 확인 후 내원해 주시기 바랍니다.</h4>
                </div>
            </div>
            <div class="depth__wrap">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title">예약 진료과로 바로 가셔서 성함을 말씀하시고 직원의 안내를 빋으십시오.</h4>
                </div>
            </div>
            <div class="depth__wrap">
                <div class="depth__bullet">※</div>
                <div class="depth__text">
                    <h4 class="depth03__title">본인확인 의무화제도 시행으로 진료 전 반드시 신분증을 챙겨서 방문해주십시오.</h4>
                </div>
            </div>
        </div>
        <div class="info-text">
            <div class="btn-wrap center">
                <a href="javascript:;" class="btn blue">예약 현황</a>
                <a href="javascript:;" class="btn">예약 변경</a>
                <a href="javascript:;" class="btn">예약 취소</a>
            </div>
        </div>
    </div>
</template>


<script>
  export default {

}
</script>