<template>
    <header class="center-header health" :class="{ 'show': isMenuActive }" @mouseenter="activateMenu" @mouseleave="resetHeaderActive">
        <div class="header__top">
            <div class="header__inner">
                <ul class="header__top--list">
                    <li>
                        <a href="javascript:;"><img src="../../assets/resource/icon/canter_home-icon.png" alt="이미지"></a>
                    </li>
                    <li>
                        <a href="javascript:;">창원파티마병원</a>
                    </li>
                    <li>
                        <div class="header__top--select-wrap" @mouseenter="activeSelect" @mouseleave="resetSelect">
                            <a href="javascript:;">센터바로가기</a>
                            <slide-up-down :active="isSelectActive" class="header__top--select">
                                <div class="header__top--option"><a href="javascript:;">진료협력센터</a></div>
                                <div class="header__top--option"><a href="javascript:;">직업환경의학센터</a></div>
                                <div class="header__top--option"><a href="javascript:;">건강증진센터</a></div>
                            </slide-up-down>
                        </div>
                    </li>
                </ul>
                <ul class="header__top--list">
                    <li>
                        <a href="javascript:;">로그인</a>
                    </li>
                    <li>
                        <a href="javascript:;">회원가입</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header__bottom">
            <div class="header__inner">
                <div class="header__front">
                    <h1 class="header__tit">
                        <a href="/">
                            <img src="@/assets/resource/icon/logo-b.png" alt="이미지">
                            <span>건강증진센터</span>
                        </a>
                    </h1>
                    <div class="header__menu-wrap">
                        <div class="line"></div>
                        <ul class="header__list">
                            <li class="header__item" @mouseenter="menuLine(1)">
                                <a href="javascript:;" class="header__link">센터소개</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(2)">
                                <a href="javascript:;" class="header__link">외래긴료</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(3)">
                                <a href="javascript:;" class="header__link">건강검진</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(4)">
                                <a href="javascript:;" class="header__link">예약/결과조회</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(5)">
                                <a href="javascript:;" class="header__link">보건관리위탁</a>
                            </li>
                            <li class="header__item" @mouseenter="menuLine(6)">
                                <a href="javascript:;" class="header__link">작업환경측정</a>
                            </li>
                        </ul>
                    </div>
                    <div class="header__menu">
                        <a href="" class="header__menu-link login">로그인</a>
                        <a href="" class="header__menu-link join">회원가입</a>
                        <a href="javascript:;" class="header__menu-link" title="전체메뉴 보기" @click="navClick()">
                            <span class="bar"></span>
                            <span class="bar"></span>
                            <span class="bar"></span>
                        </a>
                    </div>
                    <slide-up-down class="header__cont-box" :active="isMenuActive">
                        <div class="header__inner">
                            <div class="header__cont-left">
                                <h3 class="header__cont-left__title">전화예약안내</h3>
                                <ul class="header__cont-left__number">
                                    <li>
                                        <img src="../../assets/resource/icon/header__cont-left__number01.png"  alt="이미지">
                                        TEL : 055)270-1051~3
                                    </li>
                                </ul>
                                <P class="header__cont-left__number--sub-text">(평일 09:00~16:40/토요일 09:00~12:00)</P>
                                <div class="header__cont-left__bottom">
                                    <a href="javascript:;">국가암검진 예약</a>
                                    <a href="javascript:;">건강검진안내</a>
                                </div>
                                <br>
                                <br>
                                <span></span>
                            </div>
                            <ul class="header__cont-list">
                                <li class="header__cont-item" @mouseenter="menuLine(1)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">센터소개</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">의료진안내</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">담당자연락처</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(2)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">업무적합성 평가</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">업무관련성 평가</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">근로자 뇌심혈관계</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">금연약물치료</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">환경성질환</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(3)">
                                    <h2 class="header__cont-tit header__sub-list-wrap">
                                        <a href="javascript:;" class="header__deps1-link">국가건강검진(일반/암)</a>
                                        <ul class="header__sub-list">
                                            <li><a href="javascript:;" class="header__sub-list--link">일반건강검진</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">국가암검진</a></li>
                                        </ul>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">배치전 및 특수검진</a>
                                    </h2>
                                    <h2 class="header__cont-tit header__sub-list-wrap">
                                        <a href="javascript:;" class="header__deps1-link">채용 검진(일반/공무원)</a>
                                        <ul class="header__sub-list">
                                            <li><a href="javascript:;" class="header__sub-list--link">일반채용검진</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">공무원채용검진</a></li>
                                        </ul>
                                    </h2>
                                    <h2 class="header__cont-tit header__sub-list-wrap">
                                        <a href="javascript:;" class="header__deps1-link">비자검진(발급/E-2/H-2)</a>
                                        <ul class="header__sub-list">
                                            <li><a href="javascript:;" class="header__sub-list--link">비자발급 및 유학</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">외국인강사(E-2)</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">방문취업(H-2)</a></li>
                                        </ul>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">선원 검진(국내/국외)</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">기숙사검진</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">보건의료인 면허발급검진</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">보건증/운전면허</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">검진유의사항</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(4)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">국가암검진 예약</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">예약조회</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">검진결과 조회</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">의무기록 사본발급</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(5)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">보건관리위탁</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">위탁절차</a>
                                    </h2>
                                </li>
                                <li class="header__cont-item" @mouseenter="menuLine(6)">
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">직웝환경측정</a>
                                    </h2>
                                    <h2 class="header__cont-tit">
                                        <a href="javascript:;" class="header__deps1-link">위탁절차</a>
                                    </h2>
                                    <h2 class="header__cont-tit header__sub-list-wrap">
                                        <a href="javascript:;" class="header__deps1-link">비자검진(발급/E-2/H-2)</a>
                                        <ul class="header__sub-list">
                                            <li><a href="javascript:;" class="header__sub-list--link">3댑스 메뉴</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">3댑스 메뉴</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">3댑스 메뉴</a></li>
                                            <li><a href="javascript:;" class="header__sub-list--link">3댑스 메뉴</a></li>
                                        </ul>
                                    </h2>
                                </li>
                            </ul>
                        </div>
                    </slide-up-down>

                </div>
            </div>
        </div>
        <div class="site-map">
            <ul class="site-map__list">
                <li class="site-map__item">
                    <h2>센터소개</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">센터소개</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">의료진안내</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">담당자연락체</a>
                        </li>
                    </ul>
                </li>
                <li class="site-map__item">
                    <h2>외래진료</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">업무적합성 평가</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">업무관련성 평가</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">근로자 뇌심혈관계</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">금연약물치료</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">환경성질환</a>
                        </li>
                    </ul>
                </li>
                <li class="site-map__item">
                    <h2>건강검진</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">국가건강검진(일반/암)</a>
                            <ul class="deps3__list">
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">일반건강검진</span>
                                    </a>
                                </li>
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">국가암검진</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">배치전 및 특수검진</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">채용검진(일반/공무원)</a>
                            <ul class="deps3__list">
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">일반채용검진</span>
                                    </a>
                                </li>
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">공무원채용검진</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">비자검진(발급/E-2/H-2)</a>
                            <ul class="deps3__list">
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">비자발급 및 유학</span>
                                    </a>
                                </li>
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">외국인강사(E-2)</span>
                                    </a>
                                </li>
                                <li class="deps3__item">
                                    <a href="/healthinfo/diseaseList.do" class="">
                                        <span class="dot">·</span> <span class="name">방문취업(H-2)</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">선원검진(국내/국외)</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">기숙사검진</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">보건의료인 면허발급검진</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">보건증/운전면허</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">검진유의사항</a>
                        </li>
                        
                    </ul>
                </li>
                <li class="site-map__item">
                    <h2>예약/결과조회</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">국가암검진 예약</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">예약조회</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">검진결과 조회</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">의무기록 사본발급</a>
                        </li>
                    </ul>
                </li>
                <li class="site-map__item">
                    <h2>보건관리위탁</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">보건관리위탁</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">위탁절차</a>
                        </li>
                        
                    </ul>
                </li>
                <li class="site-map__item">
                    <h2>직업환경측정</h2>
                    <ul class="deps2__list">
                        <li class="deps2__item">
                            <a href="javascript:;"  class="deps2__link">작업환경측정</a>
                        </li>
                        <li class="deps2__item">
                            <a href="javascript:;" class="deps2__link">위탁절차</a>
                        </li>
                        
                    </ul>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
    import SlideUpDown from 'vue-slide-up-down';

    export default {
        components: {
            SlideUpDown,
        },
        data() {
            return {
                isMenuActive: false,
                isSelectActive: false,
            };
        },
        methods: {
            navClick() {
                document.querySelector('.site-map').classList.toggle('is-open');
                document.querySelector('body').classList.toggle('is-open');
            },
            activateMenu() {
                this.isMenuActive = true;
            },
            resetHeaderActive() {
                this.isMenuActive = false;
            },
            activeSelect() {
                this.isSelectActive = true;
            },
            resetSelect() {
                this.isSelectActive = false;
            },
            menuLine(item) {
                let headerLine = document.querySelector(".header__menu-wrap .line")
                if (item == 1) {
                    headerLine.style.left = '0%'
                }
                if (item == 2) {
                    headerLine.style.left = '16.666%'
                }
                if (item == 3) {
                    headerLine.style.left = '33.33%'
                }
                if (item == 4) {
                    headerLine.style.left = '49.99%'
                }
                if (item == 5) {
                    headerLine.style.left = '66.666%'
                }
                if (item == 6) {
                    headerLine.style.left = '83.33%'
                }
            },
        },
        mounted() {
        },
        beforeUnmount() {
        }
    };
</script>