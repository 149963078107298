<template>
    <!-- 비만근감소클리닉클리닉 -->
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">소개</h4>
                 <div class="depth__wrap depth02">
                    <div class="depth__text">
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>창원파티마병원 내분비대사내과에서는 비만 환자는 물론 근감소증 환자를 위한 검사와 운동 프로그램, 영양 지침, 약물치료 등 개인별 맞춤 진료를 제공하는 비만·근감소증클리닉을 운영하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-text">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">특장점</h4>
                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">비만클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>삶의 전반에 영향 미치는 ‘비만’</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>비만은 건강의 가장 큰 적이자 치료해야 할 질병으로 대사증후군의 위험요소인 복부비만은 노년층에서 특히 높은 비중을 차지하고 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>청소년의 성조숙증, 월경부전, 다낭성난소증후군, 여성형유방 등과도 관련 있으며, 젊은 성인의 발기부전, 불임 및 난임 등 생식내분비계질환의 발생 위험을 높이는 것으로 알려져 있습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>골관절염, 허리 통증, 천식, 수면무호흡증, 하지정맥류, 긴장성요실금처럼 일상생활과 밀접히 관련된 질환들과도 인과성을 가지며, 비만으로 인한 우울감, 사회적 활동 장애, 자살 충동 등 정신건강에도 영향을 미칠 수 있습니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>비만의 원인</p>
                                <p>비만은 1차성과 2차성으로 구분할 수 있으며, 1차성의 경우 에너지 소비 불균형으로 인한 체중과 체지방이 증가한 상태입니다. 2차성의 경우 유전 질환, 선천성 질환, 신경내분비계 질환, 정신 질환, 약물 등으로 유발된 비만을 의미합니다. 우리나라는 아시아-태평양 기준의 체질량지수 (BMI)에 따라 아래와 같이 비만 단계를 구분합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                               <img src="/resource/img/center09.png" alt="bmi에 따른 성인 비만 기준 표" style="width:100%; max-width:800px;">
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p><b>비만치료, 과학적이고 체계적인 방법으로 장기간 안전하게</b> 창원파티마병원 비만클리닉은 환자 개개인의 건강상태에 맞춰 목표를 설정하며, 비만전문의 자격을 보유한 의사가 환자와 충분한 상담과 논의를 거쳐 합리적으로 치료 방향을 결정합니다.</p>
                                <br>
                                <p>일반적으로 치료 전 체중의 5~10%를 6개월 내 점진적으로 감량하는 것을 1차 목표로 권고하고 있으며, 치료 전 체중의 3~5%만 감량해도 심혈관질환의 위험인자를 개선할 수 있습니다. 무엇보다 환자가 장기간 스트레스 없이 치료를 유지할 수 있도록 생활습관 개선을 우선하며, 운동을 포함한 행동치료를 동반합니다.</p>
                                <br>
                                <p>약물 치료는 비약물 치료에 실패한 경우 고려해볼 수 있으며, 환자의 기저질환과 동반질환을 종합적으로 검토하여 처방하고, 약물 치료 시에도 생활습관 교정을 병행합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depth__wrap depth02">
                    <div class="depth__bullet"><span class="bullet02"></span></div>
                    <div class="depth__text">
                        <h4 class="depth02__title blue__title title">근감소증클리닉</h4>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <p>근감소증검사가 반드시 필요한 환자</p>
                            </div>
                        </div>
                        <br>
                       <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>많이 자도 쉽게 피곤하다</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>예전만큼 운동을 못하겠다</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>평소보다 허리가 더 아프고 다리가 당긴다</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>다리가 쉽게 붓는데 병원에서 정상이라 한다</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>치매는 아니라는데 건망증이 더 심해진다</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>혈압은 괜찮은데 일어설 때 어지럽다</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>근감소증검사, 왜 중요할까요?</p>
                                <p>당뇨, 고혈압, 신부전 등 만성질환자와 뇌졸중 치료 또는 수술 후 요양, 운동 부족 등으로 근력이 쇠퇴한 경우 전문의 상담을 통한 근감소증검사가 꼭 필요하며, 초기 치료와 적극적인 노력을 통한 근육량 증진 및 관리가 매우 중요합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>근감소증클리닉의 장점</p>
                                <p>근육량 및 근력 측정으로 정확히 평가하여 맞춤형 운동 프로그램 및 영양지침 등 치료 계획을 수립하며, 진행 중에는 정기검사를 통해 체계적으로 치료 효과를 모니터링하여 필요 시 적절히 조정합니다. 또한 단순 근육량 유지를 넘어 건강한 노화와 활동성 증진을 위한 적극적인 생활방식, 균형 잡힌 식단을 제안하고, 근육량 및 근력 유지 개선을 통해 노년기 자신감과 자아 존중감을 향상 시켜 삶의 질을 개선합니다.</p>
                            </div>
                        </div>
                        <br>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>조기발견과 예방</p>
                                <p>초기 운동, 영양, 약물 조절을 통한 적절한 중재로 질환의 예방 및 진행 지연을 돕습니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>낙상 및 골절 위험 감소</p>
                                <p>근력 약화의 조기 진단 및 관리로 낙상의 위험요인을 예방합니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>만성질환 관리</p>
                                <p>근감소는 심혈관질환, 당뇨, 비만, 신부전, 골다공증 등 만성질환과 관련이 깊으며, 근력 개선이 만성질환 예방 및 관리에 도움됩니다.</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>수술 후 회복 촉진</p>
                                <p>노령 환자는 근감소증 여부가 수술 결과 및 회복 속도에 영향</p>
                            </div>
                        </div>
                        <div class="depth__wrap">
                            <div class="depth__bullet"><span class="bullet03"></span></div>
                            <div class="depth__text">
                                <p>근감소증 선별 질문</p>
                                <p>한국형 근감소증 선별 질문지(SARC-F) 기준 10점 만점에 4점 이상이면 근감소증을 의심해볼 수 있습니다.</p>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                 <br>
                        <div class="depth__wrap">
                            <div class="depth__text">
                                <div class="table-wrap table-type1">
                                    <div class="scroll">
                                        <table>
                                            <caption class="blind">근감소증 선별 질문</caption>
                                            <colgroup>
                                                <col style="width:15%;">
                                                <col style="width:35%;"> 
                                                <col style="width:40%;">
                                                <col style="width:5%;">
                                                <col style="width:5%;">
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>항목</th>
                                                    <th>질문</th>
                                                    <th colspan="3">답변 *해당 항목에 체크하고 점수를 모두 더합니다</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td rowspan="3">근력</td>
                                                    <td rowspan="3">무게 4.5㎏을 들어 나르는 것이<br>얼마나 어려운가요?</td>
                                                    <td>전혀 어렵지 않다</td>
                                                    <td></td>
                                                    <td>0점</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <td style="border-left:1px solid #d9d9d9;">조금 어렵다</td>
                                                    <td></td>
                                                    <td>1점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">매우 어렵다/할 수 없다</td>
                                                    <td></td>
                                                    <td>2점</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3">보행보조</td>
                                                    <td rowspan="3">방 한 쪽 끝에서 다른 쪽 끝까지 걷는 것이<br>얼마나 어려운가요?</td>
                                                    <td>전혀 어렵지 않다</td>
                                                    <td></td>
                                                    <td>0점</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <td style="border-left:1px solid #d9d9d9;">조금 어렵다</td>
                                                    <td></td>
                                                    <td>1점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">매우 어렵다/보조기 사용해야 가능/할 수 없다</td>
                                                    <td></td>
                                                    <td>2점</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3">의자에서<br>일어나기</td>
                                                    <td rowspan="3">의자(휠체어)에서 일어나 침대(잠자리)로<br>이동하는 것이 얼마나 어려운가요?</td>
                                                    <td>전혀 어렵지 않다</td>
                                                    <td></td>
                                                    <td>0점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">조금 어렵다</td>
                                                    <td></td>
                                                    <td>1점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">매우 어렵다/도움 없이는 할 수 없다</td>
                                                    <td></td>
                                                    <td>2점</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3">계단 오르기</td>
                                                    <td rowspan="3">계단 10개를 쉬지 않고 오르는 것이<br>얼마나 어려운가요?</td>
                                                    <td>전혀 어렵지 않다</td>
                                                    <td></td>
                                                    <td>0점</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <td style="border-left:1px solid #d9d9d9;">조금 어렵다</td>
                                                    <td></td>
                                                    <td>1점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">매우 어렵다/할 수 없다</td>
                                                    <td></td>
                                                    <td>2점</td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="3">낙상</td>
                                                    <td rowspan="3">지난 1년 동안 몇 번이나<br>넘어지셨나요?</td>
                                                    <td>전혀 없다</td>
                                                    <td></td>
                                                    <td>0점</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <td style="border-left:1px solid #d9d9d9;">1~3회</td>
                                                    <td></td>
                                                    <td>1점</td>
                                                </tr>
                                                <tr>
                                                    <td style="border-left:1px solid #d9d9d9;">4회 이상</td>
                                                    <td></td>
                                                    <td>2점</td>
                                                </tr>

                                                <tr>
                                                    <td colspan="3" style="text-align:right;">합계 점수</td>
                                                    <td></td>
                                                    <td>:점</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


            </div>
        </div>
    </div>
</template>


<script>
export default {
   
};
</script>