<template lang="">
<div>
<div class="ideology-visual-wrap">
    <div class="ideology-visual">
        <h4 class="ideology-visual__text">파티마병원 이념</h4>
        <p class="ideology-visual__desc">파티마병원은 포교 베네딕도 수녀회가 <br>

            가톨릭교회 전통인 예수그리스도의 <br>
            치유 사도직을 수행하기 위하여 설립되었다</p>
    </div>
</div>
<div class="ideology-list">
    <ul class="ideology-list__item-wrap">
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img01.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>우리 병원을 찾는 모든 이들의 삶의 질을 높이기 위하여 양질의 진료로써 질병으로 고통받는 이들을 낫게 하고, 심리적, 정신적 및 영적인 안정과 평화를 찾도록 도와주며 최선의 예방 및 재활에
                    힘써 건강한 생활을 하도록 돕는다.</p>
            </div>
        </li>
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img07.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>질병과 고통 그리고 죽음이 인간 삶의 한 부분이며 오히려 이들을 통해 하느님의 현존을 체험하고 환자와 그의 가족들에게도 병을 치유하시는 분이 하느님이심을 알게 하며 하느님 나라에 대한
                    새로운 희망과 인간다운 품위를 지니도록 그들을 돕는다.</p>
            </div>
        </li>
    </ul>
    <ul class="ideology-list__item-wrap">
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img08.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>각 개인이 기본적으로 필요한 진료를 받을 권리가 있음을 인정하며 특히 가난하고 소외된 이들에게 각별한 관심을 가지고 돌본다.</p>
            </div>
        </li>
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img09.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>모든 인간 생명의 신비와 존엄성이 진료활동 전반에 걸쳐 존중되도록 가톨릭교회의 가르침을 준수한다.</p>
            </div>
        </li>
    </ul>
    <ul class="ideology-list__item-wrap">
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img10.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>성 베네딕도가 가르친 대로 "병든 형제를 그리스도처럼" 섬기고 돌볼 뿐 아니라 우리의 치유 사도직에 동참하는 동료들과 형제적 연대성을 증진시켜 그리스도교적 공동체를 형성하는데 힘쓴다.
                </p>
            </div>
        </li>
        <li class="ideology-list__item">
            <div class="ideology-list__img">
                <img src="/resource/img/ideology-list__img11.png" alt="이미지">
            </div>
            <div class="ideology-list__con">
                <h4 class="ideology-list__title">우리는</h4>
                <p>양질의 진료와 서비스를 제공하는데 필요한 기술과 지식을 갖추고 책임 있는 관리로 이들 목적을 달성하도록 노력한다.</p>
            </div>
        </li>
    </ul>
</div>
<div class="ideology-visual-wrap bottom">
    <div class="ideology-visual">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">가치선언문</h4>
            </div>
        </div>
        <h4 class="ideology-visual__text">
            <strong>사랑의 돌봄</strong>으로 <strong>생명존중</strong>의 <br>
            <strong>전인치유</strong>를 수행한다
        </h4>
    </div>
</div>
<div class="ideology-valueform__wrap">
    <p class="ideology-valueform__wrap-back">Core values ​​&amp; behavior patterns</p>
    <div class="info-text depth__wrap depth01">
        <div class="depth__bullet"><span class="bullet01"></span></div>
        <div class="depth__text">
            <h4 class="depth01__title title">핵심가치 &amp; 행동양식</h4>
        </div>
    </div>
    <div class="ideology-valueform">
        <ul class="ideology-valueform__list">
            <li class="ideology-valueform__list--item on">
                <a href="javascript:;">
                    양질의<br>
                    진료
                </a>
            </li>
            <li class="ideology-valueform__list--item">
                <a href="javascript:;">
                    책임있는 <br>
                    관리
                </a>
            </li>
            <li class="ideology-valueform__list--item">
                <a href="javascript:;">
                    생명 <br>
                    존중
                </a>
            </li>
            <li class="ideology-valueform__list--item">
                <a href="javascript:;">
                    그리스교도적 <br>
                    공동체
                </a>
            </li>
        </ul>
        <ul class="ideology-valueform__text-list">
            <li class="ideology-valueform__text-list--item info-text on">
                <h3 class="depth01__title title blue__title">양질의진료</h3>
                <h3 class="depth03__title title">병원을 들어오는 순간부터 나가는 순간까지 최선의 진료서비스를 제공해 환자와 고객의 건강과 치료를 돕는 것을 의미합니다</h3>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 적극경청, 적극공감, 적극설명합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 환자의 기대가 만족이 되도록 노력합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 환자의 입장에서 최선의 선택을 합니다</p>
                    </div>
                </div>
                <img class="ideology-valueform__text-img" src="/resource/img/ideology-valueform__text-img.png" alt="이미지">
            </li>
            <li class="ideology-valueform__text-list--item info-text">
                <h3 class="depth01__title title blue__title">책임있는 관리</h3>
                <h3 class="depth03__title title">양질의 진료와 서비스를 제공하기 위해 높은 역량에 기반해 자신의 역할에 충실하여 병원 공동체의 완성도를 높이는 것을 의미합니다</h3>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 끊임없이 학습합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 사소한 일이라도 소통하고 공유합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 내 업무 분야의 전문가입니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 업무규정을 준수합니다</p>
                    </div>
                </div>
                <img class="ideology-valueform__text-img" src="/resource/img/ideology-valueform__text-img.png" alt="이미지">
            </li>
            <li class="ideology-valueform__text-list--item info-text">
                <h3 class="depth01__title title blue__title">생명존중</h3>
                <h3 class="depth03__title title">생명을 살리는 일에 최선을 다하며 치유를 통해 탄생에서 죽음에 이르기까지 인간다운 품위를 지키게 하는 것을 의미합니다</h3>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 환자를 존중합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 생명의 잉태에서부터 죽음에 이르기까지 모든 순간에서 생명의 가치를 지켜나갑니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 환자에게 다름을 이유로 차별하지 않고 동등하게 대합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 환자의 불만에서 불편함을 찾아내고 개선합니다</p>
                    </div>
                </div>
                <img class="ideology-valueform__text-img" src="/resource/img/ideology-valueform__text-img.png" alt="이미지">
            </li>
            <li class="ideology-valueform__text-list--item info-text">
                <h3 class="depth01__title title blue__title">그리스도교적 공동체</h3>
                <h3 class="depth03__title title">병원에 있는 모든 이를 환대와 사랑으로 대하며 병원구성원 간에 형제적 연대성을 바탕으로 긴밀히 협업하는 것을 의미합니다</h3>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 눈빛만 마주쳐도 먼저 인사합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 언제 어디서나 환대와 칭찬을 생활화합니다</p>
                    </div>
                </div>
                <div class="depth__wrap depth03">
                    <div class="depth__bullet">
                        <span class="bullet03"></span>
                    </div>
                    <div class="depth__text">
                        <p>우리는 힘들고 어려운 일을 함께 협력해서 수행합니다</p>
                    </div>
                </div>
                <img class="ideology-valueform__text-img" src="/resource/img/ideology-valueform__text-img.png" alt="이미지">
            </li>
        </ul>
    </div>
</div>
</div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>